// @flow

import React, { type Node } from 'react'
import { Button } from 'antd'
import './StartupButton.css'

type Props = {
  children?: Node,
  disabled?: boolean,
  loading?: boolean,
  onClick?: (any) => mixed,
}

const StartupButton = (props: Props) => {
  return (
    <Button
      {...props}
      type="primary"
      className="startup-button"
      disabled={props.disabled}
      loading={props.loading}
      onClick={props.onClick}
    >
      {props.children}
    </Button>
  )
}

export default StartupButton
