export const setMicrosoftTeamsPermission = (microsoftTeamsPermission) => {
  return {
    type: 'setMicrosoftTeamsPermission',
    microsoftTeamsPermission,
  }
}

export const setPhoneNumberPermission = (phoneNumberPermission) => {
  return {
    type: 'setPhoneNumberPermission',
    phoneNumberPermission,
  }
}

export const setStatsPermission = (statsPermission) => {
  return {
    type: 'setStatsPermission',
    statsPermission,
  }
}

export const setAdvancedPermission = (advancedPermission) => {
  return {
    type: 'setAdvancedPermission',
    advancedPermission,
  }
}

export const setBillingPermission = (billingPermission) => {
  return {
    type: 'setBillingPermission',
    billingPermission,
  }
}

export const setCompanySettingsPermission = (companySettingsPermission) => {
  return {
    type: 'setCompanySettingsPermission',
    companySettingsPermission,
  }
}

export const setAccessUsersPermission = (accessUsersPermission) => {
  return {
    type: 'setAccessUsersPermission',
    accessUsersPermission,
  }
}
