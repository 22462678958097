/* eslint-disable no-console */
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setPaymentSucceededEventData } from '../actions/billingAction'
import { setAccountSettingsData } from '../actions/SampleAction'
import Sockette from 'sockette'
import { EXPIRE, ID_TOKEN, SOCKET_URL } from '../utils/constants'
import { getItem } from '../utils/storage'

const UseSocketSettings = () => {
  const dispatch = useDispatch()
  const [reconnect, setReconnect] = useState(false)
  const [idToken, setIdToken] = useState(getItem(ID_TOKEN))
  var prevPaymentEventId = ''

  useEffect(() => {
    if (getItem(EXPIRE) && Number(getItem(EXPIRE)) * 1000 > Date.now()) {
      let ws = new Sockette(`${SOCKET_URL}?token=${idToken}`, {
        timeout: 5e3,
        maxAttempts: 10,
        onopen: (event) => console.log('Connected!', event),
        onmessage: (event) => handleMessage(event),
        onreconnect: (event) => console.log('Reconnecting...', event),
        onmaximum: (event) => {
          console.log('Stop Attempting --> Reconnecting...', event)
          setReconnect(!reconnect)
        },
        onclose: (event) => {
          console.log('Closed!', event)
          if (ws) {
            (getItem(ID_TOKEN) !== idToken) && setIdToken(getItem(ID_TOKEN))
            getItem(ID_TOKEN) === idToken && ws.reconnect()
          }
          // ws && ws.reconnect()
        },
        onerror: (event) => console.log('Error:', event),
      })

      return function cleanup() {
        ws && ws.close()
        ws = null
      }
    }
  }, [reconnect, idToken])

  const handleMessage = (event) => {
    console.log('Message recived: ', event)
    const eventData = JSON.parse(event.data)

    if (eventData.event === 'PAYMENT_SUCCESS' && prevPaymentEventId !== eventData.data?.data?.detail?.stripe_paymentId) {
      prevPaymentEventId = eventData.data.data.detail.stripe_paymentId
      dispatch(setPaymentSucceededEventData(eventData.data))
    } else if (eventData.event === 'TAXID_UPDATED') {
      dispatch(setAccountSettingsData({ data: eventData.data }))
    }
  }
}

export default UseSocketSettings
