// @flow

import React from 'react'
import { Steps as StepWrapper, Tooltip } from 'antd'

type Props = {
  steps: $ReadOnlyArray<*>,
  current: number,
}

const Steps = ({ steps, current = 0 }: Props) => {
  return (
    <StepWrapper size={'small'} current={current}>
      {steps.map((step, index) => (
        <StepWrapper.Step
          key={index}
          title={<Tooltip title={step.title}>{step.title}</Tooltip>}
        />
      ))}
    </StepWrapper>
  )
}

export default Steps
