/* eslint-disable no-negated-condition */
// @flow

import React, { useState } from 'react'
import { Button, Input, message } from 'antd'
import { useSelector } from 'react-redux'
import { getTranslatedText } from '../../utils/translations'
import PttButton from '../CustomButton/PttButton'
import ModalLayout, { Body, Footer, Header } from '../Popup/popup'
import { LOGINAUTHURL, SIGNUP_API, SIGNUP_APIKEY } from '../../utils/constants'
import { AUTH_API_KEY } from '../../utils/constants'
import { setTokens } from '../../utils/LocalStorage'
import './Settings.css'

type Props = {
  onClose: () => void,
  visiblity: boolean,
}

const ChangePassword = ({ onClose, visiblity }: Props) => {
  const apiHeader = {
    'Content-Type': 'application/json',
    'x-api-key': AUTH_API_KEY,
  }

  const { language, translations } = useSelector((state) => {
    return {
      language: state.sampleReducer.language,
      translations: state.sampleReducer.translations,
    }
  })

  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [loading, setLoading] = useState(false)

  const handleError = () => {
    setLoading(false)
    message.error(
      getTranslatedText(translations, language, 'something_went_wrong')
    )
  }

  const updatePassword = () => {
    const userEmail = localStorage.getItem('user')

    if (userEmail) {
      fetch(`${SIGNUP_API}/changepassword/${userEmail}?myDirectRouting=false`, {
        headers: {
          'x-api-key': SIGNUP_APIKEY,
        },
        method: 'POST',
        body: JSON.stringify({
          password: newPassword.trim(),
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status) {
            closeHandler()
            message.success(
              getTranslatedText(
                translations,
                language,
                'password_updated_successfully'
              )
            )
          } else {
            handleError()
          }
        })
        .catch(() => {
          handleError()
        })
    }
  }

  const saveHandler = () => {
    if (currentPassword.trim().length === 0) {
      return message.warning(
        getTranslatedText(
          translations,
          language,
          'current_password_placeholder'
        )
      )
    } else if (newPassword.trim().length === 0) {
      return message.warning(
        getTranslatedText(translations, language, 'new_password_placeholder')
      )
    } else if (confirmPassword.trim().length === 0) {
      return message.warning(
        getTranslatedText(
          translations,
          language,
          'confirm_password_placeholder'
        )
      )
    } else if (currentPassword === newPassword) {
      return message.warning(
        getTranslatedText(translations, language, 'password_change_invalid')
      )
    } else if (newPassword !== confirmPassword) {
      return message.warning(
        getTranslatedText(translations, language, 'password_not_match')
      )
    } else {
      setLoading(true)
      checkLogin()
    }
  }

  const handleLoginError = () => {
    setLoading(false)
    message.error(getTranslatedText(translations, language, 'invalid_password'))
  }

  const checkLogin = () => {
    const userEmail = localStorage.getItem('user')

    if (userEmail) {
      fetch(LOGINAUTHURL, {
        headers: apiHeader,
        method: 'PUT',
        body: JSON.stringify({
          username: userEmail,
          password: currentPassword,
          permisionlist: [],
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.result && !res.error) {
            setTokens(res)
            updatePassword()
          } else {
            handleLoginError()
          }
        })
        .catch(() => handleLoginError)
    }
  }

  const cleanUp = () => {
    setCurrentPassword('')
    setNewPassword('')
    setConfirmPassword('')
    setLoading(false)
  }

  const closeHandler = () => {
    if (!loading) {
      cleanUp()
      onClose()
    }
  }

  return (
    <ModalLayout
      closable={!loading}
      onCancel={closeHandler}
      visible={visiblity}
    >
      <Header>
        {getTranslatedText(translations, language, 'change_password')}
      </Header>
      <Body>
        <React.Fragment>
          <div className="change-password-title-div">
            {getTranslatedText(translations, language, 'current_password')}
          </div>
          <Input.Password
            className="change-password-input"
            onChange={(event) => setCurrentPassword(event.target.value)}
            onPressEnter={saveHandler}
            placeholder={getTranslatedText(
              translations,
              language,
              'current_password_placeholder'
            )}
            value={currentPassword}
          />
          <div className="change-password-title-div">
            {getTranslatedText(translations, language, 'new_password')}
          </div>
          <Input.Password
            className="change-password-input"
            onChange={(event) => setNewPassword(event.target.value)}
            onPressEnter={saveHandler}
            placeholder={getTranslatedText(
              translations,
              language,
              'new_password_placeholder'
            )}
            value={newPassword}
          />
          <div className="change-password-title-div">
            {getTranslatedText(translations, language, 'confirm_password')}
          </div>
          <Input.Password
            className="change-password-input"
            onChange={(event) => setConfirmPassword(event.target.value)}
            onPressEnter={saveHandler}
            placeholder={getTranslatedText(
              translations,
              language,
              'confirm_password_placeholder'
            )}
            value={confirmPassword}
          />
        </React.Fragment>
      </Body>
      <Footer>
        <div className="cp-button-body">
          <Button
            disabled={loading}
            className="cp-back-button-style"
            onClick={closeHandler}
          >
            {getTranslatedText(translations, language, 'cancel')}
          </Button>
          <PttButton
            disabled={loading}
            loading={loading}
            type="primary"
            onClick={saveHandler}
          >
            {getTranslatedText(translations, language, 'save')}
          </PttButton>
        </div>
      </Footer>
    </ModalLayout>
  )
}

export default ChangePassword
