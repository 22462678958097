// @flow

import React, {
  forwardRef,
  Fragment,
  memo,
  useState,
  useImperativeHandle,
  useEffect,
} from 'react'
import { Input } from 'antd'

import './numberfield.css'
import { NODE_TYPES } from '../nodetypes'
import { keyDownHandler, pasteHandler } from '../../../utils/helpers'

type Props = {
  autoSaveHandler?: (any) => mixed,
  initialValue?: string,
  label?: string,
  keyName: string,
  maxLength?: number,
  mode: string,
  placeholder?: string,
  size?: string,
}

const NumberField = forwardRef<Props, *>((props: Props, ref) => {
  const {
    autoSaveHandler,
    initialValue = '',
    keyName,
    label = 'Label',
    maxLength = 500,
    placeholder = '',
    size = 'default',
    mode,
  } = props

  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const changeHandler = (event) => {
    setValue(event.target.value)
  }

  useImperativeHandle(ref, () => ({
    getLatestValue() {
      return value
    },
    getName() {
      return keyName
    },
    getType() {
      return NODE_TYPES.number
    },
    resetValue() {
      setValue('')
    },
  }))

  const triggerAutoSave = () => {
    if (mode === 'Edit' && autoSaveHandler && initialValue !== value) {
      autoSaveHandler(NODE_TYPES.number)
    }
  }

  return (
    <Fragment>
      <div className="label-spacing"> {label} </div>
      <Input
        maxLength={maxLength}
        onBlur={triggerAutoSave}
        onChange={changeHandler}
        onKeyDown={(event) => keyDownHandler(event, 'allowOnlyNumbers')}
        onPaste={(event) => pasteHandler(event, 'allowOnlyNumbers')}
        placeholder={placeholder}
        size={size}
        value={value}
      />
    </Fragment>
  )
})

export default memo<Props>(NumberField)
