/* eslint-disable no-console */
// @flow

import { Button, message } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty, cloneDeep } from 'lodash'
import ModalLayout, { Header, Body, Footer } from '../Popup/popup'
import Step from '../Steps/step'
import ConfigureUsers from '../Connect/MicrosoftTeams/configureusers'
import SelectUsers from '../Connect/MicrosoftTeams/selectusers'
import { PttButton } from '../CustomButton/custombutton'
import { parseApiResult } from '../../utils/helpers'
import { getNewArray } from '../../utils/helpers'
import { getCallingPlanMonth } from '../../utils/helpers'
import useCustomFetch from '../../Hooks/UseCustomFetch'
import { APIURL, CUSTOM_ACTION_URL } from '../../utils/constants'
import { getConfigUserOptions } from './msteamsutils'
import { getMsUsers } from './msteamsutils'
import { isContainsError } from './msteamsutils'
import { canProceed } from './msteamsutils'
import { oauthMicrosoft } from './msteamsutils'
import { getSetupConnectorRetryTime } from './msteamsutils'
import VoiceUserCheckout from './VoiceUserCheckout'
import { setPaymentSucceededEventData } from '../../actions/billingAction'
import UseFetchMicrosoftUsers from '../../Hooks/UseFetchMicrosoftUsers'
import './adduser.css'

type Props = {
  connectorId: string,
  onClose: () => void,
  msOAuthErrorHandler: (any) => mixed,
  relationApi: Object,
  visiblity: boolean,
  translations: Object,
  language: string,
  callingPlansData: $ReadOnlyArray<*>,
  updatePhoneListCallback: () => mixed,
  microsoftUsersCollections: Object,
  existingUsers: $ReadOnlyArray<*>,
  userAccounId: String,
  connectorMailId: String,
  relationData: $ReadOnlyArray<*>,
}

const AddUser = ({
  connectorId,
  onClose,
  relationApi,
  visiblity,
  connectorMailId,
  msOAuthErrorHandler,
  callingPlansData,
  relationData,
  updatePhoneListCallback,
  existingUsers,
}: Props) => {
  const dispatch = useDispatch()
  const {
    accountSettings,
    language,
    translations,
    userSettings,
    microsoftUsersCollections,
    userAccounId,
  } = useSelector((state) => ({
    accountSettings: state.sampleReducer.accountSettings,
    language: state.sampleReducer.language,
    translations: state.sampleReducer.translations || {},
    userSettings: state.sampleReducer.userSettings,
    microsoftUsersCollections: state.msTeamsReducer.microsoftUsersCollections,
    userAccounId: state.sampleReducer.userAccounId,
  }))

  const [currentStep, setCurrentStep] = useState(0)
  const [tableLoading, setTableLoading] = useState(false)
  const [refreshLoading, setRefreshLoading] = useState(false)
  const [listRows, setListRows] = useState([])
  const [rowKeys, setRowKeys] = useState([])
  const [options, setOptions] = useState([])
  const [configLoad, setConfigLoad] = useState(false)
  const [accessToken, setAccessToken] = useState('')
  const [updateData, setUpdateData] = useState('')
  const [checkoutConfirm, setCheckoutConfirm] = useState(false)
  const [checkoutApiBodyData, setCheckoutApiBodyData] = useState({ action: '' })
  const [phoneCheckOutBody, setPhoneCheckOutBody] = useState({ action: '' })
  const [amountToCharge, setAmountToCharge] = useState(0)
  const [checkoutLoader, setCheckoutLoader] = useState(false)
  const [callingPlans, setCallingPlans] = useState([])
  const [phones, setPhones] = useState([])
  const [phoneAmount, setPhoneAmount] = useState(0)
  const [callingPlanAmount, setCallingPlanAmount] = useState(0)
  const [configUsers, setConfigUsers] = useState([])

  const onFetchUsersSuccess = (data) => {
    const relations = parseApiResult(relationApi.response)

    setListRows(getMsUsers(data.value, listRows, relations))
    setTableLoading(false)
    setRefreshLoading(false)
  }

  const onFetchUsersError = (err) => {
    console.log('Error Fetching microsoft users', err)
  }

  const onFetchUsersInvalidToken = () => {
    oauthMicrosoft(
      connectorMailId,
      () => {
        executeFetchUsersApi()
      },
      (err) => {
        msOAuthErrorHandler(err)
      }
    )
  }

  const onFetchUsersTooManyReq = (data) => {
    setTimeout(() => {
      executeFetchUsersApi()
    }, getSetupConnectorRetryTime(data.error.retry))
  }

  const [fetchUsers] = UseFetchMicrosoftUsers(
    onFetchUsersSuccess,
    onFetchUsersError,
    onFetchUsersInvalidToken,
    onFetchUsersTooManyReq
  )

  const executeFetchUsersApi = () => {
    fetchUsers.reset()
    fetchUsers.setAction('')
    fetchUsers.setId(connectorId)
    fetchUsers.setFetch(true)
  }

  const onPreviousClick = () => {
    const index = microsoftUsersCollections.currentPage - 2
    const url =
      index === 0
        ? null
        : microsoftUsersCollections.nextUsersUrls.find(
            (item) => item.key === index
          )?.link

    fetchUsers.reset()
    fetchUsers.setAction('prevoius')
    fetchUsers.setBody({ search: null, nextlink: url ? url : '' })
    fetchUsers.setId(connectorId)
    fetchUsers.setFetch(true)
    setTableLoading(true)
  }

  const onNextClick = () => {
    const url =
      microsoftUsersCollections.nextUsersUrls[
        microsoftUsersCollections.currentPage - 1
      ]?.link

    fetchUsers.reset()
    fetchUsers.setAction('next')
    fetchUsers.setBody({ search: null, nextlink: url ? url : '' })
    fetchUsers.setId(connectorId)
    fetchUsers.setFetch(true)
    setTableLoading(true)
  }

  const onSearch = (word) => {
    fetchUsers.reset()
    fetchUsers.setAction('')
    fetchUsers.setBody({ search: word })
    fetchUsers.setId(connectorId)
    fetchUsers.setFetch(true)
    setTableLoading(true)
  }

  const [phoneApi] = useCustomFetch(
    `${APIURL}/phone?relations=voice_user,call_flow`,
    'GET',
    false
  )

  const [voiceUserApi] = useCustomFetch(`${APIURL}/voice_user`, 'GET', false)
  const [getConnectorApi] = useCustomFetch(
    `${APIURL}/connector/${connectorId}`,
    'GET',
    false
  )

  const [updateConnectorApi] = useCustomFetch(
    `${APIURL}/connector/${connectorId}`,
    'PATCH',
    false,
    updateData
  )

  const [checkoutApi] = useCustomFetch(
    `${CUSTOM_ACTION_URL}/payment`,
    'PAYMENT',
    false,
    JSON.stringify(checkoutApiBodyData)
  )

  const [phoneCheckoutApi] = useCustomFetch(
    `${CUSTOM_ACTION_URL}/payment`,
    'PAYMENT',
    false,
    JSON.stringify(phoneCheckOutBody)
  )

  useEffect(() => {
    if (phoneCheckOutBody.action) {
      phoneCheckoutApi.setRefetch(true)
    }
  }, [phoneCheckOutBody])

  useEffect(() => {
    setCheckoutConfirm(false)
  }, [currentStep])

  useEffect(() => {
    if (phoneCheckoutApi.response && phoneCheckoutApi.response.status) {
      makeVoiceUsers()
      message.success(translations.phone_number_added_success[language])
      updatePhoneListCallback()
    } else if (
      phoneCheckoutApi.response &&
      phoneCheckoutApi.response.status === false
    ) {
      message.error(translations.something_went_wrong[language])
    }
  }, [phoneCheckoutApi.response])

  useEffect(() => {
    if (visiblity) {
      listUsers()
      phoneApi.setRefetch(true)
      voiceUserApi.setRefetch(true)
      setRowKeys([])
      setConfigUsers([])
    }
  }, [visiblity])

  useEffect(() => {
    if (!isEmpty(phoneApi.response)) {
      if (phoneApi.response.result) {
        setOptions(getConfigUserOptions(phoneApi.response))
      }
    }
  }, [phoneApi.response])

  useEffect(() => {
    if (phones.length) {
      const opts = JSON.parse(JSON.stringify(options))
      const optsIds = opts.map((opt) => opt.id)
      const data = []

      phones.length &&
        phones.forEach((phone) => {
          if (!optsIds.includes(phone.id)) {
            data.push({ id: phone.id, chosen: false })
          }
        })

      setOptions(opts.concat(data))
    }
  }, [phones])

  useEffect(() => {
    if (configLoad) {
      makeVoiceUsers()
    }
  }, [configLoad])

  useEffect(() => {
    if (checkoutApiBodyData.action) {
      checkoutApi.setRefetch(true)
    }
  }, [checkoutApiBodyData])

  useEffect(() => {
    if (checkoutApi.response && checkoutApi.response.status) {
      const userCount = checkoutApi.response.response.voiceUserResponse.filter(
        (response) => response.console === 'createVoiceUser'
      ).length

      if (userCount) {
        message.success(
          userCount === 1
            ? translations.voice_user_success[language]
            : `${userCount} ${translations.voice_users_success[language]}`
        )
        relationApi.setRefetch(true)
      }
      setCheckoutLoader(false)
      exitHandler()
    }
  }, [checkoutApi.response])

  const enableCheckoutConfirm = (
    value,
    amountToCharge = 0,
    voiceProRateCharge = 0,
    phoneProRateCharge = 0,
    callingPlanProRateCharge = 0
  ) => {
    console.log({ amountToCharge })
    dispatch(setPaymentSucceededEventData(null))
    setAmountToCharge(voiceProRateCharge)
    setCheckoutConfirm(value)
    setPhoneAmount(phoneProRateCharge)
    setCallingPlanAmount(callingPlanProRateCharge)
  }

  const getCallingPlanBody = (row) => {
    const { data } = row.callingPlan

    data.length &&
      data.forEach((item) => {
        item.uniqueId && delete item.uniqueId
      })

    return {
      data: {
        calling_plan: data,
        month: getCallingPlanMonth('current'),
      },
    }
  }

  const makeVoiceUsers = () => {
    const users = []
    const activeRows = cloneDeep(configUsers)

    if (activeRows.length) {
      activeRows.forEach((row) => {
        users.push({
          callingPlanBody: getCallingPlanBody(row),
          displayName: row.displayName,
          email: row.email,
          extension: row.extension,
          givenName: row.givenName,
          lastName: row.lastName,
          microsoft_id: row.microsoft_id,
          phonenumber: row.phonenumber,
        })
      })

      setCheckoutApiBodyData({
        action: 'MICROSOFT_CHECKOUT',
        data: {
          accountId: userAccounId,
          callingPlanAmount: callingPlanAmount,
          chargeAmount: amountToCharge,
          connectorId: connectorId,
          idArray:
            relationData.length && relationData[0].id
              ? relationData.map((item) => item.id)
              : [],
          voiceUsers: users,
          company_settings: accountSettings,
          user_settings: userSettings,
        },
      })
    }
  }

  const getAvailableExtensions = useMemo(() => {
    const result = parseApiResult(voiceUserApi.response)
    const data = []

    if (voiceUserApi.response.result && result.length) {
      result.forEach((res) => {
        if (res.data && res.data.extension) {
          data.push(res.data.extension)
        }
      })
    }

    return data
  }, [voiceUserApi.response])

  const listUsers = () => {
    setTableLoading(true)
    executeFetchUsersApi()
  }

  const onHandleRefresh = () => {
    setRefreshLoading(true)
    executeFetchUsersApi()
  }

  useEffect(() => {
    if (accessToken.length) {
      getConnectorApi.setRefetch(true)
    }
  }, [accessToken])

  useEffect(() => {
    if (!isEmpty(getConnectorApi.response)) {
      if (getConnectorApi.response.result) {
        const result = parseApiResult(getConnectorApi.response)

        result[0].data.microsoft_response.microsoft_token = accessToken
        console.log('updating token')
        setUpdateData(JSON.stringify(result[0]))
      }
    }
  }, [getConnectorApi.response])

  useEffect(() => {
    if (updateData.length) {
      updateConnectorApi.setRefetch(true)
    }
  }, [updateData])

  useEffect(() => {
    if (!isEmpty(updateConnectorApi.response)) {
      const res = parseApiResult(updateConnectorApi.response)

      if (
        updateConnectorApi.response.result &&
        res.length &&
        res[0].action === 'updated'
      ) {
        setAccessToken('')
        getConnectorApi.setResponse({})
        setUpdateData('')
        updateConnectorApi.setResponse({})
        setTimeout(() => {
          console.log('Calling list users again')
          listUsers()
        }, 3000)
      }
    }
  }, [updateConnectorApi.response])

  const updateConfigUsers = (rows) => {
    setConfigUsers(getNewArray(rows))
  }

  const onRowSelect = (record, status) => {
    let users = cloneDeep(configUsers)

    if (status) {
      users.push(record)
    } else {
      users = users.filter((user) => user.key !== record.key)
    }

    setConfigUsers(users)
    setRowKeys(users.map((user) => user.key))
  }

  const onRowSelectUnSelectAll = (status, records) => {
    if (status) {
      const users = cloneDeep(configUsers).concat(records)

      setConfigUsers(users)
      setRowKeys(users.map((user) => user.key))
    } else {
      const items = []
      const recordsKeys = records.map((record) => record.key)

      configUsers.length &&
        configUsers.forEach((configUser) => {
          if (!recordsKeys.includes(configUser.key)) {
            items.push(configUser)
          }
        })

      setConfigUsers(items)
      setRowKeys(items.map((item) => item.key))
    }
  }

  const renderSelectUsers = () => {
    return (
      <SelectUsers
        listRows={listRows}
        loading={tableLoading}
        onNextClick={onNextClick}
        onPreviousClick={onPreviousClick}
        onRefresh={onHandleRefresh}
        onRowSelect={onRowSelect}
        onRowSelectUnSelectAll={onRowSelectUnSelectAll}
        onSearch={onSearch}
        refreshLoading={refreshLoading}
        rowKeys={rowKeys}
        scrollObject={{ y: 130 }}
      />
    )
  }

  const renderConfigureUsers = () => {
    return (
      <ConfigureUsers
        availableExtensions={getAvailableExtensions}
        callingPlans={callingPlans}
        callingPlansData={callingPlansData}
        configUsers={configUsers}
        options={options}
        phones={phones}
        rowKeys={rowKeys}
        setPhones={setPhones}
        updateConfigUsers={updateConfigUsers}
        scrollObject={{ y: 308 }}
      />
    )
  }

  const renderVoiceUserCheckout = () => {
    return (
      <VoiceUserCheckout
        callingPlans={cloneDeep(callingPlans)}
        phones={phones}
        voiceUsers={cloneDeep(configUsers)}
        enableCheckoutConfirm={enableCheckoutConfirm}
        scrollObjectSmall={{ y: 190 }}
        scrollObjectBig={{ y: 150 }}
      />
    )
  }

  const pages = [
    {
      title: translations.select_users[language],
      component: () => renderSelectUsers(),
    },
    {
      title: translations.configure_users[language],
      component: () => renderConfigureUsers(),
    },
    {
      title: translations.checkout[language],
      component: () => renderVoiceUserCheckout(),
    },
  ]

  const moveStepForward = () => {
    setCurrentStep((currentStep) => currentStep + 1)
  }

  const moveStepBackward = () => {
    setCurrentStep((currentStep) => currentStep - 1)
  }

  const moveToCheckoutStep = () => {
    const activeRows = cloneDeep(configUsers)
    const proceedObj = canProceed(activeRows, existingUsers)

    if (proceedObj.proceed) {
      const plans = []

      activeRows.forEach((row) => {
        row.callingPlan.data.length &&
          row.callingPlan.data.forEach((item) => {
            plans.push(item)
          })
      })

      setCurrentStep(2)
      setCallingPlans(plans)
    } else {
      message.warning(
        `${proceedObj.phonenumber} ${translations.has_more_than_one_empty_extension[language]}`
      )
    }
  }

  const doneButton = useMemo(() => {
    return (
      <PttButton
        disabled={isContainsError(cloneDeep(configUsers))}
        onClick={moveToCheckoutStep}
        type="primary"
      >
        {translations.next[language]}
      </PttButton>
    )
  }, [configUsers, configLoad])

  const addPhoneNumbers = () => {
    const phoneNumbers = phones.map((item) => {
      if (item.skuId) {
        return {
          geo: item.data.geo,
          price: item.data.price.usd.monthly_amount,
          skuId: item.skuId,
          id: item.id,
          didId: item.didId,
        }
      } else {
        return { id: item.id }
      }
    })

    setPhoneCheckOutBody({
      action: 'PHONE_NUMBER_CHECKOUT',
      data: {
        chargeAmount: phoneAmount,
        phoneNumbers: phoneNumbers,
      },
    })
  }

  const getFooter = () => {
    if (currentStep === 0) {
      return (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <PttButton
            disabled={!rowKeys.length}
            onClick={moveStepForward}
            type="primary"
          >
            {translations.next[language]}
          </PttButton>
        </div>
      )
    } else if (currentStep === 1) {
      return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button className="back-button-style" onClick={moveStepBackward}>
            {translations.back[language]}
          </Button>
          {doneButton}
        </div>
      )
    } else if (currentStep === 2) {
      return (
        <div>
          <div className="stepmodal-footer">
            <Button
              className="back-button-style"
              disabled={checkoutLoader}
              onClick={() => {
                moveStepBackward()
              }}
            >
              {translations.back[language]}
            </Button>
            <PttButton
              onClick={() => {
                setCheckoutLoader(true)
                if (phones.length) {
                  addPhoneNumbers()
                } else {
                  makeVoiceUsers()
                }
              }}
              type="primary"
              disabled={!checkoutConfirm || checkoutLoader}
              loading={checkoutLoader}
            >
              {translations.confirm[language]}
            </PttButton>
          </div>
        </div>
      )
    }
  }

  const exitHandler = () => {
    setCurrentStep(0)
    setTableLoading(false)
    setRefreshLoading(false)
    setListRows([])
    setRowKeys([])
    setPhones([])
    setOptions([])
    setConfigLoad(false)
    setAccessToken('')
    setUpdateData('')
    onClose()
    setCheckoutLoader(false)
  }

  return (
    <ModalLayout
      visible={visiblity}
      onCancel={exitHandler}
      maskClosable={false}
    >
      <Header>{translations.add_users[language]}</Header>
      <Body>
        <div style={{ height: 400, minWidth: 300 }}>
          <div style={{ height: 40, marginBottom: 10 }}>
            <Step steps={pages} current={currentStep} />
          </div>
          <div style={{ height: 'calc(100% - 50px)' }}>
            {pages[currentStep].component()}
          </div>
        </div>
      </Body>
      <Footer>{getFooter()}</Footer>
    </ModalLayout>
  )
}

export default AddUser
