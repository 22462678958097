// @flow
import React, { useState, useEffect, useMemo } from 'react'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import ModalLayout, { Header, Body, Footer } from '../Popup/popup'
import { Radio, message, Spin, Alert } from 'antd'
import useCustomFetch from '../../Hooks/UseCustomFetch'
import { CUSTOM_ACTION_URL, EUROPIAN_UNION_COUNTRIES } from '../../utils/constants'
import { connect } from 'react-redux'
import { LoadingOutlined } from '@ant-design/icons'
import { convertToDollar } from '../../utils/helpers'

type Props = {
  handlePopupClose: (any) => void,
  popupState: boolean,
  getCustomerPaymentSuccList: (any) => mixed,
  userStripeData: Object,
  getPaymentMethods: (any) => mixed,
  topUpAmount: number,
  translations: Object,
  language: string,
  accountSettings: Object
}

// const messages = {
//   failed: 'Oops! something went wrong please try again later',
//   succeeded: 'Payment done successfully!',
// }

const MakePaymentModal = (props: Props) => {
  const {
    handlePopupClose,
    popupState,
    getCustomerPaymentSuccList,
    userStripeData,
    getPaymentMethods,
    topUpAmount = 0,
    translations,
    language,
    accountSettings
  } = props

  const [credit, setCredit] = useState('')
  const [loader, setLoader] = useState(false)
  const [error, setError] = useState(null)
  const [disabled, setDisabled] = useState(true)
  const [isCardValid, setIsCardValid] = useState(false)
  const [name, setName] = useState('')
  const [amountToPay, setAmountToPay] = useState()
  const [isVatApplied, setIsVatApplied] = useState(false)
  const [vatAmount, setVatAmount] = useState(0)
  const stripe = useStripe()
  const elements = useElements()

  /* Create payment intent */
  const [createPaymentIntentApi] = useCustomFetch(
    `${CUSTOM_ACTION_URL}/payment`,
    'PAYMENT',
    false,
    JSON.stringify({
      action: 'CREATE_PAYMENT_INTENTS_WITHOUT_CARD',
      data: {
        amount: amountToPay,
        customerId: userStripeData.stripe_id,
        vatAmount: vatAmount,
        vat_number: (accountSettings?.data?.vatId_verification_status === 'verified' &&
                    accountSettings?.data?.vat_number) ? accountSettings.data.vat_number : '',
        country: accountSettings?.data?.countryCode ? accountSettings.data.countryCode : '',
        email: accountSettings?.data?.billing_email ? accountSettings.data.billing_email : ''
      },
    })
  )

  useEffect(() => {
    let amtToPay = Number((topUpAmount || Number(credit) * 100).toFixed(0)) || 0

    if (amtToPay && accountSettings?.data) {
      const isEuropianUnionCountry = EUROPIAN_UNION_COUNTRIES.some((country) => country === accountSettings.data.countryCode)

      if (!isEuropianUnionCountry ||
        ((accountSettings.data.countryCode === 'ES' && accountSettings.data.spanish_tax_exempt) ||
          (isEuropianUnionCountry && accountSettings.data.countryCode !== 'ES' &&
            accountSettings.data.tax_id && accountSettings.data.vatId_verification_status === 'verified'))) {
        setIsVatApplied(false)
      } else {
        const vatAmt = Number(((amtToPay * 21) / 100).toFixed(0))

        setVatAmount(vatAmt)
        amtToPay = amtToPay + vatAmt
        setIsVatApplied(true)
      }
    }
    setAmountToPay(Number(amtToPay.toFixed(0)))
  }, [topUpAmount, credit])

  useEffect(() => {
    if (
      createPaymentIntentApi.response &&
      createPaymentIntentApi.response.status &&
      createPaymentIntentApi.response.response.clientSecret
    ) {
      confirmCardPayment(createPaymentIntentApi.response.response.clientSecret)
    } else if (
      createPaymentIntentApi.response &&
      createPaymentIntentApi.response.status == false
    ) {
      message.error(translations.oops_something_went_wrong[language])
    }
  }, [createPaymentIntentApi.response])

  const confirmCardPayment = async (clientSecret) => {
    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: name,
        },
      },
    })

    // eslint-disable-next-line no-console
    console.log('[Payment]', payload)

    if (payload.error) {
      message.error(payload.error.message || translations.oops_something_went_wrong[language])
      // setError(`Payment failed ${payload.error.message}`)
      setLoader(false)
    } else {
      setError(null)
      setLoader(false)
      handleSuccess()
    }
  }

  const useOptions = () => {
    // const fontSize = useResponsiveFontSize()
    const options = useMemo(() => ({
      hidePostalCode: true,
      style: {
        base: {
          fontSize: 'inherit',
          color: 'rgba(0, 0, 0, 0.65)',
          letterSpacing: '0.025em',
          fontFamily: 'inherit',
          '::placeholder': {
            color: '#bfbfbf',
          },
          invalid: {
            color: '#9e2146',
          },
        },
      },
    }))

    return options
  }

  const options = useOptions()

  const handleCreditSelect = (event) => {
    setCredit(event.target.value)
    if (isCardValid && name && event.target.value) {
      setDisabled(null)
    } else {
      setDisabled(true)
    }
    // validateData()
  }

  const handleNameChange = (event) => {
    setName(event.target.value)
    if (isCardValid && (topUpAmount || credit) && event.target.value) {
      setDisabled(null)
    } else {
      setDisabled(true)
    }
    // validateData()
  }

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setIsCardValid(event.complete)
    if (event.complete && (topUpAmount || credit) && name) {
      setDisabled(null)
    } else {
      setDisabled(true)
    }
    setError(event.error ? event.error.message : '')
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    setLoader(true)

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    createPaymentIntentApi.setRefetch(true)
  }

  const handleSuccess = () => {
    message.success(translations.payment_done[language])
    getCustomerPaymentSuccList()
    getPaymentMethods()
    exitHandler()
  }

  const getBody = () => {
    return (
      <div>
        {error && <div className="err-msg add-cred-err">{error}</div>}
        <form
          className="make-payment-form"
          onSubmit={handleSubmit}
          id="make-payment-form"
        >
          <label>
            {translations.name_on_card[language]}
            <input
              className="StripeElement name-on-card"
              onChange={handleNameChange}
              placeholder={translations.john_doe[language]}
              value={name}
            ></input>
          </label>
          <label>
            {translations.card_details[language]}
            <CardElement options={options} onChange={handleChange} />
          </label>
          <div className="field-label">{translations.amount[language]}</div>
          {topUpAmount !== 0 && (
            <input
              className="StripeElement amount-to-pay"
              value={`$${convertToDollar(topUpAmount)}`}
              readOnly
            ></input>
          )}
          {!topUpAmount && (
            <Radio.Group
              className="add-credit"
              value={credit}
              onChange={handleCreditSelect}
            >
              <Radio.Button value="10">$10</Radio.Button>
              <Radio.Button value="50">$50</Radio.Button>
              <Radio.Button value="200">$200</Radio.Button>
              <Radio.Button value="500">$500</Radio.Button>
              <Radio.Button value="1000">$1000</Radio.Button>
            </Radio.Group>
          )}
        </form>
      </div>
    )
  }

  const getFooter = () => {
    return (
      <div style={{ display: isVatApplied? 'flex' : '' }}>
        {/* {isVatApplied && <div className='vat-info'>{translations.vat_info[language]}</div>} */}
        {isVatApplied && <Alert
          message={translations.vat_info[language]}
          type="info"
          showIcon
        // style={{width: '75%'}}
        />}
        <button
          type="submit"
          form="make-payment-form"
          disabled={!stripe || loader || disabled}
          className="ant-btn primary-button ant-btn-primary top-up-btn"
        >
          {loader && (
            <Spin
              indicator={
                <LoadingOutlined
                  style={{ fontSize: 14, color: '#bfbfbf', marginRight: '6px' }}
                />
              }
            />
          )}
          {props.translations.pay[props.language]}
        </button>
      </div>
    )
  }

  const exitHandler = () => {
    setName('')
    setCredit('')
    /* setAmountToPay()
    setIsVatApplied(false) */
    handlePopupClose()
    setDisabled(true)
    setLoader(false)
  }

  return (
    <ModalLayout
      visible={popupState}
      onCancel={exitHandler}
      className="make-payment-modal"
      destroyOnClose={true}
    >
      <Header>{translations.add_credit[language]}</Header>
      <Body>{getBody()}</Body>
      <Footer>{getFooter()}</Footer>
    </ModalLayout>
  )
}

const mapsStateToProps = (state) => {
  return {
    userStripeData: state.sampleReducer.userStripeData || {},
    translations: state.sampleReducer.translations || {},
    language: state.sampleReducer.language,
    accountSettings: state.sampleReducer.accountSettings || {}
  }
}

export default connect(mapsStateToProps, null)(MakePaymentModal)
