const settingReducer = (
  state = {
    fetchDetails: true,
    fetchSettings: true,
    hasDetails: null,
    initailSettingsLoading: true,
    triggerSettingsSave: false,
    settingsSavePostUrl: '/settings',
    settingsSaveRedirect: false,
    companySettingsChanged: false,
    personalSettingsChanged: false,
    updatedState: ''
  },
  action
) => {
  switch (action.type) {
    case 'setFetchDetails':
      return { ...state, fetchDetails: action.fetchDetails }

    case 'setFetchSettings':
      return { ...state, fetchSettings: action.fetchSettings }

    case 'setHasDetails':
      return { ...state, hasDetails: action.hasDetails }

    case 'setInitalSettingsLoading':
      return { ...state, initailSettingsLoading: action.initailSettingsLoading }

    case 'setTriggerSettingsSave':
      return { ...state, triggerSettingsSave: action.triggerSettingsSave }

    case 'setSettingsSavePostUrl':
      return { ...state, settingsSavePostUrl: action.settingsSavePostUrl }

    case 'setSettingsSaveRedirect':
      return { ...state, settingsSaveRedirect: action.settingsSaveRedirect }

    case 'setPersonalSettingsChanged':
      return {
        ...state,
        personalSettingsChanged: action.personalSettingsChanged,
      }

    case 'setCompanySettingsChanged':
      return { ...state, companySettingsChanged: action.companySettingsChanged }

      case 'setUpdatedState':
        return { ...state, updatedState: action.updatedState }

    default:
      return state
  }
}

export default settingReducer
