import React, { useState } from 'react'
import { message, Upload } from 'antd'
import { CloudUploadOutlined } from '@ant-design/icons'
import Spinner from '../../../Spinner/spinner'
import {
  APIURL,
  API_MANAGER_KEY,
  ID_TOKEN,
  TOKEN_TYPE,
} from '../../../utils/constants'
import { getItem } from '../../../utils/storage'
import './customdropzone.css'
import axios from 'axios'

const audioApiUrl = `${APIURL}/audiofile?sync=yes`

const AudioUploader = ({ doneCb, translations = {}, language = '' }) => {
  const [loading, setLoading] = useState(false)

  const errorHandler = () => {
    setLoading(false)
    message.error(translations.error_uploading_audio[language])
  }

  const customReqHandler = (response) => {
    setTimeout(() => {
      response.file.type.split('/')[0] === 'audio'
        ? response.onSuccess('ok')
        : message.error(translations.accept_audio_files_only[language])
    }, 0)
  }

  const successHandler = (name, id) => {
    setLoading(false)
    doneCb([
      { key: 'fileName', value: name },
      { key: 'fileId', value: id },
    ])
  }

  const draggerprops = {
    accept: 'audio/*',
    customRequest: customReqHandler,
    showUploadList: false,
    onChange(info) {
      if (info.file.status === 'done') {
        setLoading(true)
        fetch(audioApiUrl, {
          headers: {
            Authorization: `${getItem(TOKEN_TYPE)} ${getItem(ID_TOKEN)}`,
            'Content-Type': 'application/json',
            'x-api-key': API_MANAGER_KEY,
          },
          method: 'PUT',
          body: JSON.stringify({ filename: info.file.name, description: '' }),
        })
          .then((res) => res.json())
          .then((res) => {
            if (
              res.result === true &&
              res.response.items.length &&
              res.response.items[0].action === 'created'
            ) {
              const id = res.response.items[0].id
              const data = res.response.items[0].data
              const { fields } = data
              const fd = new FormData()

              fd.append('key', fields.key)
              fd.append('AWSAccessKeyId', fields.AWSAccessKeyId)
              fd.append('x-amz-security-token', fields['x-amz-security-token'])
              fd.append('policy', fields.policy)
              fd.append('signature', fields.signature)
              fd.append('file', info.file.originFileObj)

              axios({
                method: 'post',
                url: data.url,
                data: fd,
                headers: { 'Content-Type': 'multipart/form-data' },
              }).then((res) => {
                res.status === 204
                  ? successHandler(info.file.name, id)
                  : errorHandler()
              })
            } else {
              errorHandler()
            }
          })
          .catch(() => errorHandler())
      }
    },
  }

  return loading ? (
    <div className="cdz-spinner-style">
      <Spinner />
    </div>
  ) : (
    <Upload.Dragger {...draggerprops}>
      <p className="ant-upload-drag-icon">
        <CloudUploadOutlined />
      </p>
      <p className="ant-upload-text">
        {translations.upload_file_info[language]}
      </p>
    </Upload.Dragger>
  )
}

export default AudioUploader
