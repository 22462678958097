// @flow
/* eslint-disable react/prop-types */

import React, { Fragment } from 'react'
import { Select } from 'antd'
import { useSelector } from 'react-redux'
import { FULL_ACCESS, READ_ONLY, NO_ACCESS } from '../../utils/constants'

const AccessDropDown = ({
  changeCallback,
  dropDownStyle = { width: '100%' },
  headerStyle = { margin: '5px 0px' },
  heading,
  value,
}) => {
  const { language, translations, accessUsersPermission } = useSelector(
    (state) => {
      return {
        language: state.sampleReducer.language,
        translations: state.sampleReducer.translations,
        accessUsersPermission: state.permissionReducer.accessUsersPermission,
      }
    }
  )

  const getValue = (value) => {
    if (value === FULL_ACCESS) {
      return translations.full_access[language]
    } else if (value === READ_ONLY) {
      return translations.read_only[language]
    } else if (value === NO_ACCESS) {
      return translations.no_access[language]
    } else {
      return ''
    }
  }

  const renderBox = () => {
    if (accessUsersPermission === FULL_ACCESS) {
      return (
        <Select value={value} style={dropDownStyle} onChange={changeCallback}>
          <Select.Option value={FULL_ACCESS}>
            {translations.full_access[language]}
          </Select.Option>
          <Select.Option value={READ_ONLY}>
            {translations.read_only[language]}
          </Select.Option>
          <Select.Option value={NO_ACCESS}>
            {translations.no_access[language]}
          </Select.Option>
        </Select>
      )
    } else {
      return (
        <div
          style={{
            marginBottom: 5,
            padding: '4px 11px',
            border: '1px solid lightgrey',
            height: 32,
            width: '97%',
          }}
        >
          {getValue(value)}
        </div>
      )
    }
  }

  return (
    <Fragment>
      <div style={headerStyle}>{heading}</div>
      {renderBox()}
    </Fragment>
  )
}

export default AccessDropDown
