import React from 'react'
import { useSelector } from 'react-redux'
import Advanced from '../components/Advance/AdvancedView'
import PttLayout from '../components/Home/PttHome/PttLayout'
import { getTranslatedText } from '../utils/translations'

function AdvancePage() {
  const { language, translations } = useSelector(({ sampleReducer }) => ({
    language: sampleReducer.language || 'en',
    translations: sampleReducer.translations || {},
  }))

  return (
    <PttLayout
      currentkey="7"
      title={getTranslatedText(translations, language, 'advanced')}
    >
      <Advanced />
    </PttLayout>
  )
}

export default AdvancePage
