const billingReducer = (
  state = {
    paymentMethods: undefined,
    creditList: [],
    paymentSucceededEventData: {},
    creditChargeList: [],
    transPaginationType: 'next',
    transPageSize: 100,
    transQueryUrl: `?sort=create:desc,_id&size=${100}`,
    transPaginationArray: [],
    transRecordCount: 0,
    transPrevCount: 0,
    transPageNumber: -1,
  },
  action
) => {
  switch (action.type) {
    case 'setPaymentList':
      return { ...state, paymentMethods: action.data }

/*     case 'setCreditList':
      return { ...state, creditList: action.data } */

    case 'setPaymentSucceededEventData':
      return { ...state, paymentSucceededEventData: action.data }

    case 'setCreditChargeList':
      return {
        ...state,
        creditChargeList: action.data.items,
        transRecordCount: state.transPaginationType === 'next' ? (state.transRecordCount + action.data?.count) :
                                                        (state.transRecordCount - state.transPrevCount),
        transPrevCount: action.data?.count,
        totalTransRecordCount: action.data?.total?.value,
        transPaginationArray: ((state.transPaginationType === 'next' &&
          (action.data && action.data.sort && state.transPaginationArray.some((page) => JSON.stringify(page) === JSON.stringify(action.data.sort)))) ||
          state.transPaginationType !== 'next') ? [...state.transPaginationArray] : [...state.transPaginationArray, action.data.sort],
      }

    case 'setOnlyCreditChargeList':
      return { ...state, creditChargeList: action.data }

    case 'setTransactionpaginationType':
      return { ...state, transPaginationType: action.data }

    case 'setTransactionPageSize':
      return { ...state, transPageSize: action.data }

    case 'setTransactionQueryUrl':
      return { ...state, transQueryUrl: action.data }

    case 'resetTransactionRecordCount':
      return {
        ...state, transRecordCount: action.data, transPageNumber: -1,
        transPaginationArray: [], transPrevCount: 0, transPaginationType: 'next'
      }

    case 'setTransactionRecordCount':
      return { ...state, transRecordCount: action.data }

    case 'setTransactionTotalRecordCount':
      return { ...state, totalTransRecordCount: action.data }

    case 'setTransactionpaginationArray':
      return { ...state, transPaginationArray: action.data }

    case 'setTransactionPageNumber':
      return { ...state, transPageNumber: action.data }

    default:
      return state
  }
}

export default billingReducer
