// @flow

import React, { useMemo, useState } from 'react'
import { PlusCircleOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { Form, Input, Select, Table, Tooltip, Button } from 'antd'
import Spinner from '../../../Spinner/spinner'
import { getTickedRows } from '../../MsTeams/msteamsutils'
import PttButton from '../../CustomButton/PttButton'
import AddPhoneNumberMsTeams from './AddPhoneNumberMsTeams'
import { keyDownHandler } from '../../../utils/helpers'
import { pasteHandler } from '../../../utils/helpers'
import { getNewArray } from '../../../utils/helpers'
import AddCallingPlanMsTeams from './AddCallingPlanMsTeams'

type Props = {
  availableExtensions: $ReadOnlyArray<*>,
  callingPlansData: $ReadOnlyArray<*>,
  callingPlans: $ReadOnlyArray<*>,
  configUsers: $ReadOnlyArray<*>,
  loading?: boolean,
  options: $ReadOnlyArray<*>,
  phones: $ReadOnlyArray<*>,
  rowKeys: $ReadOnlyArray<*>,
  setPhones: (any) => mixed,
  scrollObject?: Object,
  updateConfigUsers: (any) => mixed,
}

const ConfigureUsers = ({
  availableExtensions,
  callingPlans,
  callingPlansData,
  configUsers,
  loading = false,
  options,
  phones,
  rowKeys,
  scrollObject = { x: 1000, y: '64vh' },
  setPhones,
  updateConfigUsers,
}: Props) => {
  const { language, translations } = useSelector(({ sampleReducer }) => ({
    language: sampleReducer.language || 'en',
    translations: sampleReducer.translations || {},
  }))

  const [editRow, setEditRow] = useState('')
  const [phoneModel, setPhoneModel] = useState(false)
  const [callingPlanModel, setCallingPlanModel] = useState(false)
  const [callingPlanRecord, setCallingPlanRecord] = useState({})

  const renderPhoneHeader = () => {
    return (
      <div className="ConfigUsers__ColumnHeader">
        <span>{translations.phone_number_label[language]}</span>
        <PttButton
          size="small"
          onClick={() => setPhoneModel(true)}
          type="primary"
        >
          {translations.add[language]}
        </PttButton>
      </div>
    )
  }

  const onCallingPlanAdd = (record) => {
    setCallingPlanModel(true)
    setCallingPlanRecord(record)
  }

  const callingPlanField = (record) => {
    const keys = record.callingPlan.data.map(
      (item) => item.data.label[language]
    )

    return (
      <div className="ConfigUsers__ColumnHeader">
        <Select
          className="select-style"
          onDeselect={(value) => handleDeselect(value, record)}
          style={{ marginRight: 10 }}
          placeholder={translations.select_a_callingplan[language]}
          value={keys.length ? keys : undefined}
          open={false}
          mode="multiple"
          showArrow={false}
          /* keeping undefined if empty beacuse to show place holder */
          /* https://stackoverflow.com/questions/45546300/antd-design-select-placeholder-issues */
        />
        <Tooltip title={translations.add_calling_plan[language]}>
          <Button
            shape="circle"
            icon={<PlusCircleOutlined />}
            onClick={() => onCallingPlanAdd(record)}
          />
        </Tooltip>
      </div>
    )
  }

  const contactUsersTable = useMemo(() => {
    const columns = [
      {
        defaultSortOrder: 'ascend',
        dataIndex: 'displayName',
        key: 'displayName',
        sorter: (a, b) => {
          const wordA = a.displayName.toLowerCase()
          const wordB = b.displayName.toLowerCase()

          if (wordA < wordB) {
            return -1
          } else if (wordA > wordB) {
            return 1
          } else {
            return 0
          }
        },
        title: translations.display_name[language],
        width: '25%',
      },
      {
        title: renderPhoneHeader(),
        width: '25%',
        render: (text, record) => phoneNumberField(record),
      },
      {
        title: translations.extension[language],
        width: '25%',
        render: (text, record) => extensionField(record),
      },
      {
        title: translations.calling_plan[language],
        width: '25%',
        render: (text, record) => callingPlanField(record),
      },
    ]

    return (
      <Table
        columns={columns}
        dataSource={getTickedRows(configUsers, rowKeys)}
        pagination={false}
        scroll={scrollObject}
        showHeader={true}
        showSorterTooltip={false}
        size="small"
        style={{ height: '100%' }}
      />
    )
  }, [configUsers, rowKeys, options, phones, callingPlans, scrollObject])

  const renderConfigOptions = () => {
    return options.map((option) => {
      if (option.chosen) {
        return <React.Fragment />
      } else {
        return <Select.Option key={option.id}>{option.id}</Select.Option>
      }
    })
  }

  const phonenumberHelp = (phonenumberError) => {
    return phonenumberError.isError
      ? translations.select_phone_number_error[language]
      : ''
  }

  const phonenumberStatus = (phonenumberError) => {
    if (phonenumberError.type === 'none') {
      return ''
    }

    return phonenumberError.isError ? 'error' : 'success'
  }

  const phoneNumberField = (record) => {
    const { phonenumber, phonenumberError } = record

    return (
      <Form.Item
        hasFeedback
        help={phonenumberHelp(phonenumberError)}
        validateStatus={phonenumberStatus(phonenumberError)}
      >
        <Select
          allowClear={phonenumber.length !== 0}
          className="select-style"
          onChange={(value = '') => updateRows(String(value), record)}
          placeholder={translations.select_a_phone_pumber[language]}
          value={phonenumber.length ? phonenumber : undefined}
          /* keeping undefined if empty beacuse to show place holder */
          /* https://stackoverflow.com/questions/45546300/antd-design-select-placeholder-issues */
        >
          {renderConfigOptions()}
        </Select>
      </Form.Item>
    )
  }

  const extensionHelp = (extensionError) => {
    return extensionError.isError ? translations.extension_in_use[language] : ''
  }

  const extensionStatus = (extensionError) => {
    if (extensionError.type === 'none') {
      return ''
    }

    return extensionError.isError ? 'error' : 'success'
  }

  const extensionField = (record) => {
    const { extension, extensionError, key } = record

    return (
      <Form.Item
        hasFeedback
        help={extensionHelp(extensionError)}
        validateStatus={extensionStatus(extensionError)}
      >
        <Tooltip
          trigger={['focus']}
          title={extension.length ? '' : 'Input a number'}
          placement="topLeft"
        >
          <Input
            autoFocus={editRow === key}
            onBlur={() => setEditRow('')}
            onChange={(event) => valueHandler(event.target.value, record)}
            onKeyDown={(event) => keyDownHandler(event, 'allowOnlyNumbers')}
            onPaste={(event) => pasteHandler(event, 'allowOnlyNumbers')}
            placeholder={translations.extension_number[language]}
            value={extension}
          />
        </Tooltip>
      </Form.Item>
    )
  }

  const getExistStatus = (extension, rows) =>
    !rows.some((row) => row.extension === extension) &&
    !availableExtensions.some((ext) => ext === extension)
      ? { isError: false, type: '' }
      : { isError: true, type: 'exist' }

  const getErrorObject = (value) =>
    value.length
      ? getExistStatus(value, configUsers)
      : { isError: false, type: 'none' }

  const valueHandler = (value, record) => {
    let existAryay = []
    let existValue = ''
    const list = getNewArray(configUsers)

    list.forEach((row) => {
      if (row.key === record.key) {
        if (record.key === row.key && row.extension.length) {
          existValue = row.extension
          existAryay = list.filter(
            ({ extension, key }) =>
              extension === row.extension && key !== row.key
          )
        }
        row.extension = value
        row.extensionError = getErrorObject(value)
      }
    })

    if (existAryay.length) {
      list.forEach((row) => {
        if (row.key === existAryay[0].key) {
          const excludedData = list.filter(
            ({ extension }) => extension !== existValue
          )

          row.extensionError = getExistStatus(row.extension, excludedData)
        }
      })
    }

    setEditRow(record.key)
    updateConfigUsers(list)
  }

  const updateRows = (value = '', record) => {
    const list = getNewArray(configUsers)

    list.every((row) => {
      if (row.key === record.key) {
        row.phonenumber = value
        row.phonenumberError = value.length
          ? { isError: false, type: '' }
          : { isError: true, type: 'req' }
      }

      return row.key !== record.key
    })

    updateConfigUsers(list)
  }

  const updateCallingPlan = (record, SelectedData = {}) => {
    const list = getNewArray(configUsers)

    list.forEach((row) => {
      if (row.key === record.key) {
        const previousData = JSON.parse(JSON.stringify(row.callingPlan.data))

        SelectedData.data = previousData.concat(SelectedData.data)
        row.callingPlan = SelectedData
      }
    })

    updateConfigUsers(list)
  }

  const handleDeselect = (value, record) => {
    const list = getNewArray(configUsers)

    list.forEach((row) => {
      if (row.key === record.key) {
        row.callingPlan.data = row.callingPlan.data.filter(
          (item) => item.data.label[language] !== value
        )
      }
    })

    updateConfigUsers(list)
  }

  const phoneCancelCallback = () => {
    setPhoneModel(false)
  }

  const phoneSuccessCallback = (phoneNumbers) => {
    setPhoneModel(false)
    setPhones(JSON.parse(JSON.stringify(phones)).concat(phoneNumbers))
  }

  const callingPlanSuccessCallBack = (record, selectedData) => {
    setCallingPlanModel(false)
    updateCallingPlan(record, selectedData)
  }

  const loader = () => {
    return (
      <div className="align-center full-height">
        <Spinner spinning={true} />
      </div>
    )
  }

  const callingPlanExitHandler = () => {
    setCallingPlanModel(false)
  }

  return (
    <React.Fragment>
      {loading ? loader() : contactUsersTable}
      <AddPhoneNumberMsTeams
        cancelCallback={phoneCancelCallback}
        successCallback={phoneSuccessCallback}
        visiblity={phoneModel}
        existingIds={options.map((phone) => phone.id)}
      />
      <AddCallingPlanMsTeams
        record={callingPlanRecord}
        callingPlansData={callingPlansData}
        exitHandler={callingPlanExitHandler}
        successCallBack={callingPlanSuccessCallBack}
        visiblity={callingPlanModel}
      />
    </React.Fragment>
  )
}

export default ConfigureUsers
