const dev = {
  apiBaseUrl: 'https://dev-api.plug2teams.com/',
  apiManagerApiKey: 'DiqCXtNops1LbrISxsCHA7vN7hRGF1QS6nKMornU',
  authApiKey: 'g9UkoRMEtu73WHBpjM9Ry7fNNSi81Inh5Crs6eL6',
  connectorApiKey: 'ngrC9Tvnd41vXz3qSxjRP5gMfOxQMC9192vcc9JD',
  imageUrl: 'https://cf-ptt-publicassets-dev.s3-eu-west-1.amazonaws.com/',
  name: 'dev',
  paymentApiKey: 'K1ejHX3SKl6DAa8hxfzE88s741vjzPPz8ngivY79',
  signupApiKey: '290jD1GqWl1nL7fTBhnLA6unqreSaG6m8NW6MA4O',
  socketEndPoint: 'wss://9nago85p9b.execute-api.eu-west-1.amazonaws.com/v1',
  stripe_publishable_key:
    'pk_test_51H84xEAjNN0R99fNrpik7Kc3pQ22LNySDfUR5FEX42ceoUsd4kNm3qoKRqnJRGWpfLFMJbCeEA30zIj9w2xgRIgw00DUGqH7oy',
}

export default dev
