/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
// @flow

import React, { useEffect, useState, useMemo } from 'react'
import PttButton from '../CustomButton/PttButton'
import MemoTable from '../MemoTable/memotable'
import AddCreditModal from './AddCreditModal'
import { CUSTOM_ACTION_URL, FULL_ACCESS } from '../../utils/constants'
import useCustomFetch from '../../Hooks/UseCustomFetch'
import Spinner from '../../Spinner/spinner'
import { connect } from 'react-redux'
import MakePaymentModal from './MakePaymentModal'
import { convertToDollar, removeURLParameter } from '../../utils/helpers'
import { EyeOutlined, RightOutlined, LeftOutlined } from '@ant-design/icons'
import BillingInfoModal from './BillingInfoModal'
import Pagination from '../CustomComponents/Pagination'
import { bindActionCreators } from 'redux'
import {
  setCreditChargeList, setTransactionpaginationType, resetTransactionRecordCount,
  setTransactionPageNumber, setTransactionQueryUrl, setTransactionPageSize,
  setTransactionpaginationArray, setOnlyCreditChargeList, setTransactionRecordCount,
  setTransactionTotalRecordCount
} from '../../actions/billingAction'
import { Button } from 'antd'

type Props = {
  paymentMethods: Object,
  userStripeData: Object,
  getPaymentMethods: (any) => mixed,
  translations: Object,
  language: string,
  paymentSucceededEventData: Object,
  creditChargeList: Array,
  setCreditChargeList: (any) => mixed,
  transRecordCount: Number,
  totalTransRecordCount: Number,
  transPageNumber: Number,
  transPageSize: Number,
  setTransactionpaginationType: (any) => mixed,
  resetTransactionRecordCount: (any) => mixed,
  setTransactionPageNumber: (any) => mixed,
  setTransactionQueryUrl: (any) => mixed,
  transQueryUrl: String,
  transPaginationArray: Array,
  setTransactionPageSize: (any) => mixed,
  setTransactionpaginationArray: (any) => mixed,
  setOnlyCreditChargeList: (any) => mixed,
  setTransactionRecordCount: (any) => mixed,
  setTransactionTotalRecordCount: (any) => mixed
}

/* const link = `https://pay.stripe.com/receipts/acct_1H84xEAjNN0R99fN/ch_1Hk1c4AjNN0R99fNxDGHto6c/
rcpt_IKh05Lq7iT7B80w4bswGizDWkdTxSvJ` */

const Balance = (props: Props) => {
  const {
    paymentMethods,
    getPaymentMethods,
    translations,
    language,
    paymentSucceededEventData,
    creditChargeList,
    setCreditChargeList,
    transRecordCount,
    totalTransRecordCount,
    transPageNumber,
    transPageSize,
    setTransactionpaginationType,
    resetTransactionRecordCount,
    setTransactionPageNumber,
    transQueryUrl,
    transPaginationArray,
    setTransactionPageSize,
    setTransactionQueryUrl,
    /* setTransactionpaginationArray,
    setOnlyCreditChargeList,
    setTransactionRecordCount,
    setTransactionTotalRecordCount */
    billingPermission
  } = props
  const [popup1State, setPopup1State] = useState(false)
  const [popup2State, setPopup2State] = useState(false)
  /* need to remove */
  // eslint-disable-next-line no-unused-vars
  const [paymentSuccList, setPaymentSuccList] = useState([])
  const [balance, setBalance] = useState('0.00')
  // const [creditChargeList, setCreditChargeList] = useState([])
  const [billingInfoModal, setBillingInfoModal] = useState(false)
  const [billingInfo, setBillingInfo] = useState()
  const [transactionListLoader, setTransactionListLoader] = useState(false)
  const [disablePaginationOptions, setDisablePagination] = useState(false)

  const [getCreditAndChargeApi] = useCustomFetch(
    `${CUSTOM_ACTION_URL}/payment`,
    'PAYMENT',
    false,
    JSON.stringify({
      action: 'LIST_CHARGE_AND_CREDITS',
      data: {
        queryUrl: transQueryUrl
      },
    })
  )

  useEffect(() => {
    disablePagination()
    resetTransactionRecordCount(0)
    if (transQueryUrl.includes('search_after')) {
      const updatedQuery = removeURLParameter(transQueryUrl, 'search_after')

      setTransactionQueryUrl(updatedQuery)
    }
  }, [])

  const disablePagination = () => {
    setDisablePagination(true)
  }

  const enablePagination = () => {
    setDisablePagination(false)
  }

  useEffect(() => {
    if (paymentSucceededEventData && paymentSucceededEventData.account_id) {

      resetTransactionRecordCount(0)
      if (transQueryUrl.includes('search_after')) {
        const updatedQuery = removeURLParameter(transQueryUrl, 'search_after')

        setTransactionQueryUrl(updatedQuery)
      } else {
        console.log('transactionListLoader1: ', transactionListLoader)
        setTransactionListLoader(true)
        getCreditAndChargeApi.setRefetch(true)
      }

      /* if (transPageNumber === -1) {
        const tmpTransactionArr = [...creditChargeList]

        tmpTransactionArr.unshift(paymentSucceededEventData)
        tmpTransactionArr.pop()
        setOnlyCreditChargeList(tmpTransactionArr)

        const lastItem = tmpTransactionArr[tmpTransactionArr.length - 1]
        const tempPaginationArr = [...transPaginationArray]

        tempPaginationArr.splice(0, 1, [lastItem.updatedata.create.time, lastItem.id])
        // setTransactionTotalRecordCount(totalTransRecordCount + 1)
        setTransactionpaginationArray(tempPaginationArr)
        enablePagination()
      } else {
        setTransactionRecordCount(transRecordCount + 1)
      }

      setBalance(convertToDollar(paymentSucceededEventData.data.balance))
      setTransactionListLoader(false) */
    }
  }, [paymentSucceededEventData])

  useEffect(() => {
    if (transQueryUrl) {
      console.log('transQueryUrl: ', transQueryUrl)
      console.log('transactionListLoader2: ', transactionListLoader)
      disablePagination()
      setTransactionListLoader(true)
      getCreditAndChargeApi.setRefetch(true)
    }
  }, [transQueryUrl])

  useEffect(() => {
    if (
      getCreditAndChargeApi.response &&
      getCreditAndChargeApi.response.status &&
      getCreditAndChargeApi.response.response &&
      getCreditAndChargeApi.response.response.balance !== undefined
    ) {
      console.log('getCreditAndChargeApi: ', getCreditAndChargeApi.response)
      setCreditChargeList(
        getCreditAndChargeApi.response.response.transactionResponse
      )

      if (transPageNumber === -1) {
        const balance =
        convertToDollar(getCreditAndChargeApi.response.response.balance) ==
        '-0.00'
          ? 0
          : convertToDollar(getCreditAndChargeApi.response.response.balance)

        setBalance(balance)
      }
      console.log('getCreditAndChargeApi-1 ', transactionListLoader)
      enablePagination()
      setTransactionListLoader(false)
    }
  }, [getCreditAndChargeApi.response])

  useEffect(() => {
    console.log('transactionListLoader: ', transactionListLoader)
  }, [transactionListLoader])

  const handleOpenPopup = () => {
    if (paymentMethods && paymentMethods.length > 0) {
      setPopup1State(true)
    } else {
      setPopup2State(true)
    }
  }

  const handlePopupClose = () => {
    setPopup1State(false)
    setPopup2State(false)
  }

  const getCustomerPaymentSuccList = () => {
    console.log('transactionListLoader3: ', transactionListLoader)
    disablePagination()
    setTransactionListLoader(true)
  }

  const handleVoiceUserModalClose = () => {
    setBillingInfoModal(false)
  }

  const balanceTable = useMemo(() => {
    if (!creditChargeList && creditChargeList.length === 0) {
      return
    }

    creditChargeList.sort(function (x, y) {
      return x?.updatedata?.create?.time + y?.updatedata?.create?.time
    })

    const data = creditChargeList.map((data) => {
      const createdDate =
        data.updatedata && data.updatedata.create && data.updatedata.create.time
          ? new Date(data.updatedata.create.time * 1000)
          : ''

      if (data.data) {
        const payment_method =
          (data.data.detail && data.data.detail.payment_method_details) || {}

        return {
          key: data.id,
          date: createdDate ? createdDate : '',
          amount: `${
            Number(data.data.amount.toFixed(2)) > 0
              ? '+'
              : Number(data.data.amount.toFixed(2)) === 0
              ? ''
              : '-'
          }$${(data.data.amount.toString().replace('-', '') / 100).toFixed(2)}`,
          type:
            // translations[data.data.type][language] ||
            translations[data.data.description]
              ? translations[data.data.description][language]
              : data.data.description || '',
          paymentMethod:
            payment_method && payment_method.card
              ? payment_method.card.brand +
                ' ' +
                payment_method.card.funding +
                ' card ending with ' +
                payment_method.card.last4
              : data.type !== 'Amount credit' &&
                data.data.type !== 'SIGNUP_CREDIT'
              ? translations.from_balance[language]
              : '',
          balance: `${
            Number(data.data.balance.toFixed(2)) > 0
              ? '+'
              : Number(data.data.balance.toFixed(2)) === 0
              ? ''
              : '-'
          }$${(data.data.balance.toString().replace('-', '') / 100).toFixed(
            2
          )}`,
          details: data.data.detail,
          typeCode: data.data.description,
        }
      }
    })

    const showMoreDetails = (data, typeCode) => {
      setBillingInfoModal(true)
      if (typeCode === 'VOICE_USER' && data && data.user) {
        setBillingInfo({ data: data.user, typeCode })
      } else if (typeCode === 'PHONE_NUMBER' && data && data.phone) {
        setBillingInfo({ data: data.phone, typeCode })
      } else if (typeCode === 'TOPUP' && data && data.payment_method_details) {
        setBillingInfo({ data: data.payment_method_details, typeCode })
      } else if (typeCode === 'CALLING_PLAN' && data && data.callingPlan) {
        setBillingInfo({ data: data.callingPlan, typeCode })
      }
    }

    const columns = [
      {
        title: translations.date[language],
        dataIndex: 'date',
        width: '23%',
        render: (createdDate) => {
          const getFullDate = () => {
            const date = String(createdDate.getDate())

            return date.length === 1 ? `0${date}` : date
          }

          const getFullMonth = () => {
            const num = createdDate.getMonth() + 1
            const month = String(num)

            return month.length === 1 ? `0${month}` : month
          }

          return createdDate ? (
            <span
              title={
                getFullDate() +
                '/' +
                getFullMonth() +
                '/' +
                createdDate.getFullYear() +
                ' ' +
                (createdDate.getHours() > 9
                  ? createdDate.getHours()
                  : '0' + createdDate.getHours()) +
                ':' +
                (createdDate.getMinutes() > 9
                  ? createdDate.getMinutes()
                  : '0' + createdDate.getMinutes())
              }
            >
              {getFullDate() +
                '/' +
                getFullMonth() +
                '/' +
                createdDate.getFullYear() +
                ' '}
            </span>
          ) : (
            <></>
          )
        },
      },
      {
        title: translations.description[language],
        dataIndex: 'type',
        width: '32%',
      },
      {
        title: translations.amount[language],
        dataIndex: 'amount',
        width: '20%',
        align: 'right',
      },
      {
        title: translations.balance[language],
        dataIndex: 'balance',
        width: '20%',
        align: 'right',
      },
      {
        title: translations.more_actions[language],
        dataIndex: '',
        align: 'center',
        key: 'x',
        render: (text, record) => (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {record &&
              (record.typeCode === 'VOICE_USER' ||
                record.typeCode === 'PHONE_NUMBER' ||
                record.typeCode === 'CALLING_PLAN' ||
                record.typeCode === 'TOPUP') && (
                <EyeOutlined
                  onClick={() =>
                    showMoreDetails(record.details, record.typeCode)
                  }
                />
              )}
            {/* <a
              href={link}
              download="stripe.pdf"
              width="10px"
            /> */}
            {/* {(record.typeCode === 'TOPUP') &&
            <FilePdfOutlined></FilePdfOutlined>} */}
          </div>
        ),
        width: '10%',
      },
    ]

    return (
      <MemoTable
        columns={columns}
        dataSource={data}
        isSelectable={true}
        scrollObject={{ y: 'calc(100vh - 292px)' }}
      />
    )
  }, [creditChargeList])

  const handlePageChange = (type) => {
    const updatedQuery = removeURLParameter(transQueryUrl, 'search_after')

    setTransactionpaginationType(type)
    const pageNo = type === 'next' ? transPageNumber + 1 : transPageNumber - 1

    setTransactionPageNumber(pageNo)
    const url =  `${updatedQuery}${
      pageNo === -1 ? '' : `&search_after=${JSON.stringify(transPaginationArray[pageNo])}` }`

    console.log('transactionListLoader4: ', transactionListLoader)
    console.log('transQueryUrl1: ', updatedQuery)

    setTransactionQueryUrl(url)
  }

  /* const handlePageSizeChange = (size) => {
    if (size !== transPageSize) {
      resetTransactionRecordCount(0)
      let updatedQuery = removeURLParameter(transQueryUrl, 'size')

      updatedQuery = removeURLParameter(updatedQuery, 'search_after')
      const url = `${updatedQuery}&size=${size}`

      setTransactionQueryUrl(url)
      setTransactionPageSize(size)
      console.log('transactionListLoader5: ', transactionListLoader)
      console.log('transQueryUrl2: ', updatedQuery)
    }
  } */

  /* const getPaginationMenu = () => {
    return (
      <Menu>
        {PAGE_SIZE.map((size) => (
          <Menu.Item key={size} disabled={disablePaginationOptions}>
            <a onClick={() => handlePageSizeChange(size)}>
              {size} / {translations.page[language]}
            </a>
          </Menu.Item>
        ))}
      </Menu>
    )
  } */

  const getFooter = () => {
    return (
      <div className='custom-table-footer'>
        <Pagination
          currentRecord={transRecordCount}
          recordPerPage={transPageSize}
          totalRecord={totalTransRecordCount}
        />
        <Button
          disabled={
            // transPageNumber !== -1 ? false : true || disablePaginationOptions
            (transPageNumber === -1 || disablePaginationOptions) ? true : false
          }
          onClick={() => handlePageChange('prev')}
        >
          <LeftOutlined className='pagination-icon' />
        </Button>
        <Button
          className='next-btn'
          disabled={
            (creditChargeList.length === 0 || transRecordCount >= totalTransRecordCount || disablePaginationOptions)
              ? true
              : false
          }
          onClick={() => handlePageChange('next')}
        >
          <RightOutlined className='pagination-icon' />
        </Button>
        {/* <Dropdown overlay={getPaginationMenu()} placement='topCenter' disabled={transactionListLoader}>
          <Button style={{ marginRight: '5px' }}>
            {transPageSize} / {translations.page[language]}
          </Button>
        </Dropdown> */}
      </div>
    )
  }

  return (
    <div className="billing-main-cont">
      <div className="billing-utils-container">
        <div className="billing-utils-parent">
          <div className="balance-div">
            {translations.balance[language]}: <b>${balance}</b>
          </div>
          <PttButton
            onClick={handleOpenPopup}
            type="primary"
            disabled={
              paymentMethods === undefined || billingPermission !== FULL_ACCESS
            }
          >
            {translations.TOP_UP[language]}
          </PttButton>
          <AddCreditModal
            handlePopupClose={handlePopupClose}
            popupState={popup1State}
            paymentMethods={paymentMethods}
            getCustomerPaymentSuccList={getCustomerPaymentSuccList}
          />
          <MakePaymentModal
            handlePopupClose={handlePopupClose}
            popupState={popup2State}
            getCustomerPaymentSuccList={getCustomerPaymentSuccList}
            getPaymentMethods={getPaymentMethods}
          />
        </div>
      </div>
      <div className="billing-table-container">
        {/* <div className='phone-body'> */}
        {transactionListLoader ? (
          <div className="spinner-div">
            <Spinner spinning={true} />
          </div>
        ) : (
          <div className="billing-inner-body">
            <div className="billing-table-body">{balanceTable}</div>
          </div>
        )}
        {/* </div> */}
      </div>
      <div className="transaction-footer">{getFooter()}</div>

      <BillingInfoModal
        visiblity={billingInfoModal}
        billingInfo={billingInfo}
        handleVoiceUserModalClose={handleVoiceUserModalClose}
        onOkay={handleVoiceUserModalClose}
      />
    </div>
  )
}

const mapsStateToProps = (state) => {
  return {
    userStripeData: state.sampleReducer.userStripeData || {},
    paymentMethods: state.billingReducer.paymentMethods,
    translations: state.sampleReducer.translations || {},
    language: state.sampleReducer.language,
    paymentSucceededEventData: state.billingReducer.paymentSucceededEventData,
    creditChargeList: state.billingReducer.creditChargeList || [],
    transRecordCount: state.billingReducer.transRecordCount,
    totalTransRecordCount: state.billingReducer.totalTransRecordCount,
    transPageNumber: state.billingReducer.transPageNumber,
    transPageSize: state.billingReducer.transPageSize,
    transPaginationArray: state.billingReducer.transPaginationArray,
    transQueryUrl: state.billingReducer.transQueryUrl,
    billingPermission: state.permissionReducer.billingPermission,
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setCreditChargeList,
      setTransactionpaginationType,
      resetTransactionRecordCount,
      setTransactionPageNumber,
      setTransactionQueryUrl,
      setTransactionPageSize,
      setTransactionpaginationArray,
      setOnlyCreditChargeList,
      setTransactionRecordCount,
      setTransactionTotalRecordCount
    },
    dispatch
  )

export default connect(mapsStateToProps, mapDispatchToProps)(Balance)
