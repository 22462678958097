// @flow

import React from 'react'
import { Button, Dropdown, Menu } from 'antd'
import { MoreOutlined } from '@ant-design/icons'

import './moreactions.css'

type Props = {
  actionCallBack?: (action: string, record: Object) => mixed,
  contents?: $ReadOnlyArray<*>,
  disabled?: boolean,
  loading?: boolean,
  origin?: string,
  placement?: string,
  record?: Object,
  trigger?: $ReadOnlyArray<*>,
}

const MoreActions = (props: Props) => {
  const {
    actionCallBack,
    contents = [],
    disabled = false,
    loading = false,
    origin = '',
    placement = 'bottomCenter',
    record = {},
    trigger = ['click'],
  } = props

  const moreActionHandler = (event) => {
    event.stopPropagation()
  }

  const contentClickHandler = (action, record) => {
    if (actionCallBack) {
      actionCallBack(action, record)
    }
  }

  const menu = (
    <Menu>
      {contents.length &&
        contents.map((data) => (
          <Menu.Item
            className="rowmoreactionsoptions"
            key={data.text}
            onClick={() => contentClickHandler(data.text, record)}
          >
            {data.render && data.render()}
            {data.text}
          </Menu.Item>
        ))}
    </Menu>
  )

  return (
    <div className="rowmoreactionsdiv" onClick={moreActionHandler}>
      <Dropdown overlay={menu} placement={placement} trigger={trigger}>
        <Button shape="circle" icon={<MoreOutlined />} />
      </Dropdown>
    </div>
  )
}

export default MoreActions
