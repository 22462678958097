import React, { useState, useRef, useEffect } from 'react'
import ModalLayout, { Body, Header, Footer } from '../Popup/popup'
import { getTranslatedText } from '../../utils/translations'
import { Input, Checkbox, Button, Tooltip, Form, message } from 'antd'
import ReactFlagsSelect from 'react-flags-select'
import { useSelector } from 'react-redux'
import PttButton from '../CustomButton/PttButton'
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  ExclamationCircleTwoTone,
  LoadingOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons'
import { EUROPIAN_UNION_COUNTRIES, SIGNUP_API } from '../../utils/constants'
import useCustomFetch from '../../Hooks/UseCustomFetch'
import { isEmpty } from 'lodash'

type Props = {
  onClose: () => void,
  visiblity: boolean,
  refetchClients: () => mixed,
}

const mail = 'example@mail.com'

const AddClient = ({ onClose, visiblity, refetchClients }: Props) => {
  const flagRef = useRef(null)
  const {
    appProviderBrand,
    accountPlanId,
    language,
    translations,
  } = useSelector(({ sampleReducer }) => ({
    language: sampleReducer.language || 'en',
    translations: sampleReducer.translations || {},
    accountPlanId: sampleReducer.accountPlanId,
    appProviderBrand: sampleReducer.appProviderBrand,
  }))

  const [phoneNumber, setPhoneNumber] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [address, setAddress] = useState('')
  const [companyEmail, setCompanyEmail] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [countryCode, setCountryCode] = useState('')
  const [spanishTaxExempt, setSpanishTaxExempt] = useState(false)
  const [vatIdVerificationStatus, setVatIdVerificationStatus] = useState('')
  const [vatNo, setVatNo] = useState('')
  const [isTaxFieldEnabled, setTaxFieldEnabled] = useState(false)
  const [validationStatus, setValidationStatus] = useState('')
  const [loading, setLoading] = useState(false)

  const cleanup = () => {
    setPhoneNumber('')
    setPostalCode('')
    setAddress('')
    setCompanyEmail('')
    setCompanyName('')
    setCountryCode('')
    setSpanishTaxExempt(false)
    setVatIdVerificationStatus('')
    setVatNo('')
    setValidationStatus('')
    setLoading(false)
  }

  const [createApi] = useCustomFetch(
    `${SIGNUP_API}/createClientAccount/${mail}?myDirectRouting=false`,
    'SIGNUP_POST',
    false,
    JSON.stringify({
      companyName,
      companyEmail,
      countryCode,
      spanishTaxExempt,
      vatNo,
      address,
      postalCode,
      phoneNumber,
      plan: accountPlanId,
      provider: appProviderBrand
    })
  )

  useEffect(() => {
    if (!isEmpty(createApi.response)) {
      if (createApi.response.status) {
        closeHandler()
        message.success(
          getTranslatedText(
            translations,
            language,
            'client_created_successfully'
          )
        )
        if (createApi.response.response?.err?.error?.raw?.message) {
          message.error(createApi.response.response.err.error.raw.message)
        }
        refetchClients()
      } else {
        message.error(
          getTranslatedText(translations, language, 'client_creation_failed')
        )
      }
    }
  }, [createApi.response])

  useEffect(() => {
    if (countryCode) {
      if (EUROPIAN_UNION_COUNTRIES.some((country) => country === countryCode)) {
        setTaxFieldEnabled(true)
      } else {
        setTaxFieldEnabled(false)
      }
    }
  }, [countryCode])

  const closeHandler = () => {
    flagRef.current && flagRef.current.onSelect('')
    onClose()
    cleanup()
  }

  const onCountryChange = (countryCode) => {
    setCountryCode(countryCode)
    setSpanishTaxExempt(false)
  }

  const onSpanishExcemptChange = (e) => {
    setSpanishTaxExempt(e.target.checked)
  }

  const saveHandler = () => {
    const isValid = validateCountryAndVatNo(countryCode)
    const validator = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    // eslint-disable-next-line max-len
    const phoneNumberValidator = /^\+{0,1}([\-\. ])?(\(?\d{0,3}\))?([\-\. ])?\(?\d{0,7}\)?([\-\. ])?\d{3}([\-\. ])?\d{4}?$/

    if (!isValid) {
      return
    }

    if (companyName.trim().length === 0) {
      message.warning(
        getTranslatedText(translations, language, 'please_enter_company_name')
      )
    } else if (companyEmail.trim().length === 0) {
      message.warning(
        getTranslatedText(translations, language, 'enter_useremail')
      )
    } else if (countryCode.trim().length === 0) {
      message.warning(
        getTranslatedText(translations, language, 'please_select_a_country')
      )
    } else if (!validator.test(companyEmail)) {
      message.warning(
        getTranslatedText(translations, language, 'enter_valid_email')
      )
    } else if (address.trim().length === 0) {
      message.warning(
        getTranslatedText(translations, language, 'enter_valid_address')
      )
    } else if (
      postalCode.trim().length === 0 ||
      postalCode.trim().length > 15
    ) {
      message.warning(
        getTranslatedText(translations, language, 'enter_valid_postal_code')
      )
    } else if (
      phoneNumber.trim().length === 0 ||
      !phoneNumberValidator.test(phoneNumber)
    ) {
      message.warning(
        getTranslatedText(translations, language, 'enter_valid_phone_number')
      )
    } else {
      setLoading(true)
      setTimeout(() => {
        createApi.setRefetch(true)
      }, 100)
    }
  }

  const onVatNumberChange = (event) => {
    setVatIdVerificationStatus('')
    setVatNo(event.target.value)
  }

  const getTaxIdValidationStatus = () => {
    if (vatIdVerificationStatus === 'verified') {
      return (
        <Tooltip title={translations.valid[language]}>
          <CheckCircleTwoTone twoToneColor="#52c41a" />
        </Tooltip>
      )
    } else if (vatIdVerificationStatus === 'pending') {
      return (
        <Tooltip title={translations.vat_pending[language]}>
          <LoadingOutlined /*  twoToneColor="yellow" */ />
        </Tooltip>
      )
    } else if (vatIdVerificationStatus === 'unavailable') {
      return (
        <Tooltip title={translations.vat_unavailable[language]}>
          <ExclamationCircleTwoTone twoToneColor="red" />
        </Tooltip>
      )
    } else if (vatIdVerificationStatus === 'invalid') {
      return (
        <Tooltip title={translations.invalid[language]}>
          <CloseCircleTwoTone twoToneColor="red" />
        </Tooltip>
      )
    } else if (vatIdVerificationStatus === 'unverified') {
      return (
        <Tooltip title={translations.unverified[language]}>
          <InfoCircleOutlined twoToneColor="yellow" />
        </Tooltip>
      )
    } else if (vatIdVerificationStatus === '') {
      return <></>
    }
  }

  const validateCountryAndVatNo = (countryCode) => {
    if (
      vatNo &&
      EUROPIAN_UNION_COUNTRIES.some((country) => country === countryCode) &&
      ((countryCode === 'GR' && vatNo.substr(0, 2) !== 'EL') ||
        (countryCode !== 'GR' && vatNo.substr(0, 2) !== countryCode))
    ) {
      // setVatNo('')
      setVatIdVerificationStatus('')
      setValidationStatus('error')
      message.error(translations.invalidVat_Country[language])

      return false
    } else {
      setValidationStatus('')

      return true
    }
  }

  const renderCountryDropDown = () => {
    return (
      <div style={{ width: 250 }}>
      <ReactFlagsSelect
        searchable
        selectedSize={12}
        optionsSize={12}
        selected={countryCode}
        onSelect={onCountryChange}
        placeholder={getTranslatedText(
          translations,
          language,
          'company_country'
        )}
      />
      </div>
    )
  }

  return (
    <div className="client-modal-container">
      <ModalLayout
        className="client-modal"
        onCancel={closeHandler}
        visible={visiblity}
        closable={!loading}
      >
        <Header>
          {getTranslatedText(translations, language, 'add_client')}
        </Header>
        <Body>
          <div style={{ maxHeight: 300, overflow: 'auto' }}>
            <div>
              <div className="setting-tab-label">
                {getTranslatedText(
                  translations,
                  language,
                  'userdetail_company'
                )}
              </div>
              <div className="setting-tab-element">
                <Input
                  value={companyName}
                  onChange={(event) => {
                    setCompanyName(event.target.value)
                  }}
                />
              </div>
              <div className="setting-tab-label">
                {getTranslatedText(translations, language, 'billing_email')}
              </div>
              <div className="setting-tab-element">
                <Input
                  value={companyEmail}
                  onChange={(event) => {
                    setCompanyEmail(event.target.value)
                  }}
                />
              </div>
              <div className="setting-tab-label">
                {getTranslatedText(translations, language, 'company_country')}
              </div>
              <div
                className="setting-tab-element"
                style={{ display: 'inline' }}
              >
                {renderCountryDropDown()}
                {countryCode === 'ES' && (
                  <div>
                    <Checkbox
                      onChange={onSpanishExcemptChange}
                      checked={spanishTaxExempt}
                      className="spanish-excempt-checkbx"
                    >
                      {getTranslatedText(
                        translations,
                        language,
                        'spain_tax_excempt_area'
                      )}
                    </Checkbox>
                  </div>
                )}
                {isTaxFieldEnabled && (
                  <>
                    <div className="setting-tab-label">
                      {getTranslatedText(translations, language, 'tax_number')}
                    </div>
                    <div className="setting-tab-element">
                      <Form.Item
                        // hasFeedback
                        validateStatus={validationStatus}
                      >
                        <Input
                          onChange={onVatNumberChange}
                          value={vatNo}
                          suffix={getTaxIdValidationStatus()}
                        />
                      </Form.Item>
                    </div>
                  </>
                )}
                <div className="setting-tab-label">
                  {getTranslatedText(translations, language, 'address')}
                </div>
                <div className="setting-tab-element-address">
                  <Input
                    value={address}
                    onChange={(event) => {
                      setAddress(event.target.value)
                    }}
                  />
                </div>
                <div className="setting-tab-label">
                  {getTranslatedText(translations, language, 'postal_code')}
                </div>
                <div className="setting-tab-element">
                  <Input
                    value={postalCode}
                    onChange={(event) => {
                      if (event.target.value.length <= 15) {
                        setPostalCode(event.target.value)
                      }
                    }}
                  />
                </div>
                <div className="setting-tab-label">
                  {getTranslatedText(translations, language, 'phone_number')}
                </div>
                <div style={{ width: '250px' }}>
                  <Input
                    value={phoneNumber}
                    onChange={(event) => {
                      if (event.target.value.length <= 15) {
                        setPhoneNumber(event.target.value)
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Body>
        <Footer>
          <div className="cp-button-body">
            <Button
              className="cp-back-button-style"
              onClick={closeHandler}
              disabled={loading}
            >
              {getTranslatedText(translations, language, 'cancel')}
            </Button>
            <PttButton
              type="primary"
              onClick={saveHandler}
              disabled={loading}
              loading={loading}
            >
              {getTranslatedText(translations, language, 'save')}
            </PttButton>
          </div>
        </Footer>
      </ModalLayout>
    </div>
  )
}

export default AddClient
