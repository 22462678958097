// @flow

import React, {
  forwardRef,
  Fragment,
  memo,
  useState,
  useImperativeHandle,
  useEffect,
} from 'react'
import { Input } from 'antd'

import './shorttext.css'
import { isTextSame } from '../../../utils/helpers'
import { NODE_TYPES } from '../nodetypes'

type Props = {
  autoSaveHandler?: (any) => mixed,
  initialValue?: string,
  keyName: string,
  label?: string,
  maxLength?: number,
  mode: string,
  placeholder?: string,
  size?: string,
}

const ShortText = forwardRef<Props, *>((props: Props, ref) => {
  const {
    autoSaveHandler,
    initialValue = '',
    keyName,
    label = 'Label',
    maxLength = 500,
    mode,
    placeholder = '',
    size = 'default',
  } = props

  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const changeHandler = (event) => {
    setValue(event.target.value)
  }

  useImperativeHandle(ref, () => ({
    getLatestValue() {
      return value
    },
    getName() {
      return keyName
    },
    getType() {
      return NODE_TYPES.shortText
    },
    resetValue() {
      setValue('')
    },
  }))

  const triggerAutoSave = () => {
    if (
      mode === 'Edit' &&
      autoSaveHandler &&
      !isTextSame(initialValue, value)
    ) {
      autoSaveHandler(NODE_TYPES.shortText)
    }
  }

  return (
    <Fragment>
      <div className="label-spacing"> {label} </div>
      <Input
        maxLength={maxLength}
        onBlur={triggerAutoSave}
        onChange={changeHandler}
        placeholder={placeholder}
        size={size}
        value={value}
      />
    </Fragment>
  )
})

export default memo<Props>(ShortText)
