// @flow

import React from 'react'
import { Switch } from 'antd'
import { NODE_TYPES } from '../nodetypes'

type Props = {
  autoSaveHandler: (any) => mixed,
  keyName: string,
  label: string,
  mode: string,
  selectedValue: boolean,
}

const SwitchComponent = React.forwardRef<Props, *>(
  ({ autoSaveHandler, keyName, label, mode, selectedValue }: Props, ref) => {
    const [value, setValue] = React.useState(false)
    const [update, setUpdate] = React.useState(false)

    React.useEffect(() => {
      setValue(selectedValue)
    }, [selectedValue])

    React.useImperativeHandle(ref, () => ({
      getLatestValue() {
        return value
      },
      getName() {
        return keyName
      },
      getType() {
        return NODE_TYPES.switch
      },
      resetValue() {
        setValue(false)
      },
    }))

    const onChange = (checked) => {
      setValue(checked)
      setUpdate(true)
    }

    React.useEffect(() => {
      if (update && mode === 'Edit' && autoSaveHandler) {
        autoSaveHandler(NODE_TYPES.switch)
        setUpdate(false)
      }
    }, [update])

    return (
      <React.Fragment>
        <div className="label-spacing">{label}</div>
        <Switch checked={value} onChange={onChange} />
      </React.Fragment>
    )
  }
)

export default React.memo<Props>(SwitchComponent)
