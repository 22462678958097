// @flow

import React, { useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import useCustomFetch from '../../../../Hooks/UseCustomFetch'
import { CUSTOM_ACTION_URL } from '../../../../utils/constants'
import { convertToDollar } from '../../../../utils/helpers'
import { setPaymentList } from '../../../../actions/billingAction'
import MakePaymentModal from '../../../Billing/MakePaymentModal'
import AddCreditModal from '../../../Billing/AddCreditModal'
import PttButton from '../../../CustomButton/PttButton'
import Spinner from '../../../../Spinner/spinner'
import MemoTable from '../../../MemoTable/memotable'

type Props = {
  selectedPhoneNumbers: Object,
  enablePhoneNoSelected: (any) => mixed,
  enableCheckoutConfirm: (value: boolean, amount?: number) => mixed,
  setPaymentList: (any) => mixed,
  paymentMethods: Object,
  userStripeData: Object,
  paymentSucceededEventData: Object,
  translations: Object,
  language: string,
}

const PhoneNumberCheckout = (props: Props) => {
  const {
    selectedPhoneNumbers,
    userStripeData,
    enableCheckoutConfirm,
    setPaymentList,
    paymentMethods,
    paymentSucceededEventData,
    translations,
    language,
  } = props

  const [totalBalance, setTotalBalance] = useState(null)
  const [monthlyCharge, setMonthlyCharge] = useState(0)
  const [proRatedCharge, setProRatedCharge] = useState(null)
  const [popup1State, setPopup1State] = useState(false)
  const [popup2State, setPopup2State] = useState(false)
  const [amountToPay, setAmountToPay] = useState(0)
  const [loading, setLoading] = useState(true)

  /* List charges and credits of the account */
  const [getCreditAndChargeApi] = useCustomFetch(
    `${CUSTOM_ACTION_URL}/payment`,
    'PAYMENT',
    true,
    JSON.stringify({
      action: 'LIST_CHARGE_AND_CREDITS',
      data: {
        customerId: userStripeData.stripe_id,
      },
    })
  )

  /* List payment methods of the loggedin customer */
  const [listPaymentMethodApi] = useCustomFetch(
    `${CUSTOM_ACTION_URL}/payment`,
    'PAYMENT',
    false,
    JSON.stringify({
      action: 'LIST_PAYMENT_METHODS',
      data: {
        customerId: userStripeData.stripe_id,
      },
    })
  )

  useEffect(() => {
    if (paymentSucceededEventData && paymentSucceededEventData.account_id) {
      setTotalBalance(Number(paymentSucceededEventData.data.balance.toFixed(0)))
      setLoading(false)
    }
  }, [paymentSucceededEventData])

  useEffect(() => {
    if (
      getCreditAndChargeApi.response &&
      getCreditAndChargeApi.response.status &&
      getCreditAndChargeApi.response.response &&
      getCreditAndChargeApi.response.response.balance !== undefined
      /* getCreditAndChargeApi.response.response.length === 3 */
    ) {
      setTotalBalance(
        Number(getCreditAndChargeApi.response.response.balance.toFixed(0))
      )
      setLoading(false)
    }
  }, [getCreditAndChargeApi.response])

  useEffect(() => {
    if (totalBalance !== null && proRatedCharge !== null) {
      const amtToPayTmp = proRatedCharge - totalBalance

      if (totalBalance < proRatedCharge && amtToPayTmp > 0) {
        enableCheckoutConfirm(false)
        const amountToPay = amtToPayTmp <= 200 ? 200 : amtToPayTmp

        setAmountToPay(amountToPay)
      } else {
        setAmountToPay(0)
        enableCheckoutConfirm(true, proRatedCharge)
      }
    }
  }, [totalBalance, proRatedCharge])

  useEffect(() => {
    if (selectedPhoneNumbers && selectedPhoneNumbers.length) {
      let monthlyCharge = 0
      let charge = 0
      const daysInTotal = moment().daysInMonth()
      const daysRemaining = moment().daysInMonth() - moment().date() + 1

      selectedPhoneNumbers.map((phone) => {
        charge = charge + (daysRemaining / daysInTotal) * Number(phone.price)
        monthlyCharge = monthlyCharge + Number(phone.price)
      })

      setMonthlyCharge(convertToDollar(monthlyCharge))
      setProRatedCharge(Number(charge.toFixed(0)))
    }
  }, [selectedPhoneNumbers])

  useEffect(() => {
    if (
      listPaymentMethodApi.response &&
      listPaymentMethodApi.response.status &&
      listPaymentMethodApi.response.response
    ) {
      setPaymentList(listPaymentMethodApi.response.response.data)
    }
  }, [listPaymentMethodApi.response])

  const handleOpenPopup = () => {
    if (paymentMethods && paymentMethods.length > 0) {
      setPopup1State(true)
    } else {
      setPopup2State(true)
    }
  }

  const handlePopupClose = () => {
    setPopup1State(false)
    setPopup2State(false)
  }

  const getCustomerPaymentSuccList = () => {
    // getCreditAndChargeApi.setRefetch(true)
    setLoading(true)
  }

  const getPaymentMethods = () => {
    listPaymentMethodApi.setRefetch(true)
  }

  const monthlyPriceTable = useMemo(() => {
    const data = selectedPhoneNumbers.map((data) => {
      if (data.key) {
        return {
          key: data.key,
          licence: translations.phone_number_label[language],
          phone: data.number,
          price: `$${convertToDollar(data.price)}`,
          total: `$${convertToDollar(data.price)}`,
        }
      }
    })

    data.push({
      key: 'total',
      licence: (
        <div style={{ fontWeight: 500 }}>
          {translations.total_price[language]}
        </div>
      ),
      quantity: '',
      unitPrice: '',
      total: <div style={{ fontWeight: 500 }}>{`$${monthlyCharge}`}</div>,
    })

    const columns = [
      {
        title: translations.licence[language],
        dataIndex: 'licence',
        width: '35%',
      },
      {
        title: translations.phone[language],
        dataIndex: 'phone',
        width: '35%',
      },
      {
        title: translations.monthly_price[language],
        dataIndex: 'total',
        width: '30%',
        align: 'right',
      },
    ]

    return (
      <MemoTable
        columns={columns}
        dataSource={data}
        isSelectable={true}
        customClass="phone-no-checkout"
        // scrollObject={{ y: 'calc(100vh - 190px)' }}
        scrollObject={{ y: totalBalance < proRatedCharge ? '18vh' : '25vh' }}
        showHeader={true}
      />
    )
  }, [monthlyCharge, selectedPhoneNumbers])

  return loading ? (
    <div className="align-center full-height">
      <Spinner spinning={true} />
    </div>
  ) : (
    <div>
      {amountToPay ? (
        <div className="checkout-sub-head">
          <div className="checkout-alert">
            <div>
              {translations.current_account_balance[language]}
              {`: $${convertToDollar(totalBalance)}`}
            </div>
            <div style={{ fontSize: '14px' }}>
              {translations.checkout_info[language]}
            </div>
          </div>
          <PttButton
            onClick={handleOpenPopup}
            type="primary"
            customClass="topup-btn"
            disabled={paymentMethods === undefined}
          >
            {translations.TOP_UP[language]}
          </PttButton>
        </div>
      ) : (
        <></>
      )}
      {!amountToPay && (
        <div className="checkout-sub-head2">
          {translations.current_account_balance[language]}: $
          {convertToDollar(totalBalance)}
        </div>
      )}
      <AddCreditModal
        handlePopupClose={handlePopupClose}
        popupState={popup1State}
        paymentMethods={paymentMethods}
        getCustomerPaymentSuccList={getCustomerPaymentSuccList}
        topUpAmount={amountToPay}
      />
      <MakePaymentModal
        handlePopupClose={handlePopupClose}
        popupState={popup2State}
        getCustomerPaymentSuccList={getCustomerPaymentSuccList}
        getPaymentMethods={getPaymentMethods}
        topUpAmount={amountToPay}
      />
      {/* </div>} */}
      <div className="checkout-sub-head2" style={{ paddingTop: '10px' }}>
        {translations.monthly_charge[language]}: ${monthlyCharge}
      </div>
      {proRatedCharge !== null && (
        <div className="charge-info">
          {translations.checkout_charge_info_1[language]}
          {convertToDollar(proRatedCharge)}{' '}
          {translations.checkout_charge_info_2[language]}
          {monthlyCharge} {translations.checkout_charge_info_3[language]}
        </div>
      )}
      {monthlyPriceTable}
    </div>
  )
}

const mapsStateToProps = (state) => {
  return {
    userStripeData: state.sampleReducer.userStripeData || {},
    paymentMethods: state.billingReducer.paymentMethods,
    paymentSucceededEventData: state.billingReducer.paymentSucceededEventData,
    translations: state.sampleReducer.translations || {},
    language: state.sampleReducer.language,
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setPaymentList,
    },
    dispatch
  )

export default connect(
  mapsStateToProps,
  mapDispatchToProps
)(PhoneNumberCheckout)
