// @flow

import React from 'react'
import './hoverablebutton.css'
import CircularIconButton from '../CustomButton/circulariconbutton'

type Props = {
  children?: Node,
  className?: string,
  disabled?: boolean,
  loading?: boolean,
  onClick?: (any) => mixed,
  type?: string,
}

const HoverableButton = (props: Props) => {
  const {
    children,
    className = 'button-style',
    disabled,
    loading = false,
    onClick,
    type,
  } = props

  return (
    <CircularIconButton
      customClass={className}
      onClick={onClick}
      disabled={disabled}
      loading={loading}
      type={type}
    >
      {children}
    </CircularIconButton>
  )
}

export default HoverableButton
