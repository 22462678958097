// @flow

import React, { useMemo } from 'react'
import { Checkbox, Empty, Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import PttButton from '../CustomButton/PttButton'
import ModalLayout, { Header, Body, Footer } from '../Popup/popup'
import { getNewArray } from '../../utils/helpers'
import { useSelector } from 'react-redux'
import Spinner from '../../Spinner/spinner'

type Props = {
  closeModel: () => void,
  onSuccess: (any) => mixed,
  usersArray: $ReadOnlyArray<*>,
  visible: boolean,
  modelLoading: boolean,
}

const AddAccessModal = ({
  closeModel,
  onSuccess,
  usersArray,
  visible,
  modelLoading,
}: Props) => {
  const { language, translations } = useSelector((state) => ({
    language: state.sampleReducer.language || 'en',
    translations: state.sampleReducer.translations || {},
  }))

  const [filter, setFilter] = React.useState('')
  const [users, setUsers] = React.useState([])

  React.useEffect(() => {
    setFilter('')
    setUsers(usersArray)
  }, [usersArray])

  const onChecked = (checked, id) => {
    const set = getNewArray(users)

    set.map((user) => {
      if (user.id === id) {
        user.selected = checked
      }
    })

    setUsers(set)
  }

  const loader = () => {
    return (
      <div className="spin-style" style={{ width: 200, height: 190 }}>
        <Spinner spinning={true} />
      </div>
    )
  }

  const renderUsersList = () => {
    if (modelLoading) {
      return loader()
    }

    const list = users.filter(
      (user) => user.username.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    )

    return (
      <React.Fragment>
        <Input
          className="VoiceUserComponent__ModelSearch"
          onChange={(event) => setFilter(event.target.value)}
          prefix={<SearchOutlined />}
          value={filter}
        />
        <div className="VoiceUserComponent__ModelTitle">
          {translations.name[language]}
        </div>
        <div className="VoiceUserComponent__ModelCheckBody">
          {list.map((row) => (
            <div
              className="VoiceUserComponent__ModelCheckStyle"
              key={`check ${row.id}`}
            >
              <Checkbox
                checked={row.selected}
                onChange={(event) => onChecked(event.target.checked, row.id)}
              >
                {row.username}
              </Checkbox>
            </div>
          ))}
        </div>
      </React.Fragment>
    )
  }

  const renderBody = useMemo(() => {
    if (users.length) {
      return renderUsersList()
    } else {
      return (
        <div className="VoiceUserComponent__ModelEmpty">
          <Empty />
        </div>
      )
    }
  }, [users, translations, language, modelLoading])

  const renderFooter = useMemo(() => {
    const selected = users.some((item) => item.selected)

    if (users.length) {
      return (
        <Footer>
          <PttButton
            onClick={() => onSuccess(users)}
            type="primary"
            loading={modelLoading}
            disabled={modelLoading || !selected}
          >
            {translations.add[language]}
          </PttButton>
        </Footer>
      )
    } else {
      return <React.Fragment />
    }
  }, [users, translations, language, modelLoading])

  return (
    <React.Fragment>
      <ModalLayout onCancel={closeModel} visible={visible}>
        <Header>{translations.add_users[language]}</Header>
        <Body>{renderBody}</Body>
        {renderFooter}
      </ModalLayout>
    </React.Fragment>
  )
}

export default AddAccessModal
