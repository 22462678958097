import * as React from 'react'
import { AbstractReactFactory } from '@projectstorm/react-canvas-core'
import InbuiltNodeModel from './InbuiltNodeModel'
import InbuiltNodeWidget from './InbuiltNodeWidget'

class InbuiltNodeFactory extends AbstractReactFactory {
  constructor() {
    super('js-inbuilt-node')
  }

  generateModel() {
    return new InbuiltNodeModel()
  }

  generateReactWidget(event) {
    return <InbuiltNodeWidget engine={this.engine} node={event.model} />
  }
}

export default InbuiltNodeFactory
