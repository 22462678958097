import React from 'react'
import { message } from 'antd'
import { isEmpty } from 'lodash'
import { useDispatch } from 'react-redux'
import useCustomFetch from './UseCustomFetch'
import { APIURL, CUSTOM_ACTION_URL } from '../utils/constants'
import { setPaymentList } from '../actions/billingAction'
import { setFetchSettings } from '../actions/settingAction'
import {
  setAccSettingsData,
  setAccountSettingsData,
  setIsResellerAccount,
  SetAccountConfig,
  SetAccountPlanId,
  setAppProviderBrand,
  /* setAppFromClient, */
} from '../actions/SampleAction'
/* import { removeItem } from '../utils/storage' */

const UseFetchSettings = ({ paymentsMessage = '', settingsMessage = '' }) => {
  const dispatch = useDispatch()
  const [stripeId, setStripeId] = React.useState('')

  const [accountSettingsSystem] = useCustomFetch(
    `${APIURL}/account_settings_system`,
    'GET',
    true
  )

  const [accountSettings] = useCustomFetch(
    `${APIURL}/account_settings`,
    'GET',
    true
  )

  const [listPayments] = useCustomFetch(
    `${CUSTOM_ACTION_URL}/payment`,
    'PAYMENT',
    false,
    JSON.stringify({
      action: 'LIST_PAYMENT_METHODS',
      data: {
        customerId: stripeId,
      },
    })
  )

  React.useEffect(() => {
    if (!isEmpty(accountSettingsSystem.response)) {
      if (
        accountSettingsSystem.response.result &&
        accountSettingsSystem.response.response.count > 0
      ) {
        const items = accountSettingsSystem.response.response.items
        const info = items.find((item) => item.custom_id === 'provider')
        const plan = items.find((item) => item.custom_id === 'plan')
        const planId = plan?.data?.account_plan?.id
        const price = plan?.data?.account_plan?.data?.price
        const provider = info?.data?.provider
        const account = items.filter(
          (item) => item.custom_id === 'stripe_account'
        )

        /* if (info.data.type === 'RESELLER') {
          removeItem('IsClientPage')
          dispatch(setAppFromClient(false))
        } */

        dispatch(setIsResellerAccount(info.data.type === 'RESELLER'))
        dispatch(setAccSettingsData(items))
        dispatch(SetAccountConfig(price))
        dispatch(SetAccountPlanId(planId))
        dispatch(setAppProviderBrand(provider ? provider : 'PTT'))

        if (account.length > 0) {
          setStripeId(account[0].data.stripe_id)
          listPayments.setRefetch(true)
        } else {
          dispatch(setFetchSettings(false))
        }
      } else {
        message.error(settingsMessage)
        dispatch(setFetchSettings(false))
      }
    }
  }, [accountSettingsSystem.response])

  React.useEffect(() => {
    if (accountSettingsSystem.error !== null) {
      message.error(settingsMessage)
      dispatch(setFetchSettings(false))
    }
  }, [accountSettingsSystem.error])

  React.useEffect(() => {
    if (accountSettings.response?.response?.count) {
      dispatch(
        setAccountSettingsData(accountSettings.response.response.items[0])
      )
    }
  }, [accountSettings.response])

  React.useEffect(() => {
    if (!isEmpty(listPayments.response)) {
      if (listPayments.response.status && listPayments.response.response) {
        dispatch(setPaymentList(listPayments.response.response.data))
      } else {
        message.error(paymentsMessage)
      }
      dispatch(setFetchSettings(false))
    }
  }, [listPayments.response])

  React.useEffect(() => {
    if (listPayments.error !== null) {
      message.error(paymentsMessage)
      dispatch(setFetchSettings(false))
    }
  }, [listPayments.error])
}

export default UseFetchSettings
