/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from 'react'
import { Input } from 'antd'
import PttButton from '../../CustomButton/PttButton'
import InfoPanel from '../../InfoPanel'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from '../../../Spinner/spinner'
import MemoTable from '../../MemoTable/memotable'
import AddRoutingPopup from './AddRoutingPopup'
import { APIURL, FULL_ACCESS } from '../../../utils/constants'
import useCustomFetch from '../../../Hooks/UseCustomFetch'
import { isEmpty } from '../../../utils/helpers'
import './RoutingView.css'
import RoutingSidebar from './RoutingSidebar'
import { Fragment } from 'react'
import { isArray, isEqual, orderBy } from 'lodash'
import {
  setActualRouting,
  setModifiedRouting,
  setRoutingSaveFromClose,
  setRoutingSaveFromRow,
} from '../../../actions/routingAction'
import ConfirmPopup from '../../Popup/confirmpopup'

const dummyRow = { id: '' }
const routingUrl = `${APIURL}/routing?relations=gateway`

const RoutingView = ({ active }) => {
  const dispatch = useDispatch()
  const {
    actualRouting,
    language,
    modifiedRouting,
    translations,
    routingSaveFromClose,
    routingSaveFromRow,
    advancedPermission
  } = useSelector((state) => {
    return {
      language: state.sampleReducer.language,
      translations: state.sampleReducer.translations,
      actualRouting: state.routingReducer.actualRouting,
      modifiedRouting: state.routingReducer.modifiedRouting,
      routingSaveFromClose: state.routingReducer.routingSaveFromClose,
      routingSaveFromRow: state.routingReducer.routingSaveFromRow,
      advancedPermission: state.permissionReducer.advancedPermission,
    }
  })
  const [filter, setFilter] = useState('')
  const [loading, setLoading] = useState(true)
  const [infoLoad, setInfoLoad] = useState(false)
  const [info, setInfo] = useState(false)
  const [row, setRow] = useState(dummyRow)
  const [addPopup, setAddPopup] = useState(false)
  const [routing, setRouting] = useState([])
  const [unsave, setUnsave] = useState(false)
  const [postRow, setPostRow] = useState({})
  const [saveOrigin, setSaveOrigin] = useState('')

  const [routingApi] = useCustomFetch(routingUrl, 'GET', false)

  const getDummyMode = (mode) => {
    if (mode === 'FO') {
      return 'Fail over'
    } else if (mode === 'LB') {
      return 'Load balancer'
    } else {
      return ''
    }
  }

  useEffect(() => {
    if (active) {
      setFilter('')
      setLoading(true)
      setInfoLoad(false)
      setInfo(false)
      setRow(dummyRow)
      setAddPopup(false)
      setRouting([])
      routingApi.setRefetch(true)
    }
  }, [active])

  const onRouteAddSuccess = () => {
    setLoading(true)
    routingApi.setRefetch(true)
    setRow(dummyRow)
    setInfo(false)
  }

  const updateOpenedRow = (details) => {
    const itemData = details?.data ? details?.data : {}
    const relation = details?.relation ? details?.relation : {}
    const trunks =
      relation?.gateway && isArray(relation.gateway) ? relation.gateway : []
    const sortedTrunks = orderBy(trunks, ['name'], ['asc'])

    setRow({
      id: details.id,
      key: String(details.id),
      name: details.name,
      description: itemData.description,
      mode: getDummyMode(itemData.mode),
      host_origin: itemData.host_origin,
      callee: itemData.callee,
      caller: itemData.caller,
      trunks: sortedTrunks,
    })
  }

  useEffect(() => {
    if (!isEmpty(routingApi.response)) {
      if (
        routingApi.response.result &&
        routingApi.response.response.items.length &&
        routingApi.response.response.items[0].id
      ) {
        setRouting(routingApi.response.response.items)
        setLoading(false)

        if (row.id) {
          const found = routingApi.response.response.items.find(
            (item) => item.id === row.id
          )

          if (found.id) {
            updateOpenedRow(found)
          }
        }
      } else {
        setRouting([])
        setLoading(false)
      }
    }
  }, [routingApi.response])

  const loader = () => {
    return (
      <div className="spin-style">
        <Spinner spinning={true} />
      </div>
    )
  }

  const closeInfo = () => {
    if (isEqual(actualRouting, modifiedRouting)) {
      setInfo(false)
      setRow(dummyRow)
      dispatch(setActualRouting({}))
      dispatch(setModifiedRouting({}))
    } else {
      setSaveOrigin('close')
      setUnsave(true)
    }
  }

  const onUnsaveCancel = () => {
    if (saveOrigin === 'close') {
      setRow(dummyRow)
      setPostRow({})
      setInfo(false)
    } else if (saveOrigin === 'row') {
      setRow(postRow)
      setTimeout(() => setPostRow({}), 1000)
    }

    setSaveOrigin('')
    setUnsave(false)
    dispatch(setActualRouting({}))
    dispatch(setModifiedRouting({}))
  }

  const onUnsaveClose = () => {
    setSaveOrigin('')
    setUnsave(false)
  }

  const onUnsaveOkay = () => {
    setUnsave(false)
    if (saveOrigin === 'close') {
      dispatch(setRoutingSaveFromClose(true))
    } else if (saveOrigin === 'row') {
      dispatch(setRoutingSaveFromRow(true))
    }
  }

  const closeAddPopup = () => {
    setAddPopup(false)
  }

  const onRoutingDeleteCb = () => {
    setLoading(true)
    routingApi.setRefetch(true)
    setInfo(false)
    setRow(dummyRow)
  }

  const onRoutingUpdateCb = () => {
    setLoading(true)
    routingApi.setRefetch(true)
    if (routingSaveFromClose) {
      setInfo(false)
      setRow(dummyRow)
      dispatch(setRoutingSaveFromClose(false))
      setSaveOrigin('')
    } else if (routingSaveFromRow) {
      setRow(postRow)
      dispatch(setRoutingSaveFromRow(false))
      setSaveOrigin('')
    }
  }

  const renderInfoPanelChild = () => {
    return (
      <RoutingSidebar
        routing={routing}
        row={row}
        onRoutingDeleteCb={onRoutingDeleteCb}
        onRoutingUpdateCb={onRoutingUpdateCb}
      />
    )
  }

  const applyMultipleFilters = (dataProvider) => {
    const doFilter = (value) => {
      return value.toLowerCase().includes(filter.toLowerCase())
    }

    const doArrayFilter = (value, type = '') => {
      const data =
        type === 'trunks'
          ? value.map((item) => item.name.toLowerCase())
          : value.map((item) => item.toLowerCase())

      return data.some((item) => item.includes(filter.toLowerCase()))
    }

    return dataProvider.filter((routing) => {
      return (
        routing.name &&
        routing.mode &&
        routing.host_origin &&
        routing.caller &&
        routing.callee &&
        routing.trunks &&
        (doFilter(routing.name) ||
          doFilter(routing.mode) ||
          doArrayFilter(routing.host_origin) ||
          doArrayFilter(routing.caller) ||
          doArrayFilter(routing.callee) ||
          doArrayFilter(routing.trunks, 'trunks'))
      )
    })
  }

  const renderRoutingTable = useMemo(() => {
    let data = routing.map((tableItem) => {
      const itemData = tableItem?.data ? tableItem?.data : {}
      const relation = tableItem?.relation ? tableItem?.relation : {}
      const trunks =
        relation?.gateway && isArray(relation.gateway) ? relation.gateway : []
      const sortedTrunks = orderBy(trunks, ['name'], ['asc'])

      return {
        id: tableItem.id,
        key: String(tableItem.id),
        name: tableItem.name,
        description: itemData.description,
        mode: getDummyMode(itemData.mode),
        host_origin: itemData.host_origin,
        callee: itemData.callee,
        caller: itemData.caller,
        trunks: sortedTrunks,
      }
    })

    data = filter.length ? applyMultipleFilters(data) : data

    const columns = [
      {
        dataIndex: 'name',
        sorter: (a, b) => {
          const wordA = a.name.toLowerCase()
          const wordB = b.name.toLowerCase()

          if (wordA < wordB) {
            return -1
          } else if (wordA > wordB) {
            return 1
          } else {
            return 0
          }
        },
        title: translations.name[language],
        defaultSortOrder: 'ascend',
      },
      {
        dataIndex: 'mode',
        title: translations.mode[language],
      },
      {
        dataIndex: 'host_origin',
        title: translations.host_origin[language],
        render: (text, record) => {
          return (
            <div className="row_table_list">
              {record?.host_origin?.map((item) => {
                return (
                  <div className="route_array_list" key={item}>
                    {item}
                  </div>
                )
              })}
            </div>
          )
        },
      },
      {
        dataIndex: 'callee',
        title: translations.callee[language],
        render: (text, record) => {
          return (
            <div className="row_table_list">
              {record?.callee?.map((callees) => {
                return (
                  <div className="route_array_list" key={callees}>
                    {callees}
                  </div>
                )
              })}
            </div>
          )
        },
      },
      {
        dataIndex: 'caller',
        title: translations.caller[language],
        render: (text, record) => {
          return (
            <div className="row_table_list">
              {record?.caller?.map((callers) => {
                return (
                  <div className="route_array_list" key={callers}>
                    {callers}
                  </div>
                )
              })}
            </div>
          )
        },
      },
      {
        dataIndex: 'trunks',
        title: 'Trunks',
        render: (text, record) => {
          return (
            <div className="row_table_list">
              {record?.trunks?.map((trunk) => {
                return (
                  <div className="route_array_list" key={trunk.id}>
                    {trunk.name}
                  </div>
                )
              })}
            </div>
          )
        },
      },
    ]

    const rowSelectHandler = (rowSelected) => {
      if (Object.keys(rowSelected).length) {
        if (row.id === rowSelected.id) {
          setInfo(true)
        } else if (isEqual(actualRouting, modifiedRouting)) {
          setRow(rowSelected)
          setInfoLoad(true)
          setInfo(true)
          setTimeout(() => {
            setInfoLoad(false)
          })
        } else {
          setSaveOrigin('row')
          setUnsave(true)
          setPostRow(rowSelected)
        }
      }
    }

    return (
      <MemoTable
        columns={columns}
        dataSource={data}
        isSelectable={true}
        selectedRow={row}
        onSelectedRowChange={rowSelectHandler}
        scrollObject={{ y: 'calc(100vh - 230px)' }}
        style={{ height: '100%' }}
        size="small"
      />
    )
  }, [routing, row, filter, actualRouting, modifiedRouting])

  const renderSearchField = () => {
    if (loading) {
      return <Fragment />
    } else {
      return (
        <Input
          onChange={(event) => setFilter(event.target.value)}
          placeholder={translations.search[language]}
          style={{ width: 200, marginRight: 10 }}
        />
      )
    }
  }

  const renderAddButton = () => {
    return (
      <PttButton
        type="primary"
        loading={loading}
        disabled={loading || advancedPermission !== FULL_ACCESS}
        onClick={() => setAddPopup(true)}
      >
        {translations.add_routing[language]}
      </PttButton>
    )
  }

  const renderContent = () => {
    if (loading) {
      return loader()
    } else {
      return (
        <div className="panel-cont">
          <div
            className={info ? 'routing_panel_active' : 'routing_panel_inactive'}
          >
            {renderRoutingTable}
          </div>
          <InfoPanel
            changeVisiblity={closeInfo}
            visiblity={info}
            width={'520px'}
          >
            {infoLoad ? loader() : renderInfoPanelChild()}
          </InfoPanel>
        </div>
      )
    }
  }

  return (
    <div style={{ height: '100%' }}>
      <div
        style={{
          height: 40,
          margin: '5px 0px',
          padding: 5,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        {renderSearchField()}
        {renderAddButton()}
      </div>
      <div style={{ height: 'calc(100% - 50px)', padding: 5 }}>
        {renderContent()}
      </div>
      <AddRoutingPopup
        routing={routing}
        visiblity={addPopup}
        exitHandler={closeAddPopup}
        onRouteAddSuccess={onRouteAddSuccess}
      />
      <ConfirmPopup
        cancelText={translations.no[language]}
        closable={true}
        confirmText={translations.routing_unsaved_changes[language]}
        okayText={translations.yes[language]}
        onCancel={onUnsaveCancel}
        onClose={onUnsaveClose}
        onOkay={onUnsaveOkay}
        visiblity={unsave}
      >
        <React.Fragment>
          {translations.routing_unsaved_changes_info[language]}
        </React.Fragment>
      </ConfirmPopup>
    </div>
  )
}

export default RoutingView
