// @flow

import React, { useEffect, useState } from 'react'
import StatsSidebar from './StatsSidebar'
import './stats.css'
import { APIURL } from '../../utils/constants'
import useCustomFetch from '../../Hooks/UseCustomFetch'
import { parseApiResult } from '../../utils/helpers'

type Props = {
  pageTitle?: string,
}

const Stats = (props: Props) => {
  const { pageTitle = '' } = props
  const [statsList, setStatsList] = useState([])

  /* get stats list */
  const [statsListApi] = useCustomFetch(`${APIURL}/stats`, 'GET', false)

  useEffect(() => {
    const result = parseApiResult(statsListApi.response)

    if (result.length) {
      setStatsList(result)
    }
  }, [statsListApi.response])

  return (
    <div className="stats-cont">
      <StatsSidebar statsList={statsList} pageTitle={pageTitle} />
    </div>
  )
}

export default Stats
