// @flow

import { Badge, Empty, Input, notification, Tooltip, message } from 'antd'
import { CheckOutlined } from '@ant-design/icons'
import { LoadingOutlined } from '@ant-design/icons'
import { SearchOutlined } from '@ant-design/icons'
import { isEmpty, isEqual } from 'lodash'
import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import AddMsTeams from './addmsteams'
import AddUser from './adduser'
import EditVoiceUsers from './editvoiceuser'
import { APIURL, FULL_ACCESS } from '../../utils/constants'
import { CUSTOM_ACTION_URL } from '../../utils/constants'
import { REFRESHURL } from '../../utils/constants'
import { REFRESH_TOKEN } from '../../utils/constants'
import { parseApiResult } from '../../utils/helpers'
import useCustomFetch from '../../Hooks/UseCustomFetch'
import { PttButton } from '../CustomButton/custombutton'
import MoreActions from '../MoreActions/moreactions'
import Spinner from '../../Spinner/spinner'
import MemoTable from '../MemoTable/memotable'
import InfoPanel from '../InfoPanel'
import ConfirmPopup from '../Popup/confirmpopup'
import { getItem } from '../../utils/storage'
import { oauthMicrosoft } from './msteamsutils'
import {
  setActualVoiceUserData,
  setTeamsStep,
} from '../../actions/MsTeamsAction'
import { setModifiedVoiceUserData } from '../../actions/MsTeamsAction'
import { setUpdateVoiceUserOnTheFly } from '../../actions/MsTeamsAction'
import useMatchMedia from '../../Hooks/UseMatchMedia'
import './msteams.css'

const MsTeams = () => {
  const optionMedia = useMatchMedia('(max-width: 1000px)')

  const dispatch = useDispatch()
  const {
    accountSettings,
    actualVoiceUserData,
    language,
    modifiedVoiceUserData,
    translations,
    userAccounId,
    userSettings,
    microsoftTeamsPermission,
  } = useSelector((state) => ({
    accountSettings: state.sampleReducer.accountSettings,
    actualVoiceUserData: state.msTeamsReducer.actualVoiceUserData,
    language: state.sampleReducer.language,
    modifiedVoiceUserData: state.msTeamsReducer.modifiedVoiceUserData,
    translations: state.sampleReducer.translations || {},
    userAccounId: state.sampleReducer.userAccounId,
    userSettings: state.sampleReducer.userSettings,
    microsoftTeamsPermission: state.permissionReducer.microsoftTeamsPermission,
  }))

  const [userConnector, setUserConnector] = useState({ id: '', data: {} })
  const [connectorMode, setConnectorMode] = useState('None')
  const [connectorAlert, setConnectorAlert] = useState(false)
  const [voiceuserMode, setVoiceuserMode] = useState('None')
  const [voiceuserAlert, setVoiceuserAlert] = useState(false)
  const [infoState, setInfoState] = useState(false)
  const [row, setRow] = useState({})
  const [addUserPopup, setAddUserPopup] = useState(false)
  const [filter, setFilter] = useState('')
  const [isValid, setIsValid] = useState(null)
  const [msLoginLoading, setMsLoginLoading] = useState(false)
  const [connectorMailId, setConnectorMailId] = useState('')
  const [phonenumbers, setPhonenumbers] = useState([])
  const [deleteConnectorBody, setDeleteConnectorBody] = useState(null)
  const [deleteVoiceUserBody, setDeleteVoiceUserBody] = useState(null)
  const [voiceUserClose, setVoiceUserClose] = useState(false)
  const [rowSwitch, setRowSwitch] = useState(false)
  const [postCbData, setPostCbData] = useState([])
  const [connectorState, setConnectorState] = useState('')
  const [errorPoint, setErrorPoint] = useState('')

  const callingPlanUrl = `${APIURL}/calling_plan`
  const connectorUrl = `${APIURL}/connector?relations=gateway`
  const connectorDefUrl = `${APIURL}/connector/${userConnector.id}`
  const deleteConnectorUrl = `${CUSTOM_ACTION_URL}/delete_connector/${userConnector.id}`
  const phoneUrl = `${APIURL}/phone?relations=voice_user,call_flow`
  const relationUrl = `${APIURL}/connector-voice_user/${userConnector.id}`
  const voiceUsersUrl = `${APIURL}/voice_user?relations=phone`
  const voiceUserDetailUrl = `${APIURL}/voice_user/${row.id}`
  const voiceUserPhoneUrl = `${APIURL}/voice_user-phone/${row.id}`

  const [connectorsApi] = useCustomFetch(connectorUrl, 'GET', true)
  const [phoneApi] = useCustomFetch(phoneUrl, 'GET', true)
  const [connectorDefinitionApi] = useCustomFetch(connectorDefUrl, 'GET', false)
  const [relationApi] = useCustomFetch(relationUrl, 'GET', false)
  const [getAllVoiceUsers] = useCustomFetch(voiceUsersUrl, 'GET', false)
  const [voiceUserDetailApi] = useCustomFetch(voiceUserDetailUrl, 'GET', false)
  const [voiceUserPhoneApi] = useCustomFetch(voiceUserPhoneUrl, 'GET', false)
  const [callingPlansApi] = useCustomFetch(callingPlanUrl, 'GET', true)

  const [deleteConnectorApi] = useCustomFetch(
    deleteConnectorUrl,
    'DELTE_WITH_BODY',
    false,
    deleteConnectorBody
  )

  const [deleteVoiceuserApi] = useCustomFetch(
    deleteConnectorUrl,
    'DELTE_WITH_BODY',
    false,
    deleteVoiceUserBody
  )

  const [tokenApi] = useCustomFetch(
    REFRESHURL,
    'REFRESH_CONNECTOR',
    false,
    JSON.stringify({
      RefreshToken: getItem(REFRESH_TOKEN),
    })
  )

  const renderAddUserButton = () => {
    return (
      <PttButton
        disabled={msLoginLoading || microsoftTeamsPermission !== FULL_ACCESS}
        loading={msLoginLoading}
        type="primary"
        onClick={() => {
          getAllVoiceUsers.setRefetch(true)
          setMsLoginLoading(true)
          handleMsLogin()
        }}
      >
        {translations.add_users[language]}
      </PttButton>
    )
  }

  useEffect(() => {
    const { result, response } = phoneApi.response

    if (!isEmpty(phoneApi.response)) {
      if (result && response && response.items.length && response.items[0].id) {
        setPhonenumbers(
          response.items.filter(
            (item) => !item.relation || item.relation.voice_user
          )
        )
      }
    }
  }, [phoneApi.response])

  useEffect(() => {
    if (row.id) {
      setInfoState(true)
      voiceUserDetailApi.setRefetch(true)
      voiceUserPhoneApi.setRefetch(true)
    }
  }, [row])

  useEffect(() => {
    if (!isEmpty(connectorsApi.response)) {
      if (connectorsApi.response.result) {
        const items = connectorsApi.response.response.items

        if (items.length && items[0].id) {
          setUserConnector(items[items.length - 1])
          if (!items[0].relation) {
            setConnectorState('gateway')
            setErrorPoint('gateway')
          }
        } else {
          setIsValid(false)
          setUserConnector({ id: '', data: {} })
          setConnectorState('empty')
          setErrorPoint('empty')
        }
      }
    }
  }, [connectorsApi.response])

  useEffect(() => {
    if (userConnector.id && userConnector.id.length) {
      connectorDefinitionApi.setRefetch(true)
    }
  }, [userConnector])

  useEffect(() => {
    if (!isEmpty(connectorDefinitionApi.response)) {
      if (
        connectorDefinitionApi.response.result &&
        connectorDefinitionApi.response.response.items.length &&
        connectorDefinitionApi.response.response.items[0].id
      ) {
        const items = connectorDefinitionApi.response.response.items
        const mail = items.length ? items[0].data.msEmail : ''
        const wizard = items.length
          ? items[0].data.wizard
          : { step2: { status: false }, step8: { status: false } }

        const getState = () => {
          if (wizard.step2.status === false) {
            return 'setup1'
          } else if (wizard.step8.status === false) {
            return 'setup2'
          } else {
            return 'success'
          }
        }

        setIsValid(true)
        setConnectorMailId(mail)
        setConnectorState((value) =>
          value === 'gateway' ? 'gateway' : getState()
        )
        setErrorPoint((value) => (value === 'gateway' ? 'gateway' : getState()))
      }
    }
  }, [connectorDefinitionApi.response])

  useEffect(() => {
    isValid && relationApi.setRefetch(true)
  }, [isValid])

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log({ connectorState })
  }, [connectorState])

  useEffect(() => {
    if (!isEmpty(deleteConnectorApi.response)) {
      if (deleteConnectorApi.response.status) {
        message.success(translations.connector_deleted_success[language])
        setConnectorMode('None')
        setUserConnector({ id: '', data: {} })
        setConnectorState('')
        setErrorPoint('')
        setIsValid(null)
        deleteConnectorApi.setResponse({})
        setDeleteConnectorBody(null)
        connectorsApi.setRefetch(true)
        setConnectorMailId('')

        /* commenting out logout request as per this https://app.clickup.com/t/ccv8hp */
        /* const logoutRequest = {
          account: myMsalObject.getAccount(connectorMailId),
        }

        myMsalObject.logout(logoutRequest) */
      } else {
        message.error(translations.deleting_Connector_failed[language])
      }
    }
  }, [deleteConnectorApi.response])

  useEffect(() => {
    if (!isEmpty(deleteVoiceuserApi.response)) {
      if (deleteVoiceuserApi.response.status) {
        message.success(translations.voice_user_deleted_success[language])
        setVoiceuserMode('None')
        setInfoState(false)
        setFilter('')
        setUserConnector({ id: '', data: {} })
        setConnectorState('')
        setErrorPoint('')
        setIsValid(null)
        setDeleteVoiceUserBody(null)
        connectorsApi.setRefetch(true)
        dispatch(setActualVoiceUserData([]))
        dispatch(setModifiedVoiceUserData([]))
        dispatch(
          setUpdateVoiceUserOnTheFly({
            execute: false,
            postCallback: () => {},
          })
        )
      } else {
        message.error(translations.deleting_voice_user_failed[language])
      }
    }
  }, [deleteVoiceuserApi.response])

  useEffect(() => {
    if (connectorMode === 'Delete') {
      setConnectorAlert(true)
    }
  }, [connectorMode])

  useEffect(() => {
    if (voiceuserMode === 'Delete') {
      setVoiceuserAlert(true)
    }
  }, [voiceuserMode])

  const msOAuthErrorHandler = (err) => {
    /* const cancelErrCode = "user_cancelled" */

    // eslint-disable-next-line no-console
    console.error(err)

    if (err.errorCode === 'login_progress_error') {
      notification.warning({
        message: translations.microsoft_login_window_opened_warning[language],
        description:
          translations.microsoft_login_window_opened_warning[language],
      })
    }
  }

  const onRowAction = (action) => {
    setConnectorMode(action)
  }

  const onSideBarAction = (action) => {
    setVoiceuserMode(action)
  }

  const connectorAlertCancel = () => {
    setConnectorMode('None')
    setConnectorAlert(false)
  }

  const connectorAlertOkay = () => {
    if (connectorMode === 'Delete') {
      tokenApi.setRefetch(true)
      setConnectorAlert(false)
    }
  }

  useEffect(() => {
    if (deleteConnectorBody) {
      deleteConnectorApi.setRefetch(true)
    }
  }, [deleteConnectorBody])

  useEffect(() => {
    if (!isEmpty(tokenApi.response)) {
      if (tokenApi.response.result) {
        const relatedUsers = parseApiResult(relationApi.response)

        tokenApi.setResponse({})
        setDeleteConnectorBody(
          JSON.stringify({
            action: 'DELETE_CONNECTOR',
            data: {
              accountId: userAccounId,
              company_settings: accountSettings,
              user_settings: userSettings,
              voice_user:
                relatedUsers.length && relatedUsers[0].id ? relatedUsers : [],
            },
          })
        )
      }
    }
  }, [tokenApi.response])

  const msTeamsLoader = () => {
    return (
      <div className="spin-style">
        <Spinner spinning={true} />
      </div>
    )
  }

  const voiceuserAlertCancel = () => {
    setVoiceuserMode('None')
    setVoiceuserAlert(false)
  }

  useEffect(() => {
    if (deleteVoiceUserBody !== null) {
      deleteVoiceuserApi.setRefetch(true)
    }
  }, [deleteVoiceUserBody])

  const voiceuserAlertOkay = () => {
    const voiceUserDetails = parseApiResult(voiceUserDetailApi.response)

    if (voiceuserMode === 'Delete') {
      setVoiceuserAlert(false)
      setDeleteVoiceUserBody(
        JSON.stringify({
          action: 'DELETE_VOICEUSER',
          data: {
            accountId: userAccounId,
            company_settings: accountSettings,
            user_settings: userSettings,
            voice_user: voiceUserDetails.length ? voiceUserDetails[0] : {},
          },
        })
      )
    }
  }

  const closeAddUserPopup = () => {
    setAddUserPopup(false)
  }

  const closeInfoPanel = () => {
    if (isEqual(actualVoiceUserData, modifiedVoiceUserData)) {
      setInfoState(false)
    } else {
      setVoiceUserClose(true)
    }
  }

  const onSidebarUnsaveCancel = () => {
    dispatch(setActualVoiceUserData([]))
    dispatch(setModifiedVoiceUserData([]))
    setInfoState(false)
    setVoiceUserClose(false)
  }

  const onSidebarUnsaveClose = () => {
    setVoiceUserClose(false)
  }

  const onSidebarUnsaveOkay = () => {
    setVoiceUserClose(false)
    dispatch(
      setUpdateVoiceUserOnTheFly({
        execute: true,
        postCallback: () => {
          setInfoState(false)
        },
      })
    )
  }

  const onRowUnsaveCancel = () => {
    dispatch(setActualVoiceUserData([]))
    dispatch(setModifiedVoiceUserData([]))
    setRow(postCbData)
    setRowSwitch(false)
  }

  const onRowUnsaveClose = () => {
    setRowSwitch(false)
  }

  const onRowUnsaveOkay = () => {
    setRowSwitch(false)
    dispatch(
      setUpdateVoiceUserOnTheFly({
        execute: true,
        postCallback: () => {
          setRow(postCbData)
        },
      })
    )
  }

  const handleMsLogin = () => {
    oauthMicrosoft(
      connectorMailId,
      () => {
        setMsLoginLoading(false)
        setAddUserPopup(true)
      },
      (error) => {
        setMsLoginLoading(false)
        msOAuthErrorHandler(error)
      }
    )
  }

  const checkIsEmpty = (voiceUserData) => {
    if (!voiceUserData.length) {
      return true
    }

    if (voiceUserData[0].id) {
      return false
    } else {
      return true
    }
  }

  const getDescription = (text) => {
    return (
      <Fragment>
        <Badge status="error" style={{ marginRight: 5 }} />
        {translations[text][language]}
      </Fragment>
    )
  }

  const filterResult = (array, value) => {
    return array.filter(({ data }) => {
      return data.displayName.toLowerCase().indexOf(value.toLowerCase()) !== -1
    })
  }

  const voiceUserTable = useMemo(() => {
    const result = parseApiResult(relationApi.response)

    if (checkIsEmpty(result)) {
      const txt = getDescription(
        'you_have_not_added_any_user_from_your_microsoft_teams'
      )

      return <Empty description={txt}>{renderAddUserButton()}</Empty>
    }

    const voiceUserData = filter.length ? filterResult(result, filter) : result
    const getStatus = (status) => {
      switch (status) {
        case 'SETUP_IN_PROGRESS':
          return {
            icon: () => <LoadingOutlined />,
            text: translations.configuration_in_progress[language],
            title: translations.progressHelpText[language],
          }

        case 'MODIFY_IN_PROGRESS':
          return {
            icon: () => <LoadingOutlined />,
            text: translations.modify_in_progress[language],
            title: translations.progressHelpText[language],
          }

        case 'DELETE_IN_PROGRESS':
          return {
            icon: () => <LoadingOutlined />,
            text: translations.deletion_in_progres[language],
            title: translations.progressHelpText[language],
          }

        case 'SETUP_COMPLETED':
          return {
            icon: () => <CheckOutlined />,
            text: translations.configuration_completed[language],
            title: '',
          }

        default:
          return {}
      }
    }

    const data =
      voiceUserData.length && voiceUserData[0].id
        ? voiceUserData.map((voiceUser) => {
            return {
              id: voiceUser.id,
              key: voiceUser.id,
              status:
                voiceUser && voiceUser.data && voiceUser.data.status
                  ? getStatus(voiceUser.data.status)
                  : {},
              name:
                voiceUser && voiceUser.data && voiceUser.data.displayName
                  ? voiceUser.data.displayName
                  : '',
            }
          })
        : []

    const columns = [
      {
        defaultSortOrder: 'ascend',
        dataIndex: 'name',
        sorter: (a, b) => {
          const wordA = a.name.toLowerCase()
          const wordB = b.name.toLowerCase()

          if (wordA < wordB) {
            return -1
          } else if (wordA > wordB) {
            return 1
          } else {
            return 0
          }
        },
        title: translations.voice_users[language],
        width: '50%',
      },
      {
        title: translations.status[language],
        width: '50%',
        render: (text, record) => {
          return (
            <Fragment>
              <Tooltip title={record.status.title}>
                {record.status.icon()}
              </Tooltip>
              <span style={{ marginLeft: 15 }}>{record.status.text}</span>
            </Fragment>
          )
        },
      },
    ]

    const rowSelectHandler = (rowSelected = {}) => {
      if (Object.keys(rowSelected).length) {
        phoneApi.setRefetch(true)
        getAllVoiceUsers.setRefetch(true)
        if (row.id === rowSelected.id) {
          setInfoState(true)
        } else if (isEqual(actualVoiceUserData, modifiedVoiceUserData)) {
          setInfoState(true)
          setRow(rowSelected)
        } else {
          setPostCbData(rowSelected)
          setRowSwitch(true)
        }
      }
    }

    const yscroll = optionMedia ? 'calc(100vh - 200px)' : 'calc(100vh - 190px)'

    return (
      <MemoTable
        columns={columns}
        dataSource={data}
        isSelectable={true}
        selectedRow={row}
        onSelectedRowChange={rowSelectHandler}
        scrollObject={{ y: yscroll }}
        style={{ height: '100%' }}
      />
    )
  }, [
    relationApi.response,
    row,
    filter,
    msLoginLoading,
    actualVoiceUserData,
    modifiedVoiceUserData,
    optionMedia,
  ])

  const savedCallBack = (data) => {
    relationApi.setResponse(data.relationResponse)
    voiceUserDetailApi.setResponse(data.detailsResponse)
    voiceUserPhoneApi.setResponse(data.phoneResponse)
  }

  const renderInfoPanelChild = () => {
    const data = parseApiResult(voiceUserDetailApi.response)
    const phoneData = parseApiResult(voiceUserPhoneApi.response)

    return (
      <div style={{ padding: 5, height: '100%' }}>
        <EditVoiceUsers
          microsoftTeamsPermission={microsoftTeamsPermission}
          connectorId={userConnector.id}
          callingPlansApi={callingPlansApi}
          data={data.length ? data[0] : {}}
          voiceUserDetailApi={voiceUserDetailApi}
          deleteVoiceUser={onSideBarAction}
          phonenumbers={phonenumbers}
          phoneData={
            phoneData.length && phoneData[0].id && phoneData[0].id.length
              ? phoneData[0]
              : {
                  data: {},
                  id: '',
                  name: '',
                }
          }
          savedCallBack={savedCallBack}
          existingUsers={getExistingUsersData}
        />
      </div>
    )
  }

  const renderSearchField = useMemo(() => {
    if (checkIsEmpty(parseApiResult(relationApi.response))) {
      return <Fragment />
    }

    return (
      <Input
        className="MsTeams-SearchField"
        onChange={(event) => setFilter(event.target.value)}
        placeholder={translations.search[language]}
        prefix={<SearchOutlined />}
        value={filter}
      />
    )
  }, [relationApi.response, filter])

  const renderMoreActions = () => {
    if (microsoftTeamsPermission === FULL_ACCESS) {
      return (
        <MoreActions
          actionCallBack={onRowAction}
          contents={[{ text: translations.delete[language] }]}
          placement="bottomRight"
        />
      )
    } else {
      return <Fragment />
    }
  }

  const displayDetails = () => {
    const flexStyle = optionMedia ? 'column' : 'row'
    const text =
      translations.plug2teams_is_connected_to_your_microsoft_account[language]

    return (
      <div
        className="user-connector-style"
        style={{ flexDirection: flexStyle, padding: '0px 5px' }}
      >
        <div style={{ marginRight: 5, textAlign: 'left', width: '100%' }}>
          {text}
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ fontWeight: 'bold', marginRight: 5 }}>
            {connectorMailId}
          </span>
          {renderMoreActions()}
        </div>
      </div>
    )
  }

  const renderTableAndPanel = () => {
    if (relationApi.loading) {
      return msTeamsLoader()
    } else {
      return (
        <div className="panel-cont">
          <div className={infoState ? 'ms-active-style' : 'ms-inactive'}>
            {voiceUserTable}
          </div>
          <InfoPanel
            changeVisiblity={closeInfoPanel}
            visiblity={infoState}
            width={'320px'}
          >
            {voiceUserDetailApi.loading
              ? msTeamsLoader()
              : renderInfoPanelChild()}
          </InfoPanel>
        </div>
      )
    }
  }

  const teamsView = () => {
    const optionStyle = optionMedia
      ? 'MicrosoftTeams-OptionBody-small'
      : 'MicrosoftTeams-OptionBody-big'
    const tableStyle = optionMedia
      ? 'MicrosoftTeams-TableBody-small'
      : 'MicrosoftTeams-TableBody-big'

    return (
      <div className="MicrosoftTeams-InnerBody">
        <div className={optionStyle}>
          <div className="user-connector-style">{displayDetails()}</div>
          <div className="MsTeams-SearchContainer">
            {renderSearchField}
            {renderAddUserButton()}
          </div>
        </div>
        <div className={tableStyle}>{renderTableAndPanel()}</div>
      </div>
    )
  }

  const handleAutoSetup = () => {
    oauthMicrosoft(
      connectorMailId,
      () => {
        setMsLoginLoading(false)
        setConnectorState('inprogress')
        dispatch(setTeamsStep('2'))
      },
      (error) => {
        setMsLoginLoading(false)
        msOAuthErrorHandler(error)
      }
    )
  }

  const renderContinueAutoSetup = () => {
    return (
      <PttButton
        disabled={msLoginLoading || microsoftTeamsPermission !== FULL_ACCESS}
        loading={msLoginLoading}
        type="primary"
        onClick={() => {
          setMsLoginLoading(true)
          handleAutoSetup()
        }}
      >
        {translations.continue_auto_setup[language]}
      </PttButton>
    )
  }

  const continueView = () => {
    const optionStyle = optionMedia
      ? 'MicrosoftTeams-OptionBody-small'
      : 'MicrosoftTeams-OptionBody-big'
    const tableStyle = optionMedia
      ? 'MicrosoftTeams-TableBody-small'
      : 'MicrosoftTeams-TableBody-big'

    return (
      <div className="MicrosoftTeams-InnerBody">
        <div className={optionStyle}>
          <div className="user-connector-style">{displayDetails()}</div>
          <div className="MsTeams-SearchContainer">
            {renderSearchField}
            {renderContinueAutoSetup()}
          </div>
        </div>
        <div className={tableStyle}>
          <Empty description={getDescription('continue_auto_setup_text')}>
            {renderContinueAutoSetup()}
          </Empty>
        </div>
      </div>
    )
  }

  const getCallingPlansData = () => {
    // eslint-disable-next-line no-negated-condition
    if (!isEmpty(callingPlansApi.response)) {
      if (
        callingPlansApi.response.result &&
        callingPlansApi.response.response.items.length &&
        callingPlansApi.response.response.items[0].id
      ) {
        return callingPlansApi.response.response.items
      } else {
        return []
      }
    } else {
      return []
    }
  }

  const updatePhoneListCallback = () => {
    phoneApi.setRefetch(true)
  }

  const AddConnectionView = () => {
    return (
      <AddMsTeams
        connectorsApi={connectorsApi}
        existingConnectorId={userConnector.id}
        connectorMailId={connectorMailId}
        msOAuthErrorHandler={msOAuthErrorHandler}
        callingPlansData={getCallingPlansData()}
        updatePhoneListCallback={updatePhoneListCallback}
        connectorState={connectorState}
        errorPoint={errorPoint}
        microsoftTeamsPermission={microsoftTeamsPermission}
      />
    )
  }

  const getExistingUsersData = useMemo(() => {
    if (Object.keys(getAllVoiceUsers.response).length) {
      const result = parseApiResult(getAllVoiceUsers.response)
      const users = result.length && result[0].id ? result : []
      const data = users.map((user) => {
        const phone = user.relation?.phone ? user.relation.phone : []

        return {
          id: user?.id,
          extension: user?.data?.extension,
          phonenumber: phone.length ? phone[0].id : '',
        }
      })

      return data
    } else {
      return []
    }
  }, [getAllVoiceUsers.response])

  const renderContent = () => {
    if (
      isValid === null ||
      connectorsApi.loading ||
      connectorDefinitionApi.loading ||
      deleteConnectorApi.loading ||
      deleteVoiceuserApi.loading ||
      relationApi.loading ||
      tokenApi.loading ||
      connectorState.length === 0
    ) {
      return msTeamsLoader()
    }

    if (connectorState === 'empty' || connectorState === 'inprogress') {
      return AddConnectionView()
    } else if (
      connectorState === 'gateway' ||
      connectorState === 'setup1' ||
      connectorState === 'setup2'
    ) {
      return continueView()
    } else if (connectorState === 'success') {
      return teamsView()
    } else {
      return <React.Fragment />
    }
  }

  const renderDeleteConnectorPopup = () => {
    return (
      <ConfirmPopup
        onCancel={connectorAlertCancel}
        onOkay={connectorAlertOkay}
        visiblity={connectorAlert}
        okayText={translations.delete[language]}
        confirmText={translations.delete_connector[language]}
        type="danger"
      >
        {connectorMode === 'Delete' &&
          translations.delete_connector_info[language]}
      </ConfirmPopup>
    )
  }

  const renderDeleteVoiceUserPopup = () => {
    return (
      <ConfirmPopup
        onCancel={voiceuserAlertCancel}
        onOkay={voiceuserAlertOkay}
        visiblity={voiceuserAlert}
        confirmText={translations.delete_voice_user[language]}
        okayText={translations.delete_voice_user[language]}
        type="danger"
        okBtnClassName="on-button-style"
      >
        {voiceuserMode === 'Delete' &&
          translations.delete_voice_user_info[language]}
      </ConfirmPopup>
    )
  }

  const renderSibebarUnsavePopup = () => {
    return (
      <ConfirmPopup
        cancelText={translations.no[language]}
        closable={true}
        confirmText={translations.voice_user_unsaved_changes[language]}
        okayText={translations.yes[language]}
        onCancel={onSidebarUnsaveCancel}
        onClose={onSidebarUnsaveClose}
        onOkay={onSidebarUnsaveOkay}
        visiblity={voiceUserClose}
      >
        {translations.voice_user_unsaved_changes_info[language]}
      </ConfirmPopup>
    )
  }

  const renderRowUnsavePopup = () => {
    return (
      <ConfirmPopup
        cancelText={translations.no[language]}
        closable={true}
        confirmText={translations.voice_user_unsaved_changes[language]}
        okayText={translations.yes[language]}
        onCancel={onRowUnsaveCancel}
        onClose={onRowUnsaveClose}
        onOkay={onRowUnsaveOkay}
        visiblity={rowSwitch}
      >
        {translations.voice_user_unsaved_changes_info[language]}
      </ConfirmPopup>
    )
  }

  return (
    <div className="MicrosoftTeams-Body">
      <AddUser
        connectorId={userConnector.id}
        connectorMailId={connectorMailId}
        onClose={closeAddUserPopup}
        relationApi={relationApi}
        visiblity={addUserPopup}
        msOAuthErrorHandler={msOAuthErrorHandler}
        callingPlansData={getCallingPlansData()}
        relationData={parseApiResult(relationApi.response)}
        updatePhoneListCallback={updatePhoneListCallback}
        existingUsers={getExistingUsersData}
      />
      {renderContent()}
      {renderDeleteConnectorPopup()}
      {renderDeleteVoiceUserPopup()}
      {renderSibebarUnsavePopup()}
      {renderRowUnsavePopup()}
    </div>
  )
}

export default MsTeams
