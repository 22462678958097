// @flow

import React, { useState, useEffect } from 'react'
import { Layout, Menu, PageHeader } from 'antd'
import StatsDashboard from './StatsDashboard'
import useCustomFetch from '../../Hooks/UseCustomFetch'
import { APIURL } from '../../utils/constants'
import { fetchEnviroinment, parseApiResult } from '../../utils/helpers'
import Spinner from '../../Spinner/spinner'
import { connect } from 'react-redux'

type Props = {
  pageTitle: string,
  language: string,
}

const StatsSidebar = (props: Props) => {
  const { pageTitle, language } = props
  const { SubMenu } = Menu
  const { Sider } = Layout

  const [selectedDashboardUrl, setSelectedDashboardUrl] = useState('')
  const [statsData, setStatsData] = useState({ id: '' })

  /* get selected dashboard info */
  const [dashboardApi] = useCustomFetch(
    `${APIURL}/system_config/STATS`,
    'GET',
    true
  )

  useEffect(() => {
    const result = parseApiResult(dashboardApi.response)

    if (result.length) {
      setStatsData(result[0].data)
    }
  }, [dashboardApi.response])

  const getEnviroinmentName = () => {
    const name = fetchEnviroinment('name')

    if (name === 'dev') {
      return 'dev-'
    } else if (name === 'stage') {
      return 'staging-'
    } else {
      return ''
    }
  }

  const onDashboardSelection = (dashboard) => {
    let url = dashboard
    const name = getEnviroinmentName()

    if (url.includes('{ENV}')) {
      url = url.replace('{ENV}', name)
    }

    setSelectedDashboardUrl(url)
  }

  return (
    <div className="stat-cont-wrap">
      <div className="stat-sidebar-wrap">
        <PageHeader backIcon={false} style={{ padding: 0 }} title={pageTitle} />
        <Layout className="site-layout-background stat-sidebar">
          {dashboardApi.loading && (
            <div className="spinner-div">
              <Spinner spinning={true} />
            </div>
          )}

          {!dashboardApi.loading && (
            <Sider className="site-layout-background" width={200}>
              <Menu mode="inline" style={{ height: '100%' }}>
                {((statsData && statsData.groups) || []).map((group) => (
                  <SubMenu key={group.id} title={group.label[language]}>
                    {(group.dashboards || []).map((dashboard) => (
                      <Menu.Item
                        key={dashboard.id}
                        onClick={() => onDashboardSelection(dashboard.url)}
                      >
                        {dashboard.label[language]}
                      </Menu.Item>
                    ))}
                  </SubMenu>
                ))}
              </Menu>
            </Sider>
          )}
        </Layout>
      </div>
      {!dashboardApi.loading && (
        <StatsDashboard dashboardUrl={selectedDashboardUrl} />
      )}
    </div>
  )
}

const mapsStateToProps = (state) => {
  return {
    translations: state.sampleReducer.translations || {},
    language: state.sampleReducer.language,
  }
}

export default connect(mapsStateToProps, null)(StatsSidebar)
