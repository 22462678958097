// @flow

import React from 'react'
import { connect } from 'react-redux'
import { COMMON_IMG_URL } from '../../../../utils/constants'
import './ActionsLibrary.css'

type Props = {
  nodeList: $ReadOnlyArray<*>,
  translations: Object,
  language: string,
}

const ActionsLibrary = ({ nodeList, translations, language }: Props) => {
  const onDragStart = (event) => {
    event.dataTransfer.setData('text/plain', event.target.id)
  }

  return (
    <div className="ActionsLibrary__Container">
      <div className="ActionsLibrary__Heading">
        {translations.actions_library[language]}
      </div>
      <div className="ActionsLibrary__Section">
        {nodeList.map(({ data, id }) => (
          <div
            className="ActionsLibrary__DragItem"
            draggable
            key={id}
            id={id}
            onDragStart={onDragStart}
          >
            <img
              alt=""
              className="ActionsLibrary__Img"
              src={`${COMMON_IMG_URL}nodeicon/${data.icon}.png`}
            />
            {data.label[language]}
          </div>
        ))}
      </div>
    </div>
  )
}

const mapStateToProps = ({ incomingCallsReducer, sampleReducer }) => ({
  nodeList: incomingCallsReducer.nodeList || [],
  translations: sampleReducer.translations || {},
  language: sampleReducer.language,
})

export default connect(mapStateToProps)(ActionsLibrary)
