/* eslint-disable react/prop-types */
/* eslint-disable no-negated-condition */
//  @flow

import React, { useState, Fragment } from 'react'
import { Input, message } from 'antd'
import { LockOutlined, MailOutlined } from '@ant-design/icons'
import { bindActionCreators } from 'redux'
import { connect, useDispatch } from 'react-redux'
import { useHistory, withRouter } from 'react-router-dom'
import 'antd/dist/antd.css'
import * as actions from '../../actions/SampleAction'
import { putService } from '../../services/RequestService'
import {
  COMMON_IMG_URL,
  EXPIRE,
  LOGINAUTH1URL,
  LOGINAUTH2URL,
  NO_ACCESS,
} from '../../utils/constants'
import StartupImagePanel from '../StartUps/StartupImagePanel'
import StartupAddons from '../StartUps/StartupAddons'
import { StartupButton } from '../CustomButton/custombutton'
import Spinner from '../../Spinner/spinner'
import { removeTokens, setTokens } from '../../utils/LocalStorage'
import { getItem } from '../../utils/storage'
import UseConstructor from '../../Hooks/UseConstructor'
import { getBrandFromUrl } from '../../utils/helpers'

const logo = `${COMMON_IMG_URL}branding/PTT/logo1.png`

type Props = {
  history: History,
  setUserDetails: (any) => mixed,
  translations: Object,
  language: String,
  isResellerAccount: boolean,
  appUrlBrand: String,
}

const Login = ({
  translations,
  language,
  setUserDetails,
  isResellerAccount,
  appUrlBrand,
  microsoftTeamsPermission,
  phoneNumberPermission,
  statsPermission,
  advancedPermission,
  billingPermission,
}: Props) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const checkIsAuthenticated = () => {
    if (getItem(EXPIRE) && Number(getItem(EXPIRE)) * 1000 > Date.now()) {
      const route = () => {
        if (microsoftTeamsPermission !== NO_ACCESS) {
          return '/msteams'
        } else if (phoneNumberPermission !== NO_ACCESS) {
          return '/phonenumbers'
        } else if (statsPermission !== NO_ACCESS) {
          return '/stats'
        } else if (advancedPermission !== NO_ACCESS) {
          return '/advanced'
        } else if (billingPermission !== NO_ACCESS) {
          return '/billing'
        } else {
          return '/settings'
        }
      }

      const path = isResellerAccount ? '/clients' : route()

      history.push({ pathname: path })
    } else {
      removeTokens()
    }
  }

  const loadBrandFromUrl = () => {
    dispatch(actions.setAppUrlBrand(getBrandFromUrl()))
  }

  UseConstructor(loadBrandFromUrl)
  UseConstructor(checkIsAuthenticated)

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)

  const messages = {
    success: translations.logged_in_successfully[language],
    failed: translations.oops_something_went_wrong[language],
    invalid: translations.invalid_username_or_password[language],
    username: translations.please_enter_username[language],
    password: translations.please_enter_password[language],
  }

  const onUsernameChange = (event) => {
    setUsername(event.target.value)
  }

  const onPasswordChange = (event) => {
    setPassword(event.target.value)
  }

  const onLoginClick = () => {
    if (username.length && password.length) {
      setLoading(true)
      loginAuthOne()
    } else if (!username.length) {
      message.warning(messages.username)
    } else if (!password.length) {
      message.warning(messages.password)
    }
  }

  const loginAuthOne = () => {
    const data = {
      username: String(username).trim(),
      password: String(password).trim(),
      permisionlist: [],
    }

    putService(
      LOGINAUTH1URL,
      JSON.stringify(data),
      loginAuthOneResultHandler,
      loginAuthOneErrorHandler
    )
  }

  const loginAuthTwo = (code) => {
    putService(
      LOGINAUTH2URL,
      JSON.stringify({ code: code }),
      loginAuthTwoResultHandler,
      loginAuthTwoErrorHandler
    )
  }

  const loginAuthOneResultHandler = (response) => {
    if (response.result) {
      loginAuthTwo(response.code)
    } else {
      message.error(messages.invalid)
      setLoading(false)
    }
  }

  const loginAuthOneErrorHandler = () => {
    message.error(messages.failed)
    setLoading(false)
  }

  const loginAuthTwoResultHandler = (response) => {
    if (response.result === true) {
      setTokens(response)
      localStorage.setItem('user', username)
      setUserDetails({ username: username })
      setTimeout(() => {
        message.success(messages.success)
        history.push({ pathname: '/' })
      }, 1000)
    } else {
      message.error(messages.failed)
      setLoading(false)
    }
  }

  const loginAuthTwoErrorHandler = () => {
    message.error(messages.failed)
    setLoading(false)
  }

  const redirectToRecoverpage = () => {
    history.push({ pathname: '/recoverpassword' })
  }

  const renderInputs = () => {
    return (
      <Fragment>
        <div>
          <Input
            className="start-up-input"
            onChange={onUsernameChange}
            onPressEnter={onLoginClick}
            prefix={<MailOutlined className="startup-prefix-style" />}
            placeholder={translations.email[language]}
            size="large"
            value={username}
          />
        </div>
        <div>
          <Input.Password
            className="start-up-input"
            onChange={onPasswordChange}
            onPressEnter={onLoginClick}
            placeholder={translations.password[language]}
            prefix={<LockOutlined className="startup-prefix-style" />}
            size="large"
            value={password}
          />
        </div>
        <div
          className="startup-link"
          onClick={() => redirectToRecoverpage()}
          style={{
            marginTop: 15,
            textAlign: 'right',
          }}
        >
          {translations.forgot_your_password[language]}
        </div>
        <StartupButton
          block
          disabled={loading}
          loading={loading}
          onClick={onLoginClick}
        >
          {translations.login[language]}
        </StartupButton>
      </Fragment>
    )
  }

  const renderLogo = () => {
    if (appUrlBrand === 'Plug2Teams') {
      return (
        <div className="align-center">
          <img alt="pttlogo" className="signin-colored-logo" src={logo} />
        </div>
      )
    } else {
      return <Fragment />
    }
  }

  const renderAddons = () => {
    if (appUrlBrand === 'Plug2Teams') {
      return <StartupAddons option="Link" linkTo="signup" />
    } else {
      return <Fragment />
    }
  }

  return (
    <div className="startup-container">
      <div className="startup-image-section">
        <StartupImagePanel text={translations.startup_image_text[language]} />
      </div>
      <div className="startup-field-section">
        <div className="align-center full-height">
          <Spinner color="#00569D" spinning={loading}>
            <div className="align-center startup-field-container">
              <div className="startup-field-inputs">
                {renderLogo()}
                <div className="align-center startup-title">
                  {translations.login[language]}
                </div>
                {renderInputs()}
                {renderAddons()}
              </div>
            </div>
          </Spinner>
        </div>
      </div>
    </div>
  )
}

const mapsStateToProps = (state) => {
  return {
    translations: state.sampleReducer.translations || {},
    language: state.sampleReducer.language,
    isResellerAccount: state.sampleReducer.isResellerAccount,
    appUrlBrand: state.sampleReducer.appUrlBrand,
    microsoftTeamsPermission: state.permissionReducer.microsoftTeamsPermission,
    phoneNumberPermission: state.permissionReducer.phoneNumberPermission,
    statsPermission: state.permissionReducer.statsPermission,
    advancedPermission: state.permissionReducer.advancedPermission,
    billingPermission: state.permissionReducer.billingPermission,
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...actions }, dispatch)

export default withRouter(connect(mapsStateToProps, mapDispatchToProps)(Login))
