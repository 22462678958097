// @flow

import React from 'react'
import { NODE_TYPES } from '../nodetypes'
import AudioUploader from './AudioUploader'
import Audiobar from './Audiobar'

type Props = {
  label?: string,
  initialValue: $ReadOnlyArray<*>,
  type?: string,
  keyName: string,
  translations: Object,
  language: string,
  mode: string,
  autoSaveHandler: (any) => mixed,
}

var updateCallFlow = false

const CustomDropZone = React.forwardRef<Props, *>(
  (
    {
      initialValue = [],
      label = 'Drop Zone',
      keyName,
      translations,
      language,
      mode,
      autoSaveHandler,
    }: Props,
    ref
  ) => {
    const [update, setUpdate] = React.useState(false)
    const [file, setFile] = React.useState(initialValue)

    React.useImperativeHandle(ref, () => ({
      getLatestValue() {
        return file
      },
      getName() {
        return keyName
      },
      getType() {
        return NODE_TYPES.audio
      },
      resetValue() {
        setFile([])
      },
    }))

    React.useEffect(() => {
      if (update && mode === 'Edit' && autoSaveHandler) {
        autoSaveHandler(NODE_TYPES.audio, updateCallFlow)
        setUpdate(false)
        updateCallFlow = false
      }
    }, [update])

    React.useEffect(() => {
      setFile(initialValue)
    }, [initialValue])

    const renderAudioUploader = () => {
      return (
        <div style={{ height: 155 }}>
          <AudioUploader
            doneCb={(file) => {
              setFile(file)
              setUpdate(true)
            }}
            translations={translations}
            language={language}
          />
        </div>
      )
    }

    const renderAudioFile = () => {
      return (
        <Audiobar
          details={file}
          deleteCb={() => {
            setFile([])
            setUpdate(true)
            updateCallFlow = false
          }}
          translations={translations}
          language={language}
        />
      )
    }

    return (
      <React.Fragment>
        {<div className="label-spacing"> {label} </div>}
        {file.length ? renderAudioFile() : renderAudioUploader()}
      </React.Fragment>
    )
  }
)

export default React.memo<Props>(CustomDropZone)
