import React, { useEffect, useState } from 'react'
import { Table } from 'antd'
import { useSelector } from 'react-redux'
import { getTranslatedText } from '../../../utils/translations'
import ModalLayout, { Body/* , Footer */, Header } from '../../Popup/popup'
/* import PttButton from '../../CustomButton/PttButton' */

const VoiceUserInfoModal = ({ data = {}, onCancel, visiblity, onOkay }) => {
  const { language, translations } = useSelector(({ sampleReducer }) => ({
    language: sampleReducer.language || 'en',
    translations: sampleReducer.translations || {},
  }))

  const [columns, setColumns] = useState([])
  const [dataSource, setDataSource] = useState([])

  useEffect(() => {
    if (Object.keys(data).length) {
      setColumns([
        {
          dataIndex: 'user',
          title: getTranslatedText(translations, language, 'voice_users'),
          width: '50%',
        },
        {
          align: 'right',
          dataIndex: 'price',
          title: getTranslatedText(translations, language, 'monthly_price'),
          width: '50%',
        },
      ])
      setDataSource(
        (data.details || []).map((voiceUser) => {
          return {
            key: voiceUser.microsoft_id,
            price: data.unit_price,
            user: voiceUser.displayName,
          }
        })
      )
    }
  }, [data])

  return (
    <ModalLayout
      className="subscription-info-modal"
      destroyOnClose={true}
      onCancel={onCancel}
      visible={visiblity}
    >
      <Header>
        {getTranslatedText(translations, language, 'subscribed_voice_users')}
      </Header>
      <Body>
        <Table
          className="voiceuser-info-modal"
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          scroll={{ y: '40vh' }}
        />
      </Body>
      {/* <Footer>
        {
          <PttButton type="primary" onClick={onOkay}>
            {translations.okay[language]}
          </PttButton>
        }
      </Footer> */}
    </ModalLayout>
  )
}

export default VoiceUserInfoModal
