import { UserOutlined } from '@ant-design/icons'

import { ReactComponent as Building } from '../../assets/icon/building.svg'
import { ReactComponent as Callflow } from '../../assets/icon/new-callflow.svg'
import { ReactComponent as Exit } from '../../assets/icon/new-exit.svg'
import { ReactComponent as IncomingCall } from '../../assets/icon/new-incoming-call.svg'
import { ReactComponent as Stats } from '../../assets/icon/new-stats.svg'
import { ReactComponent as MsTeamsIcon } from '../../assets/icon/new-msteams.svg'
import { ReactComponent as CreditCardIcon } from '../../assets/icon/new-credit-card.svg'
import { ReactComponent as Setting } from '../../assets/icon/new-setting.svg'
import { ReactComponent as Chat } from '../../assets/icon/chat.svg'
import { ReactComponent as Clients } from '../../assets/icon/rating.svg'
import { ReactComponent as Advance } from '../../assets/icon/advance.svg'

export const BuildingIcon = Building
export const CallFlowIcon = Callflow
export const LogoutIcon = Exit
export const PhoneIcon = IncomingCall
export const StatsIcon = Stats
export const TeamsIcon = MsTeamsIcon
export const UserIcon = UserOutlined
export const BillingIcon = CreditCardIcon
export const SettingIcon = Setting
export const ChatIcon = Chat
export const ClientIcon = Clients
export const AdvanceIcon = Advance