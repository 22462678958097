// @flow

import React, { useEffect, useImperativeHandle, useState } from 'react'
import { Button, Input, Select } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import './mapnumbertonode.css'
import { COMMON_IMG_URL } from '../../../utils/constants'
import { NODE_TYPES } from '../nodetypes'
import {
  getNewArray,
  keyDownHandler,
  pasteHandler,
} from '../../../utils/helpers'

type Props = {
  autoSaveHandler?: (any) => mixed,
  dropDownData: $ReadOnlyArray<*>,
  isAddAction: boolean,
  keyName: string,
  label?: string,
  menuOptions?: $ReadOnlyArray<*>,
  mode: string,
  translations: Object,
  language: string,
}

const MapNumberToNode = React.forwardRef<Props, *>((props: Props, ref) => {
  const {
    isAddAction = false,
    label = 'Label',
    menuOptions = [],
    translations,
    language,
  } = props
  const [update, setUpdate] = useState(false)
  const [menuData, setMenuData] = useState(menuOptions)

  useEffect(() => {
    if (!isAddAction) {
      setMenuData(menuOptions)
    }
  }, [menuOptions])

  useImperativeHandle(ref, () => ({
    getLatestValue() {
      return menuData
    },
    getName() {
      return props.keyName
    },
    getType() {
      return NODE_TYPES.mapNumberToNode
    },
    resetValue() {
      setMenuData([])
    },
  }))

  const addHandler = () => {
    const data = getNewArray(menuData)

    data.push({ key: '', value: '' })
    setMenuData(data)
  }

  const deleteHandler = (event, key) => {
    setMenuData(menuData.filter((data, index) => index !== key))
    setUpdate(true)
  }

  const valueChangeHandler = (value = '', key) => {
    const updatedRow = setRowDataByProp(menuData, 'value', key, value)
    const rowData = getRowDataByProp(menuData, 'value', key)

    setMenuData(updatedRow)
    if (rowData.value !== value || !value.length) {
      setUpdate(true)
    }
  }

  const keyChangeHandler = (event, key) => {
    const updatedRow = setRowDataByProp(
      menuData,
      'key',
      key,
      event.target.value
    )

    setMenuData(updatedRow)
  }

  const blurHandler = (event, key) => {
    const rowData = getRowDataByProp(menuData, 'key', key)

    if (rowData.value !== event.target.value) {
      setUpdate(true)
    }
  }

  const getRowDataByProp = (array, prop, value) => {
    let rowData = { key: '', value: '' }
    let isFound = false

    if (array.length) {
      array.forEach((data) => {
        if (data[prop] === value && isFound) {
          rowData = data
          isFound = true
        }
      })
    }

    return rowData
  }

  const setRowDataByProp = (array, prop, value, replacement) => {
    const newArray = getNewArray(array)
    let isReplaced = false

    newArray.forEach((data) => {
      if (data.key === value && !isReplaced) {
        data[prop] = replacement
        isReplaced = true
      }
    })

    return isReplaced ? newArray : array
  }

  useEffect(() => {
    if (update && props.mode === 'Edit' && props.autoSaveHandler) {
      props.autoSaveHandler(NODE_TYPES.mapNumberToNode)
      setUpdate(false)
    }
  }, [update])

  const renderOptions = () =>
    props.dropDownData.map(({ callFlowId, icon, name }, index) => (
      <Select.Option key={index} value={callFlowId}>
        <img
          alt=""
          className="opt-image"
          src={`${COMMON_IMG_URL}nodeicon/${icon ? icon : ''}.png`}
        />
        {name}
      </Select.Option>
    ))

  const renderRow = (data, index) => {
    return (
      <div className="row-container" key={index}>
        <div className="index-container">
          <Input
            onBlur={(event) => blurHandler(event, data.key)}
            onChange={(event) => keyChangeHandler(event, data.key)}
            onKeyDown={(event) => keyDownHandler(event, 'allowOnlyNumbers')}
            onPaste={(event) => pasteHandler(event, 'allowOnlyNumbers')}
            value={data.key}
          />
        </div>
        <div className="select-container">
          <Select
            allowClear={data.value.length > 0}
            onChange={(value) => valueChangeHandler(value, data.key)}
            placeholder="Select Action"
            style={{ width: '100%' }}
            value={data.value}
          >
            {renderOptions()}
          </Select>
        </div>
        <DeleteOutlined
          className="delete-container"
          onClick={(event) => deleteHandler(event, index)}
        />
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className="label-spacing">{label}</div>
      <div className="list-box-style">
        <div style={{ height: 'calc(100% - 27px)', overflow: 'auto' }}>
          {menuData.map((data, index) => renderRow(data, index))}
        </div>
        <Button type="dashed" size="small" block onClick={addHandler}>
          {translations.add_menu_option[language]}
        </Button>
      </div>
    </React.Fragment>
  )
})

export default React.memo<Props>(MapNumberToNode)
