// @flow

import React, { useEffect, useMemo, useState } from 'react'
import { Empty, Select, Spin, Table } from 'antd'
import useCustomFetch from '../../../../Hooks/UseCustomFetch'
import {
  ACTIONAPIURL,
  API_MANAGER_KEY,
  CUSTOM_ACTION_URL,
  ID_TOKEN,
  TOKEN_TYPE,
} from '../../../../utils/constants'
import {
  convertToCent,
  convertToDollar,
  getNewArray,
  parseApiResult,
} from '../../../../utils/helpers'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setSelectedPhoneNoDetails } from '../../../../actions/phoneNumberAction'
import CountrySelect from '../../../CustomComponents/CountrySelect'
import useMatchMedia from '../../../../Hooks/UseMatchMedia'
import { getItem } from '../../../../utils/storage'
import axios from 'axios'
import { cloneDeep } from 'lodash'
import { LoadingOutlined } from '@ant-design/icons'

type Props = {
  enablePhoneNoSelected: (any) => mixed,
  phoneNumbersSelected: Object,
  setSelectedPhoneNoDetails: (any) => mixed,
  selectedPhoneNumberDetails: Object,
  translations: Object,
  language: string,
}

let cityPaginationTotal = 0
let cityPaginationProcess = false
let cityPaginationNumber = 2

const AddPhoneNumber = (props: Props) => {
  const isSmall = useMatchMedia('(max-width: 1088px)')
  const cityPagination = axios.create({
    baseURL: `${CUSTOM_ACTION_URL}`,
    headers: {
      Authorization: `${getItem(TOKEN_TYPE)} ${getItem(ID_TOKEN)}`,
      'Content-Type': 'application/json',
      'x-api-key': API_MANAGER_KEY,
    },
  })

  const {
    enablePhoneNoSelected,
    setSelectedPhoneNoDetails,
    selectedPhoneNumberDetails,
    translations,
    language,
  } = props

  const [cityPaginationLoad, setCityPaginationLoad] = useState(false)
  const [countryLoading, setCountryLoading] = useState(true)
  const [cityLoading, setCityLoading] = useState(false)
  const [countryList, setCountryList] = useState([])
  const [areaList, setAreaList] = useState(
    selectedPhoneNumberDetails.areaList || []
  )
  const [unAssignedPhoneList, setunAssignedPhoneList] = useState(
    selectedPhoneNumberDetails.phoneNumberList || []
  )
  const [selectedCountryCode, setSelectedCountryCode] = useState(
    selectedPhoneNumberDetails.country || ''
  )
  const [selectedCity, setSelectedCity] = useState(
    selectedPhoneNumberDetails.city || ''
  )
  const [selectedPhonenumbers, setSelectedPhonenumbers] = useState(
    selectedPhoneNumberDetails.selectedPhonenumbers || []
  )
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [tableLoading, setTableLoading] = useState(false)
  const [selectedCountryId, setSelectedCountryId] = useState(
    selectedPhoneNumberDetails.countryId || ''
  )
  const [selectedCityId, setSelectedCityId] = useState('')
  const [selectedCountryName, setSelectedCountryName] = useState('')
  const [data, setData] = useState({})
  const [type,setType] = useState('')

  /* get all geo info */
  /* const [geoListApi] = useCustomFetch(
    `${ACTIONAPIURL}/phone`,
    'POST',
    true,
    JSON.stringify({ action: 'geoinfo' })
  ) */

  /* Get available countries list */
  const [countryListApi] = useCustomFetch(
    `${CUSTOM_ACTION_URL}/provider`,
    'POST',
    true,
    JSON.stringify({
      didww_step: 'GET_COUNTRIES',
    })
  )

  /* Get available cities list for the selected country */
  const [cityListApi] = useCustomFetch(
    `${CUSTOM_ACTION_URL}/provider`,
    'POST',
    false,
    JSON.stringify({
      didww_step: 'GET_CITIES',
      countryId: selectedCountryId,
      countryCode: selectedCountryCode,
    })
  )

  const [unAssignedPhoneNoListApi] = useCustomFetch(
    `${CUSTOM_ACTION_URL}/provider`,
    'POST',
    false,
    JSON.stringify(data)
  )

  /* get unassigned phone numbers list */
  const [unAssignedPhoneNosApi] = useCustomFetch(
    `${ACTIONAPIURL}/phone`,
    'POST',
    false,
    JSON.stringify({
      action: 'getunassigned',
      actiondata: {
        countrycode: selectedCountryCode,
        area: selectedCity,
      },
    })
  )

  useEffect(() => {
    const { response, status } = countryListApi.response
    const responseData = response?.Payload ? JSON.parse(response.Payload) : {}

    if (status && responseData?.data?.data) {
      const countries = responseData.data.data.map((country) => {
        return {
          id: country.id,
          code: country.attributes.iso,
          name: country.attributes.name,
        }
      })

      setCountryList(countries)
      setCountryLoading(false)
    }
  }, [countryListApi.response])

  useEffect(() => {
    if (selectedCountryCode) {
      setCityLoading(true)
      setSelectedCityId('')
      cityListApi.setRefetch(true)
    }
  }, [selectedCountryId])

  useEffect(() => {
    if (type === 'cities') {
      const data = {
        didww_step: 'AVAILABLE_DIDS',
        action: 'getunassigned',
        countrycode: selectedCountryCode,
        cityId: selectedCityId,
        area: selectedCity,
      }

    setData(data)
    } else if (type === 'regions') {
      const data = {
        didww_step: 'AVAILABLE_DIDS',
        action: 'getunassigned',
        countrycode: selectedCountryCode,
        regionId: selectedCityId,
        area: selectedCity,
      }

      setData(data)
    }
  },[type,selectedCountryCode,selectedCityId,selectedCity])

  useEffect(() => {
    const { response, status } = cityListApi.response
    const responseData = response?.Payload ? JSON.parse(response.Payload) : {}

    if (status && responseData?.data?.data) {
      const type = responseData?.data?.data[0]?.type

      setType(type)
      cityPaginationTotal = responseData?.data?.meta?.total_records
      setCityLoading(false)
      setAreaList(responseData.data.data)
    } else {
      setCityLoading(false)
      setAreaList([])
    }
  }, [cityListApi.response])

  /*  useEffect(() => {
    if (selectedCityId) {
      setTableLoading(true)
      unAssignedPhoneNoListApi.setRefetch(true)
    }
  }, [selectedCityId]) */

  const getListFromItems = (response) => {
    const data = response?.response?.items
      ? response.response.items[0].data
      : {}

    if (data) {
      return data?.response?.items ? data.response.items : []
    } else {
      return []
    }
  }

  const getListFromPayload = (response) => {
    const responseData = JSON.parse(response.Payload)

    if (responseData?.data?.data) {
      return responseData.data.meta ? responseData.data : responseData.data.data
    } else {
      return []
    }
  }

  useEffect(() => {
    const { response, status } = unAssignedPhoneNoListApi.response

    if (status && response) {
      const didww = response?.Payload ? true : false
      const list = didww
        ? getListFromPayload(response)
        : getListFromItems(response)

      setunAssignedPhoneList(list)
      enablePhoneNoSelected(false)
      setTableLoading(false)
    }
  }, [unAssignedPhoneNoListApi.response])

  /*   useEffect(() => {
    const { result, response } = geoListApi.response

    if (result && response.items.length && response.items[0].actionid) {
      const area = []
      const items = parseApiResult(response.items[0].data)
      const countries = items.map((item) => {
        area[item.countrycode] = item.area

        return {
          code: item.countrycode,
          name: item.country,
        }
      })

      setCountryList(countries)
      setAreaList(area)
      setCountryLoading(false)
    }
  }, [geoListApi.response]) */

  useEffect(() => {
    const unAssignedPhoneList = parseApiResult(unAssignedPhoneNosApi.response)

    if (
      unAssignedPhoneList[0] &&
      unAssignedPhoneList[0].data &&
      unAssignedPhoneList[0].data.response &&
      unAssignedPhoneList[0].data.response.items
    ) {
      setunAssignedPhoneList(unAssignedPhoneList[0].data.response.items)
      enablePhoneNoSelected(false)
      setTableLoading(false)
    }
  }, [unAssignedPhoneNosApi.response])

  useEffect(() => {
    if (selectedCountryCode && selectedCity.length) {
      if (
        selectedPhoneNumberDetails &&
        selectedPhoneNumberDetails.phoneNumberList
      ) {
        setunAssignedPhoneList(selectedPhoneNumberDetails.phoneNumberList)
        /* if (areaList.length === 0) {
          cityListApi.setRefetch(true)
        }
 */
        if (
          selectedPhoneNumberDetails.selectedPhonenumbers &&
          selectedPhoneNumberDetails.selectedPhonenumbers.length
        ) {
          const keys = []

          selectedPhoneNumberDetails.selectedPhonenumbers.forEach((row) => {
            keys.push(row.key)
          })
          setSelectedRowKeys(keys)
        }
      } else {
        setTableLoading(true)
        unAssignedPhoneNoListApi.setRefetch(true)
      }
    }
  }, [selectedCity, selectedCountryCode, selectedPhoneNumberDetails])

  useEffect(() => {
    if (selectedPhonenumbers.length > 0) {
      enablePhoneNoSelected(true)
      const updatedData = {
        country: selectedCountryCode,
        city: selectedCity,
        phoneNumberList: unAssignedPhoneList,
        selectedPhonenumbers: selectedPhonenumbers,
        areaList: areaList,
        countryName: selectedCountryName,
        countryId: selectedCountryId,
      }

      if (selectedPhonenumbers[0].skuId) {
        updatedData.price = selectedPhonenumbers[0].price
      }

      setSelectedPhoneNoDetails(updatedData)
    } else {
      enablePhoneNoSelected(false)
    }
  }, [selectedPhonenumbers])

  const handleCountryChange = (countryCode, countryKey) => {
    clearCurrentPhoneList()
    setSelectedCountryId(countryKey.id)
    setSelectedCountryCode(countryCode)
    setSelectedCountryName(countryKey.key)
    setSelectedCity('')
    setSelectedCityId('')
    setSelectedRowKeys([])
    setunAssignedPhoneList([])
    enablePhoneNoSelected(false)
    setCityPaginationLoad(false)
    cityPaginationTotal = 0
    cityPaginationProcess = false
    cityPaginationNumber = 2
  }

  const handleCityChange = (cityId, city) => {
    clearCurrentPhoneList()
    setSelectedCity(city.children)
    setSelectedCityId(cityId)
    setSelectedRowKeys([])
    setunAssignedPhoneList([])
    enablePhoneNoSelected(false)
  }

  const clearCurrentPhoneList = () => {
    setSelectedPhoneNoDetails({})
  }

  const rowSelectHandler = (rowKeys, rowData) => {
    setSelectedPhonenumbers(getNewArray(rowData))
    setSelectedRowKeys(rowKeys)
  }

  const unAssignedPhoneListTable = useMemo(() => {
    let data = []

    if (unAssignedPhoneList?.meta) {
      if (
        unAssignedPhoneList.included?.length &&
        unAssignedPhoneList.included.length > 1
      ) {
        // const skuId = unAssignedPhoneList.included[1].id
        const skuId = unAssignedPhoneList.included.find(
          (x) =>
            x.type === 'stock_keeping_units' &&
            (x.attributes.channels_included_count == 0 || x.attributes.channels_included_count)
        ).id
        // const monthly_amount =
        //   unAssignedPhoneList.included[1].attributes?.monthly_price
        const obj = unAssignedPhoneList.included.find(
          (x) =>
            x.type === 'stock_keeping_units' &&
            (x.attributes.channels_included_count == 0 || x.attributes.channels_included_count)
        )

        const monthly_amount =
          Number(obj?.attributes.monthly_price) +
          Number(obj?.attributes.setup_price)

        data = (unAssignedPhoneList?.data || []).map((data) => {
          if (data.id) {
            return {
              key: data.attributes?.number,
              id: data.id,
              number: data.attributes?.number,
              price: convertToCent(Number(monthly_amount)),
              skuId: skuId,
            }
          }
        })
      }
    } else {
      data = (
        (unAssignedPhoneList[0] &&
          unAssignedPhoneList[0].id &&
          unAssignedPhoneList) ||
        []
      ).map((data) => {
        if (data.data && data.data.price) {
          return {
            key: data.id,
            number: data.id,
            price: data.data.price.usd.monthly_amount,
          }
        }
      })
    }

    const columns = [
      {
        title: translations.number[language],
        dataIndex: 'number',
        width: '50%',
      },
      {
        title: translations.price[language],
        dataIndex: 'price',
        width: '40%',
        align: 'right',
        render: (text, record) => (
          <div className="price-amt">
            ${convertToDollar(record.price)} / {translations.month[language]}
          </div>
        ),
      },
    ]

    const rowSelection = {
      selectedRowKeys,
      onChange: (selectedRowKeys, selectedRows) =>
        rowSelectHandler(selectedRowKeys, selectedRows),
      getCheckboxProps: (record) => ({
        disabled: record && record.disabled,
      }),
    }

    const noPhoneForCity = selectedCity.length && data.length === 0

    return (
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        size={'small'}
        loading={tableLoading}
        className="add-phone-no-table"
        scroll={{ y: isSmall ? '20vh' : '22vh' }}
        style={{ height: '100%', marginTop: 10 }}
        rowSelection={{
          type: 'checkbox',
          // selectedRowKeys,
          ...rowSelection,
        }}
        locale={
          tableLoading
            ? {}
            : {
                emptyText: (
                  <div style={{ height: '100%' }}>
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      style={noPhoneForCity ? { margin: '5px 0px' } : {}}
                    />
                    {noPhoneForCity
                      ? translations.no_phone_in_country[language]
                      : ''}
                  </div>
                ),
              }
        }
      />
    )
  }, [unAssignedPhoneList, tableLoading, selectedRowKeys, isSmall])

  const handleCityPaginationError = (error) => {
    setCityPaginationLoad(false)
    cityPaginationProcess = false
    // eslint-disable-next-line no-console
    console.log(error)
  }

  const handleCityPaginationSuccess = (res) => {
    const { response, status } = res.data
    const responseData = response?.Payload ? JSON.parse(response.Payload) : {}

    setCityPaginationLoad(false)
    cityPaginationProcess = false
    if (status && responseData?.data?.data) {
      const existingAreas = cloneDeep(areaList)

      cityPaginationNumber = cityPaginationNumber + 1
      setAreaList(existingAreas.concat(responseData.data.data))
    }
  }

  const onCityPagination = (event) => {
    const { target } = event

    if (!cityPaginationProcess) {
      const isLastElement =
        target.scrollTop + target.offsetHeight === target.scrollHeight

      if (isLastElement && areaList.length < cityPaginationTotal) {
        setCityPaginationLoad(true)
        cityPaginationProcess = true
        const body = {
          didww_step: 'GET_CITIES',
          countryId: selectedCountryId,
          countryCode: selectedCountryCode,
          pageNumber: cityPaginationNumber,
        }

        cityPagination
          .post('provider', body)
          .then(handleCityPaginationSuccess)
          .catch(handleCityPaginationError)
      }
    }
  }

  const renderCities = () => {
    return (
      <React.Fragment>
        {areaList.map((area) => (
          <Select.Option key={(area?.attributes?.name, area?.id)}>
            {area?.attributes?.name}
          </Select.Option>
        ))}
        {cityPaginationLoad && (
          <div style={{ height: 50 }}>
            <Spin
              indicator={
                <LoadingOutlined
                  style={{ fontSize: 24, color: 'var(--userPrimaryColor)' }}
                  spin={true}
                />
              }
              spinning={true}
            />
          </div>
        )}
      </React.Fragment>
    )
  }

  const renderDropDowns = () => {
    return (
      <div style={{ marginTop: 15, display: 'flex' }}>
        <div style={{ width: '50%' }}>
          <div style={{ marginBottom: 5 }}>
            {translations.country[language]}
          </div>
          <CountrySelect
            dataProvider={countryList}
            placeholder={translations.select_country[language]}
            selectProps={{
              disabled: countryLoading,
              loading: countryLoading,
              onChange: handleCountryChange,
              style: { width: '21vw' },
              value: selectedCountryCode,
              showSearch: true,
              filterOption: (input, option) => {
                return (
                  option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                )
              },
            }}
          />
        </div>
        <div style={{ width: '50%' }}>
          <div style={{ marginBottom: 5 }}>{translations.area[language]}</div>
          <Select
            className="city-select avoid-margin"
            onChange={handleCityChange}
            placeholder={translations.select_area[language]}
            value={selectedCity.length ? selectedCity : undefined}
            disabled={countryLoading || !selectedCountryCode || cityLoading}
            loading={cityLoading}
            showSearch={true}
            optionFilterProp="label"
            // onPopupScroll={onCityPagination}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {renderCities()}
          </Select>
        </div>
      </div>
    )
  }

  return (
    <div className="add-phone-modal-body">
      {renderDropDowns()}
      {unAssignedPhoneListTable}
    </div>
  )
}

const mapsStateToProps = (state) => {
  return {
    userStripeData: state.sampleReducer.userStripeData || {},
    translations: state.sampleReducer.translations || {},
    language: state.sampleReducer.language,
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setSelectedPhoneNoDetails,
    },
    dispatch
  )

export default connect(mapsStateToProps, mapDispatchToProps)(AddPhoneNumber)
