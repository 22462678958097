// @flow

import React from 'react'
import { Button } from 'antd'
import ModalLayout, { Header, Body } from '../../../Popup/popup'
import './callflow.css'

type Props = {
  disabledKeys: $ReadOnlyArray<*>,
  onCancel: () => void,
  onSelect: (string) => mixed,
  visible: boolean,
}

const AddLabel = (props: Props) => {
  const renderButton = (number, className = '') => {
    return (
      <Button
        className={`dialpad_button-style ${className}`}
        disabled={props.disabledKeys.includes(number)}
        key={number}
        onClick={() => props.onSelect(number)}
      >
        {number}
      </Button>
    )
  }

  const renderBody = () => {
    if (props.visible) {
      return (
        <React.Fragment>
          <div>{['1', '2', '3'].map((number) => renderButton(number))}</div>
          <div>{['4', '5', '6'].map((number) => renderButton(number))}</div>
          <div>{['7', '8', '9'].map((number) => renderButton(number))}</div>
          <div>{renderButton('0', 'zero_button-style')}</div>
        </React.Fragment>
      )
    } else {
      return <React.Fragment />
    }
  }

  return (
    <ModalLayout onCancel={props.onCancel} visible={props.visible}>
      <Header>{'Add Label'}</Header>
      <Body>{renderBody()}</Body>
    </ModalLayout>
  )
}

export default AddLabel
