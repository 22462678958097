// @flow

import { useState, useEffect, useCallback } from 'react'
import { getItem } from '../utils/storage'
import {
  AUTH_API_KEY,
  API_MANAGER_KEY,
  ID_TOKEN,
  TOKEN_TYPE,
  SIGNUP_APIKEY,
} from '../utils/constants'

function useCustomFetch(
  url: string,
  apiMethodType: string,
  initialState: boolean,
  apiData?: Object
) {
  const [response, setResponse] = useState<Object>({})
  const [error, setError] = useState<Object>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [refetch, setRefetch] = useState<boolean>(false)
  const [loadInitially, setLoadInitially] = useState<boolean>(initialState)

  const customFetch = useCallback(
    async (api) => {
      try {
        const response = await fetch(api, getHeader(apiMethodType, apiData))
        const responseData = await response.json()

        setResponse(responseData)
        setLoading(false)
      } catch (err) {
        setError(err)
        setLoading(false)
      }
    },
    [apiMethodType, apiData]
  )

  const getHeader = (apiMethodType, apiData) => {
    const auth = `${getItem(TOKEN_TYPE)} ${getItem(ID_TOKEN)}`

    switch (apiMethodType) {
      case 'GET':
        return {
          headers: {
            Authorization: auth,
            'x-api-key': API_MANAGER_KEY,
          },
          method: 'GET',
        }

      case 'DELETE':
        return {
          headers: {
            Authorization: auth,
            'x-api-key': API_MANAGER_KEY,
          },
          method: 'DELETE',
        }

      case 'DELTE_WITH_BODY':
        return {
          headers: {
            Authorization: auth,
            'x-api-key': API_MANAGER_KEY,
          },
          method: 'DELETE',
          body: apiData,
        }

      case 'SETUPCONNECTOR':
        return {
          headers: {
            Authorization: auth,
            'x-api-key': API_MANAGER_KEY,
            'ms-token': localStorage.getItem('mstoken'),
          },
          method: 'GET',
        }

      case 'SETUPCONNECTOR_WITH_BODY':
        return {
          headers: {
            Authorization: auth,
            'x-api-key': API_MANAGER_KEY,
            'ms-token': localStorage.getItem('mstoken'),
          },
          method: 'POST',
          body: JSON.stringify({ size: 10 }),
        }

      case 'PAYMENT':
        return {
          headers: {
            Authorization: auth,
            'x-api-key': API_MANAGER_KEY,
          },
          method: 'POST',
          body: apiData,
        }

      case 'SIGNUP_GET':
        return {
          headers: {
            'x-api-key': SIGNUP_APIKEY,
          },
          method: 'GET',
        }

      case 'SIGNUP_POST':
        return {
          headers: {
            Authorization: auth,
            'x-api-key': SIGNUP_APIKEY,
          },
          method: 'POST',
          body: apiData,
        }

      case 'PUT':
        return {
          headers: {
            Authorization: auth,
            'Content-Type': 'application/json',
            'x-api-key': API_MANAGER_KEY,
          },
          method: 'PUT',
          body: apiData,
        }

      case 'REFRESH_CONNECTOR':
        return {
          headers: {
            Authorization: auth,
            'Content-Type': 'application/json',
            'x-api-key': AUTH_API_KEY,
          },
          method: 'PUT',
          body: apiData,
        }

      case 'PATCH':
        return {
          headers: {
            Authorization: auth,
            'Content-Type': 'application/json',
            'x-api-key': API_MANAGER_KEY,
          },
          method: 'PATCH',
          body: apiData,
        }

      case 'POST':
        return {
          headers: {
            Authorization: auth,
            'Content-Type': 'application/json',
            'x-api-key': API_MANAGER_KEY,
          },
          method: 'POST',
          body: apiData,
        }

      case 'delete':
        return {
          headers: {
            Authorization: auth,
            'x-api-key': API_MANAGER_KEY,
          },
          method: 'delete',
        }

      default:
        return {}
    }
  }

  useEffect(() => {
    if (url && loadInitially) {
      setLoading(true)
      customFetch(url)
      setLoadInitially(false)
    }
  }, [url, loadInitially, customFetch])

  useEffect(() => {
    if (url && refetch) {
      setLoading(true)
      customFetch(url)
      setRefetch(false)
    }
  }, [url, refetch, customFetch])

  return [{ response, error, loading, setResponse, setRefetch }]
}

export default useCustomFetch
