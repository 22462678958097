export const setSaveClientState = (saveClientState) => ({
  type: 'setSaveClientState',
  saveClientState,
})
export const setOnSaveState = (onSaveState) => ({
  type: 'setOnSaveState',
  onSaveState,
})

export const setCloseState = (closeState) => ({
  type: 'setCloseState',
  closeState,
})
