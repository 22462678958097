// @flow

import React from 'react'
import { Button } from 'antd'
import { DeleteOutlined, UserOutlined } from '@ant-design/icons'
import { NODE_TYPES } from '../nodetypes'
import VoiceUserModelComponent from './VoiceUserModelComponent'
import './VoiceUserComponent.css'
import { getNewArray } from '../../../utils/helpers'
import { connect } from 'react-redux'

type Props = {
  agents: $ReadOnlyArray<*>,
  autoSaveHandler: (any) => mixed,
  keyName: string,
  label: string,
  mode: string,
  selectedValue: $ReadOnlyArray<*>,
  translations: Object,
  language: string,
}

const VoiceUserComponent = React.forwardRef<Props, *>(
  (
    {
      agents,
      autoSaveHandler,
      label,
      keyName,
      mode,
      selectedValue = [],
      translations,
      language,
    }: Props,
    ref
  ) => {
    const [model, setModel] = React.useState(false)
    const [update, setUpdate] = React.useState(false)
    const [voiceUsers, setVoiceUsers] = React.useState([])

    React.useEffect(() => {
      setVoiceUsers(
        agents.map((agent) => ({
          id: agent.id,
          displayName: agent.data.displayName,
          selected: selectedValue.includes(agent.id) ? true : false,
        }))
      )
    }, [])

    React.useEffect(() => {
      if (update && mode === 'Edit' && autoSaveHandler) {
        autoSaveHandler(NODE_TYPES.voiceUser)
        setUpdate(false)
      }
    }, [update])

    React.useImperativeHandle(ref, () => ({
      getLatestValue() {
        return voiceUsers.map((voiceUser) => voiceUser.id)
      },
      getName() {
        return keyName
      },
      getType() {
        return NODE_TYPES.voiceUser
      },
      resetValue() {
        setVoiceUsers([])
      },
    }))

    const successHandler = (items) => {
      setVoiceUsers(items)
      setUpdate(true)
      setModel(false)
    }

    const deleteHandler = (id) => {
      const set = getNewArray(voiceUsers)

      set.map((user) => {
        if (user.id === id) {
          user.selected = false
        }
      })

      setVoiceUsers(set)
      setUpdate(true)
    }

    const getRows = () => {
      return voiceUsers
        .filter((voiceUser) => voiceUser.selected)
        .map((voiceUser) => (
          <div key={voiceUser.id} className="VoiceUserComponent__RowBody">
            <div className="VoiceUserComponent__RowIconAndName">
              <UserOutlined className="VoiceUserComponent__RowIcon" />
              {voiceUser.displayName}
            </div>
            <DeleteOutlined
              className="VoiceUserComponent__RowDeleteIcon"
              onClick={() => deleteHandler(voiceUser.id)}
            />
          </div>
        ))
    }

    return (
      <React.Fragment>
        <div className="label-spacing">{label}</div>
        <div className="VoiceUserComponent__Box">
          <div className="VoiceUserComponent__Rows">{getRows()}</div>
          <Button
            block
            onClick={() => setModel(true)}
            size="small"
            type="dashed"
          >
            {translations.add_agent[language]}
          </Button>
        </div>
        <VoiceUserModelComponent
          closeModel={() => setModel(false)}
          onSuccess={successHandler}
          visible={model}
          voiceUsers={voiceUsers}
        />
      </React.Fragment>
    )
  }
)

const mapsStateToProps = (state) => {
  return {
    translations: state.sampleReducer.translations || {},
    language: state.sampleReducer.language,
  }
}

export default connect(
  mapsStateToProps,
  null
)(React.memo<Props>(VoiceUserComponent))
