import React, { useEffect, useMemo, useState } from 'react'
import { Table } from 'antd'
import { EyeOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { getTranslatedText } from '../../../utils/translations'
import ModalLayout, { Body, /* Footer, */ Header } from '../../Popup/popup'
import { convertToDollar } from '../../../utils/helpers'
/* import PttButton from '../../CustomButton/PttButton' */

const CallingPlansInfoModal = ({ data = {}, onCancel, visiblity, onOkay }) => {
  const { language, translations } = useSelector(({ sampleReducer }) => ({
    language: sampleReducer.language || 'en',
    translations: sampleReducer.translations || {},
  }))

  const [columns, setColumns] = useState([])
  const [dataSource, setDataSource] = useState([])
  const [popup, setPopup] = useState(false)
  const [additionalData, setAdditionalData] = useState([])
  const [planName, setPlanName] = useState('')

  const getCallingPlansToShow = (details) => {
    const uniquePlans = []

    const getUsers = (callingPlanId) => {
      const users = []

      details.length &&
        details.forEach((detail, index) => {
          const plans = details[index]?.callingPlans?.data?.calling_plan
            ? details[index].callingPlans.data.calling_plan
            : []
          const plansId = plans.map((plan) => plan.id)

          if (plansId.includes(callingPlanId)) {
            users.push(detail.voiceUser)
          }
        })

      return [...new Set(users)]
    }

    details.length &&
      details.forEach((detail) => {
        const planIds = uniquePlans.map((plan) => plan.id)
        const callingPlans = detail?.callingPlans?.data?.calling_plan
          ? detail.callingPlans.data.calling_plan
          : []

        callingPlans.length &&
          callingPlans.forEach((callingPlan) => {
            if (planIds.includes(callingPlan.id)) {
              uniquePlans.forEach((uniquePlan) => {
                if (uniquePlan.id === callingPlan.id) {
                  uniquePlan.quantity = uniquePlan.quantity + 1
                }
              })
            } else {
              uniquePlans.push({
                data: callingPlan.data,
                id: callingPlan.id,
                quantity: 1,
                users: getUsers(callingPlan.id),
              })
            }
          })
      })

    return uniquePlans
  }

  useEffect(() => {
    if (Object.keys(data).length) {
      const details = JSON.parse(JSON.stringify(data.details))
      const callingPlansToShow = getCallingPlansToShow(details)

      const getPrice = (callingPlan, quantity) => {
        let amount = callingPlan?.data?.price?.usd?.monthly_amount
          ? Number(callingPlan.data.price.usd.monthly_amount)
          : 0

        amount = amount * quantity
        amount = String(amount)

        return amount.length ? `$${convertToDollar(amount)}` : ''
      }

      setColumns([
        {
          dataIndex: 'calllingPlan',
          title: getTranslatedText(translations, language, 'calling_plans'),
          width: '30%',
        },
        {
          align: 'center',
          dataIndex: 'quantity',
          title: getTranslatedText(translations, language, 'quantity'),
          width: '10%',
        },
        {
          align: 'right',
          dataIndex: 'monthly_unit_price',
          title: getTranslatedText(
            translations,
            language,
            'monthly_unit_price'
          ),
          width: '25%',
        },
        {
          align: 'right',
          dataIndex: 'monthly_total_price',
          title: getTranslatedText(
            translations,
            language,
            'monthly_total_price'
          ),
          width: '25%',
        },
        {
          align: 'center',
          dataIndex: '',
          render: (text, record) => {
            return (
              <EyeOutlined
                onClick={() => {
                  setPlanName(record.calllingPlan)
                  setAdditionalData([{ users: record.users }])
                  setPopup(true)
                }}
              />
            )
          },
          title: getTranslatedText(translations, language, 'more_actions'),
          width: '10%',
        },
      ])
      setDataSource(
        (callingPlansToShow || []).map((callingPlan) => {
          return {
            calllingPlan: callingPlan.data.label[language],
            quantity: callingPlan.quantity,
            key: callingPlan.id,
            monthly_unit_price: getPrice(callingPlan, 1),
            monthly_total_price: getPrice(callingPlan, callingPlan.quantity),
            users: callingPlan.users,
          }
        })
      )
    }
  }, [data])

  const getInfoTitle = () => {
    return (
      <div>
        <span style={{ fontWeight: '400' }}>
          {getTranslatedText(
            translations,
            language,
            'users_using'
          ).toLowerCase()}
        </span>
        <span style={{ margin: '0px 5px' }}>{planName}</span>
        <span style={{ fontWeight: '400' }}>
          {getTranslatedText(
            translations,
            language,
            'calling_plan'
          ).toLowerCase()}
        </span>
      </div>
    )
  }

  const additionalTable = useMemo(() => {
    if (additionalData.length) {
      return (
        <Table
          className="voiceuser-info-modal"
          columns={[
            {
              dataIndex: 'users',
              render: (text, record) => {
                return record.users.map((data) => <div key={data}>{data}</div>)
              },
              title: getInfoTitle(),
            },
          ]}
          dataSource={additionalData}
          pagination={false}
        />
      )
    } else {
      return <React.Fragment />
    }
  }, [additionalData])

  return (
    <React.Fragment>
      <ModalLayout
        destroyOnClose={true}
        onCancel={onCancel}
        visible={visiblity}
      >
        <Header>
          {getTranslatedText(
            translations,
            language,
            'subscribed_calling_plans'
          )}
        </Header>
        <Body>
          <Table columns={columns} dataSource={dataSource} pagination={false} scroll={{ y: '40vh' }} />
        </Body>
        {/* <Footer>
          {
            <PttButton type="primary" onClick={onOkay}>
              {translations.okay[language]}
            </PttButton>
          }
        </Footer> */}
      </ModalLayout>
      <ModalLayout
        className="subscription-info-modal"
        destroyOnClose={true}
        onCancel={() => setPopup(false)}
        visible={popup}
      >
        <Header>{getTranslatedText(translations, language, 'info')}</Header>
        <Body>{additionalTable}</Body>
        {/* <Footer>
          {
            <PttButton type="primary" onClick={() => setPopup(false)}>
              {translations.okay[language]}
            </PttButton>
          }
        </Footer> */}
      </ModalLayout>
    </React.Fragment>
  )
}

export default CallingPlansInfoModal
