/* eslint-disable react/prop-types */
// @flow

import React, { useEffect, useState, useMemo } from 'react'
import PttButton from '../CustomButton/PttButton'
import MemoTable from '../MemoTable/memotable'
import { bindActionCreators } from 'redux'
import AddPaymentMethodModal from './AddPaymentMethodModal'
import { CUSTOM_ACTION_URL, FULL_ACCESS } from '../../utils/constants'
import useCustomFetch from '../../Hooks/UseCustomFetch'
import { connect } from 'react-redux'
import InfoPanel from '../InfoPanel'
import { Button, Input, message } from 'antd'
// import brand_visa from '../../assets/images/visa.png'
import Spinner from '../../Spinner/spinner'
import { setPaymentList } from '../../actions/billingAction'
import visa from '../../assets/images/visa.png'
import mastercard from '../../assets/images/mastercard.png'
import ConfirmPopup from '../Popup/confirmpopup'
import { Fragment } from 'react'

type Props = {
  // stripePromise: Object,
  userStripeData: Object,
  paymentMethods: Object,
  setPaymentList: (any) => mixed,
  translations: Object,
  language: string,
}

const UnEditableSection = ({ title, value }) => {
  return (
    <Fragment>
      <div style={{ marginBottom: 5 }}>{title}</div>
      <div
        style={{
          marginBottom: 5,
          padding: '4px 11px',
          border: '1px solid lightgrey',
          height: 32,
        }}
      >
        {value}
      </div>
    </Fragment>
  )
}

const PaymentMethod = (props: Props) => {
  const {
    // stripePromise,
    paymentMethods,
    userStripeData,
    setPaymentList,
    translations,
    language,
    billingPermission,
  } = props
  const [popupState, setPopupState] = useState(false)
  const [infoState, setInfoState] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [paymentMethodsList, setPaymentMethods] = useState(paymentMethods)
  const [selectedRow, setSelectedRow] = useState({})
  const [name, setName] = useState('')
  const [expiry, setExpiry] = useState('')
  const [paymentAlert, setPaymentAlert] = useState(false)

  // const elements = useElements()

  /* List payment methods of the loggedin customer */
  const [listPaymentMethodApi] = useCustomFetch(
    `${CUSTOM_ACTION_URL}/payment`,
    'PAYMENT',
    false,
    JSON.stringify({
      action: 'LIST_PAYMENT_METHODS',
      data: {
        customerId: userStripeData.stripe_id,
      },
    })
  )

  /* detach customer from payment method */
  const [removePaymentMethodApi] = useCustomFetch(
    `${CUSTOM_ACTION_URL}/payment`,
    'PAYMENT',
    false,
    JSON.stringify({
      action: 'DETACH_PAYMENT_METHOD',
      data: {
        paymentMethodId: selectedRow.key,
      },
    })
  )

  /* update payment method */
  // eslint-disable-next-line no-unused-vars
  const [updatePaymentMethodApi] = useCustomFetch(
    `${CUSTOM_ACTION_URL}/payment`,
    'PAYMENT',
    false,
    JSON.stringify({
      action: 'UPDATE_PAYMENT_METHOD',
      data: {
        paymentMethodId: selectedRow.key,
        billingDetails: selectedRow.name,
        card: selectedRow.expiry,
      },
    })
  )

  useEffect(() => {
    if (
      listPaymentMethodApi.response &&
      listPaymentMethodApi.response.status &&
      listPaymentMethodApi.response.response
    ) {
      // setPaymentMethods(listPaymentMethodApi.response.response.data)
      setPaymentList(listPaymentMethodApi.response.response.data)
    }
  }, [listPaymentMethodApi.response])

  useEffect(() => {
    if (
      removePaymentMethodApi.response &&
      removePaymentMethodApi.response.status
    ) {
      message.success(translations.payment_method_removed_success[language])
      handleChangeVisiblity()
      getPaymentMethods()
      setPaymentAlert(false)
    } else if (
      removePaymentMethodApi.response &&
      removePaymentMethodApi.response.status === false
    ) {
      message.error(translations.something_went_wrong[language])
    }
  }, [removePaymentMethodApi.response])

  const handleOpenPopup = () => {
    setPopupState(true)
  }

  const handlePopupClose = () => {
    setPopupState(false)
  }

  const getPaymentMethods = () => {
    listPaymentMethodApi.setRefetch(true)
  }

  const handleChangeVisiblity = () => {
    setInfoState(false)
  }

  const rowSelectHandler = (selectedRow) => {
    if (selectedRow) {
      setSelectedRow(selectedRow)
      setName(selectedRow.name)
      setExpiry(selectedRow.expiry)
      setInfoState(true)
    }
  }

  const handleRemovePaymentMethod = () => {
    removePaymentMethodApi.setRefetch(true)
  }

  const getCardImg = (brand) => {
    switch (brand) {
      case 'visa':
        return visa

      case 'mastercard':
        return mastercard

      default:
        return
    }
  }

  const setRemovePaymentAlertVisibility = (value) => {
    setPaymentAlert(value)
  }

  const paymentMethodListTable = useMemo(() => {
    const data = (paymentMethods || []).map((data) => {
      if (data.card) {
        return {
          key: data.id,
          brand: data.card.brand,
          card: data.card.brand + ' ' + data.card.funding + ' card',
          name: (data.billing_details && data.billing_details.name) || '',
          description:
            data.card.brand +
            ' ' +
            data.card.funding +
            ' card ending with ' +
            data.card.last4,
          expiry: data.card.exp_month + '/' + data.card.exp_year,
          cardNo: '**** **** **** ' + data.card.last4,
          cardImg: getCardImg(data.card.brand),
        }
      }
    })

    const columns = [
      {
        title: translations.card[language],
        dataIndex: 'card',
        width: '20%',
        render: (text, record) => {
          // const card_img = require(`../../assets/images/${record.brand}.png`)

          return (
            <div>
              <img
                src={record.cardImg}
                alt={record.card}
                className="card-img"
              />
            </div>
          )
        },
      },
      {
        title: translations.name[language],
        dataIndex: 'name',
        width: '35%',
      },
      {
        title: translations.description[language],
        dataIndex: 'description',
        width: '45%',
      },
    ]

    return (
      <MemoTable
        columns={columns}
        dataSource={data}
        isSelectable={true}
        scrollObject={{ y: 'calc(100vh - 190px)' }}
        selectedRow={selectedRow}
        onSelectedRowChange={rowSelectHandler}
      />
    )
  }, [paymentMethods, selectedRow])

  const renderDeleteButton = () => {
    if (billingPermission === FULL_ACCESS) {
      return (
        <div className="">
          <Button
            className="edit-custom-btn"
            onClick={() => setRemovePaymentAlertVisibility(true)}
            danger
          >
            {translations.delete_payment_method[language]}
          </Button>
        </div>
      )
    } else {
      return <Fragment />
    }
  }

  const getTopupDisableStatus = () => {
    if (billingPermission === FULL_ACCESS) {
      return paymentMethods && paymentMethods.length >= 10 ? true : false
    } else {
      return true
    }
  }

  return (
    <div className="billing-main-cont">
      <div className="billing-utils-container">
        <div className="billing-utils-parent">
          <PttButton
            onClick={handleOpenPopup}
            type="primary"
            disabled={getTopupDisableStatus()}
          >
            {translations.add_payment_method_btn[language]}
          </PttButton>
          <AddPaymentMethodModal
            handlePopupClose={handlePopupClose}
            popupState={popupState}
            // stripePromise={stripePromise}
            getPaymentList={getPaymentMethods}
          />
        </div>
      </div>
      <div className="payment-method-container">
        <div className="payment-body">
          {listPaymentMethodApi.loading ? (
            <div className="spinner-div">
              <Spinner spinning={true} />
            </div>
          ) : (
            <div className="billing-inner-body">
              <div
                className={
                  infoState
                    ? 'billing-table-body phone-active-style'
                    : 'billing-table-body inactive-style'
                }
              >
                {paymentMethodListTable}
              </div>
              <InfoPanel
                changeVisiblity={handleChangeVisiblity}
                // closeButtonVisiblity={phoneNoApi.loading}
                contents={[{ text: 'Delete' }]}
                isMoreActionNeeded={false}
                visiblity={infoState}
              >
                <div className="info-children">
                  <div style={{ height: '50px ' }}>
                    <img className="card-info-img" src={selectedRow.cardImg} />
                  </div>
                  <div
                    style={{
                      height:
                        billingPermission === FULL_ACCESS
                          ? 'calc(100% - 90px)'
                          : 'calc(100% - 40px)',
                      overflow: 'auto',
                    }}
                  >
                    <UnEditableSection
                      title={translations.name[language]}
                      value={name || ''}
                    />
                    <UnEditableSection
                      title={translations.card_number[language]}
                      value={selectedRow.cardNo || ''}
                    />
                    <UnEditableSection
                      title={translations.cvc[language]}
                      value={'***'}
                    />
                    <UnEditableSection
                      title={translations.expiry[language]}
                      value={expiry || ''}
                    />
                  </div>
                  <div style={{ height: 40 }}>{renderDeleteButton()}</div>
                  <ConfirmPopup
                    onCancel={() => setRemovePaymentAlertVisibility(false)}
                    onOkay={handleRemovePaymentMethod}
                    visiblity={paymentAlert}
                    confirmText={translations.delete_payment_method[language]}
                    okayText={translations.delete[language]}
                    type="danger"
                    okBtnClassName="on-button-style"
                    loading={removePaymentMethodApi.loading}
                    disabled={removePaymentMethodApi.loading}
                  >
                    <div>
                      {translations.delete_payment_method_info[language]}
                    </div>
                  </ConfirmPopup>
                </div>
              </InfoPanel>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const mapsStateToProps = (state) => {
  return {
    userStripeData: state.sampleReducer.userStripeData || {},
    paymentMethods: state.billingReducer.paymentMethods,
    translations: state.sampleReducer.translations || {},
    language: state.sampleReducer.language,
    billingPermission: state.permissionReducer.billingPermission,
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setPaymentList,
    },
    dispatch
  )

export default connect(mapsStateToProps, mapDispatchToProps)(PaymentMethod)
