/* eslint-disable react/prop-types */

import { Fragment } from 'react'
import React, { useEffect } from 'react'
import { Form, Input, Switch } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { keyDownHandler, pasteHandler } from '../../../utils/helpers'
import PttButton from '../../CustomButton/PttButton'
import { DeleteOutlined } from '@ant-design/icons'
import { cloneDeep } from 'lodash'
import { setModifiedTrunk } from '../../../actions/trunksAction'
import { FULL_ACCESS } from '../../../utils/constants'

const UnEditableSection = ({ label }) => {
  return (
    <div
      style={{
        marginBottom: 5,
        padding: '4px 11px',
        border: '1px solid lightgrey',
        height: 32,
      }}
    >
      {label}
    </div>
  )
}

const FormItem = ({ label, children, rulesConfig }) => {
  return (
    <div style={{ marginBottom: 10 }}>
      <Form.Item label={label} rulesConfig={rulesConfig}>
        {children}
      </Form.Item>
    </div>
  )
}

const ModifiersTab = ({ origin = '', init, state = {}, setState }) => {
  const dispatch = useDispatch()
  const {
    language,
    translations,
    modifiedTrunk,
    advancedPermission,
  } = useSelector((state) => {
    return {
      language: state.sampleReducer.language,
      translations: state.sampleReducer.translations,
      modifiedTrunk: state.trunkReducer.modifiedTrunk,
      advancedPermission: state.permissionReducer.advancedPermission,
    }
  })

  const updateModifiedTrunk = (newState) => {
    if (origin === 'sidebar') {
      const prev = cloneDeep(modifiedTrunk)

      prev.modifiers = newState
      dispatch(setModifiedTrunk(prev))
    }
  }

  useEffect(() => {
    if (init) {
      const element = document.getElementById('modifiersTab')

      if (element) {
        element.scrollTop = 0
      }
    }
  }, [init])

  const onCountryChange = (event) => {
    onFieldChange('countryCode', event.target.value)
  }

  const onSymbolChange = (event) => {
    onFieldChange('symbol', event.target.value)
  }

  const onForceSymbolChange = (checked) => {
    onFieldChange('forceSymbol', checked)
  }

  const onFieldChange = (field, value) => {
    const newState = cloneDeep(state)

    newState.international[field] = value
    setState(newState)
    updateModifiedTrunk(newState)
  }

  const onPrefixChange = (event, rowIndex) => {
    onRowChange('prefix', event.target.value, rowIndex)
  }

  const onSuffixChange = (event, rowIndex) => {
    onRowChange('suffix', event.target.value, rowIndex)
  }

  const onStripLeftChange = (event, rowIndex) => {
    onRowChange('stripLeft', event.target.value, rowIndex)
  }

  const onStripRightChange = (event, rowIndex) => {
    onRowChange('stripRight', event.target.value, rowIndex)
  }

  const onMinLengthChange = (event, rowIndex) => {
    onRowChange('minLength', event.target.value, rowIndex)
  }

  const onMaxLengthChange = (event, rowIndex) => {
    onRowChange('maxLength', event.target.value, rowIndex)
  }

  const onRowAdd = () => {
    const newState = cloneDeep(state)

    newState.rules.push({
      prefix: '',
      suffix: '',
      stripLeft: '',
      stripRight: '',
      minLength: '',
      maxLength: '',
    })
    setState(newState)
    updateModifiedTrunk(newState)
  }

  const onRowDelete = (rowIndex) => {
    const newState = cloneDeep(state)

    newState.rules = []
    state.rules.forEach((item, index) => {
      if (rowIndex !== index) {
        newState.rules.push(item)
      }
    })
    setState(newState)
    updateModifiedTrunk(newState)
  }

  const onRowChange = (field, value, rowIndex) => {
    const newState = cloneDeep(state)

    newState.rules = []
    state.rules.forEach((item, index) => {
      if (rowIndex === index) {
        item[field] = value
        newState.rules.push(item)
      } else {
        newState.rules.push(item)
      }
    })
    setState(newState)
    updateModifiedTrunk(newState)
  }

  const renderHeading = () => {
    return (
      <Fragment>
        <div style={{ width: '13%', paddingLeft: 5 }}>
          {translations.prefix[language]}
        </div>
        <div style={{ width: '13%', paddingLeft: 5 }}>
          {translations.suffix[language]}
        </div>
        <div style={{ width: '15%', paddingLeft: 5 }}>
          {translations.strip_left[language]}
        </div>
        <div style={{ width: '15%', paddingLeft: 5 }}>
          {translations.strip_right[language]}
        </div>
        <div style={{ width: '17%', paddingLeft: 5 }}>
          {translations.min_length[language]}
        </div>
        <div style={{ width: '17%', paddingLeft: 5 }}>
          {translations.max_length[language]}
        </div>
      </Fragment>
    )
  }

  const renderDelete = (rowIndex) => {
    if (advancedPermission === FULL_ACCESS) {
      return (
        <div className="trunk_add_credentials_delbutton">
          <DeleteOutlined onClick={() => onRowDelete(rowIndex)} />
        </div>
      )
    } else {
      return <Fragment />
    }
  }

  const renderPrefixField = (rowItem, rowIndex) => {
    if (advancedPermission === FULL_ACCESS) {
      return (
        <Input
          value={rowItem.prefix}
          onChange={(event) => onPrefixChange(event, rowIndex)}
        />
      )
    } else {
      return <UnEditableSection label={rowItem.prefix} />
    }
  }

  const renderSuffixField = (rowItem, rowIndex) => {
    if (advancedPermission === FULL_ACCESS) {
      return (
        <Input
          value={rowItem.suffix}
          onChange={(event) => onSuffixChange(event, rowIndex)}
        />
      )
    } else {
      return <UnEditableSection label={rowItem.suffix} />
    }
  }

  const renderLeftField = (rowItem, rowIndex) => {
    if (advancedPermission === FULL_ACCESS) {
      return (
        <Input
          value={rowItem.stripLeft}
          onChange={(event) => onStripLeftChange(event, rowIndex)}
        />
      )
    } else {
      return <UnEditableSection label={rowItem.stripLeft} />
    }
  }

  const renderRightField = (rowItem, rowIndex) => {
    if (advancedPermission === FULL_ACCESS) {
      return (
        <Input
          value={rowItem.stripRight}
          onChange={(event) => onStripRightChange(event, rowIndex)}
        />
      )
    } else {
      return <UnEditableSection label={rowItem.stripRight} />
    }
  }

  const renderMinField = (rowItem, rowIndex) => {
    if (advancedPermission === FULL_ACCESS) {
      return (
        <Input
          value={rowItem.minLength}
          onChange={(event) => onMinLengthChange(event, rowIndex)}
          onKeyDown={(event) => keyDownHandler(event, 'allowOnlyNumbers')}
          onPaste={(event) => pasteHandler(event, 'allowOnlyNumbers')}
        />
      )
    } else {
      return <UnEditableSection label={rowItem.minLength} />
    }
  }

  const renderMaxField = (rowItem, rowIndex) => {
    if (advancedPermission === FULL_ACCESS) {
      return (
        <Input
          value={rowItem.maxLength}
          onChange={(event) => onMaxLengthChange(event, rowIndex)}
          onKeyDown={(event) => keyDownHandler(event, 'allowOnlyNumbers')}
          onPaste={(event) => pasteHandler(event, 'allowOnlyNumbers')}
        />
      )
    } else {
      return <UnEditableSection label={rowItem.maxLength} />
    }
  }

  const renderCountryField = () => {
    if (advancedPermission === FULL_ACCESS) {
      return (
        <Input
          style={{ width: 200, marginRight: 10 }}
          value={state.international.countryCode}
          onChange={onCountryChange}
        />
      )
    } else {
      return (
        <div style={{ width: 200, marginRight: 10 }}>
          <UnEditableSection label={state.international.countryCode} />
        </div>
      )
    }
  }

  const renderSymbolField = () => {
    if (advancedPermission === FULL_ACCESS) {
      return (
        <Input
          style={{ width: 150, marginRight: 10 }}
          value={state.international.symbol}
          onChange={onSymbolChange}
        />
      )
    } else {
      return (
        <div style={{ width: 150, marginRight: 10 }}>
          <UnEditableSection label={state.international.symbol} />
        </div>
      )
    }
  }

  const renderRow = (rowItem, rowIndex) => {
    return (
      <div className="trunk_add_credentials_rowsingle" key={rowIndex}>
        <div style={{ width: '13%', padding: 5 }}>
          {renderPrefixField(rowItem, rowIndex)}
        </div>
        <div style={{ width: '13%', padding: 5 }}>
          {renderSuffixField(rowItem, rowIndex)}
        </div>
        <div style={{ width: '15%', padding: 5 }}>
          {renderLeftField(rowItem, rowIndex)}
        </div>
        <div style={{ width: '15%', padding: 5 }}>
          {renderRightField(rowItem, rowIndex)}
        </div>
        <div style={{ width: '17%', padding: 5 }}>
          {renderMinField(rowItem, rowIndex)}
        </div>
        <div style={{ width: '17%', padding: 5 }}>
          {renderMaxField(rowItem, rowIndex)}
        </div>
        {renderDelete(rowIndex)}
      </div>
    )
  }

  return (
    <div id={'modifiersTab'} style={{ height: '100%' }}>
      <Form layout="vertical" style={{ height: '100%' }}>
        <div style={{ height: 100, marginTop: 10 }}>
          <div style={{ fontWeight: '500', fontSize: 15, height: 25 }}>
            {translations.international[language]}
          </div>
          <div style={{ display: 'flex', alignItems: 'center', height: 75 }}>
            <FormItem label={translations.country_code[language]}>
              {renderCountryField()}
            </FormItem>
            <FormItem label={translations.symbol[language]}>
              {renderSymbolField()}
            </FormItem>
            <FormItem label={translations.force_symbol[language]}>
              <Switch
                checked={state.international.forceSymbol}
                onChange={onForceSymbolChange}
                disabled={advancedPermission !== FULL_ACCESS}
              />
            </FormItem>
          </div>
        </div>
        <div style={{ height: 'calc(100% - 110px)' }}>
          <div style={{ fontWeight: '500', fontSize: 15, height: 18 }}>
            {translations.rules[language]}
          </div>
          <div style={{ height: 'calc(100% - 50px)', paddingBottom: 5 }}>
            <div className="trunk_add_modifiers_heading">{renderHeading()}</div>
            <div className="trunk_add_modifiers_row">
              {state.rules.map((item, index) => {
                return renderRow(item, index)
              })}
            </div>
          </div>
          <div
            style={{
              height: 32,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <PttButton
              style={{ marginLeft: 10 }}
              type="dashed"
              onClick={onRowAdd}
              disabled={advancedPermission !== FULL_ACCESS}
            >
              {translations.add_rules[language]}
            </PttButton>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default ModifiersTab
