import { combineReducers } from 'redux'
import sampleReducer from './SampleReducer'
import incomingCallsReducer from './IncomingCallsReducer'
import billingReducer from './billingReducer'
import phoneNumberReducer from './phoneNumberReducer'
import settingReducer from './settingReducer'
import msTeamsReducer from './MsTeamsReducer'
import CallflowReducer from './CallflowReducer'
import clientReducer from './ClientReducer'
import trunkReducer from './trunkReducer'
import routingReducer from './routingReducer'
import permissionReducer from './permissionReducer'

const appReducer = combineReducers({
  incomingCallsReducer: incomingCallsReducer,
  sampleReducer: sampleReducer,
  billingReducer: billingReducer,
  phoneNumberReducer: phoneNumberReducer,
  settingReducer: settingReducer,
  msTeamsReducer: msTeamsReducer,
  callflowReducer: CallflowReducer,
  clientReducer: clientReducer,
  trunkReducer: trunkReducer,
  routingReducer: routingReducer,
  permissionReducer: permissionReducer
})

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === 'USER_LOGGED_OUT') {
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer
