/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { Button, message } from 'antd'
import PttButton from '../../CustomButton/PttButton'
import { useDispatch, useSelector } from 'react-redux'
import ConfirmPopup from '../../Popup/confirmpopup'
import {
  APIURL,
  API_MANAGER_KEY,
  FULL_ACCESS,
  ID_TOKEN,
  TOKEN_TYPE,
} from '../../../utils/constants'
import { getItem } from '../../../utils/storage'
import axios from 'axios'
import useCustomFetch from '../../../Hooks/UseCustomFetch'
import { Tabs } from 'antd'
import { initGeneral, initCredentials, initModifiers } from './TrunksUtils'
import GeneralTab from './GeneralTab'
import CredentialsTab from './CredentialsTab'
import ModifiersTab from './ModifiersTab'
import Spinner from '../../../Spinner/spinner'
import ModalLayout, { Body } from '../../Popup/popup'
import { Footer, Header } from 'antd/lib/layout/layout'
import {
  setActualTrunk,
  setModifiedTrunk,
  setTrunkSavedFromOption,
  setTrunkSavedFromTab,
  setTrunkSaveFromClose,
  setTrunkSaveFromOption,
  setTrunkSaveFromRow,
  setTrunkSaveFromTab,
} from '../../../actions/trunksAction'
import { cloneDeep } from 'lodash'
import { Fragment } from 'react'

const TrunkSidebar = ({
  row = {},
  refetchUsersAndClosePanel,
  onTrunkUpdated,
  trunks,
  onTrunkSavedFromRow,
  onTrunkSavedFromClose,
}) => {
  const dispatch = useDispatch()
  const { TabPane } = Tabs
  const {
    language,
    translations,
    trunkSaveFromClose,
    trunkSaveFromRow,
    trunkSaveFromTab,
    trunkSaveFromOption,
    advancedPermission
  } = useSelector((state) => {
    return {
      language: state.sampleReducer.language,
      translations: state.sampleReducer.translations,
      trunkSaveFromClose: state.trunkReducer.trunkSaveFromClose,
      trunkSaveFromRow: state.trunkReducer.trunkSaveFromRow,
      trunkSaveFromTab: state.trunkReducer.trunkSaveFromTab,
      trunkSaveFromOption: state.trunkReducer.trunkSaveFromOption,
      advancedPermission: state.permissionReducer.advancedPermission,
    }
  })

  const sidebarContHeight =
    advancedPermission === FULL_ACCESS
      ? 'calc(100% - 80px)'
      : 'calc(100% - 0px)'

  const [trunkDetailsApi] = useCustomFetch(
    `${APIURL}/gateway/${row.id}`,
    'GET',
    false
  )

  const [popup, setPopup] = useState(false)
  const [alert, setAlert] = useState(false)
  const [loading, setLoading] = useState(true)
  const [key, setKey] = useState('1')
  const [tab1Init, setTab1Init] = useState(false)
  const [tab2Init, setTab2Init] = useState(false)
  const [tab3Init, setTab3Init] = useState(false)
  const [general, setGeneral] = useState(initGeneral())
  const [credentials, setCredentials] = useState(initCredentials())
  const [modifiers, setModifiers] = useState(initModifiers())

  useEffect(() => {
    if (row) {
      setPopup(false)
      setAlert(false)
      setKey('1')
      setTab1Init(false)
      setTab2Init(false)
      setTab3Init(false)
      setGeneral(initGeneral())
      setCredentials(initCredentials())
      setModifiers(initModifiers())
      setLoading(true)
      trunkDetailsApi.setRefetch(true)
    }
  }, [row])

  useEffect(() => {
    const { result, response } = trunkDetailsApi.response

    if (result && response.items.length && response.items[0].id) {
      const name = response.items[0]?.name ? response.items[0].name : ''
      const data = response.items[0]?.name ? response.items[0].data : {}
      const cred = data?.credentials ? data.credentials : []
      const mod = data?.modifier ? data.modifier : {}
      const rules = mod?.rules ? mod.rules : []
      const int = mod?.international ? mod.international : {}

      const general = {
        name: name,
        description: data?.description ? data.description : '',
        host: data?.host ? data.host : '',
        port: data?.port ? data.port : '',
        protocol: data?.protocol ? data.protocol : '',
      }

      const credentials = cred.map((item) => {
        return {
          caller: item?.caller ? item.caller : '',
          username: item?.username ? item.username : '',
          password: item?.password ? item.password : '',
          from: item?.from ? item.from : '',
        }
      })

      const modifiers = {
        international: {
          countryCode: int?.country_code ? int.country_code : '',
          symbol: int?.symbol ? int.symbol : '+',
          forceSymbol: int?.force_symbol ? int.force_symbol : false,
        },
        rules: rules.map((rule) => {
          return {
            prefix: rule?.prefix ? rule.prefix : '',
            suffix: rule?.suffix ? rule.suffix : '',
            stripLeft: rule?.stripleft ? rule.stripleft : '',
            stripRight: rule?.stripright ? rule.stripright : '',
            minLength: rule?.min_length ? Number(rule.min_length) : '',
            maxLength: rule?.max_length ? Number(rule.max_length) : '',
          }
        }),
      }

      const details = { general, credentials, modifiers }

      setGeneral(general)
      setCredentials(credentials)
      setModifiers(modifiers)
      dispatch(setActualTrunk(cloneDeep(details)))
      dispatch(setModifiedTrunk(cloneDeep(details)))

      setTimeout(() => {
        setLoading(false)
      }, 100)
    }
  }, [trunkDetailsApi.response])

  const closePopup = () => {
    setPopup(false)
  }

  const api = axios.create({
    baseURL: APIURL,
    headers: {
      Authorization: `${getItem(TOKEN_TYPE)} ${getItem(ID_TOKEN)}`,
      'Content-Type': 'application/json',
      'x-api-key': API_MANAGER_KEY,
    },
  })

  const deleteTrunk = () => {
    api
      .delete(`gateway/${row.id}`)
      .then((res) => {
        if (
          res.data.result &&
          res.data.response.items.length &&
          res.data.response.items[0].action === 'deleted'
        ) {
          refetchUsersAndClosePanel()
          dispatch(setActualTrunk({}))
          dispatch(setModifiedTrunk({}))
          setTimeout(() => {
            setLoading(false)
            message.success(translations.delete_trunk_success[language])
          }, 200)
        } else {
          setLoading(false)
          message.error(translations.delete_trunk_error[language])
        }
      })
      .catch(() => {
        setLoading(false)
        message.error(translations.delete_trunk_error[language])
      })
  }

  const onPopupConfirmed = () => {
    setPopup(false)
    setLoading(true)
    deleteTrunk()
  }

  const renderConfirmPopup = () => {
    return (
      <ConfirmPopup
        onCancel={closePopup}
        onOkay={onPopupConfirmed}
        visiblity={popup}
        confirmText={`${translations.delete_trunk[language]}?`}
        cancelText={translations.no[language]}
        okayText={translations.yes[language]}
        type="danger"
        okBtnClassName="on-button-style"
        loading={false}
        disabled={false}
      >
        <div>{translations.delete_trunk_info[language]}</div>
      </ConfirmPopup>
    )
  }

  const renderAlert = () => {
    const routes = row?.routing?.length ? row.routing : []

    return (
      <ModalLayout onCancel={() => setAlert(false)} visible={alert}>
        <Header>{translations.alert[language]}</Header>
        <Body>
          <div>{translations.routing_delete_info1[language]}</div>
          <div>{translations.routing_delete_info2[language]}</div>
          <div style={{ maxHeight: 250, overflow: 'auto', marginTop: 10 }}>
            {routes.map((item) => {
              return <div key={item.name}>{item.name}</div>
            })}
          </div>
        </Body>
        <Footer>
          <div>
            <PttButton type="primary" onClick={() => setAlert(false)}>
              {translations.okay[language]}
            </PttButton>
          </div>
        </Footer>
      </ModalLayout>
    )
  }

  const onTabChange = (activeKey) => {
    setKey(activeKey)
    setTab1Init(false)
    setTab2Init(false)
    setTab3Init(false)
    if (activeKey === '1') {
      setTab1Init(true)
    } else if (activeKey === '2') {
      setTab2Init(true)
    } else if (activeKey === '3') {
      setTab3Init(true)
    }
  }

  const updateGateway = () => {
    const body = {
      name: general.name,
      data: {
        description: general.description,
        host: general.host,
        port: general.port,
        protocol: general.protocol,
        type: 'TRUNK',
        credentials: credentials,
        modifier: {
          rules: modifiers.rules.map((rule) => {
            return {
              prefix: rule.prefix,
              suffix: rule.suffix,
              stripleft: rule.stripLeft,
              stripright: rule.stripRight,
              min_length: String(rule.minLength),
              max_length: String(rule.maxLength),
            }
          }),
          international: {
            country_code: modifiers.international.countryCode,
            symbol: modifiers.international.symbol,
            force_symbol: modifiers.international.forceSymbol,
          },
        },
      },
    }

    api
      .patch(`gateway/${row.id}?sync=yes`, body)
      .then((res) => {
        if (res.data.result) {
          trunkDetailsApi.setRefetch(true)
          onTrunkUpdated()
          dispatch(setActualTrunk({}))
          dispatch(setModifiedTrunk({}))
          if (trunkSaveFromClose) {
            onTrunkSavedFromClose()
            dispatch(setTrunkSaveFromClose(false))
          }
          if (trunkSaveFromRow) {
            dispatch(setTrunkSaveFromRow(false))
            onTrunkSavedFromRow()
          }
          if (trunkSaveFromTab) {
            dispatch(setTrunkSaveFromTab(false))
            dispatch(setTrunkSavedFromTab(true))
          }
          if (trunkSaveFromOption) {
            dispatch(setTrunkSaveFromOption(false))
            dispatch(setTrunkSavedFromOption(true))
          }
          message.success(translations.update_trunk_success[language])
        } else {
          setLoading(false)
          message.error(translations.update_trunk_success[language])
        }
      })
      .catch(() => {
        setLoading(false)
        message.error(translations.update_trunk_success[language])
      })
  }

  useEffect(() => {
    if (
      trunkSaveFromClose ||
      trunkSaveFromRow ||
      trunkSaveFromTab ||
      trunkSaveFromOption
    ) {
      handleSave()
    }
  }, [
    trunkSaveFromClose,
    trunkSaveFromRow,
    trunkSaveFromTab,
    trunkSaveFromOption,
  ])

  const handleSave = () => {
    const validTrunks = trunks.filter((item) => item.name !== row.name)

    if (general.name.length === 0) {
      message.warn(translations.enter_trunk_name[language])
      onTabChange('1')
    } else if (general.host.length === 0) {
      message.warn(translations.enter_host_name[language])
      onTabChange('1')
    } else if (validTrunks.some((item) => item.name === general.name)) {
      message.warn(translations.trunk_name_exists[language])
    } else {
      setLoading(true)
      updateGateway()
    }
  }

  const loader = () => {
    return (
      <div className="spin-style">
        <Spinner spinning={true} />
      </div>
    )
  }

  const renderGeneralTab = () => {
    if (loading) {
      return loader()
    } else {
      return (
        <div style={{ padding: 5, height: '100%' }}>
          <GeneralTab
            origin="sidebar"
            loading={true}
            init={tab1Init}
            state={general}
            setState={setGeneral}
          />
        </div>
      )
    }
  }

  const renderModifiersTab = () => {
    if (loading) {
      return loader()
    } else {
      return (
        <div style={{ padding: 5, height: '100%' }}>
          <ModifiersTab
            origin="sidebar"
            loading={loading}
            init={tab3Init}
            state={modifiers}
            setState={setModifiers}
          />
        </div>
      )
    }
  }

  const renderCredentialsTab = () => {
    if (loading) {
      return loader()
    } else {
      return (
        <div style={{ padding: 5, height: '100%' }}>
          <CredentialsTab
            origin="sidebar"
            loading={loading}
            init={tab2Init}
            state={credentials}
            setState={setCredentials}
          />
        </div>
      )
    }
  }

  const validateHasRouting = () => {
    if (row.isRouted) {
      setAlert(true)
    } else {
      setPopup(true)
    }
  }

  const renderActions = () => {
    if (advancedPermission === FULL_ACCESS) {
      return (
        <div style={{ height: 80 }}>
          <PttButton
            block
            type="primary"
            disabled={loading}
            style={{ marginBottom: 10 }}
            onClick={handleSave}
          >
            {translations.save[language]}
          </PttButton>
          <Button block danger disabled={loading} onClick={validateHasRouting}>
            {translations.delete_trunk[language]}
          </Button>
        </div>
      )
    } else {
      return <Fragment />
    }
  }

  return (
    <div style={{ padding: 5, height: '100%', paddingLeft: 15 }}>
      <div style={{ height: sidebarContHeight, paddingBottom: 10 }}>
        <Tabs
          activeKey={key}
          centered
          className="billing-tab"
          onChange={onTabChange}
        >
          <TabPane tab={translations.general[language]} key="1">
            {renderGeneralTab()}
          </TabPane>
          <TabPane tab={translations.credentials[language]} key="2">
            {renderCredentialsTab()}
          </TabPane>
          <TabPane tab={translations.modifiers[language]} key="3">
            {renderModifiersTab()}
          </TabPane>
        </Tabs>
      </div>
      {renderActions()}
      {renderConfirmPopup()}
      {renderAlert()}
    </div>
  )
}

export default TrunkSidebar
