import React, { useEffect, useState } from 'react'
import { Table } from 'antd'
import { useSelector } from 'react-redux'
import { getTranslatedText } from '../../../utils/translations'
import ModalLayout, { Body, /* Footer, */ Header } from '../../Popup/popup'
import { convertToDollar } from '../../../utils/helpers'
/* import PttButton from '../../CustomButton/PttButton' */

const PhoneNumberInfoModal = ({ data = {}, onCancel, visiblity, onOkay }) => {
  const { language, translations } = useSelector(({ sampleReducer }) => ({
    language: sampleReducer.language || 'en',
    translations: sampleReducer.translations || {},
  }))

  const [columns, setColumns] = useState([])
  const [dataSource, setDataSource] = useState([])

  useEffect(() => {
    if (Object.keys(data).length) {
      const getPrice = (phone) => {
        const amount = phone?.data?.price?.usd?.monthly_amount === undefined
          ? ''
          : phone.data.price.usd.monthly_amount

        return amount === '' ? '' : `$${convertToDollar(amount)}`
      }

      setColumns([
        {
          dataIndex: 'phone',
          title: getTranslatedText(translations, language, 'phone_numbers'),
          width: '50%',
        },
        {
          align: 'right',
          dataIndex: 'price',
          title: getTranslatedText(translations, language, 'monthly_price'),
          width: '50%',
        },
      ])
      setDataSource(
        (data.details || []).map((phone) => {
          return { key: phone.id, phone: phone.id, price: getPrice(phone) }
        })
      )
    }
  }, [data])

  return (
    <ModalLayout
      className="subscription-info-modal"
      destroyOnClose={true}
      onCancel={onCancel}
      visible={visiblity}
    >
      <Header>
        {getTranslatedText(translations, language, 'subscribed_phone_numbers')}
      </Header>
      <Body>
        <Table
          className="voiceuser-info-modal"
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          scroll={{ y: '40vh' }}
        />
      </Body>
      {/* <Footer>
        {
          <PttButton type="primary" onClick={onOkay}>
            {translations.okay[language]}
          </PttButton>
        }
      </Footer> */}
    </ModalLayout>
  )
}

export default PhoneNumberInfoModal
