/* eslint-disable react/prop-types */
// @flow

import { Button, Input, message, Spin } from 'antd'
import axios from 'axios'
import { DeleteOutlined, UserOutlined } from '@ant-design/icons'
import { cloneDeep } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  APIURL,
  API_MANAGER_KEY,
  ID_TOKEN,
  TOKEN_TYPE,
} from '../../utils/constants'
import { getItem } from '../../utils/storage'
import PttButton from '../CustomButton/PttButton'
import AddAccessModal from './AddAccessModal'
import { getNewArray } from '../../utils/helpers'
import Spinner from '../../Spinner/spinner'
import {
  setCloseState,
  setOnSaveState,
  setSaveClientState,
} from '../../actions/ClientAction'
import ConfirmPopup from '../Popup/confirmpopup'

const ClientSidebar = ({
  clientsData = [],
  selectedClient = {},
  getClientsApi = {},
  users = [],
  clientPanelState,
  setClientPanelState,
  setSidebarState,
  onSavePostCb,
}) => {
  const dispatch = useDispatch()
  const { language, translations, onSaveState } = useSelector(
    ({ sampleReducer, clientReducer }) => ({
      language: sampleReducer.language || 'en',
      translations: sampleReducer.translations || {},
      onSaveState: clientReducer.onSaveState,
    })
  )

  const api = axios.create({
    baseURL: APIURL,
    headers: {
      Authorization: `${getItem(TOKEN_TYPE)} ${getItem(ID_TOKEN)}`,
      'Content-Type': 'application/json',
      'x-api-key': API_MANAGER_KEY,
    },
  })

  const [name, setName] = useState('')
  const [loading, setLoading] = useState(false)
  const [model, setModel] = useState(false)
  const [displayData, setDisplayData] = useState([])
  const [modelLoading, setModelLoading] = useState(false)

  const accountRelations = () => {
    api
      .get(`account-user/${selectedClient.id}`)
      .then((res) => {
        if (
          res.data.result &&
          res.data.response.items.length &&
          res.data.response.items[0].username
        ) {
          const { items } = res.data.response
          const idArray = items.map((item) => (item.id ? item.id : ''))

          setDisplayData(
            cloneDeep(users).map((item) => {
              return {
                id: item.id,
                username: item.username,
                selected: idArray.includes(item.id) ? true : false,
              }
            })
          )
        } else {
          setDisplayData(
            cloneDeep(users).map((item) => {
              return {
                id: item.id,
                username: item.username,
                selected: false,
              }
            })
          )
        }
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (selectedClient.name) {
      setLoading(true)
      setName(selectedClient.name)
      accountRelations()
    }
  }, [selectedClient])

  useEffect(() => {
    if (onSaveState) {
      onSave()
      dispatch(setCloseState(true))
      dispatch(setOnSaveState(false))
    }
  }, [onSaveState])

  const updateUsers = (users) => {
    api
      .put(`account-user/${selectedClient.id}?sync=yes`, {
        items: users
          .filter((item) => item.selected)
          .map((item) => {
            return { id: item.id }
          }),
      })
      .then((res) => {
        if (res.data.result) {
          setModelLoading(false)
          message.success(translations.user_panel_add_success[language])
          setModel(false)
          accountRelations()
        } else {
          setModelLoading(false)
          setModel(false)
          message.error(translations.user_panel_add_error[language])
        }
      })
      .catch(() => {
        setModelLoading(false)
        setModel(false)
        message.error(translations.user_panel_add_error[language])
      })
  }

  const updateName = () => {
    api
      .patch(`account/${selectedClient.id}?sync=yes`, {
        name: name,
      })
      .then((res) => {
        if (
          res.data.result &&
          res.data.response.items.length &&
          res.data.response.items[0].action === 'updated'
        ) {
          getClientsApi.setRefetch(true)
          dispatch(setSaveClientState(false))
          onSavePostCb()
          message.success(translations.client_updated_successfully[language])
          setLoading(false)
        } else {
          dispatch(setSaveClientState(false))
          message.error(translations.client_updated_error[language])
          setLoading(false)
        }
      })
      .catch(() => {
        dispatch(setSaveClientState(false))
        message.error(translations.client_updated_error[language])
        setSidebarState(false)
      })
  }

  const onSave = () => {
    const duplicate = clientsData.filter((item) => item.name === name)

    if (name.length === 0) {
      message.warn(translations.enter_username[language])
    } else if (duplicate.length === 2) {
      message.warn(translations.name_already_exists[language])
    } else {
      setLoading(true)
      updateName()
    }
  }

  const successHandler = (users) => {
    setModelLoading(true)
    updateUsers(users)
  }

  const onDeleteError = () => {
    setLoading(false)
    message.error(translations.user_panel_delete_error[language])
  }

  const onDeleteSuccess = (res, id) => {
    const { items } = res.response

    if (res.result && items.length && items[0].action === 'updated') {
      const set = getNewArray(displayData)

      set.map((user) => {
        if (user.id === id) {
          user.selected = false
        }
      })

      setDisplayData(set)
      message.success(translations.user_panel_delete_success[language])
      setLoading(false)
    } else {
      onDeleteError(res)
    }
  }

  const deleteHandler = (id) => {
    setLoading(true)
    const url = `${APIURL}/account-user/${selectedClient.id}?sync=yes`

    fetch(url, {
      headers: {
        Authorization: `${getItem(TOKEN_TYPE)} ${getItem(ID_TOKEN)}`,
        'Content-Type': 'application/json',
        'x-api-key': API_MANAGER_KEY,
      },
      method: 'DELETE',
      body: JSON.stringify({ items: [{ id: id }] }),
    })
      .then((res) => res.json())
      .then((res) => {
        onDeleteSuccess(res, id)
      })
      .catch(onDeleteError)
  }

  const clientPanelClose = () => {
    setClientPanelState(false)
  }

  const clientPanelCancel = () => {
    setClientPanelState(false)
    dispatch(setSaveClientState(false))
    onSavePostCb()
  }

  const clientPanelOkay = () => {
    onSave()
    setClientPanelState(false)
  }

  const getRows = () => {
    return displayData
      .filter((item) => item.selected)
      .map((item) => (
        <div key={item.id} className="VoiceUserComponent__RowBody">
          <div className="VoiceUserComponent__RowIconAndName">
            <UserOutlined className="VoiceUserComponent__RowIcon" />
            {item.username}
          </div>
          <DeleteOutlined
            className="VoiceUserComponent__RowDeleteIcon"
            onClick={() => deleteHandler(item.id)}
          />
        </div>
      ))
  }

  const renderContent = () => {
    if (loading) {
      return (
        <div className="spin-style">
          <Spinner spinning={true} />
        </div>
      )
    }

    return (
      <React.Fragment>
        <div style={{ height: 'calc(100% - 32px)', overflow: 'auto' }}>
          <div className="edit-body">
            <div className="edit-heading">{translations.name[language]}</div>
            <Input
              className="edit-input-style"
              value={name}
              onChange={(event) => {
                dispatch(setSaveClientState(true))
                setName(event.target.value)
              }}
            />
          </div>
          <div className="label-spacing">{translations.users[language]}</div>
          <div className="VoiceUserComponent__Box">
            <div className="VoiceUserComponent__Rows">{getRows()}</div>
            <Button
              block
              onClick={() => setModel(true)}
              size="small"
              type="dashed"
            >
              {translations.add_users[language]}
            </Button>
          </div>
          <ConfirmPopup
            cancelText={translations.no[language]}
            closable={true}
            confirmText={translations.client_unsaved_changes[language]}
            okayText={translations.yes[language]}
            onCancel={clientPanelCancel}
            onClose={clientPanelClose}
            onOkay={clientPanelOkay}
            visiblity={clientPanelState}
          >
            <React.Fragment>
              {translations.client_unsaved_changes_info[language]}
            </React.Fragment>
          </ConfirmPopup>
        </div>
        <div
          style={{
            height: 32,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <PttButton
            type="primary"
            style={{ width: '100%' }}
            onClick={onSave}
            loading={loading}
            disabled={loading}
          >
            {translations.save[language]}
          </PttButton>
        </div>
      </React.Fragment>
    )
  }

  return (
    <div style={{ height: '100%', padding: 10, marginLeft: 5 }}>
      {renderContent()}
      <AddAccessModal
        closeModel={() => setModel(false)}
        onSuccess={successHandler}
        visible={model}
        usersArray={cloneDeep(displayData)}
        modelLoading={modelLoading}
      />
    </div>
  )
}

export default ClientSidebar
