// @flow

import React, { useEffect, useState, useMemo } from 'react'
import { EyeOutlined } from '@ant-design/icons'
import MemoTable from '../MemoTable/memotable'
import { CUSTOM_ACTION_URL } from '../../utils/constants'
import useCustomFetch from '../../Hooks/UseCustomFetch'
import Spinner from '../../Spinner/spinner'
import { connect } from 'react-redux'
import { convertToDollar } from '../../utils/helpers'
import VoiceUserInfoModal from './InfoModal/VoiceUserInfoModal'
import PhoneNumberInfoModal from './InfoModal/PhoneNumberInfoModal'
import CallingPlansInfoModal from './InfoModal/CallingPlansInfoModal'

type Props = {
  translations: Object,
  language: string,
}

const Subscription = (props: Props) => {
  const { translations, language } = props

  const [subscriptionAmount, setSubscriptionAmount] = useState(0)
  const [subscriptionList, setSubscriptionList] = useState([])
  const [row, setRow] = useState({})
  const [userPopup, setUserPopup] = useState(false)
  const [phonePopup, setPhonePopup] = useState(false)
  const [callingPopup, setCallingPopup] = useState(false)

  const [getSubscriptionListApi] = useCustomFetch(
    `${CUSTOM_ACTION_URL}/payment`,
    'PAYMENT',
    true,
    JSON.stringify({
      action: 'GET_SUBSCRIPTIONS',
      data: { singleAccount: true},
    })
  )

  useEffect(() => {
    if (
      getSubscriptionListApi.response &&
      getSubscriptionListApi.response.status &&
      getSubscriptionListApi.response.response &&
      getSubscriptionListApi.response.response.subscriptions &&
      getSubscriptionListApi.response.response.subscriptions.length
    ) {
      setSubscriptionList(
        getSubscriptionListApi.response.response.subscriptions
      )
      let subscriptionAmt = 0

      getSubscriptionListApi.response.response.subscriptions.map(
        (subscription) => {
          subscriptionAmt += subscription.ttlPrice
        }
      )
      setSubscriptionAmount(subscriptionAmt)
    }
  }, [getSubscriptionListApi.response])

  const subscriptionTable = useMemo(() => {
    const data = (subscriptionList || []).map((data) => {
      // if (data.data && data.data.price) {
      return {
        key: data.type,
        type: translations[data.type]
          ? translations[data.type][language]
          : data.type,
        quantity: data.qty,
        unit_price:
          data.type === 'voice_users'
            ? `$${(data.ttlPrice / data.qty / 100).toFixed(2)}`
            : '',
        totalPrice: `$${(data.ttlPrice / 100).toFixed(2)}`,
        details: data.details,
      }
      // }
    })

    const columns = [
      {
        dataIndex: 'type',
        title: translations.type[language],
        width: '30%',
      },
      {
        align: 'center',
        dataIndex: 'quantity',
        title: translations.quantity[language],
        width: '20%',
      },
      {
        align: 'right',
        dataIndex: 'unit_price',
        title: `${translations.unit_price[language]}`,
        width: '20%',
      },
      {
        align: 'right',
        dataIndex: 'totalPrice',
        title: `${translations.total_price[language]}`,
        width: '20%',
      },
      {
        align: 'center',
        title: `${translations.more_actions[language]}`,
        width: '10%',
        render: (text, record) => {
          return (
            <EyeOutlined
              onClick={() => {
                setRow(record)
                if (record.key === 'voice_users') {
                  setUserPopup(true)
                } else if (record.key === 'phone_numbers') {
                  setPhonePopup(true)
                } else if (record.key === 'calling_plans') {
                  setCallingPopup(true)
                }
              }}
            />
          )
        },
      },
    ]

    return (
      <MemoTable
        columns={columns}
        dataSource={data}
        isSelectable={true}
        scrollObject={{ y: 'calc(100vh - 190px)' }}
        //   selectedRow={selectedRow}
        //   onSelectedRowChange={rowSelectHandler}
      />
    )
  }, [subscriptionList])

  const voiceUserPopup = useMemo(() => {
    if (userPopup) {
      return (
        <VoiceUserInfoModal
          data={row}
          visiblity={userPopup}
          onCancel={() => {
            setUserPopup(false)
            setRow({})
          }}
          onOkay={() => {
            setUserPopup(false)
            setRow({})
          }}
        />
      )
    } else {
      return <React.Fragment />
    }
  }, [userPopup])

  const phoneNumberPopup = useMemo(() => {
    if (phonePopup) {
      return (
        <PhoneNumberInfoModal
          data={row}
          visiblity={phonePopup}
          onCancel={() => {
            setPhonePopup(false)
            setRow({})
          }}
          onOkay={() => {
            setPhonePopup(false)
            setRow({})
          }}
        />
      )
    } else {
      return <React.Fragment />
    }
  }, [phonePopup])

  const callingPlanPopup = useMemo(() => {
    if (callingPopup) {
      return (
        <CallingPlansInfoModal
          data={row}
          visiblity={callingPopup}
          onCancel={() => {
            setCallingPopup(false)
            setRow({})
          }}
          onOkay={() => {
            setCallingPopup(false)
            setRow({})
          }}
        />
      )
    } else {
      return <React.Fragment />
    }
  }, [callingPopup])

  return (
    <React.Fragment>
      <div className="billing-main-cont">
        <div className="billing-utils-container">
          <div className="billing-utils-parent">
            <div className="balance-div">
              Monthly subscription cost:{' '}
              <b>${convertToDollar(subscriptionAmount)}</b>
            </div>
          </div>
        </div>
        <div className="billing-table-container">
          <div className="phone-body">
            {getSubscriptionListApi.loading ? (
              <div className="spinner-div">
                <Spinner spinning={true} />
              </div>
            ) : (
              <div className="billing-inner-body">
                <div className={'billing-table-body inactive-style'}>
                  {subscriptionTable}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {voiceUserPopup}
      {phoneNumberPopup}
      {callingPlanPopup}
    </React.Fragment>
  )
}

const mapsStateToProps = (state) => {
  return {
    userStripeData: state.sampleReducer.userStripeData || {},
    translations: state.sampleReducer.translations || {},
    language: state.sampleReducer.language,
  }
}

export default connect(mapsStateToProps, null)(Subscription)
