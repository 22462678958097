import { READ_ONLY } from '../utils/constants'

const permissionReducer = (
  state = {
    microsoftTeamsPermission: READ_ONLY,
    phoneNumberPermission: READ_ONLY,
    statsPermission: READ_ONLY,
    advancedPermission: READ_ONLY,
    billingPermission: READ_ONLY,
    companySettingsPermission: READ_ONLY,
    accessUsersPermission: READ_ONLY,
  },
  action
) => {
  switch (action.type) {
    case 'setMicrosoftTeamsPermission':
      return {
        ...state,
        microsoftTeamsPermission: action.microsoftTeamsPermission,
      }

    case 'setPhoneNumberPermission':
      return {
        ...state,
        phoneNumberPermission: action.phoneNumberPermission,
      }

    case 'setStatsPermission':
      return {
        ...state,
        statsPermission: action.statsPermission,
      }

    case 'setAdvancedPermission':
      return {
        ...state,
        advancedPermission: action.advancedPermission,
      }

    case 'setBillingPermission':
      return {
        ...state,
        billingPermission: action.billingPermission,
      }

    case 'setCompanySettingsPermission':
      return {
        ...state,
        companySettingsPermission: action.companySettingsPermission,
      }

    case 'setAccessUsersPermission':
      return {
        ...state,
        accessUsersPermission: action.accessUsersPermission,
      }

    default:
      return state
  }
}

export default permissionReducer
