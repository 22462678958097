const stage = {
  apiBaseUrl: 'https://staging-api.plug2teams.com/',
  apiManagerApiKey: '3Yud3pumK84t7rGuxpT9Q8vZkV7GtjIB6DqZ7F2e',
  authApiKey: 'uZAPjLJ4d97NPnLsE3sOR1kgoAZRLQA5al4Hkn8Q',
  connectorApiKey: 'hcHXzH7cg34rKCD3RCrpr92yDsHvUFetilCDXbc0',
  imageUrl: 'https://cf-ptt-publicassets-staging.s3-eu-west-1.amazonaws.com/',
  name: 'stage',
  paymentApiKey: 'TLu4WjQbrn0pzGRjNhZg8VziecYOoOg5vpPxkDL8',
  signupApiKey: 'gNRlD2Bwzv96cE0KPn5ID26sS4qLo9FK1DlN8hDl',
  socketEndPoint: 'wss://r0lrp8osyi.execute-api.eu-west-1.amazonaws.com/v1',
  stripe_publishable_key:
    'pk_test_51H84xEAjNN0R99fNrpik7Kc3pQ22LNySDfUR5FEX42ceoUsd4kNm3qoKRqnJRGWpfLFMJbCeEA30zIj9w2xgRIgw00DUGqH7oy',
}

export default stage
