// @flow

import React, { useEffect } from 'react'
import { Provider, useDispatch, useSelector } from 'react-redux'
import { Switch, Route, HashRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'

import 'antd/dist/antd.css'
import './App.css'

import Login from './components/Login/Login'
import Signup from './components/Login/Signup'
import Setpassword from './components/Login/Setpassword'
import Recoverpassword from './components/Login/Recoverpassword'
import Resetpassword from './components/Login/Resetpassword'
import AuthCheck from './AuthCheck'
import store from './store'

import { translations } from './utils/translations'
import useConstructor from './Hooks/UseConstructor'
import { setAppUrlBrand, setTranslations } from './actions/SampleAction'
import { getBrandFromUrl } from './utils/helpers'
import { COMMON_IMG_URL } from './utils/constants'

const Routes = () => {
  const dispatch = useDispatch()
  const { appProviderBrand } = useSelector((state) => {
    return {
      appProviderBrand: state.sampleReducer.appProviderBrand,
    }
  })

  const setAppTranslations = () => {
    dispatch(setTranslations(translations))
  }

  const loadBrandFromUrl = () => {
    const brand = getBrandFromUrl()

    document.title = brand
    dispatch(setAppUrlBrand(brand))
  }

  useConstructor(setAppTranslations)
  useConstructor(loadBrandFromUrl)

  useEffect(() => {
    const element = document.getElementById('favicon')
    const fav = require('./assets/icon/favicon.ico')
    const icon = `${COMMON_IMG_URL}branding/${appProviderBrand}/favicon.png`

    if (appProviderBrand.length && element) {
      element.href = icon
    } else {
      element.href = fav
    }
  }, [appProviderBrand])

  return (
    <HashRouter>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/signup" component={Signup} />
        <Route exact path="/setpassword" component={Setpassword} />
        <Route exact path="/recoverpassword" component={Recoverpassword} />
        <Route exact path="/resetpassword" component={Resetpassword} />
        <Route path={'/'} component={AuthCheck} />
      </Switch>
    </HashRouter>
  )
}

const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistStore(store)}>
      <Routes />
    </PersistGate>
  </Provider>
)

export default App
