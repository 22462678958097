// @flow

import React from 'react'
import { getNewArray, getNodesDropDownData } from '../../../../utils/helpers'
import { NODE_TYPES } from '../../../NodeFieldTypes/nodetypes'
import {
  ShortText,
  LongText,
  NumberField,
  NodeField,
  CustomDropZone,
  MapNumberToNode,
  TtsSpeaker,
  CallFlowComponent,
  ApiMethodComponent,
  SwitchComponent,
  VoiceUserComponent,
  MapNumberOrStringToNode,
  Announcement
} from '../../../NodeFieldTypes/nodefieldtypes'

type Props = {
  agents: $ReadOnlyArray<*>,
  callFlow: $ReadOnlyArray<*>,
  callFlowId: string,
  callFlowNodes: $ReadOnlyArray<*>,
  componentProps: Object,
  keyId: string,
  keyName: string,
  nodeList: $ReadOnlyArray<*>,
  type: string,
  ttsLanguages: $ReadOnlyArray<*>,
  ttsSpeakers: $ReadOnlyArray<*>,
}

const GetComponent = React.forwardRef<Props, *>(
  (
    {
      agents,
      callFlow,
      callFlowId,
      callFlowNodes,
      componentProps,
      keyId,
      keyName,
      nodeList,
      type,
      ttsLanguages,
      ttsSpeakers
    }: Props,
    ref
  ) => {
    switch (type) {
      case NODE_TYPES.longText:
        return (
          <LongText
            key={keyId}
            keyName={keyName}
            ref={ref}
            {...componentProps}
          />
        )

      case NODE_TYPES.textToSpeach:
        return (
          <TtsSpeaker
            key={keyId}
            keyName={keyName}
            ref={ref}
            {...componentProps}
          />
        )

      case NODE_TYPES.shortText:
        return (
          <ShortText
            key={keyId}
            keyName={keyName}
            ref={ref}
            {...componentProps}
          />
        )

      case NODE_TYPES.audio:
        return (
          <CustomDropZone
            key={keyId}
            keyName={keyName}
            ref={ref}
            {...componentProps}
          />
        )

      case NODE_TYPES.number:
        return (
          <NumberField
            key={keyId}
            keyName={keyName}
            ref={ref}
            {...componentProps}
          />
        )

      case NODE_TYPES.mapNumberToNode:
        return (
          <MapNumberToNode
            key={keyId}
            keyName={keyName}
            ref={ref}
            {...componentProps}
            dropDownData={getNodesDropDownData(callFlowNodes, nodeList)}
          />
        )

      case NODE_TYPES.mapNumberOrStringToNode:
        return (
          <MapNumberOrStringToNode
            key={keyId}
            keyName={keyName}
            ref={ref}
            {...componentProps}
            menuOptions={getNewArray(componentProps.menuOptions)}
            dropDownData={getNodesDropDownData(callFlowNodes, nodeList)}
          />
        )

      case NODE_TYPES.node:
        return (
          <NodeField
            key={keyId}
            keyName={keyName}
            ref={ref}
            {...componentProps}
            dropDownData={getNodesDropDownData(callFlowNodes, nodeList)}
          />
        )

      case NODE_TYPES.callflow:
        return (
          <CallFlowComponent
            key={keyId}
            keyName={keyName}
            ref={ref}
            {...componentProps}
            options={callFlow.filter((flow) => flow.id !== callFlowId)}
          />
        )

      case NODE_TYPES.apimethod:
        return (
          <ApiMethodComponent
            key={keyId}
            keyName={keyName}
            ref={ref}
            {...componentProps}
          />
        )

      case NODE_TYPES.switch:
        return (
          <SwitchComponent
            key={keyId}
            keyName={keyName}
            ref={ref}
            {...componentProps}
          />
        )

      case NODE_TYPES.voiceUser:
        return (
          <VoiceUserComponent
            agents={agents}
            key={keyId}
            keyName={keyName}
            ref={ref}
            {...componentProps}
          />
        )

        case NODE_TYPES.announcement:
        return (
          <Announcement
            key={keyId}
            keyName={keyName}
            ref={ref}
            ttsLanguages={ttsLanguages}
            ttsSpeakers={ttsSpeakers}
            {...componentProps}
          />
        )

      default:
        return <div keyName={keyName}>{`No current component for ${type}`}</div>
    }
  }
)

export default React.memo<Props>(GetComponent)
