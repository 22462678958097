import React from 'react'
import { Image } from 'antd'

const image = require('../assets/images/404.svg')

function NotFoundPage() {
  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        flexDirection: 'column',
      }}
    >
      <Image preview={false} width={400} src={image} />
      <div style={{ fontSize: 17, fontWeight: 'bold', textAlign: 'center' }}>
        Oops! Page not found
      </div>
      <div style={{ textAlign: 'center' }}>
        If you believe there should be something here, please contact our
        support team.
      </div>
    </div>
  )
}

export default NotFoundPage
