export const setActualTrunk = (actualTrunk) => {
  return {
    type: 'setActualTrunk',
    actualTrunk,
  }
}

export const setModifiedTrunk = (modifiedTrunk) => {
  return {
    type: 'setModifiedTrunk',
    modifiedTrunk,
  }
}

export const setTrunkSaveFromClose = (trunkSaveFromClose) => {
  return {
    type: 'setTrunkSaveFromClose',
    trunkSaveFromClose,
  }
}

export const setTrunkSaveFromRow = (trunkSaveFromRow) => {
  return {
    type: 'setTrunkSaveFromRow',
    trunkSaveFromRow,
  }
}

export const setTrunkSaveFromTab = (trunkSaveFromTab) => {
  return {
    type: 'setTrunkSaveFromTab',
    trunkSaveFromTab,
  }
}

export const setTrunkSavedFromTab = (trunkSavedFromTab) => {
  return {
    type: 'setTrunkSavedFromTab',
    trunkSavedFromTab,
  }
}

export const setTrunkSaveFromOption = (trunkSaveFromOption) => {
  return {
    type: 'setTrunkSaveFromOption',
    trunkSaveFromOption,
  }
}

export const setTrunkSavedFromOption = (trunkSavedFromOption) => {
  return {
    type: 'setTrunkSavedFromOption',
    trunkSavedFromOption,
  }
}
