const routingReducer = (
  state = {
    actualRouting: {},
    modifiedRouting: {},
    routingSaveFromRow: false,
    routingSaveFromClose: false,
    routingSaveFromOption: false,
    routingSavedFromOption: false,
  },
  action
) => {
  switch (action.type) {
    case 'setActualRouting':
      return { ...state, actualRouting: action.actualRouting }

    case 'setModifiedRouting':
      return { ...state, modifiedRouting: action.modifiedRouting }

    case 'setRoutingSaveFromRow':
      return { ...state, routingSaveFromRow: action.routingSaveFromRow }

    case 'setRoutingSaveFromClose':
      return { ...state, routingSaveFromClose: action.routingSaveFromClose }

    case 'setRoutingSaveFromOption':
      return { ...state, routingSaveFromOption: action.routingSaveFromOption }

    case 'setRoutingSavedFromOption':
      return { ...state, routingSavedFromOption: action.routingSavedFromOption }

    case 'setRoutingSaveFromTab':
      return { ...state, routingSaveFromTab: action.routingSaveFromTab }

    case 'setRoutingSavedFromTab':
      return { ...state, routingSavedFromTab: action.routingSavedFromTab }

    default:
      return state
  }
}

export default routingReducer
