import { DefaultNodeModel } from '@projectstorm/react-diagrams'

class CustomNodeModel extends DefaultNodeModel {
  constructor(options, clickFunction, deleteFunction) {
    super({
      ...options,
      type: 'js-custom-node',
    })

    this.clickFunction = clickFunction
    this.deleteFunction = deleteFunction
    this.name = options.name
  }

  serialize() {
    return {
      ...super.serialize(),
      clickFunction: this.clickFunction,
      deleteFunction: this.deleteFunction,
      name: this.name,
    }
  }

  deserialize(ob, engine) {
    super.deserialize(ob, engine)
    this.clickFunction = ob.clickFunction
    this.deleteFunction = ob.deleteFunction
    this.name = ob.name
  }
}

export default CustomNodeModel
