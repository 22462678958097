export const setPaymentList = (data) => ({
  type: 'setPaymentList',
  data,
})

export const setSuccPaymentList = (data) => ({
  type: 'setSuccPaymentList',
  data,
})

/* export const setCreditList = (data) => ({
  type: 'setCreditList',
  data,
}) */

export const setPaymentSucceededEventData = (data) => ({
  type: 'setPaymentSucceededEventData',
  data,
})

export const setCreditChargeList = (data) => {
  return {
    type: 'setCreditChargeList',
    data
  }
}

export const setTransactionpaginationType = (data) => {
  return {
      type: 'setTransactionpaginationType',
      data
  }
}

export const resetTransactionRecordCount = (data) => {
  return {
      type: 'resetTransactionRecordCount',
      data
  }
}

export const setTransactionRecordCount = (data) => {
  return {
      type: 'setTransactionRecordCount',
      data
  }
}

export const setTransactionTotalRecordCount = (data) => {
  return {
    type: 'setTransactionTotalRecordCount',
    data
  }
}

export const setTransactionPageNumber = (data) => {
  return {
      type: 'setTransactionPageNumber',
      data
  }
}

export const setTransactionQueryUrl = (data) => {
  return {
      type: 'setTransactionQueryUrl',
      data
  }
}

export const setTransactionPageSize = (data) => {
  return {
      type: 'setTransactionPageSize',
      data: data
  }
}

export const setTransactionpaginationArray = (data) => {
  return {
    type: 'setTransactionpaginationArray',
    data: data
  }
}

export const setOnlyCreditChargeList = (data) => {
  return {
    type: 'setOnlyCreditChargeList',
    data: data
  }
}