import { AUTH_API_KEY, ID_TOKEN, TOKEN_TYPE } from '../utils/constants'
import { isTokenActive, handleLogout } from '../utils/LocalStorage'
import { getItem } from '../utils/storage'

let prevURL = ''
let prevCallTime = -1
let requestTimeInterval = 1000 /* milliseconds */

export function putService(url, data, callbackSuccess, callbackError) {
  if (prevURL === url && Date.now() - prevCallTime < 1000) {
    setTimeout(() => {
      putService(url, data, callbackSuccess, callbackError)
    }, requestTimeInterval)
    requestTimeInterval = requestTimeInterval * 2

    return
  }

  prevCallTime = Date.now()
  prevURL = url

  fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': AUTH_API_KEY,
    },
    method: 'PUT',
    body: data,
  })
    .then((response) => response.json())
    .then((response) => {
      callbackSuccess(response)
    })
    .catch((error) => {
      callbackError(error)
    })
}

export function deleteService(url, callback) {
  if (isTokenActive()) {
    fetch(url, {
      headers: {
        Authorization: getItem(TOKEN_TYPE) + ' ' + getItem(ID_TOKEN),
        'x-api-key': AUTH_API_KEY,
      },
      method: 'delete',
    })
      .then((result) => {
        if (result.ok) {
          callback(result)
        }
      })
      .catch((error) => {
        if (error && error.status && error.status.toString() === '401') {
          handleLogout()
        }
      })
  } else {
    handleLogout()
  }
}

export function postService(url, data, callback) {
  if (isTokenActive()) {
    fetch(url, {
      headers: {
        Authorization: getItem(TOKEN_TYPE) + ' ' + getItem(ID_TOKEN),
        'Content-Type': 'application/json',
        'x-api-key': AUTH_API_KEY,
      },
      method: 'POST',
      body: data,
    })
      .then((response) => response.json())
      .then((response) => {
        callback(response)
      })
      .catch((error) => {
        if (error && error.status && error.status.toString() === '401') {
          handleLogout()
        }
      })
  } else {
    handleLogout()
  }
}

export function getService(url, callback) {
  if (isTokenActive()) {
    fetch(url, {
      headers: {
        Authorization: getItem(TOKEN_TYPE) + ' ' + getItem(ID_TOKEN),
        'x-api-key': AUTH_API_KEY,
      },
      method: 'GET',
    })
      .then((response) => response.json())
      .then((response) => {
        callback(response)
      })
      .catch((error) => {
        if (error && error.status && error.status.toString() === '401') {
          handleLogout()
        }
      })
  } else {
    handleLogout()
  }
}
