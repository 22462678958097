/* eslint-disable react/prop-types */
import { Input } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useCustomFetch from '../../../Hooks/UseCustomFetch'
import Spinner from '../../../Spinner/spinner'
import { APIURL, FULL_ACCESS } from '../../../utils/constants'
import { isEmpty } from '../../../utils/helpers'
import PttButton from '../../CustomButton/PttButton'
import InfoPanel from '../../InfoPanel'
import MemoTable from '../../MemoTable/memotable'
import AdddTrunkPopup from './AddTrunkPopup'
import TrunkSidebar from './TrunkSidebar'
import ConfirmPopup from '../../Popup/confirmpopup'
import { isEqual } from 'lodash'
import {
  setActualTrunk,
  setModifiedTrunk,
  setTrunkSaveFromClose,
  setTrunkSaveFromRow,
} from '../../../actions/trunksAction'
import './TrunksView.css'

const trunkUrl = `${APIURL}/gateway?query=data.type:trunk&relations=routing`
const dummyRow = { id: '' }

const TrunksView = ({ active }) => {
  const dispatch = useDispatch()
  const {
    actualTrunk,
    modifiedTrunk,
    language,
    translations,
    advancedPermission
  } = useSelector((state) => {
    return {
      language: state.sampleReducer.language,
      translations: state.sampleReducer.translations,
      actualTrunk: state.trunkReducer.actualTrunk,
      modifiedTrunk: state.trunkReducer.modifiedTrunk,
      advancedPermission: state.permissionReducer.advancedPermission,
    }
  })

  const [trunksApi] = useCustomFetch(trunkUrl, 'GET', false)

  const [loading, setLoading] = useState(true)
  const [info, setInfo] = useState(false)
  const [infoLoad, setInfoLoad] = useState(false)
  const [filter, setFilter] = useState('')
  const [row, setRow] = useState(dummyRow)
  const [trunks, setTrunks] = useState([])
  const [addPopup, setAddPopup] = useState(false)
  const [addLoading, setAddLoading] = useState(false)
  const [unsave, setUnsave] = useState(false)
  const [postRow, setPostRow] = useState({})
  const [saveOrigin, setSaveOrigin] = useState('')

  useEffect(() => {
    if (active) {
      setLoading(true)
      setInfo(false)
      setInfoLoad(false)
      setFilter('')
      setRow(dummyRow)
      setTrunks([])
      setAddPopup(false)
      setAddLoading(false)
      trunksApi.setRefetch(true)
    }
  }, [active])

  const updateOpenedRow = (details) => {
    const itemData = details?.data ? details?.data : {}
    const relation = details?.relation ? details?.relation : {}
    const routing = relation?.gateway?.length ? relation.gateway[0] : {}

    setRow({
      key: String(details.id),
      name: details.name,
      description: itemData.description,
      host: itemData.host,
      port: itemData.port,
      protocol: itemData.protocol,
      id: details.id,
      type: itemData.type,
      routing: routing,
      isRouted: routing.length && routing[0].id ? true : false,
    })
  }

  useEffect(() => {
    if (!isEmpty(trunksApi.response)) {
      if (
        trunksApi.response.result &&
        trunksApi.response.response.items.length &&
        trunksApi.response.response.items[0].id
      ) {
        setTrunks(trunksApi.response.response.items)
        setLoading(false)

        if (row.id) {
          const found = trunksApi.response.response.items.find(
            (item) => item.id === row.id
          )

          if (found.id) {
            updateOpenedRow(found)
          }
        }
      } else {
        setTrunks([])
        setLoading(false)
      }
    }
  }, [trunksApi.response])

  const onCreateTrunkStart = () => {
    setAddLoading(true)
  }

  const onCreateTrunkCompleted = () => {
    setAddLoading(false)
    setAddPopup(false)
    setLoading(true)
    setRow(dummyRow)
    setInfo(false)
    trunksApi.setRefetch(true)
  }

  const onTrunkUpdated = () => {
    setLoading(true)
    trunksApi.setRefetch(true)
  }

  const onTrunkSavedFromClose = () => {
    setInfo(false)
    setRow(dummyRow)
    setSaveOrigin('')
  }

  const onTrunkSavedFromRow = () => {
    setRow(postRow)
    setInfo(true)
    setSaveOrigin('')
  }

  const closeAddPopup = () => {
    setAddPopup(false)
  }

  const loader = () => {
    return (
      <div className="spin-style">
        <Spinner spinning={true} />
      </div>
    )
  }

  const closeInfo = () => {
    if (isEqual(actualTrunk, modifiedTrunk)) {
      setInfo(false)
      setRow(dummyRow)
      dispatch(setActualTrunk({}))
      dispatch(setModifiedTrunk({}))
    } else {
      setSaveOrigin('close')
      setUnsave(true)
    }
  }

  const renderSearchField = () => {
    if (loading) {
      return <Fragment />
    } else {
      return (
        <Input
          onChange={(event) => setFilter(event.target.value)}
          placeholder={translations.search[language]}
          style={{ width: 200, marginRight: 10 }}
        />
      )
    }
  }

  const refetchUsersAndClosePanel = () => {
    setRow(dummyRow)
    setInfo(false)
    setLoading(true)
    trunksApi.setRefetch(true)
  }

  const renderAddButton = () => {
    return (
      <PttButton
        type="primary"
        loading={loading}
        disabled={loading || advancedPermission !== FULL_ACCESS}
        onClick={() => setAddPopup(true)}
      >
        {translations.add_trunk[language]}
      </PttButton>
    )
  }

  const renderInfoPanelChild = useMemo(() => {
    if (row.id) {
      return (
        <TrunkSidebar
          row={row}
          refetchUsersAndClosePanel={refetchUsersAndClosePanel}
          onTrunkUpdated={onTrunkUpdated}
          trunks={trunks}
          onTrunkSavedFromClose={onTrunkSavedFromClose}
          onTrunkSavedFromRow={onTrunkSavedFromRow}
        />
      )
    } else {
      return <Fragment />
    }
  }, [row, postRow])

  const applyMultipleFilters = (dataProvider) => {
    const doFilter = (value) => {
      return value.toLowerCase().includes(filter.toLowerCase())
    }

    return dataProvider.filter(({ name, data }) => {
      return (
        name && data && data.host && (doFilter(name) || doFilter(data.host))
      )
    })
  }

  const renderTrunksTable = useMemo(() => {
    const trunksData = trunks
    const tableData = filter.length
      ? applyMultipleFilters(trunksData)
      : trunksData

    const data = tableData.map((tableItem) => {
      const itemData = tableItem?.data ? tableItem?.data : {}
      const relation = tableItem?.relation ? tableItem?.relation : {}
      const routing = relation?.routing?.length ? relation.routing : []

      return {
        key: String(tableItem.id),
        name: tableItem.name,
        description: itemData.description,
        host: itemData.host,
        port: itemData.port,
        protocol: itemData.protocol,
        id: tableItem.id,
        type: itemData.type,
        routing: routing,
        isRouted: routing.length && routing[0].id ? true : false,
      }
    })

    const columns = [
      {
        dataIndex: 'name',
        sorter: (a, b) => {
          const wordA = a.name.toLowerCase()
          const wordB = b.name.toLowerCase()

          if (wordA < wordB) {
            return -1
          } else if (wordA > wordB) {
            return 1
          } else {
            return 0
          }
        },
        title: translations.name[language],
        defaultSortOrder: 'ascend',
      },
      {
        dataIndex: 'host',
        title: translations.host[language],
      },
    ]

    const rowSelectHandler = (rowSelected) => {
      if (Object.keys(rowSelected).length) {
        if (row.id === rowSelected.id) {
          setInfo(true)
        } else if (isEqual(actualTrunk, modifiedTrunk)) {
          setRow(rowSelected)
          setInfoLoad(true)
          setInfo(true)
          setTimeout(() => {
            setInfoLoad(false)
          })
        } else {
          setSaveOrigin('row')
          setUnsave(true)
          setPostRow(rowSelected)
        }
      }
    }

    return (
      <MemoTable
        columns={columns}
        dataSource={data}
        isSelectable={true}
        selectedRow={row}
        onSelectedRowChange={rowSelectHandler}
        scrollObject={{ y: 'calc(100vh - 190px)' }}
        style={{ height: '100%' }}
      />
    )
  }, [filter, row, trunks, actualTrunk, modifiedTrunk])

  const renderContent = () => {
    if (loading) {
      return loader()
    } else {
      return (
        <div className="panel-cont">
          <div className={info ? 'trunk_panel_active' : 'trunk_panel_inactive'}>
            {renderTrunksTable}
          </div>
          <InfoPanel
            changeVisiblity={closeInfo}
            visiblity={info}
            width={'600px'}
          >
            {infoLoad ? loader() : renderInfoPanelChild}
          </InfoPanel>
        </div>
      )
    }
  }

  const onUnsaveCancel = () => {
    if (saveOrigin === 'close') {
      setRow(dummyRow)
      setPostRow({})
      setInfo(false)
    } else if (saveOrigin === 'row') {
      setRow(postRow)
      setTimeout(() => setPostRow({}), 1000)
    }

    setSaveOrigin('')
    setUnsave(false)
    dispatch(setActualTrunk({}))
    dispatch(setModifiedTrunk({}))
  }

  const onUnsaveClose = () => {
    setSaveOrigin('')
    setUnsave(false)
  }

  const onUnsaveOkay = () => {
    setUnsave(false)
    if (saveOrigin === 'close') {
      dispatch(setTrunkSaveFromClose(true))
    } else if (saveOrigin === 'row') {
      dispatch(setTrunkSaveFromRow(true))
    }
  }

  return (
    <div style={{ height: '100%' }}>
      <div className="trunk_top_container">
        {renderSearchField()}
        {renderAddButton()}
      </div>
      <div className="trunk_bottom_container">{renderContent()}</div>
      <AdddTrunkPopup
        visiblity={addPopup}
        exitHandler={closeAddPopup}
        onCreateTrunkStart={onCreateTrunkStart}
        onCreateTrunkCompleted={onCreateTrunkCompleted}
        addLoading={addLoading}
        trunks={trunks}
      />
      <ConfirmPopup
        cancelText={translations.no[language]}
        closable={true}
        confirmText={translations.trunk_unsaved_changes[language]}
        okayText={translations.yes[language]}
        onCancel={onUnsaveCancel}
        onClose={onUnsaveClose}
        onOkay={onUnsaveOkay}
        visiblity={unsave}
      >
        <React.Fragment>
          {translations.trunk_unsaved_changes_info[language]}
        </React.Fragment>
      </ConfirmPopup>
    </div>
  )
}

export default TrunksView
