// @flow

import React, { useMemo, useState, useEffect } from 'react'
import useCustomFetch from '../../../../Hooks/UseCustomFetch'
import Spinner from '../../../../Spinner/spinner'
import { Input, message } from 'antd'
import { APIURL, FULL_ACCESS } from '../../../../utils/constants'
import { convertToDollar, parseApiResult } from '../../../../utils/helpers'
import { SearchOutlined } from '@ant-design/icons'
import PttButton from '../../../CustomButton/PttButton'
import InfoPanel from '../../../InfoPanel'
import { FlagIcon } from 'react-flag-kit'
import MemoTable from '../../../MemoTable/memotable'
/* import AddPhoneLayout from './AddPhoneLayout' */
import './phonenumber.css'
import AddPhoneModal from './AddPhoneModal'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setSelectedPhoneNoDetails } from '../../../../actions/phoneNumberAction'
import PhoneNumberDetails from './PhoneNumberDetails'

type Props = {
  setSelectedPhoneNoDetails: (any) => mixed,
  editLoaderState: boolean,
  translations: Object,
  language: string,
  phoneNumberPermission: String,
}

const PhoneNumber = (props: Props) => {
  const {
    setSelectedPhoneNoDetails,
    editLoaderState,
    translations,
    language,
    phoneNumberPermission
  } = props

  const [infoState, setInfoState] = useState(false)
  const [phoneNoListData, setPhoneNumberListData] = useState([])
  const [selectedRow, setSelectedRow] = useState({})
  const [addPhonePopup, setAddPhonePopup] = useState(false)
  const [editLoader, setEditLoader] = useState(false)
  // var editLoader

  /* get all phone no. with geo info */
  const [phoneNoListApi] = useCustomFetch(`${APIURL}/phone`, 'GET', true)

  useEffect(() => {
    const result = parseApiResult(phoneNoListApi.response)

    if (phoneNoListApi.response && phoneNoListApi.response.result) {
      if (phoneNoListApi.response.response.count > 0) {
        setPhoneNumberListData(result)
      } else {
        setPhoneNumberListData([])
      }
    }
  }, [phoneNoListApi.response])

  const getPhoneNoList = () => {
    phoneNoListApi.setRefetch(true)
    setSelectedPhoneNoDetails({})
    setInfoState(false)
  }

  const handleSearch = (event) => {
    const searchValue = event.target.value
    const phoneNoListApiResponse = parseApiResult(phoneNoListApi.response)

    const filteredList = phoneNoListApiResponse.filter((item) => {
      return (
        item.data &&
        item.data.geo &&
        (item.data.ddi.toLowerCase().includes(searchValue.toLowerCase()) ||
          item.data.geo.country
            .toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          item.data.geo.area.toLowerCase().includes(searchValue.toLowerCase()))
      )
    })

    setPhoneNumberListData(filteredList)
  }

  const phoneNoTable = useMemo(() => {
    const data = phoneNoListData.map((data) => {
      if (data.data && data.data.geo) {
        return {
          key: data.data.ddi,
          countryCode: data.data.geo.countrycode,
          country: data.data.geo.country,
          area: data.data.geo.area,
          phone: data.data.ddi,
          price:
            data.data.price &&
            data.data.price.usd &&
            data.data.price.usd.monthly_amount
              ? `$${convertToDollar(data.data.price.usd.monthly_amount)}`
              : '',
        }
      }
    })

    const columns = [
      {
        title: translations.phone_number_label[language],
        dataIndex: 'countrycode',
        width: '20%',
        render: (text, record) => (
          <div>
            <FlagIcon
              code={record.countryCode}
              size={15}
              style={{ marginRight: '5px' }}
            />
            <span>{record.phone}</span>
          </div>
        ),
      },
      {
        title: translations.country[language],
        dataIndex: 'country',
        width: '30%',
      },
      {
        title: translations.area[language],
        dataIndex: 'area',
        width: '50%',
      },
    ]

    const rowSelectHandler = (selectedRow) => {
      if (selectedRow) {
        // setSelectedPhoneNo(selectedRow.phone)

        if (editLoaderState || editLoader) {
          message.warning(
            translations.warning_existing_phone_number_not_saved[language]
          )
        } else {
          setInfoState(true)
          setSelectedRow(selectedRow)
        }
      }
    }

    return (
      <MemoTable
        columns={columns}
        dataSource={data}
        isSelectable={true}
        scrollObject={{ y: 'calc(100vh - 190px)' }}
        selectedRow={selectedRow}
        onSelectedRowChange={rowSelectHandler}
      />
    )
  }, [phoneNoListData, selectedRow, editLoader])

  const handleChangeVisiblity = () => {
    setInfoState(false)
  }

  const renderInfoPanelChild = () => {
    return (
      <PhoneNumberDetails
        details={selectedRow}
        getPhoneNoList={getPhoneNoList}
      />
    )
  }

  const handleOpenAddPhonePopup = () => {
    setAddPhonePopup(true)
  }

  const handleCloseAddPhonePopup = () => {
    setSelectedPhoneNoDetails({})
    setAddPhonePopup(false)
  }

  const phoneListView = useMemo(() => {
    return (
      <div style={{ height: '100%' }}>
        <div style={{ height: 50 }}>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Input
              className="phone-utils-input"
              placeholder={translations.search[language]}
              prefix={<SearchOutlined />}
              onChange={handleSearch}
              style={{ width: 190 }}
            />
            <PttButton
              type="primary"
              onClick={handleOpenAddPhonePopup}
              disabled={phoneNumberPermission !== FULL_ACCESS}
            >
              {translations.add_phone_number[language]}
            </PttButton>
          </div>
        </div>
        <div style={{ height: 'calc(100% - 50px)' }}>
          <div className="panel-cont">
            <div className={infoState ? 'ms-active-style' : 'ms-inactive'}>
              {phoneNoTable}
            </div>
            <InfoPanel
              visiblity={infoState}
              width={'320px'}
              changeVisiblity={handleChangeVisiblity}
              disableClose={editLoader}
            >
              {/* {phoneNoApi.loading ? (
                <div className="spin-style">
                  <Spinner spinning={true} />
                </div>
              ) : ( */}
              {renderInfoPanelChild()}
              {/* )} */}
            </InfoPanel>
          </div>
        </div>
        <AddPhoneModal
          visiblity={addPhonePopup}
          handleCloseAddPhonePopup={handleCloseAddPhonePopup}
          getPhoneNoList={getPhoneNoList}
        />
      </div>
    )
  }, [phoneNoListData, selectedRow, editLoader, addPhonePopup, infoState])

  /* const AddPhoneView = () => {
    return <AddPhoneLayout getPhoneNoList={getPhoneNoList} />
  } */

  const renderContent = () => {
    /* const phoneNoList = parseApiResult(phoneNoListApi.response) */

    if (phoneNoListApi.loading) {
      return (
        <div className="spin-style">
          <Spinner spinning={true} />
        </div>
      )
    } else if (phoneNoListApi.response.result === true) {
      // if (phoneNoList.length && phoneNoList[0].id && phoneNoList[0].id.length) {
      return phoneListView

      /*  } else {
        return AddPhoneView()
      } */
    } else {
      return <div />
    }
  }

  return <div className="msteams-cont">{renderContent()}</div>
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setSelectedPhoneNoDetails,
    },
    dispatch
  )

const mapsStateToProps = (state) => {
  return {
    editLoaderState: state.phoneNumberReducer.editLoaderState || false,
    translations: state.sampleReducer.translations || {},
    language: state.sampleReducer.language,
    phoneNumberPermission: state.permissionReducer.phoneNumberPermission,
  }
}

export default connect(mapsStateToProps, mapDispatchToProps)(PhoneNumber)
