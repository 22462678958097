/* eslint-disable react/prop-types */
import { Input, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'antd'
import PttButton from '../../CustomButton/PttButton'
import { DeleteOutlined } from '@ant-design/icons'
import { cloneDeep, orderBy } from 'lodash'
import { APIURL, FULL_ACCESS } from '../../../utils/constants'
import useCustomFetch from '../../../Hooks/UseCustomFetch'
import { isEmpty } from '../../../utils/helpers'
import { setModifiedRouting } from '../../../actions/routingAction'
import { Fragment } from 'react'

const UnEditableSection = ({ label }) => {
  return (
    <div
      style={{
        marginBottom: 5,
        padding: '4px 11px',
        border: '1px solid lightgrey',
        height: 32,
      }}
    >
      {label}
    </div>
  )
}

const FormItem = ({ label, children, req }) => {
  return (
    <div style={{ marginBottom: 10 }}>
      <Form.Item label={label} required={req}>
        {children}
      </Form.Item>
    </div>
  )
}
const trunkUrl = `${APIURL}/gateway?query=data.type:trunk&relations=routing`

const RoutingDetails = ({ origin = '', state = {}, setState }) => {
  const dispatch = useDispatch()
  const { language, translations, advancedPermission } = useSelector(
    (state) => {
      return {
        language: state.sampleReducer.language,
        translations: state.sampleReducer.translations,
        advancedPermission: state.permissionReducer.advancedPermission,
      }
    }
  )

  const [trunksApi] = useCustomFetch(trunkUrl, 'GET', true)

  const [loading, setLoading] = useState(true)
  const [trunks, setTrunks] = useState([])

  useEffect(() => {
    if (!isEmpty(trunksApi.response)) {
      if (
        trunksApi.response.result &&
        trunksApi.response.response.items.length &&
        trunksApi.response.response.items[0].id
      ) {
        const items = trunksApi.response.response.items
        const sorted = orderBy(items, ['name'], ['asc'])
        const trunks = sorted.map((item) => {
          return {
            id: item.id,
            name: item.name,
          }
        })

        setTrunks(trunks)
        setLoading(false)
      } else {
        setTrunks([])
        setLoading(false)
      }
    }
  }, [trunksApi.response])

  const updateModifiedTrunk = (temp) => {
    if (origin === 'sidebar') {
      dispatch(setModifiedRouting(temp))
    }
  }

  const onDelete = (rowIndex, field) => {
    const newState = cloneDeep(state)

    newState[field] = state[field].filter((item, index) => index !== rowIndex)
    setState(newState)
    updateModifiedTrunk(newState)
  }

  const handleChange = (rowIndex, field, value) => {
    state[field][rowIndex] = value

    setState(cloneDeep(state))
    updateModifiedTrunk(cloneDeep(state))
  }

  const renderDeleteIcon = (rowIndex, field) => {
    if (advancedPermission === FULL_ACCESS) {
      return (
        <div
          style={{ padding: 5, display: 'flex', alignItems: 'center' }}
          onClick={() => onDelete(rowIndex, field)}
        >
          <DeleteOutlined />
        </div>
      )
    } else {
      return <Fragment />
    }
  }

  const renderRowItem = (rowItem, rowIndex, field) => {
    return (
      <div style={{ display: 'flex', paddingBottom: 15 }}>
        <Input
          value={rowItem}
          style={{ width: '80%' }}
          onChange={(event) =>
            handleChange(rowIndex, field, event.target.value)
          }
        />
        {renderDeleteIcon(rowIndex, field)}
      </div>
    )
  }

  const onHostAdd = () => {
    const newState = cloneDeep(state)

    newState.host.push('')
    setState(newState)
    updateModifiedTrunk(newState)
  }

  const onCalleeAdd = () => {
    const newState = cloneDeep(state)

    newState.callee.push('')
    setState(newState)
    updateModifiedTrunk(newState)
  }

  const onCallerAdd = () => {
    const newState = cloneDeep(state)

    newState.caller.push('')
    setState(newState)
    updateModifiedTrunk(newState)
  }

  const renderHost = () => {
    return (
      <div style={{ width: '33%', height: '100%', paddingRight: 5 }}>
        <div style={{ height: 32 }}>{translations.host_origin[language]}</div>
        <div
          style={{
            height: 'calc(100% - 74px)',
            overflow: 'auto',
            marginBottom: 10,
          }}
        >
          {state.host.map((item, index) => {
            return renderRowItem(item, index, 'host')
          })}
        </div>
        <div style={{ height: 32 }}>
          <PttButton
            block
            type="dashed"
            disabled={advancedPermission !== FULL_ACCESS}
            onClick={onHostAdd}
          >
            {translations.Add_host_origin[language]}
          </PttButton>
        </div>
      </div>
    )
  }

  const renderCallee = () => {
    return (
      <div style={{ width: '33%', height: '100%', paddingRight: 5 }}>
        <div style={{ height: 32 }}>{translations.callee[language]}</div>
        <div
          style={{
            height: 'calc(100% - 74px)',
            overflow: 'auto',
            marginBottom: 10,
          }}
        >
          {state.callee.map((item, index) => {
            return renderRowItem(item, index, 'callee')
          })}
        </div>
        <div style={{ height: 32 }}>
          <PttButton
            block
            type="dashed"
            disabled={advancedPermission !== FULL_ACCESS}
            onClick={onCalleeAdd}
          >
            {translations.Add_callee[language]}
          </PttButton>
        </div>
      </div>
    )
  }

  const renderCaller = () => {
    return (
      <div style={{ width: '33%', height: '100%' }}>
        <div style={{ height: 32 }}>{translations.caller[language]}</div>
        <div
          style={{
            height: 'calc(100% - 74px)',
            overflow: 'auto',
            marginBottom: 10,
          }}
        >
          {state.caller.map((item, index) => {
            return renderRowItem(item, index, 'caller')
          })}
        </div>
        <div style={{ height: 32 }}>
          <PttButton
            block
            type="dashed"
            disabled={advancedPermission !== FULL_ACCESS}
            onClick={onCallerAdd}
          >
            {translations.Add_caller[language]}
          </PttButton>
        </div>
      </div>
    )
  }

  const onNameChange = (event) => {
    state.name = event.target.value

    setState(cloneDeep(state))
    updateModifiedTrunk(cloneDeep(state))
  }

  const onDescChange = (event) => {
    state.desc = event.target.value

    setState(cloneDeep(state))
    updateModifiedTrunk(cloneDeep(state))
  }

  const onModeChange = (value) => {
    state.mode = value

    setState(cloneDeep(state))
    updateModifiedTrunk(cloneDeep(state))
  }

  const onTrunkChange = (id) => {
    const obj = trunks.find((trunk) => trunk.id === id)

    if (obj && obj?.id) {
      const arr = []

      arr.push(obj)
      state.trunks = arr
    } else {
      state.trunks = []
    }

    setState(cloneDeep(state))
    updateModifiedTrunk(cloneDeep(state))
  }

  const getTrunkValue = () => {
    if (
      state.trunks.length === 1 &&
      state.trunks[0].id &&
      state.trunks[0].name
    ) {
      return state.trunks[0].name
    } else {
      return undefined
    }
  }

  const renderNameField = () => {
    if (advancedPermission === FULL_ACCESS) {
      return (
        <Input
          style={{ width: 190 }}
          value={state.name}
          onChange={onNameChange}
        />
      )
    } else {
      return (
        <div style={{ width: 190 }}>
          <UnEditableSection label={state.name} />
        </div>
      )
    }
  }

  const renderDescField = () => {
    if (advancedPermission === FULL_ACCESS) {
      return (
        <Input
          style={{ width: 290 }}
          value={state.desc}
          onChange={onDescChange}
        />
      )
    } else {
      return (
        <div style={{ width: 290 }}>
          <UnEditableSection label={state.desc} />
        </div>
      )
    }
  }

  return (
    <div style={{ height: '100%' }}>
      <Form layout="vertical" style={{ height: '100%' }}>
        <div style={{ height: 150 }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <FormItem label={translations.name[language]} req={true}>
              {renderNameField()}
            </FormItem>
            <FormItem label={translations.description[language]}>
              {renderDescField()}
            </FormItem>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <FormItem label={translations.mode[language]} req={true}>
              <Select
                style={{ width: 240 }}
                value={state.mode.length ? state.mode : undefined}
                onChange={onModeChange}
                placeholder={translations.select_a_Mode[language]}
                disabled={advancedPermission !== FULL_ACCESS}
              >
                <Select.Option key={'Fail over'} value={'Fail over'}>
                  {translations.Fail_over[language]}
                </Select.Option>
                <Select.Option key={'Load balancer'} value={'Load balancer'}>
                  {translations.Load_balancer[language]}
                </Select.Option>
              </Select>
            </FormItem>
            <FormItem label={translations.trunk_field[language]} req={true}>
              <Select
                allowClear={state.trunks.length}
                loading={loading}
                onChange={onTrunkChange}
                placeholder={translations.select_a_trunk[language]}
                style={{ width: 240 }}
                value={getTrunkValue()}
                disabled={loading || advancedPermission !== FULL_ACCESS}
              >
                {trunks.map((item) => {
                  return (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  )
                })}
              </Select>
            </FormItem>
          </div>
        </div>
        <div className="routing_add_heading">
          {renderHost()}
          {renderCallee()}
          {renderCaller()}
        </div>
      </Form>
    </div>
  )
}

export default RoutingDetails
