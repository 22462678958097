// @flow

import React, { type Node } from 'react'
import { Button, Layout, PageHeader, Spin } from 'antd'
import PttSidebar from '../PttHome/PttSidebar'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { STRIPE_PUBLISHABLE_KEY } from '../../../utils/constants'
import { ArrowLeftOutlined, LoadingOutlined } from '@ant-design/icons'
import '../homelayout.css'
import { useSelector } from 'react-redux'

type Props = {
  currentkey: string,
  children: Node,
  title: string,
}

const PttLayout = ({ currentkey, children, title }: Props) => {
  const stripe = loadStripe(STRIPE_PUBLISHABLE_KEY)
  const { appBlurState } = useSelector((state) => {
    return {
      appBlurState: state.sampleReducer.appBlurState,
    }
  })

  const renderBreadCrumb = () => {
    if (currentkey !== '4') {
      return (
        <div className="breadcrumb-style">
          <PageHeader backIcon={false} style={{ padding: 0 }} title={title} />
          {false && <Button icon={<ArrowLeftOutlined />}>Back to Admin</Button>}
        </div>
      )
    }
  }

  return (
    <Elements stripe={stripe}>
      <Spin
        indicator={
          <LoadingOutlined
            style={{ fontSize: 50, color: 'var(--userPrimaryColor)' }}
            spin
          />
        }
        size="large"
        spinning={appBlurState}
        style={{ height: '100%', maxHeight: 'unset' }}
      >
        <Layout className="home-layout-style">
          <PttSidebar currentkey={currentkey} />
          <Layout className="site-layout">
            <Layout.Content
              className={
                currentkey === '4' ? 'stats-cont-style' : 'home-cont-style'
              }
            >
              {renderBreadCrumb()}
              <div
                className={
                  currentkey === '4'
                    ? 'stats-layout-bg'
                    : 'site-layout-background'
                }
              >
                {children}
              </div>
            </Layout.Content>
          </Layout>
        </Layout>
      </Spin>
    </Elements>
  )
}

export default PttLayout
