/* eslint-disable react/prop-types */
import { Button, message } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import RoutingDetails from './RoutingDetails'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from '../../../Spinner/spinner'
import { APIURL, FULL_ACCESS } from '../../../utils/constants'
import { API_MANAGER_KEY } from '../../../utils/constants'
import { ID_TOKEN } from '../../../utils/constants'
import { TOKEN_TYPE } from '../../../utils/constants'
import { getItem } from '../../../utils/storage'
import PttButton from '../../CustomButton/PttButton'
import ConfirmPopup from '../../Popup/confirmpopup'
import {
  setActualRouting,
  setModifiedRouting,
  setRoutingSaveFromOption,
  setRoutingSavedFromOption,
  setRoutingSavedFromTab,
  setRoutingSaveFromTab,
} from '../../../actions/routingAction'
import { cloneDeep } from 'lodash'
import { Fragment } from 'react'

const RoutingSidebar = ({
  routing,
  row = {},
  onRoutingDeleteCb,
  onRoutingUpdateCb,
}) => {
  const dispatch = useDispatch()
  const {
    language,
    translations,
    routingSaveFromClose,
    routingSaveFromRow,
    routingSaveFromOption,
    routingSaveFromTab,
    advancedPermission
  } = useSelector((state) => {
    return {
      language: state.sampleReducer.language,
      translations: state.sampleReducer.translations,
      actualRouting: state.routingReducer.actualRouting,
      modifiedRouting: state.routingReducer.modifiedRouting,
      routingSaveFromClose: state.routingReducer.routingSaveFromClose,
      routingSaveFromRow: state.routingReducer.routingSaveFromRow,
      routingSaveFromOption: state.routingReducer.routingSaveFromOption,
      routingSaveFromTab: state.routingReducer.routingSaveFromTab,
      advancedPermission: state.permissionReducer.advancedPermission,
    }
  })

  const sidebarContHeight =
    advancedPermission === FULL_ACCESS
      ? 'calc(100% - 80px)'
      : 'calc(100% - 0px)'

  const api = axios.create({
    baseURL: APIURL,
    headers: {
      Authorization: `${getItem(TOKEN_TYPE)} ${getItem(ID_TOKEN)}`,
      'Content-Type': 'application/json',
      'x-api-key': API_MANAGER_KEY,
    },
  })

  const [popup, setPopup] = useState(false)
  const [loading, setLoading] = useState(true)
  const [state, setState] = useState({})

  useEffect(() => {
    if (row) {
      const temp = {
        name: row.name,
        desc: row.description,
        mode: row.mode,
        trunks: row.trunks,
        host: row.host_origin,
        callee: row.callee,
        caller: row.caller,
      }

      setState(temp)
      dispatch(setActualRouting(cloneDeep(temp)))
      dispatch(setModifiedRouting(cloneDeep(temp)))
      setLoading(false)
    } else {
      setState({})
      dispatch(setActualRouting({}))
      dispatch(setModifiedRouting({}))
      setLoading(false)
    }
  }, [row])

  useEffect(() => {
    if (
      routingSaveFromClose ||
      routingSaveFromRow ||
      routingSaveFromOption ||
      routingSaveFromTab
    ) {
      handleSave()
    }
  }, [
    routingSaveFromClose,
    routingSaveFromRow,
    routingSaveFromOption,
    routingSaveFromTab,
  ])

  const deleteRouting = () => {
    api
      .delete(`routing/${row.id}?sync=yes`)
      .then((res) => {
        const { result, response } = res.data

        if (
          result &&
          response.items.length &&
          response.items[0].action === 'deleted'
        ) {
          onRoutingDeleteCb()
          dispatch(setActualRouting({}))
          dispatch(setModifiedRouting({}))
          setTimeout(() => {
            message.success(translations.delete_routing_success[language])
            setLoading(false)
          }, 500)
        } else {
          message.error(translations.delete_routing_error[language])
        }
      })
      .catch(() => {
        message.error(translations.delete_routing_error[language])
      })
  }

  const onDelete = () => {
    setPopup(true)
  }

  const onPopupConfirmed = () => {
    setLoading(true)
    setPopup(false)
    deleteRouting()
  }

  const closePopup = () => {
    setPopup(false)
  }

  const renderConfirmPopup = () => {
    return (
      <ConfirmPopup
        onCancel={closePopup}
        onOkay={onPopupConfirmed}
        visiblity={popup}
        confirmText={`${translations.delete_routing[language]}?`}
        cancelText={translations.no[language]}
        okayText={translations.yes[language]}
        type="danger"
        okBtnClassName="on-button-style"
        loading={false}
        disabled={false}
      >
        <div>{translations.delete_routing_info[language]}</div>
      </ConfirmPopup>
    )
  }

  const loader = () => {
    return (
      <div className="spin-style">
        <Spinner spinning={true} />
      </div>
    )
  }

  const renderContent = () => {
    if (loading) {
      return loader()
    } else {
      return (
        <RoutingDetails origin="sidebar" state={state} setState={setState} />
      )
    }
  }

  const onUpdateError = () => {
    setLoading(false)
    message.error(translations.routing_update_error[language])
    onRoutingUpdateCb()
  }

  const onRelateError = () => {
    setLoading(false)
    message.error(translations.routing_relation_update_error[language])
    onRoutingUpdateCb()
  }

  const updateRoutingRelation = (routingId) => {
    const trunkToRelate = state.trunks.map((item) => {
      return { id: item.id }
    })

    api
      .put(`routing-gateway/${routingId}?sync=yes`, {
        items: trunkToRelate,
      })
      .then((res) => {
        const { result, response } = res.data

        if (
          result &&
          response.items.length &&
          response.items[0].action === 'updated'
        ) {
          setLoading(false)
          message.success(translations.routing_update_success[language])
          onRoutingUpdateCb()
          dispatch(setActualRouting({}))
          dispatch(setModifiedRouting({}))
          if (routingSaveFromOption) {
            dispatch(setRoutingSaveFromOption(false))
            dispatch(setRoutingSavedFromOption(true))
          } else if (routingSaveFromTab) {
            dispatch(setRoutingSaveFromTab(false))
            dispatch(setRoutingSavedFromTab(true))
          }
        } else {
          onRelateError()
        }
      })
      .catch(onRelateError)
  }

  const getMode = (mode) => {
    if (mode === 'Fail over') {
      return 'FO'
    } else if (mode === 'Load balancer') {
      return 'LB'
    } else {
      return ''
    }
  }

  const updateRouting = () => {
    const body = {
      name: state.name,
      data: {
        description: state.desc,
        host_origin: state.host,
        callee: state.callee,
        caller: state.caller,
        mode: getMode(state.mode),
      },
    }

    api
      .patch(`routing/${row.id}?sync=yes`, body)
      .then((res) => {
        const { result, response } = res.data

        if (
          result &&
          response.items.length &&
          response.items[0].action === 'updated'
        ) {
          updateRoutingRelation(row.id)
        } else {
          onUpdateError()
        }
      })
      .catch(onUpdateError)
  }

  const deleteRoutingRelation = () => {
    const routingId = row.id
    const trunkToRelate = state.trunks.map((item) => {
      return { id: item.id }
    })

    api
      .delete(`routing-gateway/${routingId}?sync=yes`, {
        data: {
          items: trunkToRelate,
        },
      })
      .then((res) => {
        const { result, response } = res.data

        if (
          result &&
          response.items.length &&
          response.items[0].action === 'updated'
        ) {
          updateRouting()
        } else {
          onUpdateError()
        }
      })
      .catch(onUpdateError)
  }

  const handleSave = () => {
    const validRoutings = routing.filter((item) => item.name !== row.name)

    if (state.name.length === 0) {
      message.warn(translations.enter_routing_name[language])
    } else if (state.mode.length === 0) {
      message.warn(translations.select_mode[language])
    } else if (state.trunks.length === 0) {
      message.warn(translations.select_trunk[language])
    } else if (validRoutings.some((item) => item.name === state.name)) {
      message.warn(translations.routing_name_exists[language])
    } else {
      setLoading(true)
      deleteRoutingRelation()
    }
  }

  const renderActions = () => {
    if (advancedPermission === FULL_ACCESS) {
      return (
        <div style={{ height: 80 }}>
          <PttButton
            block
            type="primary"
            disabled={loading}
            style={{ margin: '5px 0px' }}
            onClick={handleSave}
          >
            {translations.save[language]}
          </PttButton>
          <Button block danger disabled={loading} onClick={onDelete}>
            {translations.delete_routing[language]}
          </Button>
        </div>
      )
    } else {
      return <Fragment />
    }
  }

  return (
    <div style={{ padding: 5, height: '100%', paddingLeft: 15 }}>
      <div style={{ height: sidebarContHeight, padding: 5 }}>
        {renderContent()}
      </div>
      {renderActions()}
      {renderConfirmPopup()}
    </div>
  )
}

export default RoutingSidebar
