// @flow

import React from 'react'
import { connect } from 'react-redux'
import { Breadcrumb, message } from 'antd'
import {
  ApartmentOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons'
import styled from '@emotion/styled'
import useCustomFetch from '../../../../Hooks/UseCustomFetch'
import Spinner from '../../../../Spinner/spinner'
import { APIURL } from '../../../../utils/constants'
import { getNodeDataFeed, isEmpty } from '../../../../utils/helpers'
import MoreActions from '../../../MoreActions/moreactions'
import ConfirmPopup from '../../../Popup/confirmpopup'
import AddEditFlow from './AddEditFlow'

type Props = {
  callFlow: $ReadOnlyArray<*>,
  callFlowNodes: $ReadOnlyArray<*>,
  deleteCallBack: () => mixed,
  id: string,
  onCloneFinish: () => mixed,
  onFlowViewChange: () => mixed,
  onSuccess: (string) => mixed,
  routeName: string,
  translations: Object,
  language: string,
}

const Body = styled.div`
  min-width: 300px;
  text-align: center;
`
const DisplayRoute = styled.div`
  display: flex;
  align-items: center;
`
const Element = styled.p`
  margin: 0px;
`
const Pointer = styled.span`
  cursor: pointer;
`

const ManageCallFlow = ({
  callFlow,
  callFlowNodes,
  deleteCallBack,
  id,
  onCloneFinish,
  onFlowViewChange,
  onSuccess,
  routeName,
  translations,
  language,
}: Props) => {
  const [cloneModel, setCloneModel] = React.useState(false)
  const [deleteModel, setDeleteModel] = React.useState(false)
  const [renameModel, setRenameModel] = React.useState(false)
  const [cloneName, setCloneName] = React.useState('')
  const [cloneData, setCloneData] = React.useState(null)

  const [cloneApi] = useCustomFetch(
    `${APIURL}/call_flow?sync=yes`,
    'PUT',
    false,
    cloneData
  )

  const [deleteApi] = useCustomFetch(
    `${APIURL}/call_flow/${id}?sync=yes`,
    'DELETE',
    false
  )

  React.useEffect(() => {
    if (!isEmpty(deleteApi.response)) {
      if (deleteApi.response.result) {
        message.success(translations.callflow_deleted_success[language])
        deleteCallBack()
      } else if (!deleteApi.response.result) {
        message.error(translations.deleting_Callflow_failed[language])
        setDeleteModel(false)
      }
    }
  }, [deleteApi.response])

  React.useEffect(() => {
    if (deleteApi.error !== null) {
      message.error(translations.deleting_Callflow_failed[language])
      setDeleteModel(false)
    }
  }, [deleteApi.error])

  const actionCallback = (action) => {
    action === 'Clone' && setCloneModel(true)
    action === 'Delete' && setDeleteModel(true)
    action === 'Rename' && setRenameModel(true)
  }

  const deleteBody = React.useMemo(
    () => (
      <Body>
        {deleteApi.loading ? (
          <div className="align-center full-height">
            <Spinner spinning={true} />
          </div>
        ) : (
          <React.Fragment>
            <Element>{translations.delete_callflow_info_1[language]}</Element>
            <Element>{translations.delete_callflow_info_2[language]}</Element>
          </React.Fragment>
        )}
      </Body>
    ),
    [deleteApi.loading]
  )

  const onAddFlowName = (value) => {
    onSuccess(value)
    onClose()
  }

  const onClose = () => {
    setRenameModel(false)
  }

  const onCloneClose = () => {
    setCloneModel(false)
  }

  const onCloneTrigger = (cloneName) => {
    message.loading({
      content: `cloning ${cloneName}....`,
      key: 'cloneLoader',
    })

    setCloneName(cloneName)
    setCloneData(
      JSON.stringify({
        data: getNodeDataFeed(callFlowNodes),
        name: cloneName,
      })
    )
    setCloneModel(false)
  }

  React.useEffect(() => {
    if (cloneData) {
      cloneApi.setRefetch(true)
    }
  }, [cloneData])

  const handleCloneApiError = () => {
    message.destroy('cloneLoader')
    message.error(`Error cloning ${cloneName}`)
    cloneApi.setResponse({})
    setCloneName('')
    setCloneData({})
  }

  React.useEffect(() => {
    if (!isEmpty(cloneApi.response)) {
      if (cloneApi.response.result) {
        message.destroy('cloneLoader')
        message.success(`${cloneName} cloned successfully`)
        onCloneFinish()
      } else if (!cloneApi.response.result) {
        handleCloneApiError()
      }
    }
  }, [cloneApi.response])

  React.useEffect(() => {
    if (cloneApi.error !== null) {
      handleCloneApiError()
    }
  }, [cloneApi.error])

  const getExactText = (text, routename) => {
    if (routename === text || `${translations.copy_of[language]} ${routeName}`) {
      return text
    } else {
      return text.includes('(') ? text.split('(')[0] : ''
    }
  }

  const getCloneName = (routeName) => {
    const times = callFlow
      .map((flow) => flow.name)
      .map((text) => getExactText(text, routeName))
      .filter((text) => text.length)

    return times.length > 1
      ? `${translations.copy_of[language]} ${routeName}(${times.length - 1})`
      : `${translations.copy_of[language]} ${routeName}`
  }

  return (
    <React.Fragment>
      <ConfirmPopup
        cancelText={translations.cancel[language]}
        closable={true}
        confirmText={translations.delete_call_flow[language]}
        okayText={translations.delete[language]}
        onCancel={() => {
          setDeleteModel(false)
        }}
        onOkay={() => deleteApi.setRefetch(true)}
        visiblity={deleteModel}
      >
        {deleteBody}
      </ConfirmPopup>
      <AddEditFlow
        buttonText={translations.save[language]}
        onClose={onClose}
        routeFlowName={routeName}
        successCallBack={onAddFlowName}
        title={translations.edit_call_flow[language]}
        type="Edit"
        value={routeName}
        visiblity={renameModel}
      />
      <AddEditFlow
        buttonText={translations.clone[language]}
        onClose={onCloneClose}
        routeFlowName={routeName}
        successCallBack={onCloneTrigger}
        title={translations.clone_call_flow[language]}
        type="clone"
        value={getCloneName(routeName)}
        visiblity={cloneModel}
      />
      <DisplayRoute>
        <Breadcrumb>
          <Breadcrumb.Item onClick={onFlowViewChange}>
            <ApartmentOutlined />
            <Pointer>{translations.call_flow_title[language]}</Pointer>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{routeName}</Breadcrumb.Item>
        </Breadcrumb>
        <div style={{ marginLeft: 5 }}>
          <MoreActions
            actionCallBack={actionCallback}
            contents={[
              {
                render: () => <EditOutlined />,
                text: translations.rename[language],
              },
              { 
                render: () => <CopyOutlined />,
                text: translations.clone[language], 
              },
              {
                render: () => <DeleteOutlined />,
                text: translations.delete[language],
              },
            ]}
            placement="bottomLeft"
          />
        </div>
      </DisplayRoute>
    </React.Fragment>
  )
}

const mapsStateToProps = (state) => {
  return {
    translations: state.sampleReducer.translations || {},
    language: state.sampleReducer.language,
    callFlow: state.incomingCallsReducer.callFlow || [],
    callFlowNodes: state.incomingCallsReducer.callFlowNodes || [],
  }
}

export default connect(mapsStateToProps, null)(ManageCallFlow)
