import React, { useState, useMemo, useEffect, Fragment } from 'react'
import ModalLayout, { Body, Footer } from '../Popup/popup'
import { Header } from 'antd/lib/layout/layout'
import Spinner from '../../Spinner/spinner'
import { message, Input, Button } from 'antd'
import { getTranslatedText } from '../../utils/translations'
import { useSelector } from 'react-redux'
import PttButton from '../CustomButton/PttButton'
import MemoTable from '../MemoTable/memotable'
import { SearchOutlined } from '@ant-design/icons'
import useCustomFetch from '../../Hooks/UseCustomFetch'
import {
  APIURL,
  API_MANAGER_KEY,
  ID_TOKEN,
  SIGNUP_API,
  TOKEN_TYPE,
} from '../../utils/constants'
import { isEmpty } from 'lodash'
import { getFilteredArray } from '../../utils/helpers'
import InfoPanel from '../InfoPanel'
import InviteUserEdit from './InviteUserEdit'
import AccessDropDown from '../CustomComponents/AccessDropDown'
import { FULL_ACCESS, NO_ACCESS } from '../../utils/constants'
import axios from 'axios'
import { getItem } from '../../utils/storage'

const UserTab = () => {
  const {
    appUrlBrand,
    language,
    translations,
    userAccounId,
    accessUsersPermission,
  } = useSelector(({ sampleReducer, permissionReducer }) => ({
    language: sampleReducer.language || 'en',
    translations: sampleReducer.translations || {},
    userAccounId: sampleReducer.userAccounId,
    appUrlBrand: sampleReducer.appUrlBrand,
    accessUsersPermission: permissionReducer.accessUsersPermission,
  }))

  const api = axios.create({
    baseURL: APIURL,
    headers: {
      Authorization: `${getItem(TOKEN_TYPE)} ${getItem(ID_TOKEN)}`,
      'Content-Type': 'application/json',
      'x-api-key': API_MANAGER_KEY,
    },
  })

  const [popUpState, setPopUpState] = useState(false)
  const [email, setEmail] = useState('')
  const [filter, setFilter] = useState('')
  const [loading, setLoading] = useState(false)
  const [info, setInfo] = useState(false)
  const [accounts, setAccounts] = useState([])
  const [row, setRow] = useState({})

  const [MICROSOFT_TEAMS, SET_MICROSOFT_TEAMS] = useState(NO_ACCESS)
  const [PHONE_NUMBERS, SET_PHONE_NUMBERS] = useState(NO_ACCESS)
  const [STATS, SET_STATS] = useState(NO_ACCESS)
  const [ADVANCED, SET_ADVANCED] = useState(NO_ACCESS)
  const [BILLING, SET_BILLING] = useState(NO_ACCESS)
  const [COMPANY_SETTINGS, SET_COMPANY_SETTINGS] = useState(NO_ACCESS)
  const [ACCESS_USERS, SET_ACCESS_USERS] = useState(NO_ACCESS)

  const mdr = appUrlBrand !== 'Plug2Teams'

  const [usersApi] = useCustomFetch(`${APIURL}/user`, 'GET', true)

  const [signup2Api] = useCustomFetch(
    `${SIGNUP_API}/1/${email}?myDirectRouting=${mdr}`,
    'SIGNUP_POST',
    false,
    JSON.stringify({
      accountId: userAccounId,
    })
  )

  const refetchUsers = () => {
    usersApi.setRefetch(true)
  }

  const resetPopupState = () => {
    setEmail('')
    SET_MICROSOFT_TEAMS(NO_ACCESS)
    SET_PHONE_NUMBERS(NO_ACCESS)
    SET_STATS(NO_ACCESS)
    SET_ADVANCED(NO_ACCESS)
    SET_BILLING(NO_ACCESS)
    SET_COMPANY_SETTINGS(NO_ACCESS)
    SET_ACCESS_USERS(NO_ACCESS)
  }

  const resultHandler = () => {
    setLoading(false)
    setPopUpState(false)
    signup2Api.setResponse({})
    resetPopupState()
    message.success(
      `${getTranslatedText(translations, language, 'Email_sent_to')} ${email}`
    )
  }

  const errorHandler = () => {
    setLoading(false)
    message.error(
      `${getTranslatedText(
        translations,
        language,
        'oops_something_went_wrong'
      )}`
    )
  }

  const updateInviteItem = () => {
    const updateUrl = `invited_users/${email}?sync=yes`
    const permissions = [
      { MICROSOFT_TEAMS: MICROSOFT_TEAMS },
      { PHONE_NUMBERS: PHONE_NUMBERS },
      { STATS: STATS },
      { ADVANCED: ADVANCED },
      { BILLING: BILLING },
      { COMPANY_SETTINGS: COMPANY_SETTINGS },
      { ACCESS_USERS: ACCESS_USERS },
    ]

    api
      .patch(updateUrl, {
        status: 'INVITED',
        permissions: permissions,
      })
      .then((res) => {
        if (res.data.result) {
          resultHandler()
        } else {
          errorHandler()
        }
      })
      .catch(() => {
        errorHandler()
      })
  }

  const createInviteItem = () => {
    const createUrl = 'invited_users?sync=yes'
    const permissions = [
      { MICROSOFT_TEAMS: MICROSOFT_TEAMS },
      { PHONE_NUMBERS: PHONE_NUMBERS },
      { STATS: STATS },
      { ADVANCED: ADVANCED },
      { BILLING: BILLING },
      { COMPANY_SETTINGS: COMPANY_SETTINGS },
      { ACCESS_USERS: ACCESS_USERS },
    ]

    api
      .put(createUrl, {
        id: email,
        status: 'INVITED',
        permissions: permissions,
      })
      .then((res) => {
        if (res.data.result) {
          resultHandler()
        } else if (res?.data?.error.includes('not created, (entity exits)')) {
          updateInviteItem()
        } else {
          errorHandler()
        }
      })
      .catch(() => {
        errorHandler()
      })
  }

  useEffect(() => {
    if (!isEmpty(signup2Api.response)) {
      if (signup2Api.response.status) {
        createInviteItem()
      } else {
        setLoading(false)

        if (signup2Api.response.error === 'USER_ALREADY_EXIST') {
          message.error(
            getTranslatedText(translations, language, 'USER_ALREADY_EXIST')
          )
        } else {
          message.error(
            getTranslatedText(translations, language, 'error_sending_email')
          )
        }
      }
    }
  }, [signup2Api.response])

  useEffect(() => {
    if (!isEmpty(usersApi.response)) {
      if (
        usersApi.response.result &&
        usersApi.response.response.items.length &&
        usersApi.response.response.items[0].id
      ) {
        setAccounts(usersApi.response.response.items)
      } else {
        setAccounts([])
      }
    }
  }, [usersApi.response])

  const handleUserInvite = () => {
    setPopUpState(true)
  }

  const renderPopUp = () => {
    const exitHandler = () => {
      setPopUpState(false)
      resetPopupState()
    }

    const handleEmail = () => {
      // eslint-disable-next-line no-useless-escape
      const validator = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

      if (email.trim().length === 0) {
        message.warning(
          getTranslatedText(translations, language, 'enter_useremail')
        )
        // eslint-disable-next-line no-negated-condition
      } else if (!validator.test(email)) {
        message.warning(
          getTranslatedText(translations, language, 'enter_valid_email')
        )
      } else {
        setLoading(true)
        signup2Api.setRefetch(true)
      }
    }

    const handleCancel = () => {
      setPopUpState(false)
      resetPopupState()
    }

    const renderMailBox = () => {
      const text = getTranslatedText(translations, language, 'email')

      return (
        <Fragment>
          <div style={{ margin: '5px 0px', fontSize: 17 }}>{text}</div>
          <Input
            value={email}
            style={{ width: '97%' }}
            onChange={(event) => {
              setEmail(event.target.value)
            }}
            onPressEnter={handleEmail}
          />
        </Fragment>
      )
    }

    return (
      <ModalLayout
        visible={popUpState}
        onCancel={exitHandler}
        className="add-payment-modal"
        closable={!loading}
      >
        <Header>{translations.userInvite[language]}</Header>
        <Body>
          <div className="invite-user-mailbox">
            {renderMailBox()}
            <div style={{ marginTop: 5, fontSize: 17 }}>
              {translations.permissions[language]}
            </div>
            <AccessDropDown
              changeCallback={(value) => SET_MICROSOFT_TEAMS(value)}
              dropDownStyle={{ width: '97%' }}
              heading={translations.microsoft_teams[language]}
              value={MICROSOFT_TEAMS}
            />
            <AccessDropDown
              changeCallback={(value) => SET_PHONE_NUMBERS(value)}
              dropDownStyle={{ width: '97%' }}
              heading={translations.phone_number_label[language]}
              value={PHONE_NUMBERS}
            />
            <AccessDropDown
              changeCallback={(value) => SET_STATS(value)}
              dropDownStyle={{ width: '97%' }}
              heading={translations.stats[language]}
              value={STATS}
            />
            <AccessDropDown
              changeCallback={(value) => SET_ADVANCED(value)}
              dropDownStyle={{ width: '97%' }}
              heading={translations.advanced[language]}
              value={ADVANCED}
            />
            <AccessDropDown
              changeCallback={(value) => SET_BILLING(value)}
              dropDownStyle={{ width: '97%' }}
              heading={translations.billing[language]}
              value={BILLING}
            />
            <AccessDropDown
              changeCallback={(value) => SET_COMPANY_SETTINGS(value)}
              dropDownStyle={{ width: '97%' }}
              heading={translations.company_settings[language]}
              value={COMPANY_SETTINGS}
            />
            <AccessDropDown
              changeCallback={(value) => SET_ACCESS_USERS(value)}
              dropDownStyle={{ width: '97%' }}
              heading={translations.settings_user_tab[language]}
              value={ACCESS_USERS}
            />
          </div>
        </Body>
        <Footer>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Button
              className="back-button-style"
              onClick={handleCancel}
              disabled={loading}
            >
              {translations.cancel[language]}
            </Button>
            <PttButton
              onClick={handleEmail}
              type="primary"
              loading={loading}
              disabled={loading}
            >
              {translations.sendInvite[language]}
            </PttButton>
          </div>
        </Footer>
      </ModalLayout>
    )
  }

  const refetchUsersAndClosePanel = () => {
    setRow({})
    setInfo(false)
    usersApi.setRefetch(true)
  }

  const userTable = useMemo(() => {
    const userData = accounts
    const tableData = filter.length
      ? getFilteredArray(userData, filter, 'username')
      : userData

    const data = tableData.map((data, index) => ({
      key: String(index),
      name: data.username,
      email: data.username,
      accountId: data.account,
      userId: data.id,
      type: data.type,
    }))

    const columns = [
      {
        dataIndex: 'name',
        sorter: (a, b) => {
          const wordA = a.name.toLowerCase()
          const wordB = b.name.toLowerCase()

          if (wordA < wordB) {
            return -1
          } else if (wordA > wordB) {
            return 1
          } else {
            return 0
          }
        },
        title: translations.email[language],
        defaultSortOrder: 'ascend',
      },
    ]

    const rowSelectHandler = (row) => {
      if (row) {
        setRow(row)
        setInfo(true)
      }
    }

    return (
      <MemoTable
        columns={columns}
        dataSource={data}
        isSelectable={true}
        selectedRow={row}
        onSelectedRowChange={rowSelectHandler}
        scrollObject={{ y: 'calc(100vh - 225px)' }}
        style={{ height: '100%' }}
      />
    )
  }, [accounts, filter, row])

  const loader = () => {
    return (
      <div className="align-center full-height">
        <Spinner spinning={true} />
      </div>
    )
  }

  const closeInfo = () => {
    setInfo(false)
  }

  const renderInfoPanelChild = () => {
    return (
      <InviteUserEdit
        row={row}
        refetchUsersAndClosePanel={refetchUsersAndClosePanel}
        refetchUsers={refetchUsers}
        setInfo={setInfo}
      />
    )
  }

  const renderContent = () => {
    if (usersApi.loading) {
      return loader()
    } else {
      return (
        <div className="panel-cont">
          <div className={info ? 'ms-active-style' : 'ms-inactive'}>
            {userTable}
          </div>
          <InfoPanel
            changeVisiblity={closeInfo}
            visiblity={info}
            width={'320px'}
          >
            {renderInfoPanelChild()}
          </InfoPanel>
        </div>
      )
    }
  }

  return (
    <div className="call-flow-main-cont">
      <div className="call-flow-utils-container">
        <div style={{ display: 'flex', marginTop: 'auto' }}>
          <Input
            className="call-flow-utils-input"
            onChange={(event) => setFilter(event.target.value.trim())}
            placeholder={translations.search[language]}
            prefix={<SearchOutlined />}
          />
          <PttButton
            onClick={handleUserInvite}
            type="primary"
            disabled={accessUsersPermission !== FULL_ACCESS}
          >
            {translations.userInvite[language]}
          </PttButton>
          {renderPopUp()}
        </div>
      </div>
      <div style={{ height: 'calc(100% - 40px)', paddingTop: 15 }}>
        <div className="billing-inner-body">
          <div className="billing-table-body">{renderContent()}</div>
        </div>
      </div>
    </div>
  )
}

export default UserTab
