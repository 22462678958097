import React from 'react'
import styled from '@emotion/styled'

const Container = styled.div`
  height: 100%;
  background-color: ${(options) => options.background};
  background-size: 50px 50px;
  background-image: linear-gradient(
      0deg,
      transparent 24%,
      ${(options) => options.color} 25%,
      ${(options) => options.color} 26%,
      transparent 27%,
      transparent 74%,
      ${(options) => options.color} 75%,
      ${(options) => options.color} 76%,
      transparent 77%,
      transparent
    ),
    linear-gradient(
      90deg,
      transparent 24%,
      ${(options) => options.color} 25%,
      ${(options) => options.color} 26%,
      transparent 27%,
      transparent 74%,
      ${(options) => options.color} 75%,
      ${(options) => options.color} 76%,
      transparent 77%,
      transparent
    );

  > * {
    height: 100%;
    min-height: 100%;
    width: 100%;
  }
`

// eslint-disable-next-line react/prop-types
const DiagramCanvas = ({ children }) => {
  return (
    <Container background="#FFFFFF" color="#E5E5E5">
      {children}
    </Container>
  )
}

export default DiagramCanvas
