// @flow

import React, { useMemo } from 'react'
import { Table } from 'antd'
import SelectableTableRow from '../SelectableTableRow/selectabletablerow'

type Props = {
  columns: $ReadOnlyArray<*>,
  dataSource: $ReadOnlyArray<*>,
  pagination?: boolean,
  scrollObject?: Object,
  showHeader?: boolean,
  showSorterTooltip?: boolean,
  size?: string,
  isSelectable: boolean,
  selectedRow?: Object,
  onSelectedRowChange?: (any) => mixed,
  customClass?: string,
}

const MemoTable = (props: Props) => {
  const {
    columns,
    dataSource,
    pagination = false,
    scrollObject = {},
    showHeader = true,
    showSorterTooltip = false,
    size = 'small',
    isSelectable,
    selectedRow = {},
    onSelectedRowChange: updateSelectedRow,
    customClass = '',
  } = props

  const getComponents = useMemo(() => {
    const componentsObject = {
      table: '',
      header: {
        wrapper: '',
        row: '',
        cell: '',
      },
      body: {
        wrapper: '',
        row: () => '',
        cell: '',
      },
    }

    const handleOnRowSelected = (value) => {
      const rowData = props.dataSource
      const selectedRowData = rowData.find((data) => data.key === value)

      if (updateSelectedRow) {
        updateSelectedRow(selectedRowData)
      }
    }

    if (isSelectable) {
      componentsObject.body.row = (props) => (
        <SelectableTableRow
          componentData={props}
          selectedRow={selectedRow.key}
          updateSelectedRow={handleOnRowSelected}
        />
      )
    }

    return componentsObject
  }, [props.dataSource, updateSelectedRow])

  return (
    <Table
      {...props}
      components={getComponents}
      columns={columns}
      dataSource={dataSource}
      pagination={pagination}
      scroll={scrollObject}
      showHeader={showHeader}
      showSorterTooltip={showSorterTooltip}
      size={size}
      className={customClass}
    />
  )
}

export default MemoTable
