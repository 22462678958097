// @flow

import React, { useEffect, useState } from 'react'
import { Tabs } from 'antd'
import Balance from './Balance'
import PaymentMethod from './PaymentMethod'
import {
  APIURL,
  STRIPE_PUBLISHABLE_KEY,
  CUSTOM_ACTION_URL,
} from '../../utils/constants'
import useCustomFetch from '../../Hooks/UseCustomFetch'
// import { loadStripe } from '@stripe/stripe-js'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as actions from '../../actions/SampleAction'
import { setPaymentList } from '../../actions/billingAction'
import './billing.css'
import Subscription from './Subscription'

// const stripe = loadStripe(STRIPE_PUBLISHABLE_KEY)

type Props = {
  userDetails: any,
  userStripeData: Object,
  setPaymentList: (any) => mixed,
  translations: Object,
  language: string,
}

const Billing = (props: Props) => {
  const {
    userDetails,
    userStripeData,
    setPaymentList,
    translations,
    language,
  } = props
  // eslint-disable-next-line no-unused-vars
  const [paymentMethods, setPaymentMethods] = useState([])
  const { TabPane } = Tabs

  /* get customer stripe info (Currently not used in app)*/
  // eslint-disable-next-line no-unused-vars
  const [stripeCustomerApi] = useCustomFetch(
    `${APIURL}/account_settings_system/${userDetails.username}?idname=data.email`,
    'GET',
    false
  )

  /* List payment methods of the loggedin customer */
  const [listPaymentMethodApi] = useCustomFetch(
    `${CUSTOM_ACTION_URL}/payment`,
    'PAYMENT',
    false,
    JSON.stringify({
      action: 'LIST_PAYMENT_METHODS',
      data: {
        customerId: userStripeData.stripe_id,
      },
    })
  )

  useEffect(() => {
    if (userStripeData && userStripeData.stripe_id) {
      listPaymentMethodApi.setRefetch(true)
    }
  }, [userStripeData])

  useEffect(() => {
    if (
      listPaymentMethodApi.response &&
      listPaymentMethodApi.response.status &&
      listPaymentMethodApi.response.response
    ) {
      // setPaymentMethods(listPaymentMethodApi.response.response.data)
      setPaymentList(listPaymentMethodApi.response.response.data)
    }
  }, [listPaymentMethodApi.response])

  const getPaymentMethods = () => {
    listPaymentMethodApi.setRefetch(true)
  }

  return (
    <div style={{ height: '100%' }}>
      <Tabs defaultActiveKey="1" className="billing-tab">
        <TabPane tab={translations.transactions[language]} key="1">
          <Balance getPaymentMethods={getPaymentMethods} />
        </TabPane>
        <TabPane tab={translations.payment_methods[language]} key="2">
          <PaymentMethod /* stripePromise={stripe} */ />
        </TabPane>
        <TabPane tab={translations.subscriptions[language]} key="3">
          <Subscription />
        </TabPane>
      </Tabs>
    </div>
  )
}

const mapsStateToProps = (state) => {
  return {
    userDetails: state.sampleReducer.userDetails || {},
    userStripeData: state.sampleReducer.userStripeData || {},
    translations: state.sampleReducer.translations || {},
    language: state.sampleReducer.language,
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...actions,
      setPaymentList,
    },
    dispatch
  )

export default connect(mapsStateToProps, mapDispatchToProps)(Billing)
