export const setIsCallingPlanSaved = (data) => ({
  type: 'isCallingPlanSaved',
  data,
})

export const setMsTeamsUnsaveAlertTrigger = (data) => {
  return {
    type: 'setMsTeamsUnsaveAlertTrigger',
    data: data,
  }
}

export const setActualVoiceUserData = (actualVoiceUserData) => ({
  type: 'setActualVoiceUserData',
  actualVoiceUserData,
})

export const setModifiedVoiceUserData = (modifiedVoiceUserData) => ({
  type: 'setModifiedVoiceUserData',
  modifiedVoiceUserData,
})

export const setUpdateVoiceUserOnTheFly = (updateVoiceUserOnTheFly) => ({
  type: 'setUpdateVoiceUserOnTheFly',
  updateVoiceUserOnTheFly,
})

export const setMicrosoftUsersCollection = (microsoftUsersCollections) => ({
  type: 'setMicrosoftUsersCollection',
  microsoftUsersCollections,
})

export const setTeamsStep = (teamsStep) => ({
  type: 'setTeamsStep',
  teamsStep,
})