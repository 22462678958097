/* eslint-disable react/prop-types */

import React, { useEffect, useState } from 'react'
import { message, Tabs } from 'antd'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import ModalLayout, { Header, Body, Footer } from '../../Popup/popup'
import PttButton from '../../CustomButton/PttButton'
import ModifiersTab from './ModifiersTab'
import CredentialsTab from './CredentialsTab'
import GeneralTab from './GeneralTab'
import { initGeneral, initCredentials, initModifiers } from './TrunksUtils'
import {
  APIURL,
  API_MANAGER_KEY,
  ID_TOKEN,
  TOKEN_TYPE,
} from '../../../utils/constants'
import { getItem } from '../../../utils/storage'
import { setActualTrunk, setModifiedTrunk } from '../../../actions/trunksAction'

const AdddTrunkPopup = ({
  visiblity,
  exitHandler,
  onCreateTrunkStart,
  onCreateTrunkCompleted,
  addLoading,
  trunks,
}) => {
  const { TabPane } = Tabs
  const dispatch = useDispatch()
  const { language, translations } = useSelector((state) => {
    return {
      language: state.sampleReducer.language,
      translations: state.sampleReducer.translations,
    }
  })

  const api = axios.create({
    baseURL: APIURL,
    headers: {
      Authorization: `${getItem(TOKEN_TYPE)} ${getItem(ID_TOKEN)}`,
      'Content-Type': 'application/json',
      'x-api-key': API_MANAGER_KEY,
    },
  })

  const [key, setKey] = useState('1')
  const [tab1Init, setTab1Init] = useState(false)
  const [tab2Init, setTab2Init] = useState(false)
  const [tab3Init, setTab3Init] = useState(false)
  const [general, setGeneral] = useState(initGeneral())
  const [credentials, setCredentials] = useState(initCredentials())
  const [modifiers, setModifiers] = useState(initModifiers())

  useEffect(() => {
    if (visiblity) {
      setKey('1')
      setGeneral(initGeneral())
      setCredentials(initCredentials())
      setModifiers(initModifiers())
    }
  }, [visiblity])

  const createTrunk = () => {
    const body = {
      name: general.name,
      data: {
        description: general.description,
        host: general.host,
        port: general.port,
        protocol: general.protocol,
        type: 'TRUNK',
        credentials: credentials,
        modifier: {
          rules: modifiers.rules.map((rule) => {
            return {
              prefix: rule.prefix,
              suffix: rule.suffix,
              stripleft: rule.stripLeft,
              stripright: rule.stripRight,
              min_length: String(rule.minLength),
              max_length: String(rule.maxLength),
            }
          }),
          international: {
            country_code: modifiers.international.countryCode,
            symbol: modifiers.international.symbol,
            force_symbol: modifiers.international.forceSymbol,
          },
        },
      },
    }

    api
      .put('gateway?sync=yes', body)
      .then((res) => {
        if (res.data.result) {
          onCreateTrunkCompleted()
          dispatch(setActualTrunk({}))
          dispatch(setModifiedTrunk({}))
          message.success(translations.create_trunk_success[language])
        } else {
          message.error(translations.create_trunk_error[language])
        }
      })
      .catch(() => {
        message.error(translations.create_trunk_error[language])
      })
  }

  const onCreate = () => {
    if (general.name.length === 0) {
      message.warn(translations.enter_trunk_name[language])
      onTabChange('1')
    } else if (general.host.length === 0) {
      message.warn(translations.enter_host_name[language])
      onTabChange('1')
    } else if (trunks.some((item) => item.name === general.name)) {
      message.warn(translations.trunk_name_exists[language])
    } else {
      onCreateTrunkStart()
      createTrunk()
    }
  }

  const getFooter = () => {
    return (
      <PttButton
        type="primary"
        onClick={onCreate}
        disabled={addLoading}
        loading={addLoading}
      >
        {translations.add[language]}
      </PttButton>
    )
  }

  const closeHandler = () => {
    setKey('1')
    setGeneral(initGeneral())
    setCredentials(initCredentials())
    setModifiers(initModifiers())
    exitHandler()
  }

  const onTabChange = (activeKey) => {
    setKey(activeKey)
    setTab1Init(false)
    setTab2Init(false)
    setTab3Init(false)
    if (activeKey === '1') {
      setTab1Init(true)
    } else if (activeKey === '2') {
      setTab2Init(true)
    } else if (activeKey === '3') {
      setTab3Init(true)
    }
  }

  return (
    <ModalLayout
      visible={visiblity}
      onCancel={closeHandler}
      closable={!addLoading}
      destroyOnClose={true}
    >
      <Header>{translations.add_trunk[language]}</Header>
      <Body>
        <div style={{ height: 350, width: 600 }}>
          <Tabs activeKey={key} onChange={onTabChange} className="billing-tab">
            <TabPane tab={translations.general[language]} key="1">
              <GeneralTab
                init={tab1Init}
                state={general}
                setState={setGeneral}
              />
            </TabPane>
            <TabPane tab={translations.credentials[language]} key="2">
              <CredentialsTab
                init={tab2Init}
                state={credentials}
                setState={setCredentials}
              />
            </TabPane>
            <TabPane tab={translations.modifiers[language]} key="3">
              <ModifiersTab
                init={tab3Init}
                state={modifiers}
                setState={setModifiers}
              />
            </TabPane>
          </Tabs>
        </div>
      </Body>
      <Footer>{getFooter()}</Footer>
    </ModalLayout>
  )
}

export default AdddTrunkPopup
