import { translations } from '../utils/translations'

const sampleReducer = (
  state = {
    userStripeData: {},
    accountConfig: {},
    translations,
    language: 'en',
    accountSettings: {},
    collapsed: true,
    userAccounId: '',
    isResellerAccount: undefined,
    accountPlanId: '',
    appBlurState: false,
    appFromClient: false,
    appUrlBrand: '',
    appProviderBrand: '',
  },
  action
) => {
  switch (action.type) {
    case 'Sample-Action':
      return { ...state, data: action.data }

    case 'User-Details':
      return { ...state, userDetails: action.deta }

    case 'Stripe-Details':
      return { ...state, userStripeData: action.data }

    case 'Account-details':
      action.data.map((item) => {
        if (item.custom_id === 'stripe_account' && item.data) {
          state.userStripeData = item.data
        }
      })

      return state

    case 'Set-language':
      return { ...state, language: action.data }

    case 'SetTranslations':
      return { ...state, translations: action.translationJson }

    case 'SetAccountSettings':
      return { ...state, accountSettings: action.data }

    case 'SetAccountConfig':
      return { ...state, accountConfig: action.config }

    case 'SetUserSettings':
      return { ...state, userSettings: action.data }

    case 'SetCollapsed':
      return { ...state, collapsed: action.data }

    case 'isCallingPlanSaved':
      return { ...state, isCallingPlanSaved: action.data }

    case 'Home-Key':
      return { ...state, homeKey: action.key }

    case 'setUserAccounId':
      return { ...state, userAccounId: action.userAccounId }

    case 'SET_IS_RESELLER_ACCOUNT':
      return { ...state, isResellerAccount: action.isResellerAccount }

    case 'SetAccountPlanId':
      return { ...state, accountPlanId: action.accountPlanId }

    case 'SET_APP_BLUR_STATE':
      return { ...state, appBlurState: action.appBlurState }

    case 'SET_APP_FROM_CLIENT':
      return { ...state, appFromClient: action.appFromClient }

    case 'SET_APP_URL_BRAND':
      return { ...state, appUrlBrand: action.appUrlBrand }

    case 'SET_APP_PROVIDER_BRAND':
      return { ...state, appProviderBrand: action.appProviderBrand }

    default:
      return state
  }
}

export default sampleReducer
