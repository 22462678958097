// @flow

import React from 'react'
import { Input } from 'antd'

type Props = {
  changeHandler: (value: string, position: number) => mixed,
  onBlur: () => mixed,
  position: number,
  value: string,
}

const ShortText = ({ onBlur, position, changeHandler, value }: Props) => {
  const onChange = (event) => {
    if (event.target.value.length <= 8) {
      changeHandler(event.target.value, position)
    }
  }

  return <Input onBlur={onBlur} onChange={onChange} value={value} />
}

export default React.memo<Props>(ShortText)
