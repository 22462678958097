/* eslint-disable no-negated-condition */
import { Input, message } from 'antd'
import { LockOutlined } from '@ant-design/icons'
import React, { Fragment, useState } from 'react'
import { useSelector } from 'react-redux'
import Spinner from '../../Spinner/spinner'
import { StartupButton } from '../CustomButton/custombutton'
import StartupImagePanel from '../StartUps/StartupImagePanel'
import { getParameterByName } from '../../utils/helpers'
import { AUTH_API_KEY } from '../../utils/constants'
import { LOGINAUTHURL } from '../../utils/constants'
import { SIGNUP_API } from '../../utils/constants'
import { SIGNUP_APIKEY } from '../../utils/constants'
import { getTranslatedText } from '../../utils/translations'
import { setTokens } from '../../utils/LocalStorage'
import { useHistory } from 'react-router-dom'

const logo = require('../../assets/images/colored-transp-bg-vertical.png')

const Resetpassword = () => {
  const history = useHistory()
  const token = getParameterByName('token')
  const email = getParameterByName('email')

  const { appUrlBrand, language, translations } = useSelector((state) => {
    return {
      language: state.sampleReducer.language,
      translations: state.sampleReducer.translations,
      appUrlBrand: state.sampleReducer.appUrlBrand,
    }
  })

  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [loading, setLoading] = useState(false)

  const onPasswordChange = (event) => {
    setPassword(event.target.value)
  }

  const onConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value)
  }

  const handleError = () => {
    setLoading(false)
    message.error(
      getTranslatedText(translations, language, 'something_went_wrong')
    )
  }

  const resetPassword = () => {
    if (token && email) {
      fetch(`${SIGNUP_API}/resetpassword/${email}?myDirectRouting=false`, {
        headers: {
          'x-api-key': SIGNUP_APIKEY,
        },
        method: 'POST',
        body: JSON.stringify({
          token: token,
          password: password.trim(),
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status) {
            fetch(LOGINAUTHURL, {
              headers: {
                'Content-Type': 'application/json',
                'x-api-key': AUTH_API_KEY,
              },
              method: 'PUT',
              body: JSON.stringify({
                username: String(email).trim(),
                password: String(password).trim(),
                permisionlist: [],
              }),
            })
              .then((authResponse) => authResponse.json())
              .then((authResponse) => {
                if (authResponse.result === true && !authResponse.error) {
                  setTokens(authResponse)
                  setTimeout(() => {
                    setLoading(false)
                    history.push({ pathname: '/' })
                  }, 1000)
                } else {
                  handleError()
                }
              })
              .catch(() => {
                handleError()
              })
          } else {
            handleError()
          }
        })
        .catch(() => {
          handleError()
        })
    }
  }

  const onResetClick = () => {
    const pass = password.trim()
    const confirmPass = confirmPassword.trim()

    if (pass.length === 0) {
      return message.warning(translations.setPassword_password[language])
    } else if (confirmPass.length === 0) {
      return message.warning(translations.setPassword_confirmPassword[language])
    } else if (pass.length !== confirmPass.length) {
      return message.warning(translations.setPassword_noMatch[language])
    } else if (pass !== confirmPass) {
      return message.warning(translations.setPassword_noMatch[language])
    } else {
      setLoading(true)
      resetPassword()
    }
  }

  const renderInputs = () => {
    return (
      <Fragment>
        <div>
          <Input.Password
            className="start-up-input"
            onChange={onPasswordChange}
            placeholder={translations.new_password[language]}
            onPressEnter={onResetClick}
            prefix={<LockOutlined className="startup-prefix-style" />}
            size="large"
            value={password}
          />
        </div>
        <div>
          <Input.Password
            className="start-up-input"
            onChange={onConfirmPasswordChange}
            placeholder={translations.confirm_password[language]}
            onPressEnter={onResetClick}
            prefix={<LockOutlined className="startup-prefix-style" />}
            size="large"
            value={confirmPassword}
          />
        </div>
        <StartupButton
          block
          disabled={loading}
          loading={loading}
          onClick={onResetClick}
        >
          {translations.reset_password[language]}
        </StartupButton>
      </Fragment>
    )
  }

  const renderlogo = () => {
    if (appUrlBrand === 'Plug2Teams') {
      return (
        <div className="align-center">
          <img alt="pttlogo" className="signup-colored-logo" src={logo} />
        </div>
      )
    } else {
      return <Fragment />
    }
  }

  const startupInputs = () => {
    return (
      <Fragment>
        {renderlogo()}
        <div
          className="align-center startup-title"
          style={{ whiteSpace: 'nowrap' }}
        >
          {translations.reset_your_password[language]}
        </div>
        <div className="startup-result-container">
          <div className="startup-info-text">
            {translations.please_enter_your_new_password[language]}
          </div>
        </div>
        {renderInputs()}
      </Fragment>
    )
  }

  return (
    <div className="startup-container">
      <div className="startup-image-section">
        <StartupImagePanel text={translations.startup_image_text[language]} />
      </div>
      <div className="startup-field-section">
        <div className="align-center full-height">
          <Spinner color="#00569D" spinning={loading}>
            <div className="align-center startup-field-container">
              <div className="startup-field-inputs">{startupInputs()}</div>
            </div>
          </Spinner>
        </div>
      </div>
    </div>
  )
}

export default Resetpassword
