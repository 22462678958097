// @flow

import React from 'react'
import { Select } from 'antd'
import _ from 'lodash'
import { isTextSame } from '../../../utils/helpers'
import { COMMON_IMG_URL } from '../../../utils/constants'
import { NODE_TYPES } from '../nodetypes'

type Props = {
  autoSaveHandler?: (any) => mixed,
  dropDownData: $ReadOnlyArray<*>,
  keyName: string,
  label?: string,
  mode: string,
  placeholder?: string,
  size?: string,
  selectedValue?: string,
  translations: Object,
  language: string,
}

const NodeField = React.forwardRef<Props, *>(
  (
    {
      autoSaveHandler,
      dropDownData,
      keyName,
      label = 'Label',
      mode,
      placeholder = 'Select Action',
      size = 'default',
      selectedValue = '',
      translations,
      language,
    }: Props,
    ref
  ) => {
    const [update, setUpdate] = React.useState(false)
    const [selectedOption, setSelectedOption] = React.useState(selectedValue)

    React.useEffect(() => {
      setSelectedOption(selectedValue)
    }, [selectedValue])

    React.useImperativeHandle(ref, () => ({
      getLatestValue() {
        return selectedOption
      },
      getName() {
        return keyName
      },
      getType() {
        return NODE_TYPES.node
      },
      resetValue() {
        setSelectedOption('')
      },
    }))

    const changeHandler = (value = '') => {
      setSelectedOption(value)
      if (!isTextSame(value, selectedOption)) {
        setUpdate(true)
      }
    }

    React.useEffect(() => {
      if (update && mode === 'Edit' && autoSaveHandler) {
        autoSaveHandler(NODE_TYPES.node)
        setUpdate(false)
      }
    }, [update])

    const renderOptions = () => {
      return dropDownData.map((option, index) => {
        if (!_.isEmpty(option) && !_.isEmpty(option.callFlowId)) {
          return (
            <Select.Option key={index} value={option.callFlowId}>
              <div
                style={{ display: 'flex', alignItems: 'center', color: 'grey' }}
              >
                <span style={{ marginRight: 15 }}>
                  <img
                    alt=""
                    style={{ height: 20, width: 20 }}
                    src={`${COMMON_IMG_URL}nodeicon/${
                      option.icon ? option.icon : ''
                    }.png`}
                  />
                </span>
                <div>{option.name}</div>
              </div>
            </Select.Option>
          )
        } else {
          return <div> {translations.no_options[language]} </div>
        }
      })
    }

    return (
      <React.Fragment>
        <div className="label-spacing"> {label} </div>
        <Select
          allowClear={selectedOption.length > 0}
          onChange={changeHandler}
          placeholder={placeholder}
          size={size}
          style={{ width: '100%' }}
          value={selectedOption}
        >
          {renderOptions()}
        </Select>
      </React.Fragment>
    )
  }
)

export default React.memo<Props>(NodeField)
