import {
  REFRESH_TOKEN,
  EXTRA_TOKEN,
  ID_TOKEN,
  TOKEN_TYPE,
  EXPIRE,
} from '../utils/constants'
import { getItem, removeItem, setItem } from '../utils/storage'
import { putService } from '../services/RequestService'
import { REFRESHURL } from '../utils/constants'

let refreshTokenTimeoutID = ''

export function setTokens(tokenObject) {
  // eslint-disable-next-line no-prototype-builtins
  if (tokenObject.hasOwnProperty('RefreshToken')) {
    setItem(REFRESH_TOKEN, tokenObject.RefreshToken)
  }

  setItem(EXTRA_TOKEN, tokenObject.ExtraToken)
  setItem(ID_TOKEN, tokenObject.IdToken)
  setItem(TOKEN_TYPE, tokenObject.TokenType)
  setItem(EXPIRE, tokenObject.Expire)
  setTimeoutForRefreshToken()
}

export function removeTokens() {
  removeItem(REFRESH_TOKEN)
  removeItem(EXTRA_TOKEN)
  removeItem(ID_TOKEN)
  removeItem(TOKEN_TYPE)
  removeItem(EXPIRE)
  removeItem('user')
  removeItem('mstoken')
  removeItem('IsClientPage')
}

export function setTimeoutForRefreshToken() {
  if (!(getItem(EXPIRE) && Number(getItem(EXPIRE)) * 1000 > Date.now())) {
    return
  }

  clearTimeout(refreshTokenTimeoutID)

  refreshTokenTimeoutID = setTimeout(() => {
    refreshTokens()
  }, Number(getItem(EXPIRE)) * 1000 - Date.now())
}

function refreshTokens() {
  const data = {
    RefreshToken: getItem(REFRESH_TOKEN),
  }

  putService(
    REFRESHURL,
    JSON.stringify(data),
    refreshTokenResultHandler,
    refreshTokenErrorHandler
  )
}

function refreshTokenResultHandler(tokenObject) {
  setTokens(tokenObject)
}

function refreshTokenErrorHandler(error) {
  // eslint-disable-next-line no-console
  console.log('Refresh Token - Request Failed', error)
}

export function isTokenActive() {
  if (getItem(EXPIRE) && Number(getItem(EXPIRE)) * 1000 > Date.now()) {
    return true
  } else {
    return false
  }
}

export function handleLogout() {
  removeTokens()
  window.location = `${window.location.origin}/#/login`
}
