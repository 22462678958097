/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { Button, message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import PttButton from '../CustomButton/PttButton'
import ConfirmPopup from '../Popup/confirmpopup'
import axios from 'axios'
import {
  ACTIONAPIURL,
  APIURL,
  API_MANAGER_KEY,
  FULL_ACCESS,
  ID_TOKEN,
  TOKEN_TYPE,
} from '../../utils/constants'
import { getItem } from '../../utils/storage'
import { Footer, Header } from 'antd/lib/layout/layout'
import ModalLayout, { Body } from '../Popup/popup'
import { Fragment } from 'react'
import Spinner from '../../Spinner/spinner'
import { setUpdatedState } from '../../actions/settingAction'
import AccessDropDown from '../CustomComponents/AccessDropDown'
import UserTypeDropDown from '../CustomComponents/UserTypeDropDown'
import {
  getDefaultPermissions,
  getUserPermissions,
} from '../../utils/SettingsUtils'

// accountId
// userId

const InviteUserEdit = ({
  row = {},
  refetchUsersAndClosePanel,
  refetchUsers,
}) => {
  const dispatch = useDispatch()
  const {
    language,
    translations,
    updatedState,
    accessUsersPermission,
  } = useSelector((state) => {
    return {
      language: state.sampleReducer.language,
      translations: state.sampleReducer.translations,
      updatedState: state.settingReducer.updatedState,
      accessUsersPermission: state.permissionReducer.accessUsersPermission,
    }
  })

  const api = axios.create({
    baseURL: APIURL,
    headers: {
      Authorization: `${getItem(TOKEN_TYPE)} ${getItem(ID_TOKEN)}`,
      'Content-Type': 'application/json',
      'x-api-key': API_MANAGER_KEY,
    },
  })

  const actionApi = axios.create({
    baseURL: ACTIONAPIURL,
    headers: {
      Authorization: `${getItem(TOKEN_TYPE)} ${getItem(ID_TOKEN)}`,
      'Content-Type': 'application/json',
      'x-api-key': API_MANAGER_KEY,
    },
  })

  const [popup, setPopup] = useState(false)
  const [alert, setAlert] = useState(false)
  const [mode, setMode] = useState('')
  const [loading, setLoading] = useState(false)

  const [type, setType] = useState('')
  const [MICROSOFT_TEAMS, SET_MICROSOFT_TEAMS] = useState('')
  const [PHONE_NUMBERS, SET_PHONE_NUMBERS] = useState('')
  const [STATS, SET_STATS] = useState('')
  const [ADVANCED, SET_ADVANCED] = useState('')
  const [BILLING, SET_BILLING] = useState('')
  const [COMPANY_SETTINGS, SET_COMPANY_SETTINGS] = useState('')
  const [ACCESS_USERS, SET_ACCESS_USERS] = useState('')
  const [permissionId, setPermissionId] = useState('')

  const getDetails = () => {
    api
      .get(`user_permissions?query=custom_id=${row.userId}`)
      .then((res) => {
        if (res.data.result && res.data.response.items.length) {
          const item = res.data.response.items[0]
          const id = item?.id ? item.id : ''
          const permissions = item?.permissions
            ? getUserPermissions(item.permissions)
            : getDefaultPermissions()

          SET_MICROSOFT_TEAMS(permissions['MICROSOFT_TEAMS'])
          SET_PHONE_NUMBERS(permissions['PHONE_NUMBERS'])
          SET_STATS(permissions['STATS'])
          SET_ADVANCED(permissions['ADVANCED'])
          SET_BILLING(permissions['BILLING'])
          SET_COMPANY_SETTINGS(permissions['COMPANY_SETTINGS'])
          SET_ACCESS_USERS(permissions['ACCESS_USERS'])
          setPermissionId(id)
          setLoading(false)
        } else {
          setLoading(false)
          SET_MICROSOFT_TEAMS(FULL_ACCESS)
          SET_PHONE_NUMBERS(FULL_ACCESS)
          SET_STATS(FULL_ACCESS)
          SET_ADVANCED(FULL_ACCESS)
          SET_BILLING(FULL_ACCESS)
          SET_COMPANY_SETTINGS(FULL_ACCESS)
          SET_ACCESS_USERS(FULL_ACCESS)
        }
      })
      .catch(() => {
        setLoading(false)
        SET_MICROSOFT_TEAMS(FULL_ACCESS)
        SET_PHONE_NUMBERS(FULL_ACCESS)
        SET_STATS(FULL_ACCESS)
        SET_ADVANCED(FULL_ACCESS)
        SET_BILLING(FULL_ACCESS)
        SET_COMPANY_SETTINGS(FULL_ACCESS)
        SET_ACCESS_USERS(FULL_ACCESS)
      })
  }

  useEffect(() => {
    if (row.type) {
      setLoading(true)
      setType(row.type === 'user' ? 'Regular' : 'Administrator')
      getDetails()
    }
  }, [row])

  useEffect(() => {
    if (updatedState !== '') {
      setType(updatedState)
    }
  }, [updatedState])

  const closePopup = () => {
    setPopup(false)
  }

  const onPopupConfirmed = () => {
    setPopup(false)
    setLoading(true)
    deleteUser()
  }

  /* const deleteAccount = () => {
    api
      .delete(`account/${row.accountId}`)
      .then((res) => {
        if (res.data.result) {
          refetchUsersAndClosePanel()
          setLoading(false)
          message.success(translations.delete_invite_user_success[language])
        } else {
          setLoading(false)
          message.error(translations.delete_invite_user_account_error[language])
        }
      })
      .catch(() => {
        setLoading(false)
        message.error(translations.delete_invite_user_account_error[language])
      })
  } */

  const deleteUser = () => {
    api
      .delete(`user/${row.userId}?sync=yes`)
      .then((res) => {
        if (res.data.result) {
          // deleteAccount()
          refetchUsersAndClosePanel()
          setLoading(false)
          message.success(translations.delete_invite_user_success[language])
        } else if (res.data?.error === 'Access denied entity:user') {
          setMode('delete')
          setAlert(true)
          setLoading(false)
        } else {
          setLoading(false)
          message.error(translations.delete_invite_user_error[language])
        }
      })
      .catch(() => {
        setLoading(false)
        message.error(translations.delete_invite_user_error[language])
      })
  }

  const renderConfirmPopup = () => {
    return (
      <ConfirmPopup
        onCancel={closePopup}
        onOkay={onPopupConfirmed}
        visiblity={popup}
        confirmText={`${translations.delete_user[language]}?`}
        cancelText={translations.no[language]}
        okayText={translations.yes[language]}
        type="danger"
        okBtnClassName="on-button-style"
        loading={false}
        disabled={false}
      >
        <div>{translations.delete_user_info[language]}</div>
      </ConfirmPopup>
    )
  }

  const errorHandler = () => {
    setLoading(false)
    message.error(translations.access_settings_update_error[language])
  }

  const updatePermissions = () => {
    api
      .patch(`user_permissions/${permissionId}?sync=yes`, {
        permissions: [
          { MICROSOFT_TEAMS: MICROSOFT_TEAMS },
          { PHONE_NUMBERS: PHONE_NUMBERS },
          { STATS: STATS },
          { ADVANCED: ADVANCED },
          { BILLING: BILLING },
          { COMPANY_SETTINGS: COMPANY_SETTINGS },
          { ACCESS_USERS: ACCESS_USERS },
        ],
      })
      .then((res) => {
        setLoading(false)
        refetchUsers()
        if (res.data.result) {
          message.success(translations.access_settings_update_success[language])
        } else {
          message.error(translations.access_settings_update_error[language])
        }
      })
      .catch(() => {
        setLoading(false)
        refetchUsers()
        message.error(translations.access_settings_update_error[language])
      })
  }

  const createPermissions = () => {
    actionApi
      .post('account', {
        action: 'current',
        actiondata: {},
      })
      .then((res) => {
        if (
          res.data.result &&
          res.data.response.items.length &&
          res.data.response.items[0].data
        ) {
          const account_id = res.data.response.items[0].data.account_id
          const userId = row.userId

          api
            .put('user_permissions', {
              account_id: account_id,
              custom_id: userId,
              permissions: [
                { MICROSOFT_TEAMS: MICROSOFT_TEAMS },
                { PHONE_NUMBERS: PHONE_NUMBERS },
                { STATS: STATS },
                { ADVANCED: ADVANCED },
                { BILLING: BILLING },
                { COMPANY_SETTINGS: COMPANY_SETTINGS },
                { ACCESS_USERS: ACCESS_USERS },
              ],
            })
            .then((res) => {
              if (res.data.result) {
                setLoading(false)
                message.success(
                  translations.access_settings_update_success[language]
                )
              } else {
                setLoading(false)
                message.error(
                  translations.access_settings_update_error[language]
                )
              }
            })
            .catch(() => {
              setLoading(false)
              message.error(translations.access_settings_update_error[language])
            })
        } else {
          setLoading(false)
          message.error(translations.getting_acc_details_error[language])
        }
      })
      .catch(() => {
        setLoading(false)
        message.error(translations.getting_acc_details_error[language])
      })
  }

  const onSave = () => {
    dispatch(setUpdatedState(type))
    api
      .patch(`/user/${row.userId}?sync=yes`, {
        type: type === 'Regular' ? 'user' : 'admin',
        account: row.accountId,
        name: row.username,
      })
      .then((res) => {
        if (res.data.result) {
          if (permissionId) {
            updatePermissions()
          } else {
            createPermissions()
          }
        } else if (res.data?.error === 'Access denied entity:user') {
          setMode('edit')
          setAlert(true)
          setLoading(false)
        } else {
          errorHandler()
        }
      })
      .catch(() => {
        errorHandler()
      })
  }

  const renderAlert = () => {
    const info =
      mode === 'delete' ? 'delete_invite_user_alert' : 'edit_invite_user_alert'

    return (
      <ModalLayout
        closable={true}
        onCancel={() => setAlert(false)}
        visible={alert}
      >
        <Header>{translations.alert[language]}</Header>
        <Body>{translations[info][language]}</Body>
        <Footer>
          <PttButton
            type="primary"
            onClick={() => {
              if (row.type) {
                setType(row.type === 'user' ? 'Regular' : 'Administrator')
              }
              setMode('')
              setAlert(false)
            }}
          >
            {translations.okay[language]}
          </PttButton>
        </Footer>
      </ModalLayout>
    )
  }

  const loader = () => {
    return (
      <div className="spin-style">
        <Spinner spinning={true} />
      </div>
    )
  }

  const renderContent = () => {
    if (loading) {
      return loader()
    } else {
      return (
        <Fragment>
          <UserTypeDropDown
            changeCallback={(value) => setType(value)}
            dropDownStyle={{ width: '97%' }}
            headerStyle={{ marginBottom: 5, fontSize: 17 }}
            heading={translations.type[language]}
            value={type}
          />
          <div style={{ marginTop: 5, fontSize: 17 }}>
            {translations.permissions[language]}
          </div>
          <AccessDropDown
            changeCallback={(value) => SET_MICROSOFT_TEAMS(value)}
            dropDownStyle={{ width: '97%' }}
            heading={translations.microsoft_teams[language]}
            value={MICROSOFT_TEAMS}
          />
          <AccessDropDown
            changeCallback={(value) => SET_PHONE_NUMBERS(value)}
            dropDownStyle={{ width: '97%' }}
            heading={translations.phone_number_label[language]}
            value={PHONE_NUMBERS}
          />
          <AccessDropDown
            changeCallback={(value) => SET_STATS(value)}
            dropDownStyle={{ width: '97%' }}
            heading={translations.stats[language]}
            value={STATS}
          />
          <AccessDropDown
            changeCallback={(value) => SET_ADVANCED(value)}
            dropDownStyle={{ width: '97%' }}
            heading={translations.advanced[language]}
            value={ADVANCED}
          />
          <AccessDropDown
            changeCallback={(value) => SET_BILLING(value)}
            dropDownStyle={{ width: '97%' }}
            heading={translations.billing[language]}
            value={BILLING}
          />
          <AccessDropDown
            changeCallback={(value) => SET_COMPANY_SETTINGS(value)}
            dropDownStyle={{ width: '97%' }}
            heading={translations.company_settings[language]}
            value={COMPANY_SETTINGS}
          />
          <AccessDropDown
            changeCallback={(value) => SET_ACCESS_USERS(value)}
            dropDownStyle={{ width: '97%' }}
            heading={translations.settings_user_tab[language]}
            value={ACCESS_USERS}
          />
        </Fragment>
      )
    }
  }

  const onSaveClick = () => {
    setLoading(true)
    onSave()
  }

  const renderActions = () => {
    if (accessUsersPermission === FULL_ACCESS) {
      return (
        <div style={{ height: 80 }}>
          <PttButton
            block
            type="primary"
            onClick={onSaveClick}
            disabled={loading}
            style={{ marginBottom: 10 }}
          >
            {translations.save[language]}
          </PttButton>
          <Button
            block
            danger
            onClick={() => setPopup(true)}
            disabled={loading}
          >
            {translations.delete_user[language]}
          </Button>
        </div>
      )
    }
  }

  return (
    <div style={{ padding: 5, height: '100%', paddingLeft: 15 }}>
      <div
        style={{
          height:
            accessUsersPermission === FULL_ACCESS
              ? 'calc(100% - 85px)'
              : 'calc(100% - 0px)',
          overflow: 'auto',
          marginBottom: 10,
        }}
      >
        {renderContent()}
      </div>
      {renderActions()}
      {renderConfirmPopup()}
      {renderAlert()}
    </div>
  )
}

export default InviteUserEdit
