const CallflowReducer = (
  state = {
    callflowPhones: [],
    unusedPhones: [],
  },
  action
) => {
  switch (action.type) {
    case 'setCallflowPhones':
      return { ...state, callflowPhones: action.callflowPhones }

    case 'setUnusedPhones':
      return { ...state, unusedPhones: action.unusedPhones }

    default:
      return state
  }
}

export default CallflowReducer
