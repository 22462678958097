const countryDetails = [
  { code: 'en-US', exactCode: 'US', label: 'English (United States)' },
  { code: 'en-IN', exactCode: 'IN', label: 'English (India)' },
  { code: 'en-GB', exactCode: 'GB', label: 'English (United Kingdom)' },
  { code: 'en-AU', exactCode: 'AU', label: 'English (Australia)' },
  { code: 'en-GB-WLS', exactCode: 'GB-WLS', label: 'English (Wales)' },
  { code: 'es-ES', exactCode: 'ES', label: 'Spanish (Spain)' },
  { code: 'es-MX', exactCode: 'MX', label: 'Spanish (Mexico)' },
  { code: 'es-US', exactCode: 'US', label: 'Spanish (United States)' },
  { code: 'pt-PT', exactCode: 'PT', label: 'Portuguese (Portugal)' },
  { code: 'pt-BR', exactCode: 'BR', label: 'Portuguese (Brazil)' },
  { code: 'fr-FR', exactCode: 'FR', label: 'French (France)' },
  { code: 'fr-CA', exactCode: 'CA', label: 'French (Canada)' },
  { code: 'de-DE', exactCode: 'DE', label: 'German' },
  { code: 'it-IT', exactCode: 'IT', label: 'Italian' },
  { code: 'nl-NL', exactCode: 'NL', label: 'Dutch' },
  { code: 'ru-RU', exactCode: 'RU', label: 'Russian' },
  { code: 'pl-PL', exactCode: 'PL', label: 'Polish' },
  { code: 'arb', exactCode: 'AE', label: 'Arabic' },
  { code: 'cmn-CN', exactCode: 'CN', label: 'Chinese Mandarin' },
  { code: 'cy-GB', exactCode: 'GB', label: 'Welsh' },
  { code: 'is-IS', exactCode: 'IS', label: 'Icelandic' },
  { code: 'ro-RO', exactCode: 'RO', label: 'Romanian' },
  { code: 'sv-SE', exactCode: 'SE', label: 'Swedish' },
  { code: 'tr-TR', exactCode: 'TR', label: 'Turkish' },
  { code: 'da-DK', exactCode: 'DK', label: 'Danish' },
  { code: 'nb-NO', exactCode: 'NO', label: 'Norwegian' },
  { code: 'ja-JP', exactCode: 'JP', label: 'Japanese' },
  { code: 'ko-KR', exactCode: 'KR', label: 'Korean' },
]

export const getTtsLanguages = (availableVoices) => {
  const languages = availableVoices.map((voice) => voice.LanguageCode)
  const uniqueLanguages = [...new Set(languages)]

  return uniqueLanguages.map((lang) => {
    return countryDetails.find((detail) => detail.code === lang)
  })
}
