import React from 'react'
import { useSelector } from 'react-redux'
import Clients from '../components/Clients/Clients'
import PttLayout from '../components/Home/PttHome/PttLayout'
import { getTranslatedText } from '../utils/translations'

function ClientsPage() {
  const { language, translations } = useSelector(({ sampleReducer }) => ({
    language: sampleReducer.language || 'en',
    translations: sampleReducer.translations || {},
  }))

  return (
    <PttLayout
      currentkey="10"
      title={getTranslatedText(translations, language, 'clients_title')}
    >
      <Clients />
    </PttLayout>
  )
}

export default ClientsPage
