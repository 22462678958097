// @flow

import React from 'react'
import { CheckCircleOutlined } from '@ant-design/icons'
import { PttButton } from '../../CustomButton/custombutton'
import { useSelector } from 'react-redux'
import './allset.css'

type Props = {
  loading?: boolean,
  onClickHandler: () => mixed,
  infoOne?: string,
  infoTwo?: string,
}

const AllSetPage = ({
  loading,
  onClickHandler,
  infoOne = null,
  infoTwo = null,
}: Props) => {
  const { language, translations } = useSelector(({ sampleReducer }) => ({
    language: sampleReducer.language || 'en',
    translations: sampleReducer.translations || {},
  }))

  return (
    <div className="full-height align-center text-align-center">
      <div className="full-height full-width">
        <div>
          <div className="allset-done-text">{translations.done[language]}</div>
          <div className="allset-info-text">
            {infoOne === null ? translations.allset_info_1[language] : infoOne}
          </div>
          <div className="allset-info-text">
            {infoTwo === null ? translations.allset_info_2[language] : infoTwo}
          </div>
        </div>
        <div className="allset-image-cont align-center">
          <div>
            <div>
              <CheckCircleOutlined className="allset-icon" />
            </div>
            <div className="allset-button-cont">
              <PttButton
                disabled={loading}
                loading={loading}
                onClick={() => onClickHandler()}
                type="secondary"
              >
                {translations.got_it[language]}
              </PttButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AllSetPage
