// @flow

import React, { useState, useMemo, useEffect } from 'react'
import {
  useElements,
  useStripe,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js'
import { connect } from 'react-redux'
import useCustomFetch from '../../Hooks/UseCustomFetch'
import { CUSTOM_ACTION_URL } from '../../utils/constants'
import { message, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

type Props = {
  userStripeData: Object,
  getPaymentList: (any) => mixed,
  handlePopupClose: (any) => void,
  popupWillClose: Boolean,
  translations: Object,
  language: string,
}

const CheckoutForm = (props: Props) => {
  const {
    userStripeData,
    getPaymentList,
    handlePopupClose,
    popupWillClose,
    translations,
    language,
  } = props
  const [customerPaymentData, setCustomerPaymentData] = useState({ action: '' })
  const [loader, setLoader] = useState(false)
  const [error, setError] = useState('')
  const [name, setName] = useState('')
  const [nameRequiredError, setNameRequiredError] = useState(false)
  const stripe = useStripe()
  const elements = useElements()

  /* attach customer to payment method */
  const [attachPaymentToCustomerApi] = useCustomFetch(
    `${CUSTOM_ACTION_URL}/payment`,
    'PAYMENT',
    false,
    JSON.stringify(customerPaymentData)
  )

  useEffect(() => {
    if (popupWillClose) {
      const cardElement = elements.getElement(CardNumberElement)
      const cardExpiryElement = elements.getElement(CardExpiryElement)
      const cardCvcElement = elements.getElement(CardCvcElement)

      cardElement.clear()
      cardExpiryElement.clear()
      cardCvcElement.clear()
      setError('')
      setName('')
      setNameRequiredError(false)
      setLoader(false)
    }
  }, [popupWillClose])

  useEffect(() => {
    if (customerPaymentData.action !== '') {
      attachPaymentToCustomerApi.setRefetch(true)
    }
  }, [customerPaymentData])

  useEffect(() => {
    if (
      attachPaymentToCustomerApi.response &&
      attachPaymentToCustomerApi.response.status
    ) {
      message.success(translations.payment_method_added_success[language])
      setLoader(false)
      getPaymentList()
      handlePopupClose(true)
    } else if (
      attachPaymentToCustomerApi.response &&
      !attachPaymentToCustomerApi.response.status &&
      attachPaymentToCustomerApi.response.error
    ) {
      message.error(
        (attachPaymentToCustomerApi.response.error.raw &&
        attachPaymentToCustomerApi.response.error.raw.message
          ? attachPaymentToCustomerApi.response.error.raw.message
          : attachPaymentToCustomerApi.response.error) ||
          translations.try_with_another_card_failed[language]
      )
      setLoader(false)
    }
  }, [attachPaymentToCustomerApi.response])

  const useOptions = () => {
    // const fontSize = useResponsiveFontSize()
    const options = useMemo(
      () => ({
        style: {
          base: {
            fontSize: 'inherit',
            color: 'rgba(0, 0, 0, 0.65)',
            letterSpacing: '0.025em',
            fontFamily: 'inherit',
            '::placeholder': {
              color: '#bfbfbf',
            },
          },
          invalid: {
            color: '#9e2146',
          },
        },
      })
      // [fontSize]
    )

    return options
  }

  // Handle real-time validation errors from the card Element.
  const handleChange = (event) => {
    if (event.error) {
      if (name === '') {
        setError(translations.name_field_required[language])
      } else {
        setError(event.error.message)
      }
      setLoader(false)
    } else {
      setError('')
    }
  }

  const handleNameChange = (event) => {
    if (event.target.value !== '') {
      setError('')
      setNameRequiredError(false)
    }
    setName(event.target.value)
  }

  // Handle form submission.
  const handleSubmit = async (event) => {
    event.preventDefault()

    if (event.currentTarget[0].value === '') {
      setError(translations.name_field_required[language])
      setNameRequiredError(true)
    } else {
      setNameRequiredError(false)

      const card = elements.getElement(CardNumberElement)

      setLoader(true)
      const payload = await stripe.createPaymentMethod({
        type: 'card',
        card: card,
        billing_details: {
          name: event.currentTarget[0].value,
        },
      })

      // eslint-disable-next-line no-console
      console.log('[PaymentMethod]', payload)

      if (payload.paymentMethod && payload.paymentMethod.id) {
        setLoader(true)

        const data = {
          action: 'ATTACH_CUSTOMER_TO_PAYMENT_METHOD',
          data: {
            paymentMethodId: payload.paymentMethod.id,
            customerId: userStripeData.stripe_id,
          },
        }

        setCustomerPaymentData(data)
      } else if (payload.error && payload.error.message) {
        setError(payload.error.message)
        setLoader(false)
      }
    }
  }

  const getFooter = () => {
    return (
      <div className="ant-modal-footer">
        <button
          type="submit"
          form="add-payment-form"
          disabled={!stripe || loader}
          className="ant-btn primary-button ant-btn-primary"
        >
          {loader && (
            <Spin
              indicator={
                <LoadingOutlined
                  style={{ fontSize: 14, color: '#bfbfbf', marginRight: '6px' }}
                />
              }
            />
          )}
          {translations.add[language]}
        </button>
      </div>
    )
  }

  const options = useOptions()

  return (
    <div>
      {error && <div className="err-msg">{error}</div>}
      <form
        onSubmit={handleSubmit}
        id="add-payment-form"
        style={{ padding: '15px 24px 24px 24px' }}
      >
        <label>
          {translations.name_on_card[language]}
          <input
            className="StripeElement name-on-card"
            onChange={handleNameChange}
            placeholder={translations.john_doe[language]}
            style={{ borderColor: `${nameRequiredError ? '#fa755a' : ''}` }}
          ></input>
        </label>
        <label>
          {translations.card_number[language]}
          <CardNumberElement options={options} onChange={handleChange} />
        </label>
        <div style={{ display: 'inline-flex', width: '100%' }}>
          <label style={{ width: '47%', marginRight: '6%' }}>
            {translations.expiration_date[language]}
            <CardExpiryElement options={options} onChange={handleChange} />
          </label>
          <label style={{ width: '47%' }}>
            {translations.cvc[language]}
            <CardCvcElement options={options} onChange={handleChange} />
          </label>
        </div>
      </form>
      {getFooter()}
    </div>
  )
}

const mapsStateToProps = (state) => {
  return {
    userStripeData: state.sampleReducer.userStripeData || {},
    translations: state.sampleReducer.translations || {},
    language: state.sampleReducer.language,
  }
}

export default connect(mapsStateToProps, null)(CheckoutForm)
