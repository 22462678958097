/* eslint-disable no-negated-condition */
import { Input, message } from 'antd'
import { MailOutlined } from '@ant-design/icons'
import React, { useState } from 'react'
import { Fragment } from 'react'
import { useSelector } from 'react-redux'
import Spinner from '../../Spinner/spinner'
import { StartupButton } from '../CustomButton/custombutton'
import StartupAddons from '../StartUps/StartupAddons'
import StartupImagePanel from '../StartUps/StartupImagePanel'
import {
  SIGNUP_APIKEY,
  SIGNUP_API,
  COMMON_IMG_URL,
} from '../../utils/constants'
import { getTranslatedText } from '../../utils/translations'

// eslint-disable-next-line no-useless-escape
const validator = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
const logo = `${COMMON_IMG_URL}branding/PTT/logo1.png`

const Recoverpassword = () => {
  const { appUrlBrand, language, translations } = useSelector((state) => {
    return {
      language: state.sampleReducer.language,
      translations: state.sampleReducer.translations,
      appUrlBrand: state.sampleReducer.appUrlBrand,
    }
  })

  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState(false)

  const onEmailChange = (event) => {
    setEmail(event.target.value)
  }

  const handleError = () => {
    setLoading(false)
    message.error(getTranslatedText(translations, language, 'user_not_exist'))
  }

  const recoverPassword = () => {
    const mail = email.trim()
    const mdr = appUrlBrand !== 'Plug2Teams'

    if (mail.length) {
      fetch(`${SIGNUP_API}/recoverpassword/${mail}?myDirectRouting=${mdr}`, {
        headers: {
          'x-api-key': SIGNUP_APIKEY,
        },
        method: 'GET',
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status) {
            setEmail('')
            setLoading(false)
            setResult(true)
          } else {
            handleError()
          }
        })
        .catch(() => {
          handleError()
        })
    }
  }

  const onRecoverClick = () => {
    if (!email.length) {
      return message.warning(translations.pls_enter_email[language])
    } else if (!validator.test(email)) {
      return message.warning(translations.pls_enter_valid_email[language])
    } else {
      setLoading(true)
      recoverPassword()
    }
  }

  const renderInputs = () => {
    return (
      <Fragment>
        <Input
          className="start-up-input"
          onChange={onEmailChange}
          placeholder={translations.email[language]}
          onPressEnter={onRecoverClick}
          prefix={<MailOutlined className="startup-prefix-style" />}
          size="large"
          value={email}
        />
        <StartupButton
          block
          disabled={loading}
          loading={loading}
          onClick={onRecoverClick}
        >
          {translations.recover_password[language]}
        </StartupButton>
      </Fragment>
    )
  }

  const renderlogo = () => {
    if (appUrlBrand === 'Plug2Teams') {
      return (
        <div className="align-center">
          <img alt="pttlogo" className="signup-colored-logo" src={logo} />
        </div>
      )
    } else {
      return <Fragment />
    }
  }

  const renderAddons = () => {
    if (appUrlBrand === 'Plug2Teams') {
      return <StartupAddons option="Link" linkTo="login" />
    } else {
      return <Fragment />
    }
  }

  const startupInputs = () => {
    return (
      <Fragment>
        {renderlogo()}
        <div
          className="align-center startup-title"
          style={{ whiteSpace: 'nowrap' }}
        >
          {translations.forgot_your_password[language]}
        </div>
        <div className="startup-result-container">
          <div className="startup-info-text">
            {translations.pls_enter_email[language]}
          </div>
          <div className="startup-info-text">
            {translations.will_send_link_to_redifine[language]}
          </div>
        </div>
        {renderInputs()}
        {renderAddons()}
      </Fragment>
    )
  }

  const startupInfo = () => {
    return (
      <div className="startup-result-container">
        <div className="startup-check-mail">
          {translations.check_email[language]}
        </div>
        <div className="startup-define-password">
          {translations.link_sent_redifine[language]}
        </div>
      </div>
    )
  }

  return (
    <div className="startup-container">
      <div className="startup-image-section">
        <StartupImagePanel text={translations.startup_image_text[language]} />
      </div>
      <div className="startup-field-section">
        <div className="align-center full-height">
          <Spinner color="#00569D" spinning={loading}>
            <div className="align-center startup-field-container">
              <div className="startup-field-inputs">
                {result ? startupInfo() : startupInputs()}
              </div>
            </div>
          </Spinner>
        </div>
      </div>
    </div>
  )
}

export default Recoverpassword
