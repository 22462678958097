import { Alert, Empty, Select, Spin, Table } from 'antd'
import axios from 'axios'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import useCustomFetch from '../../../Hooks/UseCustomFetch'
import UseMatchMedia from '../../../Hooks/UseMatchMedia'
import {
  API_MANAGER_KEY,
  CUSTOM_ACTION_URL,
  ID_TOKEN,
  TOKEN_TYPE,
} from '../../../utils/constants'
import {
  convertToCent,
  convertToDollar,
  getNewArray,
} from '../../../utils/helpers'
import { getItem } from '../../../utils/storage'
import PttButton from '../../CustomButton/PttButton'
import CountrySelect from '../../CustomComponents/CountrySelect'
import ModalLayout, { Body, Footer, Header } from '../../Popup/popup'
import { LoadingOutlined } from '@ant-design/icons'
import { cloneDeep } from 'lodash'

let cityPaginationTotal = 0
let cityPaginationProcess = false
let cityPaginationNumber = 2

const MsTeamsAddPhoneNumber = ({
  cancelCallback,
  successCallback,
  visiblity,
  existingIds = [],
}) => {
  const isSmall = UseMatchMedia('(max-width: 1088px)')
  const cityPagination = axios.create({
    baseURL: `${CUSTOM_ACTION_URL}`,
    headers: {
      Authorization: `${getItem(TOKEN_TYPE)} ${getItem(ID_TOKEN)}`,
      'Content-Type': 'application/json',
      'x-api-key': API_MANAGER_KEY,
    },
  })
  const { language, translations } = useSelector(({ sampleReducer }) => ({
    language: sampleReducer.language || 'en',
    translations: sampleReducer.translations || {},
  }))

  const [cityPaginationLoad, setCityPaginationLoad] = useState(false)
  const [areaList, setAreaList] = useState([])
  const [countryList, setCountryList] = useState([])
  const [cityLoading, setCityLoading] = useState(false)
  const [countryLoading, setCountryLoading] = useState(true)
  const [selectedCountryId, setSelectedCountryId] = useState('')
  const [selectedCityId, setSelectedCityId] = useState('')
  const [selectedCountryName, setSelectedCountryName] = useState('')
  const [selectedCountryCode, setSelectedCountryCode] = useState('')
  const [selectedCity, setSelectedCity] = useState('')
  const [tableLoading, setTableLoading] = useState(false)
  const [unAssignedPhoneList, setunAssignedPhoneList] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedPhonenumbers, setSelectedPhonenumbers] = useState([])
  const [data, setData] = useState({})
  const [type,setType] = useState('')

  const [countryListApi] = useCustomFetch(
    `${CUSTOM_ACTION_URL}/provider`,
    'POST',
    true,
    JSON.stringify({
      didww_step: 'GET_COUNTRIES',
    })
  )

  const [cityListApi] = useCustomFetch(
    `${CUSTOM_ACTION_URL}/provider`,
    'POST',
    false,
    JSON.stringify({
      didww_step: 'GET_CITIES',
      countryId: selectedCountryId,
      countryCode: selectedCountryCode,
    })
  )

  const [unAssignedPhoneNoListApi] = useCustomFetch(
    `${CUSTOM_ACTION_URL}/provider`,
    'POST',
    false,
    JSON.stringify(data)
  )

  useEffect(() => {
    const { response, status } = countryListApi.response
    const responseData = response?.Payload ? JSON.parse(response.Payload) : {}

    if (status && responseData?.data?.data) {
      const countries = responseData.data.data.map((country) => {
        return {
          id: country.id,
          code: country.attributes.iso,
          name: country.attributes.name,
        }
      })

      setCountryList(countries)
      setCountryLoading(false)
    }
  }, [countryListApi.response])

  useEffect(() => {
    if (selectedCountryCode) {
      setCityLoading(true)
      setSelectedCityId('')
      cityListApi.setRefetch(true)
    }
  }, [selectedCountryId])

  useEffect(() => {
    if (type === 'cities') {
      const data = {
        didww_step: 'AVAILABLE_DIDS',
        action: 'getunassigned',
        countrycode: selectedCountryCode,
        cityId: selectedCityId,
        area: selectedCity,
      }

    setData(data)
    } else if (type === 'regions') {
      const data = {
        didww_step: 'AVAILABLE_DIDS',
        action: 'getunassigned',
        countrycode: selectedCountryCode,
        regionId: selectedCityId,
        area: selectedCity,
      }

      setData(data)
    }
  },[type,selectedCountryCode,selectedCityId,selectedCity])

  useEffect(() => {
    const { response, status } = cityListApi.response
    const responseData = response?.Payload ? JSON.parse(response.Payload) : {}

    if (status && responseData?.data?.data) {
      const type = responseData?.data?.data[0]?.type

      setType(type)
      cityPaginationTotal = responseData?.data?.meta?.total_records
      setCityLoading(false)
      setAreaList(responseData.data.data)
    } else {
      setCityLoading(false)
      setAreaList([])
    }
  }, [cityListApi.response])

  const getMetaData = () => {
    const phones = unAssignedPhoneList

    if (phones?.meta) {
      if (phones.included?.length && phones.included.length > 1) {
        const skuId = phones.included[1].id
        const monthly_amount = convertToCent(
          Number(phones.included[1].attributes?.monthly_price)
        )

        return phones?.data.map((data) => {
          if (data.id) {
            return {
              didId: data.id,
              skuId: skuId,
              id: data.attributes?.number,
              name: '',
              data: {
                geo: {
                  area: selectedCity,
                  country: selectedCountryName,
                  countrycode: selectedCountryCode,
                },
                price: { usd: { monthly_amount } },
              },
            }
          }
        })
      }
    }

    return []
  }

  const onAddClick = () => {
    let data = []

    if (Array.isArray(unAssignedPhoneList)) {
      data = unAssignedPhoneList
    } else {
      data = getMetaData()
    }

    successCallback(data.filter((row) => selectedRowKeys.includes(row.id)))
  }

  const onCancel = () => {
    cancelCallback()
  }

  const handleCountryChange = (countryCode, countryKey) => {
    setSelectedCountryId(countryKey.id)
    setSelectedCountryCode(countryCode)
    setSelectedCountryName(countryKey.key)
    setSelectedCity('')
    setSelectedCityId('')
    setSelectedRowKeys([])
    setunAssignedPhoneList([])
    setCityPaginationLoad(false)
    cityPaginationTotal = 0
    cityPaginationProcess = false
    cityPaginationNumber = 2
  }

  const handleCityChange = (cityId, city) => {
    setSelectedCity(city.children)
    setSelectedCityId(cityId)
  }

  useEffect(() => {
    if (selectedCity.length) {
      setTableLoading(true)
      unAssignedPhoneNoListApi.setRefetch(true)
    }
  }, [selectedCity])

  const getListFromItems = (response) => {
    const data = response?.response?.items
      ? response.response.items[0].data
      : {}

    if (data) {
      return data?.response?.items ? data.response.items : []
    } else {
      return []
    }
  }

  const getListFromPayload = (response) => {
    const responseData = JSON.parse(response.Payload)

    if (responseData?.data?.data) {
      return responseData.data.meta ? responseData.data : responseData.data.data
    } else {
      return []
    }
  }

  useEffect(() => {
    const { response, status } = unAssignedPhoneNoListApi.response

    if (status && response) {
      const didww = response?.Payload ? true : false
      const list = didww
        ? getListFromPayload(response)
        : getListFromItems(response)

      setunAssignedPhoneList(list)
      setTableLoading(false)
    }
  }, [unAssignedPhoneNoListApi.response])

  const handleCityPaginationError = (error) => {
    setCityPaginationLoad(false)
    cityPaginationProcess = false
    // eslint-disable-next-line no-console
    console.log(error)
  }

  const handleCityPaginationSuccess = (res) => {
    const { response, status } = res.data
    const responseData = response?.Payload ? JSON.parse(response.Payload) : {}

    setCityPaginationLoad(false)
    cityPaginationProcess = false
    if (status && responseData?.data?.data) {
      const existingAreas = cloneDeep(areaList)

      cityPaginationNumber = cityPaginationNumber + 1
      setAreaList(existingAreas.concat(responseData.data.data))
    }
  }

  const onCityPagination = (event) => {
    const { target } = event

    if (!cityPaginationProcess) {
      const isLastElement =
        target.scrollTop + target.offsetHeight === target.scrollHeight

      if (isLastElement && areaList.length < cityPaginationTotal) {
        setCityPaginationLoad(true)
        cityPaginationProcess = true
        const body = {
          didww_step: 'GET_CITIES',
          countryId: selectedCountryId,
          countryCode: selectedCountryCode,
          pageNumber: cityPaginationNumber,
        }

        cityPagination
          .post('provider', body)
          .then(handleCityPaginationSuccess)
          .catch(handleCityPaginationError)
      }
    }
  }

  const renderCities = () => {
    return (
      <React.Fragment>
        {areaList.map((area) => (
          <Select.Option key={(area?.attributes?.name, area?.id)}>
            {area?.attributes?.name}
          </Select.Option>
        ))}
        {cityPaginationLoad && (
          <div style={{ height: 50 }}>
            <Spin
              indicator={
                <LoadingOutlined
                  style={{ fontSize: 24, color: 'var(--userPrimaryColor)' }}
                  spin={true}
                />
              }
              spinning={true}
            />
          </div>
        )}
      </React.Fragment>
    )
  }

  const renderDropDowns = () => {
    return (
      <div style={{ marginTop: 15, display: 'flex' }}>
        <div style={{ width: '50%' }}>
          <div style={{ marginBottom: 5 }}>
            {translations.country[language]}
          </div>
          <CountrySelect
            dataProvider={countryList}
            placeholder={translations.select_country[language]}
            selectProps={{
              disabled: countryLoading,
              loading: countryLoading,
              onChange: handleCountryChange,
              style: { width: '21vw' },
              value: selectedCountryCode,
              showSearch: true,
              filterOption: (input, option) => {
                return (
                  option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                )
              },
            }}
          />
        </div>
        <div style={{ width: '50%' }}>
          <div style={{ marginBottom: 5 }}>{translations.area[language]}</div>
          <Select
            className="city-select avoid-margin"
            onChange={handleCityChange}
            placeholder={translations.select_area[language]}
            value={selectedCity.length ? selectedCity : undefined}
            disabled={countryLoading || !selectedCountryCode || cityLoading}
            loading={cityLoading}
            showSearch={true}
            optionFilterProp="label"
            // onPopupScroll={onCityPagination}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {renderCities()}
          </Select>
        </div>
      </div>
    )
  }

  const rowSelectHandler = (rowKeys, rowData) => {
    setSelectedPhonenumbers(getNewArray(rowData))
    setSelectedRowKeys(rowKeys)
  }

  const unAssignedPhoneListTable = useMemo(() => {
    let data = []

    if (unAssignedPhoneList?.meta) {
      if (
        unAssignedPhoneList.included?.length &&
        unAssignedPhoneList.included.length > 1
      ) {
        // const skuId = unAssignedPhoneList.included[1].id
        const skuId = unAssignedPhoneList.included.find(
          (x) =>
            x.type === 'stock_keeping_units' &&
            (x.attributes.channels_included_count == 0 || x.attributes.channels_included_count)
        ).id

        // const monthly_amount =
        //   unAssignedPhoneList.included[1].attributes?.monthly_price
        const obj = unAssignedPhoneList.included.find(
          (x) =>
            x.type === 'stock_keeping_units' &&
            (x.attributes.channels_included_count == 0 || x.attributes.channels_included_count)
        )

        const monthly_amount =
          Number(obj?.attributes.monthly_price) +
          Number(obj?.attributes.setup_price)

        data = (unAssignedPhoneList?.data || []).map((data) => {
          if (data.id) {
            return {
              key: data.attributes?.number,
              id: data.id,
              number: data.attributes?.number,
              price: convertToCent(Number(monthly_amount)),
              skuId: skuId,
            }
          }
        })
      }
    } else {
      data = (
        (unAssignedPhoneList[0] &&
          unAssignedPhoneList[0].id &&
          unAssignedPhoneList) ||
        []
      ).map((data) => {
        if (data.data && data.data.price) {
          return {
            key: data.id,
            number: data.id,
            price: data.data.price.usd.monthly_amount,
          }
        }
      })
    }

    const columns = [
      {
        title: translations.number[language],
        dataIndex: 'number',
        width: '50%',
      },
      {
        title: translations.price[language],
        dataIndex: 'price',
        width: '40%',
        align: 'right',
        render: (text, record) => (
          <div className="price-amt">
            ${convertToDollar(record.price)} / {translations.month[language]}
          </div>
        ),
      },
    ]

    const rowSelection = {
      selectedRowKeys,
      onChange: (selectedRowKeys, selectedRows) =>
        rowSelectHandler(selectedRowKeys, selectedRows),
      getCheckboxProps: (record) => {
        return {
          disabled: existingIds.includes(record.key),
        }
      },
    }

    const noPhoneForCity = selectedCity.length && data.length === 0

    return (
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        size={'small'}
        loading={tableLoading}
        className="add-phone-no-table"
        scroll={{ y: isSmall ? '20vh' : '22vh' }}
        style={{ height: '100%', marginTop: 10 }}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        locale={
          tableLoading
            ? {}
            : {
                emptyText: (
                  <div style={{ height: '100%' }}>
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      style={noPhoneForCity ? { margin: '5px 0px' } : {}}
                    />
                    {noPhoneForCity
                      ? translations.no_phone_in_country[language]
                      : ''}
                  </div>
                ),
              }
        }
      />
    )
  }, [unAssignedPhoneList, tableLoading, selectedRowKeys, isSmall, existingIds])

  return (
    <ModalLayout onCancel={onCancel} visible={visiblity}>
      <Header>{translations.add_phone_number[language]}</Header>
      <Body>
        <Alert
          message={translations.choose_phone_number_info[language]}
          type="info"
          showIcon
        />
        <div className="add-phone-modal-body">
          {renderDropDowns()}
          {unAssignedPhoneListTable}
        </div>
      </Body>
      <Footer>
        <PttButton
          type="primary"
          onClick={onAddClick}
          disabled={!selectedRowKeys.length}
        >
          {translations.add[language]}
        </PttButton>
      </Footer>
    </ModalLayout>
  )
}

export default MsTeamsAddPhoneNumber
