// @flow

import React from 'react'
import { Alert, Input, message } from 'antd'
import { connect } from 'react-redux'
import { PttButton } from '../../../CustomButton/custombutton'
import ModalLayout, { Header, Body, Footer } from '../../../Popup/popup'
import { DEFAULT_NODE } from '../../../NodeFieldTypes/nodetypes'
import useCustomFetch from '../../../../Hooks/UseCustomFetch'
import { APIURL } from '../../../../utils/constants'
import { isEmpty } from 'lodash'
import Spinner from '../../../../Spinner/spinner'

type Props = {
  addSuccessCallback: (Object) => mixed,
  buttonText: string,
  callFlow: $ReadOnlyArray<*>,
  onClose: () => void,
  routeFlowName?: string,
  successCallBack: (string) => mixed,
  title: string,
  type?: string,
  value: string,
  visiblity: boolean,
  translations: Object,
  language: string,
}

const AddEditflow = ({
  addSuccessCallback,
  callFlow,
  onClose,
  routeFlowName = '',
  successCallBack,
  title,
  type = '',
  value,
  visiblity,
  translations,
  language,
  buttonText = translations.add[language],
}: Props) => {
  const inputRef = React.createRef()
  const [error, setError] = React.useState('')
  const [name, setName] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [createData, setCreateData] = React.useState(null)

  const [craeteApi] = useCustomFetch(
    `${APIURL}/call_flow?sync=yes`,
    'PUT',
    false,
    createData
  )

  const getError = (currentValue) => {
    const isNameExist = () => {
      return type === 'clone'
        ? callFlow.some((flow) => flow.name === currentValue.trim())
        : callFlow
            .filter((flow) => flow.name !== routeFlowName)
            .some((flow) => flow.name === currentValue.trim())
    }

    if (!currentValue.length) {
      return translations.enter_call_flow_name_info[language]
    } else if (isNameExist()) {
      return translations.call_flow_name_already_exits_info[language]
    } else {
      return ''
    }
  }

  React.useEffect(() => {
    if (visiblity) {
      setError(type === 'Add' ? '' : getError(value))
      setName(value)
    }
  }, [visiblity])

  React.useEffect(() => {
    inputRef &&
      setTimeout(() => {
        inputRef.current && inputRef.current.focus()
      }, 100)
  }, [inputRef])

  const onChange = (event) => {
    setName(event.target.value)
    setError(getError(event.target.value))
  }

  const onSuccess = () => {
    if (error.length) {
      message.error(error)
    } else if (name.trim().length === 0) {
      message.error(translations.call_flow_name[language])
    } else if (type === 'Add') {
      createNewCallFlow(name.trim())
    } else {
      successCallBack(name.trim())
    }
  }

  const createNewCallFlow = (name) => {
    setLoading(true)
    setCreateData(
      JSON.stringify({
        data: {
          nodes: [],
          trigger: {
            icon_position: DEFAULT_NODE.default_icon_position,
            incoming_call: '',
          },
        },
        name: name,
      })
    )
  }

  React.useEffect(() => {
    if (createData !== null) {
      craeteApi.setRefetch(true)
    }
  }, [createData])

  React.useEffect(() => {
    if (!isEmpty(craeteApi.response)) {
      if (
        craeteApi.response.response &&
        craeteApi.response.response.items.length &&
        craeteApi.response.response.items[0].id
      ) {
        message.success(translations.call_flow_created_success[language])
        addSuccessCallback({
          id: craeteApi.response.response.items[0].id,
          key: craeteApi.response.response.items[0].id,
          name: name.trim(),
        })
        setError('')
        setName('')
        setLoading(false)
        setCreateData(null)
      } else {
        message.error(translations.call_flow_created_error[language])
        setLoading(false)
        setCreateData(null)
      }
    }
  }, [craeteApi.response])

  React.useEffect(() => {
    if (craeteApi.error !== null) {
      message.error(translations.call_flow_created_error[language])
      setLoading(false)
      setCreateData(null)
    }
  }, [craeteApi.error])

  return (
    <ModalLayout onCancel={onClose} visible={visiblity} closable={!loading}>
      <Header>{title}</Header>
      <Body>
        <div className="AddEditFlow__Body">
          {error.length ? (
            <Alert message={error} type="error" />
          ) : (
            <React.Fragment />
          )}
          {loading ? (
            <div className="spin-style">
              <Spinner spinning={true} />
            </div>
          ) : (
            <div className="AddEditFlow__Conatiner">
              <div className="AddEditFlow__TextField">
                <Input
                  ref={inputRef}
                  onChange={onChange}
                  onPressEnter={onSuccess}
                  placeholder={translations.new_yorks_office[language]}
                  style={{ marginTop: error ? 10 : 0 }}
                  value={name}
                />
              </div>
            </div>
          )}
        </div>
      </Body>
      <Footer>
        <PttButton
          disabled={error.length !== 0 || name.trim().length === 0}
          loading={loading}
          onClick={onSuccess}
          type="primary"
        >
          {buttonText}
        </PttButton>
      </Footer>
    </ModalLayout>
  )
}

const mapStateToProps = ({ incomingCallsReducer, sampleReducer }) => ({
  callFlow: incomingCallsReducer.callFlow || [],
  translations: sampleReducer.translations || {},
  language: sampleReducer.language,
})

export default connect(mapStateToProps, null)(AddEditflow)
