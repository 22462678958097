import { Button, Input, message } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getFilteredArray } from '../../utils/helpers'
import PttButton from '../CustomButton/PttButton'
import MemoTable from '../MemoTable/memotable'
import { SubnodeOutlined, SearchOutlined } from '@ant-design/icons'
import InfoPanel from '../InfoPanel'
import Spinner from '../../Spinner/spinner'
import useCustomFetch from '../../Hooks/UseCustomFetch'
import useAxiosInstance from '../../Hooks/UseAxiosInstance'
import { APIURL, ASSUMEURL } from '../../utils/constants'
import { cloneDeep, isEmpty } from 'lodash'
import ClientSidebar from './ClientSidebar'
import AddClient from './AddClient'
import { setTokens } from '../../utils/LocalStorage'
import { useHistory } from 'react-router'
import {
  setAppBlurState,
  setIsResellerAccount,
} from '../../actions/SampleAction'

const Clients = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { language, translations, saveClientState } = useSelector(
    ({ sampleReducer, clientReducer }) => ({
      language: sampleReducer.language || 'en',
      translations: sampleReducer.translations || {},
      saveClientState: clientReducer.saveClientState,
    })
  )

  const [clientsData, setClientsData] = useState([])
  const [filter, setFilter] = useState('')
  const [row, setRow] = useState({})
  const [postRow, setPostRow] = useState({})
  const [closeTrigger, setCloseTrigger] = useState(false)
  const [loading, setLoading] = useState(true)
  const [sidebarState, setSidebarState] = useState(false)
  const [addClients, setAddClients] = useState(false)
  const [users, setUsers] = useState([])
  const [clientPanelState, setClientPanelState] = useState(false)

  const assumeApi = useAxiosInstance(ASSUMEURL, 'PttAuthApi')
  const [getClientsApi] = useCustomFetch(
    `${APIURL}/account?relations=*&account_id=*&query=NOT(id:@accountid)`,
    'GET',
    true
  )

  const [getUsersApi] = useCustomFetch(`${APIURL}/user`, 'GET', true)

  useEffect(() => {
    if (!isEmpty(getClientsApi.response)) {
      if (
        getClientsApi.response.result &&
        getClientsApi.response.response.items.length &&
        getClientsApi.response.response.items[0].id
      ) {
        const items = getClientsApi.response.response.items
        const filter = items.filter(
          (item) => item.id && item.id !== 'request_teams'
        )

        setClientsData(filter)
      }

      setLoading(false)
    }
  }, [getClientsApi.response])

  useEffect(() => {
    if (!isEmpty(getUsersApi.response)) {
      if (
        getUsersApi.response.result &&
        getUsersApi.response.response.items.length &&
        getUsersApi.response.response.items[0].id
      ) {
        setUsers(getUsersApi.response.response.items)
      }
    }
  }, [getUsersApi.response])

  const assumeAccount = (id) => {
    const assumeBody = {
      IdToken: localStorage.getItem('IdToken'),
      account: id,
    }

    const onAssumeError = (err) => {
      dispatch(setAppBlurState(false))

      if (
        err &&
        err.error &&
        err.error.includes("you can't assume this account")
      ) {
        message.error(translations.client_redirect_access_error[language])
      } else {
        message.error(translations.client_redirect_error[language])
      }
    }

    const onAssumeSuccess = (res) => {
      if (!res.data.result) {
        return onAssumeError(res.data)
      }

      message.success(translations.client_redirect_success[language])
      setTimeout(() => {
        dispatch(setAppBlurState(false))
        dispatch(setIsResellerAccount(undefined))
        setTokens(res.data)
        localStorage.setItem('IsClientPage', 'yes')
        history.push({ pathname: '/' })
        window.location.reload()
      }, 1000)
    }

    dispatch(setAppBlurState(true))
    assumeApi
      .post('', assumeBody)
      .then(onAssumeSuccess)
      .catch((err) => onAssumeError(err))
  }

  const onAccessClick = (event, text) => {
    if (event) {
      assumeAccount(text.id)
      event.stopPropagation()
    }
  }

  const clientsTable = useMemo(() => {
    if (loading) {
      return (
        <div className="align-center full-height">
          <Spinner spinning={true} />
        </div>
      )
    }

    const tableData = filter.length
      ? getFilteredArray(clientsData, filter, 'name')
      : clientsData

    const columns = [
      {
        dataIndex: 'name',
        sorter: (a, b) => {
          const wordA = a.name.toLowerCase()
          const wordB = b.name.toLowerCase()

          if (wordA < wordB) {
            return -1
          } else if (wordA > wordB) {
            return 1
          } else {
            return 0
          }
        },
        title: translations.clients_title[language],
        defaultSortOrder: 'ascend',
        render: (text, record) => {
          return (
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div style={{ width: 'calc(100% - 120px)' }}>{record.name}</div>
              <div
                style={{
                  width: 120,
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  className="child-client-access-button"
                  icon={<SubnodeOutlined />}
                  onClick={(event) => {
                    onAccessClick(event, record)
                  }}
                  size="small"
                >
                  {translations.access[language]}
                </Button>
              </div>
            </div>
          )
        },
      },
    ]

    const data =
      tableData.length && tableData[0].id
        ? tableData.map(({ id, name }) => ({ id: id, key: id, name: name }))
        : []

    const rowSelectHandler = (rowSelected) => {
      if (row.id === rowSelected.id) {
        setSidebarState(true)
      } else if (saveClientState) {
        setClientPanelState(true)
        setPostRow(rowSelected)
      } else {
        setPostRow(rowSelected)
        setRow(rowSelected)
      }
    }

    return (
      <MemoTable
        columns={columns}
        dataSource={data}
        isSelectable={true}
        selectedRow={row}
        onSelectedRowChange={rowSelectHandler}
        scrollObject={{ y: 'calc(100vh - 190px)' }}
        style={{ height: '100%' }}
      />
    )
  }, [clientsData, row, filter, loading, saveClientState])

  useEffect(() => {
    if (row.id) {
      setSidebarState(true)
    }
  }, [row])

  const addClient = () => {
    setAddClients(true)
  }

  const onSavePostCb = () => {
    setRow(postRow)
    setTimeout(() => setPostRow({}), 1000)
    if (closeTrigger) {
      setSidebarState(false)
      setTimeout(() => setCloseTrigger(false), 1000)
    }
  }

  const onPanelClose = () => {
    if (saveClientState) {
      setClientPanelState(true)
      setPostRow({})
      setCloseTrigger(true)
    } else {
      setSidebarState(false)
    }
  }

  const sidebar = useMemo(() => {
    if (sidebarState) {
      return (
        <InfoPanel
          changeVisiblity={onPanelClose}
          closeButtonVisiblity={false}
          visiblity={sidebarState}
        >
          <ClientSidebar
            clientsData={clientsData}
            selectedClient={row}
            getClientsApi={getClientsApi}
            users={cloneDeep(users)}
            clientPanelState={clientPanelState}
            setClientPanelState={setClientPanelState}
            setSidebarState={setSidebarState}
            onSavePostCb={onSavePostCb}
          />
        </InfoPanel>
      )
    } else {
      return <React.Fragment />
    }
  }, [sidebarState, row, clientsData, getClientsApi, users])

  const refetchClients = () => {
    setLoading(true)
    getClientsApi.setRefetch(true)
  }

  return (
    <div style={{ height: '100%' }}>
      <div className="call-flow-utils-container">
        <div className="call-flow-utils-parent">
          <Input
            className="call-flow-utils-input"
            onChange={(event) => setFilter(event.target.value.trim())}
            placeholder={translations.search[language]}
            prefix={<SearchOutlined />}
          />
          <PttButton onClick={addClient} type="primary">
            {translations.add_client[language]}
          </PttButton>
        </div>
      </div>
      <div className="call-flow-table-container" style={{ display: 'flex' }}>
        <div
          className={
            sidebarState ? 'call-flow-active-style' : 'call-flow-inactive-style'
          }
        >
          {clientsTable}
        </div>
        {sidebar}
      </div>
      <AddClient
        onClose={() => setAddClients(false)}
        visiblity={addClients}
        refetchClients={refetchClients}
      />
    </div>
  )
}

export default Clients
