// @flow

import React from 'react'
import { Button } from 'antd'
import { PortWidget } from '@projectstorm/react-diagrams'
import './CustomNodeWidget.css'

type Props = {
  engine: Object,
  node: Object,
}

const InbuiltNodeWidget = ({ engine, node }: Props) => {
  const nodeClick = (event) => {
    event.stopPropagation()
    node.setSelected(false)
  }

  return (
    <div className="CustomNodeWidget__Container">
      <div className="CustomNodeWidget__Outport_And_Node">
        <div
          className="CustomNodeWidget__Node InbuildNodeWidget__Node"
          onClick={nodeClick}
        >
          <div className="InbuildNodeWidget__Node__Text">{node.name}</div>
          <Button
            className="InbuildNodeWidget__Node__Text"
            onClick={node.clickFunction}
          >
            {node.subname}
          </Button>
        </div>
        <div className="InbuiltWidget__Outport-Container">
          {node.portsOut.map((outport) => (
            <div className="Outport_Style" key={outport.options.name}>
              <PortWidget engine={engine} port={outport}>
                <div className="node-port-out" />
              </PortWidget>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default InbuiltNodeWidget
