// @flow

import React, { useEffect, useMemo, useState, Fragment } from 'react'
import { Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import CallFlowEditor from './callfloweditor'
import { PttButton } from '../../../CustomButton/custombutton'
import MemoTable from '../../../MemoTable/memotable'
import InfoPanel from '../../../InfoPanel'
/* import AddCallFlowModal from '../../Modal/AddCallFlowModal/addcallflowmodal' */
import useCustomFetch from '../../../../Hooks/UseCustomFetch'
import {
  APIURL,
  API_MANAGER_KEY,
  ID_TOKEN,
  TOKEN_TYPE,
} from '../../../../utils/constants'
import { parseApiResult } from '../../../../utils/helpers'
import CallFlowSideBar from './callflowsidebar'
import * as actions from '../../../../actions/IncomingCallsAction'
import Spinner from '../../../../Spinner/spinner'
import AddEditFlow from './AddEditFlow'
import {
  isEmpty,
  getFilteredArray,
  getNewArray,
} from '../../../../utils/helpers'
import './callflow.css'
import ActionsLibrary from './ActionsLibrary'
import { DEFAULT_NODE } from '../../../NodeFieldTypes/nodetypes'
import { getItem } from '../../../../utils/storage'
import { getTtsLanguages } from './CallFlowUtils'

type Props = {
  callFlow: $ReadOnlyArray<*>,
  callFlowNodes: $ReadOnlyArray<*>,
  routeName: string,
  setCallFlow: (any) => mixed,
  setActualCallFlowNodes: (any) => mixed,
  setActualRouteName: (any) => mixed,
  setCallFlowNodes: (any) => mixed,
  setNodeList: (any) => mixed,
  setRedoArray: (any) => mixed,
  setRouteName: (any) => mixed,
  setUndoArray: (any) => mixed,
  setTtsLanguages: (any) => mixed,
  setTtsSpeakers: (any) => mixed,
  undoArray: $ReadOnlyArray<*>,
  translations: Object,
  language: string,
}

const CallFlow = (props: Props) => {
  const { translations, language } = props
  const [view, setView] = useState('FlowView')
  const [filter, setFilter] = useState('')
  const [row, setRow] = useState({})
  const [modalState, setModalState] = useState(false)
  const [callFlowNodeId, setCallFlowNodeId] = useState('')
  const [agents, setAgents] = useState([])

  const [callFlowApi] = useCustomFetch(`${APIURL}/call_flow`, 'GET', true)
  const [nodeListApi] = useCustomFetch(`${APIURL}/node`, 'GET', true)
  const [rowApi] = useCustomFetch(`${APIURL}/call_flow/${row.id}`, 'GET', false)
  const [speakersApi] = useCustomFetch(
    `${APIURL}/system_config/TEXT_TO_SPEECH`,
    'GET',
    true
  )

  useEffect(() => {
    async function getRelatedAgents() {
      const getHeader = {
        headers: {
          Authorization: `${getItem(TOKEN_TYPE)} ${getItem(ID_TOKEN)}`,
          'x-api-key': API_MANAGER_KEY,
        },
        method: 'GET',
      }

      const connector_req = await fetch(`${APIURL}/connector`, getHeader)
      const connector_res = await connector_req.json()
      const items = connector_res.response.items

      if (connector_res.result === true && items.length && items[0].id) {
        const url = `${APIURL}/connector-voice_user/`
        const req = await fetch(`${url}${items[0].id}`, getHeader)
        const res = await req.json()
        const agentsItems = res.response.items

        if (res.result === true && agentsItems.length && agentsItems[0].id) {
          setAgents(agentsItems)
        }
      }
    }

    getRelatedAgents()
  }, [])

  useEffect(() => {
    if (!isEmpty(callFlowApi.response)) {
      if (
        callFlowApi.response.result &&
        callFlowApi.response.response.items.length &&
        callFlowApi.response.response.items[0].id
      ) {
        props.setCallFlow(callFlowApi.response.response.items)
      } else {
        props.setCallFlow([])
      }
    }
  }, [callFlowApi.response])

  useEffect(() => {
    if (!isEmpty(nodeListApi.response)) {
      if (
        nodeListApi.response.result &&
        nodeListApi.response.response.items.length &&
        nodeListApi.response.response.items[0].id
      ) {
        props.setNodeList(nodeListApi.response.response.items)
      } else {
        props.setNodeList([])
      }
    }
  }, [nodeListApi.response])

  useEffect(() => {
    if (!isEmpty(speakersApi.response)) {
      if (
        speakersApi.response.result &&
        speakersApi.response.response.items.length &&
        speakersApi.response.response.items[0].id
      ) {
        const voices = speakersApi.response.response.items[0].data.voices
        const availableVoices = voices.filter((voice) => voice.Available)

        props.setTtsLanguages(getTtsLanguages(availableVoices))
        props.setTtsSpeakers(availableVoices)
      }
    }
  }, [speakersApi.response])

  useEffect(() => {
    row.id && rowApi.setRefetch(true)
  }, [row])

  useEffect(() => {
    if (!isEmpty(rowApi.response)) {
      if (
        rowApi.response.result &&
        rowApi.response.response.items.length &&
        rowApi.response.response.items[0].id
      ) {
        const data = parseApiResult(rowApi.response)[0].data

        setInitialNodes(
          data.nodes,
          data.trigger.incoming_call,
          data.trigger.icon_position
        )
      }
    }
  }, [rowApi.response])

  const setInitialNodes = (initialNodes = [], trigger = '', position = {}) => {
    const nodes = initialNodes
    const undoData = getNewArray(props.undoArray)

    nodes.push({
      call_flow_node_id: DEFAULT_NODE.call_flow_node_id,
      name: DEFAULT_NODE.name,
      icon_position: isEmpty(position)
        ? DEFAULT_NODE.default_icon_position
        : position,
      node_id: DEFAULT_NODE.node_id,
      values: [
        {
          name: 'trigger',
          type: 'TRIGGER',
          value: trigger,
        },
      ],
    })

    undoData.push(nodes)
    props.setActualCallFlowNodes(nodes)
    props.setCallFlowNodes(nodes)
    props.setRedoArray([])
    props.setUndoArray(undoData)
  }

  const setEmpty = () => {
    props.setActualCallFlowNodes([])
    props.setCallFlowNodes([])
    props.setRedoArray([])
    props.setUndoArray([])
  }

  const closePanel = () => {
    setCallFlowNodeId('')
  }

  const openModal = () => {
    setModalState(true)
  }

  const closeModal = () => {
    props.setActualRouteName('')
    props.setRouteName('')
    setModalState(false)
  }

  const changeFlowView = () => {
    setRow({})
    props.setActualRouteName('')
    props.setRouteName('')
    setView('FlowView')
    setEmpty()
    closePanel()
  }

  const deleteCallBack = () => {
    callFlowApi.setRefetch(true)
    changeFlowView()
  }

  const changeEditorView = () => {
    setView('EditorView')
  }

  const onCloneFinish = () => {
    callFlowApi.setRefetch(true)
  }

  /* const createNewCallFlow = (
    callFlowName,
    closeHandler,
    showValidationError
  ) => {
    checkCallFlowNameExist(
      callFlowName,
      () => {
        setFlowName(callFlowName)
        closeHandler()
        changeEditorView()
      },
      showValidationError
    )
  }

  const checkCallFlowNameExist = (newName, onSuccess, onError) => {
    if (callFlow.length) {
      const result = callFlow.find((data) => data.name === newName)

      if (result && (result.length > 0 || Object.keys(result).length)) {
        return onError('Call Flow Name Already Exist')
      }
    }

    return onSuccess()
  } */

  const onAddFlowName = (row) => {
    callFlowApi.setRefetch(true)
    setModalState(false)
    setRow(row)
    props.setActualRouteName(row.name)
    props.setRouteName(row.name)
    changeEditorView()
  }

  const onNodeSelection = (callFlowNodeId) => {
    setCallFlowNodeId(callFlowNodeId)
  }

  const onNodeDeletion = () => {
    closePanel()
  }

  const flowNameChangeCallBack = (value) => {
    props.setRouteName(value)
  }

  const renderFlowView = () => {
    return (
      <div className="call-flow-main-cont">
        <div className="call-flow-utils-container">
          <div className="call-flow-utils-parent">
            <Input
              className="call-flow-utils-input"
              onChange={(event) => setFilter(event.target.value.trim())}
              placeholder={translations.search[language]}
              prefix={<SearchOutlined />}
            />
            <PttButton type="primary" onClick={openModal}>
              {translations.add_call_flow[language]}
            </PttButton>
          </div>
        </div>
        <div className="call-flow-table-container">
          {callFlowApi.loading ? (
            <div className="align-center full-height">
              <Spinner spinning={true} />
            </div>
          ) : (
            renderTable
          )}
        </div>
      </div>
    )
  }

  const renderTable = useMemo(() => {
    const tableData = filter.length
      ? getFilteredArray(props.callFlow, filter, 'name')
      : props.callFlow

    const columns = [
      {
        defaultSortOrder: 'ascend',
        dataIndex: 'name',
        sorter: (a, b) => {
          const wordA = a.name.toLowerCase()
          const wordB = b.name.toLowerCase()

          if (wordA < wordB) {
            return -1
          } else if (wordA > wordB) {
            return 1
          } else {
            return 0
          }
        },
        title: translations.call_flow_title[language],
      },
    ]

    const data =
      tableData.length && tableData[0].id
        ? tableData.map(({ id, name }) => ({ id: id, key: id, name: name }))
        : []

    const rowSelectHandler = (row) => {
      if (row) {
        setRow(row)
        props.setActualRouteName(row.name)
        props.setRouteName(row.name)
        changeEditorView()
      }
    }

    return (
      <MemoTable
        columns={columns}
        dataSource={data}
        isSelectable={true}
        selectedRow={row}
        onSelectedRowChange={rowSelectHandler}
        scrollObject={{ y: 'calc(100vh - 190px)' }}
        style={{ height: '100%' }}
      />
    )
  }, [filter, props.callFlow, row])

  const getCallFlowNodeData = (callFlowNodeId) => {
    const nodeData = props.callFlowNodes.filter(
      (node) => node.call_flow_node_id === callFlowNodeId
    )

    return nodeData.length ? nodeData[0] : {}
  }

  const nodeSidebar = useMemo(() => {
    const result = parseApiResult(nodeListApi.response)
    const nodeId = getCallFlowNodeData(callFlowNodeId).node_id
    const nodeDefinition = result.filter(({ id }) => id === nodeId)

    return (
      <InfoPanel
        changeVisiblity={closePanel}
        closeButtonVisiblity={!(callFlowNodeId.length > 0)}
        visiblity={true}
      >
        {callFlowNodeId.length ? (
          <CallFlowSideBar
            callFlowId={row.id}
            callFlowNodeId={callFlowNodeId}
            nodeDefinition={nodeDefinition}
            agents={agents}
          />
        ) : (
          <ActionsLibrary />
        )}
      </InfoPanel>
    )
  }, [callFlowNodeId])

  const renderEditorView = () => {
    return (
      <div className="call-flow-edit-cont">
        <div className="call-flow-edit-body">
          <div className="call-flow-edit-inner-body">
            <div className="call-flow-active-style">
              <CallFlowEditor
                callFlowId={row.id}
                callFlowNodeId={callFlowNodeId}
                changeFlowView={changeFlowView}
                deleteCallBack={deleteCallBack}
                loading={rowApi.loading}
                onNodeDeletion={onNodeDeletion}
                onNodeSelected={onNodeSelection}
                selectedCallFlow={row}
                flowNameChangeCallBack={flowNameChangeCallBack}
                callFlowApi={callFlowApi}
                onCloneFinish={onCloneFinish}
              />
            </div>
            {nodeSidebar}
          </div>
        </div>
      </div>
    )
  }

  return (
    <Fragment>
      {view === 'FlowView' ? renderFlowView() : renderEditorView()}
      {/* commenting create call flow template as we dont have templates
      as of now https://app.clickup.com/t/6jt2dg
      {<AddCallFlowModal
        open={modalState}
        closeHandler={closeModal}
        createCallFlowCallback={createNewCallFlow}
      />} */}
      {
        <AddEditFlow
          onClose={closeModal}
          addSuccessCallback={onAddFlowName}
          type="Add"
          title={translations.add_call_flow[language]}
          value=""
          visiblity={modalState}
        />
      }
    </Fragment>
  )
}

const mapStateToProps = ({ incomingCallsReducer, sampleReducer }) => ({
  callFlow: incomingCallsReducer.callFlow || [],
  callFlowNodes: incomingCallsReducer.callFlowNodes || [],
  undoArray: incomingCallsReducer.undoArray || [],
  translations: sampleReducer.translations || {},
  language: sampleReducer.language,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...actions }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(CallFlow)
