const phoneNumberReducer = (
  state = {
    selectedPhoneNumberDetails: {},
    editLoaderState: false,
  },
  action
) => {
  switch (action.type) {
    case 'setSelectedPhoneNumberDetails':
      return { ...state, selectedPhoneNumberDetails: action.data }

    case 'setEditLoaderState':
      return { ...state, editLoaderState: action.data }

    default:
      return state
  }
}

export default phoneNumberReducer
