// @flow

import React, { useState, useEffect, useMemo } from 'react'
import ReactFlagsSelect from 'react-flags-select'
import useCustomFetch from '../../Hooks/UseCustomFetch'
import { APIURL } from '../../utils/constants'
import { convertToDollar, getCallingPlanHistoryId, getCallingPlanMonth } from '../../utils/helpers'
import PttButton from '../CustomButton/PttButton'
import ModalLayout, { Body, Footer, Header } from '../Popup/popup'
import { Table } from 'antd'
import { connect } from 'react-redux'

type Props = {
  visiblity: boolean,
  handleModalClose: (any) => mixed,
  callingPlans: Object,
  voiceUserData: Object,
  voiceUserDetailApi: Object,
  nextMonthsCallingPlans: Object,
  isNextBillingCycleEmpty: Object,
  setNextBillingCycleStatus: (any) => mixed,
  getNextMonthCallingPlan: (any) => mixed,
  translations: Object,
  language: string,
  nextCallingPlanId: String,
  createOrUpdateCallingPlan: (any) => mixed
}

const AddCallingPlanModal = (props: Props) => {
  const {
    visiblity,
    handleModalClose,
    callingPlans,
    voiceUserData,
    nextMonthsCallingPlans,
    isNextBillingCycleEmpty,
    setNextBillingCycleStatus,
    getNextMonthCallingPlan,
    translations,
    language,
    nextCallingPlanId,
    createOrUpdateCallingPlan
  } = props

  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [selectedCountry, setSelectedCountry] = useState('')
  const [countryCallingPlans, setCountryCallingPlans] = useState([])
  // const [callingPlanEffectiveOnData, setCallingPlanEffectiveOnData] = useState({ id: '' })
  const [confirmLoader, setConfirmLoader] = useState(false)
  const [countriesList, setCountriesList] = useState([])
  const [updatedCallingPlanData, setUpdatedCallingPlanData] = useState({
    id: '',
  })

  /* Create calling plan history */
  const [createCallingPlanApi] = useCustomFetch(
    `${APIURL}/voice_user_calling_plan_history?sync=yes`,
    'PUT',
    false,
    JSON.stringify(updatedCallingPlanData)
  )

  /* Update calling plan history */
  const [updateCallingPlanApi] = useCustomFetch(
    `${APIURL}/voice_user_calling_plan_history/${nextCallingPlanId}?sync=yes`,
    'PATCH',
    false,
    JSON.stringify(updatedCallingPlanData)
  )

  useEffect(() => {
    if (
      callingPlans.response &&
      callingPlans.response.items &&
      callingPlans.response.items.length
    ) {
      const countries = [],
        callingPlansList = []

      {
        ;(callingPlans.response.items || []).map((callingPlan) => {
          ;(callingPlan.data && callingPlan.data.related_country).map(
            (country) => {
              countries.push(country.toUpperCase())
              {
                ;(callingPlansList[country.toUpperCase()] = callingPlansList[
                  country.toUpperCase()
                ]
                  ? callingPlansList[country.toUpperCase()]
                  : []).push(callingPlan)
              }
            }
          )
        })
      }
      const uniqueCountriesList = [...new Set(countries)]

      setCountryCallingPlans(callingPlansList)
      setCountriesList(uniqueCountriesList)
    }
  }, [callingPlans])

  useEffect(() => {
    if (updatedCallingPlanData.data?.voice_user) {
      setConfirmLoader(true)
      // saveCallingPlanToVoiceUserApi.setRefetch(true)
      if (nextMonthsCallingPlans.length && !isNextBillingCycleEmpty) {
        updateCallingPlanApi.setRefetch(true)
      } else {
        setNextBillingCycleStatus(false)
        createCallingPlanApi.setRefetch(true)
      }
    }
  }, [updatedCallingPlanData])

  useEffect(() => {
    if (
      createCallingPlanApi.response.result ||
      updateCallingPlanApi.response.result
    ) {
      setConfirmLoader(false)
      exitHandler()
      getNextMonthCallingPlan()
    }
  }, [
    createCallingPlanApi.response,
    updateCallingPlanApi.response,
  ])

  const addCallingPlan = () => {
    const planEffectiveMonth = getCallingPlanMonth(
      'nextMonth'
    )
    const updatedCallingPlans = nextMonthsCallingPlans.length
      ? nextMonthsCallingPlans.concat(selectedRows)
      : selectedRows

    const data = {
      // id: planEffectiveFrom,
      data: {
        voice_user: voiceUserData.id,
        month: planEffectiveMonth,
        calling_plan: updatedCallingPlans,
      },
    }

    // setUpdatedCallingPlanData(data)
    createOrUpdateCallingPlan(data)
    exitHandler()
  }

  const exitHandler = () => {
    setSelectedRows([])
    setSelectedRowKeys([])
    setSelectedCountry('')
    setConfirmLoader(false)
    handleModalClose()
  }

  const rowSelectHandler = (rowKeys, rowData) => {
    setSelectedRowKeys(rowKeys)
    const selectedRowData = []

    rowData.map((data) => selectedRowData.push(data.data))
    setSelectedRows(selectedRowData)
  }

  const callingPlansListTable = useMemo(() => {
    const data = (
      (countryCallingPlans && countryCallingPlans[selectedCountry]) ||
      []
    ).map((data) => {
      if (data.data && data.id) {
        return {
          key: data.id,
          name: data.data.label[language] || '',
          minutes: data.data.minutes,
          price: data.data.price.usd.monthly_amount,
          disabled: nextMonthsCallingPlans.some((plan) => plan.id === data.id),
          data: data,
        }
      }
    })

    const columns = [
      {
        title: translations.name[language],
        dataIndex: 'name',
        width: '40%',
      },
      {
        title: translations.minutes[language],
        dataIndex: 'minutes',
        width: '30%',
      },
      {
        title: translations.price[language],
        dataIndex: 'price',
        width: '40%',
        align: 'right',
        render: (text, record) => (
          <div>${convertToDollar(record.price)} / {translations.month[language]}</div>
        ),
      },
    ]

    const rowSelection = {
      selectedRowKeys,
      onChange: (selectedRowKeys, selectedRows) =>
        rowSelectHandler(selectedRowKeys, selectedRows),
      getCheckboxProps: (record) => ({
        disabled: record.disabled,
      }),
    }

    return (
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        scroll={{ y: 'calc(100vh - 190px)' }}
        className="calling-plan-table"
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
      />
    )
  }, [countryCallingPlans, selectedRowKeys, selectedCountry])

  const handleCountryChange = (countryCode) => {
    setSelectedCountry(countryCode)
    setSelectedRowKeys([])
    setSelectedRows([])
  }

  const renderCountryDropDown = () => {
    return (
      <div style={{ width: 250, marginLeft: 10 }}>
        <ReactFlagsSelect
            selectedSize={12}
            optionsSize={12}
            selected={selectedCountry}
            onSelect={handleCountryChange}
            countries={countriesList}
            placeholder={translations.select_a_country[language]}
          />
      </div>
    )
  }

  const getBody = () => {
    return (
      <div>
        <div className='country-select-wrapper'>
        <div>{translations.the_user_will_make_calls_to[language]}</div>
          {renderCountryDropDown()}
        </div>
        {callingPlansListTable}
      </div>
    )
  }

  const getFooter = () => {
    return (
      <PttButton
        onClick={addCallingPlan}
        disabled={selectedRows.length ? false : true}
        loading={confirmLoader}
        type="primary"
      >
        {translations.confirm[language]}
      </PttButton>
    )
  }

  return (
    <ModalLayout
      visible={visiblity}
      onCancel={exitHandler}
      destroyOnClose={true}
      className="calling-plan-modal"
    >
      <Header>{translations.add_calling_plan[language]}</Header>
      <Body>{getBody()}</Body>
      <Footer>{getFooter()}</Footer>
    </ModalLayout>
  )
}

const mapsStateToProps = (state) => {
  return {
    translations: state.sampleReducer.translations || {},
    language: state.sampleReducer.language,
  }
}

export default connect(mapsStateToProps, null)(AddCallingPlanModal)
