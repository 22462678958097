import { DefaultNodeModel } from '@projectstorm/react-diagrams'

class InbuiltNodeModel extends DefaultNodeModel {
  constructor(options, subname, clickFunction) {
    super({
      ...options,
      type: 'js-inbuilt-node',
    })

    this.name = options.name
    this.subname = subname
    this.clickFunction = clickFunction
  }

  serialize() {
    return {
      ...super.serialize(),
      name: this.name,
      subname: this.subname,
      clickFunction: this.clickFunction,
    }
  }

  deserialize(ob, engine) {
    super.deserialize(ob, engine)
    this.name = ob.name
    this.subname = ob.subname
    this.clickFunction = ob.clickFunction
  }
}

export default InbuiltNodeModel
