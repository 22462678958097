import { COMMON_IMG_URL } from '../../utils/constants'
import { DEFAULT_NODE, NODE_TYPES } from '../NodeFieldTypes/nodetypes'

const getNodeIconUrl = (nodeId, nodeList) => {
  const node = nodeList.find((node) => node.id === nodeId)
  const url = node && node.data && node.data.icon ? node.data.icon : ''

  return `${COMMON_IMG_URL}nodeicon/${url}.png`
}

const getValueData = (node) => {
  return node.values.filter(
    ({ type }) =>
      type === NODE_TYPES.node ||
      type === NODE_TYPES.mapNumberToNode ||
      type === NODE_TYPES.mapNumberOrStringToNode
  )
}

const getNodeFields = (tabs) => {
  const fields = []

  tabs.forEach((tab) => {
    tab.fields.forEach((field) => {
      fields.push(field)
    })
  })

  return fields
}

const getFieldData = (nodeFields, name) => {
  return nodeFields.filter((field) => field.name === name)[0]
}

const getPorts = (node, nodeDefintion, values, language) => {
  const ports = []
  const nodeFields = getNodeFields(nodeDefintion.data.tabs)

  values.forEach((data) => {
    const fieldData = getFieldData(nodeFields, data.name)

    if (data.type === NODE_TYPES.node) {
      ports.push({
        displayName: fieldData.label[language],
        from: node.call_flow_node_id,
        label: '',
        labelName: data.name,
        name: `${node.call_flow_node_id}-out-${data.type}-${data.name}`,
        to: data.value,
        type: data.type,
      })
    } else if (data.type === NODE_TYPES.mapNumberToNode) {
      data.key_value_pair.forEach(({ key, value }, index) => {
        ports.push({
          displayName: fieldData.label[language],
          from: node.call_flow_node_id,
          identifier: index,
          label: key,
          labelName: data.name,
          name: `${node.call_flow_node_id}-out-${data.type}-${data.name}-${key}`,
          to: value,
          type: data.type,
        })
      })
    } else if (data.type === NODE_TYPES.mapNumberOrStringToNode) {
      const getDialSpeechLabel = (dial, say) => {
        if (dial.length && say.length) {
          return `${dial}/${say}`
        } else if (dial.length) {
          return dial
        } else if (say.length) {
          return say
        } else {
          return ''
        }
      }

      data.key_value_pair.forEach(({ dial = '', say = '', value }, index) => {
        ports.push({
          dial: dial,
          displayName: fieldData.label[language],
          from: node.call_flow_node_id,
          identifier: index,
          label: getDialSpeechLabel(dial, say),
          labelName: data.name,
          name: `${index}-${node.call_flow_node_id}-out-${data.type}-${data.name}-${dial}/${say}`,
          say: say,
          to: value,
          type: data.type,
        })
      })
    }
  })

  return ports
}

export const getNode = (model, nodeId) =>
  model.getNodes().find((node) => node.options.callFlowNodeId === nodeId)

export const getActiveModelNodes = (model) =>
  model.getNodes().filter(({ options }) => options.mode !== NODE_TYPES.default)

const defaultLink = (node) => {
  if (!node.values[0].value.length) {
    return []
  }

  return [
    {
      displayName: '',
      from: node.call_flow_node_id,
      label: '',
      labelName: '',
      name: `${node.call_flow_node_id}-out-connect`,
      to: node.values[0].value,
      type: '',
    },
  ]
}

export const getDiagramData = (nodes, nodeList, language) => {
  const diagramData = []

  nodes.forEach((node) => {
    const data = getValueData(node)
    const nodeDefintion = nodeList.filter((list) => list.id === node.node_id)[0]

    if (nodeDefintion) {
      diagramData.push({
        callFlowNodeId: node.call_flow_node_id,
        iconUrl: getNodeIconUrl(node.node_id, nodeList),
        ports: data.length ? getPorts(node, nodeDefintion, data, language) : [],
        mode: data.length ? data[0].type : 'none',
        name: node.name,
        nodeId: node.node_id,
        values: node.values,
        pos: {
          x: Number(node.icon_position.x),
          y: Number(node.icon_position.y),
        },
      })
    } else if (node.node_id === DEFAULT_NODE.node_id) {
      diagramData.push({
        callFlowNodeId: node.call_flow_node_id,
        iconUrl: '',
        ports: defaultLink(node),
        mode: NODE_TYPES.default,
        name: node.name,
        nodeId: node.node_id,
        values: node.values,
        pos: {
          x: Number(node.icon_position.x),
          y: Number(node.icon_position.y),
        },
      })
    }
  })

  return diagramData
}
