// @flow

import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { EXTRA_TOKEN } from '../../utils/constants'
import { getItem } from '../../utils/storage'

type Props = {
  dashboardUrl: Object,
  translations: Object,
  language: string,
}

const StatsDashboard = (props: Props) => {
  const { dashboardUrl, translations, language } = props
  const [iframeurl, setIframeUrl] = useState('')

  useEffect(() => {
    if (dashboardUrl) {
      setIframeUrl(dashboardUrl + (dashboardUrl.includes('?')? '&' : '?') + 'ExtraToken=' + getItem(EXTRA_TOKEN))
    }
  }, [dashboardUrl])

  return (
    <div style={{ width: '100%', height: '100%' }}>
      {dashboardUrl && (
        <iframe
          width="100%"
          height="100%"
          style={{ border: 0 }}
          src={iframeurl}
        ></iframe>
      )}

      {!dashboardUrl && (
        <div className="no-dashboard-txt">
          {translations.no_dashboard_selected[language]}
        </div>
      )}
    </div>
  )
}

const mapsStateToProps = (state) => {
  return {
    translations: state.sampleReducer.translations || {},
    language: state.sampleReducer.language,
  }
}

export default connect(mapsStateToProps, null)(StatsDashboard)
