/* eslint-disable react/prop-types */
/* eslint-disable no-console */
// @flow

import React, { useEffect, useMemo, useState } from 'react'
import { Divider, Layout, Menu, message } from 'antd'
import { connect, useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useHistory } from 'react-router-dom'
import Icon from '@ant-design/icons'
import { isEqual, isEmpty } from 'lodash'
import {
  BillingIcon,
  /* CallFlowIcon, */
  PhoneIcon,
  StatsIcon,
  TeamsIcon,
  SettingIcon,
  LogoutIcon,
  ChatIcon,
  ClientIcon,
  AdvanceIcon,
} from '../HomeIcons'

import { setUnsaveAlertTrigger } from '../../../actions/IncomingCallsAction'
import { removeTokens, setTokens } from '../../../utils/LocalStorage'
import '../homelayout.css'
import useCustomFetch from '../../../Hooks/UseCustomFetch'
import {
  APIURL,
  API_MANAGER_KEY,
  ASSUMEURL,
  COMMON_IMG_URL,
  ID_TOKEN,
  NO_ACCESS,
  TOKEN_TYPE,
} from '../../../utils/constants'
import { getItem } from '../../../utils/storage'
import {
  setCollapsed,
  setHomeKey,
  resetReduxStore,
  setIsResellerAccount,
  setAppBlurState,
} from '../../../actions/SampleAction'
import {
  setActualVoiceUserData,
  setTeamsStep,
  setModifiedVoiceUserData,
  setMsTeamsUnsaveAlertTrigger,
  setUpdateVoiceUserOnTheFly,
} from '../../../actions/MsTeamsAction'
import { getTranslatedText } from '../../../utils/translations'
import {
  setTriggerSettingsSave,
  setSettingsSavePostUrl,
  setSettingsSaveRedirect,
} from '../../../actions/settingAction'
import ConfirmPopup from '../../Popup/confirmpopup'
import { Fragment } from 'react'
import useAxiosInstance from '../../../Hooks/UseAxiosInstance'
import {
  setCloseState,
  setOnSaveState,
  setSaveClientState,
} from '../../../actions/ClientAction'
import {
  setActualRouting,
  setModifiedRouting,
  setRoutingSavedFromOption,
  setRoutingSaveFromOption,
} from '../../../actions/routingAction'
import {
  setActualTrunk,
  setModifiedTrunk,
  setTrunkSavedFromOption,
  setTrunkSaveFromOption,
} from '../../../actions/trunksAction'

const PttSidebar = ({
  actualCallFlowNodes,
  callFlowNodes,
  homeKey,
  currentkey,
  setHomeKey,
  setUnsaveAlertTrigger,
  editLoaderState,
  translations,
  language,
  setCollapsed,
  collapsed,
  companySettingsChanged,
  personalSettingsChanged,
  actualVoiceUserData,
  modifiedVoiceUserData,
  resetReduxStore,
  isResellerAccount,
  teamsStep,
  appFromClient,
  appProviderBrand,
  saveClientState,
  closeState,
  actualRouting,
  modifiedRouting,
  routingSavedFromOption,
  actualTrunk,
  modifiedTrunk,
  trunkSavedFromOption,
  microsoftTeamsPermission,
  phoneNumberPermission,
  statsPermission,
  advancedPermission,
  billingPermission,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  // const [bot] = useBot()

  const [id, setId] = useState('')
  const [data, setData] = useState('')
  const [saveMsTeams, setSaveMsTeams] = useState(false)
  const [postSaveRedirectPath, setPostSaveRedirectPath] = useState('')
  const [teamsProcessing, setTeamsProcessing] = useState(false)
  const [clientPanelState, setClientPanelState] = useState(false)
  const [routingPanel, setRoutingPanel] = useState(false)
  const [postRoutingPath, setPostRoutingPath] = useState('')
  const [trunkPanel, setTrunkPanel] = useState(false)
  const [postTrunkPath, setPostTrunkPath] = useState('')

  const iconStyle = collapsed ? 'icon-big' : 'icon-small'
  const listSyle = collapsed ? 'list-style' : 'list-style-expanded'

  const assumeApi = useAxiosInstance(ASSUMEURL, 'PttAuthApi')
  const [collapsedAPI] = useCustomFetch(
    `${APIURL}/user_settings?query=custom_id=@userid`,
    'GET',
    true
  )

  useEffect(() => {
    if (!isEmpty(collapsedAPI.response)) {
      if (
        collapsedAPI.response.result &&
        collapsedAPI.response.response.items.length &&
        collapsedAPI.response.response.items[0].id
      ) {
        setId(collapsedAPI.response.response.items[0].id)
        setData(collapsedAPI.response.response.items[0].data)
        setCollapsed(collapsedAPI.response.response.items[0].data.collapsed)
      }
    }
  }, [collapsedAPI.response])

  const getSidebarOptons = () => {
    const data = []

    if (microsoftTeamsPermission !== NO_ACCESS) {
      data.push({
        className: listSyle,
        key: '1',
        icon: () => <Icon className={iconStyle} component={TeamsIcon} />,
        name: getTranslatedText(translations, language, 'microsoft_teams'),
        path: '/msteams',
      })
    }

    if (phoneNumberPermission !== NO_ACCESS) {
      data.push({
        className: listSyle,
        key: '2',
        name: getTranslatedText(translations, language, 'phone_numbers'),
        icon: () => <Icon className={iconStyle} component={PhoneIcon} />,
        path: '/phonenumbers',
      })
    }

    if (statsPermission !== NO_ACCESS) {
      data.push({
        className: listSyle,
        key: '4',
        name: getTranslatedText(translations, language, 'stats'),
        icon: () => <Icon className={iconStyle} component={StatsIcon} />,
        path: '/stats',
      })
    }

    if (advancedPermission !== NO_ACCESS) {
      data.push({
        className: listSyle,
        key: '7',
        name: getTranslatedText(translations, language, 'advanced'),
        icon: () => <Icon className={iconStyle} component={AdvanceIcon} />,
        path: '/advanced',
      })
    }

    if (billingPermission !== NO_ACCESS) {
      data.push({
        className: listSyle,
        key: '5',
        name: getTranslatedText(translations, language, 'billing'),
        icon: () => <Icon className={iconStyle} component={BillingIcon} />,
        path: '/billing',
      })
    }

    data.push({
      className: listSyle,
      key: '6',
      name: getTranslatedText(translations, language, 'settings_title'),
      icon: () => <Icon className={iconStyle} component={SettingIcon} />,
      path: '/settings',
    })

    /* data.push({
      className: listSyle,
      key: '3',
      name: getTranslatedText(translations, language, 'call_flow_title'),
      icon: () => <Icon className={iconStyle} component={CallFlowIcon} />,
      path: '/callflow',
    }) */

    return data
  }

  const menuItemsTop = () => {
    if (isResellerAccount === undefined) {
      return []
    } else if (isResellerAccount) {
      return [
        {
          className: listSyle,
          key: '10',
          icon: () => <Icon className={iconStyle} component={ClientIcon} />,
          name: getTranslatedText(translations, language, 'clients_title'),
          path: '/clients',
        },
        {
          className: listSyle,
          key: '6',
          name: getTranslatedText(translations, language, 'settings_title'),
          icon: () => <Icon className={iconStyle} component={SettingIcon} />,
          path: '/settings',
        },
      ]
    } else {
      return getSidebarOptons()
    }
  }

  const menuItemsBottom = useMemo(() => {
    if (isResellerAccount === undefined) {
      return []
    } else if (isResellerAccount) {
      return [
        {
          className: listSyle,
          key: '8',
          name: getTranslatedText(translations, language, 'logout'),
          icon: () => <Icon className={iconStyle} component={LogoutIcon} />,
          path: '/login',
        },
      ]
    } else {
      return [
        {
          className: listSyle,
          key: '9',
          name: getTranslatedText(translations, language, 'Chat_Support'),
          icon: () => <Icon className={iconStyle} component={ChatIcon} />,
          path: '/bot',
        },
        {
          className: listSyle,
          key: '8',
          name: getTranslatedText(translations, language, 'logout'),
          icon: () => <Icon className={iconStyle} component={LogoutIcon} />,
          path: '/login',
        },
      ]
    }
  }, [collapsed, currentkey, isResellerAccount])

  useEffect(() => {
    if (homeKey.length) {
      setTimeout(() => {
        redirectToPath(homeKey)
        setHomeKey('')
      }, 100)
    }
  }, [homeKey])

  const logout = () => {
    removeTokens()
    resetReduxStore()
    history.push({ pathname: '/login' })
  }

  const handleOnBotClick = () => {
    window.open(
      'https://tawk.to/chat/605b0bd9f7ce182709335f27/1f1hqcj1i',
      'Ptt Chat Support',
      'height=700,width=700,modal=yes,alwaysRaised=yes'
    )
  }

  const onModuleChange = (path, key) => {
    if (key === '9') {
      return handleOnBotClick()
    }

    if (
      currentkey === '6' &&
      (companySettingsChanged || personalSettingsChanged)
    ) {
      dispatch(setTriggerSettingsSave(true))
      dispatch(setSettingsSavePostUrl(path))
      dispatch(setSettingsSaveRedirect(false))

      return
    }

    if (currentkey === '1') {
      if (!isEqual(actualVoiceUserData, modifiedVoiceUserData)) {
        setSaveMsTeams(true)
        setPostSaveRedirectPath(path)

        return
      } else if (teamsStep.length) {
        setTeamsProcessing(true)
        setPostSaveRedirectPath(path)

        return
      }
    }

    if (currentkey === '10') {
      if (saveClientState) {
        setClientPanelState(true)
        setPostSaveRedirectPath(path)

        return
      }
    }

    if (currentkey === '7') {
      if (!isEqual(actualRouting, modifiedRouting)) {
        setRoutingPanel(true)
        setPostRoutingPath(path)

        return
      }

      if (!isEqual(actualTrunk, modifiedTrunk)) {
        setTrunkPanel(true)
        setPostTrunkPath(path)

        return
      }
    }

    if (key === '8') {
      return logout()
    }

    if (currentkey === '3') {
      if (isEqual(actualCallFlowNodes, callFlowNodes)) {
        redirectToPath(path)
      } else {
        setUnsaveAlertTrigger({
          state: true,
          key: path,
        })
      }
    } else if (currentkey === '2') {
      if (editLoaderState) {
        message.warning(
          translations.warning_existing_phone_number_not_saved[language]
        )
      } else {
        redirectToPath(path)
      }
    } else {
      redirectToPath(path)
    }
  }

  const redirectToPath = (path) => {
    history.push({ pathname: path })
  }

  const handleOnCollapse = (value) => {
    setCollapsed(value)
    if (id.length && data !== null) {
      const bodyData = data

      data.collapsed = value

      const url = `${APIURL}/user_settings/${id}`
      const headers = {
        headers: {
          Authorization: `${getItem(TOKEN_TYPE)} ${getItem(ID_TOKEN)}`,
          'Content-Type': 'application/json',
          'x-api-key': API_MANAGER_KEY,
        },
        method: 'PATCH',
        body: JSON.stringify({ id: id, data: bodyData }),
      }

      fetch(url, headers)
        .then((res) => res.json())
        .then((res) => console.log(res))
        .catch((err) => console.error(err))
    }
  }

  const handlePostSaveRedirect = () => {
    if (postSaveRedirectPath === '/login') {
      logout()
    } else {
      redirectToPath(postSaveRedirectPath)
      setPostSaveRedirectPath('')
    }
  }

  const clientPanelClose = () => {
    setClientPanelState(false)
  }
  const clientPanelCancel = () => {
    setClientPanelState(false)
    dispatch(setSaveClientState(false))
    handlePostSaveRedirect()
  }

  const clientPanelOkay = () => {
    dispatch(setOnSaveState(true))
    if (closeState) {
      setClientPanelState(false)
      dispatch(setSaveClientState(false))
      handlePostSaveRedirect()
      dispatch(setCloseState(false))
    }
  }

  const saveMsTeamsCancel = () => {
    dispatch(setActualVoiceUserData([]))
    dispatch(setModifiedVoiceUserData([]))
    handlePostSaveRedirect()
    setSaveMsTeams(false)
  }

  const saveMsTeamsClose = () => {
    setSaveMsTeams(false)
  }

  const saveMsTeamsOkay = () => {
    setSaveMsTeams(false)
    dispatch(
      setUpdateVoiceUserOnTheFly({
        execute: true,
        postCallback: handlePostSaveRedirect,
      })
    )
  }

  useEffect(() => {
    if (isResellerAccount) {
      const bodyId = 'ant-layout-sider-children'
      const footerId = 'ant-layout-sider-trigger'

      const menuId1 = 'sidebar-native-menu1'
      const menuId2 = 'sidebar-native-menu2'

      const layoutBody = document.getElementsByClassName(bodyId)
      const layoutFooter = document.getElementsByClassName(footerId)
      const menuElement1 = document.getElementById(menuId1)
      const menuElement2 = document.getElementById(menuId2)

      if (
        layoutBody.length &&
        layoutFooter.length &&
        menuElement1 &&
        menuElement2
      ) {
        layoutBody[0].style.background = '#008b8b'
        layoutFooter[0].style.background = '#256161'
        menuElement1.style.background = '#008b8b'
        menuElement2.style.background = '#008b8b'
      }
    }
  }, [isResellerAccount, currentkey, collapsed])

  useEffect(() => {
    if (closeState) {
      setClientPanelState(false)
      dispatch(setSaveClientState(false))
      handlePostSaveRedirect()
      dispatch(setCloseState(false))
    }
  }, [closeState])

  const closeProceedPopup = () => {
    setTeamsProcessing(false)
  }

  const okayProceedPopup = () => {
    setTeamsProcessing(false)
    dispatch(setTeamsStep(''))
    handlePostSaveRedirect()
  }

  const onAdminClick = () => {
    const assumeBody = {
      IdToken: localStorage.getItem('IdToken'),
      account: '@rootaccountid',
    }

    const onAssumeError = () => {
      message.error(translations.admin_redirect_error[language])
      dispatch(setAppBlurState(false))
    }

    const onAssumeSuccess = (res) => {
      if (!res.data.result) {
        return onAssumeError()
      }

      message.success(translations.admin_redirect_success[language])
      setTimeout(() => {
        dispatch(setAppBlurState(false))
        dispatch(setIsResellerAccount(undefined))
        setTokens(res.data)
        localStorage.removeItem('IsClientPage')
        history.push({ pathname: '/clients' })
        window.location.reload()
      }, 1000)
    }

    dispatch(setAppBlurState(true))
    assumeApi.post('', assumeBody).then(onAssumeSuccess).catch(onAssumeError)
  }

  const renderAdminButton = () => {
    if (!appFromClient) {
      return <React.Fragment />
    }

    return (
      <Fragment>
        <Menu.Item
          className={listSyle}
          key={'BackToAdmin'}
          icon={<Icon className={iconStyle} component={ClientIcon} />}
          onClick={onAdminClick}
        >
          {translations.back_to_admin[language]}
        </Menu.Item>
        <Divider style={{ borderWidth: 2, borderColor: 'white', margin: 0 }} />
      </Fragment>
    )
  }

  const getTeamsProcessText = (type) => {
    if (teamsStep === '2') {
      return type === 'info'
        ? translations.leave_connector_info[language]
        : translations.leave_connector[language]
    } else if (teamsStep === '3' || teamsStep === '4') {
      return type === 'info'
        ? translations.leave_addusers_info[language]
        : translations.leave_addusers[language]
    } else if (teamsStep === '5') {
      return type === 'info'
        ? translations.leave_checkout_info[language]
        : translations.leave_checkout[language]
    } else {
      return ''
    }
  }

  const getLogoSource = () => {
    const imageBaseUrl = `${COMMON_IMG_URL}branding/${appProviderBrand}`

    if (collapsed) {
      return `${imageBaseUrl}/logo3.png`
    } else {
      return `${imageBaseUrl}/logo2.png`
    }
  }

  const renderMenuItem = (item) => {
    return (
      <Menu.Item
        className={item.className}
        key={item.key}
        icon={item.icon()}
        onClick={() => onModuleChange(item.path, item.key)}
      >
        {item.name}
      </Menu.Item>
    )
  }

  const renderSidebarLogo = () => {
    return (
      <div className="ptt-logo-cont">
        <img
          alt="pttWhiteLogo"
          className="ptt-logo-style"
          style={{ padding: collapsed ? 20 : 15 }}
          src={getLogoSource()}
        />
      </div>
    )
  }

  const routingUnsaveClose = () => {
    setRoutingPanel(false)
    setPostRoutingPath('')
  }

  const routingUnsaveCancel = () => {
    setRoutingPanel(false)
    dispatch(setActualRouting({}))
    dispatch(setModifiedRouting({}))
    if (postRoutingPath === '/login') {
      logout()
    } else {
      redirectToPath(postRoutingPath)
      setTimeout(() => setPostRoutingPath(''), 1000)
    }
  }

  const routingUnsaveOkay = () => {
    setRoutingPanel(false)
    dispatch(setRoutingSaveFromOption(true))
  }

  useEffect(() => {
    if (routingSavedFromOption) {
      dispatch(setRoutingSavedFromOption(false))
      if (postRoutingPath === '/login') {
        logout()
      } else {
        redirectToPath(postRoutingPath)
        setTimeout(() => setPostRoutingPath(''), 1000)
      }
    }
  }, [routingSavedFromOption])

  const trunkUnsaveClose = () => {
    setTrunkPanel(false)
    setPostTrunkPath('')
  }

  const trunkUnsaveCancel = () => {
    setTrunkPanel(false)
    dispatch(setActualTrunk({}))
    dispatch(setModifiedTrunk({}))
    if (postTrunkPath === '/login') {
      logout()
    } else {
      redirectToPath(postTrunkPath)
      setTimeout(() => setPostTrunkPath(''), 1000)
    }
  }

  const trunkUnsaveOkay = () => {
    setTrunkPanel(false)
    dispatch(setTrunkSaveFromOption(true))
  }

  useEffect(() => {
    if (trunkSavedFromOption) {
      dispatch(setTrunkSavedFromOption(false))
      if (postTrunkPath === '/login') {
        logout()
      } else {
        redirectToPath(postTrunkPath)
        setTimeout(() => setPostTrunkPath(''), 1000)
      }
    }
  }, [trunkSavedFromOption])

  return (
    <React.Fragment>
      <Layout.Sider
        collapsible
        collapsed={collapsed}
        onCollapse={handleOnCollapse}
        style={{ height: 'calc(100vh - 48px)' }}
      >
        {renderSidebarLogo()}
        <div style={{ height: 'calc(100% - 62px)' }}>
          <Menu
            id={isResellerAccount ? 'sidebar-native-menu1' : ''}
            className={
              isResellerAccount
                ? 'top-sidebar-items-reseller'
                : 'top-sidebar-items-normal'
            }
            mode="inline"
            selectedKeys={[currentkey]}
            theme="dark"
          >
            {renderAdminButton()}
            {menuItemsTop().map((item) => renderMenuItem(item))}
          </Menu>
          <Menu
            id={isResellerAccount ? 'sidebar-native-menu2' : ''}
            className={
              isResellerAccount
                ? 'bottom-sidebar-items-reseller'
                : 'bottom-sidebar-items-normal'
            }
            mode="inline"
            selectedKeys={[currentkey]}
            theme="dark"
          >
            {menuItemsBottom.map((item) => renderMenuItem(item))}
          </Menu>
        </div>
      </Layout.Sider>
      <ConfirmPopup
        cancelText={translations.no[language]}
        closable={true}
        confirmText={translations.voice_user_unsaved_changes[language]}
        okayText={translations.yes[language]}
        onCancel={saveMsTeamsCancel}
        onClose={saveMsTeamsClose}
        onOkay={saveMsTeamsOkay}
        visiblity={saveMsTeams}
      >
        <React.Fragment>
          {translations.voice_user_unsaved_changes_info[language]}
        </React.Fragment>
      </ConfirmPopup>
      <ConfirmPopup
        cancelText={translations.no[language]}
        closable={true}
        confirmText={getTeamsProcessText('title')}
        okayText={translations.yes[language]}
        onCancel={closeProceedPopup}
        onClose={closeProceedPopup}
        onOkay={okayProceedPopup}
        visiblity={teamsProcessing}
      >
        <React.Fragment>{getTeamsProcessText('info')}</React.Fragment>
      </ConfirmPopup>
      <ConfirmPopup
        cancelText={translations.no[language]}
        closable={true}
        confirmText={translations.client_unsaved_changes[language]}
        okayText={translations.yes[language]}
        onCancel={clientPanelCancel}
        onClose={clientPanelClose}
        onOkay={clientPanelOkay}
        visiblity={clientPanelState}
      >
        <React.Fragment>
          {translations.client_unsaved_changes_info[language]}
        </React.Fragment>
      </ConfirmPopup>
      <ConfirmPopup
        cancelText={translations.no[language]}
        closable={true}
        confirmText={translations.routing_unsaved_changes[language]}
        okayText={translations.yes[language]}
        onCancel={routingUnsaveCancel}
        onClose={routingUnsaveClose}
        onOkay={routingUnsaveOkay}
        visiblity={routingPanel}
      >
        <React.Fragment>
          {translations.routing_unsaved_changes_info[language]}
        </React.Fragment>
      </ConfirmPopup>
      <ConfirmPopup
        cancelText={translations.no[language]}
        closable={true}
        confirmText={translations.trunk_unsaved_changes[language]}
        okayText={translations.yes[language]}
        onCancel={trunkUnsaveCancel}
        onClose={trunkUnsaveClose}
        onOkay={trunkUnsaveOkay}
        visiblity={trunkPanel}
      >
        <React.Fragment>
          {translations.trunk_unsaved_changes_info[language]}
        </React.Fragment>
      </ConfirmPopup>
    </React.Fragment>
  )
}

const mapStateToProps = ({
  incomingCallsReducer,
  phoneNumberReducer,
  sampleReducer,
  msTeamsReducer,
  settingReducer,
  clientReducer,
  trunkReducer,
  routingReducer,
  permissionReducer,
}) => {
  return {
    actualCallFlowNodes: incomingCallsReducer.actualCallFlowNodes || [],
    callFlowNodes: incomingCallsReducer.callFlowNodes || [],
    homeKey: sampleReducer.homeKey || '',
    editLoaderState: phoneNumberReducer.editLoaderState || false,
    translations: sampleReducer.translations || {},
    language: sampleReducer.language,
    collapsed: sampleReducer.collapsed,
    isCallingPlanSaved: msTeamsReducer.isCallingPlanSaved,
    settingsSavePostUrl: settingReducer.settingsSavePostUrl,
    settingsSaveRedirect: settingReducer.settingsSaveRedirect,
    companySettingsChanged: settingReducer.companySettingsChanged,
    personalSettingsChanged: settingReducer.personalSettingsChanged,
    actualVoiceUserData: msTeamsReducer.actualVoiceUserData,
    modifiedVoiceUserData: msTeamsReducer.modifiedVoiceUserData,
    isResellerAccount: sampleReducer.isResellerAccount,
    teamsStep: msTeamsReducer.teamsStep,
    appFromClient: sampleReducer.appFromClient,
    appProviderBrand: sampleReducer.appProviderBrand,
    saveClientState: clientReducer.saveClientState,
    closeState: clientReducer.closeState,
    actualRouting: routingReducer.actualRouting,
    modifiedRouting: routingReducer.modifiedRouting,
    routingSavedFromOption: routingReducer.routingSavedFromOption,
    actualTrunk: trunkReducer.actualTrunk,
    modifiedTrunk: trunkReducer.modifiedTrunk,
    trunkSavedFromOption: trunkReducer.trunkSavedFromOption,
    microsoftTeamsPermission: permissionReducer.microsoftTeamsPermission,
    phoneNumberPermission: permissionReducer.phoneNumberPermission,
    statsPermission: permissionReducer.statsPermission,
    advancedPermission: permissionReducer.advancedPermission,
    billingPermission: permissionReducer.billingPermission,
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setUnsaveAlertTrigger,
      setHomeKey,
      setCollapsed,
      setMsTeamsUnsaveAlertTrigger,
      resetReduxStore,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(PttSidebar)
