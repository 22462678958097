// @flow

import React, { type Node } from 'react'
import { Button } from 'antd'
import './PttButton.css'
import { useSelector } from 'react-redux'

type Props = {
  children?: Node,
  customClass?: string,
  disabled?: boolean,
  loading?: boolean,
  onClick?: (any) => mixed,
  type: string,
  size: string,
}

const PttButton = (props: Props) => {
  const { customClass = '', size = 'default' } = props

  const { isResellerAccount } = useSelector((state) => ({
    isResellerAccount: state.sampleReducer.isResellerAccount,
  }))

  const getClassName = () => {
    if (props.type === 'primary') {
      return `${
        isResellerAccount ? 'primary-button-reseller' : 'primary-button'
      } ${customClass}`
    } else if (props.type === 'secondary') {
      return `${
        isResellerAccount ? 'secondary-button-reseller' : 'secondary-button'
      } ${customClass}`
    } else {
      return customClass
    }
  }

  return (
    <Button
      {...props}
      className={getClassName()}
      disabled={props.disabled}
      loading={props.loading}
      onClick={props.onClick}
      size={size}
    >
      {props.children}
    </Button>
  )
}

export default PttButton
