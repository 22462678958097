/* eslint-disable react/prop-types */
/* eslint-disable no-useless-escape */
/* eslint-disable no-negated-condition */
import React, { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { FlagIcon } from 'react-flag-kit'
import { useDispatch, useSelector } from 'react-redux'
import { message, Tabs, Input, Select, Checkbox, Tooltip, Form } from 'antd'
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  ExclamationCircleTwoTone,
  LoadingOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons'
import Spinner from '../../Spinner/spinner'
import PttButton from '../CustomButton/PttButton'
import {
  setAccountSettingsData,
  setUserLanguage,
} from '../../actions/SampleAction'
import {
  APIURL,
  API_MANAGER_KEY,
  ID_TOKEN,
  TOKEN_TYPE,
  EUROPIAN_UNION_COUNTRIES,
  CUSTOM_ACTION_URL,
  NO_ACCESS,
  FULL_ACCESS,
} from '../../utils/constants'
import { availableLanguages, getTranslatedText } from '../../utils/translations'
import useCustomFetch from '../../Hooks/UseCustomFetch'
import './Settings.css'
import { getItem } from '../../utils/storage'
import ReactFlagsSelect from 'react-flags-select'
import ConfirmPopup from '../Popup/confirmpopup'
import {
  setTriggerSettingsSave,
  setPersonalSettingsChanged,
  setCompanySettingsChanged,
} from '../../actions/settingAction'
import { removeTokens } from '../../utils/LocalStorage'
import { useHistory } from 'react-router-dom'
import ChangePassword from './ChangePassword'
import UserTab from './UserTab'
import { Fragment } from 'react'

const UnEditableSection = ({ value }) => {
  return (
    <div
      style={{
        marginBottom: 5,
        padding: '4px 11px',
        border: '1px solid lightgrey',
        height: 32,
      }}
    >
      {value}
    </div>
  )
}

const Settings = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {
    accountSettings,
    appFromClient,
    language,
    translations,
    userStripeData,
    triggerSettingsSave,
    settingsSavePostUrl,
    companySettingsPermission,
    accessUsersPermission,
  } = useSelector(({ sampleReducer, settingReducer, permissionReducer }) => ({
    language: sampleReducer.language || 'en',
    translations: sampleReducer.translations || {},
    userStripeData: sampleReducer.userStripeData || {},
    accountSettings: sampleReducer.accountSettings || {},
    triggerSettingsSave: settingReducer.triggerSettingsSave,
    settingsSavePostUrl: settingReducer.settingsSavePostUrl,
    appFromClient: sampleReducer.appFromClient,
    companySettingsPermission: permissionReducer.companySettingsPermission,
    accessUsersPermission: permissionReducer.accessUsersPermission,
  }))

  const [activeKey, setActiveKey] = useState(appFromClient ? '2' : '1')
  const [loading, setLoading] = useState(true)
  // const [plansData, setPlansData] = useState([])
  const [userName, setUserName] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const [personalLanguage, setPersonalLanguage] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [companyEmail, setCompanyEmail] = useState('')
  const [address, setAddress] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  // const [companyPlan, setCompanyPlan] = useState({})
  const [accountId, setAccountId] = useState('')
  const [userId, setUserId] = useState('')
  const [accountData, setAccountData] = useState(null)
  const [userData, setUserData] = useState(null)
  const [companyLoading, setCompanyLoading] = useState(false)
  const [userLoading, setUserLoading] = useState(false)
  const [countryCode, setCountryCode] = useState('')
  const [spanishTaxExempt, setSpanishTaxExempt] = useState(false)
  const [vatNo, setVatNo] = useState('')
  const [prevVatNo, setPrevVatNo] = useState('')
  const [isTaxFieldEnabled, setTaxFieldEnabled] = useState(false)
  const [accSettingsBodyData, setAccSettingsBodyData] = useState({})
  const [vatIdVerificationStatus, setVatIdVerificationStatus] = useState('')
  const [validationStatus, setValidationStatus] = useState('')
  const [onRedirectUpdate, setOnRedirectUpdate] = useState(false)
  const [companyUpdateStatus, setCompanyUpdateStatus] = useState('')
  const [userUpdateStatus, setUserUpdateStatus] = useState('')
  const [passwordChange, setPasswordChange] = useState(false)

  // const [plansApi] = useCustomFetch(`${APIURL}/account_plan`, 'GET', true)
  const [accountSettingsApi] = useCustomFetch(
    `${APIURL}/account_settings`,
    'GET',
    true
  )
  const [userSettingsApi] = useCustomFetch(
    `${APIURL}/user_settings?query=custom_id=@userid`,
    'GET',
    true
  )

  /* Add taxId */
  const [updateAccSettingsApi] = useCustomFetch(
    `${CUSTOM_ACTION_URL}/payment`,
    'PAYMENT',
    false,
    JSON.stringify({
      action: 'UPDATE_ACCOUNT_SETTINGS',
      data: accSettingsBodyData,
    })
  )

  useEffect(() => {
    if (accountSettings?.data) {
      const accSettings = accountSettings.data

      setAccountData(accSettings)
      setCompanyName(accSettings.company)
      setCompanyEmail(accSettings.billing_email)
      // setCompanyPlan(accSettings.account_plan)
      accSettings.countryCode && setCountryCode(accSettings.countryCode)
      accSettings.spanish_tax_exempt !== undefined &&
        setSpanishTaxExempt(accSettings.spanish_tax_exempt)
      if (accSettings.vat_number) {
        setPrevVatNo(accSettings.vat_number)
        setVatNo(accSettings.vat_number)
        accSettings.vatId_verification_status &&
          setVatIdVerificationStatus(accSettings.vatId_verification_status)
      }
    }
  }, [accountSettings])

  useEffect(() => {
    if (accSettingsBodyData.id) {
      updateAccSettingsApi.setRefetch(true)
    }
  }, [accSettingsBodyData])

  /* useEffect(() => {
    if (!isEmpty(plansApi.response)) {
      if (plansApi.response.result) {
        const items = plansApi.response.response.items

        if (items.length && items[0].id) {
          setPlansData(items)
        }
      } else {
        console.log('plans Api Failed')
      }
    }
  }, [plansApi.response]) */

  /* useEffect(() => {
    if (plansApi.error !== null) {
      console.log('plans Api Failed')
    }
  }, [plansApi.error]) */

  const ApiErrorHandler = (message = '') => {
    setLoading(false)
    message.error(message)
  }

  useEffect(() => {
    if (!isEmpty(accountSettingsApi.response)) {
      if (accountSettingsApi.response.result) {
        if (
          accountSettingsApi.response.response.items.length &&
          accountSettingsApi.response.response.items[0].id
        ) {
          const responseData =
            accountSettingsApi.response.response.items[0].data

          dispatch(setAccountSettingsData({ data: responseData }))
          setAccountId(accountSettingsApi.response.response.items[0].id)
          setAccountData(responseData)
          responseData.company && setCompanyName(responseData.company)
          responseData.billing_email &&
            setCompanyEmail(responseData.billing_email)
          // responseData.account_plan && setCompanyPlan(responseData.account_plan)
          responseData.countryCode && setCountryCode(responseData.countryCode)
          responseData.phone_number && setPhoneNumber(responseData.phone_number)
          responseData.postal_code && setPostalCode(responseData.postal_code)
          responseData.address && setAddress(responseData.address)
          responseData.spanish_tax_exempt !== undefined &&
            setSpanishTaxExempt(responseData.spanish_tax_exempt)
          if (responseData.vat_number) {
            setPrevVatNo(responseData.vat_number)
            setVatNo(responseData.vat_number)
            responseData.vatId_verification_status &&
              setVatIdVerificationStatus(responseData.vatId_verification_status)
          }
        }
      } else {
        ApiErrorHandler(
          getTranslatedText(translations, language, 'account_settings_failed')
        )
      }
    }
  }, [accountSettingsApi.response])

  useEffect(() => {
    if (accountSettingsApi.error !== null) {
      ApiErrorHandler(
        getTranslatedText(translations, language, 'account_settings_failed')
      )
    }
  }, [accountSettingsApi.error])

  useEffect(() => {
    if (!isEmpty(userSettingsApi.response)) {
      if (userSettingsApi.response.result) {
        setLoading(false)
        if (
          userSettingsApi.response.response.items.length &&
          userSettingsApi.response.response.items[0].id
        ) {
          const responseData = userSettingsApi.response.response.items[0].data

          setUserData(responseData)
          setUserId(userSettingsApi.response.response.items[0].id)
          responseData.user_name && setUserName(responseData.user_name)
          responseData.user_email && setUserEmail(responseData.user_email)
          responseData.language && setPersonalLanguage(responseData.language)
        }
      } else {
        ApiErrorHandler(
          getTranslatedText(translations, language, 'user_settings_failed')
        )
      }
    }
  }, [userSettingsApi.response])

  useEffect(() => {
    if (userSettingsApi.error !== null) {
      ApiErrorHandler(
        getTranslatedText(translations, language, 'user_settings_failed')
      )
    }
  }, [userSettingsApi.error])

  useEffect(() => {
    if (countryCode) {
      if (EUROPIAN_UNION_COUNTRIES.some((country) => country === countryCode)) {
        setTaxFieldEnabled(true)
      } else {
        setTaxFieldEnabled(false)
      }
    }
  }, [countryCode])

  useEffect(() => {
    if (
      updateAccSettingsApi.response &&
      updateAccSettingsApi.response.status === true
    ) {
      setCompanyLoading(false)
      dispatch(setCompanySettingsChanged(false))
      message.success(
        getTranslatedText(
          translations,
          language,
          'account_settings_updated_sucessfully'
        )
      )
      accountSettingsApi.setRefetch(true)
      if (updateAccSettingsApi.response.response?.errorMsg?.length) {
        let data = accountData

        // dispatch(setAccountSettingsData({ data: data }))
        message.error(
          updateAccSettingsApi.response.response.errorMsg[0]?.raw?.message
        )
      }
    } else if (
      updateAccSettingsApi.response &&
      updateAccSettingsApi.response.status === false &&
      updateAccSettingsApi.response.error
    ) {
      setCompanyLoading(false)
      accountSettingsApi.setRefetch(true)
      message.error(
        getTranslatedText(
          translations,
          language,
          'error_updating_user_settings'
        )
      )
    }
  }, [updateAccSettingsApi.response])

  const onTabChange = (key) => {
    setActiveKey(key)
  }

  const renderLoader = () => {
    return (
      <div className="spin-style">
        <Spinner spinning={true} />
      </div>
    )
  }

  const personalTabSaveHandler = () => {
    if (userName.trim().length === 0) {
      setOnRedirectUpdate(false)
      message.warning(
        getTranslatedText(translations, language, 'enter_username')
      )
    } else if (userId.length && userData !== null) {
      setUserLoading(true)
      updateUser()
    }
  }

  const companyTabSaveHandler = () => {
    const validator = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    // eslint-disable-next-line max-len
    const phoneNumberValidator = /^\+{0,1}([\-\. ])?(\(?\d{0,3}\))?([\-\. ])?\(?\d{0,7}\)?([\-\. ])?\d{3}([\-\. ])?\d{4}?$/

    if (companyName.trim().length === 0) {
      message.warning(
        getTranslatedText(translations, language, 'please_enter_company_name')
      )
    } else if (companyEmail.trim().length === 0) {
      message.warning(
        getTranslatedText(translations, language, 'enter_useremail')
      )
    } else if (!validator.test(companyEmail)) {
      message.warning(
        getTranslatedText(translations, language, 'enter_valid_email')
      )
    } else if (address.trim().length === 0) {
      message.warning(
        getTranslatedText(translations, language, 'enter_valid_address')
      )
    } else if (
      postalCode.trim().length === 0 ||
      postalCode.trim().length > 15
    ) {
      message.warning(
        getTranslatedText(translations, language, 'enter_valid_postal_code')
      )
    } else if (
      phoneNumber.trim().length === 0 ||
      !phoneNumberValidator.test(phoneNumber)
    ) {
      message.warning(
        getTranslatedText(translations, language, 'enter_valid_phone_number')
      )
    } else if (accountId.length && accountData !== null) {
      setCompanyLoading(true)
      updateCompany()
    }
  }

  const postUserUpdateSuccess = () => {
    if (onRedirectUpdate) {
      updateCompany()
    } else {
      message.success(
        getTranslatedText(
          translations,
          language,
          'user_settings_updated_sucessfully'
        )
      )
    }
  }

  const postUserUpdateError = () => {
    if (onRedirectUpdate) {
      setOnRedirectUpdate(false)
    } else {
      message.error(
        getTranslatedText(
          translations,
          language,
          'error_updating_user_settings'
        )
      )
    }
  }

  useEffect(() => {
    if (userUpdateStatus.length) {
      userUpdateStatus === 'success'
        ? postUserUpdateSuccess()
        : postUserUpdateError()
    }
  }, [userUpdateStatus])

  const postCompanyUpdateSuccess = () => {
    setOnRedirectUpdate(false)
    if (onRedirectUpdate) {
      dispatch(setTriggerSettingsSave(false))
      dispatch(setPersonalSettingsChanged(false))
      dispatch(setCompanySettingsChanged(false))
      handlePageSwitching()
      message.success(
        getTranslatedText(
          translations,
          language,
          'settings_updated_succesfully'
        )
      )
    } else {
      message.success(
        getTranslatedText(
          translations,
          language,
          'account_settings_updated_sucessfully'
        )
      )
    }
  }

  const postCompanyUpdateError = () => {
    setOnRedirectUpdate(false)
    message.error(
      getTranslatedText(
        translations,
        language,
        'error_updating_account_settings'
      )
    )
  }

  useEffect(() => {
    if (companyUpdateStatus.length) {
      companyUpdateStatus === 'success'
        ? postCompanyUpdateSuccess()
        : postCompanyUpdateError()
    }
  }, [companyUpdateStatus])

  const updateUser = () => {
    const bodyData = userData

    bodyData.user_name = userName
    bodyData.language = personalLanguage

    const url = `${APIURL}/user_settings/${userId}?sync=yes`
    const headers = {
      headers: {
        Authorization: `${getItem(TOKEN_TYPE)} ${getItem(ID_TOKEN)}`,
        'Content-Type': 'application/json',
        'x-api-key': API_MANAGER_KEY,
      },
      method: 'PATCH',
      body: JSON.stringify({ id: userId, data: bodyData }),
    }

    const userUpdateResultHandler = () => {
      setUserLoading(false)
      dispatch(setUserLanguage(personalLanguage))
      dispatch(setPersonalSettingsChanged(false))
      setUserUpdateStatus('success')
    }

    const userUpdateErrortHandler = () => {
      setUserLoading(false)
      setUserUpdateStatus('error')
    }

    fetch(url, headers)
      .then((res) => res.json())
      .then((res) =>
        res.result ? userUpdateResultHandler() : userUpdateErrortHandler()
      )
      .catch(() => userUpdateErrortHandler())
  }

  const updateCompany = () => {
    const bodyData = accountData
    let isValid = false

    // if (vatNo && countryCode) {
    isValid = validateCountryAndVatNo(countryCode)
    // }

    if (isValid) {
      bodyData.account_plan = {}
      bodyData.billing_email = companyEmail
      bodyData.company = companyName
      bodyData.countryCode = countryCode
      bodyData.address = address
      bodyData.postal_code = postalCode
      bodyData.phone_number = phoneNumber
      bodyData.spanish_tax_exempt = spanishTaxExempt
      bodyData.vat_number = vatNo
      // bodyData.tax_id = bodyData.tax_id || ''

      const url = `${APIURL}/account_settings/${accountId}?sync=yes`
      const headers = {
        headers: {
          Authorization: `${getItem(TOKEN_TYPE)} ${getItem(ID_TOKEN)}`,
          'Content-Type': 'application/json',
          'x-api-key': API_MANAGER_KEY,
        },
        method: 'PATCH',
        body: JSON.stringify({ id: accountId, data: bodyData }),
      }

      const accountUpdateResultHandler = async () => {
        setCompanyLoading(false)
        setCompanyUpdateStatus('success')
        dispatch(setAccountSettingsData({ data: bodyData }))
        dispatch(setCompanySettingsChanged(false))
        accountSettingsApi.setRefetch(true)
      }

      const accountUpdateErrortHandler = () => {
        setCompanyLoading(false)
        setCompanyUpdateStatus('error')
      }

      if (
        vatNo === prevVatNo ||
        (vatNo === '' &&
          (bodyData.tax_id === '' || bodyData.tax_id === undefined))
      ) {
        fetch(url, headers)
          .then((res) => res.json())
          .then((res) =>
            res.result
              ? accountUpdateResultHandler()
              : accountUpdateErrortHandler()
          )
          .catch(() => accountUpdateErrortHandler())
      } else {
        if (vatNo) {
          bodyData.vatId_verification_status = 'pending'
          setVatIdVerificationStatus('pending')
        } else {
          bodyData.vatId_verification_status = ''
          setVatIdVerificationStatus('')
        }
        setAccSettingsBodyData({
          id: accountId,
          data: bodyData,
          customerId: userStripeData.stripe_id,
          isExist: bodyData.tax_id ? true : false,
        })
      }
    } else {
      setCompanyLoading(false)

      return
    }
  }

  const renderPersonalTabElements = () => {
    if (loading) {
      return renderLoader()
    } else {
      return (
        <div className="container">
          <div
            className="setting-tab-children"
            style={{ height: '93%' }}
          >
            <div className="setting-tab-label">
              {getTranslatedText(translations, language, 'name')}
            </div>
            <div className="setting-tab-element">
              <Input
                value={userName}
                onChange={(event) => {
                  setUserName(event.target.value)
                  dispatch(setPersonalSettingsChanged(true))
                }}
              />
            </div>
            <div className="setting-tab-label">
              {getTranslatedText(translations, language, 'username')}
            </div>
            <div
              className="setting-tab-element"
              style={{ width: '100%', display: 'flex', alignItems: 'center' }}
            >
              <div className="settings-uneditable-div" style={{ width: 250 }}>
                {userEmail}
              </div>
              <div>
                <PttButton
                  style={{ marginLeft: 15 }}
                  type="primary"
                  onClick={() => setPasswordChange(true)}
                >
                  {getTranslatedText(translations, language, 'change_password')}
                </PttButton>
              </div>
            </div>
            <div className="setting-tab-label">
              {getTranslatedText(translations, language, 'language')}
            </div>
            <div className="setting-tab-element">
              <Select
                className="setting-tab-dropdown"
                value={personalLanguage}
                onChange={(value) => {
                  setPersonalLanguage(value)
                  dispatch(setPersonalSettingsChanged(true))
                }}
              >
                {availableLanguages.map((langs) => (
                  <Select.Option key={langs.code} value={langs.code}>
                    <div className="setting-country-dd-body">
                      <FlagIcon code={langs.exactCode} size={24} />
                      <div className="setting-country-dd-text">
                        {langs.name}
                      </div>
                    </div>
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>
          <div>
            <PttButton
              type="primary"
              loading={userLoading || onRedirectUpdate}
              disabled={userLoading || onRedirectUpdate}
              onClick={personalTabSaveHandler}
            >
              {getTranslatedText(translations, language, 'save')}
            </PttButton>
          </div>
        </div>
      )
    }
  }

  /* const planHandler = (value) => {
    dispatch(setCompanySettingsChanged(true))
    setCompanyPlan(plansData.find((plan) => plan.id === value))
  } */

  const onCountryChange = (countryCode) => {
    dispatch(setCompanySettingsChanged(true))
    setCountryCode(countryCode)
    // validateCountryAndVatNo(countryCode)
    setSpanishTaxExempt(false)
  }

  const validateCountryAndVatNo = (countryCode) => {
    if (
      vatNo &&
      EUROPIAN_UNION_COUNTRIES.some((country) => country === countryCode) &&
      ((countryCode === 'GR' && vatNo.substr(0, 2) !== 'EL') ||
        (countryCode !== 'GR' && vatNo.substr(0, 2) !== countryCode))
    ) {
      // setVatNo('')
      setVatIdVerificationStatus('')
      setValidationStatus('error')
      message.error(translations.invalidVat_Country[language])

      return false
    } else {
      setValidationStatus('')
      return true
    }
  }

  const onSpanishExcemptChange = (e) => {
    dispatch(setCompanySettingsChanged(true))
    setSpanishTaxExempt(e.target.checked)
  }

  const onVatNumberChange = (event) => {
    /* event.target.value ? setVatIdVerificationStatus('invalid') : */ setVatIdVerificationStatus(
      ''
    )
    dispatch(setCompanySettingsChanged(true))
    setVatNo(event.target.value)
  }

  const getTaxIdValidationStatus = () => {
    if (vatIdVerificationStatus === 'verified') {
      return (
        <Tooltip title={translations.valid[language]}>
          <CheckCircleTwoTone twoToneColor="#52c41a" />
        </Tooltip>
      )
    } else if (vatIdVerificationStatus === 'pending') {
      return (
        <Tooltip title={translations.vat_pending[language]}>
          <LoadingOutlined /*  twoToneColor="yellow" */ />
        </Tooltip>
      )
    } else if (vatIdVerificationStatus === 'unavailable') {
      return (
        <Tooltip title={translations.vat_unavailable[language]}>
          <ExclamationCircleTwoTone twoToneColor="red" />
        </Tooltip>
      )
    } else if (vatIdVerificationStatus === 'invalid') {
      return (
        <Tooltip title={translations.invalid[language]}>
          <CloseCircleTwoTone twoToneColor="red" />
        </Tooltip>
      )
    } else if (vatIdVerificationStatus === 'unverified') {
      return (
        <Tooltip title={translations.unverified[language]}>
          <InfoCircleOutlined twoToneColor="yellow" />
        </Tooltip>
      )
    } else if (vatIdVerificationStatus === '') {
      return <></>
    }
  }

  const renderCountryDropDown = () => {
    return (
      <div style={{ width: 250 }}>
        <ReactFlagsSelect
          searchable
          selectedSize={12}
          optionsSize={12}
          selected={countryCode}
          onSelect={onCountryChange}
          disabled={companySettingsPermission !== FULL_ACCESS}
          placeholder={getTranslatedText(
            translations,
            language,
            'company_country'
          )}
        />
      </div>
    )
  }

  const renderCompanyName = () => {
    if (companySettingsPermission === FULL_ACCESS) {
      return (
        <Input
          value={companyName}
          onChange={(event) => {
            dispatch(setCompanySettingsChanged(true))
            setCompanyName(event.target.value)
          }}
        />
      )
    } else {
      return <UnEditableSection value={companyName} />
    }
  }

  const renderBillingEmail = () => {
    if (companySettingsPermission === FULL_ACCESS) {
      return (
        <Input
          value={companyEmail}
          onChange={(event) => {
            dispatch(setCompanySettingsChanged(true))
            setCompanyEmail(event.target.value)
          }}
        />
      )
    } else {
      return <UnEditableSection value={companyEmail} />
    }
  }

  const renderVatNo = () => {
    if (companySettingsPermission === FULL_ACCESS) {
      return (
        <Input
          onChange={onVatNumberChange}
          value={vatNo}
          suffix={getTaxIdValidationStatus()}
        />
      )
    } else {
      return <UnEditableSection value={vatNo} />
    }
  }

  const renderAddress = () => {
    if (companySettingsPermission === FULL_ACCESS) {
      return (
        <Input
          value={address}
          onChange={(event) => {
            dispatch(setCompanySettingsChanged(true))
            setAddress(event.target.value)
          }}
        />
      )
    } else {
      return <UnEditableSection value={address} />
    }
  }

  const renderPostalCode = () => {
    if (companySettingsPermission === FULL_ACCESS) {
      return (
        <Input
          value={postalCode}
          onChange={(event) => {
            dispatch(setCompanySettingsChanged(true))
            if (event.target.value.length <= 15) {
              setPostalCode(event.target.value)
            }
          }}
        />
      )
    } else {
      return <UnEditableSection value={postalCode} />
    }
  }

  const renderPhoneNumber = () => {
    if (companySettingsPermission === FULL_ACCESS) {
      return (
        <Input
          value={phoneNumber}
          onChange={(event) => {
            dispatch(setCompanySettingsChanged(true))
            if (event.target.value.length <= 15) {
              setPhoneNumber(event.target.value)
            }
          }}
        />
      )
    } else {
      return <UnEditableSection value={phoneNumber} />
    }
  }

  const renderCompanySave = () => {
    if (companySettingsPermission === FULL_ACCESS) {
      return (
        <div>
          <PttButton
            type="primary"
            loading={companyLoading || onRedirectUpdate}
            disabled={companyLoading || onRedirectUpdate}
            onClick={companyTabSaveHandler}
          >
            {getTranslatedText(translations, language, 'save')}
          </PttButton>
        </div>
      )
    } else {
      return <Fragment />
    }
  }


  const renderCompanyTabElements = () => {
    if (loading) {
      return renderLoader()
    } else {
      return (
        <div className="container">
          <div
            className="setting-tab-children"
            style={{
              height: companySettingsPermission === FULL_ACCESS ? '93%' : '100%',
            }}
          >
            <div className="setting-tab-label">
              {getTranslatedText(translations, language, 'userdetail_company')}
            </div>
            <div className="setting-tab-element">{renderCompanyName()}</div>
            <div className="setting-tab-label">
              {getTranslatedText(translations, language, 'billing_email')}
            </div>
            <div className="setting-tab-element">{renderBillingEmail()}</div>
            <div className="setting-tab-label">
              {getTranslatedText(translations, language, 'company_country')}
            </div>
            <div className="setting-tab-element" style={{ display: 'inline' }}>
              {renderCountryDropDown()}
              {countryCode === 'ES' && (
                <div>
                  <Checkbox
                    onChange={onSpanishExcemptChange}
                    checked={spanishTaxExempt}
                    className="spanish-excempt-checkbx"
                    disabled={companySettingsPermission !== FULL_ACCESS}
                  >
                    {getTranslatedText(
                      translations,
                      language,
                      'spain_tax_excempt_area'
                    )}
                  </Checkbox>
                </div>
              )}
              {isTaxFieldEnabled && (
                <>
                  <div className="setting-tab-label">
                    {getTranslatedText(translations, language, 'tax_number')}
                  </div>
                  <div className="setting-tab-element">
                    <Form.Item validateStatus={validationStatus}>
                      {renderVatNo()}
                    </Form.Item>
                  </div>
                </>
              )}
              <div className="setting-tab-label">
                {getTranslatedText(translations, language, 'address')}
              </div>
              <div className="setting-tab-element-address">
                {renderAddress()}
              </div>
              <div className="setting-tab-label">
                {getTranslatedText(translations, language, 'postal_code')}
              </div>
              <div className="setting-tab-element">{renderPostalCode()}</div>
              <div className="setting-tab-label">
                {getTranslatedText(translations, language, 'phone_number')}
              </div>
              <div className="setting-tab-element">{renderPhoneNumber()}</div>
            </div>
            {/* <div className="setting-tab-label">
              {getTranslatedText(translations, language, 'plans')}
            </div> */}
            {/* <div className="setting-tab-element">
              <Select
                className="setting-tab-dropdown"
                value={companyPlan?.id}
                onChange={planHandler}
              >
                {plansData.map((plans) => {
                  if (plans.enable) {
                    return (
                      <Select.Option key={plans.id} value={plans.id}>
                        {plans.data.label[language]}
                      </Select.Option>
                    )
                  } else {
                    return <Fragment />
                  }
                })}
              </Select>
            </div> */}
          </div>
          {renderCompanySave()}
        </div>
      )
    }
  }

  const handlePageSwitching = () => {
    if (settingsSavePostUrl === '/login') {
      removeTokens()
    }

    history.push({ pathname: settingsSavePostUrl })
  }

  const saveAndRedirect = () => {
    const validator = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    const item = localStorage.getItem('IsClientPage')

    dispatch(setTriggerSettingsSave(false))
    if (item !== 'yes' && userName.trim().length === 0) {
      setOnRedirectUpdate(false)
      message.warning(
        getTranslatedText(translations, language, 'enter_username')
      )
    } else if (companyName.trim().length === 0) {
      message.warning(
        getTranslatedText(translations, language, 'please_enter_company_name')
      )
    } else if (companyEmail.trim().length === 0) {
      message.warning(
        getTranslatedText(translations, language, 'enter_useremail')
      )
    } else if (!validator.test(companyEmail)) {
      message.warning(
        getTranslatedText(translations, language, 'enter_valid_email')
      )
    } else {
      setOnRedirectUpdate(true)
      if (item === 'yes') {
        companyTabSaveHandler()
      } else {
        personalTabSaveHandler()
        companyTabSaveHandler()
      }
    }
  }

  const confirmRedirect = () => {
    dispatch(setTriggerSettingsSave(false))
    dispatch(setPersonalSettingsChanged(false))
    dispatch(setCompanySettingsChanged(false))
    handlePageSwitching()
  }

  const abortRedirect = () => {
    dispatch(setTriggerSettingsSave(false))
  }

  const renderPersonalTab = () => {
    if (appFromClient) {
      return <React.Fragment />
    }

    return (
      <Tabs.TabPane
        tab={getTranslatedText(translations, language, 'settings_personal_tab')}
        key="1"
      >
        {renderPersonalTabElements()}
      </Tabs.TabPane>
    )
  }

  const renderCompanyTab = () => {
    if (companySettingsPermission !== NO_ACCESS) {
      return (
        <Tabs.TabPane
          tab={getTranslatedText(
            translations,
            language,
            'settings_company_tab'
          )}
          key="2"
        >
          {renderCompanyTabElements()}
        </Tabs.TabPane>
      )
    } else {
      return <React.Fragment />
    }
  }

  const renderAccessTab = () => {
    if (accessUsersPermission !== NO_ACCESS) {
      return (
        <Tabs.TabPane
          tab={getTranslatedText(translations, language, 'settings_user_tab')}
          key="3"
        >
          <UserTab />
        </Tabs.TabPane>
      )
    } else {
      return <React.Fragment />
    }
  }

  return (
    <React.Fragment>
      <div className="setting-body-container">
        <Tabs
          activeKey={activeKey}
          className="setting-tab"
          onChange={onTabChange}
        >
          {renderPersonalTab()}
          {renderCompanyTab()}
          {renderAccessTab()}
        </Tabs>
      </div>
      <ConfirmPopup
        cancelText={translations.no[language]}
        closable={true}
        confirmText={translations.settings_unsaved_changes[language]}
        okayText={translations.yes[language]}
        onCancel={confirmRedirect}
        onClose={abortRedirect}
        onOkay={saveAndRedirect}
        visiblity={triggerSettingsSave}
      >
        {translations.settings_unsaved_changes_info[language]}
      </ConfirmPopup>
      <ChangePassword
        onClose={() => setPasswordChange(false)}
        visiblity={passwordChange}
      />
    </React.Fragment>
  )
}

export default Settings
