import React from 'react'
import { Button, Progress, Result } from 'antd'
import { useSelector } from 'react-redux'
import './configaccount.css'

const ConfigAccount = ({
  hasError,
  percent,
  status,
  showFinalLicenseCount = 0,
  showFinalLicenseError,
  renderConfigButton,
}) => {
  const sampleReducer = useSelector(({ sampleReducer }) => sampleReducer)
  const translations = sampleReducer.translations || {}
  const language = sampleReducer.language || 'en'

  const onLinkClick = () => {
    window.open(
      'https://portal.office.com/Adminportal/Home?#/licensedetailpage/18181a46-0d4e-45cd-891e-60aabd171b4e',
      '_blank'
    )
  }

  const renderElement = (entity) => {
    return (
      <div className="config_account_element">
        {translations[entity][language]}
      </div>
    )
  }

  const renderWarningBody = () => {
    return (
      <Result
        status="warning"
        title={
          <React.Fragment>
            <div className="config_account_warn_top_container">
              <span>{translations.render_warning_header_info[language]}</span>
              {renderElement('render_warning_info_1')}
              {/* {renderElement('render_warning_info_2')} */}
            </div>
            {/* <div className="config_account_warn_bottom_container">
              {renderElement('please')}
              <Button
                className="config_account_link"
                onClick={onLinkClick}
                type="link"
              >
                {translations.render_warning_info_button[language]}
              </Button>
              {renderElement('render_warning_info_3')}
            </div> */}
            <div className="retry">{renderConfigButton()}</div>
          </React.Fragment>
        }
      />
    )
  }

  const renderProgress = () => {
    const wizard_progress = require('../../../assets/images/wizard_in_progress.svg')

    return (
      <div className="config_account_progress_body">
        <img alt="" width={500} height={300} src={wizard_progress} />
        {renderElement('render_progress_info_1')}
        {renderElement('render_progress_info_2')}
        <div className="config_account_progress_bar">
          <Progress
            className="config_account_progress"
            percent={percent}
            status={status}
          />
        </div>
        {status === 'success' && renderElement('render_progress_info_3')}
        {renderConfigButton()}
      </div>
    )
  }

  const renderFinalLicenseBody = () => {
    return (
      <Result
        status="warning"
        title={
          <React.Fragment>
            <div className="config_account_warn_top_container">
              <span>{`${showFinalLicenseCount} ${translations.officee_license_required[language]}`}</span>
              {renderElement('render_warning_info_1')}
              {renderElement('render_warning_info_2')}
            </div>
            <div className="config_account_warn_bottom_container">
              {renderElement('please')}
              <Button
                className="config_account_link"
                onClick={onLinkClick}
                type="link"
              >
                {translations.render_warning_info_button[language]}
              </Button>
              {renderElement('render_warning_info_3')}
            </div>
          </React.Fragment>
        }
      />
    )
  }

  const renderBody = () => {
    if (hasError) {
      return renderWarningBody()
    } else if (showFinalLicenseError) {
      return renderFinalLicenseBody()
    } else {
      return renderProgress()
    }
  }

  return renderBody()
}

export default ConfigAccount
