import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getItem } from '../utils/storage'
import useCustomFetch from './UseCustomFetch'
import {
  APIURL,
  API_MANAGER_KEY,
  CUSTOM_ACTION_URL,
  ID_TOKEN,
  TOKEN_TYPE,
} from '../utils/constants'
import { getChangeUserMailTemplate } from '../components/MsTeams/msteamsutils'

const UseUpdateVoiceUser = (callBack = () => {}) => {
  const { accountSettings, userSettings, userAccounId } = useSelector(
    ({ sampleReducer }) => ({
      accountSettings: sampleReducer.accountSettings,
      userAccounId: sampleReducer.userAccounId,
      userSettings: sampleReducer.userSettings,
    })
  )

  const [connectorId, setConnectorId] = useState('')
  const [id, setId] = useState('')
  const [execute, setExecute] = useState(false)
  const [loading, setLoading] = useState(false)
  const [phoneBody, setPhoneBody] = useState(null)
  const [userBody, setUserBody] = useState(null)
  const [oldPhoneNumber, setOldPhoneNumber] = useState('')
  const [newPhoneNumber, setNewPhoneNumber] = useState('')
  const [userObject, setUserObject] = useState({})
  const [mailNeeded, setMailNeeded] = useState(false)

  const getHeader = {
    headers: {
      Authorization: `${getItem(TOKEN_TYPE)} ${getItem(ID_TOKEN)}`,
      'x-api-key': API_MANAGER_KEY,
    },
    method: 'GET',
  }

  const [phoneApi] = useCustomFetch(
    `${APIURL}/voice_user-phone/${id}?sync=yes`,
    'PUT',
    false,
    phoneBody
  )

  const [userApi] = useCustomFetch(
    `${APIURL}/voice_user/${id}?sync=yes`,
    'PATCH',
    false,
    userBody
  )

  const handleResponse = (message, data) => {
    setLoading(false)
    callBack({ status: message, data: data })
  }

  useEffect(() => {
    if (!isEmpty(userApi.response)) {
      if (userApi.response.result) {
        phoneApi.setRefetch(true)
      } else {
        handleResponse('error', userApi.response.error)
      }
    }
  }, [userApi.response])

  const getEmailHeader = (detailsResponse) => {
    return {
      headers: {
        Authorization: `${getItem(TOKEN_TYPE)} ${getItem(ID_TOKEN)}`,
        'x-api-key': API_MANAGER_KEY,
      },
      method: 'POST',
      body: JSON.stringify({
        action: 'REQUEST_TEAMS',
        data: {
          address: 'vijay@softsuave.com',
          template: getChangeUserMailTemplate(
            accountSettings,
            userSettings,
            {
              phonenumber: oldPhoneNumber,
              voiceuser: userObject,
            },
            {
              phonenumber: newPhoneNumber,
              voiceuser: detailsResponse?.response?.items.length
                ? detailsResponse.response.items[0]
                : {},
            }
          ),
          subject: 'User changed',
          accountId: userAccounId,
          connectorId: connectorId,
          modifiedUsers: userObject,
        },
      }),
    }
  }

  useEffect(() => {
    if (!isEmpty(phoneApi.response)) {
      if (phoneApi.response.result) {
        fetch(`${APIURL}/connector-voice_user/${connectorId}`, getHeader)
          .then((relationResponse) => relationResponse.json())
          .then((relationResponse) => {
            fetch(`${APIURL}/voice_user/${id}`, getHeader)
              .then((detailsResponse) => detailsResponse.json())
              .then((detailsResponse) => {
                fetch(`${APIURL}/voice_user-phone/${id}`, getHeader)
                  .then((phoneResponse) => phoneResponse.json())
                  .then((phoneResponse) => {
                    if (mailNeeded) {
                      fetch(
                        `${CUSTOM_ACTION_URL}/payment`,
                        getEmailHeader(detailsResponse)
                      )
                        .then((emailRes) => emailRes.json())
                        .then((emailRes) => {
                          if (emailRes.status) {
                            handleResponse('success', {
                              relationResponse: relationResponse,
                              detailsResponse: detailsResponse,
                              phoneResponse: phoneResponse,
                              emailRes: emailRes,
                            })
                          } else {
                            handleResponse('error', emailRes)
                          }
                        })
                        .catch((emailErr) => {
                          handleResponse('error', emailErr)
                        })
                    } else {
                      handleResponse('success', {
                        relationResponse: relationResponse,
                        detailsResponse: detailsResponse,
                        phoneResponse: phoneResponse,
                      })
                    }
                  })
                  .catch((phoneError) => {
                    handleResponse('error', phoneError)
                  })
              })
              .catch((detailsError) => {
                handleResponse('error', detailsError)
              })
          })
          .catch((relationError) => {
            handleResponse('error', relationError)
          })
      } else {
        handleResponse('error', phoneApi.response.error)
      }
    }
  }, [phoneApi.response])

  useEffect(() => {
    if (userApi.error !== null) {
      handleResponse('error', userApi.error)
    }
  }, [userApi.error])

  useEffect(() => {
    if (phoneApi.error !== null) {
      handleResponse('error', phoneApi.error)
    }
  }, [phoneApi.error])

  useEffect(() => {
    if (execute) {
      setLoading(true)
      setExecute(false)
      userApi.setRefetch(true)
    }
  }, [execute])

  return [
    {
      loading,
      setConnectorId,
      setId,
      setExecute,
      setPhoneBody,
      setUserBody,
      setOldPhoneNumber,
      setMailNeeded,
      setNewPhoneNumber,
      setUserObject,
    },
  ]
}

export default UseUpdateVoiceUser
