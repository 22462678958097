// @flow

import React, { createRef, Fragment, useEffect, useState } from 'react'
import { Input, Tabs } from 'antd'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import './callflowsidebar.css'
import {
  getLabelByLang,
  getComponetValues,
  getValues,
  getNewArray,
} from '../../../../utils/helpers'
import GetComponent from './getcomponent'
import Spinner from '../../../../Spinner/spinner'
import * as actions from '../../../../actions/IncomingCallsAction'
import { COMMON_IMG_URL } from '../../../../utils/constants'
import { NODE_TYPES } from '../../../NodeFieldTypes/nodetypes'

var components = []
var firstTabComponents = []
var secondTabComponents = []

type Props = {
  agents: $ReadOnlyArray<*>,
  callFlow: $ReadOnlyArray<*>,
  callFlowId: string,
  callFlowNodeId: string,
  callFlowNodes: $ReadOnlyArray<*>,
  loading?: boolean,
  nodeList: $ReadOnlyArray<*>,
  nodeDefinition: $ReadOnlyArray<*>,
  setCallFlowNodes: (any) => mixed,
  setRedoArray: (any) => mixed,
  setUndoArray: (any) => mixed,
  setAutoUpdateCallFlow: (any) => mixed,
  undoArray: $ReadOnlyArray<*>,
  translations: Object,
  language: string,
  ttsLanguages: $ReadOnlyArray<*>,
  ttsSpeakers: $ReadOnlyArray<*>,
}

const CallFlowSideBar = (props: Props) => {
  const { TabPane } = Tabs
  const {
    callFlowNodeId,
    callFlowNodes,
    loading = false,
    nodeList,
    nodeDefinition,
    undoArray,
    translations,
    language,
    ttsLanguages,
    ttsSpeakers,
    setAutoUpdateCallFlow
  } = props
  const [nodeName, setNodeName] = useState('')

  useEffect(() => {
    if (callFlowNodeId.length && callFlowNodes.length) {
      const currentNode = callFlowNodes.filter(
        (node) => node.call_flow_node_id === callFlowNodeId
      )

      setNodeName(currentNode.length ? currentNode[0].name : '')
    }
  }, [callFlowNodeId, callFlowNodes])

  const getInitialValue = (type, name) => {
    const callFlowData = callFlowNodes.filter(
      ({ call_flow_node_id }) => call_flow_node_id === callFlowNodeId
    )
    const values =
      callFlowData.length && callFlowData[0].values
        ? callFlowData[0].values
        : []

    const value = values.length
      ? values.filter((data) => data.name === name && data.type === type)
      : []

    const getReturnValue = () => {
      if (value.length) {
        if (
          value[0].type === NODE_TYPES.mapNumberToNode ||
          value[0].type === NODE_TYPES.mapNumberOrStringToNode ||
          value[0].type === NODE_TYPES.audio ||
          value[0].type === NODE_TYPES.announcement
        ) {
          return value[0].key_value_pair
        } else if (value[0].type === NODE_TYPES.voiceUser) {
          return value[0].value_array
        } else {
          return value[0].value
        }
      }

      return type === NODE_TYPES.mapNumberToNode ||
        type === NODE_TYPES.mapNumberOrStringToNode ||
        type === NODE_TYPES.voiceUser ||
        type === NODE_TYPES.announcement ||
        type === NODE_TYPES.audio
        ? []
        : ''
    }

    return getReturnValue()
  }

  const getComponentProps = (element, language) => {
    switch (element.type) {
      case NODE_TYPES.longText:
        return {
          initialValue: getInitialValue(NODE_TYPES.longText, element.name),
          label: element.label[language],
          maxLentgh: Number(element.max_lentgh),
          mode: 'Edit',
          autoSaveHandler: autoSaveHandler,
          translations: translations,
          language: language,
        }

      case NODE_TYPES.textToSpeach:
        return {
          initialValue: getInitialValue(NODE_TYPES.textToSpeach, element.name),
          label: element.label[language],
          maxLentgh: Number(element.max_lentgh),
          mode: 'Edit',
          autoSaveHandler: autoSaveHandler,
          translations: translations,
          language: language,
        }

      case NODE_TYPES.shortText:
        return {
          initialValue: getInitialValue(NODE_TYPES.shortText, element.name),
          label: element.label[language],
          maxLentgh: Number(element.max_lentgh),
          mode: 'Edit',
          autoSaveHandler: autoSaveHandler,
          translations: translations,
          language: language,
        }

      case NODE_TYPES.audio:
        return {
          initialValue: getInitialValue(NODE_TYPES.audio, element.name),
          label: element.label[language],
          mode: 'Edit',
          autoSaveHandler: autoSaveHandler,
          translations: translations,
          language: language,
        }

      case NODE_TYPES.number:
        return {
          initialValue: getInitialValue(NODE_TYPES.number, element.name),
          label: element.label[language],
          maxLentgh: Number(element.max_lentgh),
          mode: 'Edit',
          autoSaveHandler: autoSaveHandler,
          translations: translations,
          language: language,
        }

      case NODE_TYPES.mapNumberToNode:
        return {
          label: element.label[language],
          menuOptions: getInitialValue(
            NODE_TYPES.mapNumberToNode,
            element.name
          ),
          mode: 'Edit',
          autoSaveHandler: autoSaveHandler,
          translations: translations,
          language: language,
        }

      case NODE_TYPES.mapNumberOrStringToNode:
        return {
          label: element.label[language],
          menuOptions: getInitialValue(
            NODE_TYPES.mapNumberOrStringToNode,
            element.name
          ),
          mode: 'Edit',
          autoSaveHandler: autoSaveHandler,
          translations: translations,
          language: language,
        }

      case NODE_TYPES.node:
        return {
          label: element.label[language],
          selectedValue: getInitialValue(NODE_TYPES.node, element.name),
          mode: 'Edit',
          autoSaveHandler: autoSaveHandler,
          translations: translations,
          language: language,
        }

      case NODE_TYPES.callflow:
        return {
          label: element.label[language],
          selectedValue: getInitialValue(NODE_TYPES.callflow, element.name),
          mode: 'Edit',
          autoSaveHandler: autoSaveHandler,
          translations: translations,
          language: language,
        }

      case NODE_TYPES.apimethod:
        return {
          label: element.label[language],
          selectedValue: getInitialValue(NODE_TYPES.apimethod, element.name),
          mode: 'Edit',
          autoSaveHandler: autoSaveHandler,
          translations: translations,
          language: language,
        }

      case NODE_TYPES.switch:
        return {
          label: element.label[language],
          selectedValue: getInitialValue(NODE_TYPES.switch, element.name),
          mode: 'Edit',
          autoSaveHandler: autoSaveHandler,
          translations: translations,
          language: language,
        }

      case NODE_TYPES.voiceUser:
        return {
          label: element.label[language],
          selectedValue: getInitialValue(NODE_TYPES.voiceUser, element.name),
          mode: 'Edit',
          autoSaveHandler: autoSaveHandler,
          translations: translations,
          language: language,
        }

      case NODE_TYPES.announcement:
        return {
          label: element.label[language],
          selectedValue: getInitialValue(NODE_TYPES.announcement, element.name),
          mode: 'Edit',
          autoSaveHandler: autoSaveHandler,
          translations: translations,
          language: language,
        }

      default:
        return {}
    }
  }

  const handleNameChange = (event) => {
    setNodeName(event.target.value)
  }

  const renderNameField = () => {
    return (
      <div key="name_cont">
        <div className="label-spacing" key="name_head">
          {translations.name[language]}
        </div>
        <div key="name_input">
          <Input
            onBlur={saveNodeName}
            onChange={handleNameChange}
            onPressEnter={saveNodeName}
            value={nodeName}
          />
        </div>
      </div>
    )
  }

  const saveNodeName = () => {
    updateNodes(updateNodeName(), false)
  }

  const renderComponent = (element) => {
    return (
      <GetComponent
        agents={props.agents}
        callFlow={props.callFlow}
        callFlowId={props.callFlowId}
        callFlowNodes={callFlowNodes}
        componentProps={getComponentProps(element, language)}
        key={`${element.name} ${element.type}`}
        keyId={`${element.name} ${element.type}`}
        keyName={element.name}
        nodeList={nodeList}
        ref={createRef()}
        type={element.type}
        ttsLanguages={ttsLanguages}
        ttsSpeakers={ttsSpeakers}
      />
    )
  }

  const renderContent = (tabs) => {
    if (tabs.length === 1) {
      components = []
      const fields = tabs[0].fields

      components.push(renderNameField())
      fields.length &&
        fields.forEach((element) => {
          components.push(renderComponent(element))
        })

      return <div className="CallFlowSideBar__NoTabsDiv">{components}</div>
    } else if (tabs.length === 2) {
      firstTabComponents = []
      secondTabComponents = []
      const firstTabFields = tabs[0].fields ? tabs[0].fields : []
      const secondTabFields = tabs[1].fields ? tabs[1].fields : []

      firstTabComponents.push(renderNameField())
      firstTabFields.length &&
        firstTabFields.forEach((element) => {
          firstTabComponents.push(renderComponent(element))
        })

      secondTabFields &&
        secondTabFields.forEach((element) => {
          secondTabComponents.push(renderComponent(element))
        })

      return (
        <Fragment>
          <Tabs className="CallFlowSideBar__Tabs" defaultActiveKey="1">
            <TabPane tab="Basic" key="1">
              {firstTabComponents}
            </TabPane>
            <TabPane tab="Advanced" key="2">
              {secondTabComponents}
            </TabPane>
          </Tabs>
        </Fragment>
      )
    } else {
      return <div> {translations.no_content[language]} </div>
    }
  }

  const updateNodeName = () => {
    return callFlowNodes.map((flow) => {
      if (flow.call_flow_node_id === callFlowNodeId) {
        flow.name = nodeName
      }

      return flow
    })
  }

  const updateNodeValues = (values) => {
    return callFlowNodes.map((flow) => {
      if (flow.call_flow_node_id === callFlowNodeId) {
        flow.name = nodeName
        flow.values = values
      }

      return flow
    })
  }

  const updateNodes = (nodesData, toSave) => {
    const undoData = getNewArray(undoArray)

    undoData.push(getNewArray(callFlowNodes))
    props.setCallFlowNodes(getNewArray(nodesData))
    props.setUndoArray(undoData)
    props.setRedoArray([])

    if (toSave) {
      setTimeout(() => {
        setAutoUpdateCallFlow(true)
      }, 100)
    }
  }

  const autoSaveHandler = (origin, toSave = false) => {
    if (components.length) {
      let value = []

      value = getComponetValues(components, value)

      if (value.length && callFlowNodes.length) {
        updateNodes(updateNodeValues(getValues(value)), toSave)
      }
    } else if (firstTabComponents.length || secondTabComponents.length) {
      let tabValue = []

      tabValue = getComponetValues(firstTabComponents, tabValue)
      tabValue = getComponetValues(secondTabComponents, tabValue)

      if (tabValue.length && callFlowNodes.length) {
        updateNodes(updateNodeValues(getValues(tabValue)), toSave)
      }
    }
  }

  const renderElements = () => {
    if (loading) {
      return (
        <div className="align-center full-height">
          <Spinner spinning={loading} />
        </div>
      )
    } else {
      const data = nodeDefinition.length ? nodeDefinition[0].data : null
      const id = nodeDefinition.length ? nodeDefinition[0].id : null
      const tabs = data && data.tabs && data.tabs.length ? data.tabs : null

      if (data && id && tabs) {
        const details = nodeDefinition[0].data ? nodeDefinition[0].data : {}
        const imgUrl = `${COMMON_IMG_URL}nodeicon/${details.icon}.png`
        const label = getLabelByLang(details.label, language)
        const description = getLabelByLang(details.description, language)

        return (
          <div className="CallFlowSideBar__Container">
            <div className="CallFlowSideBar__Describtion">
              <div className="CallFlowSideBar__Icon">
                <img
                  alt="Icon"
                  className="CallFlowSideBar__Iconstyle"
                  src={imgUrl}
                />
              </div>
              <div className="CallFlowSideBar__Label">{label}</div>
              <div className="CallFlowSideBar__Desc">{description}</div>
            </div>
            <div className="CallFlowSideBar__Elements">
              {renderContent(tabs)}
            </div>
          </div>
        )
      } else {
        return (
          <div className="align-center full-height">
            {translations.select_a_node_info[language]}
          </div>
        )
      }
    }
  }

  return <Fragment> {renderElements()} </Fragment>
}

const mapStateToProps = ({ incomingCallsReducer, sampleReducer }) => ({
  callFlow: incomingCallsReducer.callFlow || [],
  callFlowNodes: incomingCallsReducer.callFlowNodes || [],
  nodeList: incomingCallsReducer.nodeList || [],
  undoArray: incomingCallsReducer.undoArray || [],
  translations: sampleReducer.translations || {},
  language: sampleReducer.language,
  ttsLanguages: incomingCallsReducer.ttsLanguages || [],
  ttsSpeakers: incomingCallsReducer.ttsSpeakers || [],
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...actions }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(CallFlowSideBar)
