import { FULL_ACCESS } from './constants'

export const getDefaultLanguage = () => {
  return 'en'
}

export const getDefaultPermissions = () => {
  return {
    MICROSOFT_TEAMS: FULL_ACCESS,
    PHONE_NUMBERS: FULL_ACCESS,
    STATS: FULL_ACCESS,
    ADVANCED: FULL_ACCESS,
    BILLING: FULL_ACCESS,
    COMPANY_SETTINGS: FULL_ACCESS,
    ACCESS_USERS: FULL_ACCESS,
  }
}

export const getUserPermissions = (permissions) => {
  if (permissions.length) {
    let data = {}

    data = Object.assign(data, ...permissions)

    return {
      MICROSOFT_TEAMS: data['MICROSOFT_TEAMS'],
      PHONE_NUMBERS: data['PHONE_NUMBERS'],
      STATS: data['STATS'],
      ADVANCED: data['ADVANCED'],
      BILLING: data['BILLING'],
      COMPANY_SETTINGS: data['COMPANY_SETTINGS'],
      ACCESS_USERS: data['ACCESS_USERS'],
    }
  } else {
    return {
      MICROSOFT_TEAMS: FULL_ACCESS,
      PHONE_NUMBERS: FULL_ACCESS,
      STATS: FULL_ACCESS,
      ADVANCED: FULL_ACCESS,
      BILLING: FULL_ACCESS,
      COMPANY_SETTINGS: FULL_ACCESS,
      ACCESS_USERS: FULL_ACCESS,
    }
  }
}
