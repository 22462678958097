import React from 'react'
import { message, Tooltip } from 'antd'
import { isEmpty } from 'lodash'
import { DeleteOutlined } from '@ant-design/icons'
import useCustomFetch from '../../../Hooks/UseCustomFetch'
import { APIURL } from '../../../utils/constants'
import AudioPlayer from 'react-h5-audio-player'
import 'react-h5-audio-player/lib/styles.css'
import ConfirmPopup from '../../Popup/confirmpopup'
import './customdropzone.css'

var timerId = ''

const Audiobar = ({ deleteCb, details, translations, language }) => {
  const [id, setId] = React.useState('')
  const [name, setName] = React.useState('')
  const [audioUrl, setAudioUrl] = React.useState('')
  const [confirm, setConfirm] = React.useState(false)
  const [deleteLoading, setDeleteLoading] = React.useState(false)
  const [expireTime, setExpireTime] = React.useState('')
  const [audioApi] = useCustomFetch(`${APIURL}/audiofile/${id}`, 'GET', false)
  const [deleteApi] = useCustomFetch(
    `${APIURL}/audiofile/${id}`,
    'DELETE',
    false
  )

  React.useEffect(() => {
    const fileIdObj = details.find((detail) => detail.key === 'fileId')
    const fileNameObj = details.find((detail) => detail.key === 'fileName')

    setId(fileIdObj ? fileIdObj.value : '')
    setName(fileNameObj ? fileNameObj.value : '')
  }, [details])

  React.useEffect(() => {
    id.length && audioApi.setRefetch(true)
  }, [id])

  React.useEffect(() => {
    if (!isEmpty(audioApi.response)) {
      if (
        audioApi.response.result &&
        audioApi.response.response?.items.length
      ) {
        const audioDetail = audioApi.response.response.items[0]
        const splittedUrl = audioDetail.download.url.split('Expires=')
        const time = splittedUrl.length === 2 ? splittedUrl[1] : ''

        setExpireTime(time)
        setAudioUrl(audioDetail.download.url)
      }
    }
  }, [audioApi.response])

  const handleDeleteError = () => {
    setDeleteLoading(false)
    message.error(translations.audio_delete_error[language])
  }

  React.useEffect(() => {
    if (!isEmpty(deleteApi.response)) {
      if (deleteApi.response.result) {
        message.success(translations.audio_delete_success[language])
        setDeleteLoading(false)
        setConfirm(false)
        deleteCb()
      } else {
        handleDeleteError()
      }
    }
  }, [deleteApi.response])

  React.useEffect(() => {
    if (deleteApi.error !== null) {
      handleDeleteError()
    }
  }, [deleteApi.error])

  React.useEffect(() => {
    if (expireTime.length) {
      const minutes = new Date(Number(expireTime)).getMinutes()

      /* console.log(`setting audio url timeout in ${minutes} minutes`) */
      clearTimeout(timerId)
      timerId = setTimeout(() => {
        /* console.log('getting audio url again') */
        audioApi.setRefetch(true)
      }, minutes * 60000)
    }

    return function cleanup() {
      /* console.log('clearedTimeOut') */
      clearTimeout(timerId)
    }
  }, [expireTime])

  const deleteHandler = () => {
    setConfirm(true)
  }

  const deleteOkay = () => {
    deleteApi.setRefetch(true)
  }

  const deleteCancel = () => {
    setConfirm(false)
  }

  return (
    <div className="Audiobar-body">
      <div className="Audiobar-Detail">
        <div className="Audiobar-body-text">{name}</div>
        <div className="Audiobar-body-Icons">
          <Tooltip title={translations.delete[language]}>
            <DeleteOutlined
              className="Audiobar-body-Icon"
              onClick={deleteHandler}
            />
          </Tooltip>
        </div>
      </div>
      <div className="Audio-player-style">
        <AudioPlayer
          autoPlay={false}
          src={audioUrl}
          showSkipControls={false}
          showJumpControls={false}
          autoPlayAfterSrcChange={false}
        />
      </div>
      <ConfirmPopup
        cancelText={translations.cancel[language]}
        closable={true}
        confirmText={translations.audio_delete_title[language]}
        okayText={translations.delete[language]}
        onCancel={deleteCancel}
        onOkay={deleteOkay}
        visiblity={confirm}
        loading={deleteLoading}
      >
        <React.Fragment>
          {translations.callflow_delete_audio[language]}
        </React.Fragment>
      </ConfirmPopup>
    </div>
  )
}

export default Audiobar
