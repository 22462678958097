// @flow

import React, { Fragment, type Node } from 'react'
import { Modal } from 'antd'
import './popup.css'

type Props = {
  children?: Node,
  className?: string,
  closable?: boolean,
  onCancel?: () => void,
  visible?: boolean,
  destroyOnClose?: boolean,
  maskClosable?: boolean,
}

export const Header = (props: Props) => <Fragment>{props.children}</Fragment>
export const Body = (props: Props) => <Fragment>{props.children}</Fragment>
export const Footer = (props: Props) => <Fragment>{props.children}</Fragment>

Header.displayName = 'Header'
Body.displayName = 'Body'
Footer.displayName = 'Footer'

const ModalLayout = (props: Props) => {
  const {
    children,
    className,
    closable,
    onCancel,
    visible,
    destroyOnClose = false,
    maskClosable = true,
  } = props

  let header = ''
  let body = ''
  let footer = ''

  React.Children.forEach(children, (child) => {
    const { type, props } = child

    switch (type?.displayName) {
      case 'Header': {
        header = props.children
        break
      }
      case 'Body': {
        body = props.children
        break
      }
      case 'Footer': {
        footer = props.children
        break
      }
      default:
        break
    }
  })

  return (
    <Modal
      centered
      className={className ? `custom-modal ${className}` : 'custom-modal'}
      closable={closable}
      footer={footer}
      onCancel={onCancel}
      title={header}
      visible={visible}
      wrapClassName="custom-modalwrapper"
      destroyOnClose={destroyOnClose}
      maskClosable={maskClosable}
    >
      {body}
    </Modal>
  )
}

export default ModalLayout
