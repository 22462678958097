import React from 'react'
import { Breadcrumb } from 'antd'
import { useSelector } from 'react-redux'

const Pagination = ({
  currentRecord = 0,
  recordPerPage = 0,
  totalRecord = 0,
}) => {
  const { language, translations } = useSelector(({ sampleReducer }) => {
    return {
      language: sampleReducer.language,
      translations: sampleReducer.translations,
    }
  })

  const getStartRecordNumber = () => {
    if (currentRecord === 0) {
      return currentRecord
    } else if (currentRecord <= recordPerPage) {
      // 90 data / 100 per page
      return 1
    } else if (currentRecord % recordPerPage === 0 && currentRecord > recordPerPage - 1) {
      // whole number
      return currentRecord - (recordPerPage - 1)
    } else {
      return currentRecord - ((currentRecord % recordPerPage) - 1)
    }
  }

  const getStartPageNumber = () => {
    if (currentRecord === totalRecord) {
      return Math.ceil(totalRecord / recordPerPage)
    } else {
      return Math.floor(currentRecord / recordPerPage)
    }
  }

  const showPageDetails = () => {
    return (
      <div className='pagination'>
        <span className='page-data'>
          {`${
            translations.showing[language]
          } ${getStartRecordNumber()} - ${currentRecord} ${
            translations.of[language]
          } ${totalRecord}`}
        </span>
        <span className='page-data'>
          {`${translations.page[language]} ${getStartPageNumber()} ${
            translations.of[language]
          } ${Math.ceil(totalRecord / recordPerPage)}`}
        </span>
      </div>
    )
  }

  // eslint-disable-next-line no-console
  console.log({ currentRecord, totalRecord })

  return <React.Fragment>{showPageDetails()}</React.Fragment>
}

export default Pagination
