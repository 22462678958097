/* eslint-disable no-console */
// @flow

import React, { useState, useEffect, useMemo } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import useCustomFetch from '../../Hooks/UseCustomFetch'
import { CUSTOM_ACTION_URL } from '../../utils/constants'
import { convertToDollar } from '../../utils/helpers'
import AddCreditModal from '../Billing/AddCreditModal'
import MakePaymentModal from '../Billing/MakePaymentModal'
import PttButton from '../CustomButton/PttButton'
import MemoTable from '../MemoTable/memotable'
import { setPaymentList } from '../../actions/billingAction'
import './voiceUserCheckout.css'
import Spinner from '../../Spinner/spinner'
import { Spin } from 'antd'

type Props = {
  voiceUsers: $ReadOnlyArray<*>,
  phones: $ReadOnlyArray<*>,
  scrollObjectSmall: Object,
  scrollObjectBig: Object,
  enableCheckoutConfirm: (
    value: boolean,
    amount?: number,
    voiceAmount?: number,
    phoneAmount?: number
  ) => mixed,
}

const VoiceUserCheckout = ({
  callingPlans,
  enableCheckoutConfirm,
  phones,
  voiceUsers,
  scrollObjectSmall,
  scrollObjectBig,
}: Props) => {
  const {
    userStripeData,
    paymentMethods,
    accountConfig,
    paymentSucceededEventData,
    translations,
    language,
  } = useSelector(({ billingReducer, sampleReducer }) => ({
    userStripeData: sampleReducer.userStripeData || {},
    paymentMethods: billingReducer.paymentMethods,
    accountConfig: sampleReducer.accountConfig || {},
    paymentSucceededEventData: billingReducer.paymentSucceededEventData,
    translations: sampleReducer.translations || {},
    language: sampleReducer.language,
  }))
  const dispatch = useDispatch()
  const [loader, setLoader] = useState(true)
  const [totalBalance, setTotalBalance] = useState(null)
  const [monthlyCharge, setMonthlyCharge] = useState(0)
  const [proRatedCharge, setProRatedCharge] = useState(null)
  const [popup1State, setPopup1State] = useState(false)
  const [popup2State, setPopup2State] = useState(false)
  const [amountToPay, setAmountToPay] = useState(0)
  const [loading, setLoading] = useState(true)
  const [tableData, setTableData] = useState([])
  const [voiceProRateCharge, setVoiceProRatedCharge] = useState(null)
  const [phoneProRateCharge, setPhoneProRatedCharge] = useState(null)
  const [callingPlanProRateCharge, setCallingPlanProRatedCharge] = useState(
    null
  )

  /* List charges and credits of the account */
  const [getCreditAndChargeApi] = useCustomFetch(
    `${CUSTOM_ACTION_URL}/payment`,
    'PAYMENT',
    true,
    JSON.stringify({
      action: 'LIST_CHARGE_AND_CREDITS',
      data: {
        customerId: userStripeData.stripe_id,
      },
    })
  )

  /* List payment methods of the loggedin customer */
  const [listPaymentMethodApi] = useCustomFetch(
    `${CUSTOM_ACTION_URL}/payment`,
    'PAYMENT',
    false,
    JSON.stringify({
      action: 'LIST_PAYMENT_METHODS',
      data: {
        customerId: userStripeData.stripe_id,
      },
    })
  )

  useEffect(() => {
    if (paymentSucceededEventData && paymentSucceededEventData.account_id) {
      setTotalBalance(Number(paymentSucceededEventData.data.balance.toFixed(0)))
      setLoading(false)
    }
  }, [paymentSucceededEventData])

  const getProRateForItems = (items = [], monthly_amount) => {
    let amount = 0
    const daysInTotal = moment().daysInMonth()
    const daysRemaining = moment().daysInMonth() - moment().date() + 1

    items.forEach((item) => {
      let charge = 0
      const monthlyCharge =
        monthly_amount === null
          ? item.data?.price?.usd?.monthly_amount || 0
          : monthly_amount

      if (daysRemaining > 0) {
        charge = (daysRemaining / daysInTotal) * monthlyCharge
        amount = amount + charge
      }
    })

    return Number(amount.toFixed(0))
  }

  useEffect(() => {
    if (getCreditAndChargeApi?.response?.response?.balance !== undefined) {
      setTotalBalance(
        Number(getCreditAndChargeApi.response.response.balance.toFixed(0))
      )
      setLoading(false)

      let monthCost = 0
      const data = []
      const procostForVoiceUser = getProRateForItems(
        voiceUsers,
        accountConfig.voice_user.price.usd.monthly_amount
      )
      const procostForPhoneNumber = getProRateForItems(phones, null)
      const procostForCallingPlan = getProRateForItems(callingPlans, null)

      voiceUsers.forEach((user) => {
        const amtInNumbers = accountConfig.voice_user.price.usd.monthly_amount
        const amtInDollars = convertToDollar(amtInNumbers)

        monthCost = monthCost + amtInNumbers
        data.push({
          licence: translations.microsoft_user[language],
          name: user.displayName,
          quantity: 1,
          unitPrice: `$${amtInDollars}`,
          total: `$${amtInDollars}`,
        })
      })

      phones.forEach((phone) => {
        const amtInNumbers = Number(phone.data.price.usd.monthly_amount)
        const amtInDollars = convertToDollar(amtInNumbers)

        monthCost = monthCost + amtInNumbers
        data.push({
          licence: translations.phone_number_label[language],
          name: phone.id,
          quantity: 1,
          unitPrice: `$${amtInDollars}`,
          total: `$${amtInDollars}`,
        })
      })

      callingPlans.forEach((callingPlan) => {
        const amtInNumbers = Number(callingPlan.data.price.usd.monthly_amount)
        const amtInDollars = convertToDollar(amtInNumbers)

        monthCost = monthCost + amtInNumbers
        data.push({
          licence: translations.calling_plan[language],
          name: callingPlan.data.label[language],
          quantity: 1,
          unitPrice: `$${amtInDollars}`,
          total: `$${amtInDollars}`,
        })
      })

      data.push({
        licence: (
          <div style={{ fontWeight: 500 }}>
            {translations.total_price[language]}
          </div>
        ),
        name: '',
        quantity: '',
        unitPrice: `$${convertToDollar(monthCost)}`,
        total: (
          <div style={{ fontWeight: 500 }}>{`$${convertToDollar(
            monthCost
          )}`}</div>
        ),
      })

      setTableData(data)
      setMonthlyCharge(convertToDollar(monthCost))
      setLoader(false)
      setProRatedCharge(
        procostForVoiceUser + procostForPhoneNumber + procostForCallingPlan
      )
      setVoiceProRatedCharge(procostForVoiceUser)
      setPhoneProRatedCharge(procostForPhoneNumber)
      setCallingPlanProRatedCharge(procostForCallingPlan)
    }
  }, [getCreditAndChargeApi.response])

  const onSufficientBalance = () => {
    setAmountToPay(0)
    enableCheckoutConfirm(
      true,
      proRatedCharge,
      voiceProRateCharge,
      phoneProRateCharge,
      callingPlanProRateCharge
    )
  }

  useEffect(() => {
    if (
      totalBalance !== null &&
      proRatedCharge !== null &&
      voiceProRateCharge !== null &&
      phoneProRateCharge !== null &&
      callingPlanProRateCharge !== null
    ) {
      if (totalBalance === proRatedCharge) {
        onSufficientBalance()
        console.log('charge are equal', {
          totalBalance,
          proRatedCharge,
          pay: 0,
        })
      } else if (totalBalance > proRatedCharge) {
        onSufficientBalance()
        console.log('charge are equal', {
          totalBalance,
          proRatedCharge,
          pay: 0,
        })
      } else {
        let temp = proRatedCharge - totalBalance

        temp = temp <= 200 ? 200 : temp
        setAmountToPay(temp)
        enableCheckoutConfirm(false)
        setLoading(false)
        console.log('insufficient balance', {
          totalBalance,
          proRatedCharge,
          pay: temp,
        })
      }

      /* old condition
      const amtToPayTmp = proRatedCharge - totalBalance

      if (totalBalance < proRatedCharge && amtToPayTmp > 0) {
        enableCheckoutConfirm(false)
        const amountToPay = amtToPayTmp <= 200 ? 200 : amtToPayTmp

        setAmountToPay(amountToPay)
      } else {
        setAmountToPay(0)
        enableCheckoutConfirm(
          true,
          proRatedCharge,
          voiceProRateCharge,
          phoneProRateCharge,
          callingPlanProRateCharge
        )
      } */
    }
  }, [
    totalBalance,
    proRatedCharge,
    voiceProRateCharge,
    phoneProRateCharge,
    callingPlanProRateCharge,
  ])

  useEffect(() => {
    if (listPaymentMethodApi?.response?.response) {
      dispatch(setPaymentList(listPaymentMethodApi.response.response.data))
    }
  }, [listPaymentMethodApi.response])

  const handleOpenPopup = () => {
    if (paymentMethods && paymentMethods.length > 0) {
      setPopup1State(true)
    } else {
      setPopup2State(true)
    }
  }

  const handlePopupClose = () => {
    setPopup1State(false)
    setPopup2State(false)
  }

  const getCustomerPaymentSuccList = () => {
    setLoading(true)
  }

  const getPaymentMethods = () => {
    listPaymentMethodApi.setRefetch(true)
  }

  const monthlyPriceTable = useMemo(() => {
    const data = tableData
    const columns = [
      {
        title: translations.licence[language],
        dataIndex: 'licence',
        width: '40%',
      },
      {
        title: translations.name[language],
        dataIndex: 'name',
        width: '40%',
      },
      {
        title: translations.monthly_charge[language],
        dataIndex: 'total',
        width: '20%',
        align: 'right',
      },
    ]

    return (
      <MemoTable
        columns={columns}
        dataSource={data}
        isSelectable={true}
        scrollObject={amountToPay ? scrollObjectBig : scrollObjectSmall}
        showHeader={true}
      />
    )
  }, [monthlyCharge, tableData, accountConfig, amountToPay])

  const renderInfo = () => {
    return (
      <React.Fragment>
        {amountToPay ? (
          <div
            className="checkout-sub-head"
            style={{ marginBottom: 5, marginTop: 0, padding: 5 }}
          >
            <div className="checkout-alert">
              <div style={{ fontWeight: 500 }}>
                {translations.current_account_balance[language]} : $
                {convertToDollar(totalBalance)}
              </div>
              <div style={{ fontSize: '14px' }}>
                {translations.checkout_info[language]}
              </div>
            </div>
            <PttButton
              onClick={handleOpenPopup}
              type="primary"
              customClass="topup-btn"
              disabled={paymentMethods === undefined}
            >
              {translations.TOP_UP[language]}
            </PttButton>
          </div>
        ) : (
          <></>
        )}
        {!amountToPay && (
          <div
            className="checkout-sub-head2"
            style={{
              paddingTop: 0,
              marginBottom: 5,
              fontSize: 17,
              fontWeight: 500,
            }}
          >
            {translations.current_account_balance[language]}: $
            {convertToDollar(totalBalance)}
          </div>
        )}
        <div
          className="checkout-sub-head2"
          style={{
            paddingTop: 0,
            marginBottom: 5,
            fontSize: 17,
            fontWeight: 500,
          }}
        >
          {translations.monthly_charge[language]}: ${monthlyCharge}
        </div>
        {proRatedCharge !== null && (
          <div style={{ marginBottom: 5 }}>
            {translations.checkout_charge_info_1[language]}
            {convertToDollar(proRatedCharge)}{' '}
            {translations.checkout_charge_info_2[language]}
            {monthlyCharge}
            {translations.checkout_charge_info_3[language]}
          </div>
        )}
      </React.Fragment>
    )
  }

  const renderLoader = () => {
    return (
      <div className="spin-style">
        <Spinner spinning={true} />
      </div>
    )
  }

  const renderContent = () => {
    if (loading) {
      return (
        <div className="spin-style">
          <Spin tip={translations.please_wait[language]}>
            {monthlyPriceTable}
          </Spin>
        </div>
      )
    } else {
      return (
        <React.Fragment>
          <div style={{ height: amountToPay ? 155 : 113 }}>{renderInfo()}</div>
          <div
            style={{
              height: amountToPay ? 'calc(100% - 155px)' : 'calc(100% - 113px)',
            }}
          >
            {monthlyPriceTable}
          </div>
        </React.Fragment>
      )
    }
  }

  return (
    <div style={{ height: '100%' }}>
      {renderContent()}
      <AddCreditModal
        handlePopupClose={handlePopupClose}
        popupState={popup1State}
        paymentMethods={paymentMethods}
        getCustomerPaymentSuccList={getCustomerPaymentSuccList}
        topUpAmount={amountToPay}
        setTotalBalance={setTotalBalance}
        totalBalance={totalBalance}
      />
      <MakePaymentModal
        handlePopupClose={handlePopupClose}
        popupState={popup2State}
        getCustomerPaymentSuccList={getCustomerPaymentSuccList}
        getPaymentMethods={getPaymentMethods}
        topUpAmount={amountToPay}
      />
    </div>
  )
}

export default VoiceUserCheckout
