// @flow

import { Table } from 'antd'
import React, { useMemo } from 'react'
import { connect } from 'react-redux'
/* import PttButton from '../CustomButton/PttButton' */
import ModalLayout, { Body /* , Footer */, Header } from '../Popup/popup'

type Props = {
  visiblity: boolean,
  billingInfo: Object,
  translations: Object,
  language: string,
  handleVoiceUserModalClose: (any) => mixed,
}

const BillingInfoModal = (props: Props) => {
  const {
    visiblity,
    billingInfo,
    handleVoiceUserModalClose,
    translations,
    language,
    onOkay,
  } = props

  const getData = () => {
    if (billingInfo && billingInfo.typeCode === 'VOICE_USER') {
      return (billingInfo.data || []).map((data) => {
        return {
          key: data.microsoft_id,
          name: data.displayName,
        }
      })
    } else if (billingInfo && billingInfo.typeCode === 'PHONE_NUMBER') {
      return (billingInfo.data || []).map((data) => {
        return {
          key: data,
          name: data,
        }
      })
    } else if (billingInfo && billingInfo.typeCode === 'TOPUP') {
      const payment_method = billingInfo.data

      return [
        {
          key: 'payment_method',
          name:
            payment_method && payment_method.card
              ? payment_method.card.brand.charAt(0).toUpperCase() +
                payment_method.card.brand.slice(1) +
                ' ' +
                payment_method.card.funding +
                ' card ending with ' +
                payment_method.card.last4
              : 'Payment method not available!',
        },
      ]
    }
  }

  const getColumnName = () => {
    if (billingInfo && billingInfo.typeCode === 'VOICE_USER') {
      return translations.voice_users[language]
    } else if (billingInfo && billingInfo.typeCode === 'PHONE_NUMBER') {
      return translations.phone_numbers[language]
    } else if (billingInfo && billingInfo.typeCode === 'TOPUP') {
      return translations.payment_method_used[language]
    }
  }

  const getUsualColumns = () => {
    return [
      {
        title: getColumnName(),
        dataIndex: 'name',
        width: '100%',
      },
    ]
  }

  const getCallingPlanColumns = () => {
    return [
      {
        title: 'User',
        dataIndex: 'userName',
        width: '50%',
      },
      {
        title: 'Calling plans',
        dataIndex: 'calllingPlans',
        render: (text, record) => {
          const items = record.calllingPlans?.data?.calling_plan
            ? record.calllingPlans.data.calling_plan
            : []

          return items.map((item) => {
            return (
              <div className="license-cont">
                <div style={{ color: 'grey', fontSize: 14, marginRight: 15 }}>
                  {item.data.label[language]}
                </div>
              </div>
            )
          })
        },
        width: '50%',
      },
    ]
  }

  const getCallingPlanData = () => {
    return billingInfo.data.map((item) => {
      return {
        calllingPlans: item.callingPlans ? item.callingPlans : [],
        key: item.voiceUser ? item.voiceUser : '',
        userName: item.voiceUser ? item.voiceUser : '',
      }
    })
  }

  const getBody = useMemo(() => {
    const columns =
      billingInfo && billingInfo.typeCode === 'CALLING_PLAN'
        ? getCallingPlanColumns()
        : getUsualColumns()

    const data =
      billingInfo && billingInfo.typeCode === 'CALLING_PLAN'
        ? getCallingPlanData()
        : getData()

    return (
      <Table
        dataSource={data}
        columns={columns}
        pagination={false}
        className="voiceuser-info-modal"
      />
    )
  }, [billingInfo])

  const exitHandler = () => {
    handleVoiceUserModalClose()
  }

  const getTitle = () => {
    if (billingInfo && billingInfo.typeCode === 'VOICE_USER') {
      return translations.purchased_voice_users[language]
    } else if (billingInfo && billingInfo.typeCode === 'PHONE_NUMBER') {
      return translations.purchased_phone_numbers[language]
    } else if (billingInfo && billingInfo.typeCode === 'TOPUP') {
      return translations.topup_details[language]
    } else if (billingInfo && billingInfo.typeCode === 'CALLING_PLAN') {
      return translations.purchased_calling_plans[language]
    }
  }

  return (
    <ModalLayout
      visible={visiblity}
      onCancel={exitHandler}
      destroyOnClose={true}
      className="subscription-info-modal"
    >
      <Header>{getTitle()}</Header>
      <Body>{getBody}</Body>
      {/* <Footer>
        {
          <PttButton type="primary" onClick={onOkay}>
            {translations.okay[language]}
          </PttButton>
        }
      </Footer> */}
    </ModalLayout>
  )
}

const mapsStateToProps = (state) => {
  return {
    userStripeData: state.sampleReducer.userStripeData || {},
    translations: state.sampleReducer.translations || {},
    language: state.sampleReducer.language,
  }
}

export default connect(mapsStateToProps, null)(BillingInfoModal)
