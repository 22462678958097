// @flow

import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Breadcrumb, Button, Input, Spin, Table, Tooltip } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { LeftOutlined } from '@ant-design/icons'
import { RightOutlined } from '@ant-design/icons'
import { SearchOutlined } from '@ant-design/icons'
import { ClearOutlined } from '@ant-design/icons'
import PttButton from '../../CustomButton/PttButton'
import { getTickedRows } from '../../MsTeams/msteamsutils'
import { getNewArray } from '../../../utils/helpers'
import './microsoftconnectors.css'

type Props = {
  listRows: $ReadOnlyArray<*>,
  loading: boolean,
  onNextClick: (any) => mixed,
  onPreviousClick: (any) => mixed,
  onRefresh?: (any) => mixed,
  onRowSelect: (any) => mixed,
  onRowSelectUnSelectAll: (any) => mixed,
  onSearch: (any) => mixed,
  refreshLoading?: boolean,
  rowKeys: $ReadOnlyArray<*>,
  scrollObject?: Object,
}

const SelectUsers = ({
  listRows,
  loading,
  onNextClick,
  onPreviousClick,
  onRefresh,
  onRowSelect,
  onRowSelectUnSelectAll,
  onSearch,
  refreshLoading = false,
  rowKeys,
  scrollObject = { x: 1000, y: '38vh' },
}: Props) => {
  const [search, setSearch] = useState('')
  const { language, translations, microsoftUsersCollections } = useSelector(
    (state) => {
      return {
        language: state.sampleReducer.language,
        translations: state.sampleReducer.translations,
        microsoftUsersCollections:
          state.msTeamsReducer.microsoftUsersCollections,
      }
    }
  )

  const renderListUsers = React.useMemo(() => {
    if (loading || refreshLoading) {
      return (
        <div className="spin-style">
          <Spin tip={translations.please_wait[language]} />
        </div>
      )
    }

    const tableData = getNewArray(listRows)
    const columns = [
      {
        defaultSortOrder: 'ascend',
        dataIndex: 'displayName',
        sorter: (a, b) => {
          const wordA = a.displayName.toLowerCase()
          const wordB = b.displayName.toLowerCase()

          if (wordA < wordB) {
            return -1
          } else if (wordA > wordB) {
            return 1
          } else {
            return 0
          }
        },
        title: translations.display_name[language],
        width: '25%',
      },
      {
        defaultSortOrder: 'ascend',
        dataIndex: 'email',
        sorter: (a, b) => {
          const wordA = a.email.toLowerCase()
          const wordB = b.email.toLowerCase()

          if (wordA < wordB) {
            return -1
          } else if (wordA > wordB) {
            return 1
          } else {
            return 0
          }
        },
        title: translations.email[language],
        width: '50%',
      },
      {
        title: translations.microsoft_license[language],
        width: '20%',
        render: (text, record) => {
          return (
            <React.Fragment>
              {record.licenseName.map((name) => (
                <div className="license-cont" key={`${record.key} ${name}`}>
                  <div className="license-name">{name}</div>
                </div>
              ))}
            </React.Fragment>
          )
        },
      },
      {
        title: '',
        width: '5%',
        render: (text, record) => {
          return (
            <Tooltip title={translations.invalid_license_text[language]}>
              <ExclamationCircleOutlined
                className={
                  record.valid_license
                    ? 'license-icon-valid'
                    : 'license-icon-invalid'
                }
              />
            </Tooltip>
          )
        },
      },
    ]

    const rowSelection = {
      onSelect: (record, status) => {
        onRowSelect(record, status)
      },
      onSelectAll: (selected, selectedRows, changeRows) => {
        onRowSelectUnSelectAll(selected, changeRows)
      },
      getCheckboxProps: (record) => ({
        disabled: record.disabled,
      }),
    }

    return (
      <Table
        columns={columns}
        dataSource={tableData.map((data) => data)}
        pagination={false}
        rowSelection={{
          selectedRowKeys: getTickedRows(listRows, rowKeys).map(
            (item) => item.key
          ),
          type: 'checkbox',
          ...rowSelection,
        }}
        scroll={scrollObject}
        showSorterTooltip={false}
        size="small"
        style={{ height: '100%' }}
      />
    )
  }, [search, listRows, scrollObject, rowKeys, loading, refreshLoading])

  const handleRefresh = () => {
    onRefresh && onRefresh()
  }

  const getStartRecordNumber = useMemo(() => {
    const { currentUsers, usersPerPage } = microsoftUsersCollections

    if (currentUsers === 0) {
      return currentUsers
    } else if (currentUsers <= usersPerPage) {
      return 1
    } else if (currentUsers % 100 === 0 && currentUsers > usersPerPage - 1) {
      return currentUsers - (usersPerPage - 1)
    } else {
      return currentUsers - ((currentUsers % usersPerPage) - 1)
    }
  }, [microsoftUsersCollections])

  return (
    <div style={{ height: '100%' }}>
      <div
        style={{
          height: 75,
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          width: '100%',
          marginBottom: 10,
        }}
      >
        <div
          style={{
            height: '100%',
            overflow: 'auto',
            textAlign: 'left',
            width: 'calc(100% - 90px)',
          }}
        >
          {translations.info_text[language]}
        </div>
        <div
          style={{
            display: 'flex',
            height: '100%',
            justifyContent: 'flex-end',
            width: 90,
          }}
        >
          <PttButton
            disabled={loading || refreshLoading}
            loading={refreshLoading}
            onClick={handleRefresh}
            type="primary"
          >
            {translations.refresh[language]}
          </PttButton>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: 15,
          height: 32,
        }}
      >
        <Input
          placeholder={translations.search_by_display_name[language]}
          prefix={<SearchOutlined />}
          onChange={(event) => setSearch(event.target.value)}
          onPressEnter={() => {
            if (!loading || !refreshLoading) {
              onSearch(search.trim())
            }
          }}
          value={search}
          style={{ marginRight: 5 }}
        />
        <PttButton
          disabled={loading}
          onClick={() => onSearch(search.trim())}
          style={{ marginRight: 5 }}
          type="primary"
        >
          {translations.search[language]}
        </PttButton>
        {search.length ? (
          <PttButton
            disabled={loading}
            icon={<ClearOutlined />}
            onClick={() => {
              onSearch('')
              setSearch('')
            }}
            style={{ marginRight: 5 }}
            type="secondary"
          >
            {translations.clear_filters[language]}
          </PttButton>
        ) : (
          <React.Fragment />
        )}
      </div>
      <div style={{ height: 'calc(100% - 179px)' }}>{renderListUsers}</div>
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: 15,
          height: 32,
        }}
      >
        <Breadcrumb style={{ marginRight: 10 }} separator="|">
          <Breadcrumb.Item style={{ color: 'black' }}>
            {`${translations.showing[language]} ${getStartRecordNumber} - 
            ${microsoftUsersCollections.currentUsers} 
            ${translations.of[language]} ${microsoftUsersCollections.users}`}
          </Breadcrumb.Item>
          <Breadcrumb.Item style={{ color: 'black' }}>
            {`${translations.page[language]} ${microsoftUsersCollections.currentPage} 
              ${translations.of[language]} ${microsoftUsersCollections.pages}`}
          </Breadcrumb.Item>
        </Breadcrumb>
        <Button
          disabled={
            microsoftUsersCollections.currentPage <= 1 ||
            loading ||
            refreshLoading
          }
          icon={<LeftOutlined />}
          onClick={onPreviousClick}
        />
        <Button
          disabled={
            microsoftUsersCollections.pages ===
              microsoftUsersCollections.currentPage ||
            loading ||
            refreshLoading
          }
          icon={<RightOutlined />}
          onClick={onNextClick}
        />
      </div>
    </div>
  )
}

export default SelectUsers
