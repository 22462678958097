/* eslint-disable no-nested-ternary */
// @flow

import React, { useEffect, useMemo, useState } from 'react'
import { Button, message } from 'antd'
import { useSelector } from 'react-redux'
import ConfirmPopup from '../../../Popup/confirmpopup'
import ModalLayout, { Body, Footer, Header } from '../../../Popup/popup'
import PttButton from '../../../CustomButton/PttButton'
import { ACTIONAPIURL, APIURL, CUSTOM_ACTION_URL, FULL_ACCESS } from '../../../../utils/constants'
import useCustomFetch from '../../../../Hooks/UseCustomFetch'
import { FlagIcon } from 'react-flag-kit'
import Spinner from '../../../../Spinner/spinner'
import { isEmpty } from 'lodash'
import { Fragment } from 'react'

type Props = {
  details: Object,
  getPhoneNoList: () => mixed,
}
const types = { flow: 'call_flow', users: 'voice_user' }

const PhoneNumberDetails = ({
  details = {},
  getPhoneNoList = () => {},
}: Props) => {
  const [loading, setLoading] = useState(true)
  const [showConfirm, setShowComfirm] = useState(false)
  const [showInfo, setShowInfo] = useState(false)
  const [relationType, setRelationType] = useState('')
  const [provider, setProvider] = useState('')
  const [relations, setRelations] = useState([])
  const [removeLoading, setRemoveLoading] = useState(false)

  const { language, translations, phoneNumberPermission } = useSelector(
    (state) => {
      return {
        language: state.sampleReducer.language,
        translations: state.sampleReducer.translations,
        phoneNumberPermission: state.permissionReducer.phoneNumberPermission,
      }
    }
  )

  const [getRelations] = useCustomFetch(
    `${ACTIONAPIURL}/phone/${details.key}?relations=voice_user,call_flow`,
    'GET',
    false
  )

  const [flowsApi] = useCustomFetch(
    `${ACTIONAPIURL}/phone-call_flow/${details.key}`,
    'GET',
    false
  )

  const [usersApi] = useCustomFetch(
    `${ACTIONAPIURL}/phone-voice_user/${details.key}`,
    'GET',
    false
  )

  const [unAssignPhoneNoApi] = useCustomFetch(
    `${ACTIONAPIURL}/phone?sync=yes`,
    'POST',
    false,
    JSON.stringify({
      action: 'unassign',
      actiondata: {
        phoneid: details.key,
      },
    })
  )

  const [deleteApi] = useCustomFetch(
    `${APIURL}/phone/${details.key}?sync=yes`,
    'DELETE',
    false
  )

  const [terminateDidwwApi] = useCustomFetch(
    `${CUSTOM_ACTION_URL}/provider`,
    'POST',
    false,
    JSON.stringify({
      didww_step: 'DELETE_DIDWW_NUMBER',
      didww_number: details.phone,
    })
  )

  useEffect(() => {
    if (Object.keys(details).length) {
      setLoading(true)
      setShowComfirm(false)
      setShowInfo(false)
      setRelationType('')
      setProvider('')
      setRelations([])
      getRelations.setRefetch(true)
    }
  }, [details])

  useEffect(() => {
    if (unAssignPhoneNoApi.response && unAssignPhoneNoApi.response.result) {
      getPhoneNoList()
      setRemoveLoading(false)
      setShowComfirm(false)
      message.success(translations.subscription_cancelled_sucessfully[language])
    }
  }, [unAssignPhoneNoApi.response])

  useEffect(() => {
    if (Object.keys(terminateDidwwApi.response).length) {
      getPhoneNoList()
      setShowComfirm(false)
      setRemoveLoading(false)
      message.success(translations.subscription_cancelled_sucessfully[language])
    }
  }, [terminateDidwwApi.response])

  useEffect(() => {
    if (relationType.length) {
      if (relationType === types.flow) {
        flowsApi.setRefetch(true)
      } else if (relationType === types.users) {
        usersApi.setRefetch(true)
      }
    }
  }, [relationType])

  useEffect(() => {
    const { result, response } = flowsApi.response

    if (result && response.items.length && response.items[0].id) {
      setLoading(false)
      setRelations(
        response.items.map((item) => {
          return {
            id: item.id,
            name: item.name,
          }
        })
      )
    }
  }, [flowsApi.response])

  useEffect(() => {
    const { result, response } = usersApi.response

    if (result && response.items.length && response.items[0].id) {
      setLoading(false)
      setRelations(
        response.items.map((item) => {
          return {
            id: item.id,
            name: item.data.displayName,
          }
        })
      )
    }
  }, [usersApi.response])

  useEffect(() => {
    const { result, response } = getRelations.response

    if (result && response.items.length && response.items[0].id) {
      const { data, relation } = response.items[0]
      const provider = data?.provider ? data.provider : ''

      setProvider(provider)
      if (relation) {
        const flow = relation[types.flow]
        const users = relation[types.users]
        const type = flow ? types.flow : users ? types.users : ''

        setRelationType(type)
      } else {
        setLoading(false)
      }
    }
  }, [getRelations.response])

  const handleCancelSubscription = () => {
    if (relationType === types.users || relationType === types.flow) {
      setShowInfo(true)
    } else {
      setShowComfirm(true)
    }
  }

  useEffect(() => {
    if (!isEmpty(deleteApi.response)) {
      if (deleteApi.response.result) {
        terminateDidwwApi.setRefetch(true)
      }
    }
  }, [deleteApi.response])

  const cancelSubscription = () => {
    setRemoveLoading(true)
    if (provider === 'didww') {
      deleteApi.setRefetch(true)
    } else {
      unAssignPhoneNoApi.setRefetch(true)
    }
  }

  const getRouterText = () => {
    if (relationType === types.flow) {
      return `${translations.Routing_incoming_calls_to[language]} ${translations.callflow[language]}`
    } else if (relationType === types.users && relations.length === 1) {
      return `${translations.Routing_incoming_calls_to[language]} ${translations.microsoft_user[language]}`
    } else if (relationType === types.users && relations.length > 1) {
      return `${translations.Routing_incoming_calls_to[language]} ${translations.voice_users[language]}`
    } else {
      return ''
    }
  }

  const renderRelationBox = useMemo(() => {
    if (relationType.length && relations.length) {
      return (
        <div className="phone-details-relations-box">
          <div className="phone-details-relations-head">{getRouterText()}</div>
          <div className="phone-details-relations-body">
            {relations.map((relation) => {
              return (
                <div key={relation.id} className="phone-details-uneditable-div">
                  {relation.name}
                </div>
              )
            })}
          </div>
        </div>
      )
    } else {
      return <React.Fragment />
    }
  }, [loading, relationType, relations])

  const renderContents = () => {
    return (
      <React.Fragment>
        <div className="phone-details-title-div">
          {translations.phone_number_label[language]}
        </div>
        <div className="phone-details-uneditable-div">
          <FlagIcon
            className="phone-details-flagicon"
            code={details.countryCode}
            size={15}
          />
          {details.phone}
        </div>
        <div className="phone-details-title-div">
          {translations.country[language]}
        </div>
        <div className="phone-details-uneditable-div">{details.country}</div>
        <div className="phone-details-title-div">
          {translations.area[language]}
        </div>
        <div className="phone-details-uneditable-div">{details.area}</div>
        <div className="phone-details-title-div">
          {translations.monthly_price[language]}
        </div>
        <div className="phone-details-uneditable-div">{details.price}</div>
        {renderRelationBox}
      </React.Fragment>
    )
  }

  const renderCancelButton = () => {
    if (phoneNumberPermission === FULL_ACCESS) {
      return (
        <div className="phone-details-footer">
          <Button
            className="phone-details-cancel-button"
            danger
            onClick={handleCancelSubscription}
          >
            {translations.cancel_phone_number_subscription[language]}
          </Button>
        </div>
      )
    } else {
      return <React.Fragment />
    }
  }

  const renderDetailsContainer = () => {
    return (
      <div
        className={
          phoneNumberPermission === FULL_ACCESS
            ? 'phone-details-container'
            : 'phone-details-cont-alt'
        }
      >
        {renderContents()}
      </div>
    )
  }

  const renderContent = () => {
    if (loading) {
      return (
        <div className="spin-style">
          <Spinner spinning={true} />
        </div>
      )
    } else {
      return (
        <Fragment>
          {renderDetailsContainer()}
          {renderCancelButton()}
        </Fragment>
      )
    }
  }

  return (
    <React.Fragment>
      {renderContent()}
      <ConfirmPopup
        cancelText={translations.keep_the_number[language]}
        confirmText={translations.cancel_phone_number_subscription[language]}
        okayText={translations.remove_the_number[language]}
        onCancel={() => setShowComfirm(false)}
        onOkay={cancelSubscription}
        visiblity={showConfirm}
        closable={true}
        type="danger"
        okBtnClassName="on-button-style"
        loading={removeLoading}
      >
        <React.Fragment>
          {translations.cancel_phone_number_subscription_info_1[language]}
          <br />
          {translations.cancel_phone_number_subscription_info_2[language]}
          <br />
          {translations.cancel_phone_number_subscription_info_3[language]}
          <br />
          {translations.cancel_phone_number_subscription_info_4[language]}
        </React.Fragment>
      </ConfirmPopup>
      <ModalLayout onCancel={() => setShowInfo(false)} visible={showInfo}>
        <Header>
          {translations.cancel_phone_number_subscription[language]}
        </Header>
        <Body>
          {relationType === types.users
            ? translations.unable_to_remove_user_number[language]
            : translations.unable_to_remove_callflow_number[language]}
        </Body>
        <Footer>
          <div>
            <PttButton type={'primary'} onClick={() => setShowInfo(false)}>
              {translations.okay[language]}
            </PttButton>
          </div>
        </Footer>
      </ModalLayout>
    </React.Fragment>
  )
}

export default PhoneNumberDetails
