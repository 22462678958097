const clientReducer = (
  state = {
    saveClientState: false,
    onSaveState: false,
    closeState: false,
  },
  action
) => {
  switch (action.type) {
    case 'setSaveClientState':
      return { ...state, saveClientState: action.saveClientState }

    case 'setOnSaveState':
      return { ...state, onSaveState: action.onSaveState }

    case 'setCloseState':
      return { ...state, closeState: action.closeState }
    default:
      return state
  }
}

export default clientReducer
