import { getNewArray } from '../../../utils/helpers'

export const setRow = (array, property, postition, replacement) => {
  array.forEach((data) => {
    if (data.position === postition) {
      data[property] = replacement
    }
  })

  return getNewArray(array)
}

export const getRowDataByProp = (array, prop, value) => {
  let rowData = { key: '', value: '' }
  let isFound = false

  if (array.length) {
    array.forEach((data) => {
      if (data[prop] === value && isFound) {
        rowData = data
        isFound = true
      }
    })
  }

  return rowData
}
