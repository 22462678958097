// @flow

import React from 'react'
import { Button } from 'antd'
import uuid from 'react-uuid'
import { DeleteOutlined } from '@ant-design/icons'
import { NODE_TYPES } from '../nodetypes'
import { getNewArray } from '../../../utils/helpers'
import NodeDropDown from '../MapComponents/NodeDropDown'
import { setRow } from '../MapComponents/maputils'
import ShortNumber from '../MapComponents/ShortNumber'
import ShortText from '../MapComponents/ShortText'
import './MapNumberOrStringToNode.css'

type Props = {
  autoSaveHandler?: (any) => mixed,
  dropDownData: $ReadOnlyArray<*>,
  keyName: string,
  label?: string,
  menuOptions?: $ReadOnlyArray<*>,
  mode: string,
  translations: Object,
  language: string,
}

const MapNumberOrStringToNode = React.forwardRef<Props, *>(
  (
    {
      autoSaveHandler,
      dropDownData = [],
      label = 'Label',
      keyName,
      menuOptions = [],
      mode,
      translations,
      language,
    }: Props,
    ref
  ) => {
    const [update, setUpdate] = React.useState(false)
    const [menuData, setMenuData] = React.useState(menuOptions)

    React.useEffect(() => {
      const data = getNewArray(menuOptions)

      data.forEach((value) => {
        value.position = uuid()
      })

      setMenuData(data)
    }, [menuOptions])

    React.useImperativeHandle(ref, () => ({
      getLatestValue() {
        const data = getNewArray(menuData)

        data.forEach((value) => {
          delete value.position
        })

        return data
      },
      getName() {
        return keyName
      },
      getType() {
        return NODE_TYPES.mapNumberOrStringToNode
      },
      resetValue() {
        setMenuData([])
      },
    }))

    const savenow = () => {
      setUpdate(true)
    }

    const addHandler = () => {
      const data = getNewArray(menuData)

      data.push({ position: uuid(), dial: '', say: '', value: '' })
      setMenuData(data)
    }

    const deleteHandler = (position) => {
      setMenuData(menuData.filter((data) => data.position !== position))
      savenow()
    }

    const valueHandler = (value = '', position) => {
      setMenuData(setRow(menuData, 'value', position, value))
      savenow()
    }

    const dialHandler = (value, position) => {
      setMenuData(setRow(menuData, 'dial', position, value))
    }

    const sayHandler = (value, position) => {
      setMenuData(setRow(menuData, 'say', position, value))
    }

    React.useEffect(() => {
      if (update && mode === 'Edit' && autoSaveHandler) {
        autoSaveHandler(NODE_TYPES.mapNumberOrStringToNode)
        setUpdate(false)
      }
    }, [update])

    const renderRow = ({ position, dial, say, value }, index) => {
      return (
        <div className="MapNumberOrStringToNode__Row" key={index}>
          <div className="MapNumberOrStringToNode__RowInner MapNumberOrStringToNode__RowSpace">
            <div className="MapNumberOrStringToNode__ShortNumber">
              <ShortNumber
                changeHandler={dialHandler}
                onBlur={savenow}
                position={position}
                value={dial}
              />
            </div>
            <div className="MapNumberOrStringToNode__ShortText">
              <ShortText
                changeHandler={sayHandler}
                onBlur={savenow}
                position={position}
                value={say}
              />
            </div>
          </div>
          <div className="MapNumberOrStringToNode__RowInner">
            <span className="MapNumberOrStringToNode__Info">Goes to:</span>
            <div className="MapNumberOrStringToNode__Select">
              <NodeDropDown
                data={{ position: position, value: value }}
                dropDownData={dropDownData}
                onChange={valueHandler}
              />
            </div>
            <DeleteOutlined
              className="MapNumberOrStringToNode__Delete"
              onClick={() => deleteHandler(position)}
            />
          </div>
        </div>
      )
    }

    return (
      <React.Fragment>
        <div className="label-spacing">{label}</div>
        <div className="MapNumberOrStringToNode__Title">
          <span>{translations.dial[language]}</span>
          <span className="MapNumberOrStringToNode__Say">
            {translations.or_say[language]}
          </span>
        </div>
        <div className="MapNumberOrStringToNode__Box">
          <div className="MapNumberOrStringToNode__Body">
            {menuData.map((data, index) => renderRow(data, index))}
          </div>
          <Button type="dashed" size="small" block onClick={addHandler}>
            {translations.add_menu_option[language]}
          </Button>
        </div>
      </React.Fragment>
    )
  }
)

export default React.memo<Props>(MapNumberOrStringToNode)
