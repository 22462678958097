// @flow

import React, { useState } from 'react'
import ModalLayout, { Header, Body } from '../Popup/popup'
import CheckoutForm from './CustomCardElement'
import { connect } from 'react-redux'

type Props = {
  handlePopupClose: (any) => void,
  popupState: boolean,
  // stripePromise: Object,
  getPaymentList: (any) => mixed,
  translations: Object,
  language: string,
}

const AddPaymentMethodModal = (props: Props) => {
  const {
    handlePopupClose,
    popupState,
    getPaymentList,
    translations,
    language,
  } = props
  const [popupWillClose, setPopupWillClose] = useState(false)

  const exitHandler = () => {
    setPopupWillClose(true)
    handlePopupClose(false)
  }

  const getBody = () => {
    return (
      <div className="add-payment-modal-body">
        {/* <Elements stripe={stripePromise}> */}
        <CheckoutForm
          getPaymentList={getPaymentList}
          handlePopupClose={handlePopupClose}
          popupWillClose={popupWillClose}
        />
        {/* </Elements> */}
      </div>
    )
  }

  return (
    <ModalLayout
      visible={popupState}
      onCancel={exitHandler}
      className="add-payment-modal"
      destroyOnClose={true}
    >
      <Header>{translations.add_payment_method[language]}</Header>
      <Body>{getBody()}</Body>
      {/* <Footer>{getFooter()}</Footer> */}
    </ModalLayout>
  )
}

const mapsStateToProps = (state) => {
  return {
    translations: state.sampleReducer.translations || {},
    language: state.sampleReducer.language,
  }
}

export default connect(mapsStateToProps, null)(AddPaymentMethodModal)
