// @flow

import React, { Fragment, useEffect, useState } from 'react'
import { Input, message } from 'antd'
import { MailOutlined } from '@ant-design/icons'
import StartupImagePanel from '../StartUps/StartupImagePanel'
import StartupAddons from '../StartUps/StartupAddons'
import { StartupButton } from '../CustomButton/custombutton'
import Spinner from '../../Spinner/spinner'
import useCustomFetch from '../../Hooks/UseCustomFetch'
import { SIGNUP_API } from '../../utils/constants'
import { connect } from 'react-redux'
import useConstructor from '../../Hooks/UseConstructor'
import { useHistory } from 'react-router'

const logo = require('../../assets/images/colored-transp-bg-vertical.png')

type Props = {
  translations: Object,
  language: string,
  appUrlBrand: string,
}

// eslint-disable-next-line no-useless-escape
const validator = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

const Signup = ({ appUrlBrand, translations, language }: Props) => {
  const history = useHistory()
  const checkPermission = () => {
    if (appUrlBrand !== 'Plug2Teams') {
      history.push({ pathname: '/login' })
    }
  }

  useConstructor(checkPermission)

  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState(false)

  /* Signup 1 */
  const [signup1Api] = useCustomFetch(
    `${SIGNUP_API}/1/${email}?myDirectRouting=false`,
    'SIGNUP_GET',
    false
  )

  /* useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false)
        setResult(true)
        message.success(messages.success)
      }, 5000)
    }
  }, [loading]) */

  useEffect(() => {
    if (signup1Api.response && signup1Api.response.status) {
      setLoading(false)
      setResult(true)
    } else if (
      signup1Api.response == {} ||
      signup1Api.response.status === false
    ) {
      setLoading(false)
      setResult(false)

      return message.error(
        (translations[signup1Api.response.error]
          ? translations[signup1Api.response.error][language]
          : signup1Api.response.error) || 'Internal server error'
      )
    }
  }, [signup1Api.response])

  const onEmailChange = (event) => {
    setEmail(event.target.value)
  }

  const onSignupClick = () => {
    if (!email.length) {
      return message.warning(translations.pls_enter_email[language])
    } else if (!validator.test(email)) {
      return message.warning(translations.pls_enter_valid_email[language])
    }
    setLoading(true)
    signup1Api.setRefetch(true)
  }

  const renderInputs = () => {
    return (
      <Fragment>
        <Input
          className="start-up-input"
          onChange={onEmailChange}
          placeholder={translations.email[language]}
          onPressEnter={onSignupClick}
          prefix={<MailOutlined className="startup-prefix-style" />}
          size="large"
          value={email}
        />
        <StartupButton
          block
          disabled={loading}
          loading={loading}
          onClick={onSignupClick}
        >
          {translations.sign_up[language]}
        </StartupButton>
      </Fragment>
    )
  }
  const startupInputs = () => {
    return (
      <Fragment>
        <div className="align-center">
          <img alt="pttlogo" className="signup-colored-logo" src={logo} />
        </div>
        <div className="align-center startup-title">
          {translations.get_started[language]}
        </div>
        <div className="startup-result-container">
          <div className="startup-info-text">
            {translations.pls_enter_email[language]}
          </div>
          <div className="startup-info-text">
            {translations.will_send_link[language]}
          </div>
        </div>
        {renderInputs()}
        <StartupAddons option="Link" linkTo="login" />
      </Fragment>
    )
  }

  const startupInfo = () => {
    return (
      <div className="startup-result-container">
        <div className="startup-check-mail">
          {translations.check_email[language]}
        </div>
        <div className="startup-define-password">
          {translations.link_sent[language]}
        </div>
      </div>
    )
  }

  return (
    <div className="startup-container">
      <div className="startup-image-section">
        <StartupImagePanel text={translations.startup_image_text[language]} />
      </div>
      <div className="startup-field-section">
        <div className="align-center full-height">
          <Spinner color="#00569D" spinning={loading}>
            <div className="align-center startup-field-container">
              <div className="startup-field-inputs">
                {result ? startupInfo() : startupInputs()}
              </div>
            </div>
          </Spinner>
        </div>
      </div>
    </div>
  )
}

const mapsStateToProps = (state) => {
  return {
    translations: state.sampleReducer.translations || {},
    language: state.sampleReducer.language,
    appUrlBrand: state.sampleReducer.appUrlBrand,
  }
}

export default connect(mapsStateToProps, null)(Signup)
