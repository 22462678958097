// @flow

import React, { useEffect, useMemo, useState } from 'react'
import { Button, message, Tooltip } from 'antd'
import { RedoOutlined, SaveOutlined, UndoOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { isEqual, remove } from 'lodash'
import './callfloweditor.css'
import { PttButton } from '../../../CustomButton/custombutton'
import * as actions from '../../../../actions/IncomingCallsAction'
import Spinner from '../../../../Spinner/spinner'
import useCustomFetch from '../../../../Hooks/UseCustomFetch'
import { APIURL } from '../../../../utils/constants'
import ConfirmPopup from '../../../Popup/confirmpopup'
import Diagram from '../../../React-Diagrams/Diagram'
import { getDiagramData } from '../../../React-Diagrams/diagramutils'
import {
  parseApiResult,
  getNewArray,
  getNodeDataFeed,
} from '../../../../utils/helpers'
import { DEFAULT_NODE, NODE_TYPES } from '../../../NodeFieldTypes/nodetypes'
import ManageCallFlow from './ManageCallFlow'
import { setHomeKey } from '../../../../actions/SampleAction'
import UseUpdateCallflowRelation from '../../../../Hooks/UseUpdateCallflowRelation'

type Props = {
  actualCallFlowNodes: $ReadOnlyArray<*>,
  setActualRouteName: (any) => mixed,
  actualRouteName: string,
  callFlowId: string,
  callFlowNodes: $ReadOnlyArray<*>,
  changeFlowView: (any) => mixed,
  loading: boolean,
  nodeList: $ReadOnlyArray<*>,
  onCallFlowCreated: (any) => mixed,
  onNodeDeletion: () => mixed,
  onNodeSelected: (any) => mixed,
  redoArray: $ReadOnlyArray<*>,
  routeName: string,
  selectedCallFlow: Object,
  setActualCallFlowNodes: (any) => mixed,
  setCallFlowNodes: (any) => mixed,
  setHomeKey: (any) => mixed,
  setRedoArray: (any) => mixed,
  setUndoArray: (any) => mixed,
  setAutoUpdateCallFlow: (any) => mixed,
  setUnsaveAlertTrigger: (any) => mixed,
  undoArray: $ReadOnlyArray<*>,
  unsaveAlertTrigger: Object,
  deleteCallBack: () => mixed,
  flowNameChangeCallBack: (string) => mixed,
  callFlowApi: Object,
  onCloneFinish: () => mixed,
  translations: Object,
  language: string,
  autoUpdateCallFlow: boolean,
  callflowPhones: $ReadOnlyArray<*>,
  unusedPhones: $ReadOnlyArray<*>,
}

let isChangeToFlowView = false

const CallFlowEditor = (props: Props) => {
  const {
    actualCallFlowNodes,
    callFlowId,
    callFlowNodes,
    callflowPhones,
    loading,
    nodeList,
    onNodeDeletion,
    onNodeSelected,
    redoArray,
    selectedCallFlow,
    undoArray,
    translations,
    language,
    setAutoUpdateCallFlow,
    autoUpdateCallFlow,
    unusedPhones,
  } = props
  const [updateData, setUpdateData] = useState(null)
  const [saveAlert, setSaveAlert] = useState(false)

  const [callflowRelations] = UseUpdateCallflowRelation()

  useEffect(() => {
    callflowRelations.setId(callFlowId)
    callflowRelations.setExecuteRelation(true)
    callflowRelations.setExecutePhoneApi(true)
  }, [])

  const [updateApi] = useCustomFetch(
    `${APIURL}/call_flow/${selectedCallFlow.id}?sync=yes`,
    'PATCH',
    false,
    updateData
  )

  useEffect(() => {
    if (updateData) {
      updateApi.setRefetch(true)
    }
  }, [updateData])

  useEffect(() => {
    const result = parseApiResult(updateApi.response)

    if (result.length && result[0].action === 'updated') {
      setUpdateData(null)
      message.success(translations.call_flow_updated_success[language])
      updateApi.setResponse({})
      props.setActualRouteName(props.routeName)
      props.callFlowApi.setRefetch(true)
      if (isChangeToFlowView) {
        props.changeFlowView()
        isChangeToFlowView = false
      }
      if (props.unsaveAlertTrigger.state) {
        props.setHomeKey(props.unsaveAlertTrigger.key)
        props.setUnsaveAlertTrigger({})
      }
    }
  }, [updateApi.response])

  const onNodeLinkAdded = (mode, name, params) => {
    let changed = false

    params.values.forEach((value) => {
      if (mode === value.type && value.name === name) {
        value.value = params.target
        changed = true
      }
    })

    !changed &&
      params.values.push({
        name: 'next',
        type: NODE_TYPES.node,
        value: params.target,
      })

    updateUndoRedoCurrent(params)
  }

  const onTriggerLinkAdded = (mode, params) => {
    params.values.forEach((value) => {
      if (mode === value.type) {
        value.value = params.target
      }
    })

    updateUndoRedoCurrent(params)
  }

  const onMapNumberLinkAdded = (mode, name, params) => {
    let labelChanged = false

    params.values.forEach((value) => {
      if (value.type === mode && value.key_value_pair) {
        value.key_value_pair.forEach((pair) => {
          if (pair.key === name) {
            labelChanged = true
            pair.value = params.target
          }
        })

        if (!labelChanged) {
          value.key_value_pair.push({
            key: name,
            value: params.target,
          })
        }
      } else if (value.type === mode) {
        params.values.push({
          name: 'menu_options',
          key_value_pair: [{ key: name, value: params.target }],
          type: mode,
        })
      }
    })

    updateUndoRedoCurrent(params)
  }

  const onDialSayLinkAdded = (mode, identifier, dial, say, params) => {
    params.values.forEach((value) => {
      if (value.type === mode && value.key_value_pair) {
        value.key_value_pair.forEach((pair, index) => {
          if (identifier === index) {
            pair.dial = dial
            pair.say = say
            pair.value = params.target
          }
        })

        if (identifier === -1) {
          value.key_value_pair.push({
            dial: dial,
            say: say,
            value: params.target,
          })
        }
      }
    })

    updateUndoRedoCurrent(params)
  }

  const updateUndoRedoCurrent = ({ source, values }) => {
    const nodes = getNewArray(callFlowNodes)
    const undo = getNewArray(undoArray)

    nodes.forEach((node) => {
      if (node.call_flow_node_id === source) {
        node.values = values
      }
    })

    undo.push(nodes)
    props.setCallFlowNodes(nodes)
    props.setUndoArray(undo)
    props.setRedoArray([])
  }

  const undoHandler = () => {
    if (undoArray.length > 1) {
      const undoData = getNewArray(undoArray)
      const redoData = getNewArray(redoArray)

      redoData.push(getNewArray(undoData[undoArray.length - 1]))
      undoData.splice(-1, 1)
      props.setCallFlowNodes(getNewArray(undoData[undoData.length - 1]))
      props.setUndoArray(undoData)
      props.setRedoArray(redoData)
    }
  }

  const redoHandler = () => {
    if (redoArray.length > 0) {
      const undoData = getNewArray(undoArray)
      const redoData = getNewArray(redoArray)

      undoData.push(getNewArray(redoData[redoArray.length - 1]))
      props.setCallFlowNodes(getNewArray(redoData[redoData.length - 1]))
      redoData.splice(-1, 1)
      props.setUndoArray(undoData)
      props.setRedoArray(redoData)
    }
  }

  const addNewNode = (newNode) => {
    const newCallFlowNodes = getNewArray(callFlowNodes)
    const undoData = getNewArray(undoArray)

    newCallFlowNodes.push(newNode)
    undoData.push(newCallFlowNodes)
    props.setUndoArray(undoData)
    props.setRedoArray([])
    props.setCallFlowNodes(newCallFlowNodes)
  }

  const replacePositions = (callFlowNodeId, positions) => {
    const data = getNewArray(callFlowNodes)

    if (data.length) {
      data.forEach((node) => {
        if (node.call_flow_node_id === callFlowNodeId) {
          node.icon_position = positions
        }
      })
    }

    return data
  }

  const onNodeDeleted = (deletedNode) => {
    const nodes = getNewArray(callFlowNodes)
    const undoData = getNewArray(undoArray)
    const newCallFlowNodes = remove(nodes, (node) => {
      return node.call_flow_node_id !== deletedNode.callFlowNodeId
    })

    newCallFlowNodes.forEach((node) => {
      node.values.forEach((value) => {
        if (value.value === deletedNode.callFlowNodeId) {
          value.value = ''
        } else if (value.key_value_pair) {
          value.key_value_pair.forEach((pair) => {
            if (pair.value === deletedNode.callFlowNodeId) {
              pair.value = ''
            }
          })
        }
      })
    })

    onNodeDeletion()
    undoData.push(newCallFlowNodes)
    props.setUndoArray(undoData)
    props.setRedoArray([])
    props.setCallFlowNodes(newCallFlowNodes)
  }

  const onNodePositionChanged = (callFlowNodeId, positions) => {
    const newCallFlowNodes = replacePositions(callFlowNodeId, positions)
    const undoData = getNewArray(undoArray)

    undoData.push(newCallFlowNodes)
    props.setUndoArray(undoData)
    props.setRedoArray([])
    props.setCallFlowNodes(newCallFlowNodes)
  }

  const onLinkDeleted = (port) => {
    const newCallFlowNodes = getNewArray(props.callFlowNodes)
    const undoData = getNewArray(undoArray)

    newCallFlowNodes.forEach((node) => {
      if (node.call_flow_node_id === port.from) {
        node.values.forEach((value) => {
          if (value.name === port.labelName) {
            if (
              value.type === NODE_TYPES.mapNumberToNode ||
              value.type === NODE_TYPES.mapNumberOrStringToNode
            ) {
              value.key_value_pair = value.key_value_pair.filter(
                (pair, index) => index !== port.identifier
              )
            } else if (value.type === NODE_TYPES.node) {
              value.value = ''
            }
          }
        })
      }
    })

    undoData.push(newCallFlowNodes)
    props.setUndoArray(undoData)
    props.setRedoArray([])
    props.setCallFlowNodes(newCallFlowNodes)
  }

  const onTriggerLinkDeleted = () => {
    const newCallFlowNodes = props.callFlowNodes
    const undoData = getNewArray(undoArray)

    newCallFlowNodes.forEach((node) => {
      if (node.call_flow_node_id === DEFAULT_NODE.call_flow_node_id) {
        node.values[0].value = ''
      }
    })

    undoData.push(newCallFlowNodes)
    props.setUndoArray(undoData)
    props.setRedoArray([])
    props.setCallFlowNodes(newCallFlowNodes)
  }

  const keyPressHandler = (event) => {
    const evt = window.event ? window.event : event
    const isSupportKey = evt.ctrlKey || evt.metakey

    if (evt.keyCode === 90 && isSupportKey) {
      undoHandler()
    }
    if (evt.keyCode === 89 && isSupportKey) {
      redoHandler()
    }
  }

  React.useEffect(() => {
    if (autoUpdateCallFlow) {
      saveHandler()
      setAutoUpdateCallFlow(false)
    }
  }, [autoUpdateCallFlow])

  const saveHandler = () => {
    props.setActualCallFlowNodes(callFlowNodes)
    setUpdateData(
      JSON.stringify({
        data: getNodeDataFeed(callFlowNodes),
        id: selectedCallFlow.id,
        name: props.routeName,
      })
    )
  }

  const updateCallFlowRelations = (selectedPhonesIds) => {
    callflowRelations.setSelectedPhones(selectedPhonesIds)
    callflowRelations.setUpdate(true)
  }

  const nodeDiagrams = useMemo(() => {
    if (loading || updateApi.loading) {
      return (
        <div className="align-center full-height">
          <Spinner spinning={true} />
        </div>
      )
    } else {
      return (
        <Diagram
          diagramData={getDiagramData(callFlowNodes, nodeList, language)}
          onLinkDeleted={onLinkDeleted}
          onNewNodeAdded={addNewNode}
          onNodeDeleted={onNodeDeleted}
          onNodePositionChanged={onNodePositionChanged}
          onNodeSelected={onNodeSelected}
          onNodeLinkAdded={onNodeLinkAdded}
          onTriggerLinkAdded={onTriggerLinkAdded}
          onMapNumberLinkAdded={onMapNumberLinkAdded}
          onDialSayLinkAdded={onDialSayLinkAdded}
          onTriggerLinkDeleted={onTriggerLinkDeleted}
          updateCallFlowRelations={updateCallFlowRelations}
        />
      )
    }
  }, [callFlowNodes, loading, updateApi.loading, callflowPhones, unusedPhones])

  const onFlowViewChange = () => {
    if (
      isEqual(actualCallFlowNodes, callFlowNodes) &&
      isEqual(props.actualRouteName, props.routeName)
    ) {
      props.changeFlowView()
    } else {
      setSaveAlert(true)
    }
  }

  useEffect(() => {
    if (props.unsaveAlertTrigger.state) {
      setSaveAlert(true)
    }
  }, [props.unsaveAlertTrigger])

  const ivrCloseHandler = () => {
    setSaveAlert(false)
  }

  const ivrCancelHandler = () => {
    setSaveAlert(false)
    if (props.unsaveAlertTrigger.state) {
      props.setHomeKey(props.unsaveAlertTrigger.key)
      props.setUnsaveAlertTrigger({})
    } else {
      props.changeFlowView()
    }
  }

  const ivrOkayHandler = () => {
    if (!props.unsaveAlertTrigger.state) {
      isChangeToFlowView = true
    }

    setSaveAlert(false)
    saveHandler()
  }

  const onSuccess = (value) => {
    props.flowNameChangeCallBack(value)
  }

  return (
    <div className="editor-style editor-spacing" onKeyPress={keyPressHandler}>
      <div className="editor-options">
        <div className="editor-utils">
          <div className="editor-utils-container">
            <ManageCallFlow
              deleteCallBack={props.deleteCallBack}
              id={selectedCallFlow.id}
              onFlowViewChange={onFlowViewChange}
              onSuccess={onSuccess}
              routeName={props.routeName}
              onCloneFinish={props.onCloneFinish}
            />
            <div className="nodeactions-container">
              <Tooltip title={translations.undo[language]}>
                <Button
                  disabled={undoArray.length <= 1}
                  onClick={undoHandler}
                  type="secondary"
                  shape="circle"
                  icon={<UndoOutlined />}
                />
              </Tooltip>
              <Tooltip title={translations.redo[language]}>
                <Button
                  disabled={redoArray.length === 0}
                  onClick={redoHandler}
                  type="secondary"
                  shape="circle"
                  icon={<RedoOutlined />}
                />
              </Tooltip>
              <PttButton
                icon={<SaveOutlined />}
                onClick={saveHandler}
                type="primary"
              >
                {translations.save[language]}
              </PttButton>
            </div>
          </div>
        </div>
      </div>
      <div className="cyto-container">{nodeDiagrams}</div>
      <ConfirmPopup
        cancelText={translations.cancel[language]}
        closable={true}
        confirmText={translations.callflow_unsaved_changes[language]}
        okayText={translations.save[language]}
        onCancel={ivrCancelHandler}
        onOkay={ivrOkayHandler}
        onClose={ivrCloseHandler}
        visiblity={saveAlert}
      >
        <React.Fragment>
          {translations.callflow_unsaved_changes_info[language]}
        </React.Fragment>
      </ConfirmPopup>
    </div>
  )
}

const mapStateToProps = ({
  callflowReducer,
  incomingCallsReducer,
  sampleReducer,
}) => ({
  actualCallFlowNodes: incomingCallsReducer.actualCallFlowNodes || [],
  actualRouteName: incomingCallsReducer.actualRouteName || '',
  callFlowNodes: incomingCallsReducer.callFlowNodes || [],
  nodeList: incomingCallsReducer.nodeList || [],
  undoArray: incomingCallsReducer.undoArray || [],
  redoArray: incomingCallsReducer.redoArray || [],
  routeName: incomingCallsReducer.routeName || '',
  unsaveAlertTrigger: incomingCallsReducer.unsaveAlertTrigger || {},
  translations: sampleReducer.translations || {},
  language: sampleReducer.language,
  autoUpdateCallFlow: incomingCallsReducer.autoUpdateCallFlow,
  callflowPhones: callflowReducer.callflowPhones,
  unusedPhones: callflowReducer.unusedPhones,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...actions, setHomeKey }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(CallFlowEditor)
