import { NODE_TYPES } from '../components/NodeFieldTypes/nodetypes'
import dev from './dev'
import stage from './stage'
import prod from './prod'
import _ from 'lodash'

export const isEmpty = (data = null) => _.isEmpty(data)

export const getFilteredArray = (array, searchValue, field) => {
  return array.filter((data) => {
    return data[field].toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
  })
}

export const parseApiResult = (responseData) => {
  const result =
    responseData.result === true && responseData.response
      ? responseData.response.items
      : []

  return result
}

export const getOption = (array = [], index) => {
  return array.length ? array[index] : {}
}

export const getOptionByValue = (array = [], value) => {
  if (array.length) {
    let isFound = false
    let option = {}

    if (array.length) {
      array.forEach((data) => {
        if (!isFound && data.value === value) {
          option = data
          isFound = true
        }
      })
    }

    return option
  } else {
    return {}
  }
}

export const getComponetValues = (componentsArray, dataArray = []) => {
  if (componentsArray.length) {
    componentsArray.forEach((comp) => {
      if (comp.ref && comp.ref.current) {
        dataArray.push({
          name: comp.ref.current.getName(),
          type: comp.ref.current.getType(),
          value: comp.ref.current.getLatestValue(),
        })
      }
    })
  }

  return getNewArray(dataArray)
}

export const getNewNodeValues = (element) => {
  const data = element.length ? element[0].data : null
  const id = element.length ? element[0].id : null
  const tabs = data && data.tabs && data.tabs.length ? data.tabs[0] : null
  const fields = tabs && tabs.fields && tabs.fields.length ? tabs.fields : null

  if (data && id && tabs && fields) {
    const values = []

    fields.forEach(({ name, type }) => {
      if (
        type === NODE_TYPES.mapNumberToNode ||
        type === NODE_TYPES.mapNumberOrStringToNode ||
        type === NODE_TYPES.audio ||
        type === NODE_TYPES.announcement
      ) {
        values.push({
          name: name,
          type: type,
          key_value_pair: [],
        })
      } else if (type === NODE_TYPES.switch) {
        values.push({
          name: name,
          type: type,
          value: false,
        })
      } else if (type === NODE_TYPES.voiceUser) {
        values.push({
          name: name,
          type: type,
          value_array: [],
        })
      } else {
        values.push({
          name: name,
          type: type,
          value: '',
        })
      }
    })

    return values
  }
}

export const getValues = (fieldValues) => {
  const values = []

  if (fieldValues.length) {
    fieldValues.forEach((fieldValue) => {
      if (
        fieldValue.type === NODE_TYPES.mapNumberToNode ||
        fieldValue.type === NODE_TYPES.mapNumberOrStringToNode ||
        fieldValue.type === NODE_TYPES.audio ||
        fieldValue.type === NODE_TYPES.announcement
      ) {
        values.push({
          name: fieldValue.name,
          key_value_pair: fieldValue.value,
          type: fieldValue.type,
        })
      } else if (fieldValue.type === NODE_TYPES.voiceUser) {
        values.push({
          name: fieldValue.name,
          type: fieldValue.type,
          value_array: fieldValue.value,
        })
      } else {
        values.push({
          name: fieldValue.name,
          type: fieldValue.type,
          value: fieldValue.value,
        })
      }
    })
  }

  return values
}

export const getNodesDropDownData = (callFlow, nodeList) => {
  const nodes = []

  if (callFlow.length && nodeList.length) {
    callFlow.forEach((flow) => {
      const nodeData = nodeList.filter((node) => node.id === flow.node_id)

      if (nodeData.length) {
        const option = {
          callFlowId: flow.call_flow_node_id,
          icon: nodeData[0].data.icon,
          label: nodeData[0].data.label,
          name: flow.name,
          nodeId: nodeData[0].id,
        }

        nodes.push(option)
      }
    })
  }

  return nodes
}

export const getLabelByLang = (label, lang) => {
  return label[lang] ? label[lang] : ''
}

export const isTextSame = (string1 = '', string2 = '') => {
  return string1.localeCompare(string2) === 0
}

export const getNewArray = (array = []) => {
  return JSON.parse(JSON.stringify(array))
}

export const keyDownHandler = (event, validator) => {
  const keyCode = event.which || event.keyCode

  if (event.ctrlKey || event.metaKey) {
    return
  }

  if (validator === 'allowNumbersAndChars') {
    if (
      !(
        (keyCode >= 48 && keyCode <= 57 && !event.shiftKey) ||
        (keyCode >= 96 && keyCode <= 105) ||
        keyCode === 107 ||
        (keyCode === 187 && event.shiftKey) ||
        keyCode === 106 ||
        keyCode === 56 ||
        keyCode === 8 ||
        keyCode === 9 ||
        keyCode === 51 ||
        keyCode === 46 ||
        (keyCode >= 35 && keyCode <= 40)
      )
    ) {
      event.preventDefault()
    }
  } else if (validator === 'allowOnlyNumbers') {
    if (
      !(
        (keyCode >= 48 && keyCode <= 57 && !event.shiftKey) ||
        (keyCode >= 96 && keyCode <= 105) ||
        keyCode === 8 ||
        keyCode === 9 ||
        keyCode === 46 ||
        (keyCode >= 35 && keyCode <= 40)
      )
    ) {
      event.preventDefault()
    }
  }
}

export const replaceAll = (text, search, replacement) => {
  return text.split(search).join(replacement)
}

export const pasteHandler = (event, validator) => {
  const numsAndCharsArray = [
    35,
    42,
    43,
    48,
    49,
    50,
    51,
    52,
    53,
    54,
    55,
    56,
    57,
    9,
  ]
  const numsArray = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 9]

  const validKeys =
    validator === 'allowOnlyNumbers' ? numsArray : numsAndCharsArray
  let actualValue = event.clipboardData.getData('Text')

  for (let index = 0; index < actualValue.length; index++) {
    if (!validKeys.includes(actualValue.charCodeAt(index))) {
      actualValue =
        actualValue.substr(0, index) + '.' + actualValue.substr(index + 1)
    }
  }
  const valueToPaste = replaceAll(actualValue, '.', '')
  const element = event.target

  if (
    element.selectionStart === 0 &&
    element.selectionEnd === element.value.length
  ) {
    element.value = valueToPaste
  } else {
    element.value =
      element.value.substr(0, element.selectionStart) +
      valueToPaste +
      element.value.substr(element.selectionEnd, element.value.length)
  }
  event.preventDefault()
}

const getSubdomain = (domain) => {
  const splitter = domain.split('.')

  return splitter.length === 3 && splitter[0].length ? splitter[0] : ''
}

const getEnviroinmentCollection = () => {
  const collection = []

  collection['development'] = { name: 'dev', collections: dev }
  collection['staging'] = { name: 'stage', collections: stage }
  collection['production'] = { name: 'prod', collections: prod }

  return collection
}

export const fetchEnviroinment = (type = 'env') => {
  const domain = window.location.hostname
  const subdomain = getSubdomain(domain)
  const enviroinmentCollection = getEnviroinmentCollection()

  if (domain.includes('localhost')) {
    return type === 'env'
      ? enviroinmentCollection['staging'].collections
      : enviroinmentCollection['staging'].name
  } else if (subdomain === 'dev-app') {
    return type === 'env'
      ? enviroinmentCollection['staging'].collections
      : enviroinmentCollection['staging'].name
  } else if (subdomain === 'staging-app') {
    return type === 'env'
      ? enviroinmentCollection['staging'].collections
      : enviroinmentCollection['staging'].name
  } else if (subdomain === 'app') {
    return type === 'env'
      ? enviroinmentCollection['production'].collections
      : enviroinmentCollection['production'].name
  } else {
    return type === 'env'
      ? enviroinmentCollection['development'].collections
      : enviroinmentCollection['development'].name
  }
}

/**
 * get query parameter from url
 * @param  {string} name
 * @param  {string} url
 * @return {string} query param value
 */
export function getParameterByName(name, url) {
  if (!url) {
    url = window.location.href
  }

  // eslint-disable-next-line no-useless-escape
  name = name.replace(/[\[\]]/g, '\\$&')
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url)

  if (!results) {
    return null
  }

  if (!results[2]) {
    return ''
  }

  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export const getActiveRows = (rows, keys) => {
  if (rows.length && keys.length) {
    const active = []

    keys.forEach((key) => {
      const row = rows.filter((row) => row.key === key)

      if (row.length) {
        active.push(row[0])
      }
    })

    return active
  } else {
    return []
  }
}

export const convertToCent = (amount) => {
  return amount * 100
}

export const convertToDollar = (amount) => {
  return (amount / 100).toFixed(2)
}

export const getCallingPlanHistoryId = (type, voiceUserId) => {
  const dd = new Date()
  let nextMonth = ''

  if (type === 'current') {
    nextMonth = getFormattedUpcomingMonth(dd.getMonth() + 1)
  } else {
    nextMonth = getFormattedUpcomingMonth(dd.getMonth() + 2)
  }
  const callingPlanHistoryId =
    voiceUserId +
    '@' +
    (nextMonth === '01' ? dd.getFullYear() + 1 : dd.getFullYear()) +
    '-' +
    nextMonth

  return callingPlanHistoryId
}

export const getCallingPlanMonth = (type) => {
  const dd = new Date()
  let nextMonth = ''

  if (type === 'current') {
    nextMonth = getFormattedUpcomingMonth(dd.getMonth() + 1)
  } else {
    nextMonth = getFormattedUpcomingMonth(dd.getMonth() + 2)
  }
  const callingPlanHistoryId =
    (nextMonth === '01' && type !== 'current'
      ? dd.getFullYear() + 1
      : dd.getFullYear()) + nextMonth

  return callingPlanHistoryId
}

export const getFormattedUpcomingMonth = (month) => {
  if (month > 9 && month < 13) {
    return String(month)
  } else if (month === 13) {
    return '01'
  } else {
    return '0' + month
  }
}

export const getCurrentMonth = () => {
  const dd = new Date()

  return dd.getMonth() + 1
}

export const isCurrentPlanGTCurrentMonth = (planMonth) => {
  const dd = new Date()
  const currentMonth = dd.getMonth() + 1

  if (
    (currentMonth < 12 && planMonth > currentMonth) ||
    (currentMonth === 12 && planMonth < currentMonth)
  ) {
    return true
  } else {
    return false
  }
}

export const getNodeDataFeed = (nodes) => {
  const defaultNode = nodes.find(
    (node) => node.call_flow_node_id === 'defaultCallFlowNodeId'
  )

  return {
    nodes: nodes.filter(
      (node) => node.call_flow_node_id !== 'defaultCallFlowNodeId'
    ),
    trigger: {
      icon_position: defaultNode.icon_position,
      incoming_call: defaultNode.values[0].value,
    },
  }
}

export const removeURLParameter = (url, parameter) => {
  // prefer to use l.search if you have a location/link object
  const urlparts = url.split('?')

  if (urlparts.length >= 2) {
    const prefix = encodeURIComponent(parameter) + '='
    const pars = urlparts[1].split(/[&;]/g)

    // reverse iteration as may be destructive
    for (let i = pars.length; i-- > 0; ) {
      // idiom for string.startsWith
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1)
      }
    }
    url = urlparts[0] + '?' + pars.join('&')

    return url
  } else {
    return url
  }
}

export const getBrandFromUrl = () => {
  const getBrand = () => {
    const domains = window.location.hostname.split('.')
    const urlBrand = domains.length === 3 ? domains[1] : ''

    if (urlBrand === 'mydirectrouting') {
      return 'My Direct Routing'
    } else if (urlBrand === 'plug2teams') {
      return 'Plug2Teams'
    } else {
      return 'Plug2Teams'
    }
  }

  return getBrand()
}

export const getInfoUrlsFromBrand = () => {
  const urlBrand = getBrandFromUrl()

  if (urlBrand === 'My Direct Routing') {
    return {
      contactPageUrl: 'https://mydirectrouting.com.com/contact/',
      requirementsPageUrl: 'https://mydirectrouting.com/requirements/',
      termsAndConditionPageUrl:
        'https://mydirectrouting.com.com/terms-and-conditions/',
      privacyPolicyPageUrl: 'https://mydirectrouting.com.com/privacy-policy/',
    }
  } else {
    return {
      contactPageUrl: 'https://plug2teams.com/contact/',
      requirementsPageUrl: 'https://plug2teams.com/requirements/',
      termsAndConditionPageUrl: 'https://plug2teams.com/terms-and-conditions/',
      privacyPolicyPageUrl: 'https://plug2teams.com/privacy-policy/',
    }
  }
}
