import React from 'react'
import { useSelector } from 'react-redux'
import PttLayout from '../components/Home/PttHome/PttLayout'
import PhoneNumber from '../components/IncomingCalls/SubComponents/PhoneNumber'
import { getTranslatedText } from '../utils/translations'

function PhoneNumbersPage() {
  const { language, translations } = useSelector(({ sampleReducer }) => ({
    language: sampleReducer.language || 'en',
    translations: sampleReducer.translations || {},
  }))

  return (
    <PttLayout
      currentkey="2"
      title={getTranslatedText(translations, language, 'phone_numbers')}
    >
      <PhoneNumber />
    </PttLayout>
  )
}

export default PhoneNumbersPage
