import React from 'react'
import { useSelector } from 'react-redux'
import Billing from '../components/Billing'
import PttLayout from '../components/Home/PttHome/PttLayout'
import { getTranslatedText } from '../utils/translations'

function BillingPage() {
  const { language, translations } = useSelector(({ sampleReducer }) => ({
    language: sampleReducer.language || 'en',
    translations: sampleReducer.translations || {},
  }))

  return (
    <PttLayout
      currentkey="5"
      title={getTranslatedText(translations, language, 'billing')}
    >
      <Billing />
    </PttLayout>
  )
}

export default BillingPage
