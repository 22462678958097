/* eslint-disable max-len */
/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { getInfoUrlsFromBrand } from './helpers'

/*
COPY AND PAST TEMPLATE

  MISSING: {
    en: '',
    es: '',
    ca: '',
    eu: '',
    gl: '',
    pt: '',
    fr: '',
    it: '',
    de: ''
  },
*/

const urls = getInfoUrlsFromBrand()
const contactPageUrl = urls.contactPageUrl
const requirementsPageUrl = urls.requirementsPageUrl
const termsAndConditionPageUrl = urls.termsAndConditionPageUrl
const privacyPolicyPageUrl = urls.privacyPolicyPageUrl

export const availableLanguages = [
  { name: 'English', code: 'en', exactCode: 'US' },
  // { name: 'Español', code: 'es', exactCode: 'ES' },
  // { name: 'Català', code: 'ca', exactCode: 'CA' },
  // { name: 'Português', code: 'pt', exactCode: 'PT' },
]

export const getTranslatedText = (
  translationObject = {},
  languageCode = 'en',
  entity = ''
) => {
  const exactObject = translationObject[entity]

  if (exactObject) {
    if (exactObject[languageCode] && exactObject[languageCode].length) {
      return exactObject[languageCode]
    } else if (exactObject['en'] && exactObject['en'].length) {
      return exactObject['en']
    } else {
      return ''
    }
  } else {
    return ''
  }
}

export const translations = {
  SOMETHING_WENT_WRONG: {
    en:
      'Oops! Something went wrong. Please try again later or contact our support team if the error persists.',
    es: '',
    ca: '',
    pt: '',
  },
  ERROR_CREATING_ACCOUNT: {
    en:
      'Oops! Something went wrong while creating you account. Please try again later or contact our support team.',
    es: '',
    ca: '',
    pt: '',
  },
  please_wait: {
    en: 'Please wait...',
    es: '',
    ca: '',
    pt: '',
  },
  USER_ALREADY_EXIST: {
    en: 'A user with this e-mail already exist',
    es: '',
    ca: '',
    pt: '',
  },
  VOICE_USER: {
    en: 'Microsoft user subscription',
    es: '',
    ca: '',
    pt: '',
  },
  CALLING_PLAN: {
    en: 'Calling plan subscription',
    es: '',
    ca: '',
    pt: '',
  },
  TRIAL_BONUS: {
    en: 'Trial bonus credit',
    es: '',
    ca: '',
    pt: '',
  },
  PHONE_NUMBER: {
    en: 'Phone number subscription',
    es: '',
    ca: '',
    pt: '',
  },
  MONTHLY_SUBSCRIPTION: {
    en: 'Monthly subscription',
    es: '',
    ca: '',
    pt: '',
  },
  TOP_UP: {
    en: 'Topup',
    es: '',
    ca: '',
    pt: '',
  },
  from_balance: {
    en: 'From balance',
    es: '',
    ca: '',
    pt: '',
  },
  SIGNUP_CREDIT: {
    en: 'Signup credit',
    es: '',
    ca: '',
    pt: '',
  },
  vat_info: {
    en: 'You will be charged 21% VAT on top of the selected amount',
    es: '',
    ca: '',
    pt: '',
  },
  continue_auto_setup: {
    en: 'Continue auto setup',
    es: '',
    ca: '',
    pt: '',
  },
  continue_auto_setup_text: {
    en:
      'We have not finished configuring you Microsoft Tenant. Please click the button below to finish the auto setup of Microsoft Tenant.',
    es: '',
    ca: '',
    pt: '',
  },

  /* Signup, Login and Logout */
  back_to_admin: {
    en: 'Back To Admin',
    es: '',
    ca: '',
    pt: '',
  },
  client_redirect_success: {
    en: <span>Please wait while we redirect you to Client&apos;s account</span>,
    es: '',
    ca: '',
    pt: '',
  },
  admin_redirect_success: {
    en: <span>Please wait while we redirect you to Admin&apos;s account</span>,
    es: '',
    ca: '',
    pt: '',
  },
  client_redirect_error: {
    en: (
      <span>
        Error configuring Client&apos;s account, Please try again later
      </span>
    ),
    es: '',
    ca: '',
    pt: '',
  },
  client_redirect_access_error: {
    en: (
      <span>
        You don&apos;t have permission to access this account, Please consult
        support team
      </span>
    ),
    es: '',
    ca: '',
    pt: '',
  },
  admin_redirect_error: {
    en: (
      <span>
        Error configuring Admin&apos;s account, Please try again later
      </span>
    ),
    es: '',
    ca: '',
    pt: '',
  },
  or: {
    en: 'or',
    es: '',
    ca: '',
    pt: '',
  },
  define_password: {
    en: 'Create account',
    es: '',
    ca: '',
    pt: '',
  },
  define_password_info: {
    en: 'Please fill up the information below to finish creating your account',
    es: '',
    ca: '',
    pt: '',
  },
  login: {
    en: 'Login',
    es: '',
    ca: '',
    pt: '',
  },
  TOPUP: {
    en: 'Topup',
    es: '',
    ca: '',
    pt: '',
  },
  logout: {
    en: 'Logout',
    es: '',
    ca: '',
    pt: '',
  },
  Chat_Support: {
    en: 'Chat support',
    es: '',
    ca: '',
    pt: '',
  },
  sign_up: {
    en: 'Signup',
    es: '',
    ca: '',
    pt: '',
  },
  dont_have_an_account: {
    en: "Don't have an account yet? Sign up here",
    es: '',
    ca: '',
    pt: '',
  },
  get_started: {
    en: "Let's get started!",
    es: '',
    ca: '',
    pt: '',
  },
  pls_enter_email: {
    en: 'Please enter your email',
    es: '',
    ca: '',
    pt: '',
  },
  pls_enter_valid_email: {
    en: 'Please enter valid email',
    es: '',
    ca: '',
    pt: '',
  },
  will_send_link: {
    en: 'we will send you a link where you can define your password',
    es: '',
    ca: '',
    pt: '',
  },
  will_send_link_to_redifine: {
    en: 'we will send you a link where you can redefine your password',
    es: '',
    ca: '',
    pt: '',
  },
  recover_password: {
    en: 'Recover password',
    es: '',
    ca: '',
    pt: '',
  },
  please_enter_your_new_password: {
    en: 'Please enter your new password',
    es: '',
    ca: '',
    pt: '',
  },
  reset_your_password: {
    en: 'Reset your password',
    es: '',
    ca: '',
    pt: '',
  },
  reset_password: {
    en: 'Reset password',
    es: '',
    ca: '',
    pt: '',
  },
  check_email: {
    en: 'Please check your email',
    es: '',
    ca: '',
    pt: '',
  },
  link_sent: {
    en: 'we have sent you a link where you can define your password',
    es: '',
    ca: '',
    pt: '',
  },
  link_sent_redifine: {
    en: 'we have sent you a link where you can redefine your password',
    es: '',
    ca: '',
    pt: '',
  },
  startup_image_text: {
    en: 'Connect your Teams to the world',
    es: '',
    ca: '',
    pt: '',
  },
  already_have_account: {
    en: 'Already have an account? Log in here',
    es: '',
    ca: '',
    pt: '',
  },
  e_mail: {
    en: 'E-mail',
    es: '',
    ca: '',
    pt: '',
  },
  password: {
    en: 'Password',
    es: '',
    ca: '',
    pt: '',
  },
  new_password: {
    en: 'New password',
    es: '',
    ca: '',
    pt: '',
  },
  password_updated_successfully: {
    en: 'Password updated successfully!',
    es: '',
    ca: '',
    pt: '',
  },
  new_password_placeholder: {
    en: 'Please enter new password',
    es: '',
    ca: '',
    pt: '',
  },
  confirm_password: {
    en: 'Confirm password',
    es: '',
    ca: '',
    pt: '',
  },
  confirm_password_placeholder: {
    en: 'Please enter confirm password',
    es: '',
    ca: '',
    pt: '',
  },
  password_not_match: {
    en: 'The "New password" and "Confirm password" field didn’t match',
    es: '',
    ca: '',
    pt: '',
  },
  password_change_invalid: {
    en: 'Your old password and new password can’t be the same',
    es: '',
    ca: '',
    pt: '',
  },
  create_account: {
    en: 'Create account',
    es: '',
    ca: '',
    pt: '',
  },

  // Microsoft team page
  microsoft_teams: {
    en: 'Microsoft Teams',
    es: '',
    ca: '',
    pt: '',
  },
  connect_to_microsoft: {
    en: 'Connect to Microsoft',
    es: '',
    ca: '',
    pt: '',
  },
  configure_your_account: {
    en: 'Microsoft Tenant auto setup',
    es: '',
    ca: '',
    pt: '',
  },
  access: {
    en: 'Access',
    es: '',
    ca: '',
    pt: '',
  },
  users: {
    en: 'Users',
    es: '',
    ca: '',
    pt: '',
  },

  // Microsoft team page -> Connect to Microsoft
  connect_to_microsoft_error: {
    // en: `Please log in to your Microsoft account as a global admin in order for us to automatically configure
    // your Microsoft Tenant and users to make and receive calls using Microsoft Teams. By doing so, you acknowledge we will
    // automatically create additional configurations to your tenant and users. Your existing configurations will not be changed.`,
    en: (
      <div style={{ textAlign: 'center' }}>
        <p>
          You are about to automatically configure your Microsoft account to
          make and receive calls using Microsoft Teams.
          <br />
          For this process to run smoothly, please check our
          <a href={requirementsPageUrl} target="_blank">
            {' '}
            requirements page here
          </a>
          .
        </p>
      </div>
    ),
    es: '',
    ca: '',
    pt: '',
  },
  no_phone_in_country: {
    en: (
      <div>
        Due to the local legislation in some countries and areas, you might need
        to provide some extra information about your business before you can
        purchase a local phone number. Please
        <a style={{ margin: '0px 5px' }} href={contactPageUrl} target="_blank">
          contact us
        </a>
        for more information.
      </div>
    ),
    es: '',
    ca: '',
    pt: '',
  },
  log_in_microsoft: {
    en: 'Log in to Microsoft',
    es: '',
    ca: '',
    pt: '',
  },
  error_recieving_access_token: {
    en:
      'Oops! Something went wrong while connecting to your Microsoft account. Please try again later or contact our support team.',
    es: '',
    ca: '',
    pt: '',
  },
  oops_something_went_wrong: {
    en:
      'Oops! Something went wrong. Please try again later or contact our support team if the error persists.',
    es: '',
    ca: '',
    pt: '',
  },
  forgot_your_password: {
    en: 'Forgot your password?',
    es: '',
    ca: '',
    pt: '',
  },
  invalid_username_or_password: {
    en: 'Invalid username or password',
    es: '',
    ca: '',
    pt: '',
  },
  invalid_password: {
    en: 'Invalid current password',
    es: '',
    ca: '',
    pt: '',
  },
  please_enter_username: {
    en: 'Please enter username',
    es: '',
    ca: '',
    pt: '',
  },
  please_enter_company_name: {
    en: 'Please enter company name',
    es: '',
    ca: '',
    pt: '',
  },
  settings_updated_succesfully: {
    en: 'Settings updated successfully!',
    es: '',
    ca: '',
    pt: '',
  },
  please_enter_password: {
    en: 'Please enter password',
    es: '',
    ca: '',
    pt: '',
  },
  logged_in_successfully: {
    en: 'Welcome!',
    es: '',
    ca: '',
    pt: '',
  },
  payment_done: {
    en: 'Payment done successfully!',
    es: '',
    ca: '',
    pt: '',
  },
  user_panel_delete_success: {
    en: 'User deleted successfully!',
    es: '',
    ca: '',
    pt: '',
  },
  user_panel_delete_error: {
    en: 'Error deleting user',
    es: '',
    ca: '',
    pt: '',
  },
  user_panel_add_success: {
    en: 'User added successfully!',
    es: '',
    ca: '',
    pt: '',
  },
  user_panel_add_error: {
    en: 'Error adding user',
    es: '',
    ca: '',
    pt: '',
  },

  // Microsoft team page -> Configure your account
  type: {
    en: 'Type',
    es: '',
    ca: '',
    pt: '',
  },
  officee_license_required: {
    en: 'Office 365 E1 unused license is required',
    es: '',
    ca: '',
    pt: '',
  },
  render_progress_info_1: {
    en: `Lay back and relax while we automatically configure your Microsoft
    account for you.`,
    es: '',
    ca: '',
    pt: '',
  },
  render_progress_info_2: {
    en: 'This process can take up to 5 minutes. Please do NOT leave this page.',
    es: '',
    ca: '',
    pt: '',
  },
  render_progress_info_3: {
    en:
      'All good! Your account is ready, please click the "Next" button below to continue.',
    es: '',
    ca: '',
    pt: '',
  },
  render_warning_header_info: {
    en: 'You need to have 2 Microsoft 365 or Office 365 licenses available',
    es: '',
    ca: '',
    pt: '',
  },
  continue: {
    en: 'Continue',
    es: '',
    ca: '',
    pt: '',
  },
  render_warning_info_1: {
    en: (
      <div style={{ textAlign: 'center' }}>
        <p>
          To continue with the auto setup of your account, please go to your
          <a
            href="https://portal.office.com/Adminportal/Home?#/licensedetailpage/3b555118-da6a-4418-894f-7df1e2096870"
            target="_blank"
          >
            {' '}
            Microsoft Admin page
          </a>{' '}
          and make sure you have at least
          <br />2 "Microsoft 365 Business Basic" licenses available (or any
          other accepted license). For more information, please visit our
          <a href={requirementsPageUrl} target="_blank">
            {' '}
            requirements page
          </a>{' '}
          or contact our support team.
        </p>
      </div>
    ),
    es: '',
    ca: '',
    pt: '',
  },
  render_warning_info_2: {
    en: `These licenses will be assigned to the 2 users this wizard will
    create.`,
    es: '',
    ca: '',
    pt: '',
  },
  please: {
    en: 'Please',
    es: '',
    ca: '',
    pt: '',
  },
  render_warning_info_3: {
    en:
      'to make sure you have the 2 unused E1 lisenses and click the "Retry" button below.',
    es: '',
    ca: '',
    pt: '',
  },
  render_warning_info_button: {
    en: 'go to your Microsoft Admin console',
    es: '',
    ca: '',
    pt: '',
  },
  retry: {
    en: 'Retry',
    es: '',
    ca: '',
    pt: '',
  },

  plug2teams_is_connected_to_your_microsoft_account: {
    en: 'Connected to your Microsoft Account',
    es: '',
    ca: '',
    pt: '',
  },
  delete: {
    en: 'Delete',
    es: '',
    ca: '',
    pt: '',
  },
  delete_connector: {
    en: 'Delete Connector',
    es: '',
    ca: '',
    pt: '',
  },
  you_have_not_added_any_user_from_your_microsoft_teams: {
    en: 'You have not added any user from your Microsoft Teams',
    es: '',
    ca: '',
    pt: '',
  },
  search: {
    en: 'Search',
    es: '',
    ca: '',
    pt: '',
  },
  clear_filters: {
    en: 'clear filters',
    es: '',
    ca: '',
    pt: '',
  },
  add_user: {
    en: 'Add User',
    es: '',
    ca: '',
    pt: '',
  },
  add_users: {
    en: 'Add Users',
    es: '',
    ca: '',
    pt: '',
  },
  voice_users: {
    en: 'Microsoft users',
    es: '',
    ca: '',
    pt: '',
  },
  status: {
    en: 'Status',
    es: '',
    ca: '',
    pt: '',
  },
  delete_connector_info: {
    en: (
      <div style={{ textAlign: 'center' }}>
        Are you sure you want to remove the connection between us and your
        Microsoft Account?
        <br />
        All Microsoft users you have configured will stop being able to make and
        receive external calls.
      </div>
    ),
    es: '',
    ca: '',
    pt: '',
  },
  // are_you_sure_want_to: {
  //   en: 'Are you sure you want to',
  //   es: '',
  //   ca: '',
  //   pt: '',
  // },
  // this_connector_qmark: {
  //   en: 'this Connector?',
  //   es: '',
  //   ca: '',
  //   pt: '',
  // },
  // this_voice_user: {
  //   en: 'this Microsoft user?',
  //   es: '',
  //   ca: '',
  //   pt: '',
  // },
  // node: {
  //   en: 'node',
  //   es: '',
  //   ca: '',
  //   pt: '',
  // },
  delete_node_info: {
    en: 'Are you sure you want to delete this action?',
    es: '',
    ca: '',
    pt: '',
  },
  configuration_in_progress: {
    en: 'Initial setup in progress',
    es: '',
    ca: '',
    pt: '',
  },
  modify_in_progress: {
    en: 'Modification in progress',
    es: '',
    ca: '',
    pt: '',
  },
  deletion_in_progres: {
    en: 'Deletion in progress',
    es: '',
    ca: '',
    pt: '',
  },
  configuration_completed: {
    en: 'Active',
    es: '',
    ca: '',
    pt: '',
  },
  progressHelpText: {
    en: 'We are configuring your user. This could take up to 24 hours.',
    es: '',
    ca: '',
    pt: '',
  },
  user_update_success: {
    en: 'User details updated successfully!',
    es: '',
    ca: '',
    pt: '',
  },
  user_update_failed: {
    en: 'Failed updating user details',
    es: '',
    ca: '',
    pt: '',
  },

  // Microsoft team -> Add User model popup
  select_users: {
    en: 'Select users',
    es: '',
    ca: '',
    pt: '',
  },
  configure_users: {
    en: 'Configure users',
    es: '',
    ca: '',
    pt: '',
  },
  checkout: {
    en: 'Checkout',
    es: '',
    ca: '',
    pt: '',
  },
  next: {
    en: 'Next',
    es: '',
    ca: '',
    pt: '',
  },
  back: {
    en: 'Back',
    es: '',
    ca: '',
    pt: '',
  },
  confirm: {
    en: 'Confirm',
    es: '',
    ca: '',
    pt: '',
  },
  okay: {
    en: 'Okay',
    es: '',
    ca: '',
    pt: '',
  },
  select_phone_number_error: {
    en: 'Please select phone number',
    es: '',
    ca: '',
    pt: '',
  },
  select_extension_error: {
    en: 'Please enter valid extension',
    es: '',
    ca: '',
    pt: '',
  },
  info: {
    en: 'Information',
    es: '',
    ca: '',
    pt: '',
  },

  // Microsoft team -> Add User model popup -> Select Users
  refresh: {
    en: 'Refresh',
    es: '',
    ca: '',
    pt: '',
  },
  info_text: {
    en: `Select the users from Microsoft Teams, you want to enable to make and
    receive voice calls. Notice that, in order to use this functionality,
    Microsoft requires the user to have the Phone System license. If you want to
    select a user that does not have the required license, please go to
    your Microsoft Admin page, add the missing license to the desired
    user, come back here and refresh the users.`,
    es: '',
    ca: '',
    pt: '',
  },
  invalid_license_text: {
    en: `The user must have the Phone System license to enable the voice services. You can add this user later after you update his license in your Microsoft account`,
    es: '',
    ca: '',
    pt: '',
  },
  search_by_display_name: {
    en: 'Search by display name',
    es: '',
    ca: '',
    pt: '',
  },
  display_name: {
    en: 'Display name',
    es: '',
    ca: '',
    pt: '',
  },
  enter_display_name: {
    en: 'please enter display name',
    es: '',
    ca: '',
    pt: '',
  },
  enter_given_name: {
    en: 'please enter given name',
    es: '',
    ca: '',
    pt: '',
  },
  enter_last_name: {
    en: 'please enter last name',
    es: '',
    ca: '',
    pt: '',
  },
  email: {
    en: 'Email',
    es: '',
    ca: '',
    pt: '',
  },
  microsoft_license: {
    en: 'Microsoft license',
    es: '',
    ca: '',
    pt: '',
  },

  // Microsoft team -> Add User model popup -> Configure users
  phone_number_label: {
    en: 'Phone number',
    es: '',
    ca: '',
    pt: '',
  },
  extension: {
    en: 'Extension',
    es: '',
    ca: '',
    pt: '',
  },
  extension_number: {
    en: 'Extension number',
    es: '',
    ca: '',
    pt: '',
  },
  extension_in_use: {
    en: 'Extension already in use',
    es: '',
    ca: '',
    pt: '',
  },
  select_a_phone_pumber: {
    en: 'Select a phone number',
    es: '',
    ca: '',
    pt: '',
  },

  // Microsoft team -> Add User model popup -> Checkout
  current_account_balance: {
    en: 'Current account balance',
    es: '',
    ca: '',
    pt: '',
  },
  monthly_charge: {
    en: 'Monthly charge',
    es: '',
    ca: '',
    pt: '',
  },
  monthly_price: {
    en: 'Monthly price',
    es: '',
    ca: '',
    pt: '',
  },
  monthly_unit_price: {
    en: 'Monthly unit price',
    es: '',
    ca: '',
    pt: '',
  },
  monthly_total_price: {
    en: 'Monthly total price',
    es: '',
    ca: '',
    pt: '',
  },
  users_using: {
    en: 'Users using',
    es: '',
    ca: '',
    pt: '',
  },
  checkout_charge_info_1: {
    en: 'You will be charged $',
    es: '',
    ca: '',
    pt: '',
  },
  checkout_charge_info_2: {
    en: `from your current balance now in regards to the 
    remaining days of the current month and $`,
    es: '',
    ca: '',
    pt: '',
  },
  checkout_charge_info_3: {
    en: ' monthly from next month.',
    es: '',
    ca: '',
    pt: '',
  },
  licence: {
    en: 'Licence',
    es: '',
    ca: '',
    pt: '',
  },
  quantity: {
    en: 'Quantity',
    es: '',
    ca: '',
    pt: '',
  },
  unit_price: {
    en: 'Unit price',
    es: '',
    ca: '',
    pt: '',
  },
  total_price: {
    en: 'Total price',
    es: '',
    ca: '',
    pt: '',
  },
  total: {
    en: 'Total',
    es: '',
    ca: '',
    pt: '',
  },
  microsoft_user: {
    en: 'Microsoft user',
    es: '',
    ca: '',
    pt: '',
  },

  // Microsoft team -> Editevoice user
  given_name: {
    en: 'Given Name',
    es: '',
    ca: '',
    pt: '',
  },
  last_name: {
    en: 'Last Name',
    es: '',
    ca: '',
    pt: '',
  },
  description: {
    en: 'Description',
    es: '',
    ca: '',
    pt: '',
  },
  calling_plan: {
    en: 'Calling plan',
    es: '',
    ca: '',
    pt: '',
  },
  calling_plans: {
    en: 'Calling plans',
    es: '',
    ca: '',
    pt: '',
  },
  current_billing_cycle: {
    en: 'Your current calling plan',
    es: '',
    ca: '',
    pt: '',
  },
  next_billing_cycle: {
    en: 'Change it on the next billing cycle',
    es: '',
    ca: '',
    pt: '',
  },
  add_calling_plan: {
    en: 'Add calling plan',
    es: '',
    ca: '',
    pt: '',
  },
  save: {
    en: 'Save',
    es: '',
    ca: '',
    pt: '',
  },
  change_password: {
    en: 'Change password',
    es: '',
    ca: '',
    pt: '',
  },
  current_password: {
    en: 'Current password',
    es: '',
    ca: '',
    pt: '',
  },
  current_password_placeholder: {
    en: 'Please enter current password',
    es: '',
    ca: '',
    pt: '',
  },
  delete_voice_user: {
    en: 'Disconnect from external calls',
    es: '',
    ca: '',
    pt: '',
  },
  delete_voice_user_info: {
    en: (
      <div style={{ textAlign: 'center' }}>
        Are you sure you want to disconnect this user from the public network?
        <br />
        This user will not be able to make nor receive external calls any more.
        <br />
        He will still be able to make internal calls to other Microsoft Teams
        users.
      </div>
    ),
    es: '',
    ca: '',
    pt: '',
  },

  // Microsoft team -> Editevoice user -> Add calling plan
  the_user_will_make_calls_to: {
    en: 'The user will make calls to',
    es: '',
    ca: '',
    pt: '',
  },
  please_select_a_country: {
    en: 'Please select a country',
    es: '',
    ca: '',
    pt: '',
  },
  select_a_country: {
    en: 'Select a country',
    es: '',
    ca: '',
    pt: '',
  },
  name: {
    en: 'Name',
    es: '',
    ca: '',
    pt: '',
  },
  username: {
    en: 'Username',
    es: '',
    ca: '',
    pt: '',
  },
  minutes: {
    en: 'Minutes',
    es: '',
    ca: '',
    pt: '',
  },
  price: {
    en: 'Price',
    es: '',
    ca: '',
    pt: '',
  },
  voice_user_unsaved_changes: {
    en: 'Save Microsoft user',
    es: '',
    ca: '',
    pt: '',
  },
  leave_checkout: {
    en: 'Leave checkout',
    es: '',
    ca: '',
    pt: '',
  },
  leave_connector: {
    en: 'Leave creating connector',
    es: '',
    ca: '',
    pt: '',
  },
  leave_addusers: {
    en: 'Leave adding users',
    es: '',
    ca: '',
    pt: '',
  },
  leave_connector_info: {
    en: 'Do you really want to leave creating connector?',
    es: '',
    ca: '',
    pt: '',
  },
  leave_addusers_info: {
    en: 'Do you really want to leave adding Micosoft users?',
    es: '',
    ca: '',
    pt: '',
  },
  leave_checkout_info: {
    en: 'Your changes are not saved yet, are you sure you want to leave?',
    es: '',
    ca: '',
    pt: '',
  },
  settings_unsaved_changes: {
    en: 'Save unsaved settings',
    es: '',
    ca: '',
    pt: '',
  },
  voice_user_unsaved_changes_info: {
    en: 'Do you want to save your Microsoft user before leaving?',
    es: '',
    ca: '',
    pt: '',
  },
  settings_unsaved_changes_info: {
    en: 'Do you want to save your settings before leaving?',
    es: '',
    ca: '',
    pt: '',
  },

  // Microsoft team wizard ends
  done: {
    en: 'Done!',
    es: '',
    ca: '',
    pt: '',
  },
  got_it: {
    en: 'Got it!',
    es: '',
    ca: '',
    pt: '',
  },
  allset_info_1: {
    en:
      'We are processing your configuration and will notify you by email once it`s done.',
    es: '',
    ca: '',
    pt: '',
  },
  allset_info_2: {
    en: 'This could take up to 24h.',
    es: '',
    ca: '',
    pt: '',
  },
  unable_to_remove_user_number: {
    en:
      'This phone number is currently assigned to user(s) and cannot be removed.',
    es: '',
    ca: '',
    pt: '',
  },
  unable_to_remove_callflow_number: {
    en:
      'This phone number is currently assigned to call flow(s) and cannot be removed.',
    es: '',
    ca: '',
    pt: '',
  },
  // Phone Number
  phone_numbers: {
    en: 'Phone numbers',
    es: '',
    ca: '',
    pt: '',
  },
  month: {
    en: 'Month',
    es: '',
    ca: '',
    pt: '',
  },
  country: {
    en: 'Country',
    es: '',
    ca: '',
    pt: '',
  },
  area: {
    en: 'Area',
    es: '',
    ca: '',
    pt: '',
  },

  // Phone Number -> Add Phone Number
  add_phone_number: {
    en: 'Add phone number',
    es: '',
    ca: '',
    pt: '',
  },
  choose_phone_number: {
    en: 'Choose phone number',
    es: '',
    ca: '',
    pt: '',
  },
  apply_tc: {
    en: (
      <div>
        By creating an account, you accept our
        <a
          href={termsAndConditionPageUrl}
          target="_blank"
          style={{ margin: '0px 5px' }}
        >
          Terms and conditions,
        </a>
        and
        <a
          href={privacyPolicyPageUrl}
          target="_blank"
          style={{ marginLeft: 5 }}
        >
          Privacy policy
        </a>
        .
      </div>
    ),
    es: '',
    ca: '',
    pt: '',
  },
  agree_terms_and_conditions_info: {
    en:
      'You should agree to the terms and conditions and privacy policy to create your account',
    es: '',
    ca: '',
    pt: '',
  },
  agree_terms_and_conditions: {
    en: (
      <div>
        I agree to the
        <a
          href={termsAndConditionPageUrl}
          target="_blank"
          style={{ margin: '0px 5px' }}
        >
          Terms and conditions
        </a>
        and
        <a
          href={privacyPolicyPageUrl}
          target="_blank"
          style={{ marginLeft: 5 }}
        >
          Privacy policy
        </a>
        .
      </div>
    ),
    es: '',
    ca: '',
    pt: '',
  },
  choose_phone_number_info: {
    en: (
      <>
        If you need a phone number from a different area or country that's not
        listed below, please
        <a href={contactPageUrl} target="_blank">
          {' '}
          contact us
        </a>
        .
      </>
    ),
    es: (
      <>
        Si necesitas un número de una localidad o pais que no está listada
        abajo, por favor,
        <a href={contactPageUrl} target="_blank">
          {' '}
          entre en contacto
        </a>{' '}
        con nosotros.
      </>
    ),
    ca: '',
    pt: (
      <>
        Se você necessita um número de uma localidade ou de um país que não está
        listado abaixo, por favor,
        <a href={contactPageUrl} target="_blank">
          {' '}
          entre em contato
        </a>{' '}
        com a gente.
      </>
    ),
  },

  // Phone Number -> Add Phone Number -> Choose Phone Number
  select_country: {
    en: 'Select country',
    es: '',
    ca: '',
    pt: '',
  },
  select_area: {
    en: 'Select area',
    es: '',
    ca: '',
    pt: '',
  },
  number: {
    en: 'Number',
    es: '',
    ca: '',
    pt: '',
  },

  // Phone Number -> Add Phone Number -> Checkout
  phone: {
    en: 'Phone',
    es: '',
    ca: '',
    pt: '',
  },
  checkout_info: {
    en: `Your current account balance is insufficient. Please top up your
    account before confirming your checkout`,
    es: '',
    ca: '',
    pt: '',
  },

  // Phone Number -> Edite Phone Number
  send_incoming_calls_to: {
    en: 'Send incoming calls to',
    es: '',
    ca: '',
    pt: '',
  },
  call_flow: {
    en: 'Call flow',
    es: '',
    ca: '',
    pt: '',
  },
  user: {
    en: 'User',
    es: '',
    ca: '',
    pt: '',
  },
  select_a_callflow: {
    en: 'Select a call flow',
    es: '',
    ca: '',
    pt: '',
  },
  select_a_callingplan: {
    en: 'Select a Calling plan',
    es: '',
    ca: '',
    pt: '',
  },
  select_a_user: {
    en: 'Select a user',
    es: '',
    ca: '',
    pt: '',
  },
  cancel_subscription: {
    en: 'Cancel subscription',
    es: '',
    ca: '',
    pt: '',
  },
  warning_existing_phone_number_not_saved: {
    en: 'Wait until existing phone number get saved!',
    es: '',
    ca: '',
    pt: '',
  },
  my_account: {
    en: 'My Account',
    es: '',
    ca: '',
    pt: '',
  },

  // Phone Number -> Edite Phone Number -> Cancel subscription
  cancel_phone_number_subscription: {
    en: 'Cancel phone number subscription',
    es: '',
    ca: '',
    pt: '',
  },
  cancel_phone_number_subscription_info_1: {
    en: 'Are you sure you want to cancel the subscription of this number and',
    es: '',
    ca: '',
    pt: '',
  },
  cancel_phone_number_subscription_info_2: {
    en: 'remove it from your account? The incoming calls to this number will',
    es: '',
    ca: '',
    pt: '',
  },
  cancel_phone_number_subscription_info_3: {
    en: 'stop working and the number will be available for other people to',
    es: '',
    ca: '',
    pt: '',
  },
  cancel_phone_number_subscription_info_4: {
    en: 'purchase it so you might not be able to recover it.',
    es: '',
    ca: '',
    pt: '',
  },
  keep_the_number: {
    en: 'Keep the number',
    es: '',
    ca: '',
    pt: '',
  },
  remove_the_number: {
    en: 'Remove the number',
    es: '',
    ca: '',
    pt: '',
  },
  subscription_cancelled_sucessfully: {
    en: 'Phone number subscription cancelled successfully!',
    es: '',
    ca: '',
    pt: '',
  },
  subscriptions: {
    en: 'Subscriptions',
    es: '',
    ca: '',
    pt: '',
  },
  clients_title: {
    en: 'Clients',
    es: '',
    ca: '',
    pt: '',
  },
  add_client: {
    en: 'Add Client',
    es: '',
    ca: '',
    pt: '',
  },
  // Call Flow Page
  call_flow_title: {
    en: 'Call flow',
    es: '',
    ca: '',
    pt: '',
  },
  add_call_flow: {
    en: 'Add call flow',
    es: '',
    ca: '',
    pt: '',
  },
  callflow_unsaved_changes: {
    en: 'Save call flow',
    es: '',
    ca: '',
    pt: '',
  },
  callflow_unsaved_changes_info: {
    en: 'Do you want to save your call flow before closing it?',
    es: '',
    ca: '',
    pt: '',
  },
  callflow_delete_audio: {
    en: 'Are you sure want to delete this audio? This action cannot be undone',
    es: '',
    ca: '',
    pt: '',
  },
  yes: {
    en: 'Yes',
    es: '',
    ca: '',
    pt: '',
  },
  no: {
    en: 'No',
    es: '',
    ca: '',
    pt: '',
  },
  no_content: {
    en: 'No content',
    es: '',
    ca: '',
    pt: '',
  },
  has_more_than_one_empty_extension: {
    en:
      'is assigned to multiple users. Please assign different extension numbers to these users.',
    es: '',
    ca: '',
    pt: '',
  },

  // Call Flow Page -> Add Call Flow
  new_yorks_office: {
    en: "New York's Office",
    es: '',
    ca: '',
    pt: '',
  },
  add: {
    en: 'Add',
    es: '',
    ca: '',
    pt: '',
  },

  // Call Flow Page -> Add Call Flow -> Manage call flow
  rename: {
    en: 'Rename',
    es: '',
    ca: '',
    pt: '',
  },
  clone: {
    en: 'Clone',
    es: '',
    ca: '',
    pt: '',
  },
  delete_call_flow: {
    en: 'Delete call flow',
    es: '',
    ca: '',
    pt: '',
  },
  edit_call_flow: {
    en: 'Edit call flow',
    es: '',
    ca: '',
    pt: '',
  },
  clone_call_flow: {
    en: 'Clone call flow',
    es: '',
    ca: '',
    pt: '',
  },
  copy_of: {
    en: 'Copy of',
    es: '',
    ca: '',
    pt: '',
  },
  incoming_call: {
    en: 'INCOMING CALL',
    es: '',
    ca: '',
    pt: '',
  },
  actions_library: {
    en: 'Actions Library',
    es: '',
    ca: '',
    pt: '',
  },
  upload_file_info: {
    en: 'Click or drag file to this area to upload',
    es: '',
    ca: '',
    pt: '',
  },
  dial: {
    en: 'Dial',
    es: '',
    ca: '',
    pt: '',
  },
  or_say: {
    en: 'or Say',
    es: '',
    ca: '',
    pt: '',
  },
  add_agent: {
    en: 'Add agent',
    es: '',
    ca: '',
    pt: '',
  },
  enter_call_flow_name_info: {
    en: 'Please enter the call flow name',
    es: '',
    ca: '',
    pt: '',
  },
  call_flow_name_already_exits_info: {
    en: 'This call flow name already exists, please enter a different one',
    es: '',
    ca: '',
    pt: '',
  },
  no_options: {
    en: 'No options',
    es: '',
    ca: '',
    pt: '',
  },
  select_a_node_info: {
    en: 'Please select an action',
    es: '',
    ca: '',
    pt: '',
  },
  delete_callflow_info_1: {
    en: 'Are you sure you want to delete this call flow?',
    es: '',
    ca: '',
    pt: '',
  },
  delete_callflow_info_2: {
    en: 'This action cannot be undone',
    es: '',
    ca: '',
    pt: '',
  },

  // Call Flow Page -> Edite Add Call Flow
  add_menu_option: {
    en: 'Add menu option',
    es: '',
    ca: '',
    pt: '',
  },

  // Stats Page
  stats: {
    en: 'Stats',
    es: '',
    ca: '',
    pt: '',
  },
  no_dashboard_selected: {
    en: 'No dashboard selected',
    es: '',
    ca: '',
    pt: '',
  },

  // Billing Page
  billing: {
    en: 'Billing',
    es: '',
    ca: '',
    pt: '',
  },
  billing_email: {
    en: 'Billing e-mail',
    es: '',
    ca: '',
    pt: '',
  },
  transactions: {
    en: 'Transactions',
    es: '',
    ca: '',
    pt: '',
  },
  payment_methods: {
    en: 'Payment methods',
    es: '',
    ca: '',
    pt: '',
  },

  // Billing Page -> Transactions
  date: {
    en: 'Date',
    es: '',
    ca: '',
    pt: '',
  },
  amount: {
    en: 'Amount',
    es: '',
    ca: '',
    pt: '',
  },
  payment_method: {
    en: 'Payment method',
    es: '',
    ca: '',
    pt: '',
  },
  balance: {
    en: 'Balance',
    es: '',
    ca: '',
    pt: '',
  },
  purchased_voice_users: {
    en: 'Purchased Microsoft users',
    es: '',
    ca: '',
    pt: '',
  },
  subscribed_voice_users: {
    en: 'Subscribed Microsoft users',
    es: '',
    ca: '',
    pt: '',
  },
  purchased_phone_numbers: {
    en: 'Purchased Phone numbers',
    es: '',
    ca: '',
    pt: '',
  },
  subscribed_phone_numbers: {
    en: 'Subscribed Phone numbers',
    es: '',
    ca: '',
    pt: '',
  },
  purchased_calling_plans: {
    en: 'Purchased Calling plans',
    es: '',
    ca: '',
    pt: '',
  },
  subscribed_calling_plans: {
    en: 'Subscribed Calling plans',
    es: '',
    ca: '',
    pt: '',
  },
  topup_details: {
    en: 'Topup details',
    es: '',
    ca: '',
    pt: '',
  },
  payment_method_used: {
    en: 'Payment method used',
    es: '',
    ca: '',
    pt: '',
  },

  // Billing Page -> Transactions -> Top up
  add_credit: {
    en: 'Add credit',
    es: '',
    ca: '',
    pt: '',
  },
  pay: {
    en: 'Pay',
    es: '',
    ca: '',
    pt: '',
  },

  // Billing Page -> Payment methods
  card: {
    en: 'Card',
    es: '',
    ca: '',
    pt: '',
  },
  add_payment_method_btn: {
    en: 'Add payment method',
    es: '',
    ca: '',
    pt: '',
  },

  // Billing Page -> Payment methods -> Add Payment Method
  add_payment_method: {
    en: 'Add payment method',
    es: '',
    ca: '',
    pt: '',
  },
  name_on_card: {
    en: 'Name on card',
    es: '',
    ca: '',
    pt: '',
  },
  card_details: {
    en: 'Card details',
    es: '',
    ca: '',
    pt: '',
  },
  john_doe: {
    en: 'John Doe',
    es: '',
    ca: '',
    pt: '',
  },
  card_number: {
    en: 'Card number',
    es: '',
    ca: '',
    pt: '',
  },
  expiration_date: {
    en: 'Expiration date',
    es: '',
    ca: '',
    pt: '',
  },
  cvc: {
    en: 'CVC',
    es: '',
    ca: '',
    pt: '',
  },
  name_field_required: {
    en: 'Name field is required',
    es: '',
    ca: '',
    pt: '',
  },
  undo: {
    en: 'undo',
    es: '',
    ca: '',
    pt: '',
  },
  redo: {
    en: 'redo',
    es: '',
    ca: '',
    pt: '',
  },
  name_already_exists: {
    en: 'Name already exists',
    es: '',
    ca: '',
    pt: '',
  },
  client_updated_successfully: {
    en: 'Client details updated successfully',
    es: '',
    ca: '',
    pt: '',
  },
  client_updated_error: {
    en: 'Error updating client details',
    es: '',
    ca: '',
    pt: '',
  },

  // Billing Page -> Payment methods -> Edite Payment Method
  expiry: {
    en: 'Expiry',
    es: '',
    ca: '',
    pt: '',
  },
  delete_payment_method: {
    en: 'Delete payment method',
    es: '',
    ca: '',
    pt: '',
  },
  delete_payment_method_info: {
    en: 'Are you sure you want to delete this payment method?',
    es: '',
    ca: '',
    pt: '',
  },
  cancel: {
    en: 'Cancel',
    es: '',
    ca: '',
    pt: '',
  },

  // Success or Failed Alert message

  // Success
  payment_method_added_success: {
    en: 'Payment method added successfully!',
    es: '',
    ca: '',
    pt: '',
  },
  payment_method_removed_success: {
    en: 'Payment method removed successfully!',
    es: '',
    ca: '',
    pt: '',
  },
  call_flow_created_success: {
    en: 'Call flow created',
    es: '',
    ca: '',
    pt: '',
  },
  call_flow_created_error: {
    en:
      'Oops! something went wrong. Please try again later or contact our support team if the error persists.',
    es: '',
    ca: '',
    pt: '',
  },
  call_flow_updated_success: {
    en: 'Call flow updated',
    es: '',
    ca: '',
    pt: '',
  },
  callflow_deleted_success: {
    en: 'Call flow deleted',
    es: '',
    ca: '',
    pt: '',
  },
  callflow: {
    en: 'Call flow',
    es: '',
    ca: '',
    pt: '',
  },
  Routing_incoming_calls_to: {
    en: 'Routing incoming calls to',
    es: '',
    ca: '',
    pt: '',
  },
  phone_number_added_success: {
    en: 'Phone number added successfully!',
    es: '',
    ca: '',
    pt: '',
  },
  connector_deleted_success: {
    en: 'Connector deleted successfully!',
    es: '',
    ca: '',
    pt: '',
  },
  voice_user_deleted_success: {
    en: 'Microsoft user disconnected successfully!',
    es: '',
    ca: '',
    pt: '',
  },
  voice_user_success: {
    en: 'Microsoft user created successfully!',
    es: '',
    ca: '',
    pt: '',
  },
  voice_users_success: {
    en: 'Microsoft users created successfully!',
    es: '',
    ca: '',
    pt: '',
  },
  phonenumber_delete_success: {
    en: 'Phone number subscription cancelled successfully!',
    es: '',
    ca: '',
    pt: '',
  },

  // Warning
  bot_window_opened_warning: {
    en: 'The Chat support window is already opened',
    es: '',
    ca: '',
    pt: '',
  },

  microsoft_login_window_opened_warning: {
    en: 'The Microsoft login window is already opened',
    es: '',
    ca: '',
    pt: '',
  },
  try_linking_from_Outports_to_In_ports_only_warning: {
    en: 'Please try linking from Out-ports to In-ports only',
    es: '',
    ca: '',
    pt: '',
  },

  // Failed
  user_not_exist: {
    en: 'Ops! We did not find any user with this e-mail',
    es: '',
    ca: '',
    pt: '',
  },
  something_went_wrong: {
    en:
      'Oops! something went wrong. Please try again later or contact our support team if the error persists.',
    es: '',
    ca: '',
    pt: '',
  },
  call_flow_name: {
    en: 'Please enter the call flow name',
    es: '',
    ca: '',
    pt: '',
  },
  deleting_Callflow_failed: {
    en:
      'Oops! something went wrong. Please try again later or contact our support team if the error persists.',
    es: '',
    ca: '',
    pt: '',
  },
  connector_created_succesfully: {
    en: 'Microsoft Tenant configured successfully!',
    es: '',
    ca: '',
    pt: '',
  },
  deleting_Connector_failed: {
    en:
      'Oops! something went wrong. Please try again later or contact our support team if the error persists.',
    es: '',
    ca: '',
    pt: '',
  },
  deleting_voice_user_failed: {
    en:
      'Oops! something went wrong. Please try again later or contact our support team if the error persists.',
    es: '',
    ca: '',
    pt: '',
  },
  account_setting_api_failed: {
    en:
      'Oops! something went wrong. Please try again later or contact our support team if the error persists.',
    es: '',
    ca: '',
    pt: '',
  },
  payment_list_api_failed: {
    en:
      'Oops! something went wrong. Please try again later or contact our support team if the error persists.',
    es: '',
    ca: '',
    pt: '',
  },
  try_with_another_card_failed: {
    en:
      'Something went wrong! Please try with another card or contact our support team.',
    es: '',
    ca: '',
    pt: '',
  },

  /* User detail translations */
  userdetail_title_head: {
    en: 'User Details',
    es: '',
    ca: '',
    pt: '',
  },
  userdetail_name_alert: {
    en: 'Please enter your name',
    es: '',
    ca: '',
    pt: '',
  },
  userdetail_company_alert: {
    en: 'Please enter the company name',
    es: '',
    ca: '',
    pt: '',
  },
  userdetail_company: {
    en: 'Company name',
    es: '',
    ca: '',
    pt: '',
  },
  userdetail_name: {
    en: 'Your name',
    es: '',
    ca: '',
    pt: '',
  },
  userdetail_button: {
    en: 'Okay',
    es: '',
    ca: '',
    pt: '',
  },
  choose_a_say_option_info: {
    en: 'Please choose a say option',
    es: '',
    ca: '',
    pt: '',
  },
  userdetail_title: {
    en: 'Welcome!',
    es: '',
    ca: '',
    pt: '',
  },
  userdetail_subtitle_one: {
    en: 'please enter you name and company name',
    es: '',
    ca: '',
    pt: '',
  },
  userdetail_subtitle_two: {
    en: 'to finish up setting your account',
    es: '',
    ca: '',
    pt: '',
  },
  userdetail_name_error: {
    en:
      'Oops! something went wrong. Please try again later or contact our support team if the error persists.',
    es: '',
    ca: '',
    pt: '',
  },
  userdetail_company_error: {
    en:
      'Oops! something went wrong. Please try again later or contact our support team if the error persists.',
    es: '',
    ca: '',
    pt: '',
  },
  userdetail_update_success: {
    en: 'User details updated successfully!',
    es: '',
    ca: '',
    pt: '',
  },
  userdetail_update_error: {
    en: 'Error updating user details',
    es: '',
    ca: '',
    pt: '',
  },
  more_actions: {
    en: 'Details',
    es: '',
    ca: '',
    pt: '',
  },
  textToSpeech: {
    en: 'Text to speech',
    es: '',
    ca: '',
    pt: '',
  },
  uploadFile: {
    en: 'Upload file',
    es: '',
    ca: '',
    pt: '',
  },
  text: {
    en: 'Text',
    es: '',
    ca: '',
    pt: '',
  },
  play: {
    en: 'Play',
    es: '',
    ca: '',
    pt: '',
  },
  language: {
    en: 'Language',
    es: '',
    ca: '',
    pt: '',
  },
  speaker: {
    en: 'Speaker',
    es: '',
    ca: '',
    pt: '',
  },
  setPassword_success: {
    en: 'Created password successfully!',
    es: '',
    ca: '',
    pt: '',
  },
  setPassword_failed: {
    en:
      'Oops! something went wrong. Please try again later or contact our support team if the error persists.',
    es: '',
    ca: '',
    pt: '',
  },
  setPassword_noMatch: {
    en: 'The two passwords you have entered do not match!',
    es: '',
    ca: '',
    pt: '',
  },
  setPassword_confirmPassword: {
    en: 'Please confirm the password you want to use in your account',
    es: '',
    ca: '',
    pt: '',
  },
  setPassword_password: {
    en: 'Please enter the password you want to use in your account',
    es: '',
    ca: '',
    pt: '',
  },
  username_required_msg: {
    en: 'Please enter your name',
    es: '',
    ca: '',
    pt: '',
  },
  companyName_required_msg: {
    en: 'Please enter your company name',
    es: '',
    ca: '',
    pt: '',
  },
  countryCode_required_msg: {
    en: 'Please choose a country',
    es: '',
    ca: '',
    pt: '',
  },
  accept_audio_files_only: {
    en: 'Please upload audio files only',
    es: '',
    ca: '',
    pt: '',
  },
  error_uploading_audio: {
    en:
      'Oops! something went wrong while uploading the audio. Please try again later or contact our support team if the error persists.',
    es: '',
    ca: '',
    pt: '',
  },
  audio_delete_title: {
    en: 'Delete audio file',
    es: '',
    ca: '',
    pt: '',
  },
  audio_delete_success: {
    en: 'Audio deleted successfully!',
    es: '',
    ca: '',
    pt: '',
  },
  audio_delete_error: {
    en: 'Error deleting audio please try again later',
    es: '',
    ca: '',
    pt: '',
  },
  settings_title: {
    en: 'Settings',
    es: '',
    ca: '',
    pt: '',
  },
  settings_personal_tab: {
    en: 'Personal',
    es: '',
    ca: '',
    pt: '',
  },
  settings_company_tab: {
    en: 'Company',
    es: '',
    ca: '',
    pt: '',
  },
  settings_user_tab: {
    en: 'Access Users',
    es: '',
    ca: '',
    pt: '',
  },
  userInvite: {
    en: 'Invite user',
    es: '',
    ca: '',
    pt: '',
  },
  sendInvite: {
    en: 'Send invite',
    es: '',
    ca: '',
    pt: '',
  },
  valid: {
    en: 'Valid',
    es: '',
    ca: '',
    pt: '',
  },
  invalid: {
    en: 'Invalid VAT number',
    es: '',
    ca: '',
    pt: '',
  },
  unverified: {
    en: 'Unverified VAT number',
    es: '',
    ca: '',
    pt: '',
  },
  vat_pending: {
    en: 'VAT number is pending validation',
    es: '',
    ca: '',
    pt: '',
  },
  vat_unavailable: {
    en: 'Not able to verify',
    es: '',
    ca: '',
    pt: '',
  },
  tax_number: {
    en: 'VAT number',
    es: '',
    ca: '',
    pt: '',
  },
  spain_tax_excempt_area: {
    en: "I'm in Canarias, Ceuta o Melilla",
    es: '',
    ca: '',
    pt: '',
  },
  company_country: {
    en: 'Company country',
    es: '',
    ca: '',
    pt: '',
  },
  address: {
    en: 'Address',
    es: '',
    ca: '',
    pt: '',
  },
  postal_code: {
    en: 'Postal code',
    es: '',
    ca: '',
    pt: '',
  },
  phone_number: {
    en: 'Phone number',
    es: '',
    ca: '',
    pt: '',
  },
  invalidVat_Country: {
    en: 'The VAT number is not valid for the selected Country',
    es: '',
    ca: '',
    pt: '',
  },
  plans: {
    en: 'Plans',
    es: '',
    ca: '',
    pt: '',
  },
  account_settings_failed: {
    en:
      'Oops! something went wrong. Please try again later or contact our support team if the error persists.',
    es: '',
    ca: '',
    pt: '',
  },
  user_settings_failed: {
    en:
      'Oops! something went wrong. Please try again later or contact our support team if the error persists.',
    es: '',
    ca: '',
    pt: '',
  },
  enter_username: {
    en: 'Please enter Name',
    es: '',
    ca: '',
    pt: '',
  },
  enter_useremail: {
    en: 'Please enter Email',
    es: '',
    ca: '',
    pt: '',
  },
  account_settings_updated_sucessfully: {
    en: 'Account settings updated successfully!',
    es: '',
    ca: '',
    pt: '',
  },
  error_updating_account_settings: {
    en:
      'Oops! something went wrong. Please try again later or contact our support team if the error persists.',
    es: '',
    ca: '',
    pt: '',
  },
  user_settings_updated_sucessfully: {
    en: 'User settings updated successfully!',
    es: '',
    ca: '',
    pt: '',
  },
  error_updating_user_settings: {
    en:
      'Oops! something went wrong. Please try again later or contact our support team if the error persists.',
    es: '',
    ca: '',
    pt: '',
  },
  enter_valid_email: {
    en: 'Please enter a valid e-mail',
    es: '',
    ca: '',
    pt: '',
  },
  enter_valid_address: {
    en: 'Please enter valid address',
    es: '',
    ca: '',
    pt: '',
  },
  enter_valid_postal_code: {
    en: 'Please enter valid postal code',
    es: '',
    ca: '',
    pt: '',
  },
  enter_valid_phone_number: {
    en: 'Please enter valid phone number',
    es: '',
    ca: '',
    pt: '',
  },
  of: {
    en: 'of',
    es: 'de',
    ca: 'de',
    pt: 'de',
  },
  showing: {
    en: 'Showing',
    es: 'Mostrando',
    ca: 'Mostrant',
    pt: 'Mostrando',
  },
  page: {
    en: 'Page',
    es: '',
    ca: '',
    pt: '',
  },
  select_phone_number_here: {
    en: 'select phone numbers here',
    es: '',
    ca: '',
    pt: '',
  },
  click_change_view_phone_numbers: {
    en: 'click to change/view phone numbers here',
    es: '',
    ca: '',
    pt: '',
  },
  callflow_relation_update_success: {
    en: 'Call flow phone number updated sucessfully',
    es: '',
    ca: '',
    pt: '',
  },
  callflow_relation_update_error: {
    en: 'Error updating call flow phone number',
    es: '',
    ca: '',
    pt: '',
  },
  Email_sent_to: {
    en: 'Email sent to',
    es: '',
    ca: '',
    pt: '',
  },
  error_sending_email: {
    en: 'error sending email please try again later',
    es: '',
    ca: '',
    pt: '',
  },
  client_created_successfully: {
    en: 'Client created successfully!',
    es: '',
    ca: '',
    pt: '',
  },
  client_creation_failed: {
    en: 'Error creating client!',
    es: '',
    ca: '',
    pt: '',
  },
  client_unsaved_changes: {
    en: 'Save client details',
    es: '',
    ca: '',
    pt: '',
  },
  client_unsaved_changes_info: {
    en: 'Do you want to save your client details before leaving ?',
    es: '',
    ca: '',
    pt: '',
  },
  /* Advance tab */
  routing_unsaved_changes: {
    en: 'Save unsaved Routing',
    es: '',
    ca: '',
    pt: '',
  },
  routing_unsaved_changes_info: {
    en: 'Do you want to save unsaved Routing before leaving ?',
    es: '',
    ca: '',
    pt: '',
  },
  trunk_unsaved_changes: {
    en: 'Save unsaved Trunk',
    es: '',
    ca: '',
    pt: '',
  },
  trunk_unsaved_changes_info: {
    en: 'Do you want to save unsaved Trunk before leaving ?',
    es: '',
    ca: '',
    pt: '',
  },
  advanced: {
    en: 'Advanced',
    es: '',
    ca: '',
    pt: '',
  },
  trunks: {
    en: 'Trunks',
    es: '',
    ca: '',
    pt: '',
  },
  routing: {
    en: 'Routing',
    es: '',
    ca: '',
    pt: '',
  },
  host: {
    en: 'Host',
    es: '',
    ca: '',
    pt: '',
  },
  port: {
    en: 'Port',
    es: '',
    ca: '',
    pt: '',
  },
  protocol: {
    en: 'Protocol',
    es: '',
    ca: '',
    pt: '',
  },
  mode: {
    en: 'Mode',
    es: '',
    ca: '',
    pt: '',
  },
  host_origin: {
    en: 'Host origin',
    es: '',
    ca: '',
    pt: '',
  },
  callee: {
    en: 'Callee',
    es: '',
    ca: '',
    pt: '',
  },
  trunk: {
    en: 'Trunk',
    es: '',
    ca: '',
    pt: '',
  },
  add_trunk: {
    en: 'Add Trunk',
    es: '',
    ca: '',
    pt: '',
  },
  add_routing: {
    en: 'Add routing',
    es: '',
    ca: '',
    pt: '',
  },
  select_a_Mode: {
    en: 'Select a mode',
    es: '',
    ca: '',
    pt: '',
  },
  select_a_trunk: {
    en: 'Select a trunk',
    es: '',
    ca: '',
    pt: '',
  },
  Fail_over: {
    en: 'Fail over',
    es: '',
    ca: '',
    pt: '',
  },
  Load_balancer: {
    en: 'Load balancer',
    es: '',
    ca: '',
    pt: '',
  },
  delete_trunk: {
    en: 'Delete Trunk',
    es: '',
    ca: '',
    pt: '',
  },
  delete_trunk_info: {
    en: 'Are you sure you want to delete this trunk?',
    es: '',
    ca: '',
    pt: '',
  },
  caller: {
    en: 'Caller',
    es: '',
    ca: '',
    pt: '',
  },
  from: {
    en: 'From',
    es: '',
    ca: '',
    pt: '',
  },
  prefix: {
    en: 'Prefix',
    es: '',
    ca: '',
    pt: '',
  },
  suffix: {
    en: 'Suffix',
    es: '',
    ca: '',
    pt: '',
  },
  strip_left: {
    en: 'Strip Left',
    es: '',
    ca: '',
    pt: '',
  },
  strip_right: {
    en: 'Strip Right',
    es: '',
    ca: '',
    pt: '',
  },
  min_length: {
    en: 'Min Length',
    es: '',
    ca: '',
    pt: '',
  },
  max_length: {
    en: 'Max Length',
    es: '',
    ca: '',
    pt: '',
  },
  country_code: {
    en: 'Country Code',
    es: '',
    ca: '',
    pt: '',
  },
  symbol: {
    en: 'Symbol',
    es: '',
    ca: '',
    pt: '',
  },
  force_symbol: {
    en: 'Force Symbol',
    es: '',
    ca: '',
    pt: '',
  },
  rules: {
    en: 'Rules',
    es: '',
    ca: '',
    pt: '',
  },
  international: {
    en: 'International',
    es: '',
    ca: '',
    pt: '',
  },
  delete_user: {
    en: 'Delete User',
    es: '',
    ca: '',
    pt: '',
  },
  delete_user_info: {
    en: 'Are you sure you want to delete this user from the account?',
    es: '',
    ca: '',
    pt: '',
  },
  delete_invite_user_success: {
    en: 'User deleted sucessfully',
    es: '',
    ca: '',
    pt: '',
  },
  delete_invite_user_error: {
    en: 'Error deleting user',
    es: '',
    ca: '',
    pt: '',
  },
  delete_invite_user_account_error: {
    en: 'Error deleting user account',
    es: '',
    ca: '',
    pt: '',
  },
  delete_invite_user_alert: {
    en: 'You do not have permission to delete users',
    es: '',
    ca: '',
    pt: '',
  },
  edit_invite_user_alert: {
    en: 'You do not have permission to edit users',
    es: '',
    ca: '',
    pt: '',
  },
  delete_trunk_success: {
    en: 'Trunk deleted successfully',
    es: '',
    ca: '',
    pt: '',
  },
  delete_trunk_error: {
    en: 'Error deleting trunk',
    es: '',
    ca: '',
    pt: '',
  },
  general: {
    en: 'General',
    es: '',
    ca: '',
    pt: '',
  },
  credentials: {
    en: 'Credentials',
    es: '',
    ca: '',
    pt: '',
  },
  modifiers: {
    en: 'Modifiers',
    es: '',
    ca: '',
    pt: '',
  },
  enter_trunk_name: {
    en: 'Please enter trunk name',
    es: '',
    ca: '',
    pt: '',
  },
  enter_routing_name: {
    en: 'Please enter routing name',
    es: '',
    ca: '',
    pt: '',
  },
  select_mode: {
    en: 'Please select a mode',
    es: '',
    ca: '',
    pt: '',
  },
  select_trunk: {
    en: 'Please select a trunk',
    es: '',
    ca: '',
    pt: '',
  },
  trunk_field: {
    en: 'Trunk',
    es: '',
    ca: '',
    pt: '',
  },
  Add_host_origin: {
    en: 'Add Host Origin',
    es: '',
    ca: '',
    pt: '',
  },
  Add_callee: {
    en: 'Add Callee',
    es: '',
    ca: '',
    pt: '',
  },
  Add_caller: {
    en: 'Add Caller',
    es: '',
    ca: '',
    pt: '',
  },
  enter_host_name: {
    en: 'Please enter host name',
    es: '',
    ca: '',
    pt: '',
  },
  create_trunk_success: {
    en: 'Trunk created successfully',
    es: '',
    ca: '',
    pt: '',
  },
  create_trunk_error: {
    en: 'Error creating trunk',
    es: '',
    ca: '',
    pt: '',
  },
  update_trunk_success: {
    en: 'Trunk updated successfully',
    es: '',
    ca: '',
    pt: '',
  },
  update_trunk_error: {
    en: 'Error updating trunk',
    es: '',
    ca: '',
    pt: '',
  },
  alert: {
    en: 'Alert',
    es: '',
    ca: '',
    pt: '',
  },
  add_credentials: {
    en: 'Add Credentials',
    es: '',
    ca: '',
    pt: '',
  },
  add_rules: {
    en: 'Add Rule',
    es: '',
    ca: '',
    pt: '',
  },
  routing_delete_info1: {
    en: 'This trunk is being used in the following routing,',
    es: '',
    ca: '',
    pt: '',
  },
  routing_delete_info2: {
    en: 'please remove the trunk from the routings before deleting it.',
    es: '',
    ca: '',
    pt: '',
  },
  delete_routing: {
    en: 'Delete Routing',
    es: '',
    ca: '',
    pt: '',
  },
  delete_routing_info: {
    en: 'Are you sure you want to delete this routing?',
    es: '',
    ca: '',
    pt: '',
  },
  trunk_delete_success: {
    en: 'Trunk deleted successfully',
    es: '',
    ca: '',
    pt: '',
  },
  trunk_delete_error: {
    en: 'Error deleting Trunk',
    es: '',
    ca: '',
    pt: '',
  },
  trunk_name_exists: {
    en: 'Trunk already exists with same name',
    es: '',
    ca: '',
    pt: '',
  },
  routing_name_exists: {
    en: 'Routing already exists with same name',
    es: '',
    ca: '',
    pt: '',
  },
  routing_relation_update_success: {
    en: 'Routing relation updated successfully',
    es: '',
    ca: '',
    pt: '',
  },
  routing_relation_update_error: {
    en: 'Unable to update Routing relation',
    es: '',
    ca: '',
    pt: '',
  },
  routing_update_success: {
    en: 'Routing updated successfully',
    es: '',
    ca: '',
    pt: '',
  },
  routing_update_error: {
    en: 'Error updating Routing',
    es: '',
    ca: '',
    pt: '',
  },
  create_routing_success: {
    en: 'Routing created successfully',
    es: '',
    ca: '',
    pt: '',
  },
  create_routing_error: {
    en: 'Error creating routing',
    es: '',
    ca: '',
    pt: '',
  },
  delete_routing_success: {
    en: 'Routing deleted successfully',
    es: '',
    ca: '',
    pt: '',
  },
  delete_routing_error: {
    en: 'Error deleting routing',
    es: '',
    ca: '',
    pt: '',
  },
  relate_routing_success: {
    en: 'Routing related successfully',
    es: '',
    ca: '',
    pt: '',
  },
  relate_routing_error: {
    en: 'Error relating routing',
    es: '',
    ca: '',
    pt: '',
  },
  full_access: {
    en: 'Full access',
    es: '',
    ca: '',
    pt: '',
  },
  read_only: {
    en: 'Read only',
    es: '',
    ca: '',
    pt: '',
  },
  no_access: {
    en: 'No access',
    es: '',
    ca: '',
    pt: '',
  },
  company_settings: {
    en: 'Company Settings',
    es: '',
    ca: '',
    pt: '',
  },
  Administrator: {
    en: 'Administrator',
    es: '',
    ca: '',
    pt: '',
  },
  Regular: {
    en: 'Regular',
    es: '',
    ca: '',
    pt: '',
  },
  permissions: {
    en: 'Permissions',
    es: '',
    ca: '',
    pt: '',
  },
  access_settings_update_success: {
    en: 'Access settings updated successfully, Latest changes will be applied when user logs in next time',
    es: '',
    ca: '',
    pt: '',
  },
  access_settings_update_error: {
    en: 'Error updating Access settings',
    es: '',
    ca: '',
    pt: '',
  },
  getting_acc_details_error: {
    en: 'Error getting Account details',
    es: '',
    ca: '',
    pt: '',
  },
}
