export const sampleAction = (data) => ({
  type: 'Sample-Action',
  data,
})

export const setUserDetails = (data) => ({
  type: 'User-Details',
  data,
})

export const setUserStripeData = (data) => ({
  type: 'Stripe-Details',
  data,
})

export const setAccSettingsData = (data) => ({
  type: 'Account-details',
  data,
})

export const setUserLanguage = (data) => ({
  type: 'Set-language',
  data,
})

export const setTranslations = (translationJson) => ({
  type: 'SetTranslations',
  translationJson,
})

export const setAccountSettingsData = (data) => ({
  type: 'SetAccountSettings',
  data,
})

export const SetAccountConfig = (config) => ({
  type: 'SetAccountConfig',
  config,
})

export const SetAccountPlanId = (accountPlanId) => ({
  type: 'SetAccountPlanId',
  accountPlanId,
})

export const setUserSettingsData = (data) => ({
  type: 'SetUserSettings',
  data,
})

export const setCollapsed = (data) => ({
  type: 'SetCollapsed',
  data,
})

export const setHomeKey = (key) => ({
  type: 'Home-Key',
  key,
})

export const setUserAccounId = (userAccounId) => ({
  type: 'setUserAccounId',
  userAccounId,
})

export const resetReduxStore = () => ({
  type: 'USER_LOGGED_OUT',
})

export const setIsResellerAccount = (isResellerAccount) => ({
  type: 'SET_IS_RESELLER_ACCOUNT',
  isResellerAccount: isResellerAccount,
})

export const setAppBlurState = (appBlurState) => ({
  type: 'SET_APP_BLUR_STATE',
  appBlurState: appBlurState,
})

export const setAppFromClient = (appFromClient) => ({
  type: 'SET_APP_FROM_CLIENT',
  appFromClient: appFromClient,
})

export const setAppUrlBrand = (appUrlBrand) => ({
  type: 'SET_APP_URL_BRAND',
  appUrlBrand: appUrlBrand,
})

export const setAppProviderBrand = (appProviderBrand) => ({
  type: 'SET_APP_PROVIDER_BRAND',
  appProviderBrand: appProviderBrand,
})
