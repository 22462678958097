/* eslint-disable react/prop-types */

import React from 'react'
import { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { COMMON_IMG_URL } from '../../utils/constants'
import './StartupImagePanel.css'

const image = require('../../assets/images/bg-1.png')
const logo = `${COMMON_IMG_URL}branding/PTT/logo2.png`
const container = 'startup-image-panel-container'
const leftContainer = 'startup-image-style align-center'
const rightContainer = 'startup-img-content'
const textStyle = 'align-center startup-text'

const StartupImagePanel = ({ text }) => {
  const bg = { backgroundImage: `url(${image})` }
  const { appUrlBrand } = useSelector((state) => {
    return {
      appUrlBrand: state.sampleReducer.appUrlBrand,
    }
  })

  const renderLogo = () => {
    if (appUrlBrand === 'Plug2Teams') {
      return (
        <div className="align-center">
          <img alt="pttlogo" className="startup-img-width" src={logo} />
        </div>
      )
    } else {
      return <Fragment />
    }
  }

  return (
    <div className={container}>
      <div className={leftContainer} style={bg}>
        <div className={rightContainer}>
          {renderLogo()}
          <div className={textStyle}>{text}</div>
        </div>
      </div>
    </div>
  )
}

export default StartupImagePanel
