// @flow

import React from 'react'
import { Select } from 'antd'
import { ApartmentOutlined } from '@ant-design/icons'
import { isTextSame } from '../../../utils/helpers'
import { NODE_TYPES } from '../nodetypes'
import './CallFlowComponent.css'

type Props = {
  autoSaveHandler: (any) => mixed,
  label?: string,
  keyName: string,
  mode: string,
  options: $ReadOnlyArray<*>,
  placeholder?: string,
  selectedValue: string,
}

const CallFlowComponent = React.forwardRef<Props, *>(
  (
    {
      autoSaveHandler,
      label = 'Call Flow',
      keyName,
      mode,
      options = [],
      placeholder = '',
      selectedValue,
    }: Props,
    ref
  ) => {
    const [value, setValue] = React.useState('')
    const [update, setUpdate] = React.useState(false)

    React.useEffect(() => {
      setValue(selectedValue)
    }, [selectedValue])

    React.useImperativeHandle(ref, () => ({
      getLatestValue() {
        return value
      },
      getName() {
        return keyName
      },
      getType() {
        return NODE_TYPES.callflow
      },
      resetValue() {
        setValue('')
      },
    }))

    const changeHandler = (changedValue = '') => {
      setValue(changedValue)
      if (!isTextSame(changedValue, value)) {
        setUpdate(true)
      }
    }

    React.useEffect(() => {
      if (update && mode === 'Edit' && autoSaveHandler) {
        autoSaveHandler(NODE_TYPES.callflow)
        setUpdate(false)
      }
    }, [update])

    return (
      <React.Fragment>
        <div className="label-spacing">{label}</div>
        <Select
          allowClear={value.length > 0}
          onChange={changeHandler}
          placeholder={placeholder}
          size="default"
          style={{ width: '100%' }}
          value={value}
        >
          {options.map(({ id, name }) => (
            <Select.Option key={id} value={id}>
              <div className="CallFlowComponent__SelectBody">
                <ApartmentOutlined className="CallFlowComponent__SelectIcon" />
                {name}
              </div>
            </Select.Option>
          ))}
        </Select>
      </React.Fragment>
    )
  }
)

export default React.memo<Props>(CallFlowComponent)
