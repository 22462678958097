const MsTeamsReducer = (
  state = {
    isCallingPlanSaved: true,
    msTeamsUnsaveAlertTrigger: {},
    actualVoiceUserData: [],
    modifiedVoiceUserData: [],
    updateVoiceUserOnTheFly: {
      exexute: false,
      postCallback: () => {},
    },
    microsoftUsersCollections: {
      currentPage: 0,
      currentUsers: 0,
      pages: 0,
      users: 0,
      usersPerPage: 100,
      nextUsersUrls: [],
    },
    teamsStep: '',
  },
  action
) => {
  switch (action.type) {
    case 'isCallingPlanSaved':
      return { ...state, isCallingPlanSaved: action.data }

    case 'setMsTeamsUnsaveAlertTrigger':
      return { ...state, msTeamsUnsaveAlertTrigger: action.data }

    case 'setActualVoiceUserData':
      return { ...state, actualVoiceUserData: action.actualVoiceUserData }

    case 'setModifiedVoiceUserData':
      return { ...state, modifiedVoiceUserData: action.modifiedVoiceUserData }

    case 'setUpdateVoiceUserOnTheFly':
      return {
        ...state,
        updateVoiceUserOnTheFly: action.updateVoiceUserOnTheFly,
      }

    case 'setMicrosoftUsersCollection':
      return {
        ...state,
        microsoftUsersCollections: action.microsoftUsersCollections,
      }

    case 'setTeamsStep':
      return {
        ...state,
        teamsStep: action.teamsStep,
      }

    default:
      return state
  }
}

export default MsTeamsReducer
