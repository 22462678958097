import { useEffect, useState } from 'react'

const UseMatchMedia = (media) => {
  const match = () => {
    return window.matchMedia(media).matches
  }

  const [value, setValue] = useState(match())

  useEffect(() => {
    const handler = () => setValue(match())

    window.addEventListener('resize', handler)

    return () => window.removeEventListener('resize', handler)
  }, [])

  return value
}

export default UseMatchMedia
