export const DEFAULT_NODE = {
  call_flow_node_id: 'defaultCallFlowNodeId',
  name: 'INCOMING CALL',
  node_id: 'defaultNodeId',
  values: [],
  default_icon_position: {
    x: '698',
    y: '4',
  },
}

export const NODE_TYPES = {
  announcement: 'ANNOUNCEMENT',
  apimethod: 'API_METHOD',
  audio: 'AUDIO',
  callflow: 'CALL_FLOW',
  default: 'DEFAULT',
  longText: 'LONG_TEXT',
  mapNumberToNode: 'MAP_NUMBER_TO_NODE',
  mapNumberOrStringToNode: 'MAP_NUMBER_OR_STRING_TO_NODE',
  node: 'NODE',
  number: 'NUMBER',
  shortText: 'SHORT_TEXT',
  switch: 'SWITCH',
  textToSpeach: 'TTS_SPEAKER',
  voiceUser: 'VOICE_USER',
}
