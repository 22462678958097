import React from 'react'
import { useSelector } from 'react-redux'
import PttLayout from '../components/Home/PttHome/PttLayout'
import Settings from '../components/Settings/Settings'
import { getTranslatedText } from '../utils/translations'

function SettingsPage() {
  const { language, translations } = useSelector(({ sampleReducer }) => ({
    language: sampleReducer.language || 'en',
    translations: sampleReducer.translations || {},
  }))

  return (
    <PttLayout
      currentkey="6"
      title={getTranslatedText(translations, language, 'settings_title')}
    >
      <Settings />
    </PttLayout>
  )
}

export default SettingsPage
