const incomingCallsReducer = (
  state = { ttsLanguages: [], ttsSpeakers: [], autoUpdateCallFlow: false },
  action
) => {
  switch (action.type) {
    case 'setCallFlow':
      return { ...state, callFlow: action.data }

    case 'setCallFlowNodes':
      return { ...state, callFlowNodes: action.data }

    case 'setActualCallFlowNodes':
      return { ...state, actualCallFlowNodes: action.data }

    case 'setActualRouteName':
      return { ...state, actualRouteName: action.data }

    case 'setRouteName':
      return { ...state, routeName: action.data }

    case 'setNodeList':
      return { ...state, nodeList: action.data }

    case 'setUndoArray':
      return { ...state, undoArray: action.data }
    case 'setRedoArray':
      return { ...state, redoArray: action.data }

    case 'Unsave-Alert-Trigger':
      return { ...state, unsaveAlertTrigger: action.details }

/*     case 'Home-Key':
      return { ...state, homeKey: action.key } */

    case 'Connector-Status':
      return { ...state, hasConnector: action.status }

    case 'setTtsLanguages':
      return { ...state, ttsLanguages: action.ttsLanguages }

    case 'setTtsSpeakers':
      return { ...state, ttsSpeakers: action.ttsSpeakers }

    case 'setAutoUpdateCallFlow':
      return { ...state, autoUpdateCallFlow: action.autoUpdateCallFlow }

    default:
      return state
  }
}

export default incomingCallsReducer
