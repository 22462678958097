import axios from 'axios'
import { useEffect, useState } from 'react'
import { APIURL } from '../utils/constants'
import { API_MANAGER_KEY } from '../utils/constants'
import { ID_TOKEN } from '../utils/constants'
import { TOKEN_TYPE } from '../utils/constants'
import { getItem } from '../utils/storage'

const UseCheckExtension = (onSuccess = () => {}, onError = () => {}) => {
  const api = axios.create({
    baseURL: APIURL,
    headers: {
      Authorization: `${getItem(TOKEN_TYPE)} ${getItem(ID_TOKEN)}`,
      'x-api-key': API_MANAGER_KEY,
      'ms-token': localStorage.getItem('mstoken'),
    },
  })

  const [id, setId] = useState('')
  const [execute, setExecute] = useState(false)
  const [value, setValue] = useState('')

  const errHandler = (data) => {
    setExecute(false)
    // eslint-disable-next-line no-console
    console.log(data)
  }


  useEffect(() => {
    if (execute) {
      api
        .get(`voice_user?query=data.extension:"${value}"`)
        .then((res) => {
          setExecute(false)
          const items = res?.data?.response?.items

          if (res.data.result) {
            if (items?.length && items[0].id) {
              const filter = items.filter((item) => item.id !== id)

              filter.length ? onError() : onSuccess(value.length ? '' : 'empty')
            } else {
              onSuccess(value.length ? '' : 'empty')
            }
          } else {
            errHandler(res.data)
          }
        })
        .catch((err) => errHandler(err))
    }
  }, [execute, value])

  return [{ setId, setExecute, setValue }]
}

export default UseCheckExtension
