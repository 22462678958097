import { useState } from 'react'

var id = null

const UseInterval = (intervalTime) => {
  const [count, setCount] = useState(0)

  const start = () => {
    stop()
    setInterval(() => {
      setCount((count) => count + 1)
    }, intervalTime)
  }

  const stop = () => {
    setCount(0)
    if (id !== null) {
      clearInterval(id)
      id = null
    }
  }

  return [{ count, start, stop }]
}

export default UseInterval
