// @flow

import React, { useState } from 'react'
import { Tabs } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { getTranslatedText } from '../../utils/translations'
import TrunksView from './Trunks/TrunksView'
import RoutingView from './Routing/RoutingView'
import { isEqual } from 'lodash'
import ConfirmPopup from '../Popup/confirmpopup'
import {
  setActualRouting,
  setModifiedRouting,
  setRoutingSavedFromTab,
  setRoutingSaveFromTab,
} from '../../actions/routingAction'
import { useEffect } from 'react'
import {
  setActualTrunk,
  setModifiedTrunk,
  setTrunkSavedFromTab,
  setTrunkSaveFromTab,
} from '../../actions/trunksAction'

const Advanced = () => {
  const { TabPane } = Tabs
  const dispatch = useDispatch()
  const {
    translations,
    language,
    actualRouting,
    modifiedRouting,
    routingSavedFromTab,
    actualTrunk,
    modifiedTrunk,
    trunkSavedFromTab,
  } = useSelector((state) => {
    return {
      translations: state.sampleReducer.translations,
      language: state.sampleReducer.language,
      actualRouting: state.routingReducer.actualRouting,
      modifiedRouting: state.routingReducer.modifiedRouting,
      routingSavedFromTab: state.routingReducer.routingSavedFromTab,
      actualTrunk: state.trunkReducer.actualTrunk,
      modifiedTrunk: state.trunkReducer.modifiedTrunk,
      trunkSavedFromTab: state.trunkReducer.trunkSavedFromTab,
    }
  })

  const [key, setKey] = useState('1')
  const [unsave, setUnsave] = useState(false)
  const [unsaveData, setUnsaveData] = useState('')
  const [trunkUnsave, setTrunkUnsave] = useState(false)
  const [trunkUnsaveData, setTrunkUnsaveData] = useState('')

  useEffect(() => {
    if (routingSavedFromTab) {
      setKey(unsaveData)
      dispatch(setRoutingSavedFromTab(false))
      setUnsaveData('')
    }
  }, [routingSavedFromTab])

  useEffect(() => {
    if (trunkSavedFromTab) {
      setKey(trunkUnsaveData)
      dispatch(setTrunkSavedFromTab(false))
      setTrunkUnsaveData('')
    }
  }, [trunkSavedFromTab])

  const onUnsaveClose = () => {
    setUnsave(false)
  }

  const onUnsaveCancel = () => {
    setUnsave(false)
    setKey(unsaveData)
    if (unsaveData === '1') {
      dispatch(setActualRouting({}))
      dispatch(setModifiedRouting({}))
    }
    setTimeout(() => {
      setUnsaveData('')
    }, 1000)
  }

  const onUnsaveOkay = () => {
    setUnsave(false)
    dispatch(setRoutingSaveFromTab(true))
  }

  const onTrunkUnsaveCancel = () => {
    setTrunkUnsave(false)
    setKey(trunkUnsaveData)
    if (trunkUnsaveData === '2') {
      dispatch(setActualTrunk({}))
      dispatch(setModifiedTrunk({}))
    }
    setTimeout(() => {
      setTrunkUnsaveData('')
    }, 1000)
  }

  const onTrunkUnsaveClose = () => {
    setTrunkUnsave(false)
  }

  const onTrunkUnsaveOkay = () => {
    setTrunkUnsave(false)
    dispatch(setTrunkSaveFromTab(true))
  }

  const onTabChange = (value) => {
    if (value === '1') {
      if (isEqual(actualRouting, modifiedRouting)) {
        setKey(value)
      } else {
        setUnsaveData(value)
        setUnsave(true)
      }
    } else if (value === '2') {
      if (isEqual(actualTrunk, modifiedTrunk)) {
        setKey(value)
      } else {
        setTrunkUnsaveData(value)
        setTrunkUnsave(true)
      }
    }
  }

  return (
    <div style={{ height: '100%' }}>
      <Tabs activeKey={key} onChange={onTabChange} className="billing-tab">
        <TabPane
          tab={getTranslatedText(translations, language, 'trunks')}
          key="1"
        >
          <TrunksView active={key === '1' ? true : false} />
        </TabPane>
        <TabPane
          tab={getTranslatedText(translations, language, 'routing')}
          key="2"
        >
          <RoutingView active={key === '2' ? true : false} />
        </TabPane>
      </Tabs>
      <ConfirmPopup
        cancelText={translations.no[language]}
        closable={true}
        confirmText={translations.routing_unsaved_changes[language]}
        okayText={translations.yes[language]}
        onCancel={onUnsaveCancel}
        onClose={onUnsaveClose}
        onOkay={onUnsaveOkay}
        visiblity={unsave}
      >
        <React.Fragment>
          {translations.routing_unsaved_changes_info[language]}
        </React.Fragment>
      </ConfirmPopup>
      <ConfirmPopup
        cancelText={translations.no[language]}
        closable={true}
        confirmText={translations.trunk_unsaved_changes[language]}
        okayText={translations.yes[language]}
        onCancel={onTrunkUnsaveCancel}
        onClose={onTrunkUnsaveClose}
        onOkay={onTrunkUnsaveOkay}
        visiblity={trunkUnsave}
      >
        <React.Fragment>
          {translations.trunk_unsaved_changes_info[language]}
        </React.Fragment>
      </ConfirmPopup>
    </div>
  )
}

export default Advanced
