// @flow
/* eslint-disable react/prop-types */

import React, { Fragment } from 'react'
import { Select } from 'antd'
import { useSelector } from 'react-redux'
import { FULL_ACCESS } from '../../utils/constants'

const UserTypeDropDown = ({
  headerStyle = { margin: '5px 0px' },
  heading,
  value,
  changeCallback,
  dropDownStyle = { width: '100%' },
}) => {
  const { language, translations, accessUsersPermission } = useSelector(
    (state) => {
      return {
        language: state.sampleReducer.language,
        translations: state.sampleReducer.translations,
        accessUsersPermission: state.permissionReducer.accessUsersPermission,
      }
    }
  )

  const renderBox = () => {
    if (accessUsersPermission === FULL_ACCESS) {
      return (
        <Select value={value} style={dropDownStyle} onChange={changeCallback}>
          <Select.Option value={translations.Administrator[language]}>
            {translations.Administrator[language]}
          </Select.Option>
          <Select.Option value={translations.Regular[language]}>
            {translations.Regular[language]}
          </Select.Option>
        </Select>
      )
    } else {
      return (
        <div
          style={{
            marginBottom: 5,
            padding: '4px 11px',
            border: '1px solid lightgrey',
            height: 32,
            width: '97%',
          }}
        >
          {value}
        </div>
      )
    }
  }

  return (
    <Fragment>
      <div style={headerStyle}>{heading}</div>
      {renderBox()}
    </Fragment>
  )
}

export default UserTypeDropDown
