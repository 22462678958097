// @flow

import React, { Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { AppstoreFilled, GoogleCircleFilled } from '@ant-design/icons'
import './StartupAddons.css'

type Props = {
  option?: string,
  linkTo?: string,
}

const StartupAddons = (props: Props) => {
  const { option = 'Icons', linkTo = '' } = props
  const history = useHistory()
  const { language, translations } = useSelector(({ sampleReducer }) => ({
    language: sampleReducer.language,
    translations: sampleReducer.translations || {},
  }))

  const redirectToTargetPage = (path) => {
    history.push({ pathname: `/${path}` })
  }

  const renderIcons = () => {
    return (
      <div className="icon-cont">
        <GoogleCircleFilled className="google-icon" />
        <div className="windows-background">
          <AppstoreFilled className="windows-icon" />
        </div>
      </div>
    )
  }

  const renderLink = () => {
    return (
      <div
        className="startup-link"
        onClick={() => redirectToTargetPage(linkTo)}
      >
        {linkTo === 'signup'
          ? translations.dont_have_an_account[language]
          : translations.already_have_account[language]}
      </div>
    )
  }

  return (
    <Fragment>
      <div className="align-center divider-cont">
        <div className="divider-items">
          <hr className="divider" />
        </div>
        <div className="align-center divider-text">
          {translations.or[language]}
        </div>
      </div>
      {option === 'Icons' ? renderIcons() : renderLink()}
    </Fragment>
  )
}

export default StartupAddons
