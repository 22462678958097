import * as React from 'react'
import { AbstractReactFactory } from '@projectstorm/react-canvas-core'
import CustomNodeModel from './CustomNodeModal'
import CustomNodeWidget from './CustomNodeWidget'

class CustomNodeFactory extends AbstractReactFactory {
  constructor() {
    super('js-custom-node')
  }

  generateModel() {
    return new CustomNodeModel()
  }

  generateReactWidget(event) {
    return <CustomNodeWidget engine={this.engine} node={event.model} />
  }
}

export default CustomNodeFactory
