/* eslint-disable react/prop-types */
/* eslint-disable no-negated-condition */
// @flow

import React, { useState, useEffect } from 'react'
import { Button, Input, Select, Form, message, Tooltip } from 'antd'
import { isEmpty, isEqual } from 'lodash'
import { CloseOutlined } from '@ant-design/icons'
import { connect, useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import { parseApiResult } from '../../utils/helpers'
import { getCallingPlanHistoryId } from '../../utils/helpers'
import { isCurrentPlanGTCurrentMonth } from '../../utils/helpers'
import { getCallingPlanMonth } from '../../utils/helpers'
import { keyDownHandler } from '../../utils/helpers'
import { pasteHandler } from '../../utils/helpers'
import { PttButton } from '../CustomButton/custombutton'
import useCustomFetch from '../../Hooks/UseCustomFetch'
import { APIURL, FULL_ACCESS } from '../../utils/constants'
import Spinner from '../../Spinner/spinner'
import AddCallingPlanModal from './AddCallingPlanModal'
import { setActualVoiceUserData } from '../../actions/MsTeamsAction'
import { setIsCallingPlanSaved } from '../../actions/MsTeamsAction'
import { setModifiedVoiceUserData } from '../../actions/MsTeamsAction'
import { setMsTeamsUnsaveAlertTrigger } from '../../actions/MsTeamsAction'
import { setUpdateVoiceUserOnTheFly } from '../../actions/MsTeamsAction'
import { setHomeKey } from '../../actions/SampleAction'
import useUpdateVoiceUser from '../../Hooks/UseUpdateVoiceUser'
import useCheckExtension from '../../Hooks/UseCheckExtension'
import './editvoiceuser.css'
import { Fragment } from 'react'

type Props = {
  callingPlansApi: Object,
  data: Object,
  voiceUserDetailApi: Object,
  deleteVoiceUser: (any) => mixed,
  translations: Object,
  language: string,
  setIsCallingPlanSaved: (any) => mixed,
  msTeamsUnsaveAlertTrigger: Object,
  setHomeKey: (any) => mixed,
  setMsTeamsUnsaveAlertTrigger: (any) => mixed,
  updateVoiceUserOnTheFly: Object,
  actualVoiceUserData: $ReadOnlyArray<*>,
  modifiedVoiceUserData: $ReadOnlyArray<*>,
  connectorId: String,
  savedCallBack: (any) => mixed,
  phoneData: Object,
  phonenumbers: $ReadOnlyArray<*>,
  existingUsers: $ReadOnlyArray<*>,
  microsoftTeamsPermission: String,
}

const EditableSection = ({ title, onChangeCb, value }) => {
  return (
    <Fragment>
      <div style={{ marginBottom: 5 }}>{title}</div>
      <Input style={{ marginBottom: 5 }} value={value} onChange={onChangeCb} />
    </Fragment>
  )
}

const UnEditableSection = ({ title, value }) => {
  return (
    <Fragment>
      <div style={{ marginBottom: 5 }}>{title}</div>
      <div
        style={{
          marginBottom: 5,
          padding: '4px 11px',
          border: '1px solid lightgrey',
          height: 32
        }}
      >
        {value}
      </div>
    </Fragment>
  )
}

const defaultPhoneData = {
  data: {},
  id: '',
  name: '',
}

const EditVoiceUsers = (props: Props) => {
  const {
    callingPlansApi,
    data: voiceData,
    voiceUserDetailApi,
    deleteVoiceUser,
    translations,
    language,
    setIsCallingPlanSaved,
    phonenumbers = [],
    phoneData = {},
    savedCallBack,
    connectorId,
    actualVoiceUserData = [],
    modifiedVoiceUserData = [],
    updateVoiceUserOnTheFly = {},
    existingUsers = [],
    microsoftTeamsPermission,
  } = props

  const dispatch = useDispatch()

  const [displayName, setDisplayName] = useState('')
  const [givenName, setGivenName] = useState('')
  const [lastName, setLastName] = useState('')
  const [mail, setMail] = useState('')
  const [type, setType] = useState('')
  const [extension, setExtension] = useState('')
  const [userStatus, setUserStatus] = useState('')
  const [phonenumber, setPhonenumber] = useState(defaultPhoneData)
  const [updateData, setUpdateData] = useState('')
  const [
    addCallingPlanModalVisibility,
    setAddCallingPlanModalVisibility,
  ] = useState(false)
  const [currentCallingPlans, setCurrentCallingPlans] = useState([])
  const [tmpNextMonthsCallingPlans, setTmpNextMonthsCallingPlans] = useState([])
  const [updatedCallingPlanData, setUpdatedCallingPlanData] = useState({
    data: {},
  })
  const [isNextBillingCycleEmpty, setIsNextBillingCycleEmpty] = useState(true)
  const [currentCallingPlanId, setCurrentCallingPlanId] = useState('')
  const [nextCallingPlanId, setnextCallingPlanId] = useState('')
  const [hasPhoneNumberError, setHasPhoneNumberError] = useState(false)
  const [requireExtension, setRequireExtension] = useState(false)
  const [extensionStatus, setExtensionStatus] = useState('')

  const triggerSave = (status) => {
    setTimeout(() => {
      saveHandler(status)
    }, 1000)
  }

  const [extensionCheck] = useCheckExtension(
    (isEmpty) => {
      if (isEmpty === 'empty') {
        const data = existingUsers.filter((user) => user.id !== voiceData.id)
        const keys = data.map((item) => item.extension)
        const getStatus = () => {
          return keys.includes('') ? 'warning' : ''
        }

        setExtensionStatus(requireExtension ? getStatus() : '')
        triggerSave(requireExtension ? getStatus() : '')
      } else {
        setExtensionStatus('success')
        triggerSave('success')
      }
    },
    () => {
      setExtensionStatus('error')
      triggerSave('error')
    }
  )

  const [userUpdater] = useUpdateVoiceUser((result) => {
    if (result.status === 'success') {
      savedCallBack(result.data)
      message.success(translations.user_update_success[language])
      if (updateVoiceUserOnTheFly.execute) {
        updateVoiceUserOnTheFly.postCallback()
        dispatch(
          setUpdateVoiceUserOnTheFly({
            execute: false,
            postCallback: () => {},
          })
        )
      }
    } else {
      message.error(translations.user_update_failed[language])
    }
  })

  useEffect(() => {
    const { id } = phonenumber

    if (id.length) {
      const details = phonenumbers.find((item) => id === item.id)

      if (details?.relation?.voice_user?.length) {
        const users = details.relation.voice_user
        const filter = users.filter((item) => item.id !== voiceData.id)

        setRequireExtension(filter.length !== 0)
      } else {
        setRequireExtension(false)
      }
    }
  }, [phonenumber])

  /* Update voice user entity */
  const [updateVoiceUserApi] = useCustomFetch(
    `${APIURL}/voice_user/${voiceData.id}?sync=yes`,
    'PATCH',
    false,
    updateData
  )

  /* Get calling planS list */
  const [callingPlanListApi] = useCustomFetch(
    `${APIURL}/voice_user_calling_plan_history?sort=updatedata.update.time:desc,_id&size=2&query=data.voice_user:${voiceData.id}`,
    'GET',
    false
  )

  /* Create calling plan history */
  const [createCallingPlanApi] = useCustomFetch(
    `${APIURL}/voice_user_calling_plan_history?sync=yes`,
    'PUT',
    false,
    JSON.stringify(updatedCallingPlanData)
  )

  /* Get current month's calling plan */
  const [currentCallingPlanApi] = useCustomFetch(
    `${APIURL}/voice_user_calling_plan_history/${currentCallingPlanId}`,
    'GET',
    false
  )

  /* Get next month's calling plan */
  const [nextMonthCallingPlanApi] = useCustomFetch(
    `${APIURL}/voice_user_calling_plan_history/${
      nextCallingPlanId
        ? nextCallingPlanId
        : getCallingPlanHistoryId('nextMonth', voiceData.id)
    }`,
    'GET',
    false
  )

  /* Delete next month's calling plan */
  const [deleteNextMonthCallingPlanApi] = useCustomFetch(
    `${APIURL}/voice_user_calling_plan_history/${nextCallingPlanId}?sync=yes`,
    'DELETE',
    false
  )

  /* Update calling plan history */
  const [updateCallingPlanApi] = useCustomFetch(
    `${APIURL}/voice_user_calling_plan_history/${nextCallingPlanId}?sync=yes`,
    'PATCH',
    false,
    JSON.stringify(updatedCallingPlanData)
  )

  useEffect(() => {
    if (phoneData.id.length) {
      const data = getPreserveDataForSave(
        displayName,
        givenName,
        lastName,
        mail,
        type,
        extension,
        phoneData,
        []
      )

      setPhonenumber(phoneData)
      dispatch(setActualVoiceUserData(JSON.parse(JSON.stringify(data))))
      dispatch(setModifiedVoiceUserData(JSON.parse(JSON.stringify(data))))
    }
  }, [phoneData])

  useEffect(() => {
    const result = parseApiResult(callingPlanListApi.response)

    if (result && result.length && result[0].data?.month) {
      const lastCallingPlanMonth = Number(
        result[0].data.month.substr(result[0].data.month.length - 2)
      )

      const data = getPreserveDataForSave(
        displayName,
        givenName,
        lastName,
        mail,
        type,
        extension,
        phoneData,
        result[0].data?.calling_plan
      )

      if (isCurrentPlanGTCurrentMonth(lastCallingPlanMonth)) {
        setnextCallingPlanId(result[0].id)
        result[0].data?.calling_plan.length &&
          /* setNextMonthsCallingPlans(result[0].data?.calling_plan) */
          setTmpNextMonthsCallingPlans(result[0].data?.calling_plan)
        dispatch(setActualVoiceUserData(JSON.parse(JSON.stringify(data))))
        dispatch(setModifiedVoiceUserData(JSON.parse(JSON.stringify(data))))
        setIsNextBillingCycleEmpty(false)
        if (result.length > 1) {
          setCurrentCallingPlanId(result[1].id)
          setCurrentCallingPlans(result[1].data?.calling_plan || [])
          /* if (result[0].data?.calling_plan.length === 0) {
            setIsNextBillingCycleEmpty(true)
            setTmpNextMonthsCallingPlans(result[1].data?.calling_plan)
          } */
        }
      } else {
        setCurrentCallingPlanId(result[0].id)
        // setnextCallingPlanId(result[0].id)
        setIsNextBillingCycleEmpty(true)
        if (result[0].data?.calling_plan) {
          setCurrentCallingPlans(result[0].data.calling_plan)
          // setNextMonthsCallingPlans(result[0].data.calling_plan)
          setTmpNextMonthsCallingPlans(result[0].data?.calling_plan)
          dispatch(setActualVoiceUserData(JSON.parse(JSON.stringify(data))))
          dispatch(setModifiedVoiceUserData(JSON.parse(JSON.stringify(data))))
        }
      }
    }
  }, [callingPlanListApi.response])

  const getPreserveDataForSave = (
    displayName,
    givenName,
    lastName,
    email,
    type,
    extension,
    phonenumber,
    callingPlans
  ) => {
    return [
      {
        displayName: displayName,
        givenName: givenName,
        lastName: lastName,
        email: email,
        type: type,
        extension: extension,
        phonenumber: phonenumber,
        callingPlans: callingPlans,
      },
    ]
  }

  useEffect(() => {
    if (voiceData.data) {
      const {
        displayName,
        givenName,
        lastName,
        email,
        type,
        extension,
        status,
      } = voiceData.data

      const data = getPreserveDataForSave(
        displayName,
        givenName,
        lastName,
        email,
        type,
        extension,
        phonenumber,
        []
      )

      voiceData.id && callingPlanListApi.setRefetch(true)
      setDisplayName(displayName)
      setGivenName(givenName)
      setLastName(lastName)
      setMail(email)
      setType(type)
      setExtension(extension)
      setUserStatus(status)
      dispatch(setActualVoiceUserData(JSON.parse(JSON.stringify(data))))
      dispatch(setModifiedVoiceUserData(JSON.parse(JSON.stringify(data))))
      checkForValidExtension({}, extension)
    }
  }, [voiceData])

  useEffect(() => {
    if (updateData.length) {
      updateVoiceUserApi.setRefetch(true)
    }
  }, [updateData])

  useEffect(() => {
    const result = parseApiResult(updateVoiceUserApi.response)

    if (
      updateVoiceUserApi.response.result === true &&
      result.length &&
      result[0].action === 'updated'
    ) {
      updateVoiceUserApi.setResponse({})
      setUpdateData('')
      savedCallBack()
      // voiceUserDetailApi.setRefetch(true)
    }
  }, [updateVoiceUserApi.response])

  useEffect(() => {
    if (
      (updateCallingPlanApi.response.result === true ||
        deleteNextMonthCallingPlanApi.response.result === true ||
        createCallingPlanApi.response.result === true ||
        isEmpty(updatedCallingPlanData.data)) &&
      (updateVoiceUserApi.response.result ||
        (!updateData.length && !isEmpty(updatedCallingPlanData.data)))
    ) {
      handleCallingPlanUpdate()
      setIsCallingPlanSaved(true)
    }
  }, [
    updateCallingPlanApi.response,
    deleteNextMonthCallingPlanApi.response,
    createCallingPlanApi.response,
    updateVoiceUserApi.response,
  ])

  useEffect(() => {
    if (
      updatedCallingPlanData.data &&
      updatedCallingPlanData.data.calling_plan
    ) {
      setIsCallingPlanSaved(false)
    }
  }, [updatedCallingPlanData])

  useEffect(() => {
    if (
      tmpNextMonthsCallingPlans.length &&
      tmpNextMonthsCallingPlans[0].data &&
      tmpNextMonthsCallingPlans[0].data.calling_plan
    ) {
      setUpdatedCallingPlanData(tmpNextMonthsCallingPlans)
      // setTmpNextMonthsCallingPlans(nextMonthsCallingPlans)
    }
  }, [tmpNextMonthsCallingPlans])

  const createOrUpdateCallingPlan = (data) => {
    // nextCallingPlanId ? setIsNextBillingCycleEmpty(false) : setIsNextBillingCycleEmpty(true)
    setUpdatedCallingPlanData(data)
    setTmpNextMonthsCallingPlans(data?.data?.calling_plan || [])
    setCompareData('callingPlans', data?.data?.calling_plan || [])
  }

  const handleCallingPlanUpdateApi = () => {
    if (updatedCallingPlanData.data?.calling_plan) {
      if (updatedCallingPlanData.data.calling_plan?.length) {
        if (isNextBillingCycleEmpty && !nextCallingPlanId) {
          // setIsNextBillingCycleEmpty(false)
          createCallingPlanApi.setRefetch(true)
        } else {
          nextCallingPlanId && updateCallingPlanApi.setRefetch(true)
        }
      } else if (updatedCallingPlanData.data.calling_plan?.length === 0) {
        if (isNextBillingCycleEmpty && !nextCallingPlanId) {
          // setIsNextBillingCycleEmpty(false)
          createCallingPlanApi.setRefetch(true)
        } else {
          nextCallingPlanId && updateCallingPlanApi.setRefetch(true)
          // !nextCallingPlanId && message.info("Emptying Next billing cycle will take current month's calling plans!")
        }
      }
    }
  }

  const handleCallingPlanUpdate = () => {
    createCallingPlanApi.setResponse({})
    updateCallingPlanApi.setResponse({})
    deleteNextMonthCallingPlanApi.setResponse({})
    setTmpNextMonthsCallingPlans([])
    setCompareData('callingPlans', [])
    !isEmpty(updatedCallingPlanData.data) && callingPlanListApi.setRefetch(true)
    setUpdatedCallingPlanData({})
  }

  const getNextMonthCallingPlan = () => {
    nextMonthCallingPlanApi.setRefetch(true)
  }

  const checkForValidExtension = (event, extensionValue) => {
    if (voiceData?.id?.length) {
      const value = extensionValue ? extensionValue : extension

      extensionCheck.setId(voiceData.id)
      extensionCheck.setValue(value)
      extensionCheck.setExecute(true)
      setExtensionStatus('validating')
    }
  }

  useEffect(() => {
    if (updateVoiceUserOnTheFly.execute) {
      checkForValidExtension()
    }
  }, [updateVoiceUserOnTheFly])

  const getSendMailRequired = () => {
    const isExtensionChanged = !isEqual(
      actualVoiceUserData[0].extension,
      modifiedVoiceUserData[0].extension
    )
    const isPhoneChanged = !isEqual(
      actualVoiceUserData[0].phonenumber.id,
      modifiedVoiceUserData[0].phonenumber.id
    )

    if (isExtensionChanged) {
      return true
    } else if (isPhoneChanged) {
      return true
    } else {
      return false
    }
  }

  const getExtensionRequiredCheck = (status) => {
    if (status === 'warning' || status === 'error') {
      return true
    } else {
      return false
    }
  }

  const saveHandler = (status) => {
    const extStatus = getExtensionRequiredCheck(status)
    const equalStatus = !isEqual(actualVoiceUserData, modifiedVoiceUserData)

    if (!equalStatus) {
      return
    }

    dispatch(
      setUpdateVoiceUserOnTheFly({
        execute: false,
        postCallback: updateVoiceUserOnTheFly.postCallback,
      })
    )

    if (displayName.length === 0) {
      message.warning(translations.enter_display_name[language])
    } else if (givenName.length === 0) {
      message.warning(translations.enter_given_name[language])
    } else if (lastName.length === 0) {
      message.warning(translations.enter_last_name[language])
    } else if (hasPhoneNumberError) {
      message.warning(translations.select_phone_number_error[language])
    } else if (extStatus) {
      message.warning(translations.select_extension_error[language])
    } else if (equalStatus) {
      const sendMailReq = getSendMailRequired()
      const userUpdateData = JSON.parse(JSON.stringify(voiceData))
      const phoneUpdateData = { items: [{ id: phonenumber.id }] }

      userUpdateData.data.displayName = displayName
      userUpdateData.data.givenName = givenName
      userUpdateData.data.lastName = lastName
      userUpdateData.data.extension = extension
      userUpdateData.data.status = sendMailReq
        ? 'MODIFY_IN_PROGRESS'
        : userStatus
      delete userUpdateData.name

      userUpdater.setConnectorId(connectorId)
      userUpdater.setId(userUpdateData.id)
      userUpdater.setNewPhoneNumber(phonenumber.id)
      userUpdater.setOldPhoneNumber(phoneData.id)
      userUpdater.setPhoneBody(JSON.stringify(phoneUpdateData))
      userUpdater.setUserBody(JSON.stringify(userUpdateData))
      userUpdater.setUserObject(JSON.parse(JSON.stringify(voiceData)))
      userUpdater.setMailNeeded(sendMailReq)
      userUpdater.setExecute(true)
      handleCallingPlanUpdateApi()
    } else {
      handleCallingPlanUpdateApi()
    }
  }

  const handleAddCallingPlanModalOpen = () => {
    setAddCallingPlanModalVisibility(true)
  }

  const handleAddCallingPlanModalClose = () => {
    setAddCallingPlanModalVisibility(false)
  }

  const setNextBillingCycleStatus = (value) => {
    setIsNextBillingCycleEmpty(value)
  }

  const removeCallingPlan = (callingPlanKey) => {
    const planEffectiveMonth = getCallingPlanMonth('nextMonth')
    const filteredCallingPlan = tmpNextMonthsCallingPlans.filter(
      (callingPlan) => callingPlan.id !== callingPlanKey
    )

    const data = {
      // id: nextCallingPlanId,
      data: {
        voice_user: voiceData.id,
        calling_plan: filteredCallingPlan,
        month: planEffectiveMonth,
      },
    }

    // nextCallingPlanId ? setIsNextBillingCycleEmpty(false) : setIsNextBillingCycleEmpty(true)
    setUpdatedCallingPlanData(data)
    setTmpNextMonthsCallingPlans(filteredCallingPlan)
    setCompareData('callingPlans', filteredCallingPlan)
  }

  const handleVoiceUserInfoChange = () => {
    setIsCallingPlanSaved(false)
  }

  const handlePhoneNumberChange = (selectedPhoneNumber) => {
    if (selectedPhoneNumber) {
      const phone = phonenumbers.find((num) => num.id === selectedPhoneNumber)

      setPhonenumber(phone)
      setHasPhoneNumberError(false)
      setCompareData('phonenumber', phone)
    } else {
      setPhonenumber(defaultPhoneData)
      setCompareData('phonenumber', defaultPhoneData)
      setHasPhoneNumberError(true)
    }
    handleVoiceUserInfoChange()
  }

  const renderPhoneNumberSelect = () => {
    return (
      <Form.Item
        hasFeedback
        validateStatus={hasPhoneNumberError ? 'error' : 'success'}
      >
        <Select
          allowClear={phonenumber.id.length !== 0}
          className="select-style"
          onChange={handlePhoneNumberChange}
          placeholder={translations.select_a_phone_pumber[language]}
          value={phonenumber.id.length ? phonenumber.id : undefined}
        >
          {phonenumbers.map((number) => (
            <Select.Option key={number.id}>{number.id}</Select.Option>
          ))}
        </Select>
      </Form.Item>
    )
  }

  const getHelpText = () => {
    if (extensionStatus === 'warning') {
      return translations.select_extension_error[language]
    } else if (extensionStatus === 'error') {
      return translations.extension_in_use[language]
    } else {
      return ''
    }
  }

  const renderExtensionField = () => {
    return (
      <Form.Item
        hasFeedback
        help={getHelpText()}
        validateStatus={extensionStatus}
      >
        <Input
          value={extension}
          onKeyDown={(event) => keyDownHandler(event, 'allowOnlyNumbers')}
          onPaste={(event) => pasteHandler(event, 'allowOnlyNumbers')}
          placeholder={translations.extension_number[language]}
          onChange={(event) => {
            setExtensionStatus('')
            setExtension(event.target.value)
            setCompareData('extension', event.target.value)
            handleVoiceUserInfoChange()
          }}
        />
      </Form.Item>
    )
  }

  const setCompareData = (field, value) => {
    const data = JSON.parse(JSON.stringify(modifiedVoiceUserData))

    if (data.length) {
      data[0][field] = value
      dispatch(setModifiedVoiceUserData(data))
    }
  }

  const renderCallingPlanRow = (callingPlan = {}) => {
    const label = callingPlan?.data?.label[language]
      ? callingPlan.data.label[language]
      : ''

    return (
      <Tooltip title={label}>
        <div className="call-plan-name">{label}</div>
      </Tooltip>
    )
  }

  const onDisplayNameChange = (event) => {
    setDisplayName(event.target.value)
    setCompareData('displayName', event.target.value)
    handleVoiceUserInfoChange()
  }

  const renderDisplayName = () => {
    if (microsoftTeamsPermission === FULL_ACCESS) {
      return (
        <EditableSection
          onChangeCb={onDisplayNameChange}
          title={translations.display_name[language]}
          value={displayName}
        />
      )
    } else {
      return (
        <UnEditableSection
          title={translations.display_name[language]}
          value={displayName}
        />
      )
    }
  }

  const onGivenNameChange = (event) => {
    setGivenName(event.target.value)
    setCompareData('givenName', event.target.value)
    handleVoiceUserInfoChange()
  }

  const renderGivenName = () => {
    if (microsoftTeamsPermission === FULL_ACCESS) {
      return (
        <EditableSection
          onChangeCb={onGivenNameChange}
          title={translations.given_name[language]}
          value={givenName}
        />
      )
    } else {
      return (
        <UnEditableSection
          title={translations.given_name[language]}
          value={givenName}
        />
      )
    }
  }

  const onLastNameChange = (event) => {
    setLastName(event.target.value)
    setCompareData('lastName', event.target.value)
    handleVoiceUserInfoChange()
  }

  const renderLastName = () => {
    if (microsoftTeamsPermission === FULL_ACCESS) {
      return (
        <EditableSection
          onChangeCb={onLastNameChange}
          title={translations.last_name[language]}
          value={lastName}
        />
      )
    } else {
      return (
        <UnEditableSection
          title={translations.last_name[language]}
          value={lastName}
        />
      )
    }
  }

  const renderEmail = () => {
    return (
      <UnEditableSection
        title={translations.email[language]}
        value={mail}
      />
    )
  }

  const renderPhoneNumber = () => {
    if (microsoftTeamsPermission === FULL_ACCESS) {
      return (
        <Fragment>
          <div style={{ marginBottom: 5 }}>
            {translations.phone_number_label[language]}
          </div>
          <div style={{ marginBottom: 5 }}>
            {renderPhoneNumberSelect()}
          </div>
        </Fragment>
      )
    } else {
      return (
        <UnEditableSection
          title={translations.phone_number_label[language]}
          value={phonenumber.id.length ? phonenumber.id : ''}
        />
      )
    }
  }

  const renderExtension = () => {
    /* const height =
      extensionStatus === 'warning' || extensionStatus === 'error' ? 85 : 75 */

    if (microsoftTeamsPermission === FULL_ACCESS) {
      return (
        <Fragment>
          <div style={{ marginBottom: 5 }}>
            {translations.extension[language]}
          </div>
          <div style={{ marginBottom: 5 }}>{renderExtensionField()}</div>
        </Fragment>
      )
    } else {
      return (
        <Fragment>
          <div style={{ marginBottom: 5 }}>
            {translations.extension[language]}
          </div>
          <div
            style={{
              marginBottom: 5,
              padding: '4px 11px',
              border: '1px solid lightgrey',
              height: 32,
            }}
          >
            {extension}
          </div>
        </Fragment>
      )
    }
  }

  const renderAddCallingPlanButton = () => {
    if (microsoftTeamsPermission === FULL_ACCESS) {
      return (
        <PttButton
          customClass="edit-custom-style"
          onClick={handleAddCallingPlanModalOpen}
          type="dashed"
        >
          {translations.add_calling_plan[language]}
        </PttButton>
      )
    } else {
      return <Fragment />
    }
  }

  const renderCloseIcon = (callingPlanId) => {
    if (microsoftTeamsPermission === FULL_ACCESS) {
      return (
        <CloseOutlined
          title="Delete calling plan"
          className="delete-call-plan-icon"
          onClick={() => removeCallingPlan(callingPlanId)}
        />
      )
    } else {
      return <Fragment />
    }
  }

  const renderCallingPlan = () => {
    return (
      <div className="">
        <div className="edit-heading">
          {translations.calling_plan[language]}
        </div>
        <div className="calling-plan-block">
          <div className="billing-label">
            {translations.current_billing_cycle[language]}
          </div>
          {currentCallingPlanApi.loading ? (
            <div className="spin-style-small">
              <Spinner spinning={true} />
            </div>
          ) : (
            currentCallingPlans.map((callingPlan) => {
              return (
                <div
                  className="call-plan-label"
                  key={`current_month_${callingPlan.id}`}
                >
                  {renderCallingPlanRow(callingPlan)}
                </div>
              )
            })
          )}
        </div>
        <div className="calling-plan-block">
          <div className="billing-label">
            {translations.next_billing_cycle[language]}
          </div>

          {updateCallingPlanApi.loading ||
          createCallingPlanApi.loading ||
          nextMonthCallingPlanApi.loading ? (
            <div className="spin-style-small">
              <Spinner spinning={true} />
            </div>
          ) : (
            (tmpNextMonthsCallingPlans || []).map((callingPlan) => {
              return (
                <div
                  className="call-plan-label"
                  key={`next_month_${callingPlan.id}`}
                >
                  {renderCallingPlanRow(callingPlan)}
                  {renderCloseIcon(callingPlan.id)}
                </div>
              )
            })
          )}
          {renderAddCallingPlanButton()}
        </div>
      </div>
    )
  }

  const renderActionButtons = () => {
    if (microsoftTeamsPermission === FULL_ACCESS) {
      return (
        <div style={{ height: 90, marginTop: 10 }}>
          <PttButton
            customClass="edit-custom-style"
            onClick={checkForValidExtension}
            type="primary"
          >
            {translations.save[language]}
          </PttButton>
          <Button
            className="edit-custom-btn"
            onClick={() => deleteVoiceUser('Delete')}
            danger
          >
            {translations.delete_voice_user[language]}
          </Button>
        </div>
      )
    } else {
      return <Fragment />
    }
  }

  const layoutStyle =
    microsoftTeamsPermission === FULL_ACCESS
      ? {
          height: 'calc(100% - 100px)',
          overflow: 'auto',
          padding: '0px 5px',
        }
      : {
          height: 'calc(100% - 0px)',
          overflow: 'auto',
          padding: '0px 5px',
        }

  return userUpdater.loading ||
    updateVoiceUserApi.loading ||
    callingPlanListApi.loading ||
    createCallingPlanApi.loading ||
    updateCallingPlanApi.loading ||
    deleteNextMonthCallingPlanApi.loading ? (
    <div className="spin-style">
      <Spinner spinning={true} />
    </div>
  ) : (
    <div className="edit-cont">
      <div style={layoutStyle}>
        {renderDisplayName()}
        {renderGivenName()}
        {renderLastName()}
        {renderEmail()}
        {renderPhoneNumber()}
        {renderExtension()}
        {renderCallingPlan()}
      </div>
      {renderActionButtons()}
      <AddCallingPlanModal
        visiblity={addCallingPlanModalVisibility}
        handleModalClose={handleAddCallingPlanModalClose}
        callingPlans={callingPlansApi.response}
        voiceUserData={voiceData}
        voiceUserDetailApi={voiceUserDetailApi}
        nextMonthsCallingPlans={tmpNextMonthsCallingPlans}
        isNextBillingCycleEmpty={isNextBillingCycleEmpty}
        setNextBillingCycleStatus={setNextBillingCycleStatus}
        getNextMonthCallingPlan={getNextMonthCallingPlan}
        nextCallingPlanId={nextCallingPlanId}
        createOrUpdateCallingPlan={createOrUpdateCallingPlan}
      />
    </div>
  )
}

const mapsStateToProps = (state) => {
  return {
    translations: state.sampleReducer.translations || {},
    language: state.sampleReducer.language,
    msTeamsUnsaveAlertTrigger: state.msTeamsReducer.msTeamsUnsaveAlertTrigger,
    isCallingPlanSaved: state.msTeamsReducer.isCallingPlanSaved,
    actualVoiceUserData: state.msTeamsReducer.actualVoiceUserData,
    modifiedVoiceUserData: state.msTeamsReducer.modifiedVoiceUserData,
    updateVoiceUserOnTheFly: state.msTeamsReducer.updateVoiceUserOnTheFly,
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setIsCallingPlanSaved,
      setMsTeamsUnsaveAlertTrigger,
      setHomeKey,
    },
    dispatch
  )

export default connect(mapsStateToProps, mapDispatchToProps)(EditVoiceUsers)
