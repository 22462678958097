/* eslint-disable react/prop-types */
import React from 'react'
import { Button } from 'antd'
import { useSelector } from 'react-redux'
import { PttButton } from '../CustomButton/custombutton'
import ModalLayout, { Header, Body, Footer } from './popup'
import './confirmpopup.css'

const ConfirmPopup = (props) => {
  const { isResellerAccount, language, translations } = useSelector(
    ({ sampleReducer }) => ({
      language: sampleReducer.language || 'en',
      translations: sampleReducer.translations || {},
      isResellerAccount: sampleReducer.isResellerAccount,
    })
  )

  const {
    children,
    cancelText = translations.cancel[language],
    closable = true,
    confirmText = `${translations.confirm[language]}...`,
    onOkay,
    onCancel,
    onClose,
    okayText = translations.okay[language],
    loading = false,
    type = 'primary',
    visiblity,
    okBtnClassName = '',
  } = props

  const handleClose = () => {
    if (onClose) {
      onClose()
    } else {
      onCancel()
    }
  }

  const handleCancel = () => {
    onCancel()
  }

  const handleOkay = () => {
    onOkay()
  }

  return (
    <ModalLayout closable={closable} onCancel={handleClose} visible={visiblity}>
      <Header>{confirmText}</Header>
      <Body>{children}</Body>
      <Footer>
        <div className="cp-button-body">
          <Button
            className={
              isResellerAccount
                ? 'cp-back-button-style-reseller'
                : 'cp-back-button-style'
            }
            onClick={handleCancel}
          >
            {cancelText}
          </Button>
          <PttButton
            customClass={okBtnClassName}
            type={type}
            onClick={handleOkay}
            loading={loading}
          >
            {okayText}
          </PttButton>
        </div>
      </Footer>
    </ModalLayout>
  )
}

export default ConfirmPopup
