// @flow

import React from 'react'
import { Select } from 'antd'
import { COMMON_IMG_URL } from '../../../utils/constants'

type Props = {
  data: Object,
  dropDownData: $ReadOnlyArray<*>,
  onChange: (value: string, key: string) => mixed,
}

const NodeDropDown = ({ data, dropDownData, onChange }: Props) => {
  const [selected, setSelected] = React.useState(data)

  React.useEffect(() => {
    setSelected(data)
  }, [data])

  return (
    <Select
      allowClear={selected.value.length > 0}
      onChange={(value) => onChange(value, selected.position)}
      placeholder="Select Action"
      style={{ width: '100%' }}
      value={selected.value}
    >
      {dropDownData.map(({ callFlowId, icon = '', name }, index) => (
        <Select.Option key={index} value={callFlowId}>
          <img
            alt=""
            className="opt-image"
            src={`${COMMON_IMG_URL}nodeicon/${icon}.png`}
          />
          {name}
        </Select.Option>
      ))}
    </Select>
  )
}

export default React.memo<Props>(NodeDropDown)
