/* eslint-disable react/prop-types */
/* eslint-disable no-negated-condition */
// @flow

import React, { Fragment, useEffect, useState, useMemo } from 'react'
import { Checkbox, Input, message } from 'antd'
import { LockOutlined, UserOutlined, BankOutlined } from '@ant-design/icons'
import StartupImagePanel from '../StartUps/StartupImagePanel'
import { StartupButton } from '../CustomButton/custombutton'
import Spinner from '../../Spinner/spinner'
import { getParameterByName } from '../../utils/helpers'
import useCustomFetch from '../../Hooks/UseCustomFetch'
import {
  SIGNUP_API,
  LOGINAUTHURL,
  CUSTOM_ACTION_URL,
  COMMON_IMG_URL,
  EXPIRE,
  NO_ACCESS,
} from '../../utils/constants'
import { useHistory } from 'react-router-dom'
import { putService } from '../../services/RequestService'
import { removeTokens, setTokens } from '../../utils/LocalStorage'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from '../../actions/SampleAction'
import ReactFlagsSelect from 'react-flags-select'
import useConstructor from '../../Hooks/UseConstructor'
import { getItem } from '../../utils/storage'

const cc = getParameterByName('cc')

type Props = {
  history: History,
  setUserDetails: (any) => mixed,
  translations: Object,
  language: string,
  appUrlBrand: string,
}

const SetPassword = (props: Props) => {
  const {
    appUrlBrand,
    translations,
    language,
    isResellerAccount,
    microsoftTeamsPermission,
    phoneNumberPermission,
    statsPermission,
    advancedPermission,
    billingPermission,
  } = props
  const history = useHistory()

  const checkIsAuthenticated = () => {
    if (getItem(EXPIRE) && Number(getItem(EXPIRE)) * 1000 > Date.now()) {
      const route = () => {
        if (microsoftTeamsPermission !== NO_ACCESS) {
          return '/msteams'
        } else if (phoneNumberPermission !== NO_ACCESS) {
          return '/phonenumbers'
        } else if (statsPermission !== NO_ACCESS) {
          return '/stats'
        } else if (advancedPermission !== NO_ACCESS) {
          return '/advanced'
        } else if (billingPermission !== NO_ACCESS) {
          return '/billing'
        } else {
          return '/settings'
        }
      }

      const path = isResellerAccount ? '/clients' : route()

      history.push({ pathname: path })
    } else {
      removeTokens()
    }
  }

  useConstructor(checkIsAuthenticated)

  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [companyName, setCompanyName] = useState('')
  const [userName, setUserName] = useState('')
  const [countryCode, setCountryCode] = useState(cc ? cc : '')
  const [spanishTaxExempt, setSpanishTaxExempt] = useState(false)
  const [acceptedTerms] = useState(true)

  const messages = useMemo(() => {
    return {
      password: translations.setPassword_password[language],
      confirmPassword: translations.setPassword_confirmPassword[language],
      noMatch: translations.setPassword_noMatch[language],
      success: translations.setPassword_success[language],
      failed: translations.setPassword_failed[language],
      userName: translations.username_required_msg[language],
      companyName: translations.companyName_required_msg[language],
      ccError: translations.countryCode_required_msg[language],
    }
  }, [translations, language])

  const token = getParameterByName('token')
  const email = getParameterByName('email')
  const type = getParameterByName('type')

  /* Signup 2 */
  const [signup2Api] = useCustomFetch(
    `${SIGNUP_API}/2/${email}?myDirectRouting=false`,
    'SIGNUP_POST',
    false,
    JSON.stringify({
      invitetoken: token,
      password: password,
      userName: userName.trim(),
      companyName: companyName.trim(),
      countryCode: countryCode,
      spanishTaxExempt: spanishTaxExempt,
      accountType: type === 'user' ? type : '',
    })
  )

  /* Create Stripe customer */
  const [createStripeCustomerApi] = useCustomFetch(
    `${CUSTOM_ACTION_URL}/payment`,
    'PAYMENT',
    false,
    JSON.stringify({
      action: 'CREATE_CUSTOMER',
      data: {
        email: email,
      },
    })
  )

  useEffect(() => {
    if (signup2Api.response && signup2Api.response.status) {
      const data = {
        username: String(email).trim(),
        password: String(password).trim(),
      }

      putService(
        LOGINAUTHURL,
        JSON.stringify(data),
        loginAuthResultHandler,
        ErrorHandler
      )
    } else if (signup2Api.response && signup2Api.response.status === false) {
      ErrorHandler(signup2Api.response.error)
    }
  }, [signup2Api.response])

  const loginAuthResultHandler = (response) => {
    if (response.result === true) {
      setTokens(response)
      props.setUserDetails({ username: email })
      setTimeout(() => {
        setLoading(false)
        history.push({ pathname: '/' })
      }, 1000)
      // createStripeCustomerApi.setRefetch(true)
    } else {
      message.error(messages.failed)
      setLoading(false)
    }
  }

  const ErrorHandler = (errorMsg) => {
    setLoading(false)
    message.error(
      (translations[errorMsg] && translations[errorMsg][language]) ||
        messages.failed
    )
  }

  useEffect(() => {
    if (
      createStripeCustomerApi.response &&
      createStripeCustomerApi.response.status
    ) {
      setTimeout(() => {
        setLoading(false)
        history.push({ pathname: '/' })
      }, 1000)
    } else if (
      createStripeCustomerApi.response &&
      (createStripeCustomerApi.response.status === false ||
        signup2Api.response.error)
    ) {
      setLoading(false)
      message.error(signup2Api.response.error || messages.failed)
    }
  }, [createStripeCustomerApi.response])

  const onPasswordChange = (event) => {
    setPassword(event.target.value)
  }

  const onConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value)
  }

  const onCountryChange = (countryCode) => {
    setCountryCode(countryCode)
  }

  const onSpanishExcemptChange = (e) => {
    setSpanishTaxExempt(e.target.checked)
  }

  const onInputChange = (event, key) => {
    if (key === 'userName') {
      setUserName(event.target.value)
    } else if (key === 'companyName') {
      setCompanyName(event.target.value)
    }
  }

  const onConfirmClick = () => {
    if (!userName.trim().length) {
      return message.warning(messages.userName)
    } else if (type !== 'user' && !companyName.trim().length) {
      return message.warning(messages.companyName)
    } else if (type !== 'user' && !countryCode.length) {
      return message.warning(messages.ccError)
    } else if (!password.length) {
      return message.warning(messages.password)
    } else if (!confirmPassword.length) {
      return message.warning(messages.confirmPassword)
    } else if (password !== confirmPassword) {
      return message.warning(messages.noMatch)
    } else if (!acceptedTerms) {
      return message.warning(messages.termsAlert)
    }

    setLoading(true)
    signup2Api.setRefetch(true)
  }

  const renderCompanyField = () => {
    if (type && type === 'user') {
      return <Fragment />
    } else {
      return (
        <div>
          <Input
            className="start-up-input"
            onChange={(event) => onInputChange(event, 'companyName')}
            placeholder={translations.userdetail_company[language]}
            onPressEnter={onConfirmClick}
            prefix={<BankOutlined className="startup-prefix-style" />}
            size="large"
            value={companyName}
          />
        </div>
      )
    }
  }

  const renderCountryField = () => {
    if (type && type === 'user') {
      return <Fragment />
    } else {
      return (
        <div>
          <ReactFlagsSelect
            className="sp-dd"
            searchable
            selectedSize={12}
            optionsSize={12}
            selected={countryCode}
            onSelect={onCountryChange}
            placeholder={translations.company_country[language]}
          />
        </div>
      )
    }
  }

  const renderInputs = () => {
    return (
      <Fragment>
        <div>
          <Input
            className="start-up-input"
            onChange={(event) => onInputChange(event, 'userName')}
            placeholder={translations.userdetail_name[language]}
            onPressEnter={onConfirmClick}
            prefix={<UserOutlined className="startup-prefix-style" />}
            size="large"
            value={userName}
          />
        </div>
        {renderCompanyField()}
        {renderCountryField()}
        {countryCode === 'ES' && (
          <Checkbox onChange={onSpanishExcemptChange} value={spanishTaxExempt}>
            {translations.spain_tax_excempt_area[language]}
          </Checkbox>
        )}
        <div>
          <Input.Password
            className="start-up-input"
            onChange={onPasswordChange}
            placeholder={translations.new_password[language]}
            onPressEnter={onConfirmClick}
            prefix={<LockOutlined className="startup-prefix-style" />}
            size="large"
            value={password}
          />
        </div>
        <div>
          <Input.Password
            className="start-up-input"
            onChange={onConfirmPasswordChange}
            placeholder={translations.confirm_password[language]}
            onPressEnter={onConfirmClick}
            prefix={<LockOutlined className="startup-prefix-style" />}
            size="large"
            value={confirmPassword}
          />
        </div>
        <div
          style={{
            height: 20,
            margin: '10px 0px',
            textAlign: 'center',
          }}
        >
          {/* <Checkbox
            checked={acceptedTerms}
            onChange={(event) => setAcceptedTerms(event.target.checked)}
            style={{ marginRight: 5 }}
          /> */}
          <div>{translations.apply_tc[language]}</div>
        </div>
        <StartupButton
          block
          disabled={loading}
          loading={loading}
          onClick={onConfirmClick}
        >
          {translations.create_account[language]}
        </StartupButton>
      </Fragment>
    )
  }

  const renderLogo = () => {
    const logo = `${COMMON_IMG_URL}branding/PTT/logo1.png`

    if (appUrlBrand === 'Plug2Teams') {
      return (
        <div className="align-center">
          <img alt="pttlogo" className="setpassword-colored-logo" src={logo} />
        </div>
      )
    } else {
      return <Fragment />
    }
  }

  return (
    <div className="startup-container">
      <div className="startup-image-section">
        <StartupImagePanel text={translations.startup_image_text[language]} />
      </div>
      <div className="startup-field-section">
        <div className="align-center full-height">
          <Spinner color="#00569D" spinning={loading}>
            <div className="align-center startup-field-container">
              <div className="startup-field-inputs">
                {/* <div className="align-center startup-title" /> */}
                {renderLogo()}
                <div className="align-center startup-title-setpassword">
                  {translations.define_password[language]}
                </div>
                <span className="align-center startup-subtitle-setpassword">
                  {translations.define_password_info[language]}
                </span>
                {renderInputs()}
              </div>
            </div>
          </Spinner>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...actions }, dispatch)

const mapsStateToProps = (state) => {
  return {
    translations: state.sampleReducer.translations || {},
    language: state.sampleReducer.language,
    appUrlBrand: state.sampleReducer.appUrlBrand,
    isResellerAccount: state.isResellerAccount,
    microsoftTeamsPermission: state.permissionReducer.microsoftTeamsPermission,
    phoneNumberPermission: state.permissionReducer.phoneNumberPermission,
    statsPermission: state.permissionReducer.statsPermission,
    advancedPermission: state.permissionReducer.advancedPermission,
    billingPermission: state.permissionReducer.billingPermission,
  }
}

export default connect(mapsStateToProps, mapDispatchToProps)(SetPassword)
