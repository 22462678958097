// @flow

import React from 'react'
import { Select } from 'antd'
import { isTextSame } from '../../../utils/helpers'
import { NODE_TYPES } from '../nodetypes'
import './ApiMethodComponent.css'

type Props = {
  autoSaveHandler: (any) => mixed,
  label?: string,
  keyName: string,
  mode: string,
  placeholder?: string,
  selectedValue: string,
}

const options = [
  'GET',
  'POST',
  'PUT',
  'PATCH',
  'DELETE',
  'COPY',
  'HEAD',
  'OPTIONS',
  'LINK',
  'UNLINK',
  'PURGE',
  'LOCK',
  'UNLOCK',
  'PROPFIND',
  'VIEW',
]

const ApiMethodComponent = React.forwardRef<Props, *>(
  (
    {
      autoSaveHandler,
      label = 'Api Method',
      keyName,
      mode,
      placeholder = '',
      selectedValue,
    }: Props,
    ref
  ) => {
    const [value, setValue] = React.useState('')
    const [update, setUpdate] = React.useState(false)

    React.useEffect(() => {
      setValue(selectedValue)
    }, [selectedValue])

    React.useImperativeHandle(ref, () => ({
      getLatestValue() {
        return value
      },
      getName() {
        return keyName
      },
      getType() {
        return NODE_TYPES.apimethod
      },
      resetValue() {
        setValue('')
      },
    }))

    const changeHandler = (changedValue = '') => {
      setValue(changedValue)
      if (!isTextSame(changedValue, value)) {
        setUpdate(true)
      }
    }

    React.useEffect(() => {
      if (update && mode === 'Edit' && autoSaveHandler) {
        autoSaveHandler(NODE_TYPES.apimethod)
        setUpdate(false)
      }
    }, [update])

    return (
      <React.Fragment>
        <div className="label-spacing">{label}</div>
        <Select
          allowClear={value.length > 0}
          onChange={changeHandler}
          placeholder={placeholder}
          size="default"
          style={{ width: '100%' }}
          value={value}
        >
          {options.map((option) => (
            <Select.Option key={option} value={option}>
              <div className="ApiMethodComponent__SelectBody">{option}</div>
            </Select.Option>
          ))}
        </Select>
      </React.Fragment>
    )
  }
)

export default React.memo<Props>(ApiMethodComponent)
