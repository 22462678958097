// @flow

import React, { useEffect, useState, Fragment } from 'react'
import { connect } from 'react-redux'
import ModalLayout, { Body, Footer, Header } from '../../../Popup/popup'
import Step from '../../../Steps/step'
import AddPhoneNumber from './AddPhoneNumber'
import { CUSTOM_ACTION_URL } from '../../../../utils/constants'
import useCustomFetch from '../../../../Hooks/UseCustomFetch'
import { setPaymentSucceededEventData } from '../../../../actions/billingAction'
import PttButton from '../../../CustomButton/PttButton'
import PhoneNumberCheckout from './PhoneNumberCheckout'
import { Alert, Button, message } from 'antd'
import { bindActionCreators } from 'redux'

const initialErrorState = {
  closable: true,
  isError: false,
  message: '',
  type: '',
}

type Props = {
  selectedPhoneNumberDetails: Object,
  handleCloseAddPhonePopup: (any) => mixed,
  visiblity: boolean,
  getPhoneNoList: (any) => mixed,
  translations: Object,
  language: string,
  setPaymentSucceededEventData: (any) => mixed,
}

const AddPhoneModal = (props: Props) => {
  const {
    selectedPhoneNumberDetails,
    handleCloseAddPhonePopup,
    visiblity,
    getPhoneNoList,
    translations,
    language,
    setPaymentSucceededEventData,
  } = props

  const [errorState, setErrorState] = useState(initialErrorState)
  const [currentStep, setCurrentStep] = useState(0)
  const [isPhoneNoSelected, setIsPhoneNoSelected] = useState(false)
  const [checkoutConfirm, setCheckoutConfirm] = useState(false)
  const [checkoutApiBodyData, setCheckoutApiBodyData] = useState({ action: '' })
  const [amountToCharge, setAmountToCharge] = useState(0)
  const [checkoutLoader, setCheckoutLoader] = useState(false)

  /* assign phone number and add charge made */
  const [checkoutApi] = useCustomFetch(
    `${CUSTOM_ACTION_URL}/payment`,
    'PAYMENT',
    false,
    JSON.stringify(checkoutApiBodyData)
  )

  useEffect(() => {
    if (checkoutApiBodyData.action) {
      checkoutApi.setRefetch(true)
    }
  }, [checkoutApiBodyData])

  useEffect(() => {
    if (checkoutApi.response && checkoutApi.response.status) {
      setCheckoutLoader(false)
      setCurrentStep(0)
      handleCloseAddPhonePopup()
      getPhoneNoList()
      // moveStepForward()
      message.success(translations.phone_number_added_success[language])
    } else if (checkoutApi.response && checkoutApi.response.status === false) {
      message.error(translations.something_went_wrong[language])
    }
  }, [checkoutApi.response])

  const getErrorContainer = () => {
    if (errorState.isError) {
      return (
        <Alert
          className="tag-space"
          closable={errorState.closable}
          message={errorState.message}
          onClose={() => setErrorState(initialErrorState)}
          type={errorState.type}
        />
      )
    } else {
      return <Fragment />
    }
  }

  const enablePhoneNoSelected = (value) => {
    setIsPhoneNoSelected(value)
  }

  const enableCheckoutConfirm = (value, amountToCharge = 0) => {
    setPaymentSucceededEventData(null)
    setAmountToCharge(amountToCharge)
    setCheckoutConfirm(value)
  }

  const pages = [
    {
      title: translations.choose_phone_number[language],
      component: () => {
        return (
          <div style={{ height: '100%' }}>
            <div style={{ marginTop: '10px' }}>
              <Alert
                message={translations.choose_phone_number_info[language]}
                type="info"
                showIcon
              />
            </div>
            <AddPhoneNumber
              enablePhoneNoSelected={enablePhoneNoSelected}
              selectedPhoneNumberDetails={selectedPhoneNumberDetails}
            />
          </div>
        )
      },
    },
    {
      title: translations.checkout[language],
      component: () => {
        return (
          <PhoneNumberCheckout
            selectedPhoneNumbers={
              selectedPhoneNumberDetails.selectedPhonenumbers || []
            }
            enableCheckoutConfirm={enableCheckoutConfirm}
          />
        )
      },
    },
  ]

  const getSteps = () => {
    if (currentStep === 5) {
      return <Fragment />
    } else {
      return <Step steps={pages} currentStep={currentStep} titleKey={'title'} />
    }
  }

  const moveStepForward = () => {
    setCurrentStep((step) => step + 1)
  }

  const moveStepBackward = () => {
    setCurrentStep((step) => step - 1)
  }

  const addPhoneNumbers = () => {
    if (selectedPhoneNumberDetails.selectedPhonenumbers.length) {
      const phoneNumbers = selectedPhoneNumberDetails.selectedPhonenumbers.map(
        (item) => {
          if (item.skuId) {
            return {
              geo: {
                area: selectedPhoneNumberDetails.city,
                country: selectedPhoneNumberDetails.countryName,
                countrycode: selectedPhoneNumberDetails.country,
              },
              price: item.price,
              skuId: item.skuId,
              id: item.key,
              didId: item.id,
            }
          } else {
            return { id: item.key }
          }
        }
      )

      setCheckoutApiBodyData({
        action: 'PHONE_NUMBER_CHECKOUT',
        data: {
          chargeAmount: amountToCharge,
          phoneNumbers: phoneNumbers,
        },
      })
    }
  }

  const getFooter = () => {
    if (currentStep === 0) {
      return (
        <div className="stepmodal-footer-end">
          <PttButton
            onClick={() => {
              moveStepForward()
            }}
            type="primary"
            disabled={!isPhoneNoSelected}
          >
            {translations.next[language]}
          </PttButton>
        </div>
      )
    } else if (currentStep === 1) {
      return (
        <div className="stepmodal-footer">
          <Button
            className="back-button-style"
            disabled={checkoutLoader}
            onClick={() => {
              moveStepBackward()
            }}
          >
            {translations.back[language]}
          </Button>
          <PttButton
            onClick={() => {
              setCheckoutLoader(true)
              addPhoneNumbers()
            }}
            type="primary"
            disabled={!checkoutConfirm || checkoutLoader}
            loading={checkoutLoader}
          >
            {translations.confirm[language]}
          </PttButton>
        </div>
      )
    } else {
      return ''
    }
  }

  const getBody = () => {
    return (
      <div style={{ height: 'calc(100% - 46px)' }}>
        {pages[currentStep].component()}
      </div>
    )
  }

  const exitHandler = () => {
    setCurrentStep(0)
    setCheckoutLoader(false)
    handleCloseAddPhonePopup()
  }

  return (
    <ModalLayout
      visible={visiblity}
      onCancel={exitHandler}
      className="add-phone-modal"
      maskClosable={false}
    >
      <Header>{translations.add_phone_number[language]}</Header>
      <Body>
        {getErrorContainer()}
        {getSteps()}
        {getBody()}
      </Body>
      <Footer>{getFooter()}</Footer>
    </ModalLayout>
  )
}

const mapsStateToProps = (state) => {
  return {
    selectedPhoneNumberDetails:
      state.phoneNumberReducer.selectedPhoneNumberDetails || {},
    translations: state.sampleReducer.translations || {},
    language: state.sampleReducer.language,
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setPaymentSucceededEventData,
    },
    dispatch
  )

export default connect(mapsStateToProps, mapDispatchToProps)(AddPhoneModal)
