// @flow

import React, { useState } from 'react'
import { sortBy } from 'lodash'
import { Input, Radio, Select, Tooltip } from 'antd'
import { PlayCircleOutlined } from '@ant-design/icons'
import { FlagIcon } from 'react-flag-kit'
import { PttButton } from '../../CustomButton/custombutton'
import { NODE_TYPES } from '../nodetypes'
import AudioUploader from '../CustomDropZone/AudioUploader'
import Audiobar from '../CustomDropZone/Audiobar'
import './Announcement.css'

var updateCallFlow = false
const maleIcon = require('../../../assets/icon/male.svg')
const femaleIcon = require('../../../assets/icon/female.svg')

const Announcement = React.forwardRef<Props, *>(
  (
    {
      label,
      autoSaveHandler,
      keyName,
      mode,
      selectedValue = [],
      ttsSpeakers = [],
      ttsLanguages = [],
      language,
      translations,
    }: Props,
    ref
  ) => {
    const [option, setOption] = useState('')
    const [lang, setLang] = useState('')
    const [speaker, setSpeaker] = useState('')
    const [text, setText] = useState('')
    const [file, setFile] = useState([])
    const [update, setUpdate] = useState(false)

    const resetLocalValue = () => {
      setLang('')
      setSpeaker('')
      setText('')
      setFile([])
    }

    const getFileEntityValue = (entity) => {
      if (file.length) {
        const res = file.find((data) => data.key === entity)

        return res ? res.value : ''
      } else {
        return ''
      }
    }

    React.useImperativeHandle(ref, () => ({
      getLatestValue() {
        return [
          { key: 'selectedOption', value: option },
          {
            key: 'selectedLanguage',
            value: option === 'textToSpeech' ? lang : '',
          },
          {
            key: 'selectedSpeaker',
            value: option === 'textToSpeech' ? speaker : '',
          },
          { key: 'selectedText', value: option === 'textToSpeech' ? text : '' },
          {
            key: 'selectedAudioId',
            value: option === 'uploadFile' ? getFileEntityValue('fileId') : '',
          },
          {
            key: 'selectedAudioName',
            value:
              option === 'uploadFile' ? getFileEntityValue('fileName') : '',
          },
        ]
      },
      getName() {
        return keyName
      },
      getType() {
        return NODE_TYPES.announcement
      },
      resetValue() {
        setOption('textToSpeech')
        resetLocalValue()
      },
    }))

    React.useEffect(() => {
      if (update && mode === 'Edit' && autoSaveHandler) {
        autoSaveHandler(NODE_TYPES.announcement, updateCallFlow)
        setUpdate(false)
        updateCallFlow = false
      }
    }, [update])

    const setValues = () => {
      const getValue = (entity) => {
        return selectedValue.length
          ? selectedValue.find((val) => val.key === entity)
          : {}
      }

      const getExactVal = (entity) => {
        const value = getValue(entity)

        if (Object.keys(value).length) {
          return value.value
        } else if (entity === 'selectedOption') {
          return 'textToSpeech'
        } else if (entity === 'selectedOption') {
          return []
        } else {
          return ''
        }
      }

      const id = getExactVal('selectedAudioId')
      const name = getExactVal('selectedAudioName')
      const localfile = []

      id.length && localfile.push({ key: 'fileId', value: id })
      name.length && localfile.push({ key: 'fileName', value: name })
      setOption(getExactVal('selectedOption'))
      setLang(getExactVal('selectedLanguage'))
      setSpeaker(getExactVal('selectedSpeaker'))
      setText(getExactVal('selectedText'))
      setFile(localfile.length === 2 ? localfile : [])
    }

    React.useEffect(() => {
      setValues()
    }, [selectedValue])

    const onLanguageChange = (value = '') => {
      setLang(value)
      setSpeaker('')
      setUpdate(true)
    }

    const onSpeakerChange = (value = '') => {
      setSpeaker(value)
      setUpdate(true)
    }

    const renderLanguageOptions = (sortedLanguages) =>
      sortedLanguages.map((ttsLang) => (
        <Select.Option key={ttsLang.code} value={ttsLang.code}>
          <div className="Announcement-OptionBody">
            <div className="Announcement-OptionBodySepertor">
              <FlagIcon code={ttsLang.exactCode} size={24} />
            </div>
            <div className="Announcement-OptionBodyText">{ttsLang.label}</div>
          </div>
        </Select.Option>
      ))

    const renderSpeakerOptions = (sortedSpeakers) =>
      sortedSpeakers
        .filter((ttsVoice) => ttsVoice.LanguageCode === lang)
        .map((ttsVoice) => (
          <Select.Option key={ttsVoice.Name} value={ttsVoice.Name}>
            <div className="Announcement-OptionBody">
              <div className="Announcement-OptionBodySepertor">
                <img
                  alt=""
                  className="Announcement-OptionIcon"
                  src={ttsVoice.Gender === 'Male' ? maleIcon : femaleIcon}
                />
              </div>
              <div className="Announcement-OptionBodyText">{ttsVoice.Name}</div>
            </div>
          </Select.Option>
        ))

    const renderTextToSpeechOptions = () => {
      const sortedLanguages = sortBy(ttsLanguages, [(ttsLang) => ttsLang.label])
      const sortedSpeakers = sortBy(ttsSpeakers, [(ttsVoice) => ttsVoice.Name])

      return (
        <React.Fragment>
          <div className="Announcement-Content-Spacing">
            <Select
              allowClear={lang.length !== 0}
              className="Announcement-Select Announcement-Rightspace"
              placeholder={translations.language[language]}
              value={lang === '' ? undefined : lang}
              onChange={onLanguageChange}
            >
              {renderLanguageOptions(sortedLanguages)}
            </Select>
            <Select
              allowClear={speaker.length !== 0}
              className="Announcement-Select"
              disabled={lang.length === 0}
              placeholder={translations.speaker[language]}
              value={speaker === '' ? undefined : speaker}
              onChange={onSpeakerChange}
            >
              {renderSpeakerOptions(sortedSpeakers)}
            </Select>
          </div>
          <div className="Announcement-Content-Spacing-bet Announcement-Topspace">
            <span className="Announcement-titlehead">
              {translations.text[language]}
            </span>
            <Tooltip title={translations.play[language]}>
              <PttButton
                size="small"
                type="primary"
                icon={<PlayCircleOutlined />}
              >
                {translations.play[language]}
              </PttButton>
            </Tooltip>
          </div>
          <div className="Announcement-Topspace">
            <Input.TextArea
              autoSize={{ minRows: 5, maxRows: 5 }}
              onBlur={() => setUpdate(true)}
              onChange={(event) => setText(event.target.value)}
              value={text}
            />
          </div>
        </React.Fragment>
      )
    }

    const renderUploadFileOptions = () => {
      return file.length ? (
        <Audiobar
          details={file}
          deleteCb={() => {
            setFile([])
            setUpdate(true)
            updateCallFlow = true
          }}
          translations={translations}
          language={language}
        />
      ) : (
        <div className="Announcement-Upload-Container">
          <AudioUploader
            doneCb={(file) => {
              setFile(file)
              setUpdate(true)
            }}
            translations={translations}
            language={language}
          />
        </div>
      )
    }

    const onRadioChange = (event) => {
      setOption(event.target.value)
    }

    return (
      <React.Fragment>
        <div className="Announcement-Topspace"> {label} </div>
        <div
          className={
            file.length
              ? 'Announcement-Container-file'
              : 'Announcement-Container'
          }
        >
          <Radio.Group onChange={onRadioChange} value={option}>
            <Radio className="Announcement-RadioButton" value={'textToSpeech'}>
              {translations.textToSpeech[language]}
            </Radio>
            <Radio className="Announcement-RadioButton" value={'uploadFile'}>
              {translations.uploadFile[language]}
            </Radio>
          </Radio.Group>
          <div className="Announcement-Topspace">
            {option === 'textToSpeech'
              ? renderTextToSpeechOptions()
              : renderUploadFileOptions()}
          </div>
        </div>
      </React.Fragment>
    )
  }
)

export default React.memo<Props>(Announcement)
