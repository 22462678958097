import { getItem } from './storage'
import { fetchEnviroinment } from './helpers'

const action = 'action'
const customAction = 'customaction'
const apiAuthStage = 'auth'
const apiName = 'app'
const apiStage = 'apimanager'
const data = 'data'
const enviroinment = fetchEnviroinment()

export const getAuth = () => {
  return `${getItem(TOKEN_TYPE)} ${getItem(ID_TOKEN)}`
}

/* token types */
export const EXTRA_TOKEN = 'ExtraToken'
export const REFRESH_TOKEN = 'RefreshToken'
export const ID_TOKEN = 'IdToken'
export const TOKEN_TYPE = 'TokenType'
export const EXPIRE = 'Expire'

/*  Dynamic public Assets */
export const COMMON_IMG_URL = enviroinment.imageUrl

/* Api */
export const APIURL = `${enviroinment.apiBaseUrl}${apiStage}/${data}/${apiName}`
export const ACTIONAPIURL = `${enviroinment.apiBaseUrl}${apiStage}/${action}/${apiName}`
export const LOGINAUTH1URL = `${enviroinment.apiBaseUrl}${apiAuthStage}/auth.s1/${apiName}`
export const LOGINAUTH2URL = `${enviroinment.apiBaseUrl}${apiAuthStage}/auth.s2/${apiName}`
export const LOGINAUTHURL = `${enviroinment.apiBaseUrl}${apiAuthStage}/auth/${apiName}`
export const ASSUMEURL = `${enviroinment.apiBaseUrl}${apiAuthStage}/assume/${apiName}`
export const CUSTOM_ACTION_URL = `${enviroinment.apiBaseUrl}${apiStage}/${customAction}/${apiName}`
export const REFRESHURL = `${enviroinment.apiBaseUrl}${apiAuthStage}/refresh/${apiName}`
export const SETUPCONNECTORAPI = `${CUSTOM_ACTION_URL}/setupconnector/`
export const SIGNUP_API = `${enviroinment.apiBaseUrl}signup`
export const SOCKET_URL = `${enviroinment.socketEndPoint}`

/* Api keys */
export const API_MANAGER_KEY = enviroinment.apiManagerApiKey
export const AUTH_API_KEY = enviroinment.authApiKey
export const CONNECTOR_APIKEY = enviroinment.connectorApiKey
export const PAYMENT_APIKEY = enviroinment.paymentApiKey
export const SIGNUP_APIKEY = enviroinment.signupApiKey

/* Stripe constants */
export const STRIPE_PUBLISHABLE_KEY = enviroinment.stripe_publishable_key

export const EUROPIAN_UNION_COUNTRIES = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
]

/* pagination constants */

export const PAGE_SIZE = [10, 20, 50, 100]

/* Payment constants */

export const TOPUP_AMT = ['10', '50', '200', '500', '1000']

export const FULL_ACCESS = 'FULL_ACCESS'
export const READ_ONLY = 'READ_ONLY'
export const NO_ACCESS = 'NO_ACCESS'