// @flow

import React, { useState, useEffect } from 'react'
import ModalLayout, { Header, Body, Footer } from '../Popup/popup'
import { PttButton } from '../CustomButton/custombutton'
import { Select, Radio, message, Alert } from 'antd'
import { CUSTOM_ACTION_URL, EUROPIAN_UNION_COUNTRIES, TOPUP_AMT } from '../../utils/constants'
import { connect } from 'react-redux'
import useCustomFetch from '../../Hooks/UseCustomFetch'
import { useStripe } from '@stripe/react-stripe-js'
import { convertToDollar } from '../../utils/helpers'

// const messages = {
//   failed: 'Oops! something went wrong please try again later',
//   succeeded: 'Payment done successfully!',
// }

type Props = {
  handlePopupClose: (any) => void,
  popupState: boolean,
  paymentMethods: Object,
  userStripeData: Object,
  getCustomerPaymentSuccList: (any) => mixed,
  topUpAmount: number,
  translations: Object,
  language: string,
  accountSettings: Object,
  setTotalBalance: (any) => mixed,
  totalBalance: number
}

const AddCreditModal = (props: Props) => {
  const {
    handlePopupClose,
    popupState,
    paymentMethods,
    userStripeData,
    getCustomerPaymentSuccList,
    topUpAmount = 0,
    translations,
    language,
    accountSettings,
    setTotalBalance,
    totalBalance
  } = props

  // const defaultPaymentId = paymentMethods.length > 0 ? paymentMethods[0].id : ''
  const [loader, setLoader] = useState(false)
  const [credit, setCredit] = useState('')
  const [paymentMethodId, setPaymentMethodId] = useState('')
  const [error, setError] = useState('')
  const [amountToPay, setAmountToPay] = useState()
  const [isVatApplied, setIsVatApplied] = useState(false)
  const [vatAmount, setVatAmount] = useState(0)

  const stripe = useStripe()

  /* Create payment intent */
  const [createPaymentIntentApi] = useCustomFetch(
    `${CUSTOM_ACTION_URL}/payment`,
    'PAYMENT',
    false,
    JSON.stringify({
      action: 'CREATE_PAYMENT_INTENTS',
      data: {
        amount: amountToPay,
        customerId: userStripeData.stripe_id,
        paymentMethodId: paymentMethodId,
        vatAmount: vatAmount,
        vat_number: (accountSettings?.data?.vatId_verification_status === 'verified' &&
                    accountSettings?.data?.vat_number) ? accountSettings.data.vat_number : '',
        country: accountSettings?.data?.countryCode ? accountSettings.data.countryCode : '',
        email: accountSettings?.data?.billing_email ? accountSettings.data.billing_email : ''
      },
    })
  )

  useEffect(() => {
    let amtToPay = Number((topUpAmount || Number(credit) * 100).toFixed(0)) || 0

    if (amtToPay && accountSettings?.data) {
      const isEuropianUnionCountry = EUROPIAN_UNION_COUNTRIES.some((country) => country === accountSettings.data.countryCode)

      if (!isEuropianUnionCountry ||
        ((accountSettings.data.countryCode === 'ES' && accountSettings.data.spanish_tax_exempt) ||
          (isEuropianUnionCountry && accountSettings.data.countryCode !== 'ES' &&
            accountSettings.data.tax_id && accountSettings.data.vatId_verification_status === 'verified'))) {
        setIsVatApplied(false)
      } else {
        const vatAmt = Number(((amtToPay * 21) / 100).toFixed(0))

        setVatAmount(vatAmt)
        amtToPay = amtToPay + vatAmt
        setIsVatApplied(true)
      }
    }
    setAmountToPay(Number(amtToPay.toFixed(0)))
  }, [topUpAmount, credit])

  useEffect(() => {
    if (paymentMethods && paymentMethods.length > 0) {
      setPaymentMethodId(paymentMethods[0].id)
    }
  }, [paymentMethods])

  useEffect(() => {
    if (
      createPaymentIntentApi.response &&
      createPaymentIntentApi.response.status
    ) {
      handleSuccess()
    } else if (
      createPaymentIntentApi.response &&
      !createPaymentIntentApi.response.status &&
      createPaymentIntentApi.response.error &&
      createPaymentIntentApi.response.error.code === 'authentication_required'
    ) {
      confirmCardPayment(
        createPaymentIntentApi.response.error.payment_intent.client_secret,
        createPaymentIntentApi.response.error.payment_method.id
      )
    } else if (
      createPaymentIntentApi.response &&
      createPaymentIntentApi.response.error
    ) {
      message.error(
        createPaymentIntentApi.response.error.code || translations.oops_something_went_wrong[language]
      )
      setLoader(false)
    }
  }, [createPaymentIntentApi.response])

  const confirmCardPayment = async (clientSecret, paymentMethodId) => {
    const { paymentIntent, error } = await stripe.confirmCardPayment(
      clientSecret,
      {
        payment_method: paymentMethodId,
      }
    )

    if (paymentIntent && paymentIntent.status === 'succeeded') {
      handleSuccess()
    } else {
      message.error((error && error.message) || translations.oops_something_went_wrong[language])
      setLoader(false)
    }
  }

  const handleSuccess = () => {
    message.success(translations.payment_done[language])
    getCustomerPaymentSuccList()
    exitHandler()
  }

  const exitHandler = () => {
    if (paymentMethods && paymentMethods.length > 0) {
      setPaymentMethodId(paymentMethods[0].id)
    }
/*     setAmountToPay()
    setIsVatApplied(false) */
    setCredit('')
    setError('')
    setLoader(false)
    handlePopupClose(false)
  }

  const handleCreditSelect = (event) => {
    if (paymentMethodId) {
      setError('')
    }
    setCredit(event.target.value)
  }

  const handlePaymentMethodChange = (card) => {
    if (topUpAmount || credit) {
      setError('')
    }
    setPaymentMethodId(card)
  }

  const makePayment = () => {
    // const totalAmount = topUpAmount + totalBalance

    if ((!credit && !topUpAmount) || !paymentMethodId) {
      // message.error('Select both Payment method and credit amount!')
      setError('Select both Payment method and credit amount!')

      return
    }
    setError('')
    setLoader(true)
    // setTotalBalance && setTotalBalance(totalAmount)
    createPaymentIntentApi.setRefetch(true)
  }

  const getBody = () => {
    return (
      <div>
        {error && <div className="err-msg add-cred-err">{error}</div>}
        <div className="add-phone-modal-body">
          <div className="field-label">
            {translations.payment_method[language]}
          </div>
          <Select
            className="credit-select"
            placeholder="Payment method"
            onChange={handlePaymentMethodChange}
            // defaultValue={paymentMethods.length > 0 ? paymentMethods[0].id : ''}
            value={paymentMethodId}
          //   disabled={!selectedCountry}
          >
            {(paymentMethods || []).map((card) => (
              <Select.Option key={card.id} value={card.id}>
                {card.card.brand +
                  ' ' +
                  card.card.funding +
                  ' card ending with ' +
                  card.card.last4}
              </Select.Option>
            ))}
          </Select>

          <div className="field-label">{translations.amount[language]}</div>
          {topUpAmount !== 0 && (
            <input
              className="StripeElement amount-to-pay"
              value={`$${convertToDollar(topUpAmount)}`}
              readOnly
            ></input>
          )}
          {!topUpAmount && (
            <Radio.Group
              className="add-credit"
              value={credit}
              onChange={handleCreditSelect}
            >
              {TOPUP_AMT.map((amount) => <Radio.Button key={amount} value={amount}>${amount}</Radio.Button>)}
            </Radio.Group>
          )}
        </div>
      </div>
    )
  }

  const getFooter = () => {
    return (
      <div style={{ display: isVatApplied? 'flex' : '' }}>
        {/* {isVatApplied && <div className='vat-info'>{translations.vat_info[language]}</div>} */}
        {isVatApplied && <Alert
          message={translations.vat_info[language]}
          type="info"
          showIcon
          // style={{width: '75%'}}
        />}
        <PttButton
          onClick={makePayment}
          disabled={loader || (!credit && !topUpAmount)}
          loading={loader}
          type="primary"
          customClass='top-up-btn'
        >
          {translations.pay[language]}
        </PttButton>
      </div>
    )
  }

  return (
    <ModalLayout
      visible={popupState}
      onCancel={exitHandler}
      destroyOnClose={true}
    >
      <Header>{translations.add_credit[language]}</Header>
      <Body>{getBody()}</Body>
      <Footer>{getFooter()}</Footer>
    </ModalLayout>
  )
}

const mapsStateToProps = (state) => {
  return {
    userStripeData: state.sampleReducer.userStripeData || {},
    translations: state.sampleReducer.translations || {},
    language: state.sampleReducer.language,
    accountSettings: state.sampleReducer.accountSettings || {}
  }
}

export default connect(mapsStateToProps, null)(AddCreditModal)
