// @flow

import React, { Fragment, type Node } from 'react'
import { CloseOutlined } from '@ant-design/icons'

import './infoPanel.css'
import MoreActions from '../MoreActions/moreactions'
import { HoverableButton } from '../CustomButton/custombutton'

type Props = {
  actionCallBack?: (any) => mixed,
  children?: Node,
  changeVisiblity: (any) => mixed,
  closeButtonVisiblity?: boolean,
  contents?: $ReadOnlyArray<*>,
  isMoreActionNeeded?: boolean,
  visiblity: boolean,
  width?: string,
  disableClose?: boolean,
}

const InfoPanel = (props: Props) => {
  const {
    actionCallBack,
    changeVisiblity,
    children,
    closeButtonVisiblity = false,
    contents = [],
    isMoreActionNeeded = false,
    visiblity,
    width = '290px',
    disableClose,
  } = props

  const closeButtonStyle = closeButtonVisiblity
    ? 'close-container content-hidden'
    : 'close-container'

  const moreButtonStyle = closeButtonVisiblity
    ? 'more-action-container content-hidden'
    : 'more-action-container'

  const contentClickHandler = (action) => {
    if (actionCallBack) {
      actionCallBack(action)
    }
  }

  const getContent = () => {
    return (
      <div style={{ width: width }} className="info-panel-container">
        <div className="info-panel-border">
          <div className="header-container">
            <div className={closeButtonStyle}>
              <HoverableButton
                onClick={changeVisiblity}
                disabled={disableClose}
              >
                <CloseOutlined />
              </HoverableButton>
            </div>
            <div className="topic-container"></div>
            {isMoreActionNeeded ? (
              <div className={moreButtonStyle}>
                <MoreActions
                  actionCallBack={contentClickHandler}
                  contents={contents}
                />
              </div>
            ) : (
              <Fragment />
            )}
          </div>
          <div className="body-container">{children}</div>
        </div>
      </div>
    )
  }

  return visiblity ? getContent() : <Fragment />
}

export default InfoPanel
