/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ModalLayout, { Header, Body, Footer } from '../../Popup/popup'
import PttButton from '../../CustomButton/PttButton'
import RoutingDetails from './RoutingDetails'
import { message } from 'antd'
import axios from 'axios'
import {
  APIURL,
  API_MANAGER_KEY,
  ID_TOKEN,
  TOKEN_TYPE,
} from '../../../utils/constants'
import { getItem } from '../../../utils/storage'
import {
  setActualRouting,
  setModifiedRouting,
} from '../../../actions/routingAction'

const AddRoutingPopup = ({
  routing,
  visiblity,
  exitHandler,
  onRouteAddSuccess,
}) => {
  const dispatch = useDispatch()
  const { language, translations } = useSelector((state) => {
    return {
      language: state.sampleReducer.language,
      translations: state.sampleReducer.translations,
    }
  })

  const api = axios.create({
    baseURL: APIURL,
    headers: {
      Authorization: `${getItem(TOKEN_TYPE)} ${getItem(ID_TOKEN)}`,
      'Content-Type': 'application/json',
      'x-api-key': API_MANAGER_KEY,
    },
  })

  const [state, setState] = useState({})
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (visiblity) {
      setState({
        name: '',
        desc: '',
        mode: translations.Fail_over[language],
        trunks: [],
        host: [],
        callee: [],
        caller: [],
      })
    }
  }, [visiblity])

  const getFooter = () => {
    return (
      <PttButton
        type="primary"
        disabled={loading}
        loading={loading}
        onClick={addHandler}
      >
        {translations.add[language]}
      </PttButton>
    )
  }

  const onCreateError = () => {
    setLoading(false)
    message.error(translations.create_routing_error[language])
  }

  const onRelateError = () => {
    dispatch(setActualRouting({}))
    dispatch(setModifiedRouting({}))
    onRouteAddSuccess()
    setLoading(false)
    message.error(translations.relate_routing_error[language])
  }

  const getMode = (mode) => {
    if (mode === 'Fail over') {
      return 'FO'
    } else if (mode === 'Load balancer') {
      return 'LB'
    } else {
      return ''
    }
  }

  const createRouting = () => {
    const body = {
      name: state.name,
      data: {
        description: state.desc,
        host_origin: state.host,
        callee: state.callee,
        caller: state.caller,
        mode: getMode(state.mode),
      },
    }

    api
      .put('routing?sync=yes', body)
      .then((res) => {
        const { result, response } = res.data

        if (
          result &&
          response.items.length &&
          response.items[0].action === 'created'
        ) {
          relateRouting(response.items[0].id)
        } else {
          onCreateError()
        }
      })
      .catch(onCreateError)
  }

  const relateRouting = (routingId) => {
    const trunkToRelate = state.trunks.map((item) => {
      return { id: item.id }
    })

    api
      .patch(`routing-gateway/${routingId}?sync=yes`, {
        items: trunkToRelate,
      })
      .then((res) => {
        const { result, response } = res.data

        if (
          result &&
          response.items.length &&
          response.items[0].action === 'updated'
        ) {
          dispatch(setActualRouting({}))
          dispatch(setModifiedRouting({}))
          onRouteAddSuccess()
          setLoading(false)
          message.success(translations.create_routing_success[language])
          exitHandler()
        } else {
          onRelateError()
        }
      })
      .catch(onRelateError)
  }

  const addHandler = () => {
    if (state.name.length === 0) {
      message.warn(translations.enter_routing_name[language])
    } else if (state.mode.length === 0) {
      message.warn(translations.select_mode[language])
    } else if (state.trunks.length === 0) {
      message.warn(translations.select_trunk[language])
    } else if (routing.some((item) => item.name === state.name)) {
      message.warn(translations.routing_name_exists[language])
    } else {
      setLoading(true)
      createRouting()
    }
  }

  const closeHandler = () => {
    exitHandler()
  }

  const renderRoutingDetails = () => {
    return <RoutingDetails state={state} setState={setState} />
  }

  return (
    <ModalLayout
      visible={visiblity}
      onCancel={closeHandler}
      closable={!loading}
      destroyOnClose={true}
    >
      <Header>{translations.add_routing[language]}</Header>
      <Body>
        <div style={{ height: 350, width: 500 }}>{renderRoutingDetails()}</div>
      </Body>
      <Footer>{getFooter()}</Footer>
    </ModalLayout>
  )
}

export default AddRoutingPopup
