import React from 'react'
import { useSelector } from 'react-redux'
import PttLayout from '../components/Home/PttHome/PttLayout'
import MsTeams from '../components/MsTeams/msteams'
import { getTranslatedText } from '../utils/translations'

function MicrosoftTeamsPage() {
  const { language, translations } = useSelector(({ sampleReducer }) => ({
    language: sampleReducer.language || 'en',
    translations: sampleReducer.translations || {},
  }))

  return (
    <PttLayout
      currentkey="1"
      title={getTranslatedText(translations, language, 'microsoft_teams')}
    >
      <MsTeams />
    </PttLayout>
  )
}

export default MicrosoftTeamsPage
