/* eslint-disable no-negated-condition */
// @flow

import React, { Fragment, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import useConstructor from './Hooks/UseConstructor'
import useFetchSettings from './Hooks/UseFetchSettings'
import useSocketSettings from './Hooks/UseSocketSettings'
import useCheckDetails from './Hooks/UseCheckDetails'
import BillingPage from './pages/BillingPage'
import CallFlowPage from './pages/CallFlowPage'
import MicrosoftTeamsPage from './pages/MicrosoftTeamsPage'
import NotFoundPage from './pages/NotFoundPage'
import PhoneNumbersPage from './pages/PhoneNumbersPage'
import StatsPage from './pages/StatsPage'
import SettingsPage from './pages/SettingsPage'
import Spinner from './Spinner/spinner'
import { setTimeoutForRefreshToken } from './utils/LocalStorage'
import useGetAccountId from './Hooks/UseGetAccountId'
import ClientsPage from './pages/ClientsPage'
import AdvancePage from './pages/AdvancePage'
import { setAppFromClient } from './actions/SampleAction'
import { NO_ACCESS } from './utils/constants'

function AuthComponents() {
  const dispatch = useDispatch()

  const adminRoutes = [
    { path: '/clients', component: ClientsPage },
    { path: '/settings', component: SettingsPage },
  ]

  const {
    isResellerAccount,
    initailSettingsLoading,
    language,
    translations,
    microsoftTeamsPermission,
    phoneNumberPermission,
    statsPermission,
    advancedPermission,
    billingPermission,
  } = useSelector(({ sampleReducer, settingReducer, permissionReducer }) => ({
    initailSettingsLoading: settingReducer.initailSettingsLoading,
    language: sampleReducer.language || 'en',
    translations: sampleReducer.translations || {},
    isResellerAccount: sampleReducer.isResellerAccount,
    microsoftTeamsPermission: permissionReducer.microsoftTeamsPermission,
    phoneNumberPermission: permissionReducer.phoneNumberPermission,
    statsPermission: permissionReducer.statsPermission,
    advancedPermission: permissionReducer.advancedPermission,
    billingPermission: permissionReducer.billingPermission,
  }))

  const getAlertMessages = () => ({
    paymentsMessage: translations.payment_list_api_failed[language],
    settingsMessage: translations.account_setting_api_failed[language],
  })

  useConstructor(setTimeoutForRefreshToken)
  useConstructor(useSocketSettings())
  useFetchSettings(getAlertMessages())
  useCheckDetails()
  useGetAccountId()

  useEffect(() => {
    const item = localStorage.getItem('IsClientPage')
    const status = item === 'yes'

    dispatch(setAppFromClient(status))
  }, [])

  const loader = () => (
    <div className="spin-style">
      <Spinner spinning />
    </div>
  )

  const renderRoutes = (routes) => {
    return routes.map((item) => {
      return (
        <Route
          exact
          key={item.path}
          path={item.path}
          component={item.component}
        />
      )
    })
  }

  const getRoutes = (routes, initPath) => {
    return (
      <Fragment>
        <Switch>
          {renderRoutes(routes)}
          <Route exact path={'/'} render={() => <Redirect to={initPath} />} />
          <Route component={NotFoundPage} />
        </Switch>
      </Fragment>
    )
  }

  const renderView = useMemo(() => {
    if (initailSettingsLoading) {
      return loader()
    } else if (isResellerAccount === undefined) {
      return loader()
    } else if (isResellerAccount) {
      return getRoutes(adminRoutes, '/clients')
    } else {
      const getUserDefaultRoute = () => {
        if (microsoftTeamsPermission !== NO_ACCESS) {
          return '/msteams'
        } else if (phoneNumberPermission !== NO_ACCESS) {
          return '/phonenumbers'
        } else if (statsPermission !== NO_ACCESS) {
          return '/stats'
        } else if (advancedPermission !== NO_ACCESS) {
          return '/advanced'
        } else if (billingPermission !== NO_ACCESS) {
          return '/billing'
        } else {
          return '/settings'
        }
      }

      const getUserRoutes = () => {
        const data = []

        data.push({ path: '/settings', component: SettingsPage })
        data.push({ path: '/callflow', component: CallFlowPage })

        if (microsoftTeamsPermission !== NO_ACCESS) {
          data.push({ path: '/msteams', component: MicrosoftTeamsPage })
        }
        if (phoneNumberPermission !== NO_ACCESS) {
          data.push({ path: '/phonenumbers', component: PhoneNumbersPage })
        }
        if (statsPermission !== NO_ACCESS) {
          data.push({ path: '/stats', component: StatsPage })
        }
        if (advancedPermission !== NO_ACCESS) {
          data.push({ path: '/advanced', component: AdvancePage })
        }
        if (billingPermission !== NO_ACCESS) {
          data.push({ path: '/billing', component: BillingPage })
        }

        return data
      }

      return getRoutes(getUserRoutes(), getUserDefaultRoute())
    }
  }, [initailSettingsLoading, isResellerAccount])

  return <Fragment>{renderView}</Fragment>
}

export default AuthComponents
