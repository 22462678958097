export const setFetchSettings = (fetchSettings) => ({
  type: 'setFetchSettings',
  fetchSettings,
})

export const setHasDetails = (hasDetails) => ({
  type: 'setHasDetails',
  hasDetails,
})

export const setFetchDetails = (fetchDetails) => ({
  type: 'setFetchDetails',
  fetchDetails,
})

export const setInitalSettingsLoading = (initailSettingsLoading) => ({
  type: 'setInitalSettingsLoading',
  initailSettingsLoading,
})

export const setTriggerSettingsSave = (triggerSettingsSave) => ({
  type: 'setTriggerSettingsSave',
  triggerSettingsSave,
})

export const setSettingsSavePostUrl = (settingsSavePostUrl) => ({
  type: 'setSettingsSavePostUrl',
  settingsSavePostUrl,
})

export const setSettingsSaveRedirect = (settingsSaveRedirect) => ({
  type: 'setSettingsSaveRedirect',
  settingsSaveRedirect,
})

export const setPersonalSettingsChanged = (personalSettingsChanged) => ({
  type: 'setPersonalSettingsChanged',
  personalSettingsChanged,
})

export const setCompanySettingsChanged = (companySettingsChanged) => ({
  type: 'setCompanySettingsChanged',
  companySettingsChanged,
})

export const setUpdatedState = (updatedState) => ({
  type: 'setUpdatedState',
  updatedState,
})
