import React from 'react'
import { useSelector } from 'react-redux'
import PttLayout from '../components/Home/PttHome/PttLayout'
import Stats from '../components/Stats'
import { getTranslatedText } from '../utils/translations'

function StatsPage() {
  const { language, translations } = useSelector(({ sampleReducer }) => ({
    language: sampleReducer.language || 'en',
    translations: sampleReducer.translations || {},
  }))

  return (
    <PttLayout
      currentkey="4"
      title={getTranslatedText(translations, language, 'stats')}
    >
      <Stats pageTitle="Stats" />
    </PttLayout>
  )
}

export default StatsPage
