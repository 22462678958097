export const setCallFlow = (data) => ({
  type: 'setCallFlow',
  data,
})

export const setActualCallFlowNodes = (data) => ({
  type: 'setActualCallFlowNodes',
  data,
})

export const setActualRouteName = (data) => ({
  type: 'setActualRouteName',
  data,
})

export const setRouteName = (data) => ({
  type: 'setRouteName',
  data,
})

export const setCallFlowNodes = (data) => ({
  type: 'setCallFlowNodes',
  data,
})

export const setNodeList = (data) => ({
  type: 'setNodeList',
  data,
})

export const setUndoArray = (data) => ({
  type: 'setUndoArray',
  data,
})

export const setRedoArray = (data) => ({
  type: 'setRedoArray',
  data,
})

export const setUnsaveAlertTrigger = (details) => ({
  type: 'Unsave-Alert-Trigger',
  details,
})

/* export const setHomeKey = (key) => ({
  type: 'Home-Key',
  key,
})
 */
export const setConnectorStatus = (status) => ({
  type: 'Connector-Status',
  status,
})

export const setTtsLanguages = (ttsLanguages) => ({
  type: 'setTtsLanguages',
  ttsLanguages,
})

export const setTtsSpeakers = (ttsSpeakers) => ({
  type: 'setTtsSpeakers',
  ttsSpeakers,
})

export const setAutoUpdateCallFlow = (autoUpdateCallFlow) => ({
  type: 'setAutoUpdateCallFlow',
  autoUpdateCallFlow,
})
