import React from 'react'
import { Table } from 'antd'
import ReactFlagsSelect from 'react-flags-select'
import { useSelector } from 'react-redux'
import PttButton from '../../CustomButton/PttButton'
import ModalLayout, { Body, Footer, Header } from '../../Popup/popup'
import { convertToDollar } from '../../../utils/helpers'
import { getCountries, getPlans } from '../../MsTeams/msteamsutils'

const AddCallingPlanMsTeams = ({
  callingPlansData,
  exitHandler,
  record,
  successCallBack,
  visiblity,
}) => {
  const flagRef = React.useRef(null)
  const { language, translations } = useSelector(({ sampleReducer }) => ({
    language: sampleReducer.language || 'en',
    translations: sampleReducer.translations || {},
  }))

  const [country, setCountry] = React.useState('')
  const [countries, setCountries] = React.useState([])
  const [plans, setPlans] = React.useState([])
  const [rows, setRows] = React.useState([])
  const [rowKeys, setRowKeys] = React.useState([])
  const [disableData, setDisableData] = React.useState([])

  React.useEffect(() => {
    if (callingPlansData.length && callingPlansData[0].id) {
      const planItems = JSON.parse(JSON.stringify(callingPlansData))
      const newCountries = getCountries(planItems)
      const newPlans = getPlans(planItems, newCountries)

      setCountries(newCountries)
      setPlans(newPlans)
    }
  }, [callingPlansData])

  React.useEffect(() => {
    if (visiblity) {
      if (Object.keys(record).length) {
        setCountry(record.callingPlan.country)
        setDisableData(record.callingPlan.data.map((cpData) => cpData.id))
        /* setRows(record.callingPlan.data)
        setRowKeys(record.callingPlan.data.map((cpData) => cpData.id)) */
      }
    }
  }, [visiblity, record])

  const onCountryChange = (countryCode) => {
    setCountry(countryCode)
    setRows([])
    setRowKeys([])
  }

  const rowSelectHandler = (keys, rows) => {
    const rowData = []

    rows.map((data) => rowData.push(data.data))
    setRowKeys(keys)
    setRows(rowData)
  }

  const plansTable = React.useMemo(() => {
    let data = []
    const availableCountryPlan = plans && plans[country] ? plans[country] : []

    if (availableCountryPlan.length) {
      data = availableCountryPlan.map((item) => {
        if (item.data && item.id) {
          return {
            key: item.id,
            name: item.data.label[language] || '',
            minutes: item.data.minutes,
            price: item.data.price.usd.monthly_amount,
            data: item.data,
          }
        }
      })
    }

    const columns = [
      {
        title: translations.name[language],
        dataIndex: 'name',
        width: '40%',
      },
      {
        title: translations.minutes[language],
        dataIndex: 'minutes',
        width: '30%',
      },
      {
        title: translations.price[language],
        dataIndex: 'price',
        width: '40%',
        render: (text, record) => {
          return <div>${convertToDollar(record.price)} / Month</div>
        },
      },
    ]

    const rowSelection = {
      onChange: (keys, rows) => rowSelectHandler(keys, rows),
      getCheckboxProps: (record) => ({
        disabled: disableData.includes(record.key),
      }),
    }

    return (
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        scroll={{ y: 'calc(100vh - 190px)' }}
        className="calling-plan-table"
        rowSelection={{
          selectedRowKeys: rowKeys,
          type: 'checkbox',
          ...rowSelection,
        }}
      />
    )
  }, [plans, rowKeys, country, rows])

  const renderCountryDropDown = () => {
    return (
      <div style={{ width: 250 }}>
        <ReactFlagsSelect
            selectedSize={12}
            optionsSize={12}
            selected={country}
            onSelect={onCountryChange}
            countries={countries}
            placeholder={translations.select_a_country[language]}
          />
      </div>
    )
  }

  const renderBody = () => {
    return (
      <React.Fragment>
        <div className="ConfigUsers__CallingPlanDropDownBody">
          <span className="ConfigUser_Text">{translations.the_user_will_make_calls_to[language]}</span>
          {renderCountryDropDown()}
        </div>
        {plansTable}
      </React.Fragment>
    )
  }

  const onConfirmClick = () => {
    const selectedPlans = plans[country].filter((plan) => {
      if (rowKeys.includes(plan.id)) {
        return plan
      }
    })

    setRows([])
    setRowKeys([])
    setDisableData([])
    successCallBack(record, { country: country, data: selectedPlans })
  }

  const renderFooter = () => {
    return (
      <PttButton
        disabled={rows.length === 0}
        onClick={onConfirmClick}
        type="primary"
      >
        {translations.confirm[language]}
      </PttButton>
    )
  }

  const onExit = () => {
    setCountry('')
    setDisableData([])
    setRows([])
    setRowKeys([])
    exitHandler()
  }

  return (
    <ModalLayout
      className="calling-plan-modal"
      onCancel={onExit}
      visible={visiblity}
    >
      <Header>{translations.add_calling_plan[language]}</Header>
      <Body>{renderBody()}</Body>
      <Footer>{renderFooter()}</Footer>
    </ModalLayout>
  )
}

export default AddCallingPlanMsTeams
