// @flow

import React, { memo, useEffect, useMemo, useState } from 'react'
import { message, Select } from 'antd'
import uuid from 'react-uuid'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import createEngine, { DiagramModel } from '@projectstorm/react-diagrams'
import { CanvasWidget } from '@projectstorm/react-canvas-core'
import CustomNodeFactory from './CustomNode/CustomNodeFactory'
import CustomNodeModal from './CustomNode/CustomNodeModal'
import InbuiltNodeFactory from './CustomNode/InbuiltNodeFactory'
import InbuiltNodeModel from './CustomNode/InbuiltNodeModel'
import useConstructor from '../../Hooks/UseConstructor'
import DiagramCanvas from './DiagramCanvas/DiagramCanvas'
import { DEFAULT_NODE, NODE_TYPES } from '../NodeFieldTypes/nodetypes'
import { getNode, getActiveModelNodes } from './diagramutils'
import ConfirmPopup from '../Popup/confirmpopup'
import { getNewNodeValues } from '../../utils/helpers'
import './Diagram.css'
import AddLabel from '../IncomingCalls/SubComponents/CallFlow/AddLabel'
import * as actions from '../../actions/IncomingCallsAction'
import Spinner from '../../Spinner/spinner'
import AddDialSay from '../IncomingCalls/SubComponents/CallFlow/AddDialSay'
import PttButton from '../CustomButton/PttButton'
import ModalLayout, { Body, Footer, Header } from '../Popup/popup'

type Props = {
  callflowPhones: $ReadOnlyArray<*>,
  diagramData: $ReadOnlyArray<*>,
  nodeList: $ReadOnlyArray<*>,
  onLinkDeleted: (details: Object) => mixed,
  onNodeDeleted: (node: Object) => mixed,
  onNodePositionChanged: (callFlowNodeId: number, positions: Object) => mixed,
  onNodeSelected: (any) => mixed,
  onNewNodeAdded: (details: Object) => mixed,
  onNodeLinkAdded: (mode: string, name: string, params: Object) => mixed,
  onTriggerLinkAdded: (mode: string, params: Object) => mixed,
  onMapNumberLinkAdded: (mode: string, name: string, params: Object) => mixed,
  onTriggerLinkDeleted: (any) => mixed,
  onDialSayLinkAdded: (
    mode: string,
    identifier: number,
    dial: string,
    say: string,
    params: Object
  ) => mixed,
  translations: Object,
  language: string,
  unusedPhones: $ReadOnlyArray<*>,
  updateCallFlowRelations: (any) => mixed,
}

var connectData = {}
var dialSayData = {}
var engine, model
var drag = false
var linkEvent = null
var offset = { x: 10, y: 10 }
var positionEvent = {}
var positionListenerExist = false
var zoom = 90

const Diagram = (props: Props) => {
  const [deleteAlert, setDeleteAlert] = useState(false)
  const [labelAlert, setLabelAlert] = useState(false)
  const [dialSayAlert, setDialSayAlert] = useState(false)
  const [deleteDetalis, setDeleteDetails] = useState({})
  const [selectedPhones, setSelectedPhones] = useState([])
  const [dropdownData, setDropdownData] = useState([])
  const [addPopup, setAddPopup] = useState(false)
  const [updateLoading, setUpdateLoading] = useState(false)

  const initializeApp = () => {
    engine = createEngine()
    engine.getNodeFactories().registerFactory(new CustomNodeFactory())
    engine.getNodeFactories().registerFactory(new InbuiltNodeFactory())
    engine.maxNumberPointsPerLink = 0
    engine
      .getStateMachine()
      .getCurrentState().dragNewLink.config.allowLooseLinks = false
  }

  useConstructor(initializeApp)

  const disableRightClick = (event: *) => {
    event.stopPropagation()
    event.preventDefault()
  }

  useEffect(() => {
    return function cleanup() {
      offset = { x: 10, y: 10 }
      zoom = 90
      engine.setModel(null)
    }
  }, [])

  useEffect(() => {
    const element = document.getElementById('diagram_drop_div')

    element && element.addEventListener('contextmenu', disableRightClick)

    return () => {
      element && element.removeEventListener('contextmenu', disableRightClick)
    }
  }, [])

  useEffect(() => {
    if (addPopup) {
      setSelectedPhones(props.callflowPhones)
      setDropdownData(props.callflowPhones.concat(props.unusedPhones))
    }
  }, [addPopup, props.callflowPhones, props.unusedPhones])

  useEffect(() => {
    setUpdateLoading(false)
    setAddPopup(false)
  }, [props.callflowPhones])

  const getPortByName = (ports, name) => {
    const port = ports.filter((data) => data.options.name === name)

    return port.length ? port[0] : null
  }

  const addLinks = (ports, isAddLabel = true) => {
    ports.forEach(({ from, name, to, label, displayName, type }) => {
      if (from.length && to.length) {
        const source = getNode(model, from)
        const target = getNode(model, to)

        if (source && target) {
          const inport = target.getInPorts()[0]
          const outports = source.getOutPorts()
          const outport = getPortByName(outports, name)

          if (inport && outport) {
            const nodeLink = inport.link(outport)
            const text =
              type === NODE_TYPES.mapNumberToNode ||
              type === NODE_TYPES.mapNumberOrStringToNode
                ? label
                : `${displayName} ${label}`

            isAddLabel && text.length && nodeLink.addLabel(text)
            model.addLink(nodeLink)
          }
        }
      }
    })
  }

  const onDeleteCancel = () => {
    setDeleteAlert(false)
    setDeleteDetails({})
  }

  const onDeleteOkay = () => {
    props.onNodeDeleted(deleteDetalis)
    setDeleteAlert(false)
    setDeleteDetails({})
  }

  const handleNodeDelete = (details) => {
    setDeleteAlert(true)
    setDeleteDetails(details)
  }

  const nodeClick = (callFlowNodeId) => {
    !drag && props.onNodeSelected(callFlowNodeId)
  }

  const onDefaultNodeClick = () => {
    !drag && setAddPopup(true)
  }

  const addDefaultNode = (defaultNode) => {
    defaultNode.forEach((data) => {
      const node = new InbuiltNodeModel(
        data,
        props.callflowPhones.length
          ? props.translations.click_change_view_phone_numbers[props.language]
          : props.translations.select_phone_number_here[props.language],
        onDefaultNodeClick
      )
      const addedNode = model.addNode(node)

      node.setPosition(data.pos.x, data.pos.y)
      addedNode.addOutPort(`${data.callFlowNodeId}-out-connect`)
      addedNode.registerListener({
        positionChanged: (event) => nodePositionHandler(event),
      })
    })
  }

  const addDefaultLink = (defaultNode) => {
    defaultNode.forEach(({ ports }) => {
      ports.length && addLinks(ports, false)
    })
  }

  const addNodes = (activeNodes) => {
    activeNodes.forEach((data) => {
      const node = new CustomNodeModal(data, nodeClick, handleNodeDelete)

      node.setPosition(data.pos.x, data.pos.y)
      model.addNode(node).registerListener({
        positionChanged: (event) => nodePositionHandler(event),
      })
    })
  }

  const addPortsToNodes = (activeNodes) => {
    activeNodes.forEach((action) => {
      const node = getNode(model, action.callFlowNodeId)

      node.addInPort(`${action.callFlowNodeId}-in-connect`)
      action.ports.forEach((port) => {
        node.addOutPort(port.name)
      })
      if (
        action.mode === NODE_TYPES.mapNumberToNode ||
        action.mode === NODE_TYPES.mapNumberOrStringToNode
      ) {
        action.ports.length < 10 &&
          node.addOutPort(`${action.callFlowNodeId}-out-connect`)
      }
    })
  }

  const addLinksToNodes = (activeNodes) => {
    activeNodes.forEach(({ ports }) => {
      ports.length && addLinks(ports)
    })
  }

  const linkHandler = () => {
    if (linkEvent) {
      if (linkEvent.link.sourcePort && linkEvent.link.targetPort) {
        if (linkEvent.link.sourcePort.options.name.includes('in')) {
          createModel()
          message.warning(
            props.translations
              .try_linking_from_Outports_to_In_ports_only_warning[
              props.language
            ]
          )
          linkEvent = null
        } else {
          linkCallBack(linkEvent.link)
        }
      }
    }

    document.removeEventListener('mouseup', linkHandler)
    highlightInPorts(false)
  }

  const highlightInPorts = (state) => {
    getActiveModelNodes(model).forEach(({ portsIn }) => {
      const port = document.getElementById(portsIn[0].options.name)
      const conatainer = document.getElementsByClassName('Inport_Style')

      if (state) {
        port && port.classList.add('Diagram__Node-Highlight')
        for (const item of conatainer) {
          item.style.visibility = 'visible'
        }
      } else {
        port && port.classList.remove('Diagram__Node-Highlight')
        for (const item of conatainer) {
          item.style.visibility = 'hidden'
        }
      }
    })
  }

  const addLinkListerners = () => {
    model.registerListener({
      linksUpdated: (event) => {
        if (event.isCreated) {
          document.addEventListener('mouseup', linkHandler)
          linkEvent = event
          if (!linkEvent.link.sourcePort.options.name.includes('in')) {
            highlightInPorts(true)
          }
        } else if (
          !event.isCreated &&
          (!event.link.sourcePort || !event.link.targetPort)
        ) {
          message.warning(
            props.translations
              .try_linking_from_Outports_to_In_ports_only_warning[
              props.language
            ]
          )
        } else {
          if (
            event.link.targetPort.parent.options.callFlowNodeId ===
            DEFAULT_NODE.call_flow_node_id
          ) {
            return props.onTriggerLinkDeleted()
          }

          const port = getPortDataByName(
            getDiagramPort(event.link.targetPort.parent.options.callFlowNodeId),
            event.link.targetPort.options.name
          )

          if (Object.keys(port).length) {
            props.onLinkDeleted(port)
          }
        }
      },
    })
  }

  const addViewListerners = () => {
    model.registerListener({
      offsetUpdated: (event) => {
        offset = {
          x: event.offsetX,
          y: event.offsetY,
        }
      },
      zoomUpdated: (event) => {
        zoom = event.zoom
      },
    })
  }

  const getActiveNodes = () =>
    props.diagramData.filter(({ mode }) => mode !== NODE_TYPES.default)

  const getDefaultNode = () =>
    props.diagramData.filter(({ mode }) => mode === NODE_TYPES.default)

  const createModel = () => {
    model = new DiagramModel()

    const activeNodes = getActiveNodes()
    const defaultNode = getDefaultNode()

    addDefaultNode(defaultNode)
    addNodes(activeNodes)
    addPortsToNodes(activeNodes)
    addDefaultLink(defaultNode)
    addLinksToNodes(activeNodes)
    addLinkListerners()
    addViewListerners()

    model.setZoomLevel(zoom)
    model.setOffset(offset.x, offset.y)

    engine.setModel(model)
    engine.repaintCanvas()
  }

  const getEngine = useMemo(() => {
    createModel()

    return engine
  }, [props.diagramData])

  const getDiagramPort = (id = '') => {
    const ports = []

    getActiveNodes().forEach((data) => {
      if (!ports.length && data.ports && data.callFlowNodeId === id) {
        ports.push(data.ports)
      }
    })

    return ports.length ? ports[0] : []
  }

  const getPortDataByName = (ports = [], name = '') => {
    let port = {}

    ports.length &&
      ports.forEach((data) => {
        if (!port.name && data.name === name) {
          port = data
        }
      })

    return port
  }

  const getType = (name) => {
    if (name.includes(NODE_TYPES.mapNumberToNode)) {
      return NODE_TYPES.mapNumberToNode
    } else if (name.includes(NODE_TYPES.mapNumberOrStringToNode)) {
      return NODE_TYPES.mapNumberOrStringToNode
    } else if (name.includes(NODE_TYPES.node)) {
      return NODE_TYPES.node
    } else if (name.includes(DEFAULT_NODE.call_flow_node_id)) {
      return 'default'
    } else if (name.includes('out-connect')) {
      return 'connect'
    } else {
      return ''
    }
  }

  const linkCallBack = ({ sourcePort, targetPort }) => {
    const sourceName = sourcePort.options.name
    const sourceId = sourcePort.parent.options.callFlowNodeId
    const targetId = targetPort.parent.options.callFlowNodeId
    const sourceMode = sourcePort.parent.options.mode
    const sourceData = getPortDataByName(getDiagramPort(sourceId), sourceName)
    const type = getType(sourceName)
    const params = {
      source: sourceId,
      target: targetId,
      values: sourcePort.parent.options.values,
    }

    if (type === NODE_TYPES.mapNumberToNode) {
      props.onMapNumberLinkAdded(
        NODE_TYPES.mapNumberToNode,
        sourceData.label,
        params
      )
    } else if (type === NODE_TYPES.node) {
      props.onNodeLinkAdded(NODE_TYPES.node, sourceData.labelName, params)
    } else if (type === 'connect') {
      if (sourceMode === NODE_TYPES.mapNumberToNode) {
        connectData = {
          mode: NODE_TYPES.mapNumberToNode,
          params: params,
        }
        setLabelAlert(true)
      } else if (sourceMode === NODE_TYPES.mapNumberOrStringToNode) {
        dialSayData = {
          mode: NODE_TYPES.mapNumberOrStringToNode,
          params: params,
        }
        setDialSayAlert(true)
      }
    } else if (type === 'default') {
      props.onTriggerLinkAdded('TRIGGER', params)
    } else if (type === NODE_TYPES.mapNumberOrStringToNode) {
      props.onDialSayLinkAdded(
        NODE_TYPES.mapNumberOrStringToNode,
        sourceData.identifier,
        sourceData.dial,
        sourceData.say,
        params
      )
    }

    linkEvent = null
  }

  const nodePositionHandler = (event) => {
    drag = true
    positionEvent = event
    if (!positionListenerExist) {
      positionListenerExist = true
      document.addEventListener('mouseup', postionHandler)
    }
  }

  const postionHandler = () => {
    props.onNodePositionChanged(positionEvent.entity.options.callFlowNodeId, {
      x: String(positionEvent.entity.position.x),
      y: String(positionEvent.entity.position.y),
    })
    drag = false
    positionEvent = {}
    positionListenerExist = false
    document.removeEventListener('mouseup', postionHandler)
  }

  const getPosition = (event) => ({
    x: event.clientX - event.target.children[1].getBoundingClientRect().x,
    y: event.clientY - event.target.children[1].getBoundingClientRect().y,
  })

  const getNodeName = (nodeName) => {
    const data = getActiveNodes().filter((data) => data.name.includes(nodeName))

    return data.length ? `${nodeName} ${data.length}` : nodeName
  }

  const onDrop = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const id = uuid()
    const nodeId = event.dataTransfer.getData('text')
    const nodeDefinition = props.nodeList.filter(({ id }) => id === nodeId)

    props.onNewNodeAdded({
      call_flow_node_id: id,
      node_id: nodeId,
      icon_position: getPosition(event),
      name: getNodeName(nodeDefinition[0].data.label[props.language]),
      values: getNewNodeValues(nodeDefinition),
    })

    setTimeout(() => {
      props.onNodeSelected(id)
    }, 100)
  }

  const ondragover = (event) => {
    event.preventDefault()
  }

  const getDisabledItems = (data = {}, entity = '') => {
    const object = data.params.values.find((value) => value.type === data.mode)

    return object?.key_value_pair
      ? object.key_value_pair.map((pair) => pair[entity])
      : []
  }

  const onSelect = (key) => {
    props.onMapNumberLinkAdded(connectData.mode, key, connectData.params)
    connectData = {}
    setLabelAlert(false)
  }

  const onAddLableClose = () => {
    connectData = {}
    setLabelAlert(false)
    createModel()
  }

  const labelPopup = useMemo(() => {
    if (labelAlert) {
      return (
        <AddLabel
          disabledKeys={getDisabledItems(connectData, 'key')}
          onCancel={onAddLableClose}
          onSelect={onSelect}
          visible={labelAlert}
        />
      )
    } else {
      return <React.Fragment />
    }
  }, [labelAlert])

  const onDialSayOkay = (dial, say) => {
    props.onDialSayLinkAdded(
      dialSayData.mode,
      -1,
      dial,
      say,
      dialSayData.params
    )
    dialSayData = {}
    setDialSayAlert(false)
  }

  const onDialSayClose = () => {
    dialSayData = {}
    setDialSayAlert(false)
    createModel()
  }

  const dialSayPopup = useMemo(() => {
    if (dialSayAlert) {
      return (
        <AddDialSay
          disabledKeys={getDisabledItems(dialSayData, 'dial')}
          disabledWords={getDisabledItems(dialSayData, 'say')}
          onCancel={onDialSayClose}
          onOkay={onDialSayOkay}
          visible={dialSayAlert}
        />
      )
    }
  }, [dialSayAlert])

  const diagramCanvas = useMemo(() => {
    if (props.diagramData.length) {
      return (
        <DiagramCanvas>
          <CanvasWidget engine={getEngine} />
        </DiagramCanvas>
      )
    }

    return (
      <div className="align-center full-height">
        <Spinner spinning={true} />
      </div>
    )
  })

  const onAddPopupCancel = () => {
    setSelectedPhones([])
    setAddPopup(false)
  }

  const addPopupOkay = () => {
    setUpdateLoading(true)
    props.updateCallFlowRelations(selectedPhones.map((phone) => phone.id))
  }

  const onPhoneChange = (idArray) => {
    setSelectedPhones(
      idArray.map((id) => {
        return dropdownData.find((phone) => phone.id === id)
      })
    )
  }

  const renderAddPhoneNumberBody = () => {
    const selectedKeys = selectedPhones.map((phone) => phone.id)
    const options = dropdownData.filter(
      (item) => !selectedKeys.includes(item.id)
    )

    return (
      <div style={{ width: 300 }}>
        <Select
          mode="multiple"
          value={selectedKeys}
          onChange={onPhoneChange}
          placeholder={
            props.translations.select_phone_number_error[props.language]
          }
          style={{ width: '100%' }}
        >
          {options.map((item) => (
            <Select.Option key={item.id} value={item.id}>
              {item.id}
            </Select.Option>
          ))}
        </Select>
      </div>
    )
  }

  return (
    <React.Fragment>
      <ModalLayout
        closable={!updateLoading}
        onCancel={onAddPopupCancel}
        visible={addPopup}
      >
        <Header>{props.translations.add_phone_number[props.language]}</Header>
        <Body>{renderAddPhoneNumberBody()}</Body>
        <Footer>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <PttButton
              disabled={updateLoading}
              onClick={onAddPopupCancel}
              type="secondary"
            >
              {props.translations.cancel[props.language]}
            </PttButton>
            <PttButton
              disabled={updateLoading}
              loading={updateLoading}
              onClick={addPopupOkay}
              type="primary"
            >
              {props.translations.save[props.language]}
            </PttButton>
          </div>
        </Footer>
      </ModalLayout>
      <ConfirmPopup
        onCancel={onDeleteCancel}
        onOkay={onDeleteOkay}
        visiblity={deleteAlert}
      >
        <span>{props.translations.delete_node_info[props.language]}</span>
      </ConfirmPopup>
      {labelPopup}
      {dialSayPopup}
      <div
        id="diagram_drop_div"
        className="Diagram__DropContainer"
        onDragOver={ondragover}
        onDrop={onDrop}
        onClick={() => props.onNodeSelected('')}
      >
        {diagramCanvas}
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = ({
  callflowReducer,
  incomingCallsReducer,
  sampleReducer,
}) => ({
  nodeList: incomingCallsReducer.nodeList || [],
  language: sampleReducer.language,
  translations: sampleReducer.translations,
  callflowPhones: callflowReducer.callflowPhones,
  unusedPhones: callflowReducer.unusedPhones,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...actions }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memo<Props>(Diagram))
