// @flow

import React, { memo } from 'react'
import { Button } from 'antd'

type Props = {
  customClass?: string,
  type?: string,
  onClick?: (any) => mixed,
  disabled?: boolean,
  children?: Node,
  loading?: boolean,
}

const CircularIconButton = (props: Props) => {
  const {
    customClass = '',
    type = 'primary',
    onClick = '',
    disabled = false,
    loading = false,
  } = props

  return (
    <Button
      className={customClass}
      type={type}
      shape="circle"
      icon={props.children}
      disabled={disabled}
      onClick={onClick}
      loading={loading}
    />
  )
}

export default memo<Props>(CircularIconButton)
