const prod = {
  apiBaseUrl: 'https://api.plug2teams.com/',
  apiManagerApiKey: 'rrVqVI6BlxIhiU3EyBFm8hyD33ig87s5JNKbHF63',
  authApiKey: 'LOF9pPhtMfBsTEYMjwOB3ERqWhzLdB6ao5dxHaGb',
  connectorApiKey: 'tjpVq6yDjq8ah2Xue0HhB1b6UBnaU9CPlW8riCi5',
  imageUrl: 'https://cf-ptt-publicassets-prod.s3-eu-west-1.amazonaws.com/',
  name: 'prod',
  paymentApiKey: 'GttXzpaKmo3YpUWjI0d6dvHHfccFYNJ3pa5c9VU4',
  signupApiKey: 'VVolqXde1qaCEp31ARfQL6f1A5JUb3wBa7IGe5by',
  socketEndPoint: 'wss://5ms0sagzy9.execute-api.eu-west-1.amazonaws.com/v1',
  stripe_publishable_key:
    'pk_live_51H84xEAjNN0R99fNGbg0YUMmGjaVA6AGqF5mOd5SLlSUQuhev0w30WWBe6pyEbZlNRKNir2OZYW1mpou5GzsrmAg00LDjblCQQ',
}

export default prod
