import axios from 'axios'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setMicrosoftUsersCollection } from '../actions/MsTeamsAction'
import { tooManyRequest } from '../components/MsTeams/msteamsutils'
import { API_MANAGER_KEY } from '../utils/constants'
import { ID_TOKEN } from '../utils/constants'
import { SETUPCONNECTORAPI } from '../utils/constants'
import { TOKEN_TYPE } from '../utils/constants'
import { getItem } from '../utils/storage'

const UseFetchMicrosoftUsers = (
  callBack = () => {},
  errorCallBack = () => {},
  onInvalidTokenError = () => {},
  onTooManyReqError = () => {}
) => {
  const api = axios.create({
    baseURL: SETUPCONNECTORAPI,
    headers: {
      Authorization: `${getItem(TOKEN_TYPE)} ${getItem(ID_TOKEN)}`,
      'x-api-key': API_MANAGER_KEY,
      'ms-token': localStorage.getItem('mstoken'),
    },
  })

  const dispatch = useDispatch()
  const { userAccounId, microsoftUsersCollections } = useSelector(
    ({ msTeamsReducer, sampleReducer }) => {
      return {
        userAccounId: sampleReducer.userAccounId,
        microsoftUsersCollections: msTeamsReducer.microsoftUsersCollections,
      }
    }
  )

  const [action, setAction] = useState('')
  const [body, setBody] = useState({})
  const [fetch, setFetch] = useState(false)
  const [id, setId] = useState('')

  const reset = () => {
    setAction('')
    setBody({})
    setFetch(false)
    setId('')
  }

  const getCurrentPage = () => {
    if (action === 'prevoius') {
      return microsoftUsersCollections.currentPage - 1
    } else if (action === 'next') {
      return microsoftUsersCollections.currentPage + 1
    } else {
      return 1
    }
  }

  const getCurrentUsers = (currentPage, pages, currentSetCount) => {
    const count =
      pages === currentPage
        ? currentSetCount
        : microsoftUsersCollections.usersPerPage

    return (
      microsoftUsersCollections.usersPerPage * currentPage -
      (microsoftUsersCollections.usersPerPage - count)
    )
  }

  useEffect(() => {
    if (fetch) {
      let keyFound = false

      setFetch(false)
      api
        .post(`${id}/3/${userAccounId}`, {
          ...{ size: microsoftUsersCollections.usersPerPage },
          ...body,
        })
        .then((res) => {
          if (res.data.status) {
            callBack(res.data.data)
            const nextUsersUrls = JSON.parse(
              JSON.stringify(microsoftUsersCollections.nextUsersUrls)
            )

            const currentSetCount = res.data?.data?.value
              ? res.data.data.value.length
              : 0

            const currentPage = getCurrentPage()
            const users = res.data.data['@odata.count']
              ? res.data.data['@odata.count']
              : microsoftUsersCollections.users

            const pages = Math.ceil(
              users / microsoftUsersCollections.usersPerPage
            )

            nextUsersUrls.length &&
              nextUsersUrls.forEach((item) => {
                if (item.key === currentPage) {
                  item.link = res.data.data['@odata.nextLink']
                  keyFound = true
                }
              })

            if (!keyFound) {
              nextUsersUrls.push({
                key: currentPage,
                link: res.data.data['@odata.nextLink'],
              })
            }

            dispatch(
              setMicrosoftUsersCollection({
                currentPage: currentPage,
                currentUsers: getCurrentUsers(
                  currentPage,
                  pages,
                  currentSetCount
                ),
                pages: pages,
                nextUsersUrls: nextUsersUrls,
                users: users,
                usersPerPage: microsoftUsersCollections.usersPerPage,
              })
            )
          } else if (res?.data?.error?.message === 'INVALID_MICROSOFT_TOKEN') {
            onInvalidTokenError(res.data.data)
          } else if (res?.data?.error?.message === tooManyRequest) {
            onTooManyReqError(res.data.data)
          } else {
            errorCallBack(res.data)
          }
        })
        .catch((err) => {
          errorCallBack(err)
        })
    }
  }, [fetch])

  return [{ reset, setAction, setBody, setFetch, setId }]
}

export default UseFetchMicrosoftUsers
