const trunkReducer = (
  state = {
    actualTrunk: {},
    modifiedTrunk: {},
    trunkSaveFromClose: false,
    trunkSaveFromRow: false,
    trunkSaveFromTab: false,
    trunkSavedFromTab: false,
    trunkSaveFromOption: false,
    trunkSavedFromOption: false,
  },
  action
) => {
  switch (action.type) {
    case 'setActualTrunk':
      return { ...state, actualTrunk: action.actualTrunk }

    case 'setModifiedTrunk':
      return { ...state, modifiedTrunk: action.modifiedTrunk }

    case 'setTrunkSaveFromClose':
      return { ...state, trunkSaveFromClose: action.trunkSaveFromClose }

    case 'setTrunkSaveFromRow':
      return { ...state, trunkSaveFromRow: action.trunkSaveFromRow }

    case 'setTrunkSaveFromTab':
      return { ...state, trunkSaveFromTab: action.trunkSaveFromTab }

    case 'setTrunkSavedFromTab':
      return { ...state, trunkSavedFromTab: action.trunkSavedFromTab }

    case 'setTrunkSaveFromOption':
      return { ...state, trunkSaveFromOption: action.trunkSaveFromOption }

    case 'setTrunkSavedFromOption':
      return { ...state, trunkSavedFromOption: action.trunkSavedFromOption }

    default:
      return state
  }
}

export default trunkReducer
