// @flow
import React, { memo } from 'react'
import './selectabletablerow.css'
type Props = {
  componentData: Object,
  selectedRow: string | Array<string>,
  updateSelectedRow: (any) => mixed,
}
const classNames = require('classnames')
const SelectableTableRow = (props: Props) => {
  const { componentData, selectedRow, updateSelectedRow } = props

  let ArrayToCheck = []

  if (typeof selectedRow === 'string') {
    ArrayToCheck.push(selectedRow)
  } else {
    ArrayToCheck = selectedRow
  }

  const rowId = componentData['data-row-key']
  const tableRow = classNames({
    'selectabletable-row': true,
    'selected-row': selectedRow && ArrayToCheck && ArrayToCheck.includes(rowId),
  })

  return (
    <tr
      key={rowId}
      onClick={() => updateSelectedRow(rowId)}
      className={tableRow}
    >
      {componentData.children}
    </tr>
  )
}

export default memo<Props>(SelectableTableRow)
