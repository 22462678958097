// @flow

import React, { Fragment } from 'react'
import { PortWidget } from '@projectstorm/react-diagrams'
import { Tooltip } from 'antd'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { NODE_TYPES } from '../../NodeFieldTypes/nodetypes'
import './CustomNodeWidget.css'

type Props = {
  engine: Object,
  node: Object,
}

const CustomNodeWidget = (props: Props) => {
  const { engine, node } = props

  const renderInPorts = () => {
    return node.portsIn.map((inport) => (
      <div className="Inport_Style" key={inport.options.name}>
        <PortWidget engine={engine} port={inport}>
          <div id={inport.options.name} className="node-port-in" />
        </PortWidget>
      </div>
    ))
  }

  const renderOutPorts = () => {
    const getTitle = (port) => {
      const portData = port.parent.options.ports.filter(
        (data) => data.name === port.options.name
      )

      if (portData.length) {
        if (
          portData[0].type === NODE_TYPES.mapNumberToNode ||
          portData[0].type === NODE_TYPES.mapNumberOrStringToNode
        ) {
          return `${portData[0].displayName}_${portData[0].label}`
        } else if (portData[0].type === NODE_TYPES.node) {
          return portData[0].displayName
        } else {
          return ''
        }
      } else {
        return ''
      }
    }

    const isAddNode = ({ options, parent }) => {
      return (parent.options.mode === NODE_TYPES.mapNumberToNode ||
        parent.options.mode === NODE_TYPES.mapNumberOrStringToNode) &&
        options.name.includes('out-connect')
        ? true
        : false
    }

    return node.portsOut.map((outport) => (
      <div className="Outport_Style" key={outport.options.name}>
        <PortWidget engine={engine} port={outport}>
          <Tooltip placement="bottom" title={getTitle(outport)}>
            <div className="node-port-out">
              {isAddNode(outport) ? (
                <PlusOutlined style={{ fontSize: 10 }} />
              ) : (
                <Fragment />
              )}
            </div>
          </Tooltip>
        </PortWidget>
      </div>
    ))
  }

  const renderNodeIcon = () => {
    return (
      <img
        alt=""
        className="CustomNodeWidget__Icon"
        src={node.options.iconUrl}
      />
    )
  }

  const nodeClick = (event) => {
    event.stopPropagation()
    node.setSelected(false)
    node.clickFunction(node.options.callFlowNodeId)
  }

  const nodeDelete = (event) => {
    event.stopPropagation()
    node.deleteFunction(node.options)
  }

  return (
    <div className="CustomNodeWidget__Container">
      <div className="CustomNodeWidget__Inport-Container">
        {renderInPorts()}
      </div>
      <div className="CustomNodeWidget__Outport_And_Node">
        <div className="CustomNodeWidget__Node" onClick={nodeClick}>
          <div className="CustomNodeWidget__IconCont">{renderNodeIcon()}</div>
          <div className="CustomNodeWidget__Name">{node.name}</div>
          <div className="CustomNodeWidget__Delete" onClick={nodeDelete}>
            <Tooltip title="Delete">
              <DeleteOutlined />
            </Tooltip>
          </div>
        </div>
        <div
          className={`CustomNodeWidget__Outport-Container ${
            node.portsOut.length > 1
              ? 'CustomNodeWidget__Outport-Cont'
              : 'CustomNodeWidget__Outports-Cont'
          }`}
        >
          {renderOutPorts()}
        </div>
      </div>
    </div>
  )
}

export default CustomNodeWidget
