// @flow

import React from 'react'
import { Input } from 'antd'
import { keyDownHandler, pasteHandler } from '../../../utils/helpers'

type Props = {
  changeHandler: (value: string, position: number) => mixed,
  onBlur: () => mixed,
  position: number,
  value: string,
}

const ShortNumber = ({ onBlur, position, changeHandler, value }: Props) => {
  const onChange = (event) => {
    if (event.target.value.length <= 2) {
      changeHandler(event.target.value, position)
    }
  }

  return (
    <Input
      onBlur={onBlur}
      onChange={onChange}
      onKeyDown={(event) => keyDownHandler(event, 'allowOnlyNumbers')}
      onPaste={(event) => pasteHandler(event, 'allowOnlyNumbers')}
      value={value}
    />
  )
}

export default React.memo<Props>(ShortNumber)
