import axios from 'axios'
import { AUTH_API_KEY, ID_TOKEN, TOKEN_TYPE } from '../utils/constants'
import { getItem } from '../utils/storage'

const useAxiosInstance = (url, type) => {
  const authToken = `${getItem(TOKEN_TYPE)} ${getItem(ID_TOKEN)}`

  const getHeader = () => {
    if (type === 'PttAuthApi') {
      return {
        Authorization: authToken,
        'Content-Type': 'application/json',
        'x-api-key': AUTH_API_KEY,
      }
    }
  }

  const api = axios.create({
    baseURL: url,
    headers: getHeader(),
  })

  return api
}

export default useAxiosInstance
