/* eslint-disable react/prop-types */

import React, { useEffect } from 'react'
import { Input } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import PttButton from '../../CustomButton/PttButton'
import { Fragment } from 'react'
import { DeleteOutlined } from '@ant-design/icons'
import { cloneDeep } from 'lodash'
import { setModifiedTrunk } from '../../../actions/trunksAction'
import { FULL_ACCESS } from '../../../utils/constants'

const UnEditableSection = ({ label }) => {
  return (
    <div
      style={{
        marginBottom: 5,
        padding: '4px 11px',
        border: '1px solid lightgrey',
        height: 32,
      }}
    >
      {label}
    </div>
  )
}

const CredentialsTab = ({ origin = '', init, state = {}, setState }) => {
  const dispatch = useDispatch()
  const {
    language,
    translations,
    modifiedTrunk,
    advancedPermission,
  } = useSelector((state) => {
    return {
      language: state.sampleReducer.language,
      translations: state.sampleReducer.translations,
      modifiedTrunk: state.trunkReducer.modifiedTrunk,
      advancedPermission: state.permissionReducer.advancedPermission,
    }
  })

  useEffect(() => {
    if (init) {
      const element = document.getElementById('credentialsTab')

      if (element) {
        element.scrollTop = 0
      }
    }
  }, [init])

  const updateModifiedTrunk = (newState) => {
    if (origin === 'sidebar') {
      const prev = cloneDeep(modifiedTrunk)

      prev.credentials = newState
      dispatch(setModifiedTrunk(prev))
    }
  }

  const onCallerChange = (event, rowIndex) => {
    onRowChange('caller', event.target.value, rowIndex)
  }

  const onUsernameChange = (event, rowIndex) => {
    onRowChange('username', event.target.value, rowIndex)
  }

  const onPasswordChange = (event, rowIndex) => {
    onRowChange('password', event.target.value, rowIndex)
  }

  const onFromChange = (event, rowIndex) => {
    onRowChange('from', event.target.value, rowIndex)
  }

  const onRowAdd = () => {
    const newState = cloneDeep(state)

    newState.push({ caller: '', username: '', password: '', from: '' })
    setState(newState)
    updateModifiedTrunk(newState)
  }

  const onRowDelete = (rowIndex) => {
    const newState = []

    state.forEach((item, index) => {
      if (rowIndex !== index) {
        newState.push(item)
      }
    })
    setState(newState)
    updateModifiedTrunk(newState)
  }

  const onRowChange = (field, value, rowIndex) => {
    const newState = []

    state.forEach((item, index) => {
      if (rowIndex === index) {
        item[field] = value
        newState.push(item)
      } else {
        newState.push(item)
      }
    })
    setState(newState)
    updateModifiedTrunk(newState)
  }

  const renderHeading = () => {
    return (
      <Fragment>
        <div style={{ width: '22%', paddingLeft: 5 }}>
          {translations.caller[language]}
        </div>
        <div style={{ width: '22%', paddingLeft: 5 }}>
          {translations.username[language]}
        </div>
        <div style={{ width: '22%', paddingLeft: 5 }}>
          {translations.password[language]}
        </div>
        <div style={{ width: '22%', paddingLeft: 5 }}>
          {translations.from[language]}
        </div>
      </Fragment>
    )
  }

  const renderDelete = (rowIndex) => {
    if (advancedPermission === FULL_ACCESS) {
      return (
        <div
          className="trunk_add_credentials_delbutton"
          onClick={() => onRowDelete(rowIndex)}
        >
          <DeleteOutlined />
        </div>
      )
    } else {
      return <Fragment />
    }
  }

  const renderCallerField = (rowItem, rowIndex) => {
    if (advancedPermission === FULL_ACCESS) {
      return (
        <Input
          value={rowItem.caller}
          onChange={(event) => onCallerChange(event, rowIndex)}
        />
      )
    } else {
      return <UnEditableSection label={rowItem.caller} />
    }
  }

  const renderUserField = (rowItem, rowIndex) => {
    if (advancedPermission === FULL_ACCESS) {
      return (
        <Input
          value={rowItem.username}
          onChange={(event) => onUsernameChange(event, rowIndex)}
        />
      )
    } else {
      return <UnEditableSection label={rowItem.username} />
    }
  }

  const renderPassField = (rowItem, rowIndex) => {
    if (advancedPermission === FULL_ACCESS) {
      return (
        <Input.Password
          autocomplete="new-password"
          value={rowItem.password}
          onChange={(event) => onPasswordChange(event, rowIndex)}
        />
      )
    } else {
      return <UnEditableSection label={rowItem.password} />
    }
  }

  const renderFromField = (rowItem, rowIndex) => {
    if (advancedPermission === FULL_ACCESS) {
      return (
        <Input
          value={rowItem.from}
          onChange={(event) => onFromChange(event, rowIndex)}
        />
      )
    } else {
      return <UnEditableSection label={rowItem.password} />
    }
  }

  const renderRow = (rowItem, rowIndex) => {
    return (
      <div className="trunk_add_credentials_rowsingle" key={rowIndex}>
        <div style={{ width: '22%', padding: 5 }}>
          {renderCallerField(rowItem, rowIndex)}
        </div>
        <div style={{ width: '22%', padding: 5 }}>
          {renderUserField(rowItem, rowIndex)}
        </div>
        <div style={{ width: '22%', padding: 5 }}>
          {renderPassField(rowItem, rowIndex)}
        </div>
        <div style={{ width: '22%', padding: 5 }}>
          {renderFromField(rowItem, rowIndex)}
        </div>
        {renderDelete(rowIndex)}
      </div>
    )
  }

  return (
    <div id={'credentialsTab'} style={{ height: '100%' }}>
      <div className="trunk_add_credentials_heading">{renderHeading()}</div>
      <div className="trunk_add_credentials_row">
        {state.map((item, index) => {
          return renderRow(item, index)
        })}
      </div>
      <div className="trunk_add_credentials_addbutton">
        <PttButton
          type="dashed"
          onClick={onRowAdd}
          disabled={advancedPermission !== FULL_ACCESS}
        >
          {translations.add_credentials[language]}
        </PttButton>
      </div>
    </div>
  )
}

export default CredentialsTab
