import { useEffect } from 'react'
import { isEmpty } from 'lodash'
import { APIURL, FULL_ACCESS, READ_ONLY } from '../utils/constants'
import useCustomFetch from './UseCustomFetch'
import { useDispatch } from 'react-redux'
import { setUserLanguage, setUserSettingsData } from '../actions/SampleAction'
import { setInitalSettingsLoading } from '../actions/settingAction'
import {
  getDefaultLanguage,
  getDefaultPermissions,
  getUserPermissions,
} from '../utils/SettingsUtils'
import {
  setAccessUsersPermission,
  setAdvancedPermission,
  setBillingPermission,
  setCompanySettingsPermission,
  setMicrosoftTeamsPermission,
  setPhoneNumberPermission,
  setStatsPermission,
} from '../actions/permissionAction'

const UseCheckDetails = () => {
  const dispatch = useDispatch()
  const [api] = useCustomFetch(
    `${APIURL}/user_settings?query=custom_id=@userid`,
    'GET',
    true
  )

  const [permissionsApi] = useCustomFetch(
    `${APIURL}/user_permissions?query=custom_id=@userid`,
    'GET',
    true
  )

  const errorHandler = () => {
    dispatch(setUserLanguage('en'))
    dispatch(setUserSettingsData({}))
    dispatch(setInitalSettingsLoading(false))
  }

  const onPermissionsError = () => {
    dispatch(setMicrosoftTeamsPermission(READ_ONLY))
    dispatch(setPhoneNumberPermission(READ_ONLY))
    dispatch(setStatsPermission(READ_ONLY))
    dispatch(setAdvancedPermission(READ_ONLY))
    dispatch(setBillingPermission(READ_ONLY))
    dispatch(setCompanySettingsPermission(READ_ONLY))
    dispatch(setAccessUsersPermission(READ_ONLY))
  }

  const onPermissionsFalse = () => {
    dispatch(setMicrosoftTeamsPermission(FULL_ACCESS))
    dispatch(setPhoneNumberPermission(FULL_ACCESS))
    dispatch(setStatsPermission(FULL_ACCESS))
    dispatch(setAdvancedPermission(FULL_ACCESS))
    dispatch(setBillingPermission(FULL_ACCESS))
    dispatch(setCompanySettingsPermission(FULL_ACCESS))
    dispatch(setAccessUsersPermission(FULL_ACCESS))
  }

  const onPermissionsSuccess = (item) => {
    const permissions = item?.permissions
      ? getUserPermissions(item.permissions)
      : getDefaultPermissions()

    dispatch(setMicrosoftTeamsPermission(permissions.MICROSOFT_TEAMS))
    dispatch(setPhoneNumberPermission(permissions.PHONE_NUMBERS))
    dispatch(setStatsPermission(permissions.STATS))
    dispatch(setAdvancedPermission(permissions.ADVANCED))
    dispatch(setBillingPermission(permissions.BILLING))
    dispatch(setCompanySettingsPermission(permissions.COMPANY_SETTINGS))
    dispatch(setAccessUsersPermission(permissions.ACCESS_USERS))
  }

  useEffect(() => {
    if (!isEmpty(api.response)) {
      if (
        api.response.response.items.length &&
        api.response.response.items[0].id
      ) {
        const item = api.response.response.items[0]
        const data = item?.data ? item.data : {}
        const language = data?.language ? data.language : getDefaultLanguage()

        dispatch(setUserLanguage(language))
        dispatch(setUserSettingsData(item))
        dispatch(setInitalSettingsLoading(false))
      } else {
        errorHandler()
      }
    }
  }, [api.response])

  useEffect(() => {
    if (!isEmpty(permissionsApi.response)) {
      if (
        permissionsApi.response.response.items.length &&
        permissionsApi.response.response.items[0].id
      ) {
        const item = permissionsApi.response.response.items[0]

        onPermissionsSuccess(item)
      } else {
        onPermissionsFalse()
      }
    }
  }, [permissionsApi.response])

  useEffect(() => {
    if (api.error !== null) {
      errorHandler()
    }
  }, [api.error])

  useEffect(() => {
    if (permissionsApi.error !== null) {
      errorHandler()
    }
  }, [permissionsApi.error])
}

export default UseCheckDetails
