// @flow

import React from 'react'
import { Alert, Button, Input, message } from 'antd'
import ModalLayout, { Header, Body, Footer } from '../../../Popup/popup'
import { PttButton } from '../../../CustomButton/custombutton'
import './callflow.css'
import { connect } from 'react-redux'

type Props = {
  disabledKeys: $ReadOnlyArray<*>,
  disabledWords: $ReadOnlyArray<*>,
  onOkay: (dial: string, say: string) => mixed,
  onCancel: () => void,
  visible: boolean,
  translations: Object,
  language: string,
}

const AddDialSay = (props: Props) => {
  const inputRef = React.createRef()
  const [dial, setDial] = React.useState('')
  const [error, setError] = React.useState('')
  const [say, setSay] = React.useState('')

  React.useEffect(() => {
    inputRef && inputRef.current && inputRef.current.focus()
  }, [dial])

  const renderButton = (number, className = '') => {
    return (
      <Button
        className={`dialpad_button-style ${className} ${
          dial === number
            ? 'AddDialSpeech__Selected'
            : 'AddDialSpeech__Deselected'
        }`}
        disabled={props.disabledKeys.includes(number)}
        key={number}
        onClick={() => setDial(number)}
      >
        {number}
      </Button>
    )
  }

  const onSayChange = (event) => {
    setSay(event.target.value)
    setError(
      props.disabledWords.includes(event.target.value.trim())
        ? 'say option already exists'
        : ''
    )
  }

  const renderBody = () => {
    if (props.visible) {
      return (
        <div style={{ display: 'flex' }}>
          <div style={{ paddingRight: 15 }}>
            <div style={{ marginBottom: 5 }}>Dial</div>
            <div>{['1', '2', '3'].map((number) => renderButton(number))}</div>
            <div>{['4', '5', '6'].map((number) => renderButton(number))}</div>
            <div>{['7', '8', '9'].map((number) => renderButton(number))}</div>
            <div>{renderButton('0', 'zero_button-style')}</div>
          </div>
          <div>
            <div style={{ marginBottom: 5 }}>or Say</div>
            {error.length ? (
              <Alert message={error} style={{ marginBottom: 5 }} type="error" />
            ) : (
              <React.Fragment />
            )}
            <Input
              ref={inputRef}
              onChange={onSayChange}
              onPressEnter={onSuccess}
              value={say}
            />
          </div>
        </div>
      )
    } else {
      return <React.Fragment />
    }
  }

  const onSuccess = () => {
    if (error.length) {
      message.error(error)
    } else if (dial.length || say.trim().length) {
      props.onOkay(dial, say.trim())
    } else if (say.trim().length === 0) {
      message.error(props.translations.choose_a_say_option_info[props.language])
    }
  }

  return (
    <ModalLayout onCancel={props.onCancel} visible={props.visible}>
      <Header>{'Add Dial/Say'}</Header>
      <Body>{renderBody()}</Body>
      <Footer>
        {
          <PttButton
            disabled={!(dial.length || say.length) || error.length}
            type="primary"
            onClick={onSuccess}
          >
            Ok
          </PttButton>
        }
      </Footer>
    </ModalLayout>
  )
}

const mapsStateToProps = (state) => {
  return {
    translations: state.sampleReducer.translations || {},
    language: state.sampleReducer.language,
  }
}

export default connect(mapsStateToProps, null)(AddDialSay)
