// @flow

import React from 'react'
import { Checkbox, Empty, Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import PttButton from '../../CustomButton/PttButton'
import ModalLayout, { Header, Body, Footer } from '../../Popup/popup'
import { getNewArray } from '../../../utils/helpers'
import './VoiceUserComponent.css'

type Props = {
  closeModel: () => void,
  onSuccess: (any) => mixed,
  voiceUsers: $ReadOnlyArray<*>,
  visible: boolean,
}

const VoiceUserModelComponent = ({
  closeModel,
  onSuccess,
  voiceUsers = [],
  visible,
}: Props) => {
  const [filter, setFilter] = React.useState('')
  const [users, setUsers] = React.useState([])

  React.useEffect(() => {
    setFilter('')
    setUsers(voiceUsers)
  }, [voiceUsers])

  const onChecked = (checked, id) => {
    const set = getNewArray(users)

    set.map((user) => {
      if (user.id === id) {
        user.selected = checked
      }
    })

    setUsers(set)
  }

  const renderVoiceUsers = () => {
    const list = users.filter(
      (user) =>
        user.displayName.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    )

    return (
      <React.Fragment>
        <Input
          className="VoiceUserComponent__ModelSearch"
          onChange={(event) => setFilter(event.target.value)}
          prefix={<SearchOutlined />}
          value={filter}
        />
        <div className="VoiceUserComponent__ModelTitle">Name</div>
        <div className="VoiceUserComponent__ModelCheckBody">
          {list.map((row) => (
            <div
              className="VoiceUserComponent__ModelCheckStyle"
              key={`check ${row.id}`}
            >
              <Checkbox
                checked={row.selected}
                onChange={(event) => onChecked(event.target.checked, row.id)}
              >
                {row.displayName}
              </Checkbox>
            </div>
          ))}
        </div>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <ModalLayout onCancel={closeModel} visible={visible}>
        <Header>Add Agent</Header>
        <Body>
          {users.length ? (
            renderVoiceUsers()
          ) : (
            <div className="VoiceUserComponent__ModelEmpty">
              <Empty />
            </div>
          )}
        </Body>
        {users.length ? (
          <Footer>
            <PttButton onClick={() => onSuccess(users)} type="primary">
              Add
            </PttButton>
          </Footer>
        ) : (
          <React.Fragment />
        )}
      </ModalLayout>
    </React.Fragment>
  )
}

export default VoiceUserModelComponent
