import { compose } from 'redux'
import storage from 'redux-persist/lib/storage'
import reducers from './reducers'
import { persistReducer } from 'redux-persist'
import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [],
}

const composeEnhancers =
  // eslint-disable-next-line no-undef
  process.env.NODE_ENV !== 'production' &&
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose

const enhancers = [applyMiddleware(thunk)]
const persistedReducer = persistReducer(persistConfig, reducers)
const store = createStore(persistedReducer, composeEnhancers(...enhancers))

export default store
