/* eslint-disable no-console */
import * as msal from '@azure/msal-browser'
import { fetchEnviroinment, parseApiResult } from '../../utils/helpers'

const msalConfig = {
  auth: {
    clientId: '4544422a-3465-4d7f-963d-1bbd8cd153a4',
    authority: 'https://login.microsoftonline.com/common',
    clientSecret: '_G5uc-~XIDQ-H-.V.GTT.OGm3R4c7vg5-6',
    knownAuthorities: [],
    redirectUri: window.origin,
    postLogoutRedirectUri: window.origin,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    windowHashTimeout: 60000,
    iframeHashTimeout: 6000,
    loadFrameTimeout: 0,
  },
}

const msalInstance = new msal.PublicClientApplication(msalConfig)

export const licenseLimitReached = 'E1_LICENCE_LIMIT_REACHED'
export const verifyDnsText = 'FAILED_VERIFY_DNS_TXT'
export const tooManyRequest = 'FAILED_TOO_MANY_REQUEST'

export const getTokenPopup = (request, myMsalObject) => {
  // need to change to refresh token
  return myMsalObject.acquireTokenSilent(request).catch(() => {
    return myMsalObject
      .acquireTokenPopup(request)
      .then((tokenResponse) => {
        return tokenResponse
      })
      .catch(() => {})
  })
}

export const oauthMicrosoft = async (mail = '', successCb, errorCb) => {
  const scopes = [
    'openid',
    'profile',
    'User.Read',
    'Directory.Read.All',
    'Domain.ReadWrite.All',
    'User.ReadWrite.All',
    'RoleManagement.ReadWrite.Directory',
  ]

  if (mail.length) {
    msalInstance
      .acquireTokenSilent({
        account: msalInstance.getAccountByUsername(mail),
        scopes: scopes,
        forceRefresh: false,
      })
      .then((resp) => {
        localStorage.setItem('mstoken', resp.accessToken)
        successCb(resp.account.username)
      })
      .catch((err) => {
        if (
          err.errorCode === 'no_account_error' ||
          err.errorCode === 'consent_required' ||
          err.errorCode === 'interaction_required' ||
          err.errorCode === 'login_required'
        ) {
          return msalInstance
            .acquireTokenPopup({
              forceRefresh: false,
              loginHint: mail,
              scopes: scopes,
            })
            .then((resp) => {
              localStorage.setItem('mstoken', resp.accessToken)
              successCb(resp.account.username)
            })
            .catch((err) => {
              errorCb(err)
            })
        } else {
          errorCb(err)
        }
      })
  } else {
    msalInstance
      .loginPopup({ scopes: scopes, prompt: 'select_account' })
      .then((resp) => {
        const username = resp.account.username
        const account = msalInstance.getAccountByUsername(username)

        msalInstance
          .acquireTokenSilent({
            account: account ? account : null,
            scopes: scopes,
          })
          .then((resp) => {
            localStorage.setItem('mstoken', resp.accessToken)
            successCb(username)
          })
          .catch((err) => {
            errorCb(err)
          })
      })
      .catch((err) => {
        errorCb(err)
      })
  }
}

export const getConfigUserOptions = (response) => {
  const res = parseApiResult(response)
  const items = res.length && res[0].id ? res : []
  const filteredItems = items.filter(
    (item) => !item.relation || item.relation.voice_user
  )

  return filteredItems.map((filteredItem) => {
    return { id: filteredItem.id, chosen: false }
  })
}

const initObject = {
  callingPlan: { country: '', data: [] },
  extension: '',
  extensionError: { isError: false, type: 'none' },
  phonenumber: '',
  phonenumberError: { isError: false, type: 'none' },
}

const getUserPrevState = (userId = '', listRows = []) => {
  if (listRows.length) {
    const details = listRows.filter((list) => list.key === userId)

    if (details.length) {
      return {
        callingPlan: details[0].callingPlan,
        extension: details[0].extension,
        extensionError: details[0].extensionError,
        phonenumber: details[0].phonenumber,
        phonenumberError: details[0].phonenumberError,
      }
    } else {
      return initObject
    }
  }

  return initObject
}

const getDisableStatus = (user, relations) => {
  return !(
    user.valid_license &&
    !relations.some((data) => data.data && data.data.microsoft_id === user.id)
  )
}

export const getMsUsers = (items = [], rows = [], relations = []) => {
  const data = []

  items.forEach((user) => {
    const prevState = getUserPrevState(user.id, rows)

    data.push({
      callingPlan: prevState.callingPlan,
      disabled: relations.length
        ? getDisableStatus(user, relations)
        : !user.valid_license,
      displayName: user.displayName ? user.displayName : '',
      email: user.userPrincipalName ? user.userPrincipalName : '',
      extension: prevState.extension,
      extensionError: prevState.extensionError,
      givenName: user.givenName ? user.givenName : '',
      key: user.id,
      lastName: user.surname ? user.surname : '',
      licenseName: user.licenseName.length ? user.licenseName : ['-'],
      microsoft_id: user.id,
      phonenumber: prevState.phonenumber,
      phonenumberError: prevState.phonenumberError,
      valid_license: user.valid_license,
    })
  })

  return data
}

export const getTickedRows = (rows = [], keys = []) => {
  return rows.filter(({ key }) => {
    return keys.includes(key)
  })
}

export const isContainsError = (activeRows) => {
  return activeRows.some(
    (row) =>
      !row.phonenumber.length ||
      row.phonenumberError.isError ||
      row.extensionError.isError
  )
}

const getUsedNumbers = (activeRows, existingUsers) => {
  const keys = []
  const result = []

  activeRows.map(({ phonenumber }) => {
    !keys.includes(phonenumber) && keys.push(phonenumber)
  })

  const getExtension = (phonenumber) => {
    const extensions = []

    activeRows.forEach((row) => {
      if (row.phonenumber === phonenumber) {
        extensions.push(row.extension)
      }
    })

    existingUsers.forEach((users) => {
      if (users.phonenumber === phonenumber) {
        extensions.push(users.extension)
      }
    })

    return extensions
  }

  keys.forEach((key) => {
    result.push({
      phonenumber: key,
      extensions: getExtension(key),
    })
  })

  return result
}

export const canProceed = (activeRows, existingUsers) => {
  let proceed = true
  let number = ''
  const nums = getUsedNumbers(activeRows, existingUsers)

  nums.forEach((numberObj) => {
    if (
      proceed &&
      numberObj.extensions.filter((ext) => ext.length === 0).length >= 2
    ) {
      number = numberObj.phonenumber
      proceed = false
    }
  })

  return { proceed: proceed, phonenumber: number }
}

export const getCountries = (plans) => {
  return [
    ...new Set(plans.map((plan) => plan.data.related_country).flat()),
  ].map((item) => item.toUpperCase())
}

export const getPlans = (plans, countries) => {
  const countryPlans = []

  countries.map((country) => {
    const data = plans.filter((plan) => {
      if (plan.data.related_country.includes(country.toLowerCase())) {
        return plan
      }
    })

    countryPlans[country] = data
  })

  return countryPlans
}

export const getSetupConnectorRetryTime = (retry) => {
  if (retry) {
    return Number(retry) * 1000
  } else {
    return 187000
  }
}

export const printSetupConnectorInfo = (api, name) => {
  console.log({
    apiname: name,
    error: api.error,
    response: api.response,
  })
}

export const getChangeUserMailTemplate = (
  company_settings,
  user_settings,
  old_voice_user,
  new_voice_user
) => {
  const header = `<html>
  <body style="background-color:#e2e1e0;font-family: Open Sans, sans-serif;font-size:100%;font-weight:400;line-height:1.4;color:#000;">
    <table style="max-width:670px;margin:50px auto 10px;background-color:#fff;padding:50px;-webkit-border-radius:3px;-moz-border-radius:3px;border-radius:3px;-webkit-box-shadow:0 1px 3px rgba(0,0,0,.12),0 1px 2px rgba(0,0,0,.24);-moz-box-shadow:0 1px 3px rgba(0,0,0,.12),0 1px 2px rgba(0,0,0,.24);box-shadow:0 1px 3px rgba(0,0,0,.12),0 1px 2px rgba(0,0,0,.24); border-top: solid 10px #0052A7;">
      <tbody>
        <tr>
          <td style="height:35px;"></td>
        </tr>`

  const body = `<tr>
  <td>
    <span style="font-weight:bold">company_settings:</span>
  </td>
  </tr>
  <tr>
      <td>
        <span>${JSON.stringify(company_settings)}</span>
      </td>
  </tr>
  <tr>
      <td>
        <span style="font-weight:bold">user_settings</span>
      </td>
  </tr>
  <tr>
      <td>
        <span>${JSON.stringify(user_settings)}</span>
      </td>
  </tr>
  <tr>
      <td>
        <span style="font-weight:bold">old_voice_user</span>
      </td>
  </tr>
  <tr>
      <td>
        <span>${JSON.stringify(old_voice_user)}</span>
      </td>
  </tr>
  <tr>
      <td>
        <span style="font-weight:bold">new_voice_user</span>
      </td>
  </tr>
  <tr>
      <td>
        <span>${JSON.stringify(new_voice_user)}</span>
      </td>
  </tr>
  <tr>
      <td>
        <span style="font-weight:bold">User Changed environment:  </span><span>${fetchEnviroinment(
          'name'
        )}</span>
      </td>
  </tr>
  </tbody>
  </table>
  </body>

  </html>`

  return header + body
}
