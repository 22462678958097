import React from 'react'
import { useSelector } from 'react-redux'
import PttLayout from '../components/Home/PttHome/PttLayout'
import Callflow from '../components/IncomingCalls/SubComponents/CallFlow/callflow'
import { getTranslatedText } from '../utils/translations'

function CallFlowPage() {
  const { language, translations } = useSelector(({ sampleReducer }) => ({
    language: sampleReducer.language || 'en',
    translations: sampleReducer.translations || {},
  }))

  return (
    <PttLayout
      currentkey="3"
      title={getTranslatedText(translations, language, 'call_flow_title')}
    >
      <Callflow />
    </PttLayout>
  )
}

export default CallFlowPage
