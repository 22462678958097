import React from 'react'
import { Redirect } from 'react-router-dom'
import AuthComponents from './AuthComponents'
import { EXPIRE } from './utils/constants'
import { getItem } from './utils/storage'

function AuthTokenRefresh() {
  return getItem(EXPIRE) && Number(getItem(EXPIRE)) * 1000 > Date.now() ? (
    <AuthComponents />
  ) : (
    <Redirect to={'/login'} />
  )
}

export default AuthTokenRefresh
