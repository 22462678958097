/* eslint-disable react/prop-types */

import React, { useEffect } from 'react'
import { Form, Input, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { keyDownHandler, pasteHandler } from '../../../utils/helpers'
import { cloneDeep } from 'lodash'
import { setModifiedTrunk } from '../../../actions/trunksAction'
import { FULL_ACCESS } from '../../../utils/constants'

const opt = ['TCP', 'TLS', 'UDP']

const FormItem = ({ label, children, req }) => {
  return (
    <div style={{ marginBottom: 10 }}>
      <Form.Item label={label} required={req}>
        {children}
      </Form.Item>
    </div>
  )
}

const UnEditableSection = ({ label, width }) => {
  return (
    <div
      style={{
        marginBottom: 5,
        padding: '4px 11px',
        border: '1px solid lightgrey',
        height: 32,
        width: width,
      }}
    >
      {label}
    </div>
  )
}

const GeneralTab = ({ origin = '', init, state = {}, setState }) => {
  const { Option } = Select
  const dispatch = useDispatch()
  const {
    language,
    translations,
    modifiedTrunk,
    advancedPermission,
  } = useSelector((state) => {
    return {
      language: state.sampleReducer.language,
      translations: state.sampleReducer.translations,
      modifiedTrunk: state.trunkReducer.modifiedTrunk,
      advancedPermission: state.permissionReducer.advancedPermission,
    }
  })

  useEffect(() => {
    if (init) {
      const element = document.getElementById('generaltab')

      if (element) {
        element.scrollTop = 0
      }
    }
  }, [init])

  const updateModifiedTrunk = (newState) => {
    if (origin === 'sidebar') {
      const prev = cloneDeep(modifiedTrunk)

      prev.general = newState
      dispatch(setModifiedTrunk(prev))
    }
  }

  const handleChange = (field, value) => {
    const newState = cloneDeep(state)

    newState[field] = value
    setState(newState)
    updateModifiedTrunk(newState)
  }

  const onNameChange = (event) => {
    handleChange('name', event.target.value)
  }

  const onDescChange = (event) => {
    handleChange('description', event.target.value)
  }

  const onHostChange = (event) => {
    handleChange('host', event.target.value)
  }

  const onPortChange = (event) => {
    handleChange('port', event.target.value)
  }

  const onProtocolChange = (value) => {
    handleChange('protocol', value)
  }

  const renderNameField = () => {
    if (advancedPermission === FULL_ACCESS) {
      return (
        <Input
          style={{ width: '40%' }}
          value={state.name}
          onChange={onNameChange}
        />
      )
    } else {
      return <UnEditableSection label={state.name} width="40%" />
    }
  }

  const renderDescField = () => {
    if (advancedPermission === FULL_ACCESS) {
      return (
        <Input
          style={{ width: '100%' }}
          value={state.description}
          onChange={onDescChange}
        />
      )
    } else {
      return <UnEditableSection label={state.description} width="100%" />
    }
  }

  const renderHostField = () => {
    if (advancedPermission === FULL_ACCESS) {
      return (
        <Input
          style={{ width: 150 }}
          value={state.host}
          onChange={onHostChange}
        />
      )
    } else {
      return <UnEditableSection label={state.host} width="150px" />
    }
  }

  const renderPortField = () => {
    if (advancedPermission === FULL_ACCESS) {
      return (
        <Input
          style={{ width: 150 }}
          value={state.port}
          onChange={onPortChange}
          onKeyDown={(event) => keyDownHandler(event, 'allowOnlyNumbers')}
          onPaste={(event) => pasteHandler(event, 'allowOnlyNumbers')}
        />
      )
    } else {
      return <UnEditableSection label={state.port} width="150px" />
    }
  }

  const renderProtocolField = () => {
    if (advancedPermission === FULL_ACCESS) {
      return (
        <Select
          style={{ width: 200 }}
          value={state.protocol}
          onChange={onProtocolChange}
        >
          {opt.map((item) => (
            <Option key={item} value={item}>
              {item}
            </Option>
          ))}
        </Select>
      )
    } else {
      return <UnEditableSection label={state.protocol} width="200px" />
    }
  }

  return (
    <div id={'generaltab'} style={{ height: '100%' }}>
      <Form layout="vertical">
        <div style={{ marginTop: 10 }}>
          <FormItem label={translations.name[language]} req={true}>
            {renderNameField()}
          </FormItem>
        </div>
        <FormItem label={translations.description[language]}>
          {renderDescField()}
        </FormItem>
        <div className="trunk_add_general_container">
          <FormItem label={translations.host[language]} req={true}>
            {renderHostField()}
          </FormItem>
          <FormItem label={translations.port[language]}>
            {renderPortField()}
          </FormItem>
          <FormItem label={translations.protocol[language]}>
            {renderProtocolField()}
          </FormItem>
        </div>
      </Form>
    </div>
  )
}

export default GeneralTab
