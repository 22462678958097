// @flow

import React from 'react'
import { Select } from 'antd'
import { FlagIcon } from 'react-flag-kit'

type Props = {
  dataProvider: $ReadOnlyArray<*>,
  iconsSize: Number,
  key?: string,
  label?: string,
  id?: string,
  optionBodyStyle?: string,
  optionTextStyle?: string,
  placeholder?: string,
  selectProps: Object,
}

const CountrySelect = ({
  dataProvider,
  iconsSize = 24,
  key = 'code',
  label = 'name',
  id = 'id',
  optionBodyStyle = '',
  optionTextStyle = '',
  placeholder,
  selectProps,
}: Props) => {
  const { Option } = Select

  return (
    <Select
      {...selectProps}
      placeholder={placeholder}
      value={
        selectProps.value && selectProps.value.length
          ? selectProps.value
          : undefined
      }
    >
      {dataProvider.map((item) => (
        <Option key={item[key], item[label]} id={item[id]} value={item[key]}>
          <div
            className={optionBodyStyle}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <FlagIcon code={item[key]} size={iconsSize} />
            <div className={optionTextStyle} style={{ marginLeft: 10 }}>
              {item[label]}
            </div>
          </div>
        </Option>
      ))}
    </Select>
  )
}

export default CountrySelect
