// @flow

import React, { type Node } from 'react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

type Props = {
  children?: Node,
  color?: string,
  iconSize?: number,
  size?: string,
  spinning: boolean,
}

const Spinner = (props: Props) => {
  const {
    children,
    color = 'var(--userPrimaryColor)',
    iconSize = 50,
    size = 'large',
    spinning,
  } = props

  const icon = (
    <LoadingOutlined style={{ fontSize: iconSize, color: color }} spin />
  )

  return (
    <Spin indicator={icon} spinning={spinning} size={size}>
      {children}
    </Spin>
  )
}

export default Spinner
