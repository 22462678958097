export const setActualRouting = (actualRouting) => {
  return {
    type: 'setActualRouting',
    actualRouting,
  }
}

export const setModifiedRouting = (modifiedRouting) => {
  return {
    type: 'setModifiedRouting',
    modifiedRouting,
  }
}

export const setRoutingSaveFromRow = (routingSaveFromRow) => {
  return {
    type: 'setRoutingSaveFromRow',
    routingSaveFromRow,
  }
}

export const setRoutingSaveFromClose = (routingSaveFromClose) => {
  return {
    type: 'setRoutingSaveFromClose',
    routingSaveFromClose,
  }
}

export const setRoutingSaveFromOption = (routingSaveFromOption) => {
  return {
    type: 'setRoutingSaveFromOption',
    routingSaveFromOption,
  }
}

export const setRoutingSavedFromOption = (routingSavedFromOption) => {
  return {
    type: 'setRoutingSavedFromOption',
    routingSavedFromOption,
  }
}

export const setRoutingSaveFromTab = (routingSaveFromTab) => {
  return {
    type: 'setRoutingSaveFromTab',
    routingSaveFromTab,
  }
}

export const setRoutingSavedFromTab = (routingSavedFromTab) => {
  return {
    type: 'setRoutingSavedFromTab',
    routingSavedFromTab,
  }
}
