/* eslint-disable no-console */
import axios from 'axios'
import { cloneDeep } from 'lodash'
import { message } from 'antd'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCallflowPhones, setUnusedPhones } from '../actions/CallflowActions'
import { APIURL } from '../utils/constants'
import { API_MANAGER_KEY } from '../utils/constants'
import { ID_TOKEN } from '../utils/constants'
import { TOKEN_TYPE } from '../utils/constants'
import { getItem } from '../utils/storage'

const UseUpdateCallflowRelation = () => {
  const dispatch = useDispatch()
  const { language, translations } = useSelector((state) => {
    return {
      language: state.sampleReducer.language,
      translations: state.sampleReducer.translations,
    }
  })

  const api = axios.create({
    baseURL: APIURL,
    headers: {
      Authorization: `${getItem(TOKEN_TYPE)} ${getItem(ID_TOKEN)}`,
      'x-api-key': API_MANAGER_KEY,
      'ms-token': localStorage.getItem('mstoken'),
    },
  })

  const [id, setId] = useState('')
  const [executeRelation, setExecuteRelation] = useState(false)
  const [executePhoneApi, setExecutePhoneApi] = useState(false)
  const [selectedPhones, setSelectedPhones] = useState(false)
  const [update, setUpdate] = useState(false)

  useEffect(() => {
    if (executeRelation) {
      setExecuteRelation(false)
      api
        .get(`call_flow-phone/${id}`)
        .then((res) => {
          const { items } = res.data.response

          if (items?.length && items[0].id) {
            dispatch(setCallflowPhones(items))
          } else {
            dispatch(setCallflowPhones(cloneDeep([])))
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [executeRelation])

  useEffect(() => {
    if (executePhoneApi) {
      setExecutePhoneApi(false)
      api
        .get('phone?relations=call_flow,voice_user')
        .then((res) => {
          const { items } = res.data.response

          if (items?.length && items[0].id) {
            dispatch(setUnusedPhones(items.filter((item) => !item.relation)))
          } else {
            dispatch(setUnusedPhones(cloneDeep([])))
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [executePhoneApi])

  useEffect(() => {
    if (update) {
      const updateBody = selectedPhones.map((phone) => {
        return { id: phone }
      })

      setUpdate(false)
      api
        .put(`call_flow-phone/${id}?sync=yes`, { items: updateBody })
        .then(() => {
          message.success(
            translations.callflow_relation_update_success[language]
          )
          setExecuteRelation(true)
          setExecutePhoneApi(true)
        })
        .catch(() => {
          message.error(translations.callflow_relation_update_error[language])
        })
    }
  }, [update])

  return [
    {
      setId,
      setExecuteRelation,
      setExecutePhoneApi,
      setSelectedPhones,
      setUpdate,
    },
  ]
}

export default UseUpdateCallflowRelation
