import axios from 'axios'
import { useDispatch } from 'react-redux'
import { ACTIONAPIURL } from '../utils/constants'
import { API_MANAGER_KEY } from '../utils/constants'
import { ID_TOKEN } from '../utils/constants'
import { TOKEN_TYPE } from '../utils/constants'
import { getItem } from '../utils/storage'
import useConstructor from './UseConstructor'
import { setUserAccounId } from '../actions/SampleAction'

const UseGetAccountId = () => {
  const dispatch = useDispatch()

  const api = axios.create({
    baseURL: ACTIONAPIURL,
    headers: {
      Authorization: `${getItem(TOKEN_TYPE)} ${getItem(ID_TOKEN)}`,
      'x-api-key': API_MANAGER_KEY,
    },
  })

  const handleError = (err) => {
    // eslint-disable-next-line no-console
    console.error(err)
  }

  const getAccountId = () => {
    api
      .post('account', {
        action: 'current',
        actiondata: {},
      })
      .then((res) => {
        if (res.data.result) {
          const { items } = res.data.response

          if (items.length && items[0].actionid) {
            dispatch(setUserAccounId(items[0].data.account_id))
          } else {
            handleError(res.data)
          }
        } else {
          handleError(res.data)
        }
      })
      .catch((err) => {
        handleError(err)
      })
  }

  useConstructor(getAccountId)
}

export default UseGetAccountId
