export const initGeneral = () => {
  return {
    name: '',
    description: '',
    host: '',
    port: 0,
    protocol: 'UDP',
  }
}

export const initCredentials = () => {
  return [
    {
      caller: '',
      username: '',
      password: '',
      from: '',
    },
  ]
}

export const initModifiers = () => {
  return {
    international: {
      countryCode: '',
      symbol: '+',
      forceSymbol: false,
    },
    rules: [
      {
        prefix: '',
        suffix: '',
        stripLeft: '',
        stripRight: '',
        minLength: '',
        maxLength: '',
      },
    ],
  }
}

export const getGeneralInitItems = () => {
  return {}
}
export const getCredentialsInitItems = () => {
  return []
}
export const getModifiersInitItems = () => {
  return []
}

export const generalReducer = () => {}
export const credentialsReducer = () => {}
export const modifiersReducer = () => {}
