/* eslint-disable no-console */

// @flow

import axios from 'axios'
import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { WindowsFilled } from '@ant-design/icons'
import { Button, message } from 'antd'
import { cloneDeep, isEmpty } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import Step from '../Steps/step'
import { PttButton } from '../CustomButton/custombutton'
import useCustomFetch from '../../Hooks/UseCustomFetch'
import { parseApiResult } from '../../utils/helpers'
import { getNewArray } from '../../utils/helpers'
import { getCallingPlanMonth } from '../../utils/helpers'
import { getItem } from '../../utils/storage'
import { setTokens } from '../../utils/LocalStorage'
import AllSetPage from '../Connect/MicrosoftTeams/allset'
import ConfigAccount from '../Connect/MicrosoftTeams/configaccount'
import ConfigureUsers from '../Connect/MicrosoftTeams/configureusers'
import SelectUsers from '../Connect/MicrosoftTeams/selectusers'
import { ACTIONAPIURL, API_MANAGER_KEY, FULL_ACCESS } from '../../utils/constants'
import { AUTH_API_KEY } from '../../utils/constants'
import { CUSTOM_ACTION_URL } from '../../utils/constants'
import { ID_TOKEN } from '../../utils/constants'
import { TOKEN_TYPE } from '../../utils/constants'
import { APIURL } from '../../utils/constants'
import { REFRESH_TOKEN } from '../../utils/constants'
import { REFRESHURL } from '../../utils/constants'
import { SETUPCONNECTORAPI } from '../../utils/constants'
import { getMsUsers } from './msteamsutils'
import { getConfigUserOptions } from './msteamsutils'
import { isContainsError } from './msteamsutils'
import { canProceed } from './msteamsutils'
import { oauthMicrosoft } from './msteamsutils'
import { getSetupConnectorRetryTime } from './msteamsutils'
import { licenseLimitReached } from './msteamsutils'
import { verifyDnsText } from './msteamsutils'
import { tooManyRequest } from './msteamsutils'
import VoiceUserCheckout from './VoiceUserCheckout'
import { setPaymentSucceededEventData } from '../../actions/billingAction'
import UseInterval from '../../Hooks/UseInterval'
import UseFetchMicrosoftUsers from '../../Hooks/UseFetchMicrosoftUsers'
import useConstructor from '../../Hooks/UseConstructor'
import { setTeamsStep } from '../../actions/MsTeamsAction'
import './addmsteams.css'

type Props = {
  connectorsApi: Object,
  existingConnectorId: string,
  translations: Object,
  language: string,
  msOAuthErrorHandler: (any) => mixed,
  callingPlansData: $ReadOnlyArray<*>,
  updatePhoneListCallback: () => mixed,
  microsoftUsersCollections: Object,
  connectorMailId: String,
  accountSettings: Object,
  userSettings: Object,
  userAccounId: String,
  connectorState: String,
  errorPoint: String,
  microsoftTeamsPermission: String
}

const AddMsTeams = ({
  connectorsApi,
  existingConnectorId,
  connectorMailId,
  msOAuthErrorHandler,
  callingPlansData,
  updatePhoneListCallback,
  connectorState,
  errorPoint,
  microsoftTeamsPermission
}: Props) => {
  const authorization = `${getItem(TOKEN_TYPE)} ${getItem(ID_TOKEN)}`

  const dispatch = useDispatch()
  const {
    translations,
    language,
    userSettings,
    accountSettings,
    microsoftUsersCollections,
    userAccounId,
  } = useSelector((state) => ({
    translations: state.sampleReducer.translations || {},
    language: state.sampleReducer.language,
    userSettings: state.sampleReducer.userSettings || {},
    accountSettings: state.sampleReducer.accountSettings || {},
    microsoftUsersCollections: state.msTeamsReducer.microsoftUsersCollections,
    userAccounId: state.sampleReducer.userAccounId,
  }))

  const [currentStep, setCurrentStep] = useState(0)
  const [retry, setRetry] = useState(null)
  const [retryCount, setRetryCount] = useState(0)
  const [createData, setCreateData] = useState(null)
  const [assignData, setAssignData] = useState(null)
  const [connectorId, setConnectorId] = useState('')
  const [tableLoading, setTableLoading] = useState(false)
  const [refreshLoading, setRefreshLoading] = useState(false)
  const [listRows, setListRows] = useState([])
  const [configLoad, setConfigLoad] = useState(false)
  const [extensions, setExtensions] = useState([])
  const [options, setOptions] = useState([])
  const [voiceError, setVoiceError] = useState(false)
  const [rowKeys, setRowKeys] = useState([])
  const [checkoutConfirm, setCheckoutConfirm] = useState(false)
  const [checkoutApiBodyData, setCheckoutApiBodyData] = useState({ action: '' })
  const [phoneCheckOutBody, setPhoneCheckOutBody] = useState({ action: '' })
  const [amountToCharge, setAmountToCharge] = useState(0)
  const [checkoutLoader, setCheckoutLoader] = useState(false)
  const [warning, setWarning] = useState(false)
  const [phones, setPhones] = useState([])
  const [callingPlans, setCallingPlans] = useState([])
  const [phoneAmount, setPhoneAmount] = useState(0)
  const [msLoginLoading, setMsLoginLoading] = useState(false)
  const [callingPlanAmount, setCallingPlanAmount] = useState(0)
  const [finalLicenseCount, setFinalLicenseCount] = useState(0)
  const [finalLicenseError, setFinalLicenseError] = useState(false)
  const [apiStatus, setApiStatus] = useState('')
  const [percent, setPercent] = useState(0)
  const [api1ReqRetry, setApi1ReqRetry] = useState(0)
  const [api2ReqRetry, setApi2ReqRetry] = useState(0)
  const [api4ReqRetry, setApi4ReqRetry] = useState(0)
  const [configUsers, setConfigUsers] = useState([])
  const [apiToRetry, setApiToRetry] = useState('')
  const layoutState = currentStep === 5 ? 'hidden' : 'visible'

  const pttApi = axios.create({
    baseURL: APIURL,
    headers: {
      Authorization: authorization,
      'x-api-key': API_MANAGER_KEY,
    },
  })

  const refreshApi = axios.create({
    baseURL: REFRESHURL,
    headers: {
      Authorization: authorization,
      'Content-Type': 'application/json',
      'x-api-key': AUTH_API_KEY,
    },
  })

  const updateTokens = async () => {
    console.log('updating token')
    await refreshApi
      .post('', {
        RefreshToken: getItem(REFRESH_TOKEN),
      })
      .then((res) => {
        setTokens(res.data)
        console.log('success refresing token')
      })
      .catch(() => console.log('error refreshing token'))
  }

  const getPhones = () => {
    pttApi
      .get('phone?relations=voice_user,call_flow')
      .then((res) => {
        setOptions(getConfigUserOptions(res.data))
        console.log('success getting phones')
      })
      .catch(() => console.log('error getting phones'))
  }

  const getVoiceUsers = () => {
    pttApi
      .get('voice_user')
      .then((res) => {
        const result = parseApiResult(res.data)
        const data = []

        if (result.length) {
          result.forEach((res) => {
            if (res.data && res.data.extension) {
              data.push(res.data.extension)
            }
          })
        }
        setExtensions(data)
        console.log('success getting voice users')
      })
      .catch(() => console.log('error getting voice users'))
  }

  useConstructor(getPhones)
  useConstructor(getVoiceUsers)

  const [interval] = UseInterval(3000)

  const setActive = () => {
    setApiStatus('active')
  }

  const setException = () => {
    setApiStatus('exception')
  }

  useEffect(() => {
    if (connectorState.length) {
      const inProgess = connectorState === 'inprogress'
      const getPercent = () => {
        if (errorPoint === 'gateway') {
          return 10
        } else if (errorPoint === 'setup1') {
          return 20
        } else if (errorPoint === 'setup2') {
          return 30
        } else {
          return 5
        }
      }

      setCurrentStep(inProgess ? 1 : 0)
      if (inProgess) {
        setActive()
        setConnectorId(existingConnectorId)
        setPercent(getPercent())
        interval.start()
        setTimeout(() => {
          if (errorPoint === 'gateway') {
            setup4Api.setResponse({})
            setup4Api.setRefetch(true)
          } else if (errorPoint === 'setup1') {
            setup1Api.setResponse({})
            setup1Api.setRefetch(true)
          } else if (errorPoint === 'setup2') {
            setup2Api.setResponse({})
            setup2Api.setRefetch(true)
          } else {
            console.log('invalid operation')
          }
        }, 500)
      }
    }
  }, [connectorState, errorPoint])

  useEffect(() => {
    if (interval.count) {
      if (apiStatus === 'active') {
        if (percent < 94) {
          setPercent(percent + 1)
        } else {
          interval.stop()
        }
      }
    }
  }, [interval.count])

  const [createApi] = useCustomFetch(
    `${APIURL}/connector?sync=yes`,
    'PUT',
    false,
    createData
  )

  const [assignApi] = useCustomFetch(
    `${ACTIONAPIURL}/connector?sync=yes`,
    'POST',
    false,
    assignData
  )

  const [setup1Api] = useCustomFetch(
    `${SETUPCONNECTORAPI}${connectorId}/1/${userAccounId}`,
    'SETUPCONNECTOR',
    false
  )

  const [setup2Api] = useCustomFetch(
    `${SETUPCONNECTORAPI}${connectorId}/2/${userAccounId}`,
    'SETUPCONNECTOR',
    false
  )

  const [setup4Api] = useCustomFetch(
    `${SETUPCONNECTORAPI}${connectorId}/4/${userAccounId}`,
    'SETUPCONNECTOR',
    false
  )

  const [checkoutApi] = useCustomFetch(
    `${CUSTOM_ACTION_URL}/payment`,
    'PAYMENT',
    false,
    JSON.stringify(checkoutApiBodyData)
  )

  const [phoneCheckoutApi] = useCustomFetch(
    `${CUSTOM_ACTION_URL}/payment`,
    'PAYMENT',
    false,
    JSON.stringify(phoneCheckOutBody)
  )

  const onFetchUsersSuccess = (data) => {
    setListRows(getMsUsers(data.value, listRows))
    setTableLoading(false)
    setRefreshLoading(false)
  }

  const onFetchUsersError = (err) => {
    console.log('Error Fetching microsoft users', err)
  }

  const onFetchUsersInvalidToken = () => {
    oauthMicrosoft(
      connectorMailId,
      () => {
        executeFetchUsersApi()
      },
      (err) => {
        msOAuthErrorHandler(err)
      }
    )
  }

  const onFetchUsersTooManyReq = (data) => {
    setTimeout(() => {
      executeFetchUsersApi()
    }, getSetupConnectorRetryTime(data.error.retry))
  }

  const [fetchUsers] = UseFetchMicrosoftUsers(
    onFetchUsersSuccess,
    onFetchUsersError,
    onFetchUsersInvalidToken,
    onFetchUsersTooManyReq
  )

  const executeFetchUsersApi = () => {
    fetchUsers.reset()
    fetchUsers.setAction('')
    fetchUsers.setId(connectorId)
    fetchUsers.setFetch(true)
  }

  const onPreviousClick = () => {
    const index = microsoftUsersCollections.currentPage - 2
    const url =
      index === 0
        ? null
        : microsoftUsersCollections.nextUsersUrls.find(
            (item) => item.key === index
          )?.link

    fetchUsers.reset()
    fetchUsers.setAction('prevoius')
    fetchUsers.setBody({ search: null, nextlink: url ? url : '' })
    fetchUsers.setId(connectorId)
    fetchUsers.setFetch(true)
    setTableLoading(true)
  }

  const onNextClick = () => {
    const url =
      microsoftUsersCollections.nextUsersUrls[
        microsoftUsersCollections.currentPage - 1
      ]?.link

    fetchUsers.reset()
    fetchUsers.setAction('next')
    fetchUsers.setBody({ search: null, nextlink: url ? url : '' })
    fetchUsers.setId(connectorId)
    fetchUsers.setFetch(true)
    setTableLoading(true)
  }

  const onSearch = (word) => {
    fetchUsers.reset()
    fetchUsers.setAction('')
    fetchUsers.setBody({ search: word })
    fetchUsers.setId(connectorId)
    fetchUsers.setFetch(true)
    setTableLoading(true)
  }

  useEffect(() => {
    if (phoneCheckOutBody.action) {
      phoneCheckoutApi.setRefetch(true)
    }
  }, [phoneCheckOutBody])

  useEffect(() => {
    if (phoneCheckoutApi.response && phoneCheckoutApi.response.status) {
      makeVoiceUsers()
      message.success(translations.phone_number_added_success[language])
      updatePhoneListCallback()
    } else if (
      phoneCheckoutApi.response &&
      phoneCheckoutApi.response.status === false
    ) {
      message.error(translations.something_went_wrong[language])
    }
  }, [phoneCheckoutApi.response])

  useEffect(() => {
    if (phones.length) {
      const opts = JSON.parse(JSON.stringify(options))
      const optsIds = opts.map((opt) => opt.id)
      const data = []

      phones.length &&
        phones.forEach((phone) => {
          if (!optsIds.includes(phone.id)) {
            data.push({ id: phone.id, chosen: false })
          }
        })

      setOptions(opts.concat(data))
    }
  }, [phones])

  useEffect(() => {
    if (createData) {
      setActive()
      createApi.setRefetch(true)
    }
  }, [createData])

  useEffect(() => {
    if (!isEmpty(createApi.response)) {
      const { result } = createApi.response
      const response = parseApiResult(createApi.response)

      if (result && response.length && response[0].id) {
        const id = response[0].id

        console.log('success creating connector')
        setActive()
        setConnectorId(id)
        setTimeout(() => {
          setup4Api.setResponse({})
          setup4Api.setRefetch(true)
        }, 100)
      } else {
        console.log('error creating connector', createApi)
        setApiToRetry('create-api')
        setException()
      }
    }
  }, [createApi.response])

  useEffect(() => {
    if (createApi.error !== null) {
      console.log('create connector api failed', createApi)
      setApiToRetry('create-api')
      setException()
    }
  }, [createApi.error])

  const refreshMsToken = (onSucess) => {
    oauthMicrosoft(connectorMailId, onSucess, (err) => msOAuthErrorHandler(err))
  }

  const onSetup4Success = () => {
    console.log('/4 success')
    setTimeout(() => {
      setActive()
      setAssignData(
        JSON.stringify({
          action: 'assigngateway',
          actiondata: {
            connector_id: connectorId,
          },
        })
      )
    }, 1000)
  }

  const onSetup4ResponseFail = () => {
    console.log('/4 response fail')
    setWarning(true)
    setApiToRetry('/4-api')
    setException()
  }

  const onSetup4InvalidToken = () => {
    console.log('/4 invalid token')
    refreshMsToken(() => {
      setup4Api.setResponse({})
      setup4Api.setRefetch(true)
    })
  }

  const onSetup4TooManyReq = (error) => {
    const time = getSetupConnectorRetryTime(error.retry)

    console.log('/4 too many req')
    setApi4ReqRetry((api4ReqRetry) => api4ReqRetry + 1)
    setActive()
    setTimeout(() => {
      console.log(`/4 too many req retry ${api4ReqRetry}`)
      setup4Api.setRefetch(true)
    }, time)
  }

  const onSetup4RetryLimit = () => {
    console.log('/4 retry limit reached')
    setApi4ReqRetry(0)
    setApiToRetry('/4-api')
    setException()
  }

  useEffect(() => {
    if (!isEmpty(setup4Api.response)) {
      const { status, data, error } = setup4Api.response

      if (status === true) {
        data === true ? onSetup4Success() : onSetup4ResponseFail()
      } else if (status === false) {
        if (error.message === 'INVALID_MICROSOFT_TOKEN') {
          onSetup4InvalidToken()
        } else if (error.message === tooManyRequest && api4ReqRetry < 2) {
          onSetup4TooManyReq(error)
        } else if (api4ReqRetry === 2) {
          onSetup4RetryLimit()
        } else {
          console.log('/4 api unknown issue', setup4Api)
          setApiToRetry('/4-api')
          setException()
        }
      }
    }
  }, [setup4Api.response])

  useEffect(() => {
    if (setup4Api.error !== null) {
      console.log('/4 api falied', setup4Api)
      setApiToRetry('/4-api')
      setException()
    }
  }, [setup4Api.error])

  useEffect(() => {
    if (assignData) {
      setActive()
      assignApi.setRefetch(true)
    }
  }, [assignData])

  useEffect(() => {
    if (!isEmpty(assignApi.response)) {
      const { result } = assignApi.response

      if (result) {
        console.log('success assigning gateway')
        setActive()
        setTimeout(() => {
          setActive()
          setup1Api.setRefetch(true)
        }, 1000)
      } else {
        console.log('error assigning gateway', assignApi)
        setApiToRetry('assign-api')
        setException()
      }
    }
  }, [assignApi.response])

  useEffect(() => {
    if (assignApi.error !== null) {
      console.log('assigning gateway api failed', assignApi)
      setApiToRetry('assign-api')
      setException()
    }
  }, [assignApi.error])

  const onSetup1Success = () => {
    console.log('/1 success')
    setRetryCount(1)
    setActive()
    setTimeout(() => {
      setup2Api.setRefetch(true)
    }, 30000)
  }

  const onSetup1InvalidToken = () => {
    console.log('/1 invalid token')
    refreshMsToken(() => {
      setup1Api.setResponse({})
      setup1Api.setRefetch(true)
    })
  }

  const onSetup1TooManyReq = (error) => {
    const time = getSetupConnectorRetryTime(error.retry)

    console.log('/1 too many req')
    setApi1ReqRetry((api1ReqRetry) => api1ReqRetry + 1)
    setActive()
    setTimeout(() => {
      console.log(`/1 too many req retry ${api1ReqRetry}`)
      setup1Api.setRefetch(true)
    }, time)
  }

  const onSetup1RetryLimit = () => {
    console.log('/1 retry limit reached')
    setApi1ReqRetry(0)
    setApiToRetry('/1-api')
    setException()
  }

  useEffect(() => {
    if (!isEmpty(setup1Api.response)) {
      const { status, error } = setup1Api.response

      if (status === true) {
        onSetup1Success()
      } else if (status === false) {
        if (error.message === 'INVALID_MICROSOFT_TOKEN') {
          onSetup1InvalidToken()
        } else if (error.message === tooManyRequest && api1ReqRetry < 2) {
          onSetup1TooManyReq(error)
        } else if (api1ReqRetry === 2) {
          onSetup1RetryLimit()
        } else {
          console.log('/1 api unknown issue', setup1Api)
          setApiToRetry('/1-api')
          setException()
        }
      }
    }
  }, [setup1Api.response])

  useEffect(() => {
    if (setup1Api.error !== null) {
      console.log('/1 api falied', setup1Api)
      setApiToRetry('/1-api')
      setException()
    }
  }, [setup1Api.error])

  const onSetup2Success = () => {
    console.log('/2 success')
    setApiStatus('success')
    setPercent(100)
    interval.stop()
  }

  const onSetup2LicenseLimit = () => {
    console.log('/2 license limit reached')
    setFinalLicenseCount(setup2Api.response.error.count)
    setFinalLicenseError(true)
    setApiToRetry('/2-api')
    setException()
  }

  const onSetup2VerifyDns = () => {
    console.log('/2 verify dns text')
    setActive()
    setRetryCount((retryCount) => retryCount + 1)
    setTimeout(() => {
      console.log(`/2 verify dns retry ${api2ReqRetry}`)
      setup2Api.setRefetch(true)
    }, 60000)
  }

  const onSetup2VerifyLimit = () => {
    console.log('/2 verify limit reached')
    setRetryCount(0)
    setApiToRetry('/2-api')
    setException()
  }

  const onSetup2TooManyReq = (error) => {
    const time = getSetupConnectorRetryTime(error.retry)

    console.log('/2 too many req')
    setApi2ReqRetry((api2ReqRetry) => api2ReqRetry + 1)
    setActive()
    setTimeout(() => {
      console.log(`/2 too many req retry ${api2ReqRetry + 1}`)
      setup2Api.setRefetch(true)
    }, time)
  }

  const onSetup2RetryLimit = () => {
    console.log('/2 retry limit reached')
    setApi2ReqRetry(0)
    setApiToRetry('/2-api')
    setException()
  }

  useEffect(() => {
    if (!isEmpty(setup2Api.response)) {
      const { status, error } = setup2Api.response

      if (status === true) {
        onSetup2Success()
      } else if (status === false) {
        if (error.message === licenseLimitReached) {
          onSetup2LicenseLimit()
        } else if (error.message === verifyDnsText && retryCount < 6) {
          onSetup2VerifyDns()
        } else if (retryCount === 6) {
          onSetup2VerifyLimit()
        } else if (error.message === tooManyRequest && api2ReqRetry < 2) {
          onSetup2TooManyReq(error)
        } else if (api2ReqRetry === 2) {
          onSetup2RetryLimit()
        } else {
          console.log('/2 api unknown issue', setup2Api)
          setApiToRetry('/2-api')
          setException()
        }
      }
    }
  }, [setup2Api.response])

  useEffect(() => {
    if (setup2Api.error !== null) {
      console.log('/2 api falied', setup2Api)
      setApiToRetry('/2-api')
      setException()
    }
  }, [setup2Api.error])

  useEffect(() => {
    if (configLoad) {
      if (configUsers.length) {
        setCurrentStep(4)
      }
    }
  }, [configLoad])

  useEffect(() => {
    if (retry !== null && !retry && currentStep === 0) {
      moveStepForward()
    }
  }, [retry])

  useEffect(() => {
    if (checkoutApiBodyData.action) {
      checkoutApi.setRefetch(true)
    }
  }, [checkoutApiBodyData])

  useEffect(() => {
    if (checkoutApi.response && checkoutApi.response.status) {
      const userCount = checkoutApi.response.response.voiceUserResponse.filter(
        (response) => response.console === 'createVoiceUser'
      ).length

      if (userCount) {
        message.success(
          userCount === 1
            ? translations.voice_user_success[language]
            : `${userCount} ${translations.voice_users_success[language]}`
        )
      }

      dispatch(setTeamsStep(''))
      setCheckoutLoader(false)
      moveStepForward()
    }
  }, [checkoutApi.response])

  const handleOnLoginClick = async () => {
    await updateTokens()
    console.log('token updated')
    oauthMicrosoft(
      connectorMailId,
      (res) => {
        setMsLoginLoading(false)
        setTimeout(() => {
          createNewConnector(res)
        }, 100)
      },
      (error) => {
        setMsLoginLoading(false)
        msOAuthErrorHandler(error)
      }
    )
  }

  const createNewConnector = async (email) => {
    dispatch(setTeamsStep('2'))
    const body = {
      data: {
        type: 'MICROSOFT_TEAMS',
        msEmail: email,
        wizard: {
          step1: { status: true },
          step2: { status: false },
          step3: { status: false },
          step4: { status: false },
          step5: { status: false },
          step6: { status: false },
          step7: { status: false },
          step8: { status: false },
        },
      },
    }

    interval.start()
    setCreateData(JSON.stringify(body))
    setRetry(false)
  }

  const handleRetryLogin = () => {
    setRetry(null)
    setTimeout(() => {
      setMsLoginLoading(true)
      handleOnLoginClick()
    }, 1000)
  }

  const resetOptions = () => {
    const newOptions = getNewArray(options)

    newOptions.length &&
      newOptions.forEach((newOption) => {
        newOption.chosen = false
      })

    setOptions(getNewArray(newOptions))
  }

  const ResetState = () => {
    setConnectorId('')
    setRetry(null)
    setRetryCount(0)
    setApi1ReqRetry(0)
    setApi2ReqRetry(0)
    setApi4ReqRetry(0)
    setApiStatus('')
    setPercent(0)
    setCreateData(null)
    setAssignData(null)
    setTableLoading(false)
    setListRows([])
    setRowKeys([])
    setConfigLoad(false)
    setCurrentStep(0)
    setup1Api.setResponse({})
    setVoiceError(false)
    resetOptions()
    setPhones([])
  }

  const onSetupSuccessHandler = () => {
    dispatch(setTeamsStep('3'))
    message.success(translations.connector_created_succesfully[language])
    setApiStatus('')
    setPercent(0)
    setCreateData(null)
    setAssignData(null)
    setRetryCount(0)
    setApi1ReqRetry(0)
    setApi2ReqRetry(0)
    setApi4ReqRetry(0)
    moveStepForward()
    setTableLoading(true)
    setTimeout(() => {
      listUsers()
    }, 10000)
  }

  const listUsers = () => {
    setTableLoading(true)
    executeFetchUsersApi()
  }

  const onHandleRefresh = () => {
    setRefreshLoading(true)
    executeFetchUsersApi()
  }

  const refreshConnector = () => {
    connectorsApi.setRefetch(true)
  }

  const moveStepForward = () => {
    setCurrentStep((step) => step + 1)
  }

  const moveStepBackward = () => {
    setCurrentStep((step) => step - 1)
  }

  const enableCheckoutConfirm = (
    value,
    amountToCharge = 0,
    voiceProRateCharge = 0,
    phoneProRateCharge = 0,
    callingPlanProRateCharge = 0
  ) => {
    console.log({ amountToCharge })
    dispatch(setPaymentSucceededEventData(null))
    setAmountToCharge(voiceProRateCharge)
    setCheckoutConfirm(value)
    setPhoneAmount(phoneProRateCharge)
    setCallingPlanAmount(callingPlanProRateCharge)
  }

  const getCallingPlanBody = (row) => {
    const { data } = row.callingPlan

    data.length &&
      data.forEach((item) => {
        item.uniqueId && delete item.uniqueId
      })

    return {
      data: {
        calling_plan: data,
        month: getCallingPlanMonth('current'),
      },
    }
  }

  const makeVoiceUsers = () => {
    const users = []
    const activeRows = cloneDeep(configUsers)

    if (activeRows.length) {
      activeRows.forEach((row) => {
        users.push({
          callingPlanBody: getCallingPlanBody(row),
          displayName: row.displayName,
          email: row.email,
          extension: row.extension,
          givenName: row.givenName,
          lastName: row.lastName,
          microsoft_id: row.microsoft_id,
          phonenumber: row.phonenumber,
        })
      })

      setCheckoutApiBodyData({
        action: 'MICROSOFT_CHECKOUT',
        data: {
          accountId: userAccounId,
          callingPlanAmount: callingPlanAmount,
          chargeAmount: amountToCharge,
          connectorId: connectorId,
          idArray: [],
          voiceUsers: users,
          company_settings: accountSettings,
          user_settings: userSettings,
        },
      })
    }
  }

  const updateConfigUsers = (rows) => {
    setConfigUsers(getNewArray(rows))
  }

  const onRowSelect = (record, status) => {
    let users = cloneDeep(configUsers)

    if (status) {
      users.push(record)
    } else {
      users = users.filter((user) => user.key !== record.key)
    }

    setConfigUsers(users)
    setRowKeys(users.map((user) => user.key))
  }

  const onRowSelectUnSelectAll = (status, records) => {
    if (status) {
      const users = cloneDeep(configUsers).concat(records)

      setConfigUsers(users)
      setRowKeys(users.map((user) => user.key))
    } else {
      const items = []
      const recordsKeys = records.map((record) => record.key)

      configUsers.length &&
        configUsers.forEach((configUser) => {
          if (!recordsKeys.includes(configUser.key)) {
            items.push(configUser)
          }
        })

      setConfigUsers(items)
      setRowKeys(items.map((item) => item.key))
    }
  }

  const renderMicrosoftLoginButton = () => {
    if (microsoftTeamsPermission === FULL_ACCESS) {
      return (
        <div>
          <PttButton
            disabled={retry || msLoginLoading}
            loading={msLoginLoading}
            icon={<WindowsFilled />}
            onClick={() => {
              setMsLoginLoading(true)
              handleOnLoginClick()
            }}
            type="primary"
          >
            {translations.log_in_microsoft[language]}
          </PttButton>
        </div>
      )
    } else {
      return <Fragment />
    }
  }

  const renderConnectToMicrosoft = () => {
    const wizard_start = require('../../assets/images/wizard_start2.svg')
    // const wizard_start2 = require('../../../assets/images/wizard_start2.svg')

    return (
      <div style={{ textAlign: 'center', height: '100%' }}>
        <div className="tag-space">
          {/* <Badge status="error" style={{ marginRight: 5 }} /> */}
          <img alt="" width={500} height={300} src={wizard_start} />
          {translations.connect_to_microsoft_error[language]}
        </div>
        {renderMicrosoftLoginButton()}
      </div>
    )
  }

  const renderConfigButton = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {configAccountFooterButton()}
      </div>
    )
  }

  const renderConfigureYourAccount = () => {
    return (
      <div style={{ height: '100%' }}>
        <ConfigAccount
          hasError={warning}
          percent={percent}
          status={apiStatus}
          showFinalLicenseCount={finalLicenseCount}
          showFinalLicenseError={finalLicenseError}
          renderConfigButton={renderConfigButton}
        />
      </div>
    )
  }

  const renderSelectUsers = () => {
    return (
      <SelectUsers
        listRows={listRows}
        loading={tableLoading}
        onNextClick={onNextClick}
        onPreviousClick={onPreviousClick}
        onRefresh={onHandleRefresh}
        onRowSelect={onRowSelect}
        onRowSelectUnSelectAll={onRowSelectUnSelectAll}
        onSearch={onSearch}
        refreshLoading={refreshLoading}
        rowKeys={rowKeys}
        scrollObject={{ y: 'calc(100vh - 417px)' }}
      />
    )
  }

  const renderConfigureUsers = () => {
    return (
      <div style={{ height: '100%' }}>
        <ConfigureUsers
          availableExtensions={extensions}
          callingPlans={callingPlans}
          callingPlansData={callingPlansData}
          configUsers={configUsers}
          options={options}
          phones={phones}
          rowKeys={rowKeys}
          scrollObject={{ y: 'calc(100vh - 240px)' }}
          setPhones={setPhones}
          updateConfigUsers={updateConfigUsers}
        />
      </div>
    )
  }

  const renderVoiceUserCheckout = () => {
    return (
      <div style={{ height: '100%' }}>
        <VoiceUserCheckout
          callingPlans={cloneDeep(callingPlans)}
          phones={phones}
          voiceUsers={cloneDeep(configUsers)}
          enableCheckoutConfirm={enableCheckoutConfirm}
          scrollObjectSmall={{ y: 'calc(100vh - 347px)' }}
          scrollObjectBig={{ y: 'calc(100vh - 395px)' }}
        />
      </div>
    )
  }

  const pages = [
    {
      title: translations.connect_to_microsoft[language],
      component: () => renderConnectToMicrosoft(),
    },
    {
      title: translations.configure_your_account[language],
      component: () => renderConfigureYourAccount(),
    },
    {
      title: translations.select_users[language],
      component: () => renderSelectUsers(),
    },
    {
      title: translations.configure_users[language],
      component: () => renderConfigureUsers(),
    },
    {
      title: translations.checkout[language],
      component: () => renderVoiceUserCheckout(),
    },
  ]

  const getSteps = () => {
    if (currentStep === 5) {
      return <Fragment />
    } else {
      return <Step steps={pages} current={currentStep} />
    }
  }

  const getBody = () => {
    if (currentStep === 5) {
      return (
        <AllSetPage
          loading={connectorsApi.loading}
          onClickHandler={refreshConnector}
        />
      )
    } else {
      return (
        <div style={{ height: '100%', marginTop: 25 }}>
          {pages[currentStep].component()}
        </div>
      )
    }
  }

  const moveToCheckoutStep = () => {
    const activeRows = cloneDeep(configUsers)
    const proceedObj = canProceed(activeRows, [])

    if (proceedObj.proceed) {
      const plans = []

      activeRows.forEach((row) => {
        row.callingPlan.data.length &&
          row.callingPlan.data.forEach((item) => {
            plans.push(item)
          })
      })

      setCurrentStep(4)
      setCallingPlans(plans)
    } else {
      message.warning(
        `${proceedObj.phonenumber} ${translations.has_more_than_one_empty_extension[language]}`
      )
    }
  }

  const userConfigNextButton = useMemo(() => {
    return (
      <PttButton
        disabled={isContainsError(cloneDeep(configUsers))}
        onClick={() => {
          dispatch(setTeamsStep('5'))
          moveToCheckoutStep()
        }}
        type="primary"
      >
        {translations.next[language]}
      </PttButton>
    )
  }, [configLoad, configUsers])

  const handleExceptionRetry = () => {
    if (apiToRetry === 'create-api') {
      setApiToRetry('')
      createApi.setResponse({})
      createApi.setRefetch(true)
    } else if (apiToRetry === 'assign-api') {
      setApiToRetry('')
      assignApi.setResponse({})
      assignApi.setRefetch(true)
    } else if (apiToRetry === '/4-api' || warning) {
      setTimeout(() => {
        setWarning(false)
        setApiStatus('')
        setApiToRetry('')
        setup4Api.setResponse({})
        setup4Api.setRefetch(true)
      }, 1000)
    } else if (apiToRetry === '/1-api') {
      setApiToRetry('')
      setup1Api.setResponse({})
      setup1Api.setRefetch(true)
    } else if (apiToRetry === '/2-api' || finalLicenseError) {
      setTimeout(() => {
        setFinalLicenseCount(0)
        setFinalLicenseError(false)
        setApiStatus('')
        setApiToRetry('')
        setup2Api.setResponse({})
        setup2Api.setRefetch(true)
      }, 1000)
    }
  }

  const configAccountFooterButton = () => {
    if (apiStatus === 'exception') {
      return (
        <div className="stepmodal-footer-end">
          <PttButton onClick={handleExceptionRetry} type="primary">
            {translations.retry[language]}
          </PttButton>
        </div>
      )
    } else {
      return (
        <div className="stepmodal-footer-end">
          <PttButton
            disabled={apiStatus === 'success' ? false : true}
            onClick={onSetupSuccessHandler}
            type="primary"
          >
            {translations.next[language]}
          </PttButton>
        </div>
      )
    }
  }

  const addPhoneNumbers = () => {
    const phoneNumbers = phones.map((item) => {
      if (item.skuId) {
        return {
          geo: item.data.geo,
          price: item.data.price.usd.monthly_amount,
          skuId: item.skuId,
          id: item.id,
          didId: item.didId,
        }
      } else {
        return { id: item.id }
      }
    })

    setPhoneCheckOutBody({
      action: 'PHONE_NUMBER_CHECKOUT',
      data: {
        chargeAmount: phoneAmount,
        phoneNumbers: phoneNumbers,
      },
    })
  }

  const getFooter = () => {
    if (currentStep === 0 && retry) {
      return (
        <div className="stepmodal-footer-end">
          <PttButton onClick={handleRetryLogin} type="primary">
            {translations.retry[language]}
          </PttButton>
        </div>
      )
    } else if (currentStep === 1) {
      return configAccountFooterButton()
    } else if (currentStep === 2) {
      return (
        <div className="stepmodal-footer-end">
          <PttButton
            disabled={rowKeys.length === 0}
            onClick={() => {
              dispatch(setTeamsStep('4'))
              moveStepForward()
            }}
            type="primary"
          >
            {translations.next[language]}
          </PttButton>
        </div>
      )
    } else if (currentStep === 3) {
      if (voiceError) {
        return (
          <div className="stepmodal-footer-end">
            <PttButton onClick={ResetState} type="primary">
              {translations.retry[language]}
            </PttButton>
          </div>
        )
      } else {
        return (
          <div className="stepmodal-footer">
            <Button
              className="back-button-style"
              // disabled={configLoad}
              onClick={() => {
                dispatch(setTeamsStep('3'))
                moveStepBackward()
              }}
            >
              {translations.back[language]}
            </Button>
            {userConfigNextButton}
          </div>
        )
      }
    } else if (currentStep === 4) {
      return (
        <div>
          <div className="stepmodal-footer">
            <Button
              className="back-button-style"
              disabled={checkoutLoader}
              onClick={() => {
                dispatch(setTeamsStep('4'))
                moveStepBackward()
              }}
            >
              {translations.back[language]}
            </Button>
            <PttButton
              onClick={() => {
                setCheckoutLoader(true)
                if (phones.length) {
                  addPhoneNumbers()
                } else {
                  makeVoiceUsers()
                }
              }}
              type="primary"
              disabled={!checkoutConfirm || checkoutLoader}
              loading={checkoutLoader}
            >
              {translations.confirm[language]}
            </PttButton>
          </div>
        </div>
      )
    } else {
      return ''
    }
  }

  return (
    <div style={{ height: '100%' }}>
      <div style={{ visibility: layoutState, height: 40 }}>{getSteps()}</div>
      <div style={{ height: 'calc(100% - 105px)' }}>{getBody()}</div>
      <div style={{ visibility: layoutState, height: 40 }}>{getFooter()}</div>
    </div>
  )
}

export default AddMsTeams
