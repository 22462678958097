import React from 'react'
import { Redirect } from 'react-router-dom'
import { ID_TOKEN } from './utils/constants'
import AuthTokenRefresh from './AuthTokenRefresh'
import './App.css'

function AuthCheck() {
  return (
    <div className="Layout__Body">
      {localStorage.getItem(ID_TOKEN) ? (
        <AuthTokenRefresh />
      ) : (
        <Redirect to={'/login'} />
      )}
    </div>
  )
}

export default AuthCheck
